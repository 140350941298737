import { ForumLayout } from "../../layouts/ForumLayout";
import { AddThreadModal } from "../../components/Modals/AddThreadModal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createThreadCommentFeth, deleteThreadByThreadIdFeth, favoriteThreadFeth, getThreadCommentFeth, getThreadFeth, likeThreadFeth, viewThreadFeth } from "../../store/forums/forumsThunk";
import CommentSection from "../../components/comments/CommentSection";
import PageLoader from "../../components/parts/PageLoader";
import { ReactComponent as HeartIcon } from "../../assets/img/ico-like.svg";
import { ReactComponent as BookmarkIcon } from "../../assets/img/ico-favor.svg";
import { ReactComponent as Comment } from "../../assets/img/ico-comment.svg";
import { ReactComponent as Share } from "../../assets/img/ico-share.svg";
import { ReactComponent as Dots } from "../../assets/img/ico-dots.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { getDateToString, getImageSrc } from "../../helpers/utils";
import { getMe } from "../../store/auth/authSlice";
import useDebounce from "../../hooks/useDebounce";
import { Dropdown } from "../../components/parts/Dropdown";
import MediaSwiper from "../../components/parts/MediaSwiper";
import { ReactComponent as PlaceholderIcon } from "../../assets/img/user-placeholder.svg"
import DeleteModal from "../../components/Modals/DeleteModal";

export const ForumThread = () => {
    const me = useSelector(getMe)
    const { id, threadId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { threadOne, forumOne, isLoading } = useSelector(state => state.forums)
    const { comments, pagination, commentsIsLoading } = useSelector(state => state.comments)
    const [deleteModal, setDeleteModal] = useState(false)
    const [commentsPage, setCommentsPage] = useState(1)
    const [commentsSort, setCommentsSort] = useState('lastest')
    const [commentsSearch, setCommentsSearch] = useState('')
    const [openAddThread, setOpenAddThread] = useState(false)
    const [commentsLimit, setCommentsLimit] = useState(10)
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (threadId) {
            dispatch(getThreadFeth({ id: threadId }))
        }
    }, [threadId])
    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    const debouncedSearchChange = useDebounce((value) => {
        setCommentsSearch(value)
    }, 500);
    const handleSearchChange = (value) => {
        debouncedSearchChange(value);
    };
    const openModal = () => {
        setOpenAddThread(true)
    }
    const closeModal = () => {
        setOpenAddThread(false)
    }
    useEffect(() => {
        if (threadId) {
            dispatch(getThreadCommentFeth({ id: threadId, qwery: `limit=${commentsLimit}&page=${commentsPage}&sort=${commentsSort}&search=${commentsSearch}` }));
        }
    }, [threadId, commentsPage, commentsSort, commentsSearch])
    useEffect(() => {
        if (!threadId) return
        dispatch(viewThreadFeth(threadId))
    }, [threadId])
    const likeHandler = async () => {
        const res = await dispatch(likeThreadFeth(threadId)).unwrap()
    }
    const favoriteHandler = async () => {
        const res = await dispatch(favoriteThreadFeth(threadId)).unwrap()
    }
    const handleDeleteButton = async () => {
        const res = await dispatch(deleteThreadByThreadIdFeth(threadId)).unwrap()
        if (res) {
            navigate(`/forums/${id}`)
        }
    }
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
    if (isLoading) return <PageLoader />
    return (
        <section className="col-group gap--md-2">
            <div className="row-group row-dots">
                <div className="row-group gap--xs">
                    <span className="ico ico--xxs imageWrapper rounded-full">
                        {
                            threadOne?.user?.photo ?
                                <img src={getImageSrc(threadOne?.user?.photo)} />
                                : <PlaceholderIcon />
                        }
                    </span>
                    <p className="text--sm font-medium">{threadOne?.user?.firstName} {threadOne?.user?.lastName}</p>
                </div>
                <p className="text--sm color-danger font-medium">{forumOne?.name}</p>
                <p className="text--sm color-secondary font-medium">{getDateToString(threadOne?.createdAt)}</p>
                {threadOne?.user?.id === me?.id && <Dropdown
                    autoClose={openAddThread || deleteModal}
                    wrapperClass="ml-auto"
                    button={
                        <button type='button' className="btn btn--md radius-5 btn--transparent btn--share btn--square btn--square--fix rounded-full">
                            <span className="ico">
                                <Dots/>
                            </span>
                        </button>
                    }
                    body={
                        <ul className='min-w-[150px]'>
                            <li>
                                <button
                                    type='button'
                                    onClick={openModal}
                                    className='btn btn--transparent btn--lg radius-5 gap--xs ' >
                                    <span className='color-default'>Edit</span>
                                </button>
                            </li>
                            <li className="px-[14px]"><hr className="hr" /></li>
                            <li>
                                <button
                                    type='button'
                                    onClick={() => setDeleteModal(true)}
                                    className='btn btn--transparent btn--lg radius-5 gap--xs'
                                >
                                    Delete
                                </button>
                            </li>
                        </ul>
                    }
                />}
            </div>
            <h2>
                {threadOne?.title}
            </h2>
            {threadOne?.type === 'text' && <div className="article-text" dangerouslySetInnerHTML={{ __html: threadOne?.content }}>
            </div>}
            {threadOne?.type === 'media' && <div className="max-w-full min-w-0">
                <MediaSwiper media={threadOne?.media?.map(media => ({
                    ...media,
                    src: getImageSrc(media.fileName),
                    type: imageExtensions.test(media.fileName) ? 'image' : 'video'
                }))} />
            </div>}
            {threadOne?.type === 'link' && <a href={threadOne.link} target="_blank" className="color-danger text-[18px]">{threadOne.link}</a>}

            <div className="discus-profile mb-[14px]">
                <div className="discus-profile--head">
                    <div className="discus-controls">
                        <div className="pill-custom pill-custom-2 --pill-st-1">
                            <span className="pill-text">Chat</span>
                        </div>
                        <div>
                            <button
                                className={`btn btn--md radius-5 btn--transparent btn--like gap-[7px] favorite-btn ${threadOne?.isLiked ? "--selected" : ""}`}
                                onClick={likeHandler}
                            >
                                <span className="ico">
                                    <HeartIcon />
                                </span>
                                <p className="text--md font-medium">{threadOne?.likes}</p>
                            </button>
                        </div>
                        <div>

                            <span className="btn btn--md radius-5
                                                btn--transparent btn--save gap-[7px]">
                                <span className="ico">
                                    
                                    <Comment/>
                                </span>
                                <p className="text--md font-medium">{threadOne?.comments} Comments</p>
                            </span>
                        </div>
                    </div>
                    <div className="discus-controls">
                        <div>
                            <CopyToClipboard text={window.location.href} onCopy={handleCopy}>
                                <span className="btn btn--md radius-5 btn--transparent btn--share gap-[7px] relative">
                                    <span className="ico">
                                        <Share/>
                                    </span>
                                    <p className="text--md font-medium">Share</p>
                                    {copied ? <span className='copy-message'>Url copied</span> : null}
                                </span>
                            </CopyToClipboard>
                        </div>
                        <div>

                            <button
                                type="button"
                                className={`btn btn--md radius-5 btn--transparent btn--save gap-[7px] favorite-btn ${threadOne?.isFavorite ? "--selected" : ""}`}
                                onClick={favoriteHandler}
                            >
                                <span className="ico">
                                    <BookmarkIcon />
                                </span>
                                <p className="text--md font-medium">Add to Bookmarks</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Comment Section */}
            {forumOne?.settings?.allowComments && <CommentSection
                addCommentFeth={createThreadCommentFeth}
                commentArrray={comments}
                pagination={pagination}
                setLimit={setCommentsPage}
                isLoading={commentsIsLoading}
                formType='editor'
                isPin={me?.id === forumOne?.user?.id || forumOne?.moderators?.filter(moder => moder.status === 'active').findIndex(moder => moder.id === me?.id)}
                searchBlock={true}
                sortHandler={(value) => setCommentsSort(value)}
                searchHandler={handleSearchChange}
            />}
            {/* Modal */}
            {openAddThread && <AddThreadModal open={openAddThread} removeHandler={() => setDeleteModal(true)} forumName={forumOne?.name} title={'Edit Thread'} handleClose={closeModal} isEdit={true} />}
            {deleteModal && <DeleteModal open={deleteModal} handelClose={() => setDeleteModal(false)} title={'thread'} deleteHandler={handleDeleteButton}  />}
        </section>
    )
}