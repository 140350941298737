const pagesBase = 'api/pages';

export const pages = (instance) => ({
    async getAllPages(payload) {
        try {
            const { data } = await instance.get(`${pagesBase}/public`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getPageByPath(path) {
        try {
            const { data } = await instance.get(`${pagesBase}/by-path?path=/${path}`,);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getHomePage(payload) {
        try {
            const { data } = await instance.get(`/api/home`,);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getAnatomyPage(payload) {
        try {
            const { data } = await instance.get(`/api/anatomy`,);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});