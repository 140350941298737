import { toast } from "react-toastify";
import api from "../../api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getFiltersByName = createAsyncThunk(
    '/getFiltersByName/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.filters.getFiltersByName(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getFiltersDiagnosisFeth = createAsyncThunk(
    '/getFiltersDiagnosisFeth/',
    
    async (payload, { rejectWithValue }) => {
        
        try {
            const res = await api.filters.getFiltersDiagnosis(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getFiltersForFooterFeth = createAsyncThunk(
    '/getFiltersForFooterFeth/',
    
    async (payload, { rejectWithValue }) => {
        
        try {
            const res = await api.filters.getFiltersForFooter(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);