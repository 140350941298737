import React from 'react'
import Actions from './Actions'
import { getDateToString, getImageSrc } from '../../../helpers/utils'
import { setShowLoginModal } from '../../../store/auth/authSlice'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { likeArticleByArticleIdFeth, toggleFavoriteArticleByArticleIdFeth } from '../../../store/articles/articlesThunks'
const statusClasses  = {
    "approved": 'color-success',
    "rejected": 'color-danger',
    "pending": 'color-primary',
    "draft": '',
}
const CardArticle = ({ data, isList, authorized, hideActions = false, showStatus = false }) => {
    const dispatch = useDispatch();
    const toggleLike = () => {
        if(authorized) {
            dispatch(likeArticleByArticleIdFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true))
        }
        

    }
    const toggleFavorite = () => {
        if(authorized) {
            dispatch(toggleFavoriteArticleByArticleIdFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true))
        }
    }
   
    return (
        <>
            {
                !isList ?
                    <div className="card card-post">
                        <div className="card-header post-image wrapp-image-sm" >
                            <Link 
                                to={`${authorized ? `/articles/${data?.id}/${data?.draft ? '?versionId=' + data?.draft?.id : ''}` : '#'}`}
                                className='flex w-full'
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }}
                            >
                                {data?.cover && <img style={{ width: '100%' }} src={getImageSrc(data?.cover)} alt={data?.title} />}
                                {!data?.isCompleted && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}
                            </Link>
                            {!hideActions && <Actions handleLikeProps={toggleLike} handleAddToFavoritesProps={toggleFavorite} isFavoriteProps={data?.isFavorite} isLikeProps={data?.isLiked} />}
                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <div className="group-between">
                                    <div className="pill-custom ">
                                        <span className="pill-text">{data?.filter?.name}</span>
                                    </div>
                                </div>
                                <Link 
                                    to={`${authorized ? `/articles/${data?.id}/${data?.draft ? '?versionId=' + data?.draft?.id : ''}` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}
                                >
                                    <h5 className="heading post-title color-default">{data?.versions?.length > 0 ? data?.versions[0].title : data?.title}</h5>
                                </Link>
                                {/* {data?.status  && showStatus && <div className="">
                                    Status: <span className={statusClasses[data?.status]}>{data?.status}</span>
                                </div> } */}
                                <div className="post-others">
                                    {data?.displayAuthorName &&
                                        <p className="text-[14px]">
                                            by {" "}
                                            <Link to={`/profile/${data?.user?.id}`}>
                                                <span className="color-danger">{(data?.user?.firstName || '') + ' ' + (data?.user?.lastName || '')}</span>
                                            </Link>
                                        </p>
                                    }
                                    <p className="mini-post--desk">{getDateToString(data?.createdAt)}</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    :
                    <div className="card card-post --card-row card-post--md">
                        <div className="card-header post-image">
                            <Link 
                                to={`${authorized ? `/articles/${data?.id}/${data?.draft ? '?versionId=' + data?.draft?.id : ''}` : '#'}`}
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }} 
                                className='card-header post-image' 
                            >
                                 {data?.cover && <img style={{ width: '100%' }} src={getImageSrc(data?.cover)} alt={data?.title} />}
                                 {!data?.isCompleted && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}
                            </Link>
                             <Actions handleLikeProps={toggleLike} handleAddToFavoritesProps={toggleFavorite} isFavoriteProps={data?.isFavorite} isLikeProps={data?.isLiked} />
                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <Link 
                                    to={`${authorized ? `/articles/${data?.id}/${data?.draft ? '?versionId=' + data?.draft?.id : ''}` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}
                                >
                                    <h4 className="heading post-title">{data?.title}</h4>
                                </Link>
                                {/* {data?.status  && showStatus && <div className="">
                                    Status: <span className={statusClasses[data?.status]}>{data?.status}</span>
                                </div> } */}
                                {data?.displayAuthorName && <div className="post-others">
                                    <p className="text-[14px]">
                                        by {" "}
                                        <Link to={`/profile/${data?.user?.id}`}>
                                            <span className="color-danger">{(data?.user?.firstName || '') + ' ' + (data?.user?.lastName || '')}</span>
                                        </Link>
                                    </p>
                                </div>}
                            </div>
                            <div className="card-body--item">
                                <p className="post--desk" dangerouslySetInnerHTML={{ __html: data?.content }}></p>
                            </div>
                            <div className="card-body--item">
                                <div className="group-between">
                                    <div className="group-between--item">
                                        <div className="pill-custom">
                                            <span className="pill-text">{data?.filter?.name}</span>
                                        </div>
                                        <p className="mini-post--desk">{getDateToString(data?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default CardArticle