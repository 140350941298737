import { createSlice } from '@reduxjs/toolkit';
import { getSearchResultsFetch } from './searchThunks';


const initialState = {
    results: null
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
       
    },

    extraReducers(builder) {
        builder
        .addCase(getSearchResultsFetch.fulfilled, (state, action) => {
            state.isLoading = false
            state.results = action.payload?.data
            state.pagination = action.payload?.pagination;
        })
        .addCase(getSearchResultsFetch.pending, (state, action) => {
            state.isLoading = true
            state.error = null;
        })
        .addCase(getSearchResultsFetch.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.payload;
        })
    }
})

export const {
   
} = searchSlice.actions;


export default searchSlice.reducer