import React from 'react'
import { useDispatch } from 'react-redux'
import { getAuthorized, setShowLoginModal } from '../../../store/auth/authSlice'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getDateToString, getImageSrc } from '../../../helpers/utils'

const SidebarRelatedVideos = ({ videos, title, postImageBig = false }) => {
    const dispatch = useDispatch()
    const authorized = useSelector(getAuthorized)
    const protectLink = (e) => {
        if (!authorized) {
            e.preventDefault()
            dispatch(setShowLoginModal(true))
        }
    }
    return (
        <div className="post-widget">
            <div className="post-widget--header">
                <div className="heading-post-row">
                    <span className="ico ico--xs">
                        <img src={require("../../../assets/img/video-play-icon.png")} />
                    </span>
                    <p className="heading-title-post text-def-2-c font-[600]">
                        {title}
                    </p>
                </div>
            </div>
            <div className="post-widget--body flex flex-col">
                <ul className="mini-posts-list flex flex-col">
                    {videos && videos?.map(video => (
                        <li key={video.id}>
                            <Link to={`/materials/video/${video.id}`} 
                               className={`mini-post ${postImageBig ? '' : 'mini-post--image-sm'}`}
                            >
                                <div className="wrapp-image mini-post-img">
                                    <video src={getImageSrc(video?.fileName)} ></video>
                                    <div className="btn-play">
                                        <img src={require("../../../assets/img/mini-play-btn.svg").default} />
                                    </div>
                                </div>
                                <div className="mini-post-content">
                                    <p className="mini-post--title">
                                        {video?.title}
                                    </p>
                                    <p className="mini-post--desk text-[13px]">
                                        {getDateToString(video?.createdAt)}
                                    </p>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
                <Link to={'/materials/videos'} className="btn max-w-max">
                    <span className="color-danger text-[13px] font-[500]">
                        See more videos
                    </span>
                </Link>
            </div>
        </div>
    )
}

export default SidebarRelatedVideos
