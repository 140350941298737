import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useValidation } from '../../hooks/useValidation'
import { bookEventByIdFetch } from '../../store/events/eventsThunks'
import InputGroup from '../UI/formElements/InputGroup'
import ModalTemplate from './ModalTemplate'

const RegisterNowModal = ({ open, handelClose, id }) => {
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phoneNumber: ""
    })

    const validators = {
        email: value => /\S+@\S+\.\S+/.test(value) ? true : 'Invalid email format',
    };

    const messages = {
        fullName: "Full name is required",
        email: 'Email is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(formData, {
        validators,
        messages,
        exceptions: ["phoneNumber"]
    });

    const handleSubmit = async () => {
        setIsErrorShown(true)
        if(errors) return
        const body = {...formData}
        // if(!body.phoneNumber) {
        //     delete body.phoneNumber
        // }
        const res = await dispatch(bookEventByIdFetch({ id, body })).unwrap()
        if(res) {
            toast.success(res.messages || "Successfully registered!")
            handelClose && handelClose()
        }
        
    }

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <ModalTemplate open={open} handelClose={handelClose} modalSize='modal__dialog--550'>
            <div className="modal__body items-center pt-0">
                <div className="modal__body-heading text-center">
                    <h2>Register now</h2>
                </div>
                <div className="register-now-group">

                    <InputGroup
                        wrapperClasses='input--lg mb-[24px]'
                        label={'Full Name'}
                        placeholder={'Enter your full name...'}
                        inputClasses='input--outline'
                        onChange={(e) => handleChange("fullName", e.target.value)}
                        error={isErrorShown && errors.fullName}
                        errorMessage={errorMessages.fullName}
                        />
                    <InputGroup
                        wrapperClasses='input--lg mb-[24px]'
                        label={'Email'}
                        placeholder={'Enter your email...'}
                        inputClasses='input--outline'
                        onChange={(e) => handleChange("email", e.target.value)}
                        error={isErrorShown && errors.email}
                        errorMessage={errorMessages.email}
                    />
                    <InputGroup
                        wrapperClasses='input--lg mb-[24px]'
                        label={'Phone Number'}
                        placeholder={'Enter your phone'}
                        inputClasses='input--outline'
                        onChange={(e) => handleChange("phoneNumber", e.target.value)}
                    />
                </div>
            </div>
            <div className="modal__footer">
                <div className="register-now-group">
                    <button onClick={handleSubmit} className="btn btn--primary btn--lg radius-5 w-full">Send</button>
                </div>
            </div>
        </ModalTemplate>
    )
}

export default RegisterNowModal
