import React from 'react'
import { ButtonView, Plugin } from "ckeditor5";
import { createRoot } from 'react-dom/client';
import Picker from 'emoji-picker-react';


const emojiIcon = `<svg width="24" height="24" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.773 3.72699C11.1367 1.09072 6.86325 1.09072 4.22697 3.72699C1.54563 6.40834 1.59139 10.7829 4.36461 13.4069L3.84981 13.951L4.36461 13.4069C6.9196 15.8241 11.0804 15.8241 13.6354 13.4068C16.4086 10.7829 16.4543 6.40833 13.773 3.72699ZM14.8336 2.66633C11.6116 -0.555728 6.38837 -0.555728 3.16631 2.66633C-0.110673 5.94332 -0.0547726 11.2904 3.33368 14.4964L3.3337 14.4965C6.46704 17.4609 11.5329 17.4609 14.6663 14.4965L14.6663 14.4964C18.0547 11.2904 18.1106 5.94332 14.8336 2.66633Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.57674 11.0237C5.8614 10.7228 6.33609 10.7097 6.637 10.9943C7.937 12.2241 10.062 12.2241 11.362 10.9943C11.6629 10.7097 12.1376 10.7228 12.4223 11.0237C12.7069 11.3247 12.6937 11.7993 12.3928 12.084C10.5145 13.8609 7.4845 13.8609 5.60617 12.084C5.30526 11.7993 5.29209 11.3247 5.57674 11.0237Z" />
</svg>
`;
export default class EmojiPlugin extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add('emojiPicker', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Insert Emoji',
                icon: emojiIcon,
                tooltip: true
            });
            let container;
            let modal
            function closePicker() {
                if (container) {
                    const root = container._root;
                    if (root) {
                        root.unmount();
                    }
                    if (document.body.contains(container)) {
                        document.body.removeChild(container);
                    }
                    document.removeEventListener('click', handleClickOutside);
                    if (modal) {
                        modal.removeEventListener('click', handleClickOutside);
                        modal = null
                    }

                    container = null;
                }
            };
            function handleClickOutside(event, buttonElement) {
                if (container && !container.contains(event.target) && event.target.closest('[data-cke-tooltip-text="Insert Emoji"]') !== buttonElement) {
                    closePicker();
                }
            };

            const openPicker = () => {
                modal = document.querySelector('.thread-modal')
                container = document.createElement('div');
                container.id = 'emoji-picker-container';
                document.body.appendChild(container);
                const buttonElement = view.element;

                const rect = buttonElement.getBoundingClientRect();

                // Позиционирование контейнера рядом с кнопкой
                const pickerWidth = 350;  // Примерная ширина пикера
                const pickerHeight = 450; // Примерная высота пикера

                let top = rect.bottom + window.scrollY;
                let left = rect.left + window.scrollX;

                // Если пикер выходит за правый край экрана
                if (left + pickerWidth > window.innerWidth) {
                    left = window.innerWidth - pickerWidth - 10; // -10 для небольшого отступа
                }

                // Если пикер выходит за нижний край экрана
                if (top + pickerHeight > window.innerHeight) {
                    top = rect.top + window.scrollY - pickerHeight; // Показать пикер над кнопкой
                }

                container.style.position = 'absolute';
                container.style.top = `${top}px`;
                container.style.left = `${left}px`;
                container.style.zIndex = '10000';


                document.addEventListener('click', (e) => handleClickOutside(e, buttonElement));
                if (modal) {
                    modal.addEventListener('click', (e) => handleClickOutside(e, buttonElement))
                }
                const root = createRoot(container);
                container._root = root; // Сохраняем ссылку на root для последующего удаления

                root.render(
                    <Picker
                        onEmojiClick={(event) => {
                            // Вставка смайлика в контент редактора
                            editor.model.change(writer => {
                                const insertPosition = editor.model.document.selection.getFirstPosition();
                                writer.insertText(event.emoji, insertPosition);
                            });
                            closePicker();
                        }}
                    />
                );
            };

            view.on('execute', () => {
                if (container) {
                    closePicker();
                } else {
                    openPicker();
                }
            });

            return view;
        });
    }
}