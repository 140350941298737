import { ButtonView, Plugin } from "ckeditor5";

export default class ChartPlugin extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add('insertChart', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Insert Chart',
                icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 8.83887C11.6642 8.83887 12 9.17465 12 9.58887V13.3346C12 13.7488 11.6642 14.0846 11.25 14.0846C10.8358 14.0846 10.5 13.7488 10.5 13.3346V9.58887C10.5 9.17465 10.8358 8.83887 11.25 8.83887Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.74805 5.91528C9.16226 5.91528 9.49805 6.25107 9.49805 6.66528V13.3347C9.49805 13.7489 9.16226 14.0847 8.74805 14.0847C8.33383 14.0847 7.99805 13.7489 7.99805 13.3347V6.66528C7.99805 6.25107 8.33383 5.91528 8.74805 5.91528Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.24805 10.3003C6.66226 10.3003 6.99805 10.6361 6.99805 11.0503V13.3346C6.99805 13.7488 6.66226 14.0846 6.24805 14.0846C5.83383 14.0846 5.49805 13.7488 5.49805 13.3346V11.0503C5.49805 10.6361 5.83383 10.3003 6.24805 10.3003Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 7.37671C14.1642 7.37671 14.5 7.7125 14.5 8.12671V13.3347C14.5 13.7489 14.1642 14.0847 13.75 14.0847C13.3358 14.0847 13 13.7489 13 13.3347V8.12671C13 7.7125 13.3358 7.37671 13.75 7.37671Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.74609 7.49682C1.74609 4.32119 4.32046 1.74683 7.49609 1.74683H12.5023C15.678 1.74683 18.2523 4.32119 18.2523 7.49683V12.5031C18.2523 15.6787 15.678 18.2531 12.5023 18.2531H7.49609C4.32045 18.2531 1.74609 15.6787 1.74609 12.5031V7.49682ZM7.49609 3.24683C5.14888 3.24683 3.24609 5.14961 3.24609 7.49682V12.5031C3.24609 14.8503 5.14888 16.7531 7.49609 16.7531H12.5023C14.8496 16.7531 16.7523 14.8503 16.7523 12.5031V7.49683C16.7523 5.14962 14.8496 3.24683 12.5023 3.24683H7.49609Z" fill="currentColor"/>
                    </svg>`,
                tooltip: true
            });

            view.on('execute', () => {
                // Вызываем пользовательскую функцию, которая открывает модальное окно
                editor.execute('openChartModal');
            });

            return view;
        });
    }
}