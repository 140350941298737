import React, { useEffect, useState } from 'react'
import QuizSteps from '../../../components/parts/QuizSteps'
import RadioButtonQuiz from '../../../components/UI/formElements/RadioButtonQuiz';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setActiveQuestionId, setActiveQuestionIndex } from '../../../store/quizzes/quizzesSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { addQuizResultByQuestionIdFeth, getQuizQuestionsByQuizIdFeth, сlearQuizResultsbyQuizIdFeth } from '../../../store/quizzes/quizzesThunks';
import { QuizeStepsQuestions } from '../../../components/parts/QuizeStepsQuestions';
import { getImageSrc } from '../../../helpers/utils';
import TimerEndModal from '../../../components/Modals/TimerEndModal';

const QuizPageQuestions = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { quiz, questions, quizeResults, isLoading } = useSelector((state) => state.quizzes);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [currentAnswerId, setCurrentAnswerId] = useState(null);
    const [erros, setErrors] = useState({})
    const [timeLeft, setTimeLeft] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false)
    useEffect(() => {
        if (questions) {
            setCurrentQuestion(questions[quizeResults.completedQuestions])
        }
    }, [quizeResults, questions])

    useEffect(() => {
        if(quiz?.quizSetting?.timer) {
            setTimeLeft(quiz.quizSetting.timer * 60)
        }
    }, [quiz])
    useEffect(() => {
        if (timeLeft !== null && timeLeft > 0) {
            const timerId = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);

            return () => clearInterval(timerId);
        } else if (timeLeft === 0) {
            setIsModalOpen(true);
        }
    }, [timeLeft]);

    useEffect(() => {
        if (quizeResults.completedQuestions === quizeResults.totalQuestions) {
            navigate(`/quiz-page/${id}/quiz/end`)
        }
    }, [quizeResults])

    const restartQuize = async () => {
        const res = await dispatch(сlearQuizResultsbyQuizIdFeth(id)).unwrap()
        if (res) {
            navigate(`/quiz-page/${id}/quiz`)
        }
    }
    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
      };
    const submitHandler = async (e, skip = false) => {
        const answerData = {
            skipped: skip
        }
        if (!skip) answerData.answerId = currentAnswerId

        if (!skip && !answerData.answerId) {
            setErrors(prev => ({
                ...prev,
                submit: 'Select your answer first'
            }))
            return
        }
        const res = await dispatch(addQuizResultByQuestionIdFeth({ id: currentQuestion.id, body: answerData })).unwrap()
        if (res) {

            setCurrentAnswerId(null)
            if (res.data.completedQuestions === res.data.totalQuestions) {
                navigate(`/quiz-page/${id}/quiz/end`)
            }
        }
    }

    return (
        <main className="content">
            <div className="quiz">
                <QuizeStepsQuestions data={questions} activeIndex={quizeResults?.completedQuestions} />
                <div className="quiz__content">
                    {timeLeft !== null && 
                        <div className='pt-2 text-center'>
                            Time left: &nbsp;
                            <span className={`font-medium ${timeLeft < 60 ? 'color-danger' : ''}`}>
                                {formatTime(timeLeft)}
                            </span>
                        </div>}
                    <div className="quiz__body pt-[60px] pb-[60px]">
                        <div className="container full-center">
                            <div className='mb-[60px] flex flex-col gap-2'>
                                <h2 className="quiz__text">{quizeResults?.completedQuestions + 1}. {currentQuestion?.question_text}</h2>

                                {currentQuestion?.question_image && <img src={getImageSrc(currentQuestion?.question_image)} alt="" />}
                            </div>
                            <div className="quiz-answers-list w-full mb-[60px]">
                                {currentQuestion?.answers && currentQuestion.answers.map(answer => (
                                    <RadioButtonQuiz
                                        name={currentQuestion?.question_text}
                                        value={currentQuestion?.answer_text}
                                        key={answer?.id}
                                        onChange={() => {
                                            setErrors(prev => ({
                                                ...prev,
                                                submit: false
                                            }))
                                            setCurrentAnswerId(answer?.id)
                                        }}
                                    >
                                        <span className="quiz-answer-item__text flex-col">
                                            <span className="quiz-answer-item__id">{answer?.answer_text}</span>
                                            {answer?.answer_image && <span>{<img src={getImageSrc(answer?.answer_image)} alt="" />}</span>}
                                        </span>
                                    </RadioButtonQuiz>
                                ))}
                                {erros.submit ?
                                    <p className='color-danger text--xs mt-1'>{erros.submit}</p> : null
                                }
                            </div>
                            <div className="group gap--xs w-full">
                                <div className="flex items-center flex-auto gap--xs">
                                    <button type='button' onClick={submitHandler} className="flex-auto md:flex-none btn btn--primary btn--md radius-5 gap--xs">
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.3332 5.9585L8.24984 16.0418L3.6665 11.4585" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                        <span className="info">Submit</span>
                                    </button>
                                    <button type='button' onClick={(e) => submitHandler(e, true)} className="flex-auto md:flex-none btn btn--shadow btn--md radius-5">Skip Question</button>
                                </div>
                                <button type='button' onClick={restartQuize} className="flex-auto md:flex-none btn btn--shadow btn--md radius-5 gap--xs">
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.864 4.25244V7.49377H14.6235H17.864" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M17.4413 7.49359C16.1973 5.21384 13.7792 3.6665 10.9998 3.6665C6.95 3.6665 3.6665 6.95 3.6665 10.9998C3.6665 15.0497 6.95 18.3332 10.9998 18.3332C15.0497 18.3332 18.3332 15.0497 18.3332 10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <span className="info">Restart Quiz</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TimerEndModal 
                open={isModalOpen} 
                handelClose={() => setIsModalOpen(false)} 
                blockCloseBg={true} 
                restartQuize={restartQuize} 
            />
        </main>
    )
}

export default QuizPageQuestions