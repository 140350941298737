const usersBase = '/api/quizzes';

export const quizzes = (instance) => ({
    async getAllQuizzes({ query }) {
        try {
            const { data } = await instance.get(`${usersBase}?${query}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getQuizGeneralByQuizId(id) {
        try {
            const { data } = await instance.get(`${usersBase}/general/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getQuizMediaByQuizId(id) {
        try {
            const { data } = await instance.get(`${usersBase}/media/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getQuizQuestionsByQuizId(id) {
        try {
            const { data } = await instance.get(`${usersBase}/questions/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getQuizDiscussionByQuizId({ id, qwery }) {
        try {
            const { data } = await instance.get(`${usersBase}/discussions/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createQuiz(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/general`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addQuizMediaFilesByQuizId({ id, body }) {
        try {
            const { data } = await instance.post(`${usersBase}/media/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addQuizHintFilesByQuizId({ id, body }) {
        try {
            const { data } = await instance.post(`${usersBase}/hints/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addQuizQuestionsByQuizId({ id, body }) {
        try {
            const { data } = await instance.post(`${usersBase}/questions/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addQuizSettingsByQuizId({ id, body }) {
        try {
            const { data } = await instance.post(`${usersBase}/settings/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async toggleFavoriteQuizById(id) {
        try {
            const { data } = await instance.post(`${usersBase}/favorite/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async toggleLikeQuizById(id) {
        try {
            const { data } = await instance.post(`${usersBase}/like/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async viewQuizById(id) {
        try {
            const { data } = await instance.post(`${usersBase}/view/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editQuizById({ id, body }) {
        try {
            const { data } = await instance.put(`${usersBase}/general/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editQuizMediaFilesByQuizId({ id, body }) {
        try {
            const { data } = await instance.put(`${usersBase}/media/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editQuizHintFilesByQuizId({ id, body }) {
        try {
            const { data } = await instance.put(`${usersBase}/hints/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editQuizQuestionsByQuestionId({ id, body }) {
        try {
            const { data } = await instance.put(`${usersBase}/questions/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editQuizSettingsById({ id, body }) {
        try {
            const { data } = await instance.put(`${usersBase}/settings/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteQuizQuestionsByQuestionId(id) {
        try {
            const { data } = await instance.delete(`${usersBase}/questions/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addQuizQuestionsOrderingByQuizId({ id, body }) {
        try {
            const { data } = await instance.post(`${usersBase}/questions/ordering/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addQuizResultByQuestionId({ id, body }) {
        try {
            const { data } = await instance.post(`${usersBase}/results/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async сlearQuizResultsbyQuizId(id) {
        try {
            const { data } = await instance.delete(`${usersBase}/results/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getQuizResultsByQuizId(id) {
        try {
            const { data } = await instance.get(`${usersBase}/results/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getQuizHintsByQuizId(id) {
        try {
            const { data } = await instance.get(`${usersBase}/hints/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async delQuizById(id) {
        try {
            const { data } = await instance.delete(`${usersBase}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});