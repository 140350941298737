import React, { useEffect, useState } from 'react'
import InputGroup from '../../components/UI/formElements/InputGroup'
import RadioDefault from '../../components/UI/formElements/RadioDefault'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import PaymantMethodForm from './parts/PaymantMethodForm';
import { useDispatch } from 'react-redux';
import { ReactComponent as DeleteIcon } from '../../assets/img/delete-Icon.svg';
import { assignDefaultPaymentMethodByMethodIdFeth, deletePaymentMethodByMethodIdFeth, getCustomerExistedPaymentMethodsFeth } from '../../store/paymant/paymantThunk';
import { useSelector } from 'react-redux';
import PageLoader from '../../components/parts/PageLoader';
const stripePromise = loadStripe('pk_test_51PhmnU2NXaTKKpTl0SqJWAi0pzKUupBA7aAC9AC7XYU08RWyauzA3MsDEDUlwcaTF9iqGNOE1gX3ksA15VyiU23j00lBpEwYIC');
const PaymantMethods = () => {
    const dispatch = useDispatch()
    const [contactEmail, setContactEmail] = useState('saved-email');
    const newEmailHandler = ({ target }) => {
        setContactEmail(target.value);
    }
    const { methods, isLoading } = useSelector(state => state.paymant)

    const deletePaymant = async (id) => {
        const res = dispatch(deletePaymentMethodByMethodIdFeth(id)).unwrap()
    }
    const asyngDefault = async (id) => {
        const res = dispatch(assignDefaultPaymentMethodByMethodIdFeth(id)).unwrap()
    }
    useEffect(() => {
        dispatch(getCustomerExistedPaymentMethodsFeth())
    }, [])
    if (isLoading) return <PageLoader />
    return (
        <div className="tabs-item active-tab" id="billings">
            <div className="col-group col-group--border gap--xl">
                <div className="row-group justify-between gap--md">
                    <div className="col-group gap--md-2 flex-auto">
                        <h4 className="text--lg">Payment methods</h4>
                        {/* <p className="color-secondary">Update your billing details and address.</p> */}
                        <div className="col-group gap--md-2 flex-auto">
                            <div className="flex w-full">
                                <div className="table-wrapper w-full">
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th className='w-[10px]'>
                                                    Type
                                                </th>
                                                <th className='text-left'>
                                                    Card number
                                                </th>
                                                <th className='text-left'>
                                                    Month / Year
                                                </th>
                                                <th className='text-left w-[10px]'>
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {methods.map((item, index) => (
                                                <tr key={index}>
                                                    <td  >
                                                        {item.brand === 'visa' && <img src={require("../../assets/img/Visa.svg").default} width="32" alt="" />}
                                                        {item.brand === 'mastercard' && <img src={require("../../assets/img/MasterCard.svg").default} width="32" alt="" />}
                                                    </td>
                                                    <td>
                                                        •••• •••• •••• {item.last4}
                                                    </td>
                                                    <td>
                                                        {`${item.exp_month} / ${item.exp_year}`}
                                                    </td>
                                                    <td>
                                                        <div className="row-group gap--xs">
                                                            {item.defaultCard ?
                                                                <span className='btn btn--secondary btn--sm radius-5 flex-auto pointer-events-none'>
                                                                    Сurrent
                                                                </span>
                                                                :

                                                                <button type='button' onClick={() => asyngDefault(item.id)} className="btn btn--primary btn--sm radius-5 flex-auto">
                                                                    <span className="info text--md">Setup</span>
                                                                </button>}
                                                            <button type='button' onClick={() => {deletePaymant(item.id)}} className="btn btn--transparent btn--sm radius-5  flex-1">
                                                                <span className="ico">
                                                                    <DeleteIcon />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Elements stripe={stripePromise}>
                    <PaymantMethodForm />
                </Elements>
                {/* <div className="col-group">
                    <div className="row g-24">
                        <div className="col-md-6">
                            <div className="col-group gap--md-2">
                                <h4 className="text--lg">Contact Email</h4>
                                <p className="color-secondary">Where should invoices be sent?</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-group gap--md-2">
                                <RadioDefault
                                    name={"contact_email"}
                                    value="saved-email"
                                    defaultChecked={true}
                                    onChange={newEmailHandler}
                                >
                                    <div className="w-full">
                                        <p className="text--sm mb-[6px]">Send to the existing email</p>
                                        <p className="color-secondary text--xs">Nur.Alam@mail.com</p>
                                    </div>
                                </RadioDefault>
                                <RadioDefault
                                    wrapperClasses={'items-center'}
                                    name={"contact_email"}
                                    value="new-email"
                                    onChange={newEmailHandler}
                                >
                                    <div className="w-full">
                                        <p className="text--sm">Add another email address</p>
                                    </div>
                                </RadioDefault>
                               {contactEmail != 'saved-email' && <InputGroup
                                    wrapperClasses='input--lg'
                                    label={'New email address'}
                                    inputClasses='input--outline'
                                    placeholder={'New Email'}
                                />}
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="row-group flex-wrap justify-between gap--sm">
                    <a href="#" className="btn w-full md:w-auto md:min-w-[250px] btn--primary btn--lg radius-5">
                        <span className="text--md font-medium">Save Changes</span>
                    </a>
                    <a href="#" className="btn w-full md:w-auto md:min-w-[250px] btn--secondary-2 btn--lg radius-5">
                        <span className="text--md font-medium">Cancel</span>
                    </a>
                </div> */}
            </div>
        </div>
    )
}

export default PaymantMethods
