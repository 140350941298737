import { formatDistanceToNow, formatRelative } from 'date-fns'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getFullName, getImageSrc } from '../../../helpers/utils'
import { getMe, setShowLoginModal } from '../../../store/auth/authSlice';

const CardThread = ({ data }) => {
    const dispatch = useDispatch()
    const me = useSelector(getMe)


    const handlePreventAccess = (e) => {
        if (!me) {
            e.preventDefault()
            dispatch(setShowLoginModal(true))
        }
    }
    // const relativeDate = formatRelative(data.createdAt, new Date())
    const relativeDate = formatDistanceToNow(data.createdAt)

    const pillsMap = {
        "Chat": "--pill-st-1",
        "Discussion": "--pill-st-6",
        "Feedback": "--pill-st-2",
        "News": "--pill-st-3",
        "Help": "--pill-st-4",
        "Question": "--pill-st-5",
    }

    return (
        <div className="card-thread border-y">
            {
                data.cover && (
                    <Link to={`/forums/${data.forumId}/thread/${data.id}`} onClick={handlePreventAccess} className="card-thread__image image-wrapper">
                        <img src={getImageSrc(data.cover)} alt="" />
                    </Link>
                )
            }
            <div className="card-thread__body gap--md-2">
                <div className="col-group gap--xs">
                    <div className="row-group row-dots">
                        <p className="text--md gap--xxs">
                            <span>by</span>
                            <Link to={`/profile/${data?.user?.id}`} onClick={handlePreventAccess}>
                                <span className="color-danger">{getFullName(data.user?.firstName, data.user?.lastName)}</span>
                            </Link>
                        </p>
                        <p className="mini-post--desk font-medium">{relativeDate} ago</p>
                    </div>
                    <Link to={`/forums/${data.forumId}/thread/${data.id}`} onClick={handlePreventAccess}>
                        <h4 className="text--lg">
                            {data.title}
                        </h4>
                    </Link>
                </div>
                <div className="row-group gap--lg">
                    <div className={`pill-custom pill-custom-2 ${pillsMap[data.filter?.name] || "--pill-st-1"}`}>
                        <span className="pill-text">{data.filter?.name}</span>
                    </div>
                    <span className="row-group gap--xs color-secondary">
                        <span className="ico">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.08 3.3335C15.7258 3.3335 17.5 5.81683 17.5 8.12933C17.5 12.8235 10.1342 16.6668 10 16.6668C9.86583 16.6668 2.5 12.8235 2.5 8.12933C2.5 5.81683 4.27417 3.3335 6.92 3.3335C8.4325 3.3335 9.42583 4.08766 10 4.75933C10.5742 4.08766 11.5675 3.3335 13.08 3.3335Z" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="text--sm">{data.likes}</span>
                    </span>
                    <span className="row-group gap--xs color-secondary">
                        <span className="ico">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.20703 12.0832H11.7904" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M6.66797 9.37516H13.3346" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.20703 6.66667H11.7904" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M17.4989 9.40918C17.4989 13.1917 14.1256 16.2275 9.99891 16.2275C9.46474 16.2275 8.94474 16.175 8.44141 16.0783" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M5.49917 14.855C3.68333 13.6133 2.5 11.645 2.5 9.40918" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M5.4984 14.855C5.49756 15.5666 5.49923 16.5266 5.49923 17.5341" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M2.5 9.4093C2.5 5.6268 5.87333 2.5918 10 2.5918C14.1267 2.5918 17.5 5.62763 17.5 9.41013" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.44167 16.0752L5.5 17.5335" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="text--sm">{data.comments}</span>
                    </span>
                    <span className="row-group gap--xs color-secondary">
                        <span className="ico">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.74219 10.9083C6.30302 5.03578 13.6972 5.03578 18.258 10.9083" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M12.8587 7.76485C14.3231 9.22932 14.3231 11.6037 12.8587 13.0682C11.3942 14.5326 9.01985 14.5326 7.55538 13.0682C6.09091 11.6037 6.09091 9.22932 7.55538 7.76485C9.01985 6.30039 11.3942 6.30039 12.8587 7.76485" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="text--sm">{data.views}</span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CardThread