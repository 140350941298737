import React, { useEffect, useState } from 'react'
import { CreateFormHeader } from '../../components/parts/CreateFormHeader'
import UploadImage from '../../components/UI/UploadImage'
import SelectGroupRow from '../../components/UI/formElements/SelectGroupRow'
import CheckBoxGroup from '../../components/UI/formElements/CheckBoxGroup'
import { categoryOptions } from '../../helpers/constants'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { getFormData, getImageSrc } from '../../helpers/utils'
import { getFilteres } from '../../store/filters/filtersSlice'
import TextareaGroupRow from '../../components/UI/formElements/TextareaGroupRow'
import { addSettingsToArticleByArticleIdFeth, editArticleSettingsByArticleIdFeth, getRelatedQuizzesListFeth, getUsersListFeth } from '../../store/articles/articlesThunks'
import Loader from '../../components/UI/Loader'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getMe, setHasEditChanges } from '../../store/auth/authSlice'
import useDebounce from '../../hooks/useDebounce'
import BallonEditor from '../../components/parts/BallonEditor'
import SelectGroupRowAsynk from '../../components/UI/formElements/SelectGropuRowAsynk'
const LIMIT = 10
const ArticleCreateSettings = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(getMe)
    const {
        articleId,
        article,
        isLoading,
    } = useSelector(state => state.articles)
    const systems = useSelector(getFilteres);
    const [settingsData, setSettingsData] = useState({
        enableComments: false,
        showRelatedArticles: false,
        displayAuthorName: false,
        isAnatomy: false,
        industry: null,
        cover: null,
        quizIds: [],
        references: '',
        authorIds: [],
        editorIds: [],
    })
    const loadOptionsModerators = async (search, loadedOptions, { page }) => {
        const res = await dispatch(getUsersListFeth(`page=${page}&limit=${LIMIT}&search=${search}`)).unwrap();
        console.log(page , res.pagination?.maxPages)
        return {
            options: res.data?.map(moder => ({ label: `${moder.firstName} ${moder.lastName}`, value: moder.id })),
            hasMore: page !== res.pagination?.maxPages, // флаг, показывающий, есть ли еще данные
            additional: {
                page: page + 1, // увеличиваем номер страницы для следующего запроса
            },
        };
    };
    const loadOptionsQuizs = async (search, loadedOptions, { page }) => {
        const res = await dispatch(getRelatedQuizzesListFeth(`page=${page}&limit=${LIMIT}&search=${search}`)).unwrap();

        return {
            options: res.data?.map(quiz => ({ label: quiz.name, value: quiz.id })),
            hasMore: page !== res?.pagination?.maxPages, // флаг, показывающий, есть ли еще данные
            additional: {
                page: page + 1, // увеличиваем номер страницы для следующего запроса
            },
        };
    };
    const categotyes = systems ? systems.map(item => ({ value: item?.name, label: item?.name })) : []

    useEffect(() => {
        if (article?.settings) {
            setSettingsData({
                enableComments: article.enableComments,
                showRelatedArticles: article.showRelatedArticles,
                displayAuthorName: article.displayAuthorName,
                isAnatomy: article?.isAnatomy,
                industry: article.filter?.name,
                cover: article?.cover && article?.cover !== 'null' && article?.cover != "undefined" ? {
                    preview: getImageSrc(article?.cover),
                    type: `image/${article?.cover.split('.').pop()}`
                } : null,
                quizIds: article.quizzes,
                authorIds: article.authors?.map(item => ({ id: item.id, name: item.firstName + " " + item.lastName })) || [],
                editorIds: article.editors?.map(item => ({ id: item.id, name: item.firstName + " " + item.lastName })) || [],
                references: article.references
            })
        }
    }, [article])

    const formDataHandler = (name, value) => {
        dispatch(setHasEditChanges(true))
        if (name === 'quizIds') {
            setSettingsData(prev => ({
                ...prev,
                [name]: value.map(option => ({
                    id: option.value,
                    name: option.label
                }))
            }))
        } else {
            setSettingsData(prev => ({
                ...prev,
                [name]: value
            }))
        }

    }
    const conf = {
        title: 'Settings',
    }
    useEffect(() => {
        dispatch(getFiltersByName('industry'))
    }, [dispatch])

    const submitHandler = async () => {
        if (!settingsData.industry) {
            toast.error('Category is required field')
            return
        }
        if (!settingsData.cover) {
            toast.error('Article Cover is required field')
            return
        }
        const newData = {
            ...settingsData,

            quizIds: settingsData.quizIds.map(item => item.id),
            authorIds: settingsData.authorIds.map(item => item.id),
            editorIds: settingsData.editorIds.map(item => item.id)
        }
        delete newData.cover
        if (settingsData.cover instanceof File) {
            newData['cover'] = settingsData.cover
        }
        const formData = getFormData(newData)
        let res = null
        if (article.settings) {
            res = await dispatch(editArticleSettingsByArticleIdFeth({ id: articleId, body: formData })).unwrap()
        } else {
            res =  await dispatch(addSettingsToArticleByArticleIdFeth({ id: articleId, body: formData })).unwrap()
        }
        if (res) {
            if (id) {
                navigate(`/article-edit/${id}/preview`)
            } else {
                navigate(`/article-create/preview`)
            }
            dispatch(setHasEditChanges(false))
        }
    }
    if (isLoading) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader />
        </div>
    )

    if (user?.id !== article?.user?.id) {
        return null
    }


    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz create-article pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters pt-[20px]">
                                        <div className="col-12">
                                            <div className="group items-center gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Article Cover</p>
                                                <UploadImage
                                                    onChange={(image) => {
                                                        formDataHandler('cover', ...image)
                                                    }}
                                                    defaulImages={settingsData.cover && settingsData.cover !== 'null' ? [settingsData.cover] : []}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Category'}
                                                placeholder={'Select category'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                options={categotyes}
                                                value={{ value: settingsData.industry, label: settingsData.industry }
                                                }

                                                onChange={(option) => { formDataHandler('industry', option.value) }}

                                            />

                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRowAsynk
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Related quizzes'}
                                                placeholder={'Select Related quizzes'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                isMulti={true}
                                                value={settingsData?.quizIds?.map((item) => {
                                                    return { value: item.id, label: item.name }
                                                })}
                                                onChange={(option) => {
                                                    formDataHandler('quizIds', option)
                                                }}
                                                fetchFunk={loadOptionsQuizs}
                                            />
                                        </div>
                                        <div className="col-12">
                                            {/* <TextareaGroupRow
                                                label={'References'}
                                                placeholder={"Enter References..."}
                                                textareaClasses="input--outline"
                                                onChange={({ target }) => formDataHandler('references', target.value)}
                                                value={settingsData.references}
                                                rows="7"
                                            /> */}
                                            <div className="form-group-row">
                                                <span className="form-group-row__label justify-end">References</span>
                                                <div className="list-group list-group--xs flex-auto">
                                                    <div className="input-wrapper  input--md flex">
                                                        <div className="input--outline rounded-[6px] w-full">
                                                            <BallonEditor
                                                                editorData={settingsData.references}
                                                                placeholder={'Enter References...'}
                                                                setData={(data) => formDataHandler('references', data)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRowAsynk
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Authors'}
                                                placeholder={'Select authors'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                isMulti={true}
                                                value={settingsData?.authorIds?.map((item) => ({ value: item.id, label: item.name }))}
                                                onChange={(options) => {
                                                    formDataHandler('authorIds', options.map(option => ({
                                                        id: option.value,
                                                        name: option.label
                                                    })))
                                                }}
                                                fetchFunk={loadOptionsModerators}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRowAsynk
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Editors'}
                                                placeholder={'Select editors'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                isMulti={true}
                                                value={settingsData?.editorIds?.map((item) => ({ value: item.id, label: item.name }))}
                                                onChange={(options) => {
                                                    formDataHandler('editorIds', options.map(option => ({
                                                        id: option.value,
                                                        name: option.label
                                                    })))
                                                }}
                                                fetchFunk={loadOptionsModerators}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="container container-1440">
                                    <div className="border-bottom mb-[36px] pb-[36px]"></div>
                                </div>
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right pt-[8px]">Options</p>
                                                <div className="checkboxes-list">
                                                    <CheckBoxGroup
                                                        checked={settingsData.isAnatomy}
                                                        onChange={(e) => {
                                                            formDataHandler('isAnatomy', e.target.checked)
                                                        }}
                                                    >
                                                        <p className="check-text">
                                                            Add to Anatomy section
                                                        </p>
                                                    </CheckBoxGroup>
                                                    <CheckBoxGroup
                                                        checked={settingsData.enableComments}
                                                        onChange={(e) => {
                                                            formDataHandler('enableComments', e.target.checked)
                                                        }}
                                                    >
                                                        <p className="check-text">
                                                            Enable comments for the article
                                                        </p>
                                                    </CheckBoxGroup>
                                                    <CheckBoxGroup
                                                        checked={settingsData.showRelatedArticles}
                                                        onChange={(e) => {
                                                            formDataHandler('showRelatedArticles', e.target.checked)
                                                        }}
                                                    >
                                                        <p className="check-text">
                                                            Show links to related articles at the end of the article
                                                        </p>
                                                    </CheckBoxGroup>
                                                    <CheckBoxGroup
                                                        checked={settingsData.displayAuthorName}
                                                        onChange={(e) => {
                                                            formDataHandler('displayAuthorName', e.target.checked)
                                                        }}
                                                    >
                                                        <p className="check-text">
                                                            Display the name of the author(s)
                                                        </p>
                                                    </CheckBoxGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/articles'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>

                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4173 10.9998H4.58398" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.834 15.5833L17.4173 11" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.834 6.4165L17.4173 10.9998" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </button>


                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default ArticleCreateSettings
