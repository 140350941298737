import React, { useEffect, useRef, useState } from 'react'
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks'
import SingleActions from '../../components/parts/SingleActions'
import BreadCrambs from '../../components/parts/BreadCrambs'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import PageLoader from '../../components/parts/PageLoader'
import { AddMaterialModal } from '../../components/Modals/AddMaterialModal'
import { delMaterialLibraryByIdFeth, favoriteMaterialLibraryByidFeth, getMaterialLibraryByIdFeth, likeMaterialLibraryByIdFeth, viewMaterialLibraryByIdFeth } from '../../store/materials/materialLibraryThunks'
import { getDateToString, getImageSrc } from '../../helpers/utils'
import { ReactComponent as PlayIcon } from "../../assets/img/play.svg"
import SidebarRelatedVideos from '../../components/parts/sidebarPosts/SidebarRelatedVideos'
import { setMaterialId } from '../../store/materials/materialLibrarySlice'
import ReactPlayer from 'react-player'
import DeleteModal from '../../components/Modals/DeleteModal'
const VideoSingle = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState(false)
    const { material, isLoading } = useSelector(state => state.materialLibrary)
    const [addMaterialModal, setAddMaterialModal] = useState(false)
    const videoRef = useRef(null);
    const playButtonRef = useRef(null);
    const addModalHandler = () => {
        setAddMaterialModal(prev => !prev)
    }

    const helpLinks = [
        {
            title: 'FAQ',
            url: '/faq'
        },
        {
            title: 'Event Calendar',
            url: '/event-calendar'
        },
        {
            title: 'Pricing Plan',
            url: '/pricing-plan'
        },
        {
            title: 'About Us',
            url: '/about-us'
        },
        {
            title: 'Contact Us',
            url: '/contact-us'
        },
    ]
    const breadcramds = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/materials',
            label: 'Material Library'
        },
        {
            link: '/materials/image/:id',
            label: material?.title
        },
    ]
    const toggleLike = () => {
        dispatch(likeMaterialLibraryByIdFeth(id))

    }
    const toggleFavorite = () => {
        dispatch(favoriteMaterialLibraryByidFeth(id))
    }
    const deleteHandler = async () => {
        const res = await dispatch(delMaterialLibraryByIdFeth(id)).unwrap()
        if (res) {
            navigate('/materials')
        }
    }
    const editHandler = () => {

        dispatch(setMaterialId(id))
        setAddMaterialModal(true)
    }
    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            playButtonRef.current.style.display = 'none';
        }
    };

    const handlePause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            playButtonRef.current.style.display = 'block';
        }
    };
    useEffect(() => {
        if (id) {
            dispatch(getMaterialLibraryByIdFeth(id))
            dispatch(viewMaterialLibraryByIdFeth(id))
        }
    }, [id, dispatch])
    if (isLoading) return <PageLoader />
    return (
        <main className="content">
            <BreadCrambs breabcrambs={breadcramds} />
            <section className="page-section">
                <div className="container">

                    <div className="page-section--row">
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    {material?.relatedMaterials && material?.relatedMaterials.length > 0 ? <SidebarRelatedVideos
                                        videos={material?.relatedMaterials}
                                        title={'Related Videos'}
                                    />
                                        : null}
                                </div>
                            </aside>
                        </div>
                        <div className="page-content">
                            <article className="single-post">
                                <div>
                                    <div className="pill-custom pill-custom-2">
                                        <span className="pill-text">{material?.filter?.name}</span>
                                    </div>
                                    <h2>
                                        {material?.title}
                                    </h2>
                                    <SingleActions
                                        likes={material?.likes}
                                        user={material?.user}
                                        displayUser={true}
                                        createAt={material?.updatedAt}
                                        toggleLike={toggleLike}
                                        toggleFavorite={toggleFavorite}
                                        isLikeProps={material?.isLiked}
                                        isFavoriteProps={material?.isFavorite}
                                        handleDeleteButton={() => setDeleteModal(true)}
                                        editButtonHandler={editHandler}
                                    />
                                    <div>
                                        <div className="video-wrapper">
                                            {/* <div className="btn-play" ref={playButtonRef} onClick={handlePlay}>
                                                <button className="quiz-video__button btn btn--square btn--xl rounded-full btn--shadow">
                                                    <PlayIcon />
                                                </button>
                                            </div>
                                            <video
                                                ref={videoRef}
                                                src={getImageSrc(material?.fileName)}
                                                controls
                                                className="video video-md"
                                                onPlay={handlePlay}
                                                onPause={handlePause}
                                            /> */}
                                            <ReactPlayer
                                                playing={true}
                                                url={getImageSrc(material?.fileName)}
                                                playIcon={<span className="quiz-video__button btn btn--square btn--xl rounded-full btn--shadow">
                                                    <PlayIcon />
                                                </span>}
                                                controls
                                                light={
                                                    <video
                                                        src={getImageSrc(material?.fileName)}
                                                    />
                                                }
                                                className="!w-full"
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            controlsList: 'nodownload'
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {material?.description}
                                </div>
                            </article>
                        </div>
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    <SidebarHelpLinks
                                        title={'Useful links'}
                                        links={helpLinks}
                                    />
                                </div>
                            </aside>
                        </div>
                    </div>

                </div>
            </section>
            {addMaterialModal && <AddMaterialModal editModal={true} title={`Edit video`} uploadType={'video'} open={addMaterialModal} handleClose={addModalHandler} setDeleteModal={() => setDeleteModal(true)}/>}
            {deleteModal && <DeleteModal open={deleteModal} handelClose={() => setDeleteModal(false)} title={'video'} deleteHandler={deleteHandler} />}
        </main>
    )
}

export default VideoSingle
