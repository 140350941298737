import React from 'react'
import { Link } from 'react-router-dom'
import { getImageSrc } from '../../../helpers/utils'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getAuthorized, setShowLoginModal } from '../../../store/auth/authSlice'

const SidebarRecomendedCourses = ({courses, title, postImageBig = false, hideIcon = false}) => {
    const dispatch = useDispatch()
    const authorized = useSelector(getAuthorized)
    const protectLink = (e) => {
        if (!authorized) {
            e.preventDefault()
            dispatch(setShowLoginModal(true))
        }
    }
    return (
        <div className="post-widget">
            <div className="post-widget--header">
                <div className="heading-post-row">
                    {!hideIcon && <span className="ico ico--xs">
                        <img src={require("../../../assets/img/ico-cources.svg").default} alt='post' />
                    </span>}
                    <p className="heading-title-post text-def-2-c">
                        {title}
                    </p>
                </div>
            </div>
            <div className="post-widget--body flex flex-col">
                <ul className="mini-posts-list flex flex-col">
                    {courses.map(course => (
                        <li key={course.id}>
                            <Link 
                            to={`/courses/${course?.id}`} 
                            onClick={protectLink} 
                            className={`mini-post ${postImageBig ? '' : 'mini-post--image-sm'}`}
                        >
                                <div className="wrapp-image mini-post-img">
                                    <img src={getImageSrc(course?.cover)} alt='post' />
                                </div>
                                <div className="mini-post-content">
                                    <p className="mini-post--desk">{course?.filter?.name}</p>
                                    <p className="mini-post--title">
                                        {course?.name}
                                    </p>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
                <Link to={'/courses'} className="btn max-w-max">
                    <span className="color-danger text-[13px] font-[500]">
                        See more courses
                    </span>
                </Link>
            </div>
        </div>
    )
}

export default SidebarRecomendedCourses
