import EmojiPicker from 'emoji-picker-react';
import React, { useRef, useState } from 'react'

import Linkify from 'react-linkify';
import { useDispatch } from 'react-redux';
import { sendMessageByConversationIdFeth } from '../../../store/chat/chatThunk';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
const linkify = require("linkifyjs");


const SendMessageForm = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [links, setLinks] = useState([])
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const { currentChatData } = useSelector(state => state.chat)
    // const [value, setValue] = useState('')
    const textareaRef = useRef(null);
    const handleFileChange = (e) => {
        const maxSizeInMB = 25;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    
        const selectedFiles = Array.from(e.target.files);
        const validFiles = selectedFiles.filter(file => file.size <= maxSizeInBytes);
    
        if (validFiles.length !== selectedFiles.length) {
            toast.warn(`Some files exceed the maximum size of ${maxSizeInMB} MB and were not added.`);
        }
    
        setFiles([...files, ...validFiles]);
    };
    const handleFileRemove = (fileToRemove) => {
        setFiles(files.filter(file => file !== fileToRemove));
    };

    const inputHandler = ({ target }) => {
        const extractedLinks = linkify.find(target.value).map(link => link.href);
        setLinks(extractedLinks);
        setMessage(target.value)

    }

    const handleSend = async (e) => {
        e.preventDefault()
        if(currentChatData.conversation.isBlocked) {
            toast.error('This user has blocked you')
            return
        }
        if (message.trim().length === 0 && files.length === 0) {
            return
        }

        const body = new FormData()
        body.append('content', message)
        files.forEach(file => {
            body.append('files', file)
        })
        body.append('links', JSON.stringify(links))
        const res = dispatch(sendMessageByConversationIdFeth({ id: id, body })).unwrap()
        if (res) {
            setMessage('');
            setFiles([]);
            setLinks([])
        }

    };

    const handleEmojiClick = (emoji) => {
        const { selectionStart, selectionEnd } = textareaRef.current;
        const textBefore = message.substring(0, selectionStart);
        const textAfter = message.substring(selectionEnd);
        const newMessage = textBefore + emoji.emoji + textAfter;

        setMessage(newMessage);
        textareaRef.current.focus();
        textareaRef.current.selectionStart = textareaRef.current.selectionEnd = selectionStart + emoji.emoji.length;
        setShowEmojiPicker(false);
    };
    return (
        <div className="chart-block__footer">
            <form className="chat-send-message-form" >
                <textarea
                    ref={textareaRef}
                    value={message}
                    onChange={inputHandler}
                    className="chat-send-message-form__text"
                    placeholder="Send a message"
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault()
                          handleSend(event)
                        }
                      }
                    }
                ></textarea>

                <div className="chat-send-message-form__actions">
                    <label className="btn btn--transparent p-1 radius-5">
                        <input type="file"
                            onChange={handleFileChange}
                            hidden={true}
                            multiple
                        />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.58269 15.4165L8.18971 16.8095C7.74768 17.2515 7.14816 17.4998 6.52304 17.4998C5.89792 17.4998 5.2984 17.2515 4.85637 16.8095V16.8095C4.41435 16.3674 4.16602 15.7679 4.16602 15.1428C4.16602 14.5177 4.41435 13.9182 4.85637 13.4761L11.666 6.6665" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M15.4586 9.54142V9.54142C17.0694 7.9306 17.0694 5.31895 15.4586 3.70814V3.70814C14.685 2.93458 13.6359 2.5 12.5419 2.5C11.4479 2.5 10.3988 2.93458 9.62525 3.70814L2.5 10.8333" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M10.834 12.5002H14.1673" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M12.5007 10.8335V14.1668" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </label>
                    <div className="emoji-wrapper">
                        <button type='button' onClick={() => setShowEmojiPicker(!showEmojiPicker)} className="btn btn--transparent p-1 radius-5">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.83398 8.33343C6.16315 7.92926 6.61315 7.7001 7.08398 7.7001C7.55482 7.7001 7.99232 7.92926 8.33398 8.33343" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M11.666 8.33343C11.9952 7.92926 12.4452 7.7001 12.916 7.7001C13.3868 7.7001 13.8243 7.92926 14.166 8.33343" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62562 18.2322 4.6967 15.3033C1.76777 12.3744 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M12.9173 12.2402C12.9173 12.2402 11.8232 13.3336 10.0007 13.3336C8.17732 13.3336 7.08398 12.2402 7.08398 12.2402" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </button>
                        {showEmojiPicker && (
                            <div className="emoji-picker-container">
                                <EmojiPicker onEmojiClick={handleEmojiClick} />
                            </div>
                        )}
                    </div>
                    <button type="submit" onClick={handleSend} className="btn ml-auto btn--transparent p-1 radius-5">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.56755 11.3557L8.40161 10.1888C8.92255 10.0848 8.92255 9.91541 8.40161 9.81135L2.56755 8.64447C2.22005 8.5751 1.88161 8.23635 1.81224 7.88916L0.645362 2.0551C0.540987 1.53385 0.848174 1.29166 1.33099 1.51447L19.1338 9.73104C19.4554 9.87947 19.4554 10.1207 19.1338 10.2692L1.33099 18.4857C0.848174 18.7085 0.540987 18.4663 0.645362 17.9451L1.81224 12.111C1.88161 11.7638 2.22005 11.4251 2.56755 11.3557Z" fill="#6F7D8C"></path>
                        </svg>
                    </button>
                </div>
                {files.length > 0 && (
                    <div className="selected-files">
                        {files.map((file, index) => (
                            <div className="user-messages__file" key={index}>
                                <div className="user-messages__file-icon">
                                    <img src={require("../../../assets/img/messages-file-icon.svg").default} alt="" />
                                </div>
                                <div className="user-messages__file-content">
                                    <div className="user-messages__file-name">{file.name}</div>
                                </div>
                                <button type='button' className='btn' onClick={() => { handleFileRemove(file) }}>
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.1875 4.8125L4.8125 17.1875" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.1875 17.1875L4.8125 4.8125" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        ))}

                    </div>
                )}
            </form>
        </div>
    )
}

export default SendMessageForm
