const authBase = 'api/auth';

export const auth = (instance) => ({
    async login(payload) {
        try {
            const { data } = await instance.post(`${authBase}/login`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async finalizeLogin(payload) {
        try {
            
            const { data } = await instance.post(`${authBase}/finalize-login`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async register(payload) {
        try {
            const { data } = await instance.post(`${authBase}/signup`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async logout() {
        try {
            const { data } = await instance.get(`${authBase}/logout`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async generateFa() {
        try {
            const { data } = await instance.post(`${authBase}/generate-2fa`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async verifyFa(payload) {
        try {
            const { data } = await instance.post(`${authBase}/verify-2fa`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async login2fa(payload) {
        try {
            const { data } = await instance.post(`${authBase}/login-2fa`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async forgotPassword(payload) {
        try {
            const { data } = await instance.post(`${authBase}/forgot-password`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async resetPassword(payload) {
        try {
            const { data } = await instance.post(`${authBase}/restore-password`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});