import React, { useState, useEffect, useRef } from 'react';


const useDebounce = (callback, delay) => {
    const callbackRef = useRef(callback);
    const timeoutRef = useRef();
  
    useEffect(() => {
      callbackRef.current = callback;
    }, [callback]);
  
    const debounce = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callbackRef.current(...args);
      }, delay);
    };
  
    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, [delay]);
  
    return debounce;
  };
  
  export default useDebounce;