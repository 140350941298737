const pagesBase = 'api/articles';

export const articles = (instance) => ({
    async getAllArticles(qwery) {
        try {
            const { data } = await instance.get(`${pagesBase}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getArticleById({id , qwery}) {
        
        try {
            const { data } = await instance.get(`${pagesBase}/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getRelatedQuizzesList (qwery) {
        try {
            const { data } = await instance.get(`${pagesBase}/quizzes-list?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getUsersList (qwery) {
        try {
            const { data } = await instance.get(`${pagesBase}/users-list?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getArticlesHistory ({id, qwery}) {
        try {
            const { data } = await instance.get(`${pagesBase}/history/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getArticlesVersiunsForApproval ({id, qwery}) {
        console.log('nen')
        try {
            const { data } = await instance.get(`${pagesBase}/versions/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createNewArticle (payload) {
        try {
            const { data } = await instance.post(`${pagesBase}`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async sendArticleToAuthorModeration (id) {
        try {
            const { data } = await instance.post(`${pagesBase}/author-moderation/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async sendArticleToAdminModeration (id) {
        try {
            const { data } = await instance.post(`${pagesBase}/moderation/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addSettingsToArticleByArticleId ({id, body}) {
        try {
            const { data } = await instance.post(`${pagesBase}/settings/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async likeArticleByArticleId (id) {
        try {
            const { data } = await instance.post(`${pagesBase}/like/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async viewArticleByArticleId (id) {
        try {
            const { data } = await instance.post(`${pagesBase}/view/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async toggleFavoriteArticleByArticleId (id) {
        try {
            const { data } = await instance.post(`${pagesBase}/favorite/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editArticleById ({id, body}) {
        try {
            const { data } = await instance.patch(`${pagesBase}/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async moderateArticleAuthor ({id, body}) {
        try {
            const { data } = await instance.patch(`${pagesBase}/author-moderate/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async authorPublishOwnChanges (id) {
        try {
            const { data } = await instance.patch(`${pagesBase}/authors/publish/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editArticleSettingsByArticleId ({id, body}) {
        try {
            const { data } = await instance.patch(`${pagesBase}/settings/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteArticleById (id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});