import React, { useEffect, useRef, useState } from 'react'
import { CreateFormHeader } from '../../components/parts/CreateFormHeader'
import SelectCommentModal from '../../components/Modals/SelectCommentModal'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { getImageSrc } from '../../helpers/utils';
import DrawingComponentTypeModam from '../../components/parts/DrawingComponentTypeModam';
import { ReactComponent as ArrowIcon } from "../../assets/img/chevron-prev.svg"
import { useDispatch } from 'react-redux';
import { addCaseCommentsfilesByCaseIdFeth, editCaseCommentFilesByCaseIdFeth } from '../../store/cases/casesThunk';
import Loader from '../../components/UI/Loader';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setHasEditChanges } from '../../store/auth/authSlice';

const CaseCreateComments = () => {
    const { id } = useParams()
    const [caseOne, caseId] = useOutletContext();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [selectedImage, setSelectedImages] = useState(null)
    // const [selectedHintsIndex, setSelectedHintsIndex] = useState(0)
    const [mobileImagesPicker, setMobileImagesPicker] = useState(true)
    const [selectedHintsIndexes, setSelectedHintsIndexes] = useState([])
    const [hints, setHints] = useState([])
    const { isLoading } = useSelector(state => state.cases)
    const drawingRef = useRef();

    const [selectedSet, setSelectedSet] = useState(null)
    const [selectedSetImages, setSelectedSetImages] = useState([])

    // const selectSetHandler = (imagesSet, index) => {
    //     const setCoverImage = imagesSet.images.filter(item => item.isCover)[0]
    //     setSelectedImages({
    //         preview: getImageSrc(setCoverImage.fileName)
    //     })
    //     setSelectedHintsIndex(index)
    // }
    const conf = {
        title: 'Comments',
    }
    // useEffect(() => {
    //     if (caseOne?.caseContent?.images && caseOne?.caseContent?.images?.length > 0) {
    //         const firsSet = caseOne?.caseContent?.images[0]
    //         const setCoverImage = firsSet.images.filter(item => item.isCover)[0]
    //         setSelectedImages({
    //             preview: getImageSrc(setCoverImage.fileName)
    //         })
    //         // setHints(caseOne?.caseContent?.images.map(imageSet => ({
    //         //     "caseContentMediaId": imageSet.images.find(image => image.isCover).id,
    //         //     "hintTexts": imageSet.commentTexts,
    //         //     "hintMarkers": imageSet.commentMarkers
    //         // })))
    //         setHints(caseOne?.caseContent?.images.map(imageSet => ({
    //             "id": imageSet.images.find(image => image.isCover).id,
    //             "hintTexts": imageSet.commentTexts,
    //             "hintMarkers": imageSet.commentMarkers
    //         })))
    //     }
    // }, [caseOne])

    useEffect(() => {
        if (caseOne?.caseContent?.images && caseOne?.caseContent?.images?.length > 0) {
            // const firsSet = caseOne?.caseContent?.images[0]
            // const setCoverImage = firsSet.images.filter(item => item.isCover)[0]
            // setSelectedImages({
            //     preview: getImageSrc(setCoverImage.fileName)
            // })
            // =============

            const images = caseOne?.caseContent?.images.map(imageSet => imageSet.images).flat();

            setHints(images?.map(image => {
                return {
                    "caseContentMediaId": image.id,
                    "hintTexts": image.commentTexts || [],
                    "hintMarkers": image.commentMarkers || []
                }
            }))
        }
    }, [caseOne])
    console.log(hints)

    useEffect(() => {
        if (selectedSetImages.length === 1) {
            setSelectedImages({
                preview: getImageSrc(selectedSetImages[0].fileName)
            })
        } else if (selectedSetImages.length > 1) {
            const cover = selectedSet.images?.find(image => image?.isCover)
            setSelectedImages({
                preview: getImageSrc(cover.fileName)
            })
        } 
        else {
            setSelectedImages(null)
        }
    }, [selectedSetImages])

    const validateComments = () => {
        let error = false
        hints.forEach(item => {
            item.hintTexts.forEach(hint => {
                if (!hint.content || hint.content.length === 0) {
                    error = true
                }
            })
        })

        return error
    }
    console.log(caseOne)
    const submitHandler = async (e) => {
        e.preventDefault();
        if (validateComments()) {
            toast.error('All comments must be completed')
            return
        }
        let isEdit = false
        const formData = {
            "comments": hints.map(item => ({
                ...item,
                commentMarkers: item.hintMarkers,
                commentTexts: item.hintTexts.map(hint => ({
                    content: hint.content,
                    position: hint.position,
                })),
            }))
        }
        
        const images = caseOne?.caseContent?.images.map(imageSet => imageSet.images).flat();
        console.log(images)
        for (let item of images) {
            if (item?.commentMarkers || item?.commentTexts) {
                isEdit = true
            }
        }

        let res = null
        if (isEdit) {
            console.log('edit')
            res = await dispatch(editCaseCommentFilesByCaseIdFeth({ id: caseId, body: formData })).unwrap()
        } else {
            console.log('add')
            res = await dispatch(addCaseCommentsfilesByCaseIdFeth({ id: caseId, body: formData })).unwrap()
        }
        if (res) {
            if (id) {
                navigate(`/case-edit/${id}/settings`)
            } else {
                navigate(`/case-create/settings`)
            }
            dispatch(setHasEditChanges(false))
        }
    }


    const handleSelectSetImages = (image) => {
        const updatedSelectedSetImages = [...selectedSetImages]
        const index = updatedSelectedSetImages.findIndex(img => img.id === image.id)
        if (index >= 0) {
            updatedSelectedSetImages.splice(index, 1)
        } else {
            updatedSelectedSetImages.push(image)
        }
        const hintIndexes = hints.map((hint, index) => {
            if (updatedSelectedSetImages.findIndex(item => item.id === hint.caseContentMediaId) >= 0) {
                return index
            }
        }).filter(index => typeof index === "number" && index >= 0)

        setSelectedHintsIndexes(hintIndexes)
        setSelectedSetImages(updatedSelectedSetImages)
    }
    
    const handleBack = () => {
        setSelectedSet(null);
        setSelectedSetImages([])
        setSelectedHintsIndexes([])
    }



    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>

    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0" id="drawContainer" data-active="">
                        <div className="quiz__content">
                            <div className="quiz__body">
                                <div className="container container--full container-my-0">
                                    {caseOne?.caseContent?.images?.length > 0 ? <div className="editor-row">
                                        <div className={`editor-col editor-col--sidebar-short editor-col--sidebar-drowpart ${mobileImagesPicker ? '--active' : ''}`}>
                                            <div className="flex flex-col gap-[16px]">
                                                <ul className="blueray-list blueray-list--100">
                                                    {
                                                        selectedSet ?
                                                            <>
                                                                <button
                                                                    onClick={handleBack} className="btn btn--transparent btn--square btn--xs rounded-full" style={{flex: '0 0 100%'}}>
                                                                    <span className="ico ico-20">
                                                                        <ArrowIcon/>
                                                                    </span>
                                                                </button>
                                                                {selectedSet?.images?.map((image, index) => (
                                                                    <li className="blueray-item" key={index}>
                                                                        <label className="blueray-control">
                                                                            <input type="checkbox" className="hidden" checked={selectedSetImages.includes(image)} onChange={() => handleSelectSetImages(image)} />
                                                                            <div className="btn blueray-btn">
                                                                                <img src={getImageSrc(image.fileName)} />
                                                                            </div>
                                                                        </label>
                                                                    </li>
                                                                ))}
                                                            </>
                                                            :
                                                            <>
                                                                {caseOne?.caseContent?.images?.map((imagesSet, index) => (
                                                                    <li onClick={() => setSelectedSet(imagesSet)} className="blueray-item" key={index}>
                                                                        <label className="blueray-control">
                                                                            {/* <input type="radio" className="hidden" name="imagesSets" checked={selectedHintsIndex === index} onChange={() => selectSetHandler(imagesSet, index)} /> */}
                                                                            <div className="btn blueray-btn">
                                                                                <img src={getImageSrc(imagesSet.images.find(image => image.isCover).fileName)} />
                                                                            </div>
                                                                            <div className="input-wrapper input--xs">
                                                                                <input className="input input--outline readonly-custom cursor-default" readOnly="" type="text" defaultValue={imagesSet.setName} placeholder="Photo name..." />
                                                                            </div>
                                                                        </label>
                                                                    </li>
                                                                ))}
                                                            </>
                                                    }
                                                </ul>
                                            </div>
                                        </div>

                                        <DrawingComponentTypeModam
                                            ref={drawingRef}
                                            image={selectedImage}
                                            hintsSelected={hints[selectedHintsIndexes[0]]}
                                            // selectedIndex={selectedHintsIndexes}
                                            selectedIndexes={selectedHintsIndexes}
                                            setItems={(e) => {
                                                dispatch(setHasEditChanges(true))
                                                setHints(e)
                                            }}
                                            modalType={true}
                                        />

                                    </div> :
                                        <h3 className='text-center'>First add image sets</h3>
                                    }
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/cases'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button onClick={() => setMobileImagesPicker(prev => !prev)} className="btn btn--primary gap-[10px] md:hidden btn--md radius-5">
                                        {mobileImagesPicker ? 'Go to draw':'Select image'}
                                    </button>
                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4163 10.9998H4.58301" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 15.5833L17.4163 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 6.4165L17.4163 10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>

                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default CaseCreateComments
