import { createSlice } from '@reduxjs/toolkit';

import { assignDefaultPaymentMethodByMethodIdFeth, deletePaymentMethodByMethodIdFeth, getCustomerExistedPaymentMethodsFeth, savePaymentMethodFeth } from './paymantThunk';

const initialState = {
    methods: [],
    isLoading: false
}

const paymantSlice = createSlice({
    name: 'paymant',
    initialState,
    reducers: {
       
    },

    extraReducers(builder) {
        builder
            .addCase(getCustomerExistedPaymentMethodsFeth.fulfilled, (state, action) => {
                
                state.isLoading = false
                state.methods = action.payload
            })
            .addCase(getCustomerExistedPaymentMethodsFeth.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getCustomerExistedPaymentMethodsFeth.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(savePaymentMethodFeth.fulfilled, (state, action) => {
                if(action.payload.defaultCard && state.methods.length > 0) {
                    const defaultIndex = state.methods.findIndex(item => item.defaultCard)
                    state.methods[defaultIndex].defaultCard = false
                }
                state.methods.push(action.payload)
                
            })
            .addCase(assignDefaultPaymentMethodByMethodIdFeth.fulfilled, (state, action) => {
                const curretnIndex = state.methods.findIndex(item => item.id === action.payload.id)
                state.isLoading = false
                state.methods[curretnIndex].defaultCard = action.payload.defaultCard
            })
            .addCase(assignDefaultPaymentMethodByMethodIdFeth.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(assignDefaultPaymentMethodByMethodIdFeth.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(deletePaymentMethodByMethodIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.methods = state.methods.filter(item => item.id != action.payload.id)
            })
            .addCase(deletePaymentMethodByMethodIdFeth.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deletePaymentMethodByMethodIdFeth.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export const {

} = paymantSlice.actions;


export default paymantSlice.reducer