const usersBase = 'api/charts';

export const charts = (instance) => ({
    async createChart(payload) {
        try {
            const { data } = await instance.post(`${usersBase}`, payload);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async updateChart({id, body}) {
        try {
            const { data } = await instance.put(`${usersBase}/${id}`, body);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getAllCharts(qwery) {
        try {
            const { data } = await instance.get(`${usersBase}?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getChartById(id) {
        try {
            const { data } = await instance.get(`${usersBase}/${id}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteChart(id) {
        try {
            const { data } = await instance.delete(`${usersBase}/${id}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});