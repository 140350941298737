
export const CreateFormHeader = ({config}) => {

    return (
        <>
            <header className="header header--quize sidebar-left-fixed">
                <div className="container">
                    <div className="header__body">
                        <p className="font-semibold">{config.title}</p>
                        {config && config.btnTitle && <button onClick={() => {config.btnAction && config.btnAction()}} className="btn btn--primary btn--xs radius-5 gap-[10px]">
                            <span className="ico">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_264_6175)">
                                        <path d="M3.33301 10H16.6663" stroke="#191919" strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"></path>
                                        <path d="M10 3.3335V16.6668" stroke="#191919" strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_264_6175">
                                            <rect width="20" height="20" fill="white"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            <span className="info text--sm">{config.btnTitle}</span>
                        </button>}
                    </div>
                </div>
            </header>
        </>
    )
}