import React, { useEffect } from 'react'
import CreateSidebar from '../components/parts/createPagesParts/CreateSidebar'
import { Outlet } from 'react-router-dom'
import {ReactComponent as SettingIcon} from '../assets/img/sidebar-settings.svg'
import {ReactComponent as ArticleIcon} from '../assets/img/sidebar-article-content.svg'
import {ReactComponent as PreviewIcon} from '../assets/img/sidebar-preview.svg'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getAllArticlesFeth, getArticleByIdFeth } from '../store/articles/articlesThunks'
const ArticleCreateLayout = () => {
    const dispatch = useDispatch()
    const { articleId, article } = useSelector(state => state.articles)
    
    const sidebalLiks = [
        {
            icon: <ArticleIcon/>,
            to: '/article-create',
            label: 'Article Content'
        },
        {
            icon: <SettingIcon/>,
            to: 'settings',
            label: 'Settings',
            disabled: articleId ? false : true
        },
        {
            icon: <PreviewIcon/>,
            to: 'preview',
            label: 'Preview Article',
            disabled: articleId ? false : true
        },
    ]
    useEffect(() => {
        if(articleId) {
          dispatch(getArticleByIdFeth({id: articleId, qwery: ''}));
        }
    }, [articleId])
    return (
        <>
            <div className={'quiz-page'}>
                <CreateSidebar
                    links={sidebalLiks}
                    title={'Create Article'}
                    backRoute={'/articles'}
                />
                <div className="custom-overlay toggler-menu" style={{ zIndex: 51 }}></div>

                <Outlet />

            </div>
        </>
    )
}
export default ArticleCreateLayout
