import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';
import { setMessage } from './pagesSlice';


export const getAllPagesFetch = createAsyncThunk(
    '/get-all-pages/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.pages.getAllPages();
            if (res.success) {
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

export const getPagesByPathFetch = createAsyncThunk(
    '/get-page-by-path/',
    async (payload, { rejectWithValue, dispatch }) => {
       
       
        try {
            const res = await api.pages.getPageByPath(payload);
            if (res.success) {
                return res.data;
            }
            
            
            toast.error(res.message)
            dispatch(setMessage(res.message))
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getHomePageFetch = createAsyncThunk(
    '/getHomePageFetch/',
    async (payload, { rejectWithValue, dispatch }) => {
       
       
        try {
            const res = await api.pages.getHomePage(payload);
            if (res.success) {
                return res.data;
            }
            
            
            toast.error(res.message)
            dispatch(setMessage(res.message))
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getAnatomyPageFetch = createAsyncThunk(
    '/getAnatomyPageFetch/',
    async (payload, { rejectWithValue, dispatch }) => {
       
       
        try {
            const res = await api.pages.getAnatomyPage(payload);
            if (res.success) {
                return res.data;
            }
            
            
            toast.error(res.message)
            dispatch(setMessage(res.message))
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

