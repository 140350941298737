const base = '/api/flash-cards';

export const flashcards = (instance) => ({
    async getAllFlashCardSets({ query }) {
        try {
            const { data } = await instance.get(`${base}/sets?${query}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }

    },

    async getFlashCardSetById({ id, query }) {
        try {
            const { data } = await instance.get(`${base}/sets/${id}?${query}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getFavoriteFlashCards({ id }) {
        try {
            const { data } = await instance.get(`${base}/sets/${id}?favorite=true`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
   

    async createFlashCardSet(payload) {
        try {
            const { data } = await instance.post(`${base}/sets`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async orderingFlashCards({id, body}) {
        try {
            const { data } = await instance.post(`${base}/cards/ordering/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editFlashCardSetById({ flashCardId, body }) {
        try {
            const { data } = await instance.put(`${base}/sets/${flashCardId}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editFlashCardSetSettingsById({ flashCardId, body }) {
        try {
            const { data } = await instance.put(`${base}/sets/settings/${flashCardId}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteFlashCardSet(id) {
        try {
            const { data } = await instance.delete(`${base}/sets/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getAllFlashCardsBySetId({ id }) {
        try {
            const { data } = await instance.get(`${base}/cards/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createFlashCardBySetId({ id, body }) {
        try {
            const { data } = await instance.postForm(`${base}/cards/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editFlashCardById({ id, body }) {
        try {
            const { data } = await instance.put(`${base}/cards/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteFlashCardById(id) {
        try {
            const { data } = await instance.delete(`${base}/cards/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async toggleLikeFlashcard({ id }) {
        try {
            const { data } = await instance.post(`${base}/sets/like/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async toggleFavoriteFlashcard({ id }) {
        try {
            const { data } = await instance.post(`${base}/sets/favorite/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async viewFCSetBySetId({ id }) {
        try {
            const { data } = await instance.post(`${base}/sets/view${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async toggleFavoriteFlashcardCardById({ id }) {
        try {
            const { data } = await instance.post(`${base}/cards/favorite/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addFlashcardCardStatsByCardId({ id, body }) {
        try {
            const { data } = await instance.post(`${base}/cards/stats/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async сlearFCSetStatisticsBySetId(id) {
        try {
            const { data } = await instance.delete(`${base}/sets/stats/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});