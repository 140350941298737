import { createSlice } from '@reduxjs/toolkit';
import { createChartFeth, getAllChartsFeth } from './chartsThuks';


const initialState = {
    allCharts: [],
    isLoading: false,
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    },
}

const chartsSlice = createSlice({
    name: 'charts',
    initialState,
    reducers: {
        
    },

    extraReducers(builder) {
       builder
        .addCase(getAllChartsFeth.fulfilled, (state, action) => {
            state.isLoading = false
            state.pagination = action.payload.pagination
            state.allCharts = action.payload.data
        })
        .addCase(getAllChartsFeth.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(getAllChartsFeth.rejected, (state, action) => {
            state.isLoading = false
        })
        .addCase(createChartFeth.fulfilled, (state, action) => {
            state.isLoading = false
            state.allCharts.push(action.payload)
        })
        .addCase(createChartFeth.pending, (state, action) => {
            state.isLoading = true
        })
        .addCase(createChartFeth.rejected, (state, action) => {
            state.isLoading = false
        })
    }
})

export const {
   
} = chartsSlice.actions;


export default chartsSlice.reducer