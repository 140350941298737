import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { getFilteres } from '../../../store/filters/filtersSlice';
import { addExperticeFeth, deleteExpertiseByExpertiseIdFeth } from '../../../store/profile/profileThunk';
import { getFiltersByName } from '../../../store/filters/filtersThukns';
import SelectGroup from '../../../components/UI/formElements/SelectGroup';

const Expertise = ({}) => {
    const dispatch = useDispatch()
    const systems = useSelector(getFilteres);
    const [categotyes, setCategotyes] = useState([])
    const [expertise, setExpertise] = useState([]);
    const { profile} = useSelector(state => state.profile);
    useEffect(() => {
        if(systems && profile) {
            setCategotyes(systems.map(item => ({ value: item?.id, label: item?.name, isDisabled: profile?.expertise?.find(expert => expert.id === item.id) ? true : false })))
        }
    }, [systems, profile])
    const submitExperts = async () => {
        const expertiseData = profile.expertise.map(option => option.id).concat(expertise.map(option => option.value))
        const res = dispatch(addExperticeFeth({ 'expertise': expertiseData }))
        if (res) {
            setExpertise([])
        }
    }
    const expertiseHandler = (options) => {
        setExpertise(options)
    }
    const deleteExperts = async (id) => {
        const res = await dispatch(deleteExpertiseByExpertiseIdFeth(id)).unwrap()
    }
    useEffect(() => {
        dispatch(getFiltersByName('industry'))
    }, [dispatch])
    return (
        <div className="col-group">
            <h4 className="mb-[24px] text--lg">Expertise</h4>
            <div className="expertise-form-group">
                <SelectGroup
                    wrapperClasses={'select--outline input--lg flex-auto'}
                    placeholder={'Add your expertise categories...'}
                    isMulti={true}
                    options={categotyes}
                    value={expertise}
                    onChange={(options) => { expertiseHandler(options) }}
                />
                <button type='button' onClick={submitExperts} className="btn btn--shadow gap--md btn--lg radius-5 ml-auto">
                    <span className="ico">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 8V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M16 12H8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                    <span className="info text--md font-medium">Add Expertise</span>
                </button>
            </div>
            <div className="row-group flex-wrap gap--xs mt-4">
                {profile?.expertise && profile?.expertise?.map(option => (
                    <span className="pill-custom pill-custom--lg" key={option.id}>
                        <span className="pill-text">{option.name}</span>
                        <button type='button' className='btn' onClick={() => {
                            deleteExperts(option.id)
                        }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.5 3.5L3.5 12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12.5 12.5L3.5 3.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </span>
                ))}
            </div>
        </div>
    )
}

export default Expertise
