import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Navigate, redirect, useNavigate, useParams } from 'react-router-dom'
import { finalizeLoginFetch } from '../../store/auth/authThunks';
import { setToken } from '../../store/auth/authSlice';

const FinalizeLogin = () => {
    const dispatch = useDispatch()
    const params = useParams();
    const [success, setSuccess] = useState(true)
    const [goToHome, setGoToHome] = useState(false)
    useEffect(() => {
        if(params.token) {
            finalizeLogin(params)
        }
    }, [])
    async function finalizeLogin (params) {
        const res = await dispatch(finalizeLoginFetch(params)).unwrap()
        if (res?.token) {
            setSuccess(true)
            const remember = localStorage.getItem("remember")
            // const storage = localStorage.getItem("remember") ? localStorage : sessionStorage;
            // storage.setItem("authToken", res.token)
            if(remember) {
                document.cookie = "rememberMe=true; path=/";  
            } else {
                document.cookie = "rememberMe=false; path=/";  
            }
            localStorage.setItem("authToken", res.token)

            dispatch(setToken(res.token))
            setGoToHome(true)
        } else {
            setGoToHome(false)
            setSuccess(false)
        }
    }    
    if(goToHome) {
        return <Navigate to={'/'} />
    }
    return (
        <div className='finalize-login-page'>
            <span className="spiner"></span>
            <h2>{success ? ' Wait a few seconds while authorization is in progress, do not close the browser window ' :
                'The link is not valid, try logging in again'}</h2> 
        </div>
    )
}

export default FinalizeLogin
