import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useValidation } from '../../../hooks/useValidation';
import InputGroup from '../../../components/UI/formElements/InputGroup';
import SelectGroup from '../../../components/UI/formElements/SelectGroup';
import DataRangeGroup from '../../../components/UI/formElements/DataRangeGroup';
import UploadImage from '../../../components/UI/UploadImage';
import { ReactComponent as DeleteIcon } from '../../../assets/img/delete-Icon.svg';
import { addExperienceFeth, deleteExperienceByExpIdFeth, editExperienceByExpIdFeth } from '../../../store/profile/profileThunk';
import { formatDateRange, getImageSrc } from '../../../helpers/utils';


const workingTimeOptions = [
    {
        value: 'Fulltime',
        label: 'Fulltime'
    },
    {
        value: 'Part time',
        label: 'Part time'
    },
    {
        value: 'Distant work',
        label: 'Distant work'
    },
]

const PracticeExperience = ({showOnly = false, titleClass = 'text--lg'}) => {
    const { profile } = useSelector(state => state.profile);
    const [showAddForm, setShowAddForm] = useState(false)
    const [isEditId, setIsEditId] = useState(null)
    const dispatch = useDispatch()

    const [addPracticeExperience, setAddPracticeExperience] = useState({
        logo: null,
        company: "",
        jobTitle: '',
        specialization: '',
        workingTime: 'Fulltime',
        startDate: '',
        endDate: null,
        location: '',
    })
    const messages = {
        company: 'Company is required',
        jobTitle: 'Job Title name is required',
        startDate: 'Start Date name is required',
        location: 'location name is required',
        specialization: 'Specialization name is required',
    };
    const exceptions = ['workingTime', 'endDate', 'logo']
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(addPracticeExperience, {
        messages,
        exceptions
    });
    const addInputsHandler = (name, value) => {
        setAddPracticeExperience(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const editHandler = (element) => {
        setIsEditId(element.id)
        setAddPracticeExperience({
            logo: element.logo ? {
                preview: getImageSrc(element.logo),
                type: `image/${element.logo.split('.').pop()}`
            } : null,
            company: element.company,
            jobTitle: element.jobTitle,
            specialization: element.specialization,
            workingTime: element.workingTime,
            startDate: element.startDate,
            endDate: element.endDate,
            location: element.location,
        })
        setShowAddForm(false)
    }
    const clearData = () => {
        setAddPracticeExperience(
            {
                logo: null,
                company: "",
                jobTitle: '',
                specialization: '',
                workingTime: 'Fulltime',
                startDate: '',
                endDate: null,
                location: '',
            }
        )
    }
    const deleteHandler = async (id) => {
        const res = await dispatch(deleteExperienceByExpIdFeth(id)).unwrap()
        if (res) {
            setIsEditId(null)
            clearData()
        }
    }
    const createSumbitHanler = async () => {
        if (errors) {
            
            setIsErrorShown(true)
            return
        }
        const data = {
            "experience": {
                company: addPracticeExperience.company,
                jobTitle: addPracticeExperience.jobTitle,
                specialization: addPracticeExperience.specialization,
                workingTime: addPracticeExperience.workingTime,
                startDate: addPracticeExperience.startDate,
                endDate: addPracticeExperience.endDate,
                location: addPracticeExperience.location,
            }
        }
        const formData = new FormData()
        formData.append('logo', addPracticeExperience.logo)
        formData.append('data', JSON.stringify(data))

        let res = null
        if (isEditId) {
            res = await dispatch(editExperienceByExpIdFeth({ id: isEditId, body: formData })).unwrap()
        } else {
            res = await dispatch(addExperienceFeth(formData)).unwrap()
        }
        if (res) {
            clearData()
            setIsErrorShown(false)
            if (isEditId) {
                setIsEditId(null)
            }
        }
    }
    const addFormHandler = () => {
        clearData()
        setIsErrorShown(false)
        setShowAddForm(prev => !prev)
        setIsEditId(null)
    }
    return (
        <div className="col-group">
            <h4 className={`mb-[24px] ${titleClass}`}>Practice Experience</h4>
            <div className="row g-24">
                {profile?.experiences.map((experience, index) => (
                    <div className="col-12" key={index}>
                        {isEditId !== experience.id ? <div className="card card-experience relative">
                            {experience.logo && <div className="card__image">
                                <img src={getImageSrc(experience.logo)} alt="" />
                            </div>}
                            <div className="card__body">
                                <div className="col-group font-semibold gap--xs">
                                    <p>{experience.company}</p>
                                    <p>{`${experience.jobTitle} - ${experience.specialization} - ${experience.workingTime}`}</p>
                                </div>
                                <div className="col-group color-secondary text--md gap--sm">
                                    <p>{formatDateRange(experience.startDate, experience.endDate)}</p>
                                    <p>{experience.location}</p>
                                </div>
                            </div>
                            {!showOnly && <button type='button' onClick={() => editHandler(experience)} className='btn card-edit-button'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_3147_61464)">
                                        <path d="M8.68969 20.2501H4.5C4.30109 20.2501 4.11032 20.1711 3.96967 20.0305C3.82902 19.8898 3.75 19.699 3.75 19.5001V15.3104C3.75009 15.1118 3.82899 14.9213 3.96938 14.7807L15.5306 3.2195C15.6713 3.07895 15.862 3 16.0608 3C16.2596 3 16.4503 3.07895 16.5909 3.2195L20.7806 7.40637C20.9212 7.54701 21.0001 7.7377 21.0001 7.93653C21.0001 8.13535 20.9212 8.32605 20.7806 8.46668L9.21937 20.0307C9.07883 20.1711 8.88834 20.25 8.68969 20.2501Z" stroke="#FF4000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12.75 6L18 11.25" stroke="#FF4000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3147_61464">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>}
                        </div> :
                            <div className="card card-experience relative">
                                <div className="card__image">
                                    <UploadImage
                                        imageCover={false}
                                        defaulImages={addPracticeExperience?.logo ? [addPracticeExperience?.logo] : []}
                                        onChange={(image) => addInputsHandler('logo', ...image)}
                                    />
                                </div>
                                <div className="card__body">
                                    <div className="row g-16">
                                        <div className="col-xl-6">
                                            <InputGroup
                                                wrapperClasses='input--md'
                                                name={'company'}
                                                label={'Place of work'}
                                                inputClasses='input--outline'
                                                placeholder={'Enter company'}
                                                value={addPracticeExperience.company}
                                                onChange={({ target }) => addInputsHandler('company', target.value)}
                                                error={isErrorShown && errors?.company}
                                                errorMessage={errorMessages?.company}
                                            />
                                        </div>
                                        <div className="col-xl-6">
                                            <DataRangeGroup
                                                wrapperClasses='input--md'
                                                label={'Duration'}
                                                inputClasses='input--outline input-date'
                                                placeholder={'Enter Date'}
                                                selectedMinDate={addPracticeExperience.startDate}
                                                selectedMaxDate={addPracticeExperience.endDate}
                                                onChangeMin={(date) => addInputsHandler('startDate', date)}
                                                onChangeMax={(date) => addInputsHandler('endDate', date)}
                                                error={isErrorShown && errors?.startDate}
                                                errorMessage={errorMessages?.startDate}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <InputGroup
                                                wrapperClasses='input--md'
                                                name={'jobTitle'}
                                                label={'Job Title'}
                                                inputClasses='input--outline'
                                                placeholder={'Enter Job Title'}
                                                value={addPracticeExperience.jobTitle}
                                                onChange={({ target }) => addInputsHandler('jobTitle', target.value)}
                                                error={isErrorShown && errors?.jobTitle}
                                                errorMessage={errorMessages?.jobTitle}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <InputGroup
                                                wrapperClasses='input--md'
                                                name={'specialization'}
                                                label={'Specialization'}
                                                inputClasses='input--outline'
                                                placeholder={'Enter Specialization'}
                                                value={addPracticeExperience.specialization}
                                                onChange={({ target }) => addInputsHandler('specialization', target.value)}
                                                error={isErrorShown && errors?.specialization}
                                                errorMessage={errorMessages?.specialization}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <SelectGroup
                                                label={'Working time'}
                                                placeholder={'Working time'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                defaultValue={{ value: addPracticeExperience.workingTime, label: addPracticeExperience.workingTime }}
                                                options={workingTimeOptions}
                                                onChange={(option) => { addInputsHandler('workingTime', option.value) }}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <InputGroup
                                                wrapperClasses='input--md'
                                                name={'location'}
                                                label={'Location'}
                                                inputClasses='input--outline'
                                                placeholder={'Enter Location'}
                                                value={addPracticeExperience.location}
                                                onChange={({ target }) => addInputsHandler('location', target.value)}
                                                error={isErrorShown && errors?.location}
                                                errorMessage={errorMessages?.location}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="row-group gap--md-2">
                                                <button type='button' onClick={createSumbitHanler} className="btn btn--primary btn--lg radius-5">
                                                    <span className="info text--md">Save Changes</span>
                                                </button>
                                                <button type='button' onClick={() => { deleteHandler(experience.id) }} className="btn btn--transparent btn--md radius-5">
                                                    <span className="ico">
                                                        <DeleteIcon />
                                                    </span>
                                                    <span className="info text--md color-danger">Delete</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                ))}
                {showAddForm && <div className="col-12">
                    <div className="card card-experience relative">
                        <div className="card__image">
                            <UploadImage
                                imageCover={false}
                                defaulImages={addPracticeExperience?.logo ? [addPracticeExperience?.logo] : []}
                                onChange={(image) => addInputsHandler('logo', ...image)}
                            />
                        </div>
                        <div className="card__body">
                            <div className="row g-16">
                                <div className="col-xl-6">
                                    <InputGroup
                                        wrapperClasses='input--md'
                                        name={'company'}
                                        label={'Place of work'}
                                        inputClasses='input--outline'
                                        placeholder={'Enter company'}
                                        value={addPracticeExperience.company}
                                        onChange={({ target }) => addInputsHandler('company', target.value)}
                                        error={isErrorShown && errors?.company}
                                        errorMessage={errorMessages?.company}
                                    />
                                </div>
                                <div className="col-xl-6">
                                    <DataRangeGroup
                                        wrapperClasses='input--md'
                                        label={'Duration'}
                                        inputClasses='input--outline input-date'
                                        placeholder={'Enter Date'}
                                        selectedMinDate={addPracticeExperience.startDate}
                                        selectedMaxDate={addPracticeExperience.endDate}
                                        onChangeMin={(date) => addInputsHandler('startDate', date)}
                                        onChangeMax={(date) => addInputsHandler('endDate', date)}
                                        error={isErrorShown && errors?.startDate}
                                        errorMessage={errorMessages?.startDate}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <InputGroup
                                        wrapperClasses='input--md'
                                        name={'jobTitle'}
                                        label={'Job Title'}
                                        inputClasses='input--outline'
                                        placeholder={'Enter Job Title'}
                                        value={addPracticeExperience.jobTitle}
                                        onChange={({ target }) => addInputsHandler('jobTitle', target.value)}
                                        error={isErrorShown && errors?.jobTitle}
                                        errorMessage={errorMessages?.jobTitle}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <InputGroup
                                        wrapperClasses='input--md'
                                        name={'specialization'}
                                        label={'Specialization'}
                                        inputClasses='input--outline'
                                        placeholder={'Enter Specialization'}
                                        value={addPracticeExperience.specialization}
                                        onChange={({ target }) => addInputsHandler('specialization', target.value)}
                                        error={isErrorShown && errors?.specialization}
                                        errorMessage={errorMessages?.specialization}
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <SelectGroup
                                        label={'Working time'}
                                        placeholder={'Working time'}
                                        wrapperClasses={'select--outline input--md w-full'}
                                        defaultValue={{ value: addPracticeExperience.workingTime, label: addPracticeExperience.workingTime }}
                                        options={workingTimeOptions}
                                        onChange={(option) => { addInputsHandler('workingTime', option.value) }}
                                    />
                                </div>
                                <div className="col-12">
                                    <InputGroup
                                        wrapperClasses='input--md'
                                        name={'location'}
                                        label={'Location'}
                                        inputClasses='input--outline'
                                        placeholder={'Enter Location'}
                                        value={addPracticeExperience.location}
                                        onChange={({ target }) => addInputsHandler('location', target.value)}
                                        error={isErrorShown && errors?.location}
                                        errorMessage={errorMessages?.location}
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="row-group gap--md-2">
                                        <button type='button' onClick={createSumbitHanler} className="btn btn--primary btn--lg radius-5">
                                            <span className="info text--md">Add Practice Experience</span>
                                        </button>
                                        <button type='button' onClick={addFormHandler} className="btn btn--shadow btn--lg radius-5">
                                            <span className="info text--md">Cansel</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {!showOnly && <div className="col-12 flex">
                    <button type='button' onClick={addFormHandler} className="btn btn--shadow gap--md btn--lg radius-5 ml-auto">
                        <span className="ico">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 8V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 12H8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="info text--md font-medium">Add Practice Experience</span>
                    </button>
                </div>}
            </div>
        </div>
    )
}

export default PracticeExperience
