import React from 'react'
import QuizSteps from '../../../components/parts/QuizSteps';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { сlearQuizResultsbyQuizIdFeth } from '../../../store/quizzes/quizzesThunks';
import { useSelector } from 'react-redux';
import { QuizeStepsQuestions } from '../../../components/parts/QuizeStepsQuestions';

const QuizPageEnd = () => {
	const {id} = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
	const {quiz,questions, quizeResults} = useSelector((state) => state.quizzes);
	const restartQuize = async () => {
        const res = await dispatch(сlearQuizResultsbyQuizIdFeth(id)).unwrap()
        if(res) {
            navigate(`/quiz-page/${id}/quiz`)
        }
    }
	console.log(quiz?.quizSetting?.one_attempt)
	return (
		<main className="content">
			<div className="quiz">
			<QuizeStepsQuestions data={questions} activeIndex={quizeResults?.completedQuestions} />
				<div className="quiz__content">
					<div className="quiz__body pt-[60px] pb-[60px]">
						<div className="container full-center">
							<div className="scrore-wrapper mb-[32px]">
								<div className="score">
									<div className="score__result">{quizeResults?.correctAnswers}</div>
									<p className="score__total"> out of {quizeResults?.totalQuestions}</p>
								</div>
							</div>
							<h2 className="quiz__text mb-[6px]">Congratulation</h2>
							<p className="quiz__label mb-[32px]">You ended our quiz, now you can check results and take part in discussion.</p>
							<div className="group gap--xs">
								<Link to={`/quiz-page/${id}/quiz/result`} className="btn btn--shadow btn--md radius-5 text--md">See Results</Link>
								<Link to={`/quiz-page/${id}/discussion`} className="btn btn--primary btn--md radius-5 text--md">Discussion</Link>
								{!quiz?.quizSetting?.one_attempt && <button type='button' onClick={restartQuize} className="btn btn--shadow  btn--md radius-5">
									<span className="ico">
										<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M17.864 4.75244V7.99377H14.6235H17.864" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M17.4413 7.99359C16.1973 5.71384 13.7792 4.1665 10.9998 4.1665C6.95 4.1665 3.6665 7.45 3.6665 11.4998C3.6665 15.5497 6.95 18.8332 10.9998 18.8332C15.0497 18.8332 18.3332 15.5497 18.3332 11.4998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</span>
									<span>Restart Quiz</span>
								</button>}
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	)
}

export default QuizPageEnd