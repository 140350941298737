import React from 'react'
import { ReactComponent as LoupeIcon } from "../../../assets/img/loupe-icon.svg"

const InputSearch = ({ className = "input--sm", closeButton = false, closeHandler, ...props }) => {
    return (
        <div className={`input-wrapper ${className ?? ""}`}>
            <span className="ico">
                <LoupeIcon />
            </span>
            <input className="input input--search input--solid rounded-full" type="text" placeholder="Search..." {...props} />
            {closeButton && <button type='button' className='ico' onClick={closeHandler}>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.1875 4.8125L4.8125 17.1875" stroke="black" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M17.1875 17.1875L4.8125 4.8125" stroke="black" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
                </svg>
            </button>}
        </div>
    )
}

export default InputSearch