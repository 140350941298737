import React, { useEffect, useMemo, useState } from 'react'
import { CreateFormHeader } from '../../components/parts/CreateFormHeader'
import InputGroup from '../../components/UI/formElements/InputGroup'
import TextEditor from '../../components/parts/TextEditor'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Loader from '../../components/UI/Loader'
import { createNewArticleFeth, editArticleByIdFeth } from '../../store/articles/articlesThunks'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { getMe, setHasEditChanges } from '../../store/auth/authSlice'
import useDebounce from '../../hooks/useDebounce'

const ArticleCreateContent = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(getMe)
    const { articleId, article } = useSelector(state => state.articles)
    const [editorData, setEditorData] = useState('');
    const [title, setTitle] = useState('')
    const conf = {
        title: 'Article Content',
    }
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setEditorData(article?.content || '')
        setTitle(article?.title || '')
    }, [article])
    const isCreator = useMemo(() => article?.user?.id === user?.id, [article, user?.id])
    const isAutor = useMemo(() => {
        return (article?.authors?.map((author) => author?.id) || []).includes(user?.id)
    }, [article, user])

    const debouncedEditChange = useDebounce((e) => {
        submitHandler(e, true)
    }, 2000);


    const submitHandler = async (e, autoSave) => {
        let res = null
        if (title.length === 0 || editorData.length === 0 || !editorData || !title) {
            toast.error('The title and content of the article are required.')
            return
        }
        const data = {
            "title": title,
            "content": editorData
        }
        if (!autoSave) {
            setIsLoading(true)
        }
        if (articleId) {
            res = await dispatch(editArticleByIdFeth({ id: articleId, body: data })).unwrap()
        } else {
            res = await dispatch(createNewArticleFeth(data)).unwrap()
        }
        if (res) {
            if (!autoSave && (user?.id === article?.user?.id || !article?.user)) {
                navigate('settings')
            } else if (!autoSave) {
                navigate('preview')
            }

        }
        dispatch(setHasEditChanges(false))
        setIsLoading(false)
    }

    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <InputGroup
                                        wrapperClasses='input--md w-full mb-[32px] mt-[20px]'
                                        inputClasses='input--outline'
                                        placeholder={'Enter article title...'}
                                        value={title}
                                        readOnly={!isCreator && !isAutor && articleId}
                                        onChange={({ target }) => {
                                            setTitle(target.value)
                                        }}
                                    />
                                    <TextEditor editorData={editorData} setData={(e) => {
                                        setEditorData(e)
                                        debouncedEditChange()
                                    }} />
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/articles'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>

                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4173 10.9998H4.58398" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.834 15.5833L17.4173 11" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.834 6.4165L17.4173 10.9998" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </button>


                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default ArticleCreateContent
