const usersBase = '/api/profile/';

export const profile = (instance) => ({
    async getProfileById(id) {
        try {
            const { data } = await instance.get(`${usersBase}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getProfileQuizzes({ id, qwery }) {
        try {
            const { data } = await instance.get(`${usersBase}/quizzes/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getProfileCourses({ id, qwery }) {
        try {
            const { data } = await instance.get(`${usersBase}/courses/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getProfileCasses({ id, qwery }) {
        try {
            const { data } = await instance.get(`${usersBase}/casses/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getProfileArticles({ id, qwery }) {
        try {
            const { data } = await instance.get(`${usersBase}/articles/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getProfileComments({ id, qwery }) {
        try {
            const { data } = await instance.get(`${usersBase}/comments/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getProfileMaterials({ id, qwery }) {
        try {
            const { data } = await instance.get(`${usersBase}/materials/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getProfileThreads({ id, qwery }) {
        try {
            const { data } = await instance.get(`${usersBase}/threads/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getProfileForums({ id, qwery }) {
        try {
            const { data } = await instance.get(`${usersBase}/forums/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addExperience(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/experience`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addCertificate(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/certificate`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addExpertice(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/expertise`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async followUserByProfileId(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/follow/${payload}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async toggleUserBlock(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/block/${payload}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editProfile(payload) {
        try {
            const { data } = await instance.patch(`${usersBase}`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editExperienceByExpId({ id, body }) {
        try {
            const { data } = await instance.patch(`${usersBase}/experience/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editCertificateByCertId({ id, body }) {
        try {
            const { data } = await instance.patch(`${usersBase}/certificate/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteExperienceByExpId(id) {
        try {
            const { data } = await instance.delete(`${usersBase}/experience/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteCertificateByCertId(id) {
        try {
            const { data } = await instance.delete(`${usersBase}/certificate/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteExpertiseByExpertiseId(id) {
        try {
            const { data } = await instance.delete(`${usersBase}/expertise/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteUserPhoto() {
        try {
            const { data } = await instance.delete(`${usersBase}/photo`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async disable2FA(payload) {
        try {
            const { data } = await instance.patch(`${usersBase}/2fa`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});