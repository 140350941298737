import { NavLink, Outlet } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import CreateSidebar from "../components/parts/createPagesParts/CreateSidebar";
import {ReactComponent as GeneralIcon} from '../assets/img/sidebar-general.svg'
import {ReactComponent as SettingIcon} from '../assets/img/sidebar-settings.svg'
import {ReactComponent as FlashcardIcon} from '../assets/img/sidebar-flashcard.svg'
import { useDispatch } from "react-redux";
import { getFlashCardId, getFlashCardSet } from "../store/flashcards/flashcardsSlice";
import { useSelector } from "react-redux";
import { getFlashCardSetByIdFetch } from "../store/flashcards/flashcardsThunks";
export const FlashcardCreateLayout = () => {
    const dispach = useDispatch();
    const flashCardId = useSelector(getFlashCardId);
    const flashCardSet = useSelector(getFlashCardSet)
    
    const sidebalLiks = [
        {
            icon: <GeneralIcon/>,
            to: 'general',
            label: 'General Information'
        },
        {
            icon: <FlashcardIcon/>,
            to: 'flashcards',
            label: 'Flashcards',
            disabled: flashCardId ? false : true
        },
        {
            icon: <SettingIcon/>,
            to: 'settings',
            label: 'Settings',
            disabled: flashCardId ? false : true
        },
       
    ]
    useEffect(() => {
        if(flashCardId) {
          dispach(getFlashCardSetByIdFetch({id:flashCardId})).unwrap();
        }
    }, [flashCardId])
    return (
        <div className={'quiz-page'}  >
            <CreateSidebar links={sidebalLiks} title={"Create Flashcards"} backRoute={'/quiz/flashcards'} />
            <Outlet context={[flashCardId, flashCardSet]}/>
            <div className="custom-overlay toggler-menu" style={{ zIndex: 51 }}></div>
        </div>
    )
}
