import { useState, useEffect } from 'react';

// Хук для работы с reCAPTCHA v3
const useRecaptcha = (siteKey) => {
  const [recaptchaToken, setRecaptchaToken] = useState('');

  useEffect(() => {
    // Загружаем скрипт reCAPTCHA один раз при монтировании компонента
    const loadRecaptchaScript = () => {
      if (document.querySelector(`script[src="https://www.google.com/recaptcha/api.js?render=${siteKey}"]`)) {
        return; // Скрипт уже загружен
      }

      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        console.log('reCAPTCHA script loaded');
      };
    };

    loadRecaptchaScript();
  }, [siteKey]);

  // Функция для получения токена
  const getRecaptchaToken = async (action) => {
    if (!window.grecaptcha) {
      console.error('reCAPTCHA not loaded');
      return null;
    }

    try {
      const token = await window.grecaptcha.execute(siteKey, { action });
      setRecaptchaToken(token);
      return token;
    } catch (error) {
      console.error('Error getting reCAPTCHA token:', error);
      return null;
    }
  };

  return { recaptchaToken, getRecaptchaToken };
};

export default useRecaptcha;