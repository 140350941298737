import React from 'react'
import { useSelector } from 'react-redux'
import { getAuthorized, getMe, setShowLoginModal } from '../../../store/auth/authSlice'

import { ReactComponent as VerifiedIcon } from "../../../assets/img/SealCheck.svg"
import { getImageSrc } from '../../../helpers/utils'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { followUserByProfileIdFeth, toggleUserBlockFeth } from '../../../store/profile/profileThunk'
import { createNewConversationByUserIdFeth } from '../../../store/chat/chatThunk'
import { Dropdown } from '../Dropdown'

const ProfileSidebar = () => {
    const me = useSelector(getMe)
    const authorized = useSelector(getAuthorized)
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()
    const { profile } = useSelector(state => state.profile);
    const getFullname = (user) => {
        if (user) {
            return user.firstName + " " + user.lastName
        }
    }
    console.log(profile)
    const subscribeToUser = async () => {
        if (!authorized) {
            dispatch(setShowLoginModal(true))
            return
        }
        const res = await dispatch(followUserByProfileIdFeth(id)).unwrap()

    }
    const crateNewChat = async () => {
        if (!authorized) {
            dispatch(setShowLoginModal(true))
            return
        }
        const res = await dispatch(createNewConversationByUserIdFeth(profile?.user?.id)).unwrap()
        if (res) {
            navigate(`/chat/${res.conversation.id}`)
        }
    }

    const blockUser = async () => {
       
        const res = dispatch(toggleUserBlockFeth(id)).unwrap()
        if(res) {
            console.log(res)
        }
    }

    if (!me && !profile) return

    return (
        <div className="page-sidebar profile-aside">
            <div className="profile-aside__top min-w-[255px]">
                <div className="col-group items-center gap--md-2">
                    <div className="image-wrapper profile-aside__avatar ico">
                        {profile?.user?.photo ? <img src={getImageSrc(profile?.user?.photo)} alt="" />
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 448 512">
                                <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" fill="currentColor" />
                            </svg>
                        }
                    </div>
                    <div className="col-group">
                        <p className="profile-aside__name justify-center text-center">
                            <span>{getFullname(profile?.user)}</span>
                            {
                                me?.isVerified ?
                                    <span className="ico ico-22">
                                        <VerifiedIcon />
                                    </span>
                                    :
                                    null
                            }
                        </p>
                        <div className="row-group gap--lg justify-center text--md">
                            <p>
                                <span className="font-semibold">{profile?.user?.followers} </span>
                                Followers
                            </p>
                            <p>
                                <span className="font-semibold">{profile?.user?.following} </span>
                                Following
                            </p>
                        </div>
                    </div>
                    {(id && id != me?.id) && <div className="row-group gap--md-2">
                        <button type='button' onClick={subscribeToUser} className="btn btn--primary btn--lg radius-5">
                            <span className="info text--md">{profile?.isFollow ? 'Unsubscribe' : 'Subscribe'}</span>
                        </button>
                        <button type='button' disabled={profile?.amIBlocked} onClick={crateNewChat} className="btn btn--shadow btn--lg radius-5">
                            <span className="info text--md">Message</span>
                        </button>
                        <Dropdown
                            button={
                                <button    
                                    type='button' 
                                    
                                    className="btn btn--shadow btn--square btn--lg radius-5">
                                    <span className="ico">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 10C11.6044 10 11.2178 10.1173 10.8889 10.3371C10.56 10.5568 10.3036 10.8692 10.1522 11.2346C10.0009 11.6001 9.96126 12.0022 10.0384 12.3902C10.1156 12.7781 10.3061 13.1345 10.5858 13.4142C10.8655 13.6939 11.2219 13.8844 11.6098 13.9616C11.9978 14.0387 12.3999 13.9991 12.7654 13.8478C13.1308 13.6964 13.4432 13.44 13.6629 13.1111C13.8827 12.7822 14 12.3956 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10ZM5 10C4.60444 10 4.21776 10.1173 3.88886 10.3371C3.55996 10.5568 3.30362 10.8692 3.15224 11.2346C3.00087 11.6001 2.96126 12.0022 3.03843 12.3902C3.1156 12.7781 3.30608 13.1345 3.58579 13.4142C3.86549 13.6939 4.22186 13.8844 4.60982 13.9616C4.99778 14.0387 5.39992 13.9991 5.76537 13.8478C6.13082 13.6964 6.44318 13.44 6.66294 13.1111C6.8827 12.7822 7 12.3956 7 12C7 11.4696 6.78929 10.9609 6.41421 10.5858C6.03914 10.2107 5.53043 10 5 10ZM19 10C18.6044 10 18.2178 10.1173 17.8889 10.3371C17.56 10.5568 17.3036 10.8692 17.1522 11.2346C17.0009 11.6001 16.9613 12.0022 17.0384 12.3902C17.1156 12.7781 17.3061 13.1345 17.5858 13.4142C17.8655 13.6939 18.2219 13.8844 18.6098 13.9616C18.9978 14.0387 19.3999 13.9991 19.7654 13.8478C20.1308 13.6964 20.4432 13.44 20.6629 13.1111C20.8827 12.7822 21 12.3956 21 12C21 11.4696 20.7893 10.9609 20.4142 10.5858C20.0391 10.2107 19.5304 10 19 10Z" fill="currentColor" />
                                        </svg>
                                    </span>
                                </button>
                            }
                            body={
                                <ul className='min-w-[150px]'>
                                    <li>
                                        <button 
                                            onClick={blockUser}
                                            type='button' 
                                            className='btn btn--transparent btn--lg radius-5 gap--xs ' >
                                                <span className='color-default'>{`${profile?.isBlocked ? 'UnBlock' : 'Block' } user`}</span>
                                        </button>
                                    </li>
                                    <li className="px-[14px]"><hr className="hr" /></li>
                                    <li>
                                        <button type='button'  className='btn btn--transparent btn--lg radius-5 gap--xs' >Report user</button>
                                    </li>
                                </ul>
                            }
                        />

                    </div>}
                </div>
            </div>
            <div className="profile-aside__block">
                <h4 className="row-group gap--sm">
                    <span className="ico ico--xs image-wrapper">
                        <img src={require("../../../assets/img/thread-ico.png")} alt="" />
                    </span>
                    <span className="info text--lg">Activity</span>
                </h4>
                <div className="col-group gap--md-2">
                    <div className="row-group gap--sm justify-between w-full">
                        <p className="w-2/3">Threads</p>
                        <div className="row-group gap--xs flex-auto text-left">
                            <span className="ico">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.4503 11.3427C16.3702 11.3055 16.2888 11.2697 16.2064 11.2355C16.0629 8.67546 14.6176 7.20984 12.1908 7.19484C12.1798 7.19478 12.1689 7.19478 12.1579 7.19478C10.7064 7.19478 9.49914 7.79446 8.7561 8.88569L10.0908 9.77183C10.6458 8.95672 11.517 8.78295 12.1585 8.78295C12.1659 8.78295 12.1734 8.78295 12.1807 8.78302C12.9798 8.78795 13.5827 9.01281 13.973 9.45131C14.257 9.77056 14.4469 10.2117 14.541 10.7685C13.8325 10.6519 13.0663 10.6161 12.2473 10.6616C9.94001 10.7902 8.45671 12.0926 8.55633 13.9024C8.60689 14.8204 9.0794 15.6101 9.88677 16.126C10.5694 16.5622 11.4486 16.7754 12.3623 16.7272C13.569 16.6631 14.5156 16.2175 15.176 15.4028C15.6775 14.784 15.9948 13.9822 16.1348 12.9718C16.7099 13.3077 17.1361 13.7498 17.3715 14.2811C17.7717 15.1844 17.795 16.6687 16.5437 17.8789C15.4473 18.939 14.1294 19.3976 12.1377 19.4118C9.92834 19.3959 8.25744 18.7102 7.17107 17.3735C6.15377 16.1218 5.62802 14.3139 5.60841 12C5.62802 9.68604 6.15377 7.87815 7.17107 6.6265C8.25744 5.28983 9.92831 4.60406 12.1377 4.58817C14.363 4.60418 16.0631 5.29326 17.191 6.63637C17.7441 7.29502 18.1611 8.12333 18.436 9.08909L20 8.68521C19.6668 7.49646 19.1425 6.4721 18.429 5.62256C16.983 3.90063 14.8681 3.0183 12.1431 3H12.1322C9.41279 3.01823 7.32159 3.90392 5.91673 5.63243C4.66659 7.1706 4.02174 9.31085 4.00007 11.9937L4 12L4.00007 12.0063C4.02174 14.6891 4.66659 16.8294 5.91673 18.3676C7.32159 20.0961 9.41279 20.9818 12.1322 21H12.1431C14.5608 20.9838 16.265 20.3711 17.6689 19.0135C19.5057 17.2374 19.4504 15.0112 18.845 13.6445C18.4107 12.6644 17.5826 11.8684 16.4503 11.3427ZM12.2759 15.1413C11.2647 15.1964 10.2141 14.7571 10.1623 13.8161C10.1239 13.1184 10.6753 12.3399 12.338 12.2472C12.5284 12.2366 12.7152 12.2314 12.8988 12.2314C13.5027 12.2314 14.0677 12.2882 14.5814 12.3968C14.3898 14.7127 13.266 15.0887 12.2759 15.1413Z" fill="#FF4000" />
                                </svg>
                            </span>
                            <span className="font-semibold">{profile?.activity?.threads}</span>
                        </div>
                    </div>
                    <div className="row-group gap--sm justify-between w-full">
                        <p className="w-2/3">Replies</p>
                        <div className="row-group gap--xs flex-auto text-left">
                            <span className="ico">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.2955 5.25H6.20455C5.55356 5.25 4.92924 5.52095 4.46892 6.00324C4.0086 6.48553 3.75 7.13966 3.75 7.82172V16.3941C3.75 17.0762 4.0086 17.7303 4.46892 18.2126C4.92924 18.6949 5.55356 18.9659 6.20455 18.9659H7.02273V22.3948C7.02309 22.5548 7.06616 22.7114 7.14705 22.847C7.22795 22.9827 7.34345 23.0919 7.48049 23.1623C7.61753 23.2328 7.77066 23.2617 7.92255 23.2457C8.07444 23.2298 8.21904 23.1696 8.34 23.072L13.38 18.9659H19.2955C19.9464 18.9659 20.5708 18.6949 21.0311 18.2126C21.4914 17.7303 21.75 17.0762 21.75 16.3941V7.82172C21.75 7.13966 21.4914 6.48553 21.0311 6.00324C20.5708 5.52095 19.9464 5.25 19.2955 5.25ZM20.1136 16.3941C20.1136 16.6215 20.0274 16.8395 19.874 17.0003C19.7206 17.1611 19.5125 17.2514 19.2955 17.2514H13.1018C12.921 17.252 12.7455 17.3153 12.6027 17.4314L8.65909 20.6803V18.1086C8.65909 17.8813 8.57289 17.6632 8.41945 17.5024C8.26601 17.3417 8.0579 17.2514 7.84091 17.2514H6.20455C5.98755 17.2514 5.77944 17.1611 5.626 17.0003C5.47256 16.8395 5.38636 16.6215 5.38636 16.3941V7.82172C5.38636 7.59437 5.47256 7.37633 5.626 7.21556C5.77944 7.0548 5.98755 6.96448 6.20455 6.96448H19.2955C19.5125 6.96448 19.7206 7.0548 19.874 7.21556C20.0274 7.37633 20.1136 7.59437 20.1136 7.82172V16.3941Z" fill="#FF4000" />
                                    <path d="M8.66193 11.2507H13.571C13.788 11.2507 13.9961 11.1604 14.1496 10.9996C14.303 10.8388 14.3892 10.6208 14.3892 10.3934C14.3892 10.1661 14.303 9.94805 14.1496 9.78728C13.9961 9.62652 13.788 9.5362 13.571 9.5362H8.66193C8.44494 9.5362 8.23683 9.62652 8.08339 9.78728C7.92995 9.94805 7.84375 10.1661 7.84375 10.3934C7.84375 10.6208 7.92995 10.8388 8.08339 10.9996C8.23683 11.1604 8.44494 11.2507 8.66193 11.2507ZM16.8438 12.9652H8.66193C8.44494 12.9652 8.23683 13.0555 8.08339 13.2162C7.92995 13.377 7.84375 13.5951 7.84375 13.8224C7.84375 14.0498 7.92995 14.2678 8.08339 14.4286C8.23683 14.5893 8.44494 14.6796 8.66193 14.6796H16.8438C17.0607 14.6796 17.2689 14.5893 17.4223 14.4286C17.5757 14.2678 17.6619 14.0498 17.6619 13.8224C17.6619 13.5951 17.5757 13.377 17.4223 13.2162C17.2689 13.0555 17.0607 12.9652 16.8438 12.9652Z" fill="#FF4000" />
                                </svg>
                            </span>
                            <span className="font-semibold">{profile?.activity?.replies}</span>
                        </div>
                    </div>
                    <div className="row-group gap--sm justify-between w-full">
                        <p className="w-2/3">Likes</p>
                        <div className="row-group gap--xs flex-auto text-left">
                            <span className="ico">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20C11.905 20.0001 11.811 19.9819 11.7232 19.9463C11.6355 19.9108 11.5558 19.8587 11.4888 19.7929L4.58581 13.0466C2.4714 10.9801 2.4714 7.61757 4.58581 5.55112C6.61634 3.56523 9.87112 3.48538 12 5.31649C14.1274 3.4875 17.3822 3.56453 19.4142 5.55112C21.5286 7.61757 21.5286 10.9808 19.4142 13.0466L12.5112 19.7929C12.4442 19.8587 12.3645 19.9108 12.2768 19.9463C12.189 19.9819 12.095 20.0001 12 20ZM8.42126 5.41331C7.89892 5.41241 7.38155 5.51234 6.89883 5.70735C6.41611 5.90237 5.97754 6.18863 5.60831 6.54971C4.86336 7.27911 4.44498 8.26758 4.44498 9.29814C4.44498 10.3287 4.86336 11.3172 5.60831 12.0466L12 18.2933L18.3917 12.0466C19.1366 11.3172 19.555 10.3287 19.555 9.29814C19.555 8.26758 19.1366 7.27911 18.3917 6.54971C17.6454 5.82167 16.634 5.41277 15.5795 5.41277C14.525 5.41277 13.5136 5.82167 12.7672 6.54971L12.5112 6.79989C12.4442 6.86558 12.3645 6.91769 12.2767 6.95324C12.189 6.9888 12.095 7.0071 12 7.0071C11.905 7.0071 11.811 6.9888 11.7233 6.95324C11.6355 6.91769 11.5558 6.86558 11.4888 6.79989L11.2328 6.54971C10.8638 6.18864 10.4255 5.90237 9.94303 5.70735C9.46054 5.51233 8.94338 5.4124 8.42126 5.41331Z" fill="#FF4000" />
                                </svg>
                            </span>
                            <span className="font-semibold">{profile?.activity?.likes}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-aside__block">
                <h4 className="row-group gap--sm">
                    <span className="ico ico--xs image-wrapper">
                        <img src={require("../../../assets/img/star-ico.png")} alt="" />
                    </span>
                    <span className="info text--lg">Badges</span>
                </h4>
                <div className="badge-group">
                    <div className="badge ico image-wrapper">
                        <img src={require("../../../assets/img/badge-reward.png")} alt="" />
                    </div>
                    <div className="badge ico image-wrapper">
                        <img src={require("../../../assets/img/badge-ribbonr.png")} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileSidebar
