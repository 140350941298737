import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { getMe, getToken } from './store/auth/authSlice';
import { useSelector } from 'react-redux';
import PageLoader from './components/parts/PageLoader';
import { getMeFetch } from './store/auth/authThunks';
import { getAllPagesFetch } from './store/pages/pagesThunks';
import Ui from './pages/Ui';
import { AppRouters } from './pages/routes';
import io from 'socket.io-client';

const token = localStorage.getItem('authToken');

export const socket = io(`${process.env.REACT_APP_API_URL}`, {
  path: "/socket",
  transports: ["websocket"],
  auth: {
    token: token,
  },
});

function App() {
  const dispatch = useDispatch()
  const { me, token, isLoading,siteTheme , hasEditChanges } = useSelector(state => state.auth)
  
  useEffect(() => {
    if (!me) {
      dispatch(getMeFetch())
    }
    dispatch(getAllPagesFetch())
  }, [dispatch, token])

  useEffect(() => {
    const body = document.querySelector('body')
    if(siteTheme === 'dark') {
      body.classList.add('theme-dark')
    } else {
      body.classList.remove('theme-dark')
    }
  }, [siteTheme])

  return (
    <>
      <AppRouters />
      <ToastContainer />
      {
        isLoading ?
          <PageLoader isOverlay />
          :
          null
      }
      { }
    </>
  );
}

export default App;