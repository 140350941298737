import React, { useEffect, useLayoutEffect, useState } from 'react'


import Dialogs from './patrs/Dialogs';
import ChatInfo from './patrs/ChatInfo';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllConversationsFeth, getConversationByIdFeth, messagesMarkAsReadFeth } from '../../store/chat/chatThunk';
import MessagesPart from './patrs/MessagesPart';
import PageLoader from '../../components/parts/PageLoader';


import { useSelector } from 'react-redux';
import { setNewMessageToCats } from '../../store/chat/chatSlice';
import { io } from 'socket.io-client';
import { socket } from '../../App';



const Chat = () => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const [mobileChatPartActive, setMobileChatPartActive] = useState('users-list')
    const { allChats, currentChatData } = useSelector(state => state.chat)
    const { token } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState(false)
    const chatHandler = (value) => {
        
        setMobileChatPartActive(value)
    }
    
    useLayoutEffect(() => {
        setLoading(true)
    }, [])
    const sendAsReat = async (idsArray) => {
        if (idsArray.length > 0) {
            const res = await dispatch(messagesMarkAsReadFeth({ id: id, body: { "messageIds": idsArray } })).unwrap()
        }
    }
    useEffect(() => {
        
        socket.emit("subscribeToMessages");

        socket.on('newMessage', (msg) => {
            
            const findCurrentChatIndex = allChats?.findIndex(item => {
                return item.id === msg.userConversationId
            })
            console.log(allChats, msg, findCurrentChatIndex)
            if (+id === msg.userConversationId) {
                sendAsReat([msg.id])
            }
            if (findCurrentChatIndex >= 0) {
                dispatch(setNewMessageToCats({ message: msg, index: findCurrentChatIndex }))
            } else {
                dispatch(getAllConversationsFeth())
            }
        });
        
        return () => {
            socket.off('newMessage');
            // socket.emit("subscribeToMessages");
            // socket.disconnect()
        };
    }, [allChats]);
    if (loading) return <PageLoader />
    return (
        <main className="content overflow-hidden">
            <section className="chart-section">
                <div className="container h-full">
                    <div className="chart-section__row h-full">
                        <Dialogs setFirstLoad={setLoading} chatHandler={chatHandler} />
                        {id ? <>
                            <MessagesPart activeMobilePart={mobileChatPartActive} chatHandler={chatHandler} />
                            <ChatInfo activeMobilePart={mobileChatPartActive} chatHandler={chatHandler} />
                        </> : 
                            <div className='empty-selected-chat flex-auto flex items-center justify-center flex-col gap-3'>
                                <h4>No chat selected.</h4>
                                <p>Select the chat you need to start communicating</p>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Chat