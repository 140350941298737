import React from 'react'

const RadioButtonQuiz = ({children,name , ...props}) => {
    return (
        <label className="quiz-answer-item">
            <input type="radio" name={name} hidden={true} {...props}/>
            <span className="quiz-answer-item__body">
                <span className="quiz-answer-item__icon"></span>
                {children}
            </span>
        </label>
    )
}

export default RadioButtonQuiz
