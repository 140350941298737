import React, { useEffect, useMemo, useState } from 'react'
import Pagination from '../../components/parts/Pagination'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import SwitchViewType from '../../components/parts/SwitchViewType'
import CardMaterials from '../../components/parts/Cards/CardMaterials'
import { data } from "../../data"
import { filterOptions } from '../../helpers/constants'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import PageLoader from '../../components/parts/PageLoader'
import { getProfileMaterialsFeth } from '../../store/profile/profileThunk'

const tabList = [
    {
        name: 'Documents',
        icon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1574 7.25742C17.1273 7.13281 17.0715 7.04257 17.007 6.96523L13.4105 3.02499C13.3289 2.93476 13.2344 2.89179 13.127 2.83593C13.0023 2.77148 12.8648 2.76718 12.723 2.76718H5.85234C5.31953 2.76718 4.83398 3.17968 4.83398 3.75117V18.1543C4.83398 18.7301 5.31953 19.2672 5.85234 19.2672H16.298C16.8309 19.2672 17.209 18.7301 17.209 18.1543V7.66562C17.209 7.51093 17.1918 7.39921 17.1574 7.25742ZM13.127 4.76953L15.6191 7.49804H13.127V4.76953ZM6.20898 17.8965V4.14648H11.752V7.65702C11.752 8.29296 12.3277 8.87304 12.9594 8.87304H15.834V17.8965H6.20898Z" fill="currentColor"></path>
        </svg>
    },
    {
        name: 'Videos',
        icon: <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.725 1.1L14.5 3.825V3C14.5 1.9 13.6 1 12.5 1H2.5C1.4 1 0.5 1.9 0.5 3V11C0.5 12.1 1.4 13 2.5 13H12.5C13.6 13 14.5 12.1 14.5 11V10.175L18.725 12.9C19.2 13.15 19.5 12.725 19.5 12.475V1.5C19.5 1.25 19.225 0.8 18.725 1.1ZM13.5 11C13.5 11.55 13.05 12 12.5 12H2.5C1.95 12 1.5 11.55 1.5 11V3C1.5 2.45 1.95 2 2.5 2H12.5C13.05 2 13.5 2.45 13.5 3V11ZM18.5 11.575L14.5 9V5L18.5 2.425V11.575Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"></path>
        </svg>
    },
    {
        name: 'Images',
        icon: <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2667 3H6.73333C5.74319 3 4.7936 3.39333 4.09347 4.09347C3.39333 4.7936 3 5.74319 3 6.73333V15.2667C3 16.2568 3.39333 17.2064 4.09347 17.9065C4.7936 18.6067 5.74319 19 6.73333 19H15.2667C16.2568 19 17.2064 18.6067 17.9065 17.9065C18.6067 17.2064 19 16.2568 19 15.2667V6.73333C19 5.74319 18.6067 4.7936 17.9065 4.09347C17.2064 3.39333 16.2568 3 15.2667 3ZM6.73333 4.06667H15.2667C15.9739 4.06667 16.6522 4.34762 17.1523 4.84772C17.6524 5.34781 17.9333 6.02609 17.9333 6.73333V11.672L15.992 9.4C15.8346 9.22365 15.6401 9.08436 15.4225 8.99215C15.2049 8.89993 14.9695 8.85708 14.7333 8.86667C14.4962 8.87364 14.2636 8.93325 14.0524 9.04117C13.8412 9.14909 13.6566 9.30263 13.512 9.49067L11.5867 11.9813C11.5429 12.0377 11.4882 12.0846 11.4259 12.1194C11.3636 12.1542 11.2949 12.1762 11.224 12.184C11.1529 12.1923 11.0808 12.1862 11.0121 12.166C10.9433 12.1459 10.8794 12.1121 10.824 12.0667L8.91467 10.4667C8.61287 10.2136 8.22837 10.0808 7.83475 10.0936C7.44113 10.1064 7.06606 10.2639 6.78133 10.536L4.06667 13.1333V6.73333C4.06667 6.02609 4.34762 5.34781 4.84772 4.84772C5.34781 4.34762 6.02609 4.06667 6.73333 4.06667ZM15.2667 17.9333H6.73333C6.02609 17.9333 5.34781 17.6524 4.84772 17.1523C4.34762 16.6522 4.06667 15.9739 4.06667 15.2667V14.616L7.528 11.3307C7.62533 11.2452 7.75045 11.198 7.88 11.198C8.00955 11.198 8.13467 11.2452 8.232 11.3307L10.1413 12.9307C10.3068 13.0677 10.4982 13.17 10.7041 13.2315C10.91 13.2929 11.1261 13.3123 11.3397 13.2884C11.5532 13.2645 11.7597 13.1978 11.947 13.0924C12.1342 12.987 12.2982 12.8449 12.4293 12.6747L14.36 10.184C14.408 10.1215 14.4693 10.0705 14.5393 10.0345C14.6094 9.99858 14.6866 9.97859 14.7653 9.976C14.8442 9.97443 14.9224 9.99036 14.9943 10.0226C15.0662 10.0549 15.1301 10.1027 15.1813 10.1627L17.9333 13.3627V15.2667C17.9333 15.9739 17.6524 16.6522 17.1523 17.1523C16.6522 17.6524 15.9739 17.9333 15.2667 17.9333Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"></path>
            <path d="M7 9C7.39556 9 7.78224 8.8827 8.11114 8.66294C8.44004 8.44318 8.69638 8.13082 8.84776 7.76537C8.99913 7.39991 9.03874 6.99778 8.96157 6.60982C8.8844 6.22186 8.69392 5.86549 8.41421 5.58579C8.13451 5.30608 7.77814 5.1156 7.39018 5.03843C7.00222 4.96126 6.60008 5.00087 6.23463 5.15224C5.86918 5.30362 5.55682 5.55996 5.33706 5.88886C5.1173 6.21776 5 6.60444 5 7C5 7.53043 5.21071 8.03914 5.58579 8.41421C5.96086 8.78929 6.46957 9 7 9ZM7 6.14286C7.16953 6.14286 7.33525 6.19313 7.4762 6.28731C7.61716 6.3815 7.72702 6.51536 7.7919 6.67199C7.85677 6.82861 7.87375 7.00095 7.84067 7.16722C7.8076 7.33349 7.72596 7.48622 7.60609 7.60609C7.48622 7.72597 7.33349 7.8076 7.16722 7.84067C7.00095 7.87375 6.82861 7.85677 6.67199 7.7919C6.51536 7.72702 6.3815 7.61716 6.28731 7.4762C6.19313 7.33525 6.14286 7.16953 6.14286 7C6.14286 6.77267 6.23316 6.55465 6.39391 6.39391C6.55465 6.23316 6.77267 6.14286 7 6.14286Z" fill="currentColor" stroke="currentColor" strokeWidth="0.2"></path>
        </svg>
    },
]

const ProfileMaterials = () => {
    
    const [isList, setIsList] = useState(false)
    const [activeTab, setActiveTab] = useState('Images')
    const { authorized,  showLoginModal } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const [userId] = useOutletContext()
    const { profileData, isLoading, pagination } = useSelector(state => state.profile);
    const [searchParams, setSearchParams] = useSearchParams()
    // const [params, setParams] = useState({
    //     sort: 'latest',
    // })
    const formatOptionsQuery = (value) => {
		return value?.length
			? value.split(',').filter(Boolean) || []
			: []
	}
	const params = useMemo(() => ({
		sort: searchParams.get('sort') || 'latest',
	}), [searchParams])
    const handleSortChange = (option) => {
        // setParams(prev => ({
        //     ...prev,
        //     sort: option.value
        // }))
        setSearchParams({
            ...params,
            sort: option.value,
        });
    }
    useEffect(() => {
        const type = activeTab === 'Images' ? 'image': activeTab === 'Videos' ? 'video' : 'doc'
        // setParams(prev => ({
        // ...prev,
        // type: type
        // }))
        setSearchParams({
        ...params,
        type: type
        });
      }, [activeTab])
    useEffect(() => {
        if (userId) {
            dispatch(getProfileMaterialsFeth({ id: userId, qwery: `${searchParams.toString()}` }))
        }
    }, [userId, searchParams])
    
    return (
        <div className="tabs-item active-tab" id="quizzes">
            <div className="w-full">
                <div className="page-heading mb-[30px]">
                    <div className="page-heading__item">
                        <h3 className="mr-[24px]">Materials Library</h3>
                        <SwitchViewType onChange={(checked) => setIsList(checked)} />
                    </div>
                    <div className="page-heading__item">
                        <SelectGroup
                            wrapperClasses={'input--sm'}
                            options={filterOptions}
                            defaultValue={filterOptions[0]}
                            onChange={handleSortChange}
                        />
                    </div>
                </div>
                <div className="tabs">
                    <ul className="tabs-nav tabs-nav--no-border mb-[30px]">
                        {tabList.map((item, index) => (
                            <li key={index}>
                                <button
                                    type='button'
                                    className={`
                                        btn btn--md btn-tablink btn-tablink-row rounded-full 
                                        ${item.name === activeTab ? 'active-tab' : ''}
                                    `}
                                    onClick={() => setActiveTab(item.name)}
                                >
                                    <span className="ico">
                                        {item.icon}
                                    </span>
                                    <span>{item.name}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="tabs-body">
                        <div className="tabs-item active-tab">
                            <div className="row gutters-cards">
                                {
                                    profileData && profileData.length && !isLoading ?
                                        profileData.map(card => (
                                            <div key={card.id} className={!isList ? "col-sm-6 col-lg-4" : "col-12"} >
                                                <CardMaterials data={card} isList={isList} isDocument={activeTab === "Documents"} isVideo={activeTab === "Videos"} authorized={authorized} />
                                            </div>
                                        ))
                                        :isLoading ?
                                        <PageLoader /> :
                                        <h2>Page is empty</h2>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination {...pagination} params={params} />
            </div>
        </div>
    )
}

export default ProfileMaterials
