import { useEffect, useState } from 'react';
import CheckBoxGroup from '../UI/formElements/CheckBoxGroup';

const Filters = ({ filters, defaultCheked = [], onChange }) => {
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [showAll, setShowAll] = useState({});
    const maxItemsForShow = 10
    const toggleShowAll = (title) => {
        setShowAll(prevShowAll => ({
            ...prevShowAll,
            [title]: !prevShowAll[title]
        }));
    };
    const isChecked = (option) => checkedOptions?.length ? checkedOptions.includes(option?.name) : false;

    useEffect(() => {
        setCheckedOptions(defaultCheked)
    }, [defaultCheked])

    return (
        <>
            {
                filters && filters.length ?
                    filters.map((filter, index) => (
                        <div key={index} className="section-filters__aside--item">
                            <span className="sidebar-label">{filter.title}</span>
                            <div className="checkboxes-list">
                                {filter?.checkboxOptions?.slice(0, showAll[filter.title] ? filter?.checkboxOptions?.length : maxItemsForShow).map((option, idx) => (
                                    <CheckBoxGroup key={idx} value={option.name} checked={isChecked(option)} onChange={() => onChange(option, filter?.type)}>
                                        <p className="check-text">{option.name}</p>
                                    </CheckBoxGroup>
                                ))}
                            </div>
                            {
                                filter?.checkboxOptions?.length > maxItemsForShow ?
                                    <button className="btn text-[13px] justify-start color-danger" onClick={() => toggleShowAll(filter.title)}>
                                        {showAll[filter.title] ? 'Show Less' : 'Show More'}
                                    </button>
                                    :
                                    null
                            }
                        </div>
                    ))
                    :
                    null
            }
        </>
    )
}

export default Filters