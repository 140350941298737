import React from 'react'
import { Link } from 'react-router-dom'

const HeaderQuiz = ({prev, next, name, desctiption}) => {
    return (
        <header className="header header--quize sidebar-left-fixed">
            <div className="container">
                <div className="header__body">
                    <Link to={prev} className={`btn btn--shadow gap-[10px] btn--xs radius-5 ${!prev ? 'disabled' : ''}`}>
                        <span className="ico">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.1665 10H15.8332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path d="M8.33317 5.83334L4.1665 10" stroke="currentColor" strokeWidth="1.5"
                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.33317 14.1667L4.1665 10" stroke="currentColor" strokeWidth="1.5"
                                    strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="info text-[13px] font-[500] sm:flex hidden">Previous Quiz</span>
                    </Link>
                    <div className="quiz-info">
                        <p className="quize-title">{name}</p>
                        <p className="quize-desk">{desctiption}</p>
                    </div>
                    <Link to={next} className={`btn btn--shadow gap-[10px] btn--xs radius-5 ${!next ? 'disabled' : ''}`}>
                        <span className="info text-[13px] font-[500] sm:flex hidden">Next Quiz</span>
                        <span className="ico">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.8332 10H4.1665" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path d="M11.6665 14.1667L15.8332 10" stroke="currentColor" strokeWidth="1.5"
                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M11.6665 5.83334L15.8332 10" stroke="currentColor" strokeWidth="1.5"
                                    strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>

                        </span>
                    </Link>
                </div>
            </div>
        </header>
    )
}

export default HeaderQuiz