import { ButtonView, Plugin } from "ckeditor5";
import { getImageSrc } from "../helpers/utils";
import { toast } from "react-toastify";
import axios from "axios";
export default class VideoUploadPlugin extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add('uploadVideo', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Upload Video',
                tooltip: true,
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"  fill="none">
            <path d="M10.5 3C10.8452 3 11.125 3.27983 11.125 3.625V6.25L14.3833 3.96919C14.5247 3.87022 14.7196 3.90461 14.8185 4.046C14.8552 4.09853 14.875 4.16109 14.875 4.22521V11.7748C14.875 11.9474 14.7351 12.0873 14.5625 12.0873C14.4984 12.0873 14.4358 12.0676 14.3833 12.0308L11.125 9.75V12.375C11.125 12.7202 10.8452 13 10.5 13H1.75C1.40483 13 1.125 12.7202 1.125 12.375V3.625C1.125 3.27983 1.40483 3 1.75 3H10.5ZM5.5 5.5V7.375H3.625V8.625H5.49937L5.5 10.5H6.75L6.74937 8.625H8.625V7.375H6.75V5.5H5.5Z" fill="black"/>
            <script xmlns=""/>
            </svg>
            `
            });

            // Обработчик нажатия на кнопку
            view.on('execute', () => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'video/*';
                input.onchange = () => {
                    const file = input.files[0];

                    // Отправка видео на сервер
                    const formData = new FormData();
                    formData.append('file', file);
                    toast.info('Uploading...', {
                        toastId: 'uploadProgress', // Уникальный идентификатор для обновления
                        autoClose: false, // Отключаем авто-закрытие
                        closeButton: false, // Отключаем кнопку закрытия
                        progress: 0, // Начальный прогресс
                    });
                    // fetch(`${process.env.REACT_APP_API_URL}/api/articles/upload`, {
                    //     method: 'POST',
                    //     headers: {
                    //         Authorization: `token ${localStorage.getItem('authToken')}`,
                    //     },
                    //     body: formData
                    // })
                    //     .then(response => response.json())
                    //     .then(data => {
                    //         console.log(data)
                    //         editor.model.change(writer => {
                    //             // const videoElement = writer.createElement('video', {
                    //             //     src: getImageSrc(data.url)
                    //             // });

                    //             // const figure = writer.createElement('figure', { class: 'media' });
                    //             // writer.append(videoElement, figure);



                    //             // editor.model.insertContent(figure, editor.model.document.selection);


                    //             // const figure = writer.createElement('figure', { class: 'media' });


                    //             // const mediaEmbed = writer.createElement('mediaEmbed', {
                    //             //     source: getImageSrc(data.url)
                    //             // });

                    //             // console.log(figure)
                    //             // writer.append(mediaEmbed, figure);
                    //             // editor.model.insertContent(writer.createElement('img', {
                    //             //     src: getImageSrc(data.url)
                    //             // }), editor.model.document.selection);

                    //             const insertPosition = editor.model.document.selection.getFirstPosition();
                    //             const imageHtml = `<span class='editor-video'><video controls class='editor-video' data-video='video' src=${getImageSrc(data.url)}></video></span>`;
                    //             const viewFragment = editor.data.processor.toView(imageHtml);
                    //             const modelFragment = editor.data.toModel(viewFragment);
                    //             writer.insert(modelFragment, insertPosition);
                    //         });
                    //     })
                    //     .catch(error => {
                    //         console.error('Error uploading video:', error);
                    //     });
                    axios.post(`${process.env.REACT_APP_API_URL}/api/articles/upload`, formData, {
                        headers: {
                            Authorization: `token ${localStorage.getItem('authToken')}`,
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                            // // Обновляем прогресс в состоянии
                            // store.dispatch(setUploadProgress(percentCompleted));

                            // Обновляем toast с прогрессом
                            toast.update('uploadProgress', {
                                render: `Uploading: ${percentCompleted}%`,
                                progress: percentCompleted / 100,
                            });

                            // Закрываем toast, когда достигли 100%
                            // if (percentCompleted === 100) {
                            //     setTimeout(() => {
                            //         toast.dismiss('uploadProgress');
                            //     }, 500); // Небольшая задержка для завершения анимации
                            // }
                        },
                    })
                        .then(response => {
                            const data = response.data
                            editor.model.change(writer => {
                                const insertPosition = editor.model.document.selection.getFirstPosition();
                                const imageHtml = `<span class='editor-video'><video controls class='editor-video' data-video='video' src=${getImageSrc(data.url)}></video></span>`;
                                const viewFragment = editor.data.processor.toView(imageHtml);
                                const modelFragment = editor.data.toModel(viewFragment);
                                writer.insert(modelFragment, insertPosition);
                            });

                        })
                        .catch(error => {
                            console.error('Error uploading video:', error);
                        }).finally(eve => {
                            toast.update('uploadProgress', {
                                render: `Uploading: 100%`,
                                progress: 100,
                            });
                            setTimeout(() => {
                                toast.dismiss('uploadProgress');
                            }, 500);
                        });
                };

                input.click();
            });

            return view;
        });
    }
}


