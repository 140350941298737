const pagesBase = 'api/contact-us';

export const contactUs = (instance) => ({
    async createMessage(payload) {
       
        try {
            const { data } = await instance.post(`${pagesBase}`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});