import React from 'react'
import { ReactComponent as ListIcon } from "../../../assets/img/ico-list-bullets.svg"
import Actions from './Actions'
import { getDateToString, getImageSrc } from '../../../helpers/utils'
import { setShowLoginModal } from '../../../store/auth/authSlice'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { favoriteForumFeth, likeForumFeth } from '../../../store/forums/forumsThunk'

const CardForum = ({ data, isList, authorized, hideActions = false }) => {
    const dispatch = useDispatch();
    const toggleLike = () => {
        if(authorized) {
            dispatch(likeForumFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true)) 
        }


    }
    const toggleFavorite = () => {
        if(authorized) {
            dispatch(favoriteForumFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true))
        }
    }
    console.log(data)
    return (
        <>
            {
                !isList ?
                    <div className="card card-post">
                        <div className="card-header post-image wrapp-image-sm">

                            <Link
                                to={`${authorized ? `/forums/${data?.id}/` : '#'}`}
                                className='flex w-full'
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }}
                            >
                                {data?.cover && <img style={{ width: '100%' }} src={getImageSrc(data?.cover)} alt={data?.title} />}
                                {!data?.isCompleted && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}
                            </Link>
                            {!hideActions && <Actions handleLikeProps={toggleLike} handleAddToFavoritesProps={toggleFavorite} isFavoriteProps={data?.isFavorite} isLikeProps={data?.isLiked} />}
                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <div className="group-between">
                                    <div className="pill-custom pill-2 ">
                                        <span className="pill-text">{data?.filter?.name}</span>
                                    </div>
                                </div>
                                <Link
                                    to={`${authorized ? `/forums/${data?.id}/` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}
                                >
                                    <h5 className="heading post-title color-default">{data?.name}</h5>
                                </Link>
                                <div className="post-others">
                                    <p className="text-[13px] leading-[16px]">
                                        by {" "}
                                        <Link to={`/profile/${data?.user?.id}`}>
                                            <span className="color-danger">{(data?.user?.firstName || '') + ' ' + (data?.user?.lastName || '')}</span>
                                        </Link>
                                    </p>
                                    <p className="mini-post--desk">{getDateToString(data?.createdAt)}</p>
                                    <div className="row-group gap--xs">
                                        <div className="btn gap--xxs">
                                            <div className="pill gap--xs p-0">
                                                <span className="ico">
                                                    <ListIcon />
                                                </span>
                                                <span>{data?.threadsCount}</span>
                                            </div>
                                        </div>
                                        {data?.randomUsers?.length > 0 && <div className="user-group">
                                            {data?.randomUsers?.map((user, index) => <img key={index} src={getImageSrc(user.photo)} className="user-group__item" alt="" style={{ "--i": index }} />)}
                                            <span className="text--sm color-secondary ml-[8px]">+{data?.uniqueUsersCount
                                            }</span>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="card card-post --card-row card-post--md">
                        <div className="card-header post-image">
                            <Link
                                to={`${authorized ? `/forums/${data?.id}/` : '#'}`}
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }}
                                className='card-header post-image'
                            >
                                {data?.cover && <img style={{ width: '100%' }} src={getImageSrc(data?.cover)} alt={data?.title} />}
                                {!data?.isCompleted && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}
                            </Link>
                            <Actions handleLikeProps={toggleLike} handleAddToFavoritesProps={toggleFavorite} isFavoriteProps={data?.isFavorite} isLikeProps={data?.isLiked} />
                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <Link
                                    to={`${authorized ? `/forums/${data?.id}/` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}
                                >
                                    <h5 className="heading post-title color-default">{data?.name}</h5>
                                </Link>
                                <div className="post-others">
                                    <p className="text-[14px]">
                                        by {" "}
                                        <Link to={`/profile/${data?.user?.id}`}>
                                            <span className="color-danger">{(data?.user?.firstName || '') + ' ' + (data?.user?.lastName || '')}</span>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="card-body--item max-md:hidden">
                                <p className="post--desk">{data?.description}</p>
                            </div>
                            <div className="card-body--item">
                                <div className="list-group-row list-group--md">
                                    <div className="pill-custom pill-2">
                                        <span className="pill-text">{data?.filter?.name}</span>
                                    </div>
                                    <div className="pill gap--xs p-0">
                                        <span className="ico">
                                            <ListIcon />
                                        </span>
                                        <span>{data?.threadsCount}</span>
                                    </div>
                                    {data?.randomUsers?.length > 0 && <div className="user-group">
                                        {data?.randomUsers?.map((user, index) => <img key={index} src={getImageSrc(user.photo)} className="user-group__item" alt="" style={{ "--i": index }} />)}
                                        <span className="text--sm color-secondary ml-[8px]">+{data?.uniqueUsersCount
                                        }</span>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default CardForum
