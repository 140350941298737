import React from 'react';
import ReactPlayer from 'react-player';

const MediaRenderer = ({ url }) => {
  // Регулярные выражения для проверки расширения файла
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
  const videoExtensions = /\.(mp4|webm|ogg|mov|avi|wmv|flv|mkv)$/i;

  if (imageExtensions.test(url)) {
    return <img src={url} alt="Image" style={{ maxWidth: '100%', height: 'auto' }} />;
  } else if (videoExtensions.test(url)) {
    return (
      <div className="video-wrapper relative">
        <ReactPlayer
          url={url}
          controls
          className={`mb-0 flex`}
          width="100%"
          // controlsList="nodownload"
          height="auto"
        />
      </div>
    );
  } else {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }
};

export default MediaRenderer;