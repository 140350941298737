import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const DatePickerGroup = ({ label, wrapperClasses = '', inputClasses, placeholder, selectedDate, onChange ,error, errorMessage}) => {
    const handleChange = (date) => {
        onChange(date)
    }
    return (
        <div className={`input-wrapper ${wrapperClasses}`}>
            {label && <label className="label">{label}</label>}
            <DatePicker
                selected={selectedDate}
                onChange={handleChange}
                dateFormat="MMM dd, yyyy"
                className={`input ${inputClasses} ${error ? "input--error" : ""}`}
                placeholderText={placeholder}
            />
            {
                errorMessage && error ?
                    <p className='color-danger text--xs mt-1'>{errorMessage}</p>
                    :
                    null
            }
        </div>
    )
}

export default DatePickerGroup
