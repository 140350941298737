
import React, { useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const DataRangeGroup = ({ label, wrapperClasses = '', inputClasses, placeholder, selectedMinDate, selectedMaxDate, onChangeMin, onChangeMax, error, errorMessage }) => {
    return (
        <div className={`input-wrapper ${wrapperClasses}`}>
            {label && <label className="label">{label}</label>}
            <div className="range-group-wrapper">
                <DatePicker
                    maxDate={selectedMaxDate}
                    selected={selectedMinDate}
                    onChange={onChangeMin}
                    dateFormat="MMM dd, yyyy"
                    className={`input flex-1 ${inputClasses} ${error ? "input--error" : ""}`}
                    placeholderText={`${placeholder} start`}
                />
                <span className='date-deliver'>
                    <svg width="9" height="1" viewBox="0 0 9 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="0.5" x2="9" y2="0.5" stroke="#999999" />
                    </svg>
                </span>
                <DatePicker
                    minDate={selectedMinDate}
                    selected={selectedMaxDate}
                    onChange={onChangeMax}
                    dateFormat="MMM dd, yyyy"
                    className={`input flex-1 ${inputClasses}`}
                    placeholderText={`${placeholder} end`}
                />
            </div>
            {
                errorMessage && error ?
                    <p className='color-danger text--xs mt-1'>{errorMessage}</p>
                    :
                    null
            }
        </div>
    )
}

export default DataRangeGroup
