import React, { useEffect } from 'react'
import ProfileSidebar from '../../components/parts/profile/ProfileSidebar'
import { Link, NavLink, Outlet, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getMe, setShowCreateModal } from '../../store/auth/authSlice'
import { useDispatch } from 'react-redux'
import { getProfileByIdFeth } from '../../store/profile/profileThunk'

const Profile = () => {
    const dispatch = useDispatch()
    const me = useSelector(getMe)   
     
    const {id} = useParams()
    const {profile} = useSelector(state => state.profile);
    const navLlinks = [
        {
            name: 'Profile',
            route: ''
        },
        {
            name: 'Quizzes',
            route: 'quizzes'
        },
        {
            name: 'Courses',
            route: 'courses'
        },
        {
            name: 'Cases',
            route: 'cases'
        },
        {
            name: 'Articles',
            route: 'articles'
        },
        {
            name: 'Forums',
            route: 'forums'
        },
        {
            name: 'Threads',
            route: 'threads'
        },
        {
            name: 'Comments',
            route: 'comments'
        },
        {
            name: 'My Materials',
            route: 'my-materials'
        },
    ]
    const userId = id ? id : me?.id
    useEffect(() => {
        if(id || me?.id) {
            dispatch(getProfileByIdFeth(userId))
        }
    },[me, id])
    return (
        <main className="content">
            <div className="container">
                <section className="py-[32px]">
                    <div className="page-section--row page-section--row--reverse">
                        <div className="page-content">
                            <div className="page-heading mb-[32px]">
                                
                                {profile && profile?.user && <h2 className="mr-[24px]">{`${profile?.user?.firstName}  ${profile?.user?.lastName}`}</h2>}
                                {(!id || +me?.id === +id)  && <div className="row-group gap--md">
                                    <button type='button' onClick={() => dispatch(setShowCreateModal(true))} className="btn btn--primary btn--lg radius-5">
                                        <span className="ico">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_568_28312)">
                                                    <path d="M4 12H20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M12 4V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <span className="info">Create</span>
                                    </button>
                                    <Link to={'/settings'} className="btn btn--shadow btn--lg radius-5">
                                        <span className="info">Edit Profile</span>
                                    </Link>
                                </div>}
                            </div>
                            <div className="tabs settings-tabs w-full">
                                <div className="tabs-header">
                                    <ul className="tabs-nav">
                                        {navLlinks.map((item, index) => (
                                            <li className="tabs-nav--item" key={index}>
                                                <NavLink 
                                                    to={item.route}
                                                    end
                                                    className={({ isActive, isPending }) => `btn btn-tablink btn--md rounded-full ${isActive ? "active-tab" : ""}`
                                                    }>
                                                    <span>{item.name}</span>
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="tabs-body py-[32px]">
                                    <Outlet context={[userId]} />
                                </div>
                            </div>
                        </div>
                        <ProfileSidebar />
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Profile