import React, { useEffect, useState } from 'react'
import { CreateFormHeader } from '../../components/parts/CreateFormHeader'
import UploadFilesRowType from '../../components/UI/UploadFilesRowType'
import InputGroup from '../../components/UI/formElements/InputGroup'
import TextareaGroup from '../../components/UI/formElements/TextareaGroup'
import UploadImage from '../../components/UI/UploadImage'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Loader from '../../components/UI/Loader'
import { getImageSrc } from '../../helpers/utils'
import { addCaseMediaFilesByCaseIdFeth, editCaseMediaFilesByCaseIdFeth } from '../../store/cases/casesThunk'
import UploadImageSet from '../../components/UI/UploadImageSet'
import { toast } from 'react-toastify'
import { setHasEditChanges } from '../../store/auth/authSlice'


const CaseCreateImageVideo = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [caseOne, caseId] = useOutletContext();
    const { isLoading } = useSelector(state => state.cases)

    const [imagesData, setImagesData] = useState([])
    const [errors, setErrors] = useState({})
    const [videoData, setVideoData] = useState([]);
    const [notes, setNotes] = useState('')
    const tabs = [
        { label: 'Set of Images', value: 'image' },
        { label: 'Videos', value: 'video' },
        { label: 'Notes', value: 'notes' },
    ]

    const [activeTab, setActiveTab] = useState(tabs[0].value)
    const conf = {
        title: 'Image & Videos',
    }

    const imageHandler = (value) => {
        dispatch(setHasEditChanges(true))
        setImagesData(value)
    }
    const videoHandler = (value) => {
        dispatch(setHasEditChanges(true))
        setVideoData(value)
    }
    const inputsHandler = (value) => {
        dispatch(setHasEditChanges(true))
        setNotes(value)
    }

    useEffect(() => {
        if (caseOne?.caseContent?.notes) {
            setNotes(caseOne.caseContent.notes)
        } else {
            setNotes('')
        }
        if (caseOne?.caseContent?.images?.length > 0) {
            setImagesData(caseOne.caseContent.images.map(item => ({
                set: item.set,
                name: item.setName,
                activeIndex: item.images.findIndex(item => item.isCover),
                images: item.images.map(image => (
                    {
                        id: image.id,
                        preview: getImageSrc(image.fileName),
                        type: `image/${image.fileName.split('.').pop()}`,
                        order: image.order,
                        fileName: image.fileName
                    }
                ))
            })))
        } else {
            setImagesData([])
        }
        if (caseOne?.caseContent?.videos?.length > 0) {
            setVideoData(caseOne.caseContent.videos.map(item => ({
                id: item.id,
                title: item.name,
                fileName: item.fileName,
                preview: getImageSrc(item.fileName),
                type: `video/${item.fileName.split('.').pop()}`
            })))
        } else {
            setVideoData([])
        }
    }, [caseOne])

    // const submitHandler = async (e) => {
    //     e.preventDefault();
    //     if (imagesData.length === 0 || notes.length === 0) {
    //         toast.error('Images sets & Notes is required')
    //         return
    //     }
    //     const videos = videoData.filter(item => !item.id)
    //     const videosNames = videos.map(item => item.title && item.title.length > 0 ? item.title : null)
    //     const newImagesData = imagesData.filter(item => !item.set)
    //     const imagesNames = newImagesData.map(item => ({
    //         "setName": item.name,
    //         "coverIndex": item.activeIndex
    //     }))
    //     const oldVideos = videoData.filter(item => item.id).map(video => ({
    //         id: video.id,
    //         name: video.title && video.title.length > 0 ? video.title : null
    //     }))

    //     const oldImagseData = imagesData.filter(item => item.set).map((imagesSet, idx) => {
    //         const oldCoverImage = caseOne?.caseContent?.images[idx].images.map(image => (
    //             {
    //                 id: image.id,
    //                 isCover: image.isCover
    //             }
    //         )).filter(item => item.isCover)
    //         oldCoverImage[0].isCover = false
    //         return {
    //             set: imagesSet.set,
    //             setName: imagesSet.name,
    //             images: imagesSet.images.map((image, index) => ({
    //                 id: image.id,
    //                 isCover: imagesSet.activeIndex === index
    //             })).filter(item => item.isCover).concat(oldCoverImage)
    //         }
    //     })


    //     const data = {
    //         images: oldImagseData,
    //         videos: oldVideos
    //     }
    //     const body = new FormData()
    //     videos.map(item => {
    //         body.append('video', item)
    //     })
    //     body.append('video_names', JSON.stringify(videosNames))

    //     body.append('images_data', JSON.stringify(imagesNames))
    //     imagesData.forEach((set, setIndex) => {
    //         if (!set.set) {
    //             set.images.forEach(image => {
    //                 body.append(setIndex, image)
    //             })
    //         }
    //     })
    //     if (oldVideos || oldImagseData) {
    //         body.append('data', JSON.stringify(data))
    //     }
    //     body.append('notes', notes)

    //     let res = null

    //     if (caseOne?.caseContent.images.length > 0) {
    //         res = await dispatch(editCaseMediaFilesByCaseIdFeth({ id: caseId, body: body })).unwrap()
    //     } else {
    //         res = await dispatch(addCaseMediaFilesByCaseIdFeth({ id: caseId, body: body })).unwrap()
    //     }
    //     if (res) {

    //         if (id) {
    //             navigate(`/case-edit/${id}/comments`)
    //         } else {
    //             navigate(`/case-create/comments`)
    //         }
    //         dispatch(setHasEditChanges(false))
    //     }
    // }


    const submitHandler = async (e) => {
        e.preventDefault();
        if (imagesData.length === 0 || notes.length === 0) {
            toast.error('Images sets & Notes is required')
            return
        }
        const images = imagesData.map((item, index) => ({
            set: index,
            setName: item.name,
            images: item.images?.map(image => ({
                fileName: image?.name || image?.fileName,
                isCover: image?.order === item.activeIndex,
                order: image?.order,
                ...(image.id ? { id: image.id } : {})
            })) || []
        })) || []
        
        const videos = videoData.map((item, index) => ({
            fileName: item.name || item.fileName,
            name: item.title,
            ...(item.id ? { id: item.id } : {})
        })) || []

        const data = {
            images,
            videos
        }
        
        const body = new FormData()

        body.append('data', JSON.stringify(data))

        imagesData.forEach((item, index) => {
            item.images.filter(image => image instanceof File).forEach(image => {
                body.append(index, image, image.name)
            })
        })
        videoData.filter(image => image instanceof File).forEach(item => {
            body.append('video', item, item.name)
        })
        body.append('notes', notes)

        let res = null

        if (caseOne?.caseContent?.images?.length > 0) {
            res = await dispatch(editCaseMediaFilesByCaseIdFeth({ id: caseId, body: body })).unwrap()
        } else {
            res = await dispatch(addCaseMediaFilesByCaseIdFeth({ id: caseId, body: body })).unwrap()
        }
        if (res) {

            if (id) {
                navigate(`/case-edit/${id}/comments`)
            } else {
                navigate(`/case-create/comments`)
            }
            dispatch(setHasEditChanges(false))
        }
    }



    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="tabs gap-[16px]">
                                        <div className="tabs-header">
                                            <ul className="tabs-nav flex-wrap">
                                                {tabs.map((tab, index) => (
                                                    <li className="tabs-nav--item max-w-max" key={index}>
                                                        <button type='button'
                                                            onClick={() => { setActiveTab(tab.value) }}
                                                            className={`btn btn-tablink btn--xs rounded-full  bg-transparent px-[10px] 
                                                            ${tab.value === activeTab && 'active-tab'}`}
                                                        >
                                                            <span className="text-[13px]">{tab.label}</span>
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="tabs-body">
                                            {activeTab === 'image' && <div className="tabs-item active-tab gap-[16px]">
                                                <UploadImageSet
                                                    defaulImages={imagesData}
                                                    onChange={(value) => imageHandler(value)}
                                                />
                                            </div>}
                                            {activeTab === 'video' && <div className="tabs-item active-tab gap-[16px]" >
                                                <UploadImage
                                                    defaulImages={videoData}
                                                    isRow={true}
                                                    type={activeTab}
                                                    multiple={true}
                                                    onChange={(value) => videoHandler(value)}
                                                />
                                            </div>}
                                            {activeTab === 'notes' && <div className="tabs-item active-tab gap-[16px]" >

                                                <div className="input-wrapper">
                                                    <textarea
                                                        value={notes}
                                                        onChange={({ target }) => inputsHandler(target.value)}
                                                        className="input input--outline p-[16px]"
                                                        placeholder="Enter your notes..." rows="7"></textarea>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/cases'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4163 10.9998H4.58301" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 15.5833L17.4163 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 6.4165L17.4163 10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default CaseCreateImageVideo
