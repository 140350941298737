import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import UploadImage from "../../components/UI/UploadImage";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getIsLoading, getQuizMedia } from "../../store/quizzes/quizzesSlice";
import { useDispatch } from "react-redux";
import { addQuizMediaFilesByQuizIdFeth, editQuizMediaFilesByQuizIdFeth, getQuizMediaByQuizIdFeth } from "../../store/quizzes/quizzesThunks";
import { getFormData, getImageSrc } from "../../helpers/utils";
import Loader from "../../components/UI/Loader";
import { toast } from "react-toastify";
import PageLoader from "../../components/parts/PageLoader";
import { setHasEditChanges } from "../../store/auth/authSlice";


export const QuizCreateImage = () => {
    const {id} = useParams()
    const dispach = useDispatch()
    const navigate = useNavigate();
    const [quizId, quiz] = useOutletContext();
    const { quizMedia } = useSelector(state => state.quizzes)
    const [activeTab, setActiveTab] = useState('image') // video notes
    const [imagesData, setImagesData] = useState([])
    const [errors, setErrors] = useState({})
    const [videoData, setVideoData] = useState([]);
    const [notes, setNotes] = useState('')
    const isLoading = useSelector(getIsLoading)
    const conf = {
        title: 'Image & Videos',
    }
    const imageHandler = (value) => {
        dispach(setHasEditChanges(true))
        setImagesData(value)
    }
    const videoHandler = (value) => {
        dispach(setHasEditChanges(true))
        setVideoData(value)
    }
    const inputsHandler = (value) => {
        dispach(setHasEditChanges(true))
        setNotes(value)
    }
    const changeActiveTab = (e) => {
        setActiveTab(e.currentTarget.id)
    }
    const submitHandler = async (e) => {
        e.preventDefault();
       
        if(notes?.length < 1 || !notes?.length) {
            
            toast.error('Notes is required field')
            return
        }
        const mediaAll = imagesData.concat(videoData)
        const media = mediaAll.filter(item => !item.id)
        const names = media.map(item => item.title && item.title.length > 0 ? item.title : null);
        const existing_media = mediaAll.filter(item => item.id).map(item => ({
            id: item.id,
            name: item.title
        }));
        const body = new FormData()
        media.map(item => {
            body.append('media', item)
        })
        if(existing_media) {
            body.append('existing_media', JSON.stringify(existing_media))
        }
        body.append('media_names', JSON.stringify(names))
        body.append('notes', notes)
        let res = null
        toast.info('Uploading...', {
            toastId: 'uploadProgress', // Уникальный идентификатор для обновления
            autoClose: false, // Отключаем авто-закрытие
            closeButton: false, // Отключаем кнопку закрытия
            progress: 0, // Начальный прогресс
        });
        if (quizMedia) {
            res = await dispach(editQuizMediaFilesByQuizIdFeth({ id: quizId, body: body })).unwrap()
        } else {
            res = await dispach(addQuizMediaFilesByQuizIdFeth({ id: quizId, body: body })).unwrap()
        }
        if (res) {
            if(id) {
                navigate(`/quiz-edit/${id}/hints`)
            } else {
                navigate('/quiz-create/hints')
            }
            dispach(setHasEditChanges(false))
        }
    }
    
    useEffect(() => {
        if(quizMedia?.notes) {
            setNotes(quizMedia?.notes)
        } else {
            setNotes('')
        }
        if(quizMedia?.images?.length > 0) {
            setImagesData(quizMedia.images.map(item => ({
                id: item.id,
                title: item.name,
                preview: getImageSrc(item.fileName),
                type: `image/${item.fileName.split('.').pop()}`
            })))
        } else {
            setImagesData([])
        }
        if(quizMedia?.videos?.length > 0) {
            setVideoData (quizMedia.videos.map(item => ({
                id: item.id,
                title: item.name,
                preview: getImageSrc(item.fileName),
                type: `video/${item.fileName.split('.').pop()}`
            })))
        } else {
            setVideoData([])
        }
    },[quizMedia])
    useEffect(() => {
        if(!quizId) return
        dispach(getQuizMediaByQuizIdFeth(quizId))
    }, [quizId])
    if (isLoading || !quizMedia) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader/>
        </div>
    )
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="tabs gap-[16px]">
                                        <div className="tabs-header">
                                            <ul className="tabs-nav flex-wrap">
                                                <li className="tabs-nav--item max-w-max">
                                                    <button onClick={changeActiveTab} id={'image'} data-href="#images" className={`btn btn-tablink btn--xs rounded-full bg-transparent px-[10px] ${activeTab === 'image' ? 'active-tab' : ''}`}>
                                                        <span className="text-[13px]">Images</span>
                                                    </button>
                                                </li>
                                                <li className="tabs-nav--item max-w-max">
                                                    <button onClick={changeActiveTab} id={'video'} data-href="#videos" className={`btn btn-tablink btn--xs rounded-full bg-transparent px-[10px] ${activeTab === 'video' ? 'active-tab' : ''}`}>
                                                        <span className="text-[13px]">Videos</span>
                                                    </button>
                                                </li>
                                                <li className="tabs-nav--item max-w-max">
                                                    <button onClick={changeActiveTab} id={'notes'} data-href="#notes" className={`btn btn-tablink btn--xs rounded-full bg-transparent px-[10px] ${activeTab === 'notes' ? 'active-tab' : ''}`}>
                                                        <span className="text-[13px]">Notes</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tabs-body">
                                            <div className={`tabs-item gap-[16px] ${activeTab === 'image' ? 'active-tab' : ''}`} id="images">
                                                <UploadImage
                                                    defaulImages={ imagesData }
                                                    onChange={(value) => imageHandler(value)}
                                                    isRow={true}
                                                    type={activeTab}
                                                    multiple={true}
                                                />
                                            </div>
                                            <div className={`tabs-item gap-[16px] ${activeTab === 'video' ? 'active-tab' : ''}`} id="videos">
                                                <UploadImage
                                                    defaulImages={videoData}
                                                    isRow={true}
                                                    type={activeTab}
                                                    multiple={true}
                                                    onChange={(value) => videoHandler(value)}
                                                />
                                            </div>
                                            <div className={`tabs-item gap-[16px] ${activeTab === 'notes' ? 'active-tab' : ''}`} id="notes">
                                                <div className="input-wrapper">
                                                    <textarea
                                                        value={notes}
                                                        onChange={({ target }) => inputsHandler(target.value)}
                                                        className="input input--outline p-[16px]"
                                                        placeholder="Enter your notes..." rows="7"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/quiz'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type="button"
                                        onClick={submitHandler}
                                        className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4163 10.9998H4.58301" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 15.5833L17.4163 11" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 6.4165L17.4163 10.9998" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>

                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}