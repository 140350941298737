import Chart from 'chart.js/auto';

export const initializeChart = (chartData, canvas) => {
    console.dir(canvas)
    const chart = new Chart(canvas, {
        type: chartData.type,
        data: {
            labels: chartData.labels,
            datasets: chartData.datasets.map(dataset => ({
                ...dataset,
                borderColor: dataset.backgroundColor,
                borderWidth: 1,
            })),
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: chartData.title
                }
            },
            scales: {
                y: {
                    display: chartData.type === 'pie' ? false : true,
                    beginAtZero: true,
                    min: chartData.yAxisMin,
                    max: chartData.yAxisMax,
                },
            },
        },
    });
    
    return chart
};