import React, { useEffect, useState } from 'react'
import BreadCrambs from '../../components/parts/BreadCrambs'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import PageLoader from '../../components/parts/PageLoader';
import { getArticleByIdFeth, getArticlesHistoryFeth } from '../../store/articles/articlesThunks';
import { format } from 'date-fns';
import Pagination from '../../components/parts/Pagination';

const ArticlesHistory = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { article, isLoading, articleHistory, pagination } = useSelector(state => state.articles)
    let navigate = useNavigate();
    const [params, setParams] = useState({})
    const [searchParams, setSearchParams] = useSearchParams({})
    const breadcramds = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/articles',
            label: 'Articles'
        },
        {
            link: '/articles/:id/history',
            label: articleHistory?.title
        },
    ]
    useEffect(() => {
        if (id) {
            dispatch(getArticlesHistoryFeth({ id, qwery: searchParams.toString() }));
        }
    }, [id, params])
    console.log(articleHistory)
    if (isLoading) return <PageLoader />
    return (
        <>

            <main className="content">
                <BreadCrambs breabcrambs={breadcramds} />
                <section className="page-section">
                    <div className="container">
                        <div className="page-section--row">
                            <div className="page-sidebar --none-border">
                                <aside className="sidebar banner-list sidebar-xs"></aside>
                            </div>
                            <div className="page-content">
                                <div className="heading-block">
                                    <div className="group group--sm py-[5px]">
                                        <button type='button' onClick={() => { navigate(-1) }} className="btn btn--shadow gap-[10px] btn--sm radius-5">
                                            <span className="ico">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.58398 11H17.4173" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M9.16732 6.41667L4.58398 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M9.16732 15.5833L4.58398 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                            <span className="info text-[13px] font-[500] sm:flex hidden">Back</span>
                                        </button>
                                    </div>
                                    <h2 className="heading">
                                        {articleHistory?.title}
                                    </h2>
                                </div>
                                <ul className="history-list">
                                    {articleHistory?.versions && articleHistory?.versions.map(version => (
                                        <li className="history-item" key={version.id}>
                                            <div className="history-text  color-danger before-point">
                                                <Link to={`/articles/${id}/?versionId=${version.id}`}>{format(version?.createdAt , "dd MMM yyyy, HH:mm")}</Link>
                                            </div>
                                            <div className="history-text color-secondary">
                                                <p>{`${version?.user?.firstName} ${version?.user?.lastName}`}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="page-sidebar --none-border">
                                <aside className="sidebar banner-list sidebar-xs"></aside>
                            </div>
                        </div>
                        <Pagination {...pagination} setParams={setParams} params={params} />
                    </div>
                </section>
               
            </main>
        </>
    )
}

export default ArticlesHistory
