import { createSlice } from '@reduxjs/toolkit';
import { addCaseCommentsfilesByCaseIdFeth, addCaseMediaFilesByCaseIdFeth, addSettingsToCaseByCaseIdFeth, createCaseFeth, editCaseByIdFeth, editCaseCommentFilesByCaseIdFeth, editCaseMediaFilesByCaseIdFeth, editCaseSettingsByCaseIdFeth, favoriteCaseByIdToggleFeth, getAllCassesFeth, getCaseByIdFeth, getRelatedCaseArticlesListFeth, getRelatedCaseQuizzesListFeth, likeCaseByIdToggleFeth } from './casesThunk';

const initialState = {
    casesAll: [],
    caseOne: null,
    caseId: 35,
    isLoading: false,
    isQuizLoading: false,
    isArticleLoading: false,
    errors: null,
    relatedCaseQuizzes: [],
    relatedCaseArticles: [],
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    },
    paginationQuiz: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    },
    paginationArticles: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    }
}

const casesSlice = createSlice({
    name: 'cases',
    initialState,
    reducers: {
        setClearCase: (state, action) => {
            state.caseOne = null
            state.caseId = null
        },
        setCaseId: (state, action) => {
            state.caseId = action.payload
        }
    },

    extraReducers(builder) {
        builder
            .addCase(getAllCassesFeth.fulfilled, (state, action) => {
                state.isLoading = false

                state.casesAll = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllCassesFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getAllCassesFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getCaseByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload) {
                    state.caseOne = action.payload
                    state.caseId = action.payload.id
                }
            })
            .addCase(getCaseByIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(getCaseByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(createCaseFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.caseOne = action.payload
                state.caseId = action.payload.id
            })
            .addCase(createCaseFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(createCaseFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editCaseByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.caseOne = action.payload
                state.caseId = action.payload.id
            })
            .addCase(editCaseByIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editCaseByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getRelatedCaseArticlesListFeth.fulfilled, (state, action) => {
                state.isArticleLoading = false
                state.relatedCaseArticles = action.payload.data
                console.log(action)
                state.paginationArticles = action.payload.pagination
            } )
            .addCase(getRelatedCaseArticlesListFeth.pending, (state, action) => {
                state.isArticleLoading = true
            } )
            .addCase(getRelatedCaseArticlesListFeth.rejected, (state, action) => {
                state.isArticleLoading = false
            } )
            .addCase(getRelatedCaseQuizzesListFeth.fulfilled, (state, action) => {
                state.isQuizLoading = false
                state.relatedCaseQuizzes = action.payload.data
                state.paginationQuiz = action.payload.pagination
            } )
            .addCase(getRelatedCaseQuizzesListFeth.pending, (state, action) => {
                state.isQuizLoading = true
            } )
            .addCase(getRelatedCaseQuizzesListFeth.rejected, (state, action) => {
                state.isQuizLoading = true
            } )
            .addCase(addSettingsToCaseByCaseIdFeth.fulfilled , (state, action) => {
                state.isLoading = false
                if(action.payload) {
                    state.caseOne.caseSetting = {
                        ...state.caseOne.caseSetting,
                        ...action.payload
                    }
                    state.caseOne.articles = action.payload.articles
                    state.caseOne.quizzes = action.payload.quizzes
                }

            } )
            .addCase(addSettingsToCaseByCaseIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(addSettingsToCaseByCaseIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editCaseSettingsByCaseIdFeth.fulfilled , (state, action) => {
                state.isLoading = false
                if(action.payload) {
                    state.caseOne.caseSetting = {
                        ...state.caseOne.caseSetting,
                        ...action.payload
                    }
                    state.caseOne.articles = action.payload.articles
                    state.caseOne.quizzes = action.payload.quizzes
                }
            } )
            .addCase(editCaseSettingsByCaseIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editCaseSettingsByCaseIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(addCaseMediaFilesByCaseIdFeth.fulfilled , (state, action) => {
                state.isLoading = false
                state.caseOne.caseContent = action.payload
            } )
            .addCase(addCaseMediaFilesByCaseIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(addCaseMediaFilesByCaseIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editCaseMediaFilesByCaseIdFeth.fulfilled , (state, action) => {
                state.isLoading = false
                state.caseOne.caseContent = action.payload
            } )
            .addCase(editCaseMediaFilesByCaseIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editCaseMediaFilesByCaseIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(likeCaseByIdToggleFeth.fulfilled, (state, action) => {
                let currentArticle = state.casesAll.find(item => item.id === action.payload.id);
                if(currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes
                }
                if(state.caseOne) {
                    state.caseOne.isLiked = action.payload.isLiked
                    state.caseOne.likes = action.payload.likes
                }
            })
            .addCase(favoriteCaseByIdToggleFeth.fulfilled, (state, action) => {
                let currentArticle = state.casesAll.find(item => item.id === action.payload.id);
                if(currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }
                if(state.caseOne) {
                    state.caseOne.isFavorite = action.payload.isFavorite
                }
            })
            .addCase(addCaseCommentsfilesByCaseIdFeth.fulfilled , (state, action) => {
                state.isLoading = false
                
                state.caseOne.caseContent.images = state.caseOne.caseContent.images.map((item, index) => ({
                    ...item,
                    ...action.payload[index]
                   
                }))
            } )
            .addCase(addCaseCommentsfilesByCaseIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(addCaseCommentsfilesByCaseIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editCaseCommentFilesByCaseIdFeth.fulfilled , (state, action) => {
                state.isLoading = false
                console.log(action)
                state.caseOne.caseContent.images = state.caseOne.caseContent.images.map((item, index) => ({
                    ...item,
                    ...action.payload[index]
                }))
            } )
            .addCase(editCaseCommentFilesByCaseIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editCaseCommentFilesByCaseIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
    }
})

export const {
    setClearCase,
    setCaseId
} = casesSlice.actions;

export const getRelatedCaseArticles = (state) => state.cases.relatedCaseArticles;

export default casesSlice.reducer