import React from 'react'
import ModalTemplate from './ModalTemplate'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearMaterial, setAddModalShow } from '../../store/materials/materialLibrarySlice'
import { setShowCreateModal } from '../../store/auth/authSlice'
import { setClearForum } from '../../store/forums/forumsSlice'
import { setClearArticle } from '../../store/articles/articlesSlice'
import { setClearCourse } from '../../store/courses/coursesSlice'
import { setClearCase } from '../../store/cases/casesSlice'
import { setFlashCardId, setFlashCardSet } from '../../store/flashcards/flashcardsSlice'
import { setQuiz, setQuizId } from '../../store/quizzes/quizzesSlice'

export const CreateEveryModal = ({ open, handelClose }) => {
    const dispatch = useDispatch()
    return (
        <ModalTemplate open={open} handelClose={handelClose}>
            <div className="modal__body items-center">
                <div className="modal__body-heading text-center">
                    <h3>Select what you want to create</h3>
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <Link to={'/quiz-create/general'} onClick={() => {
                        dispatch(setFlashCardId(null))
                        dispatch(setFlashCardSet(null))
                        dispatch(setQuiz(null))
                        dispatch(setQuizId(null))
                    }} className="btn btn--shadow btn--lg radius-5 flex-1">Quiz</Link>
                    <Link to={'/flashcards-create/general'} onClick={() => {
                        dispatch(setFlashCardId(null))
                        dispatch(setFlashCardSet(null))
                        dispatch(setQuiz(null))
                        dispatch(setQuizId(null))
                    }} className="btn btn--shadow btn--lg radius-5 flex-1">Flashcard</Link>
                    <Link to={'/case-create'} onClick={() => dispatch(setClearCase())} className="btn btn--shadow btn--lg radius-5 flex-1">Case</Link>
                    <Link to={'/course-create/general'} onClick={() => dispatch(setClearCourse())} className="btn btn--shadow btn--lg radius-5 flex-1">Course</Link>
                    <Link to={'/article-create'} onClick={() => dispatch(setClearArticle())} className="btn btn--shadow btn--lg radius-5 flex-1">Article</Link>
                    <Link to={'/forum/general'} onClick={() => dispatch(setClearForum())} className="btn btn--shadow btn--lg radius-5 flex-1">Forum</Link>
                    <Link
                        to={'/materials/images'}
                        onClick={() => {
                            dispatch(clearMaterial())
                            dispatch(setAddModalShow(true))
                            handelClose()
                        }}
                        className="btn btn--shadow btn--lg radius-5 flex-1">Image</Link>
                    <Link
                        to={'/materials/videos'}
                        onClick={() => {
                            dispatch(clearMaterial())
                            dispatch(setAddModalShow(true))
                            handelClose()
                        }}
                        className="btn btn--shadow btn--lg radius-5 flex-1">Video</Link>
                </div>
            </div>
        </ModalTemplate>
    )
}
