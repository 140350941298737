import { useEffect, useState } from 'react';

export const useValidation = (data, { requiredFields = [], exceptions = [], validators = {}, messages = {} } = {}) => {
    const [errors, setErrors] = useState({});
    const [errorMessages, setErrorMessages] = useState({});
    const [isErrorShown, setIsErrorShown] = useState(false);

    const validate = (data) => {
        const newErrors = {};
        const newErrorMessages = {};

        Object.keys(data).forEach(name => {
            if (exceptions.includes(name)) {
                newErrors[name] = false;
                newErrorMessages[name] = '';
            } else if (validators[name]) {
                const validationResult = validators[name](data[name], data);
                if (validationResult !== true) {
                    newErrors[name] = true;
                    newErrorMessages[name] = validationResult || messages[name] || 'Invalid value';
                } else {
                    newErrors[name] = false;
                    newErrorMessages[name] = '';
                }
            } else {
                const isRequired = requiredFields.includes(name) || requiredFields.length === 0;
                if (isRequired) {
                    const hasError = Array.isArray(data[name]) ? !data[name]?.length : !data[name];
                    newErrors[name] = hasError;
                    newErrorMessages[name] = hasError ? (messages[name] || 'This field is required') : '';
                } else {
                    newErrors[name] = false;
                    newErrorMessages[name] = '';
                }
            }
        });

        const isErrors = Object.values(newErrors).some(value => value);
        setErrors(isErrors ? newErrors : false);
        setErrorMessages(newErrorMessages);
        return isErrors ? { errors: newErrors, errorMessages: newErrorMessages } : false;
    };

    useEffect(() => {
        validate(data)
    }, [data])

    return { errors, errorMessages, isErrorShown, setIsErrorShown };
};
