import React, { useLayoutEffect, useState } from 'react'
import InputGroup from '../../components/UI/formElements/InputGroup'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import CheckBoxGroup from '../../components/UI/formElements/CheckBoxGroup'
import SuccesPaymant from '../../components/Modals/SuccesPaymant'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getMe } from '../../store/auth/authSlice'
import RadioDefault from '../../components/UI/formElements/RadioDefault'
import { useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement, AddressElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify'
import ButtonLoader from '../../components/UI/ButtonLoader'
import { getCustomerExistedPaymentMethodsFeth, savePaymentMethodFeth, setupIntentFeth } from '../../store/paymant/paymantThunk'
import { getMeFetch } from '../../store/auth/authThunks'
const options = {
    classes: {
        base: 'input input--outline'
    },
    style: {
        base: {
            iconColor: '#c4f0ff',
            // lineHeight: '42px',
            fontWeight: 400,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '14px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#999999'
            }
        }
    },

}

const PaymantForm = ({currentPlan, secretKeyFeth = () => {},  succsessFeth = () => {}, type}) => {
    const me = useSelector(getMe)
    const { methods, isLoading } = useSelector(state => state.paymant)
    const dispatch = useDispatch()
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    
    const [succesModal, setSuccesModal] = useState(false);
    const [cardCurrentType, setCardCurrentType] = useState('new-card')
    const [email, setEmail] = useState(me?.email)
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState('');
    const [billingAdress, setBillingAdress] = useState('');
    const [saveCard, setSaveCard] = useState(true)
    const [curreSavedMenthod, setCurrentSavedMenthod] = useState(null)
    const succesModalhandler = () => {
        setSuccesModal(prev => !prev);
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        if (!stripe || !elements) {
            return;
        }
        setIsProcessing(true);
        let clientSecret = null;
        let paymentMethod = null

        if (cardCurrentType === 'new-card') {
            const paymentMethodRes = await newCardCreate()
            if (paymentMethodRes) {
                paymentMethod = paymentMethodRes.id
            } else {
                toast.error('Failed to create payment method');
                setIsProcessing(false);
                return false
            }
        }
        if (cardCurrentType === 'my-preset-card') {
            paymentMethod = curreSavedMenthod.paymentMethodId
        }
        const body = {
            "paymentMethodId": paymentMethod
        }
        
        if(!body.paymentMethodId) {
            toast.error(`Fill in the details or select preset card`)
            setIsProcessing(false);
            return
        }
        // get subscribe secret key
        const subscribeByPlanRes = await dispatch(secretKeyFeth({ id: currentPlan.id, body })).unwrap()
        if (subscribeByPlanRes) {
            clientSecret = subscribeByPlanRes.client_secret
        }
        // confirmCardPayment
        const { error, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret
        );
        if (error) {
            setIsProcessing(false);
            toast.error(`Payment confirmation failed: ${error}`)
            return
        }
        const data = {
            paymentIntentId: paymentIntent.id
        }
        
        if (paymentIntent.status === "succeeded") {
            let paymantRes = null
            if(type === 'pricing') {
                paymantRes = await dispatch( succsessFeth(data)).unwrap()
            }
            if(type === 'course') {
                paymantRes =  await dispatch(succsessFeth({id: currentPlan.id, body: data})).unwrap()
            }
           
            if (paymantRes) {
                succesModalhandler()
                if(type === 'pricing') {
                    dispatch(getMeFetch())
                }
                setIsProcessing(false);
            }
        } else {
            toast.error(`Payment confirmation status: ${paymentIntent.status}`)
            setIsProcessing(false);
        }

    }
    const newCardCreate = async () => {
        const clientSecret = await dispatch(setupIntentFeth()).unwrap()
        
        const cardElement = elements.getElement(CardNumberElement);
        // createPaymentMethod
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                name: name,
                email: me.email,
                address: {
                    line1: billingAdress,
                },
            },
        });
        if (error) {
            toast.error(error.message);
            setIsProcessing(false);
            return false;
        }
        // confirmCardSetup
        const { error: confirmError } = await stripe.confirmCardSetup(
            clientSecret.client_secret,
            {
                payment_method: paymentMethod.id,
            }
        );


        if (confirmError) {
            toast.error(confirmError.message);
            setIsProcessing(false);
            return
        }

        //   Отправка setupIntent и кастомных полей на сервер
        if (saveCard) {
            const data = {
                id: paymentMethod.id,
                brand: paymentMethod.card.brand,
                exp_month: paymentMethod.card.exp_month,
                exp_year: paymentMethod.card.exp_year,
                last4: paymentMethod.card.last4,
                defaultCard: saveCard
            }
            const response = await dispatch(savePaymentMethodFeth(data)).unwrap();
            
            if (!response) {
                toast.error('Failed to save payment method');
                setIsProcessing(false);
                return false
            }
        }
        return paymentMethod
    }
    useEffect(() => {
        if (methods) {
            setCurrentSavedMenthod(...methods.filter(item => item.defaultCard))
        }
    }, [methods])
    useEffect(() => {
        dispatch(getCustomerExistedPaymentMethodsFeth())
    }, [])
    
    return (
        <>
            <div className="checkout-section__contact-info">
                <div className="checkout-section__form">
                    <div className="checkout-section__form-body">
                        <div className="list-group list-group--llg" data-select2-id="select2-data-9-00ur">
                            <div className="list-group list-group--mmd">
                                <h4>Contact Information</h4>
                                <InputGroup
                                    label={'Email'}
                                    wrapperClasses={'input--lg'}
                                    inputClasses={'input--outline'}
                                    placeholder={'Enter your email...'}
                                    value={email}
                                    onChange={({ target }) => setEmail(target.value)}
                                />
                            </div>
                            <div className="tabs gap-[16px]">
                                <div className="tabs-header">
                                    <ul className="tabs-nav flex-wrap">
                                        <li className="tabs-nav--item max-w-max">
                                            <a href="#" className="btn btn-tablink btn-tablink-row btn--xs rounded-full bg-transparent px-[10px] active-tab">
                                                <span className="ico ico-24">
                                                    <svg width="24" height="24" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.25 8.75033H19.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M9.27234 12.4168H6.9165" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M17 17.9168H6C4.48108 17.9168 3.25 16.6857 3.25 15.1668V7.8335C3.25 6.31458 4.48108 5.0835 6 5.0835H17C18.5189 5.0835 19.75 6.31458 19.75 7.8335V15.1668C19.75 16.6857 18.5189 17.9168 17 17.9168Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                                <span>by card</span>

                                            </a>
                                        </li>
                                        {/* <li className="tabs-nav--item max-w-max">
                                                        <a href="#" className="btn btn-tablink btn-tablink-row btn--xs rounded-full bg-transparent px-[10px] ">
                                                            <span className="ico ico-24">
                                                                <svg width="24" height="24" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.5001 7.11782C11.4698 7.11782 11.4407 7.12992 11.4194 7.15145C11.398 7.17297 11.3861 7.20213 11.3864 7.23245C11.3864 7.29576 11.4377 7.34708 11.501 7.34708C11.5643 7.34708 11.6156 7.29576 11.6156 7.23245C11.6146 7.1692 11.5633 7.11831 11.5001 7.11782" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.7278 9.97124H3.27229C3.14395 9.97148 3.02079 9.92067 2.92996 9.83001C2.83913 9.73935 2.78809 9.61629 2.78809 9.48795V7.67587C2.78839 7.28132 3.04101 6.93118 3.41535 6.8065L11.1938 4.21309C11.3926 4.14707 11.6075 4.14707 11.8063 4.21309L19.5847 6.8065C19.9591 6.93118 20.2117 7.28132 20.212 7.67587V9.48795C20.212 9.61629 20.161 9.73935 20.0701 9.83001C19.9793 9.92067 19.8561 9.97148 19.7278 9.97124Z" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M4.1636 17.0027V9.97168" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M18.8365 9.97168V17.0027" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M7.83206 9.97168V17.0027" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M11.5 9.97168V17.0027" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M15.1685 9.97168V17.0027" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.1868 17.0024H3.81337C3.46612 17.0026 3.14876 17.1989 2.99353 17.5096L2.4268 18.643C2.3628 18.7702 2.32959 18.9106 2.32959 19.053V19.7536H20.6706V19.053C20.6706 18.9106 20.6374 18.7702 20.5734 18.643L20.0066 17.5096C19.8514 17.1989 19.534 17.0026 19.1868 17.0024Z" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    <path d="M21.5877 19.7534H1.4126" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                            <span>by US bank account</span>
                                                        </a>
                                                    </li> */}
                                    </ul>
                                </div>
                                <div className="tabs-body">
                                    <div className="tabs-item gap-[16px] active-tab" id="by-us-bank-account">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <RadioDefault
                                                    wrapperClasses={'items-center'}
                                                    name={'cardswitch'}
                                                    checked={cardCurrentType === 'new-card'}
                                                    onChange={() => setCardCurrentType('new-card')}
                                                >
                                                    <p className="text--sm">
                                                        New card
                                                    </p>
                                                </RadioDefault>
                                            </div>
                                            <div className="col-lg-6">
                                                <RadioDefault
                                                    wrapperClasses={'items-center'}
                                                    name={'cardswitch'}
                                                    checked={cardCurrentType === 'my-preset-card'}
                                                    onChange={() => setCardCurrentType('my-preset-card')}
                                                >
                                                    <p className="text--sm">
                                                        My Preset Card
                                                    </p>
                                                </RadioDefault>
                                            </div>
                                        </div>
                                        {cardCurrentType === 'new-card' &&
                                            <>
                                                <div className="input-wrapper input--lg">
                                                    <label className="label">Card information</label>
                                                    <div className="card-info-group">
                                                        <div className="input-icon-wrap card-number">
                                                            <CardNumberElement className='input input--outline pr-[106px] py-[13px]' options={options} />
                                                            <div className="input-iocns right-icon-pos">
                                                                <img src={require("../../assets/img/Visa.svg").default} width="32" alt="" />
                                                                <img src={require("../../assets/img/MasterCard.svg").default} width="32" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="input-icon-wrap card-info">
                                                            <CardExpiryElement className='input input--outline py-[13px]' options={options} />
                                                        </div>
                                                        <div className="input-icon-wrap card-info">
                                                            <CardCvcElement className='input input--outline py-[13px] pr-[66px]' options={options} />
                                                            <div className="input-iocns right-icon-pos">
                                                                <img src={require("../../assets/img/card-cvc.svg").default} width="32" alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <InputGroup
                                                    label={'Name on card'}
                                                    wrapperClasses={' input--lg'}
                                                    inputClasses={'input--outline'}
                                                    placeholder={'Enter your Name'}
                                                    value={name}
                                                    onChange={({target}) => setName(target.value)}
                                                />
                                                <InputGroup
                                                    label={'Billing address'}
                                                    wrapperClasses={' input--lg'}
                                                    inputClasses={'input--outline'}
                                                    placeholder={'Enter Billing address'}
                                                    value={billingAdress}
                                                    onChange={({target}) => setBillingAdress(target.value)}
                                                />
                                                {/* <AddressElement options={{mode: 'billing'}} /> */}
                                                <CheckBoxGroup
                                                    checked={saveCard}
                                                    onChange={() => setSaveCard(prev => !prev)}
                                                >
                                                    <p className="check-text">
                                                        Save my info for secure 1-click checkout
                                                    </p>
                                                </CheckBoxGroup>
                                            </>
                                        }
                                        {cardCurrentType === 'my-preset-card' && <>
                                            {curreSavedMenthod ? <InputGroup
                                                label={'My current card'}
                                                wrapperClasses={' input--lg'}
                                                inputClasses={'input--outline'}
                                                placeholder={'Enter your Name'}
                                                value={`•••• •••• •••• ${curreSavedMenthod?.last4}`}
                                                readOnly={true}
                                            /> : 
                                                <h5 className='text-center mt-3'>
                                                    You have no saved <Link className='color-danger' to={`/settings/paymant-methods`}>payment methods</Link> 
                                                </h5>
                                            }
                                        </>}
                                    </div>
                                </div>
                            </div>

                            <div className="list-group list-group--md text-center">
                                { ((cardCurrentType === 'my-preset-card' && curreSavedMenthod) || cardCurrentType === 'new-card') ? <button
                                    onClick={submitHandler}
                                    type='button'
                                    disabled={isProcessing}
                                    className="btn btn--primary btn--md radius-5 w-full"
                                >
                                    {!isProcessing ? <>
                                        {type === 'pricing' ? 'Subscribe' : 'Buy'}
                                    </> :
                                        <ButtonLoader />
                                    }
                                </button> : null}
                                {type === 'pricing' && <p className="color-secondary text--sm">By&nbsp;confirming your subscription, you allow Demo to&nbsp;charge your card for this payment and future payments in&nbsp;accordance with their terms.</p>}
                            </div>
                        </div>
                    </div>
                    <div className="checkout-section__form-footer md:hidden">
                        <ul className="checkout-section__footer-list">
                            <li>
                                Powered by <span className="font-bold">Medwise</span>
                            </li>
                            <li>
                                <Link to={'/terms'}>Terms</Link>
                            </li>
                            <li>
                                <Link to={'/privacy'}>Privacy</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {succesModal && <SuccesPaymant blockCloseBg={false} open={succesModal} handelClose={succesModalhandler}  type={type}/>}
        </>
    )
}

export default PaymantForm
