import React, { useEffect, useState } from 'react'
import { CreateFormHeader } from '../../components/parts/CreateFormHeader'
import CheckBoxGroup from '../../components/UI/formElements/CheckBoxGroup'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getFormData } from '../../helpers/utils';
import { addSettingsToCaseByCaseIdFeth, editCaseSettingsByCaseIdFeth, getRelatedCaseArticlesListFeth, getRelatedCaseQuizzesListFeth } from '../../store/cases/casesThunk';
import SelectGroupRow from '../../components/UI/formElements/SelectGroupRow';
import Loader from '../../components/UI/Loader';
import { setClearCase } from '../../store/cases/casesSlice';
import { setHasEditChanges } from '../../store/auth/authSlice';
import useDebounce from '../../hooks/useDebounce';
import SelectGroupRowAsynk from '../../components/UI/formElements/SelectGropuRowAsynk';
const LIMIT = 10
const CaseCreateSettings = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [caseOne, caseId] = useOutletContext();
    const {
        isLoading,
        relatedCaseArticles,
        relatedCaseQuizzes,
    } = useSelector(state => state.cases)
    const [settingsData, setSettingsData] = useState({
        // private: false,
        // anonymous_responses: false,
        enableComments: false,
        isAnatomy: false,
        // can_copy: false,
        quizIds: [],
        articleIds: []
    })
    // get quizes lazy

    const loadOptionsQuizs = async (search, loadedOptions, { page }) => {
        const res = await dispatch(getRelatedCaseQuizzesListFeth(`page=${page}&limit=${LIMIT}&search=${search}`)).unwrap();

        return {
            options: res.data?.map(quiz => ({ label: quiz.name, value: quiz.id })),
            hasMore: page !== res?.pagination?.maxPages, // флаг, показывающий, есть ли еще данные
            additional: {
                page: page + 1, // увеличиваем номер страницы для следующего запроса
            },
        };
    };
    // get Articles lazy
    const loadOptionsArticles = async (search, loadedOptions, { page }) => {
        const res = await dispatch(getRelatedCaseArticlesListFeth(`page=${page}&limit=${LIMIT}&search=${search}`)).unwrap();

        return {
            options: res.data?.map(quiz => ({ label: quiz.title, value: quiz.id })),
            hasMore: res.data?.pagination?.page != res.data?.pagination?.maxPages, // флаг, показывающий, есть ли еще данные
            additional: {
                page: page + 1, // увеличиваем номер страницы для следующего запроса
            },
        };
    };

    useEffect(() => {
        if (caseOne?.caseSetting) {
            setSettingsData({
                can_copy: caseOne?.caseSetting?.can_copy,
                enableComments: caseOne?.caseSetting?.enableComments,
                isAnatomy: caseOne?.caseSetting?.isAnatomy,
                quizIds: caseOne?.quizzes,
                articleIds: caseOne?.articles.map(item => ({
                    id: item.id,
                    name: item.title
                }))
            })
        }
    }, [caseOne])

    const formDataHandler = (name, value) => {
        dispatch(setHasEditChanges(true))
        if (name === 'quizIds') {

            setSettingsData(prev => ({
                ...prev,
                [name]: value.map(option => ({
                    id: option.value,
                    name: option.label
                }) || [])
            }))
        } else if (name === 'articleIds') {
            setSettingsData(prev => ({
                ...prev,
                [name]: value.map(option => ({
                    id: option.value,
                    name: option.label
                }) || [])
            }))
        } else {
            setSettingsData(prev => ({
                ...prev,
                [name]: value
            }))
        }

    }

    const submitHandler = async (e, createAnother = false) => {

        const newData = {
            ...settingsData,
            quizIds: settingsData.quizIds.map(item => item.id),
            articleIds: settingsData.articleIds.map(item => item.id)
        }
        let res = null
        if (caseOne.caseSetting) {
            res = await dispatch(editCaseSettingsByCaseIdFeth({ id: caseId, body: newData })).unwrap()
        } else {
            res = await dispatch(addSettingsToCaseByCaseIdFeth({ id: caseId, body: newData })).unwrap()
        }
        if (res) {
            if (createAnother) {
                dispatch(setClearCase())
                navigate('/case-create')
            } else {
                navigate(`/cases/${res.caseId}`)
            }
            dispatch(setHasEditChanges(false))
        }
    }

    const relatedArticles = relatedCaseArticles ? relatedCaseArticles.map(item => ({
        value: item.id,
        label: item.title
    })) : []
    const relatedQuizzes = relatedCaseQuizzes ? relatedCaseQuizzes.map(item => ({
        value: item.id,
        label: item.name
    })) : []
    const conf = {
        title: 'Settings',
    }
    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters pt-[20px]">
                                        <div className="col-12">
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Related quizzes'}
                                                placeholder={'Select Related quizzes'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                isMulti={true}
                                                value={settingsData?.quizIds?.map((item) => {
                                                    return { value: item.id, label: item.name }
                                                })}
                                                // options={relatedQuiz}
                                                onChange={(option) => {
                                                    formDataHandler('quizIds', option)
                                                }}
                                                fetchFunk={loadOptionsQuizs}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRowAsynk
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Related Articles'}
                                                placeholder={'Select Related Articles'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                isMulti={true}
                                                value={settingsData?.articleIds?.map((item) => {
                                                    return { value: item.id, label: item.name }
                                                })}
                                                // options={relatedArticle}
                                                onChange={(option) => {
                                                    formDataHandler('articleIds', option)
                                                }}
                                                fetchFunk={loadOptionsArticles}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="group gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right pt-[16px]">Options</p>
                                                <div className="checkboxes-list">
                                                    <CheckBoxGroup
                                                        checked={settingsData.isAnatomy}
                                                        onChange={(e) => {
                                                            formDataHandler('isAnatomy', e.target.checked)
                                                        }}
                                                    >
                                                        <p className="check-text">
                                                            Add to Anatomy section
                                                        </p>
                                                    </CheckBoxGroup>
                                                    {/* <CheckBoxGroup
                                                        checked={settingsData.private}
                                                        onChange={(e) => {
                                                            formDataHandler('private', e.target.checked)
                                                        }}
                                                    >
                                                        <p className="check-text">
                                                            Private case
                                                        </p>
                                                    </CheckBoxGroup>
                                                    <CheckBoxGroup
                                                        checked={settingsData.anonymous_responses}
                                                        onChange={(e) => {
                                                            formDataHandler('anonymous_responses', e.target.checked)
                                                        }}
                                                    >
                                                        <p className="check-text">
                                                            Anonymous responses
                                                        </p>
                                                    </CheckBoxGroup> */}
                                                    <CheckBoxGroup
                                                        checked={settingsData.enableComments}
                                                        onChange={(e) => {
                                                            formDataHandler('enableComments', e.target.checked)
                                                        }}
                                                    >
                                                        <p className="check-text">
                                                            Enable comments for the case
                                                        </p>
                                                    </CheckBoxGroup>
                                                    {/* <CheckBoxGroup
                                                        checked={settingsData.can_copy}
                                                        onChange={(e) => {
                                                            formDataHandler('can_copy', e.target.checked)
                                                        }}
                                                    >
                                                        <p className="check-text">
                                                            Users can copy the case
                                                        </p>
                                                    </CheckBoxGroup> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container gap-[10px]">
                                    <Link to={'/cases'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <div className="flex gap-[12px]">
                                        <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save</span>
                                        </button>
                                        <button type='button' onClick={(e) => submitHandler(e, true)} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save and Create Another</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default CaseCreateSettings
