import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
    BalloonEditor,
    AccessibilityHelp,
    AutoLink,
    Autosave,
    Bold,
    Essentials,
    Italic,
    Link,
    List,
    Paragraph,
    SelectAll,
    Undo
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

export default function BallonEditor({editorData , setData, placeholder }) {
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
		setData(data);
    };

    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);

    const editorConfig = {
        toolbar: {
            items: [
                'undo',
                'redo',
                // '|',
                // 'selectAll',
                '|',
                'bold',
                'italic',
                '|',
                'link',
                '|',
                'bulletedList',
                'numberedList',
                // '|',
                // 'accessibilityHelp'
            ],
            shouldNotGroupWhenFull: false
        },
        plugins: [AccessibilityHelp, AutoLink, Autosave, Bold, Essentials, Italic, Link, List, Paragraph, SelectAll, Undo],
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            // decorators: {
            // 	toggleDownloadable: {
            // 		mode: 'manual',
            // 		label: 'Downloadable',
            // 		attributes: {
            // 			download: 'file'
            // 		}
            // 	}
            // }
        },
        placeholder: placeholder
    };

    return (

        <div className="editor-container  editor-container_balloon-editor" ref={editorContainerRef}>
            <div ref={editorRef}>{isLayoutReady &&
                <CKEditor 
                    editor={BalloonEditor} 
                    config={editorConfig} 
                    data={editorData} 
                    onChange={handleEditorChange} 
                />}
            </div>
        </div>
    );
}