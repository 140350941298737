import React, { useEffect, useMemo, useState } from 'react'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import CardFlashcard from "../../components/parts/Cards/CardFlashcard"
import CardQuiz from "../../components/parts/Cards/CardQuiz"
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'

import { ReactComponent as PlusIcon } from "../../assets/img/ico-plus-2.svg"
import SwitchViewType from '../../components/parts/SwitchViewType'

import { useDispatch } from 'react-redux'
import OptionToCreateModal from '../../components/Modals/OptionToCreateModal'
import { data as staticData } from "../../data"
import { filterOptions } from '../../helpers/constants'
import { getAllFlashCardSetsFetch } from '../../store/flashcards/flashcardsThunks'
import PageLoader from '../../components/parts/PageLoader'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setFlashCardId, setFlashCardSet } from '../../store/flashcards/flashcardsSlice'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getAllQuizzesFeth } from '../../store/quizzes/quizzesThunks'
import { getIsLoading, quizzesAll, quizzesPagination, setClearState, setQuiz, setQuizId } from '../../store/quizzes/quizzesSlice'
import { setShowLoginModal } from '../../store/auth/authSlice'
import useDebounce from '../../hooks/useDebounce'
import InputSearch from '../../components/UI/formElements/InputSearch'
// const filters = [
//     {
//         title: "Filter by Systems",
//         checkboxOptions: [
//             { value: 'breast', label: 'Breast' },
//             { value: 'cardiac', label: 'Cardiac' },
//             { value: 'central-nervous-system', label: 'Central Nervous System' },
//             { value: 'chest', label: 'Chest' },
//             { value: 'forensic', label: 'Forensic' },
//             { value: 'gastrointestinal', label: 'Gastrointestinal' },
//             { value: 'gynaecology', label: 'Gynaecology' },
//             { value: 'haematology', label: 'Haematology' },
//             { value: 'head-neck', label: 'Head & Neck' },
//             { value: 'hepatobiliary', label: 'Hepatobiliary' },
//             { value: 'hidden1', label: 'Hidden' },
//             { value: 'hidden2', label: 'Hidden' },
//             { value: 'hidden3', label: 'Hidden' },
//         ]
//     },
// ]
const links = [
    {
        label: "Quiz",
        to: "/quiz",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.2534 14.3647V17.3176" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.2523 6.66879V9.62169H19.2533C18.8255 9.53535 18.3824 9.56415 17.9694 9.70514C17.2018 9.96757 16.6194 10.601 16.4223 11.3879C16.237 12.1131 16.3969 12.8832 16.8556 13.4746C17.3144 14.0661 18.0206 14.4125 18.7691 14.4133C18.9317 14.413 19.0939 14.3967 19.2533 14.3647V17.3176C19.2533 17.831 19.0494 18.3234 18.6863 18.6865C18.3232 19.0495 17.8308 19.2535 17.3174 19.2534H14.3645V19.2544C14.4508 18.8266 14.422 18.3835 14.281 17.9705C14.0186 17.2029 13.3851 16.6205 12.5982 16.4234C11.8731 16.2381 11.1029 16.398 10.5115 16.8568C9.92011 17.3155 9.57369 18.0217 9.5729 18.7702C9.57317 18.9328 9.58945 19.095 9.6215 19.2544L6.66952 19.2534C5.60064 19.2536 4.73395 18.3874 4.73363 17.3185V14.3656C4.57422 14.3976 4.41204 14.4139 4.24943 14.4142C3.50095 14.4134 2.79478 14.067 2.33602 13.4756C1.87726 12.8841 1.71734 12.114 1.90271 11.3888C2.09981 10.6019 2.68218 9.96849 3.44977 9.70606C3.86279 9.56506 4.30584 9.53626 4.73363 9.6226H4.73272V6.66971C4.73268 6.15627 4.93663 5.66384 5.29968 5.30078C5.66274 4.93773 6.15517 4.73378 6.66861 4.73382H9.6215C9.58945 4.5744 9.57317 4.41222 9.5729 4.24962C9.57369 3.50113 9.92011 2.79496 10.5115 2.3362C11.1029 1.87745 11.8731 1.71753 12.5982 1.90289C13.3848 2.10056 14.0181 2.68271 14.281 3.44995C14.422 3.86297 14.4508 4.30602 14.3645 4.73382H17.3174C17.8308 4.73378 18.3232 4.93773 18.6863 5.30078C19.0494 5.66384 19.2533 6.15627 19.2533 6.66971" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
    },
    {
        label: "Flashcards",
        to: "/quiz/flashcards",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.4903 20.1667H4.68508C3.61625 20.1667 2.75 19.3004 2.75 18.2316V8.55526C2.75 7.48643 3.61625 6.62018 4.68508 6.62018H10.4903C11.5592 6.62018 12.4254 7.48643 12.4254 8.55526V18.2316C12.4263 19.3004 11.5592 20.1667 10.4903 20.1667Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.93945 6.62016L9.83687 3.26791C10.1137 2.23574 11.1752 1.62249 12.2074 1.89932L17.8146 3.40174C18.8468 3.67857 19.46 4.73916 19.1832 5.77224L16.6789 15.1186C16.402 16.1507 15.3415 16.764 14.3093 16.4872L12.4255 15.983" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
    },

]

const tips = [
    { title: "How to use quizzes?", id: 1 },
    { title: "How to use flashcards?", id: 2 },
    { title: "How to create new quizzes?", id: 3 }
];

const Quiz = ({ tab }) => {
    const [optionToCreateModalOpen, setOptionToCreateModalOpen] = useState(false)
    const { authorized, showLoginModal } = useSelector((state) => state.auth)
    const systems = useSelector(getFilteres);
    const [isList, setIsList] = useState(false)
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const { flashCardSets, isLoading, pagination } = useSelector(state => state.flashcards)
    const quizzes = useSelector(quizzesAll)
    const isQuizLoading = useSelector(getIsLoading);
    const quizPagination = useSelector(quizzesPagination)
    const [data, setData] = useState([])
    // const [params, setParams] = useState({
    //     sort: 'latest',
    //     system: [],
    //     favorite: true
    // })
    const formatOptionsQuery = (value) => {
        return value?.length
            ? value.split(',').filter(Boolean) || []
            : []
    }
    const params = useMemo(() => ({
        sort: searchParams.get('sort') || 'latest',
        system: formatOptionsQuery(searchParams.get('system')) || [],
        search: searchParams.get("search") || ""
    }), [searchParams])

    const filters = [
        {
            title: "Filter by Categories",
            checkboxOptions: systems
        },
    ]


    const handleFilterChange = (options) => {
        // setParams((prevFilters) => {
        //     const system = prevFilters.system.includes(options.name)
        //         ? prevFilters.system.filter((cat) => cat !== options.name)
        //         : [...prevFilters.system, options.name];

        //     return {
        //         ...prevFilters,
        //         system
        //     };
        // })

        // const newParams = new URLSearchParams({
        //   sort: params.sort,
        //   industry: params.industry.includes(options.name)
        //   ? params.industry.filter((cat) => cat !== options.name).join(',')
        //   : [...params.industry, options.name].join(','),
        // });
        setSearchParams({
            ...params,
            system: params.system.includes(options.name)
                ? params.system.filter((cat) => cat !== options.name).join(',')
                : [...params.system, options.name].join(','),
        });
    }

    const handleSortChange = (option) => {
        // setParams(prev => ({
        //     ...prev,
        //     sort: option.value
        // }))
        setSearchParams({
            ...params,
            sort: option.value,
        });
    }
    // useEffect(() => {
    //     const industryParam = searchParams.get('system');

    //     const system = industryParam ? searchParams.get('system')?.split(',').filter(Boolean) : [];
    //     const sort = searchParams.get('sort') || 'latest';
    //     setParams({ system, sort });

    // }, [searchParams])

    useEffect(() => {

        const fetchData = async () => {
            let res = null
            if (tab === "Flashcards") {
                res = await dispatch(getAllFlashCardSetsFetch({ query: searchParams.toString() })).unwrap()
            } else {

                res = await dispatch(getAllQuizzesFeth({ query: searchParams.toString() })).unwrap()
            }
            if (res) {
                window.scrollTo(0, 0)
            }
        }
        fetchData()
    }, [searchParams])

    const debouncedSearchChange = useDebounce((value) => {

        setSearchParams({
            ...params,
            search: value
        });
    }, 500);
    const handleSearchChange = (e) => {
        const { value } = e.target;
        debouncedSearchChange(value);
    };

    useEffect(() => {
        if (tab === "Flashcards") {
            setData(flashCardSets)
        } else {
            setData(quizzes)
        }
    }, [flashCardSets, quizzes])

    useEffect(() => {
        dispatch(getFiltersByName('system'))
    }, [dispatch])
    return (
        <main className="content">
            <div className="container">
                <section className="section-filters">
                    <SidebarFilters filters={filters} defaultCheked={params?.system} links={links} tips={tips} onChange={handleFilterChange} />
                    <div className="section-filters__body">
                        <div className="page-heading mb-[30px]">
                            <div className="page-heading__item">
                                <h3 className="mr-[24px]">{tab === "Flashcards" ? "Flashcards" : "Quiz"}</h3>
                                <SwitchViewType onChange={(checked) => setIsList(checked)} />

                            </div>
                            <div className="page-heading__item">
                                <InputSearch className='input--sm w-[250px]' defaultValue={params.search} onChange={handleSearchChange} />
                                <SelectGroup
                                    wrapperClasses={'input--sm'}
                                    options={filterOptions}
                                    defaultValue={filterOptions[0]}
                                    onChange={handleSortChange}
                                />
                                <button onClick={() => {
                                    if (authorized) {
                                        setOptionToCreateModalOpen(true)
                                        dispatch(setFlashCardId(null))
                                        dispatch(setFlashCardSet(null))
                                        dispatch(setQuiz(null))
                                        dispatch(setQuizId(null))
                                        dispatch(setClearState())
                                    } else {
                                        dispatch(setShowLoginModal(true))
                                    }
                                }} className="btn btn--primary btn--md radius-5">
                                    <span className="ico ico-22 mr-1">
                                        <PlusIcon />
                                    </span>
                                    <span>Create Quiz</span>
                                </button>
                            </div>
                        </div>
                        <div className="row gutters-cards flex-auto">
                            {
                                (!isLoading || !isQuizLoading) && data?.length ?
                                    data.map(card => (
                                        <div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"} >
                                            {
                                                tab === "Quizzes" ?
                                                    <CardQuiz authorized={authorized} data={card} isList={isList} />
                                                    :
                                                    <CardFlashcard authorized={authorized} data={card} isList={isList} />
                                            }
                                        </div>
                                    ))
                                    : isLoading || isQuizLoading ?
                                        <PageLoader /> :
                                        <h2>Page is empty</h2>

                            }
                        </div>

                        {tab === "Flashcards" && <Pagination {...pagination} params={params} />}
                        {tab === "Quizzes" && <Pagination {...quizPagination} params={params} />}

                    </div>
                </section>
            </div >
            <OptionToCreateModal open={optionToCreateModalOpen} handelClose={() => setOptionToCreateModalOpen(false)} />
        </main >
    )
}

export default Quiz