import React, { useEffect, useMemo, useState } from 'react'
import InputSearch from '../../components/UI/formElements/InputSearch'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import CardArticle from '../../components/parts/Cards/CardArticle'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'

import { ReactComponent as PlusIcon } from "../../assets/img/ico-plus-2.svg"

import { data } from "../../data"
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { filterOptions } from '../../helpers/constants'
import { setShowLoginModal } from '../../store/auth/authSlice'
import { setClearArticle } from '../../store/articles/articlesSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getAllArticlesFeth } from '../../store/articles/articlesThunks'
import Loader from '../../components/UI/Loader'
import PageLoader from '../../components/parts/PageLoader'
import useDebounce from '../../hooks/useDebounce'
import SwitchViewType from '../../components/parts/SwitchViewType'

// export const filters = [
//   {
//     title: "Filter by Industries",
//     checkboxOptions: [
//         { value: 'digital-health', label: 'Digital Health' },
//         { value: 'genomics', label: 'Genomics' },
//         { value: 'myth-busting', label: 'Myth Busting' },
//         { value: 'mental-health', label: 'Mental Health' },
//         { value: 'researches', label: 'Researches' },
//         { value: 'surgery', label: 'Surgery' },
//         { value: 'womens-health', label: 'Women’s Health' },
//         { value: 'pediatrics', label: 'Pediatrics' },
//         { value: 'artificial-intelligence', label: 'Artificial Intelligence' }
//     ]
// }
// ]

export const tips = [
	{ title: "How to search articles?", id: 1 },
	{ title: "How to study with articles?", id: 2 },
	{ title: "How to share articles?", id: 3 }
];


const Articles = () => {
	const { authorized, showLoginModal } = useSelector((state) => state.auth)
	const { articlesAll, isLoading, pagination } = useSelector(state => state.articles)
	const [isList, setIsList] = useState(false)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [searchParams, setSearchParams] = useSearchParams()
	const systems = useSelector(getFilteres);

	// const [params, setParams] = useState({
	//   sort: 'latest',
	//   industry: [],
	//   search: ''
	// })
	const formatOptionsQuery = (value) => {
		return value?.length
			? value.split(',').filter(Boolean) || []
			: []
	}
	const params = useMemo(() => ({
		sort: searchParams.get('sort') || 'latest',
		industry: formatOptionsQuery(searchParams.get('industry')) || [],
		search: searchParams.get("search") || ""
	}), [searchParams])
	const filters = [
		{
			title: "Filter by Categories",
			checkboxOptions: systems
		},
	]

	const handleFilterChange = (options) => {
		setSearchParams({
			...params,
			industry: params.industry.includes(options.name)
				? params.industry.filter((cat) => cat !== options.name).join(',')
				: [...params.industry, options.name].join(','),
		});
	}
	const debouncedSearchChange = useDebounce((value) => {
		setSearchParams({
			...params,
			search: value
		});
	}, 500);
	const handleSearchChange = (e) => {
		const { value } = e.target;
		debouncedSearchChange(value);
	};

	useEffect(() => {
		const fetchData = async () => {

			const res = await dispatch(getAllArticlesFeth(searchParams.toString())).unwrap()
			if (res) {
				window.scrollTo(0, 0)
			}
		}
		fetchData()
	}, [searchParams])

	const handleSortChange = (option) => {

		setSearchParams({
			...params,
			sort: option.value,
		});
	}

	useEffect(() => {
		dispatch(getFiltersByName('industry'))
	}, [dispatch])


	return (
		<main className="content">
			<div className="container">
				<section className="section-filters">
					<SidebarFilters filters={filters} defaultCheked={params?.industry} tips={tips} onChange={handleFilterChange} />
					<div className="section-filters__body">
						<div className="page-heading mb-[30px]">
							<div className="page-heading__item">
								<h3 className="mr-[24px]">Articles</h3>
								<SwitchViewType onChange={(checked) => setIsList(checked)} />
							</div>
							<div className="page-heading__item">
								<InputSearch className='input--sm w-[250px]' defaultValue={params.search} onChange={handleSearchChange} />
								<SelectGroup
									wrapperClasses={'input--sm'}
									options={filterOptions}
									defaultValue={filterOptions[0]}
									onChange={handleSortChange}
								/>
								<button onClick={
									() => {
										if (authorized) {
											dispatch(setClearArticle())
											navigate('/article-create')
										} else {
											dispatch(setShowLoginModal(true))
										}
									}} className="btn btn--primary btn--md gap--xs radius-5">
									<span className="ico ico-22 mr-1">
										<PlusIcon />
									</span>
									<span className="info">Create Article</span>
								</button>
							</div>
						</div>
						<div className="row gutters-cards flex-auto">
							{
								articlesAll && articlesAll.length && !isLoading ?
									articlesAll.map(card => (
										<div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"} >
											<CardArticle data={card} isList={isList} authorized={authorized} />
										</div>
									))
									: isLoading ?
										<PageLoader /> :
										<h2>Page is empty</h2>

							}
						</div>
						<Pagination {...pagination} params={params} />

					</div>
				</section>
			</div>
		</main>
	)
}

export default Articles