import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getMe } from '../../store/auth/authSlice'

const UpgradeToPremium = () => {
    const me = useSelector(getMe)

    if (me?.subscription?.name === 'Premium Plan') {
        return null
    }

    return (
        <div className="banner banner--sidebar-premium --vertical" style={{
            background: "var(--bunner-bg-1)"
        }}>
            <div className="banner-row">
                <div className="banner-img banner-img--shadow">
                    <img src={require("../../assets/img/banner-img-1.png")} />
                </div>
                <div className="banner-content">
                    <div className="heading-block">
                        <h3 className="heading">
                            Unlock Limitless Possibilities with
                            <span className="font-[800]">Medwise Premium</span>
                        </h3>
                    </div>
                    <div className="group group--sm">
                        <Link to={'/pricing'} className="btn btn--gradient-secondary btn--md rounded-full">
                            <span className="text-[14px] font-[500]">
                                Upgrade to Premium
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpgradeToPremium
