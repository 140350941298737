import { createSlice } from '@reduxjs/toolkit';
import { getAllPagesFetch, getAnatomyPageFetch, getHomePageFetch, getPagesByPathFetch } from './pagesThunks';

const initialState = {
    pages: [],
    pageData: [],
    reponseMessage: null,
    isLoading: false
}

const pagesSlice = createSlice({
    name: 'pages',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.reponseMessage = action.payload
        }
    },

    extraReducers(builder) {
        builder.addCase(getAllPagesFetch.fulfilled, (state, {payload}) => {
            state.pages = payload
            state.isLoading = false

        });
        builder.addCase(getAllPagesFetch.pending, (state, { payload }) => {
            state.pages = []
            state.isLoading = true
        });
        builder.addCase(getAllPagesFetch.rejected, (state, { payload }) => {
            state.pages = []
            state.isLoading = false
        });
        builder.addCase(getPagesByPathFetch.fulfilled, (state, { payload}) => {
            state.pageData = payload
            state.isLoading = false

        });
        builder.addCase(getPagesByPathFetch.pending, (state, { payload }) => {
            state.pageData = []
            state.isLoading = true
        });
        builder.addCase(getPagesByPathFetch.rejected, (state, { payload }) => {
            state.pageData = []
            state.isLoading = false
        });
        builder.addCase(getHomePageFetch.fulfilled, (state, { payload}) => {
            state.pageData = payload
            state.isLoading = false

        });
        builder.addCase(getHomePageFetch.pending, (state, { payload }) => {
            state.pageData = []
            state.isLoading = true
        });
        builder.addCase(getHomePageFetch.rejected, (state, { payload }) => {
            state.pageData = []
            state.isLoading = false
        });
        builder.addCase(getAnatomyPageFetch.fulfilled, (state, { payload}) => {
            state.pageData = payload
            state.isLoading = false

        });
        builder.addCase(getAnatomyPageFetch.pending, (state, { payload }) => {
            state.pageData = []
            state.isLoading = true
        });
        builder.addCase(getAnatomyPageFetch.rejected, (state, { payload }) => {
            state.pageData = []
            state.isLoading = false
        });
    }
})

export const {
    setMessage
} = pagesSlice.actions;

export const getAllPages = (state) => state.pages.pages

export default pagesSlice.reducer