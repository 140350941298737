import React from 'react'

const TextareaGroup = ({label, textareaClasses='',wrapperClasses='',placeholder,defaultValue,value,rows = '7',error, errorMessage, ...props}) => { 
  return (
    <div className={`input-wrapper ${wrapperClasses}`}>
        {label && <label className="label">{label}</label>}
        <textarea 
            className={`input ${textareaClasses}`} 
            placeholder={placeholder} 
            defaultValue={defaultValue} 
            rows={rows} 
            value={value}
            {...props}
        ></textarea>
        {
        errorMessage && error ?
          <p className='color-danger text--xs mt-1'>{errorMessage}</p>
          :
          null
      }
    </div>
  )
}

export default TextareaGroup
