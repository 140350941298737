import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Circle, Image as KonvaImage, Layer, Line, Stage, Group, Rect } from 'react-konva';
import { useImage } from "react-konva-utils";
import { getImageSrc } from "../../helpers/utils";

const HINT_OPTION = {
    radius: 8,
    strokeWidth: 13,
    strokeDefaultColor: "#19191932",
    strokeActiveHintColor: '#FFE47F',
    fillDragColor: '#FFE47F',
    filDefaultColor: '#ffffff',
    filActiveColor: '#191919'
}

const ImageSetSliderPart = ({ images = null, hintsSelected = {}, poinHandler, isActiveHitn, scrollTo, pointsShow }) => {
    // const [lines, setLines] = useState([]);
    const containerRef = useRef();
    const stageRef = useRef();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageSrc, setImageSrc] = useState(null);
    const [loadedImage] = useImage(imageSrc);
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0
    });

    const updateCanvasDimensions = () => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            });
        }
    };
    useEffect(() => {
        updateCanvasDimensions();
        window.addEventListener('resize', updateCanvasDimensions);
        return () => window.removeEventListener('resize', updateCanvasDimensions);
    }, []);
    useEffect(() => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            })
        }
    }, [containerRef, loadedImage, pointsShow])

    const calculateScale = () => {
        if (!loadedImage) return { x: 1, y: 1 };

        const scaleX = dimensions.width / loadedImage.width;
        const scaleY = dimensions.height / loadedImage.height;
        const scale = Math.min(scaleX, scaleY);
        return { x: scale, y: scale };
    };

    const calculateOffset = () => {
        if (!loadedImage) return { x: 0, y: 0 };
        const scaledWidth = loadedImage.width * calculateScale().x;
        const scaledHeight = loadedImage.height * calculateScale().y;
        const offsetX = (dimensions.width - scaledWidth) / 2;
        const offsetY = (dimensions.height - scaledHeight) / 2;
        return { x: offsetX, y: offsetY };
    };
    const scaledLines = hintsSelected?.commentMarkers?.map(line => ({
        ...line,
        points: line.points.map((p, i) =>
            i % 2 === 0
                ? p * calculateScale().x + calculateOffset().x
                : p * calculateScale().y + calculateOffset().y
        ),
    }));
    const scaledHints = hintsSelected?.commentTexts?.map(hint => ({
        ...hint,
        position: hint.position ? { x: hint.position.x * calculateScale().x + calculateOffset().x, y: hint.position.y * calculateScale().y + calculateOffset().y } : null
    }))


    useEffect(() => {
        if (images) {
            const loadImage = async () => {
                setImageSrc(getImageSrc(images?.fileName));
            };
            loadImage();
        }
    }, [currentIndex, images]);
    if (!hintsSelected) return null
    return (
        <>
            <div ref={containerRef} style={{ width: '100%', height: '100%' }}  >
                <Stage
                    width={dimensions.width}
                    height={dimensions.height}
                    ref={stageRef}
                >
                    <Layer>
                        {loadedImage && <KonvaImage
                            image={loadedImage}
                            width={loadedImage.width * calculateScale().x}
                            height={loadedImage.height * calculateScale().y}
                            x={calculateOffset().x}
                            y={calculateOffset().y}
                        />}
                    </Layer>
                   {pointsShow && <Layer>
                        {scaledLines?.map((line, i) => (
                            <Line
                                key={i}
                                points={line.points}
                                stroke={line.tool === 'erase' ? 'white' : line.color}
                                strokeWidth={line.strokeWidth}
                                tension={0.5}
                                lineCap="round"
                                globalCompositeOperation={
                                    line.tool === 'erase' ? 'destination-out' : 'source-over'
                                }
                            />
                        ))}
                    </Layer>}
                   {pointsShow && <Layer>
                        {scaledHints?.map((hint, index) => {
                            if (hint.position) {
                                return <Group
                                    key={index}
                                    x={hint.position?.x}
                                    y={hint.position?.y}
                                    onMouseEnter={() => {
                                        poinHandler(hint.id)
                                        scrollTo(index)
                                    }}
                                    // onMouseLeave={() => {
                                    //     poinHandler(false)
                                    // }}
                                    onTap={() => {
                                        poinHandler(hint.id)
                                    }}
                                >
                                    <Circle
                                        radius={HINT_OPTION.radius}
                                        stroke={
                                            isActiveHitn === hint.id ?
                                                HINT_OPTION.strokeActiveHintColor :
                                                HINT_OPTION.strokeDefaultColor
                                        }
                                        strokeWidth={HINT_OPTION.strokeWidth}
                                        fill={`
                                                ${isActiveHitn === hint.id ?
                                                HINT_OPTION.filActiveColor :
                                                HINT_OPTION.filDefaultColor}`
                                        }
                                        fillAfterStrokeEnabled
                                    />

                                </Group>
                            } else {
                                return null
                            }
                        })}
                    </Layer>}
                </Stage>
            </div>
        </>
    );
   
};

export default ImageSetSliderPart;