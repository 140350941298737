import React, { useEffect, useState } from 'react';
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import UploadImage from '../../components/UI/UploadImage';

import { ReactComponent as ImagesIcon } from "../../assets/img/ico-img-&-videos.svg"
import { ReactComponent as VideoIcon } from "../../assets/img/ico-videos.svg"
import { ReactComponent as DocumentIcon } from "../../assets/img/documents-file-text.svg"
import { ReactComponent as ArrowLeftIcon } from "../../assets/img/ico-arrow-left.svg"
import TextEditor from '../../components/parts/TextEditor';
import TextEditorCourse from '../../components/parts/TextEditorCourse';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Loader from '../../components/UI/Loader';
import { createLessonByCourseIdFeth, deleteLessonByLessonIdFeth, editLessonByLessonIdFeth, lessonsOrderingByCourseIdFeth } from '../../store/courses/coursesThunk';
import { setClearCourse, setLesson, setLessonId } from '../../store/courses/coursesSlice';
import { getImageSrc } from '../../helpers/utils';
import InputGroup from '../../components/UI/formElements/InputGroup';
import { toast } from 'react-toastify';



const CourseCreateContentAddLesson = () => {
    const { id } = useParams()
    const conf = {
        title: 'Add Lesson',
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { courseOne, courseId, isLoading, currentLessonID, currentLesson } = useSelector(state => state.courses)
    const [activeTab, setActiveTab] = useState("video")
    const [type, setType] = useState('video+text');
    const [content, setContent] = useState('')
    const [title, setTitle] = useState('')
    const [lessonData, setLessonData] = useState({
        'video+text': {
            link: '',
            media: null
        },
        'image+text': {
            link: '',
            media: null
        },
        'document+text': {
            link: '',
            media: null
        },
    })
    useEffect(() => {
        if (currentLessonID && currentLesson && currentLesson?.type) {
            setType(currentLesson.type)
            setLessonData(prev => ({
                ...prev,
                [currentLesson.type]: {
                    link: currentLesson.link,
                    media: currentLesson.media?.length > 0 ? {
                        id: currentLesson.media[0].id,
                        preview: currentLesson.media[0].id ? getImageSrc(currentLesson.media[0].fileName) : null,
                        type: `${currentLesson.media[0].type}/${currentLesson.media[0].fileName.split('.').pop()}`
                    } : null
                }
            }))
            setActiveTab(currentLesson.type === 'image+text' ? 'image' : currentLesson.type === 'video+text' ? 'video' : 'document')
            setContent(currentLesson.content)
            setTitle(currentLesson.title)
        } else {

            clearLocalState()
        }
    }, [currentLessonID])

    const clearLocalState = () => {
        setLesson({
            'video+text': {
                link: '',
                media: null
            },
            'image+text': {
                link: '',
                media: null
            },
            'document+text': {
                link: '',
                media: null
            },
        })
        setContent('')
        setTitle('')
    }

    const inputsHandler = (type, name, value) => {
        if (type === 'video+text' && value instanceof File) {
            const maxSizeInMB = 1024;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            if (value.size >= maxSizeInBytes) {
                toast.warn(`Some files exceed the maximum size of ${maxSizeInMB} MB and were not added.`);
                return
            }
        }
        setLessonData(prev => ({
            ...prev,
            [type]: {
                ...prev[type],
                [name]: value
            }
        }))
    }

    const clearLesson = () => {
        dispatch(setLesson(null))
        dispatch(setLessonId(null))
        clearLocalState()
    }
    const submitHandler = async (e, createAnother = false) => {
        if (title.trim().length === 0) {
            toast.error('Title is required')
            return
        }
        e.preventDefault()
        let res = null
        const data = lessonData[type]
        const formData = new FormData()

        formData.append('order', +courseOne.lessons.length)
        formData.append('type', type)
        formData.append('title', title)
        if (data.link && data.link.length > 0) {
            formData.append('link', data.link)
        }
        formData.append('content', content)
        if (data.media) {
            if (data.media instanceof File) {
                if (type === 'video+text') {
                    formData.append('video', data.media)
                } else if (type === 'image+text') {
                    formData.append('images', data.media)
                } else {
                    formData.append('document', data.media)
                }
            } else {
                if (type === 'image+text') {
                    let imageIds = []
                    imageIds.push(data.media.id)
                    formData.append('existingImageIds', JSON.stringify(imageIds))
                } else if (type === 'video+text') {
                    formData.append('video', JSON.stringify(data.media))
                } else {
                    formData.append('document', JSON.stringify(data.media))
                }
            }
        }
        toast.info('Uploading...', {
            toastId: 'uploadProgress', // Уникальный идентификатор для обновления
            autoClose: false, // Отключаем авто-закрытие
            closeButton: false, // Отключаем кнопку закрытия
            progress: 0, // Начальный прогресс
        });
        if (currentLessonID) {
            res = dispatch(editLessonByLessonIdFeth({ id: currentLessonID, body: formData }))
        } else {
            res = await dispatch(createLessonByCourseIdFeth({ id: courseId, body: formData })).unwrap()
        }
        if (res) {

            if (createAnother) {
                dispatch(setLesson(null))
                dispatch(setLessonId(null))
            }
        }
    }
    const deletehandler = async () => {
        const filteredLessons = courseOne.lessons.filter(item => item.id !== currentLessonID)
        let res = await dispatch(deleteLessonByLessonIdFeth(currentLessonID)).unwrap()
        if (res) {
            const orderingData = filteredLessons.map((item, index) => ({
                lessonId: item.id,
                order: index
            }))
            const res2 = await dispatch(lessonsOrderingByCourseIdFeth({
                id: courseId, body: {
                    "ordering": orderingData
                }
            })).unwrap()
            if (res2) {
                if (id) {
                    navigate(`/course-edit/${id}/content`)
                } else {
                    navigate(`/course-create/content`)
                }
            }
        }
    }
    function capitalizeFirstLetter(str) {
        return !str ? str : str.charAt(0).toUpperCase() + str.slice(1);
    }

    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="tabs gap-[32px]">
                                        <div className="tabs-header">
                                            <ul className="tabs-nav flex-wrap">
                                                <li className="tabs-nav--item max-w-max">
                                                    <button
                                                        onClick={() => {
                                                            setActiveTab("video")
                                                            setType('video+text')
                                                        }}
                                                        className={`btn btn-tablink btn-tablink-row btn--md rounded-full bg-transparent px-[10px] ${activeTab === "video" ? "active-tab" : ""}`}
                                                    >
                                                        <span className="ico">
                                                            <VideoIcon />
                                                        </span>
                                                        <span className="text-[13px]">Video + Text</span>
                                                    </button>
                                                </li>
                                                <li className='flex items-center uppercase'>or</li>
                                                <li className="tabs-nav--item max-w-max">
                                                    <button
                                                        onClick={() => {
                                                            setActiveTab("image")
                                                            setType('image+text')
                                                        }}
                                                        className={`btn btn-tablink btn-tablink-row btn--md rounded-full bg-transparent px-[10px] ${activeTab === "image" ? "active-tab" : ""}`}
                                                    >
                                                        <span className="ico">
                                                            <ImagesIcon />
                                                        </span>
                                                        <span className="text-[13px]">Image(s) + Text</span>
                                                    </button>
                                                </li>
                                                <li className='flex items-center uppercase'>or</li>
                                                <li className="tabs-nav--item max-w-max">
                                                    <button
                                                        onClick={() => {
                                                            setActiveTab("document")
                                                            setType('document+text')
                                                        }}
                                                        className={`btn btn-tablink btn-tablink-row btn--md rounded-full bg-transparent px-[10px] ${activeTab === "document" ? "active-tab" : ""}`}
                                                    >
                                                        <span className="ico">
                                                            <DocumentIcon />
                                                        </span>
                                                        <span className="text-[13px]">Document + Text</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tabs-body">
                                            <div className={`tabs-item gap-[16px] active-tab `}>
                                                <InputGroup
                                                    label={'Lesson title'}
                                                    placeholder={'Enter lesson title'}
                                                    wrapperClasses={'input--md'}
                                                    inputClasses='input--outline'
                                                    value={title}
                                                    onChange={({ target }) => { setTitle(target.value) }}
                                                />
                                                <InputGroup
                                                    label={`${capitalizeFirstLetter(activeTab)} link`}
                                                    placeholder={`Enter ${activeTab} link...`}
                                                    wrapperClasses={'input--md'}
                                                    inputClasses='input--outline'
                                                    value={lessonData[type].link}
                                                    onChange={({ target }) => { inputsHandler(type, 'link', target.value) }}
                                                />
                                                <UploadImage
                                                    isRow={true}
                                                    type={activeTab}
                                                    onChange={(image) => {
                                                        inputsHandler(type, 'media', ...image)
                                                    }}
                                                    defaulImages={lessonData[type].media?.preview ? [lessonData[type].media] : []}
                                                />

                                                <TextEditor editorData={content} setData={setContent} />
                                            </div>
                                            {/* <div className={`tabs-item gap-[16px] ${activeTab === 'image' ? 'active-tab' : ''}`} id="images">
                                                <div className="input-wrapper input--md mb-[12px]">
                                                    <input className="input input--outline" type="text" placeholder={`Enter ${activeTab} link...`} />
                                                </div>
                                                <UploadImage isRow={true} type={activeTab} multiple={true} />
                                                
                                            </div>
                                            <div className={`tabs-item gap-[16px] ${activeTab === 'document' ? 'active-tab' : ''}`} id="notes">
                                                <div className="input-wrapper input--md mb-[12px]">
                                                    <input className="input input--outline" type="text" placeholder={`Enter ${activeTab} link...`} />
                                                </div>
                                                <UploadImage isRow={true} type={activeTab} />
                                                
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/course-create/content'} onClick={clearLesson} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="ico">
                                            <ArrowLeftIcon />
                                        </span>
                                        <span className="info text-[13px] font-[500]">Back</span>
                                    </Link>
                                    <div className="flex gap-[12px]">
                                        {currentLessonID && <button type='button' onClick={deletehandler} className="btn btn--danger gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Delete</span>
                                        </button>}
                                        <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save</span>
                                        </button>
                                        <button type='button'
                                            onClick={(e) => {
                                                { submitHandler(e, true) }
                                            }}
                                            className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save and Add Another</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div >
        </>
    );
};

export default CourseCreateContentAddLesson;
