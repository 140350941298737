import React, { useEffect, useMemo, useState } from 'react'
import SelectGroup from '../../components/UI/formElements/SelectGroup';
import SwitchViewType from '../../components/parts/SwitchViewType';
import Pagination from '../../components/parts/Pagination';
import CardForum from '../../components/parts/Cards/CardForum';
import { filterOptions } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { getAllForumsFeth } from '../../store/forums/forumsThunk';
import { useDispatch } from 'react-redux';
import PageLoader from '../../components/parts/PageLoader';
import { getProfileForumsFeth } from '../../store/profile/profileThunk';

const ProfileForums = () => {
  const [isList, setIsList] = useState(false)
  const { authorized,  showLoginModal } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [userId] = useOutletContext()
  const { profileData, isLoading, pagination } = useSelector(state => state.profile);
  const [searchParams, setSearchParams] = useSearchParams()
  // const [params, setParams] = useState({
  //   sort: 'latest',
  // })
  const formatOptionsQuery = (value) => {
		return value?.length
			? value.split(',').filter(Boolean) || []
			: []
	}
	const params = useMemo(() => ({
		sort: searchParams.get('sort') || 'latest',
	}), [searchParams])
  const handleSortChange = (option) => {
    // setParams(prev => ({
    //   ...prev,
    //   sort: option.value
    // }))
    setSearchParams({
      ...params,
      sort: option.value,
    });
  }
  useEffect( () => {
    if(!userId) return
    const fetchData = async () => {
     
      const res = await  dispatch(getProfileForumsFeth({ id: userId, qwery: searchParams.toString() })).unwrap()
      if(res) {
          window.scrollTo(0, 0)
      }
    }
    
    fetchData()
  }, [userId, searchParams])
  
  // useEffect(() => {
  //   const sort = searchParams.get('sort') || 'latest';
  //   setParams({  sort });
  // }, [searchParams])

  return (
    <div className="tabs-item active-tab" id="quizzes">
      <div className="w-full">
        <div className="page-heading mb-[30px]">
          <div className="page-heading__item">
            <h3 className="mr-[24px]">Forums</h3>
            <SwitchViewType onChange={(checked) => setIsList(checked)} />
          </div>
          <div className="page-heading__item">
            <SelectGroup
              wrapperClasses={'input--sm'}
              options={filterOptions}
              defaultValue={filterOptions[0]}
              onChange={handleSortChange}
            />
          </div>
        </div>
        <div className="row gutters-cards flex-auto">
          {
            profileData && profileData.length ?
              profileData.map(card => (
                <div key={card.id} className={!isList ? "col-sm-6 col-lg-4" : "col-12"} >
                  <CardForum data={card} isList={isList} authorized={authorized} />
                </div>
              ))
              : isLoading ?
              <PageLoader /> :
              <h2>Page is empty</h2>
          }
        </div>
        <Pagination {...pagination} params={params} />  
      </div>
    </div>
  )
}

export default ProfileForums
