import { Link, useNavigate, useParams } from "react-router-dom";
import UploadImage from "../../components/UI/UploadImage";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../components/UI/Loader";
import { getFilteres } from "../../store/filters/filtersSlice";
import { useEffect, useState } from "react";
import { getFiltersByName } from "../../store/filters/filtersThukns";
import SelectGroupRow from "../../components/UI/formElements/SelectGroupRow";
import { useValidation } from "../../hooks/useValidation";
import { getFormData, getImageSrc } from "../../helpers/utils";
import { createForumFeth, updateForumByIdFeth } from "../../store/forums/forumsThunk";
import { ReactComponent as ArrowRightIcon } from '../../assets/img/ico-arrow-right.svg'
import TextareaGroupRow from "../../components/UI/formElements/TextareaGroupRow";
import InputGroupRow from "../../components/UI/formElements/InputGroupRow";
import { indexOf } from "ckeditor5";

export const ForumGeneralInformation = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { forumOne, forumId, isLoading } = useSelector(state => state.forums)
    const systems = useSelector(getFilteres);
    const categotyes = systems ? systems.map(item => ({ value: item?.name, label: item?.name })) : null;
    const conf = {
        title: 'General Information',
    }
    const [formData, setFormData] = useState({
        cover: null,
        name: '',
        description: '',
        system: null,
    })

    const validators = {
        name: value => value?.length > 1 ? true : 'Name must be more than 1 characters',
        description: value => value?.length > 5 ? true : 'Description must be more than 5 characters',
        cover: file => {
            if (!file) {
                return 'Cover is required';
            }
            if (!file?.type?.startsWith('image/')) {
                return 'Photo must be an image file (jpg, jpeg, png, gif)';
            }

            return true;
        },
        system: value => value ? true : 'System is required',
    };
    const messages = {
        name: 'First name is required',
        description: 'Description is required',
        cover: 'Cover is required',
        system: 'System is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(
        formData,
        {
            validators,
            messages
        }
    );

    const formDataHandler = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const submitHandler = async (e) => {

        e.preventDefault();
        if (errors) {
            setIsErrorShown(true)
            return
        }
        const newData = { ...formData }
        delete newData.cover
        if (formData.cover instanceof File) {
            newData['cover'] = formData.cover
        }
        const body = getFormData(newData);

        let res = null
        if (forumId) {
            res = await dispatch(updateForumByIdFeth({ id: forumId, body })).unwrap()
        } else {
            res = await dispatch(createForumFeth(body)).unwrap()
        }
        if (res) {
            if (id) {
                navigate(`/forum/${id}/rules`)
            } else {
                navigate(`/forum/rules`)
            }

            setIsErrorShown(false);
        }
    }
    useEffect(() => {
        if (forumOne) {
            setFormData({
                cover: {
                    preview: getImageSrc(forumOne?.cover),
                    type: `image/${forumOne?.cover.split('.').pop()}`
                },
                name: forumOne?.name,
                description: forumOne?.description,
                system: forumOne?.filter?.name,
            })
        }

    }, [forumOne])
    useEffect(() => {
        dispatch(getFiltersByName('system'))
    }, [dispatch])
    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>
    return (
        <div>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group items-center gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Forum
                                                    Cover</p>
                                                <UploadImage
                                                    onChange={(image) => {
                                                        formDataHandler('cover', ...image)
                                                    }}
                                                    defaulImages={formData?.cover ? [formData?.cover] : []}
                                                    error={isErrorShown && errors?.cover}
                                                    errorMessage={errorMessages?.cover}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <InputGroupRow
                                                label={"Forum Name"}
                                                inputClasses={'input--outline'}
                                                placeholder={'Enter Forum Name...'}
                                                defaultValue={forumOne?.name}
                                                onChange={({ target }) => formDataHandler('name', target.value)}
                                                error={isErrorShown && errors?.name}
                                                errorMessage={errorMessages?.name}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Forum Category'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                options={categotyes}
                                                placeholder={"Select category"}
                                                defaultValue={forumOne?.filter ? { value: forumOne?.filter?.name, label: forumOne?.filter?.name } : null}
                                                onChange={(option) => { formDataHandler('system', option.value) }}
                                                error={isErrorShown && errors?.system}
                                                errorMessage={errorMessages?.system}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextareaGroupRow
                                                label={'Description'}
                                                placeholder={"Enter Description..."}
                                                textareaClasses="input--outline"
                                                onChange={({ target }) => formDataHandler('description', target.value)}
                                                defaultValue={forumOne?.description}
                                                rows="7"
                                                error={isErrorShown && errors?.description}
                                                errorMessage={errorMessages?.description}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/forums'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <ArrowRightIcon />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </div>
    )
}
