import { useSelector } from "react-redux";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setClearForum } from "../../store/forums/forumsSlice";
import { setHasEditChanges } from "../../store/auth/authSlice";
import { createForumSettingsByForumIdFeth, editForumSettingsByForumIdFeth } from "../../store/forums/forumsThunk";
import Loader from "../../components/UI/Loader";

export const ForumSettings = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { forumOne, forumId, isLoading } = useSelector(state => state.forums)
    const [forumSettings, setForumSettings] = useState({
        threadsSetup: "any", //enum: ["any", "links", "texts"]
        allowImagesUploads: true,
        allowMultipleImages: true,
        allowComments: true,
        threadImages: true,
        threadVideos: true,
        threadFiles: true,
        threadLinks: true
    })
    const inputHandler = (name, value) => {
        setHasEditChanges(true)
        setForumSettings(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const conf = {
        title: 'Settings',
    }
    const submitHandler = async (e, createAnother = false) => {
        let res = null
        if (forumOne.settings) {
            res = await dispatch(editForumSettingsByForumIdFeth({ id: forumId, body: forumSettings })).unwrap()
        } else {
            res = await dispatch(createForumSettingsByForumIdFeth({ id: forumId, body: forumSettings })).unwrap()
        }
        if (res) {
            if (createAnother) {
                dispatch(setClearForum())
                navigate(`/course-create/general`)
            }
            dispatch(setHasEditChanges(false))
        }
    }
    useEffect(() => {
        if (forumOne?.settings) {
            setForumSettings(forumOne.settings)
        }
    }, [forumOne])
    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content pt-[20px]">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--full">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="container container--lg">
                                                <div className="group gap--lg md:flex-nowrap">
                                                    <p className="text--md font-medium min-w-[140px] md:text-right">Threads
                                                        Setup</p>
                                                    <div className="w-full">
                                                        <div className="mb-[26px]">
                                                            <label className="quiz-answer-item">
                                                                <input
                                                                    type="radio" name="cards_per_round"
                                                                    hidden
                                                                    checked={forumSettings.threadsSetup === 'any'}
                                                                    onChange={() => inputHandler('threadsSetup', 'any')}
                                                                />
                                                                <span
                                                                    className="quiz-answer-item__icon mr-[16px]"></span>
                                                                <div className="w-full">
                                                                    <p className="text--sm mb-[6px]">Any</p>
                                                                    <p className="color-secondary text--xs mb-[16px]">Any
                                                                        thread type is allowed</p>
                                                                </div>
                                                            </label>
                                                            <label className="quiz-answer-item">
                                                                <input
                                                                    type="radio" name="cards_per_round" hidden
                                                                    checked={forumSettings.threadsSetup === 'links'}
                                                                    onChange={() => inputHandler('threadsSetup', 'links')}
                                                                />
                                                                <span
                                                                    className="quiz-answer-item__icon mr-[16px]"></span>
                                                                <div className="w-full">
                                                                    <p className="text--sm mb-[6px]">Links only</p>
                                                                    <p className="color-secondary text--xs mb-[16px]">Only
                                                                        links to external websites are allowed</p>
                                                                </div>
                                                            </label>
                                                            <label className="quiz-answer-item">
                                                                <input
                                                                    type="radio" name="cards_per_round" hidden
                                                                    checked={forumSettings.threadsSetup === 'texts'}
                                                                    onChange={() => inputHandler('threadsSetup', 'texts')}
                                                                />
                                                                <span
                                                                    className="quiz-answer-item__icon mr-[16px]"></span>
                                                                <div className="w-full">
                                                                    <p className="text--sm mb-[6px]">Text threads
                                                                        only </p>
                                                                    <p className="color-secondary text--xs mb-[16px]">Only
                                                                        text threads are allowed</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr className="hr" />
                                        </div>
                                        <div className="col-12">
                                            <div className="container container--lg">
                                                <div className="group gap--lg md:flex-nowrap">
                                                    <p className="text--md font-medium min-w-[140px] md:text-right">Options</p>
                                                    <div className="checkboxes-list">
                                                        <label className="check-form">
                                                            <input
                                                                type="checkbox" className="hidden"
                                                                checked={forumSettings.allowImagesUploads}
                                                                onChange={({ target }) => inputHandler('allowImagesUploads', target.checked)}
                                                            />
                                                            <span className="check"></span>
                                                            <p className="check-text">
                                                                Allow image uploads and links to image hosting sites
                                                            </p>
                                                        </label>
                                                        {/* <label className="check-form">
                                                            <input
                                                                type="checkbox" className="hidden"
                                                                checked={forumSettings.allowMultipleImages}
                                                                onChange={({ target }) => inputHandler('allowMultipleImages', target.checked)}
                                                            />
                                                            <span className="check"></span>
                                                            <p className="check-text">
                                                                Allow multiple images per post
                                                            </p>
                                                        </label> */}
                                                        <label className="check-form">
                                                            <input
                                                                type="checkbox" className="hidden"
                                                                checked={forumSettings.allowComments}
                                                                onChange={({ target }) => inputHandler('allowComments', target.checked)}
                                                            />
                                                            <span className="check"></span>
                                                            <p className="check-text">
                                                                Allow comments
                                                            </p>
                                                        </label>
                                                        {/* <label className="check-form">
                                                            <input 
                                                                type="checkbox" className="hidden"
                                                                checked={forumSettings}
                                                                onChange={({target}) => setForumSettings('allowComments', target.checked)}
                                                            />
                                                            <span className="check"></span>
                                                            <p className="check-text">
                                                                Allow creating threads
                                                            </p>
                                                        </label> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr className="hr" />
                                        </div>
                                        {/* <div className="col-12">
                                            <div className="container container--lg">
                                                <div className="group gap--lg md:flex-nowrap">
                                                    <p className="text--md font-medium min-w-[140px] md:text-right">Media
                                                        in Threads</p>
                                                    <div className="checkboxes-list">
                                                        <label className="check-form">
                                                            <input
                                                                type="checkbox" className="hidden"
                                                                checked={forumSettings.threadImages}
                                                                onChange={({ target }) => inputHandler('threadImages', target.checked)}
                                                            />
                                                            <span className="check"></span>
                                                            <p className="check-text">
                                                                Images
                                                            </p>
                                                        </label>
                                                        <label className="check-form">
                                                            <input
                                                                type="checkbox" className="hidden"
                                                                checked={forumSettings.threadVideos}
                                                                onChange={({ target }) => inputHandler('threadVideos', target.checked)}
                                                            />
                                                            <span className="check"></span>
                                                            <p className="check-text">
                                                                Videos
                                                            </p>
                                                        </label>
                                                        <label className="check-form">
                                                            <input
                                                                type="checkbox" className="hidden"
                                                                checked={forumSettings.threadFiles}
                                                                onChange={({ target }) => inputHandler('threadFiles', target.checked)}
                                                            />
                                                            <span className="check"></span>
                                                            <p className="check-text">
                                                                Files
                                                            </p>
                                                        </label>
                                                        <label className="check-form">
                                                            <input
                                                                type="checkbox" className="hidden"
                                                                checked={forumSettings.threadLinks}
                                                                onChange={({ target }) => inputHandler('threadLinks', target.checked)}
                                                            />
                                                            <span className="check"></span>
                                                            <p className="check-text">
                                                                Links
                                                            </p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/forums'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <div className="group items-center md:flex-nowrap gap--xs">
                                        <button type="button" onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save</span>
                                        </button>
                                        <button type="button" onClick={(e) => submitHandler(e, true)} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save and Create Another</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}