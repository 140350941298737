import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getMeFetch = createAsyncThunk(
    '/get-users/',
    async (payload, { rejectWithValue, abort }) => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                return abort();
            }
            const res = await api.users.getAllUsers();
            if (res.success) {
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            
            toast.error("Something went wrong")
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getUserFetch = createAsyncThunk(
    '/getUserFetch/',
    async ({ userId }, { rejectWithValue, abort }) => {
        try {
            const res = await api.users.getUser({ userId });
            if (res.success) {
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            
            toast.error("Something went wrong")
            return rejectWithValue(err?.response.data);
        }
    },
);
