import { createSlice, current } from '@reduxjs/toolkit';
import { cloneLessonByIdFeth, completeLessonByLessonIdFeth, createCourseFeth, createLessonByCourseIdFeth, createSettingsByCourseIdFeth, deleteLessonByLessonIdFeth, editCourseByIdFeth, editLessonByLessonIdFeth, editSettingsByCourseIdFeth, favoriteCourseByCourseIdFeth, getAllCoursesFeth, getCourseByIdFeth, likeCourseByCourseIdFeth } from './coursesThunk';

const initialState = {
    coursesAll: [],
    courseOne: null,
    courseId: null,
    isLoading: false,
    errors: null,
    currentLesson: null,
    currentLessonID: null,
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    }
}

const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setClearCourse: (state, action) => {
            state.courseOne = null
            state.courseId = null
        },
        setLessonId: (state, action) => {
            state.currentLessonID = action.payload
        },
        setLesson: (state, action) => {
            state.currentLesson = action.payload
        },
        setLessons: (state, action) => {
            state.courseOne.lessons = action.payload
        }
    },

    extraReducers(builder) {
        builder
            .addCase(getAllCoursesFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.coursesAll = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllCoursesFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getAllCoursesFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getCourseByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload) {
                    state.courseOne = action.payload
                    state.courseId = action.payload.id
                }

            })
            .addCase(getCourseByIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(getCourseByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(createCourseFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.courseOne = action.payload
                state.courseId = action.payload.id
            })
            .addCase(createCourseFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(createCourseFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editCourseByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.courseOne = {
                    ...state.courseOne,
                    ...action.payload
                }
            })
            .addCase(editCourseByIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editCourseByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(createSettingsByCourseIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.courseOne.settings = action.payload
            })
            .addCase(createSettingsByCourseIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(createSettingsByCourseIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editSettingsByCourseIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.courseOne.settings = action.payload
            })
            .addCase(editSettingsByCourseIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editSettingsByCourseIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(createLessonByCourseIdFeth.fulfilled,(state, action) => {
                state.isLoading = false
                state.courseOne.lessons.push(action.payload)
                state.currentLesson = action.payload
                state.currentLessonID = action.payload.id
            })
            .addCase(createLessonByCourseIdFeth.pending,(state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(createLessonByCourseIdFeth.rejected,(state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editLessonByLessonIdFeth.fulfilled,(state, action) => {
                state.isLoading = false
                console.log(current(state.courseOne.lessons))
                let lessonIndex = state.courseOne.lessons.findIndex(lesson => lesson.id === action.payload.id)
                if(lessonIndex) {
                    state.courseOne.lessons[lessonIndex] = action.payload
                }
                state.currentLesson = action.payload
            })
            .addCase(editLessonByLessonIdFeth.pending,(state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editLessonByLessonIdFeth.rejected,(state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(deleteLessonByLessonIdFeth.fulfilled,(state, action) => {
                if(action.payload)  {
                    const filteredLessons = state.courseOne.lessons.filter(item => item.id !== action.payload.id);
                    state.courseOne.lessons = filteredLessons.map((item, index) => ({
                            ...item, 
                            order: index
                    }))
                }
            })
            .addCase(likeCourseByCourseIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.coursesAll.find(item => item.id === action.payload.id);
                if(currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes
                }
                if( state.courseOne) {
                    state.courseOne.isLiked = action.payload.isLiked
                    state.courseOne.likes = action.payload.likes
                }
            })
            .addCase(favoriteCourseByCourseIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.coursesAll.find(item => item.id === action.payload.id);
                if(currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }
                if(state.courseOne) {
                    state.courseOne.isFavorite = action.payload.isFavorite
                }
                
            })
            .addCase(completeLessonByLessonIdFeth.fulfilled, (state, action) => {
               state.courseOne.statistics = action.payload.statistics
            })
            .addCase(cloneLessonByIdFeth.fulfilled, (state, action) => {
               state.courseOne.lessons.push(action.payload)
            })
    }
})

export const {
    setClearCourse,
    setLessonId,
    setLesson,
    setLessons
} = coursesSlice.actions;


export default coursesSlice.reducer