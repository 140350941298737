import React, { useState } from 'react';
import InputSearch from './formElements/InputSearch';

const SearchButton = ({ value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSearch = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`relative transition-all duration-300 ${isOpen ? 'w-80' : 'w-32'}`}>
            <div className={`flex items-center justify-between transition-all duration-300 ${isOpen ? 'pl-4' : 'pl-2'}`}>
                <InputSearch
                    defaultValue={value}
                    onChange={onChange}
                    className={`transition-opacity input--sm duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                    placeholder="Search..."
                />
                <button
                    className="btn btn--md btn--transparent radius-5"
                    onClick={toggleSearch}
                >
                    <span className="ico">
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="10.1375"
                                cy="10.137"
                                r="6.47345"
                                stroke="#323232"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M18.3372 18.3367L14.7148 14.7144"
                                stroke="#292929"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </button>
            </div>
        </div>
    );
};

export default SearchButton;
