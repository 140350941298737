const pagesBase = '/api/notifications';

export const notifications = (instance) => ({
    async getUserNotifications(payload) {
        try {
            const { data } = await instance.get(`${pagesBase}?${payload}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getNotificationsPageContent(payload) {
        try {
            const { data } = await instance.get(`${pagesBase}/content`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getNewNotificationsCount(payload) {
        try {
            const { data } = await instance.get(`${pagesBase}/new`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async notificationsMarkAsReadOne(payload) {
        try {
            const { data } = await instance.post(`${pagesBase}/mark-as-read/${payload}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async notificationsMarkAsReadAll(payload) {
        try {
            const { data } = await instance.post(`${pagesBase}/mark-as-read`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});