import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import { getImageSrc } from './utils';
export const openFancybox = (media, startIndex = 0) => {
    const items = media.map((item) => ({
        src: getImageSrc(item)
    }));
    
    NativeFancybox.show(items, {
      startIndex: startIndex,
      on: {
        
      },
    });
  };