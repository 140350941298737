import React, { useState } from 'react';

import { ReactComponent as BookmarkIcon } from "../../../assets/img/ico-favor.svg";
import { ReactComponent as HeartIcon } from "../../../assets/img/ico-like.svg";

const Actions = ({ handleAddToFavoritesProps, isFavoriteProps, isLikeProps ,isDisabletFavorite = false, handleLikeProps}) => {
    
    return (
        <div className="card-actions">
            {!isDisabletFavorite ?  <button type="button" onClick={handleAddToFavoritesProps} className={`ico ico--sm btn--def rounded-full   favorite-btn ${isFavoriteProps ? "--selected" : ""}`}>
                <span className="ico ico-22">
                    <BookmarkIcon />
                </span>
            </button> : null}
            <button type="button" className={`ico ico--sm btn--def rounded-full favorite-btn ${isLikeProps ? "--selected" : ""}`} onClick={handleLikeProps}>
                <span className="ico ico-22">
                    <HeartIcon />
                </span>
            </button>
        </div>
    );
};

export default Actions;
