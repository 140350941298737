import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const createForumFeth = createAsyncThunk(
    '/createForumFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.createForum(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createRuleFeth = createAsyncThunk(
    '/createRuleFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.createRule(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createModeratorFeth = createAsyncThunk(
    '/createModeratorFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.createModerator(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const managingModeratorStatusFeth = createAsyncThunk(
    '/managingModeratorStatusFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.managingModeratorStatus(payload);
            if (res.success) {
                // toast.success(res.message)
                return { data: res.data, id: payload };
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createForumSettingsByForumIdFeth = createAsyncThunk(
    '/createForumSettingsByForumIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.createForumSettingsByForumId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createThreadFeth = createAsyncThunk(
    '/createThreadFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.createThread(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createThreadCommentFeth = createAsyncThunk(
    '/createThreadCommentFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.createThreadComment(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const likeForumFeth = createAsyncThunk(
    '/likeForumFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.likeForum(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const viewForumFeth = createAsyncThunk(
    '/viewForumFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.viewForum(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const favoriteForumFeth = createAsyncThunk(
    '/favoriteForumFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.favoriteForum(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const subscribeForumFeth = createAsyncThunk(
    '/subscribeForumFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.subscribeForum(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const likeThreadFeth = createAsyncThunk(
    '/likeThreadFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.likeThread(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const viewThreadFeth = createAsyncThunk(
    '/viewThreadFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.viewThread(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const favoriteThreadFeth = createAsyncThunk(
    '/favoriteThreadFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.favoriteThread(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const likeThreadCommentFeth = createAsyncThunk(
    '/likeThreadCommentFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.likeThreadComment(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const pinThreadCommentModeratorFeth = createAsyncThunk(
    '/pinThreadCommentModeratorFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.pinThreadCommentModerator(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const updateForumByIdFeth = createAsyncThunk(
    '/updateForumByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.updateForumById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const updateRuleByRuleIdFeth = createAsyncThunk(
    '/updateRuleByRuleIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.updateRuleByRuleId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const rulesOrderingByForumIdFeth = createAsyncThunk(
    '/rulesOrderingByForumIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.rulesOrderingByForumId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const moderatorOrderingByForumIdFeth = createAsyncThunk(
    '/moderatorOrderingByForumIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.moderatorOrderingByForumId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editForumSettingsByForumIdFeth = createAsyncThunk(
    '/editForumSettingsByForumIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.editForumSettingsByForumId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const updateThreadByThreadIdFeth = createAsyncThunk(
    '/updateThreadByThreadIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.updateThreadByThreadId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const updateThreadCommentFeth = createAsyncThunk(
    '/updateThreadCommentFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.updateThreadComment(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getAllForumsFeth = createAsyncThunk(
    '/getAllForumsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.getAllForums(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getForumFeth = createAsyncThunk(
    '/getForumFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.getForum(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getAllThreadsFeth = createAsyncThunk(
    '/getAllThreadsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.getAllThreads(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getThreadCommentFeth = createAsyncThunk(
    '/getThreadCommentFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.getThreadComments(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getFavoriteThreadsFetch = createAsyncThunk(
    '/getFavoriteThreadsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.getFavoriteThreads(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getThreadFeth = createAsyncThunk(
    '/getThreadFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.getThread(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getUsersForAssignAsModeratorByForumIdFeth = createAsyncThunk(
    '/getUsersForAssignAsModeratorByForumIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.getUsersForAssignAsModeratorByForumId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteRuleByRuleIdFeth = createAsyncThunk(
    '/deleteRuleByRuleIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.deleteRuleByRuleId(payload);
            if (res.success) {
                // toast.success(res.message)
                return { res, id: payload };
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteModeratorByModerIdFeth = createAsyncThunk(
    '/deleteModeratorByModerIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.deleteModeratorByModerId(payload);
            if (res.success) {
                // toast.success(res.message)
                return { res, id: payload };
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteThreadByThreadIdFeth = createAsyncThunk(
    '/deleteThreadByThreadIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.deleteThreadByThreadId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteThreadCommentFeth = createAsyncThunk(
    '/deleteThreadCommentFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.deleteThreadComment(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteForumFeth = createAsyncThunk(
    '/deleteForumFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.forums.deleteForum(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);