import React, { useEffect, useRef, useState } from 'react'
import ImageSetSliderPart from './ImageSetSliderPart'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Mousewheel, Navigation, Thumbs, Scrollbar, EffectFade } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/scrollbar';
import { getImageSrc } from '../../helpers/utils';


const CaseGalleryPart = ({ imagesSet, selectedVideo, showNotes, hideMobileActions = false }) => {
    const listRef = useRef(null);
    const swiperRef = useRef(null);
    const [mobileSliderScroll, setMobileSliderScroll] = useState(false);
    const [isActiveHitn, setIsActiveHiht] = useState(false)
    const hintClickHandler = (id, index) => {
        setIsActiveHiht(id)
    }
    const [initialSlide, setInitialSlide] = useState(0)
    const scrollToItem = (index) => {
        const itemElement = listRef.current.children[index];
        listRef.current.scrollTo({
            top: itemElement.offsetTop - 8,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        if (imagesSet?.images.length > 0) {
            setInitialSlide(imagesSet?.images?.findIndex(image => image.isCover))
        }
    }, [imagesSet])
    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(initialSlide, 0); // Переключение на активный индекс без анимации
        }
    }, [initialSlide]);

    return (
        <div className="article-cases-single" >
            <div className="article-cases-image">
                {!hideMobileActions && <>
                    <div className="article-cases-abs-nav">
                        <label className="check-btn">
                            <input type="checkbox" hidden={true} checked={mobileSliderScroll} onChange={() => {
                                setMobileSliderScroll(prev => !prev)
                            }} />
                            <div className="btn btn--shadow btn--sm btn--scrolla radius-5">
                                <span className="info">Scroll</span>
                            </div>
                        </label>
                    </div>
                    {!mobileSliderScroll && <div className="article-cases-abs--overlay"></div>}
                </>}
                <Swiper
                    ref={swiperRef}
                    speed={0}
                    effect={'fade'}
                    direction={'vertical'}
                    className={'canvas-slideshows mx-0 h-full'}
                    initialSlide={initialSlide}
                    mousewheel={{
                        releaseOnEdges: true
                    }}
                    fadeEffect={{ crossFade: true }}
                    spaceBetween={0}
                    slidesPerView={1}
                    modules={[EffectFade, Mousewheel, Scrollbar]}
                    onSlideChange={(swiper) => setInitialSlide(swiper.activeIndex)}
                    scrollbar={{
                        el: ".swiper-scrollbar",
                        hide: false,
                        draggable: true,
                    }}
                >
                    {
                        imagesSet && (
                            imagesSet?.images.map((item, index) => (
                                <SwiperSlide key={index} onClick={(e) => {
                                    e.stopPropagation()
                                }}>
                                    <ImageSetSliderPart
                                        images={imagesSet?.images[index]}
                                        hintsSelected={imagesSet?.images?.[initialSlide]}
                                        isActiveHitn={isActiveHitn}
                                        poinHandler={hintClickHandler}
                                        scrollTo={scrollToItem}
                                        pointsShow={showNotes}
                                    />
                                </SwiperSlide>
                            ))
                        )
                    }
                    {
                        selectedVideo && (
                            <SwiperSlide onClick={(e) => {
                                e.stopPropagation()
                            }}>
                                <video className='w-full h-full' src={getImageSrc(selectedVideo.fileName)} controls controlsList="nodownload" oncontextmenu="return false;" />
                            </SwiperSlide>
                        )
                    }

                    <div className="swiper-scrollbar"></div>
                </Swiper>

            </div>
            {!selectedVideo && showNotes && <ul
                ref={listRef}
                className={`article-cases-list --show`}>
                {imagesSet?.images?.[initialSlide]?.commentTexts?.map((comments, index) => (
                    <li className="article-case-i" key={index} >
                        <div
                            className={`check-btn case-checker ${comments.id === isActiveHitn ? '--active' : ''} `}
                            onClick={() => {
                                hintClickHandler(comments.id)
                                scrollToItem(index)
                            }}
                        >
                            <div className="case-deskription">
                                <p>{comments.content}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>}
        </div >
    )
}

export default CaseGalleryPart
