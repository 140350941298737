import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getSubscriptionPlansFeth = createAsyncThunk(
    '/getSubscriptionPlansFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.subscriptionPlan.getSubscriptionPlans(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const subscribeByPlanIdFeth = createAsyncThunk(
    '/subscribeByPlanIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.subscriptionPlan.subscribeByPlanId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const confirmSubscribeFeth = createAsyncThunk(
    '/confirmSubscribeFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.subscriptionPlan.confirmSubscribe(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const updateSubscribeByPlanIdFeth = createAsyncThunk(
    '/updateSubscribeByPlanIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.subscriptionPlan.updateSubscribeByPlanId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);


