const base = '/api/search';

export const search = (instance) => ({
    async getSearchResults(query) {
        try {
            const { data } = await instance.get(`${base}?search=${query}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});