import React, { useState } from 'react';
import {
    DndContext,
    DragEndEvent,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as DotsIcon } from '../../assets/img/ico-dots-2.svg';
const DraggableItem = ({ id, data, editHandler, removeHandler }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: isDragging ? 1000 : 'auto',
        boxShadow: isDragging ? '0 4px 10px rgba(0, 0, 0, 0.3)' : 'none',
        touchAction: 'none'
    };

    return (
        <div
            ref={setNodeRef}
            className="question-card card"
            style={style}
        >
            <span {...listeners} style={{ cursor: 'grab' }} className="question-card__handle ui-sortable-handle">
                <span className="ico">
                    <DotsIcon />
                </span>
            </span>
            <div className="card__body">
                <p className="text--sm w-full">{data?.rule}</p>
                <p className="text--sm color-secondary">{data?.description}</p>
            </div>
            <div className="card__actions">
                <button
                    type='button'
                    onClick={() => {
                        editHandler(data.id)
                    }}
                    className="btn btn--arrow btn--shadow btn--xs radius-5">
                    <span className="info">Edit</span>
                    <div className="btn__arrow">
                        <span className="ico">
                            <svg width="12" height="12" viewBox="0 0 12 12"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_242_5648)">
                                    <path
                                        d="M10.0967 4.35656C10.0683 4.28804 10.0203 4.22946 9.95862 4.18824C9.89696 4.14702 9.82446 4.12502 9.7503 4.125H2.2503C2.17608 4.12494 2.10352 4.1469 2.0418 4.1881C1.98008 4.22931 1.93197 4.28789 1.90356 4.35645C1.87515 4.42501 1.86773 4.50046 1.88222 4.57324C1.89672 4.64602 1.93248 4.71287 1.98498 4.76531L5.73498 8.51531C5.76981 8.55018 5.81117 8.57784 5.85669 8.59671C5.90222 8.61558 5.95101 8.62529 6.0003 8.62529C6.04958 8.62529 6.09837 8.61558 6.1439 8.59671C6.18942 8.57784 6.23078 8.55018 6.26561 8.51531L10.0156 4.76531C10.068 4.71284 10.1037 4.646 10.1182 4.57324C10.1326 4.50048 10.1251 4.42508 10.0967 4.35656Z"
                                        fill="#292929"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_242_5648">
                                        <rect width="12" height="12"
                                            fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                    </div>
                </button>
            </div>
            <div {...attributes} style={{ display: 'none' }} />
        </div>
    );
};

const SortRules = ({items, setItems, editHandler ,removeHandler}) => {
    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {

            const oldIndex = items.findIndex(item => item.order === parseInt(active.id));
            const newIndex = items.findIndex(item => item.order === parseInt(over.id));
            const updatedAnswers = arrayMove(items, oldIndex, newIndex).map((item, index) => ({
                ...item,
                order: index,
            }));
            setItems([...updatedAnswers]);
        }
    };

    return (
        <div className="questions-flow mb-[16px]">
            <DndContext onDragEnd={handleDragEnd}>
                <SortableContext items={items.map(item => item.order.toString())}>
                    {items.map((item) => (
                        <DraggableItem key={item.order} id={item.order.toString()} data={item} editHandler={editHandler} removeHandler={removeHandler} />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
}

export default SortRules
