import { toast } from "react-toastify";
import api from "../../api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllFlashCardSetsFetch = createAsyncThunk(
    '/getAllFlashCardSetFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.getAllFlashCardSets(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getFlashCardSetByIdFetch = createAsyncThunk(
    '/getFlashCardSetByIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.getFlashCardSetById(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const orderingFlashCardsFetch = createAsyncThunk(
    '/orderingFlashCardsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.orderingFlashCards(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);

export const createFlashCardSetFetch = createAsyncThunk(
    '/createFlashCardSetFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.createFlashCardSet(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);

export const editFlashCardSetByIdFetch = createAsyncThunk(
    '/editFlashCardSetByIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.editFlashCardSetById(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);

export const editFlashCardSetSettingsByIdFetch = createAsyncThunk(
    '/editFlashCardSetSettingsByIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.editFlashCardSetSettingsById(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);

export const deleteFlashCardSetFetch = createAsyncThunk(
    '/deleteFlashCardSetFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.deleteFlashCardSet(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getAllFlashCardsBySetIdFetch = createAsyncThunk(
    '/getAllFlashCardsBySetIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.getAllFlashCardsBySetId(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);

export const createFlashCardBySetIdFetch = createAsyncThunk(
    '/createFlashCardBySetIdFetch/',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.createFlashCardBySetId({ id, body });
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);

export const editFlashCardByIdFetch = createAsyncThunk(
    '/editFlashCardByIdFetch/',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.editFlashCardById({ id, body });
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);

export const deleteFlashCardByIdFetch = createAsyncThunk(
    '/deleteFlashCardByIdFetch/',
    async (id, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.deleteFlashCardById(id);
            if (res.success) {
                return {res, id};
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);

export const toggleLikeFlashcardFetch = createAsyncThunk(
    '/toggleLikeFlashcardFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.toggleLikeFlashcard(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const toggleFavoriteFlashcardFetch = createAsyncThunk(
    '/toggleFavoriteFlashcardFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.toggleFavoriteFlashcard(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const toggleFavoriteFlashcardCardByIdFetch = createAsyncThunk(
    '/toggleFavoriteFlashcardCardByIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.toggleFavoriteFlashcardCardById(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getFavoriteFlashCardsFetch = createAsyncThunk(
    '/getFavoriteFlashCardsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.getFavoriteFlashCards(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const viewFCSetBySetIdFetch = createAsyncThunk(
    '/viewFCSetBySetIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.viewFCSetBySetId(payload);
            if (res.success) {
                return res.data;
            }
            // toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const addFlashcardCardStatsByCardIdFetch = createAsyncThunk(
    '/addFlashcardCardStatsByCardId/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.addFlashcardCardStatsByCardId(payload);
            if (res.success) {
                return res.data;
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);
export const сlearFCSetStatisticsBySetIdFetch = createAsyncThunk(
    '/сlearFCSetStatisticsBySetIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.flashcards.сlearFCSetStatisticsBySetId(payload);
            if (res.success) {
                return { id: payload ,res};
            }
            toast.error(res.message);
        } catch (err) {
            
            toast.error("Something went wrong");
            return rejectWithValue(err?.response.data);
        }
    },
);