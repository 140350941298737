import React from 'react';
import { ReactComponent as HeartIcon } from "../../../assets/img/ico-heart.svg";
import Actions from './Actions';
import { useDispatch } from 'react-redux';
import { setShowLoginModal } from '../../../store/auth/authSlice';
import { ReactComponent as PlaceholderIcon } from "../../../assets/img/user-placeholder.svg"
import { Link } from 'react-router-dom';
import { getDateToString, getImageSrc } from '../../../helpers/utils';
import { favoriteCourseByCourseIdFeth, likeCourseByCourseIdFeth } from '../../../store/courses/coursesThunk';

const CardCourse = ({ data, isList, authorized }) => {
    const dispatch = useDispatch();
    const toggleLike = () => {
        if (authorized) {
            dispatch(likeCourseByCourseIdFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true))
        }


    }
    const toggleFavorite = () => {
        if (authorized) {
            dispatch(favoriteCourseByCourseIdFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true))
        }
    }
    const progress = ((data?.statistics?.completed / data?.statistics?.total) * 100).toFixed()
    return (
        <>
            {
                !isList ?
                    <div className="card card-post">
                        <div className="card-header post-image wrapp-image-sm">
                            <Link
                                to={`${authorized ? `/courses/${data?.id}/` : '#'}`}
                                className="flex w-full"
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }}
                            >
                                {data?.cover && <img style={{ width: '100%' }} src={getImageSrc(data?.cover)} alt={data?.title} />}
                                {!data?.isCompleted && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}
                            </Link>
                            <Actions handleLikeProps={toggleLike} handleAddToFavoritesProps={toggleFavorite} isFavoriteProps={data?.isFavorite} isLikeProps={data?.isLiked} />
                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <div className="group-between">
                                    {data?.user ? <Link to={`/profile/${data?.user?.id}`} className="user-info user-info--xs">
                                        <div className="ico user-ico  image-wrapper rounded-full">
                                            {
                                                data?.user?.photo ?
                                                    <img src={getImageSrc(data?.user?.photo)} />
                                                    : <PlaceholderIcon />
                                            }
                                        </div>
                                        <p className="user-name">{(data?.user?.firstName || '') + ' ' + (data?.user?.lastName || '')}</p>
                                    </Link> : null}
                                    <div className="like-checker">
                                        <span className="ico ico-like-check">
                                            <HeartIcon />
                                        </span>
                                        <span className="check-total">{data?.likes}</span>
                                    </div>
                                </div>
                                <Link
                                    to={`${authorized ? `/courses/${data?.id}/` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}
                                >
                                    <h5 className="heading post-title color-default">{data?.name}</h5>
                                </Link>
                                {data?.statistics && <div className="progress">
                                    <p className="progress-text">
                                        {progress} %
                                    </p>
                                    <div className="progress-bar">
                                        <span className="progress-line" style={{ width: progress + "%" }}></span>
                                    </div>
                                </div>}
                                <div className="group-between">
                                    <div className="group-between--item">
                                        <div className="pill-custom">
                                            <span className="pill-text">{data?.filter?.name}</span>
                                        </div>
                                        <p className="mini-post--desk">{getDateToString(data?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="card card-post --card-row card-post--md">
                        <div className="card-header post-image wrapp-image-sm">
                            <Link
                                to={`${authorized ? `/courses/${data?.id}/` : '#'}`}
                                className="flex w-full"
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }}
                            >
                                {data?.cover && <img style={{ width: '100%' }} src={getImageSrc(data?.cover)} alt={data?.title} />}
                                {!data?.isCompleted && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}
                            </Link>
                            <Actions handleLikeProps={toggleLike} handleAddToFavoritesProps={toggleFavorite} isFavoriteProps={data?.isFavorite} isLikeProps={data?.isLiked} />

                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <Link
                                    to={`${authorized ? `/courses/${data?.id}/` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}
                                >
                                    <h4 className="heading post-title color-default">{data?.name}</h4>
                                </Link>
                                <div className="group-between flex-wrap">
                                    <div className="group-between--item">
                                        <div className="pill-custom">
                                            <span className="pill-text">{data?.filter?.name}</span>
                                        </div>
                                        <p className="mini-post--desk">{getDateToString(data?.createdAt)}</p>
                                        {data?.user ? <Link to={`/profile/${data?.user?.id}`} className="user-info user-info--xs">
                                            <div className="ico user-ico  image-wrapper rounded-full">
                                                {
                                                    data?.user?.photo ?
                                                        <img src={getImageSrc(data?.user?.photo)} />
                                                        : <PlaceholderIcon />
                                                }
                                            </div>
                                            <p className="user-name">{(data?.user?.firstName || '') + ' ' + (data?.user?.lastName || '')}</p>
                                        </Link> : null}
                                        <div className="like-checker">
                                            <span className="ico ico-like-check">
                                                <HeartIcon />
                                            </span>
                                            <span className="check-total">{data?.likes}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body--item max-md:hidden">
                                <p className="post--desk">{data?.description}</p>
                            </div>
                            {data?.statistics && <div className="progress">
                                <p className="progress-text">
                                    {progress} %
                                </p>
                                <div className="progress-bar">
                                    <span className="progress-line" style={{ width: progress + "%" }}></span>
                                </div>
                            </div>}
                        </div>
                    </div>
            }
        </>
    )
}

export default CardCourse