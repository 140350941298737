import { NavLink, Outlet, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import CreateSidebar from "../components/parts/createPagesParts/CreateSidebar";
import {ReactComponent as GeneralIcon} from '../assets/img/sidebar-general.svg'
import {ReactComponent as SettingIcon} from '../assets/img/sidebar-settings.svg'
import {ReactComponent as FlashcardIcon} from '../assets/img/sidebar-flashcard.svg'
import { useDispatch } from "react-redux";
import { getFlashCardId, getFlashCardSet } from "../store/flashcards/flashcardsSlice";
import { useSelector } from "react-redux";
import { getFlashCardSetByIdFetch } from "../store/flashcards/flashcardsThunks";

const FlashcardEditLayout = () => {
    const dispach = useDispatch();
    const {id} = useParams()
    const flashCardId = id;
    const flashCardSet = useSelector(getFlashCardSet)
    
    const sidebalLiks = [
        {
            icon: <GeneralIcon />,
            to: 'general',
            label: 'General Information'
        },
        {
            icon: <FlashcardIcon />,
            to: 'flashcards',
            label: 'Flashcards',
        },
        {
            icon: <SettingIcon />,
            to: 'settings',
            label: 'Settings',
        },

    ]
    useEffect(() => {
        if (id) {
            dispach(getFlashCardSetByIdFetch({ id: id })).unwrap();
        }
    }, [id])
    return (
        <div className={'quiz-page'}  >
            <CreateSidebar links={sidebalLiks} title={"Create Flashcards"} backRoute={'/quiz/flashcards'} />
            <Outlet context={[flashCardId, flashCardSet]} />
            <div className="custom-overlay toggler-menu" style={{ zIndex: 51 }}></div>
        </div>
    )
}

export default FlashcardEditLayout
