import React, { useEffect, useState } from 'react'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'
import SwitchViewType from '../../components/parts/SwitchViewType'
import CardMaterials from '../../components/parts/Cards/CardMaterials'
import { filterOptions } from '../../helpers/constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getFilterDiagnosis, getFilteres } from '../../store/filters/filtersSlice'
import { getFiltersByName, getFiltersDiagnosisFeth } from '../../store/filters/filtersThukns'
import { useSelector } from 'react-redux'
import { getAllMaterialLibraryFeth } from '../../store/materials/materialLibraryThunks'
import PageLoader from '../../components/parts/PageLoader'
import CardCase from '../../components/parts/Cards/CardCase'
import { getAllCassesFeth } from '../../store/cases/casesThunk'
import { Favoritelinks } from './Favorites'
import { useMemo } from 'react'

const FavoritesCases = () => {
    const { authorized, showLoginModal } = useSelector((state) => state.auth)
    const { casesAll, isLoading, pagination } = useSelector(state => state.cases)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const systems = useSelector(getFilteres);
    const diagnosisFilters = useSelector(getFilterDiagnosis);
    const [isList, setIsList] = useState(false)



    const formatOptionsQuery = (value) => {
        return value?.length
            ? value.split(',').filter(Boolean) || []
            : []
    }

    const params = useMemo(() => ({
        sort: searchParams.get('sort') || 'latest',
        system: formatOptionsQuery(searchParams.get('system')) || [],
        diagnosis: formatOptionsQuery(searchParams.get('diagnosis')) || []
    }), [searchParams])

    const filters = [
        {
            title: "Filter by case status",
            type: 'diagnosis',
            checkboxOptions: diagnosisFilters
        },
        {
            title: "Filter by categories",
            type: 'system',
            checkboxOptions: systems
        },
    ]

    const handleQueryChange = (name, value) => {
        setSearchParams(prev => {
            if (value) {
                prev.set(name, value)
            } else if (prev.get(name)) {
                prev.delete(name)
            }
            return prev
        })
    }

    const handleFilterChange = (options, type) => {

        const value = params[type].includes(options.name)
            ? params[type].filter((cat) => cat !== options.name).join(',')
            : [...params[type], options.name].join(',')

        handleQueryChange(type, value)
      
    }

    const handleSortChange = (option) => {
        handleQueryChange("sort", option.value)
    }
    useEffect(() => {
        const fetchData = async () => {
            const res = await dispatch(getAllCassesFeth(`favorite=true&${searchParams.toString()}`)).unwrap()
            if (res) {
                window.scrollTo(0, 0)
            }
        }
        fetchData()
    }, [searchParams])

    useEffect(() => {
        dispatch(getFiltersByName('system'))
        dispatch(getFiltersDiagnosisFeth())
    }, [dispatch])

    return (
        <main className="content">
            <div className="container">
                <section className="section-filters">
                    <SidebarFilters filters={filters} links={Favoritelinks} defaultCheked={[...params?.system, ...params?.diagnosis]} onChange={handleFilterChange} />
                    <div className="section-filters__body">
                        <div className="page-heading mb-[30px]">
                            <div className="page-heading__item">
                                <h3 className="mr-[24px]">Favorites</h3>
                                <SwitchViewType onChange={(checked) => setIsList(checked)} />
                            </div>
                            <div className="page-heading__item">
                                <SelectGroup
                                    wrapperClasses={'input--sm'}
                                    options={filterOptions}
                                    defaultValue={filterOptions[0]}
                                    onChange={handleSortChange}
                                />
                            </div>
                        </div>
                        <div className="row gutters-cards flex-auto">
                            {
                                casesAll && casesAll.length ?
                                    casesAll.map(card => (
                                        <div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"} >
                                            <CardCase data={card} isList={isList} authorized={authorized} />
                                        </div>
                                    ))
                                    : isLoading ?
                                        <PageLoader /> :
                                        <h2>Page is empty</h2>
                            }
                        </div>
                        <Pagination {...pagination} params={params} />
                    </div>
                </section>
            </div >
        </main >
    )
}

export default FavoritesCases
