import React from 'react'
import { getMe } from '../../store/auth/authSlice'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const SubscriptionBigPart = () => {
    const me = useSelector(getMe)

    if(me?.subscription?.name === 'Premium Plan' ) return null

    return (
        <>
           <div className="row gutters-cards">
                <div className="col-12">
                    <div className="banner" style={{
                        background: "var(--bunner-bg-1)"
                    }}>
                        <div className="banner-row">
                            <div className="banner-content">
                                <div className="heading-block">
                                    <h3 className="heading">
                                        Unlock Limitless Possibilities with
                                        <span className="font-[800]">Medwise Premium</span>
                                    </h3>
                                    <p className="heading-desk">
                                        Upgrade to our premium subscription for
                                        exclusive features, enhanced benefits, and an ad-free experience.
                                    </p>
                                </div>
                                <div className="group group--sm">
                                    <Link to={'/pricing'} className="btn btn--gradient-secondary btn--md rounded-full">
                                        <span className="text-[14px] font-[500]">
                                            Upgrade to Premium
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="banner-img banner-img--shadow">
                                <img src={require("../../assets/img/banner-img-1.png")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}

export default SubscriptionBigPart
