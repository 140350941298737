import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getUserNotificationsFeth = createAsyncThunk(
    '/getUserNotificationsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.notifications.getUserNotifications(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getNotificationsPageContentFeth = createAsyncThunk(
    '/getNotificationsPageContentFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.notifications.getNotificationsPageContent(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getNewNotificationsCountFeth = createAsyncThunk(
    '/getNewNotificationsCountFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.notifications.getNewNotificationsCount(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const notificationsMarkAsReadAllFeth = createAsyncThunk(
    '/notificationsMarkAsReadAllFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.notifications.notificationsMarkAsReadAll(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            // toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const notificationsMarkAsReadOneFeth = createAsyncThunk(
    '/notificationsMarkAsReadOneFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.notifications.notificationsMarkAsReadOne(payload);
            if (res.success) {
                // toast.success(res.message)
                return {res, id: payload};
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);


