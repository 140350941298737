import { createSlice } from '@reduxjs/toolkit';
import { getUserFetch } from './usersThunks';

const initialState = {
    users: [],
    user: null,
    isLoading: false,
}


const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},

    extraReducers(builder) {
        builder
            .addCase(getUserFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getUserFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
            })
            .addCase(getUserFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export const {
} = usersSlice.actions;

export default usersSlice.reducer