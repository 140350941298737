import React from 'react'
import QuizSteps from '../../../components/parts/QuizSteps';
import { QuizeStepsQuestions } from '../../../components/parts/QuizeStepsQuestions';
import { useSelector } from 'react-redux';
import { сlearQuizResultsbyQuizIdFeth } from '../../../store/quizzes/quizzesThunks';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const QuizPageResult = () => {
    const {id} = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { quiz, questions, quizeResults, isLoading } = useSelector((state) => state.quizzes);

    const restartQuize = async () => {
        const res = await dispatch(сlearQuizResultsbyQuizIdFeth(id)).unwrap()
        if(res) {
            navigate(`/quiz-page/${id}/quiz`)
        }
    }
    
    return (
        <main className="content">
            <div className="quiz">
                <QuizeStepsQuestions data={questions} activeIndex={quizeResults?.completedQuestions} />
                <div className="quiz__content">
                    <div className="quiz__body pt-[60px] pb-[60px]">
                        <div className="container">
                            <ul className="quiz-results">
                                {questions && questions.map(item => (
                                    <li key={item.id} className="quiz-result">
                                        <div className="quiz-result--item">
                                            <p className="quiz-question counter-quiz">
                                                {item?.question_text}
                                            </p>
                                        </div>
                                        <div className="quiz-result--item">
                                            <div className={`quiz-answer  ${item?.userAnswer?.correct_answer ? '--quiz-success' : '--quiz-danger'} `}>
                                                <p className="quiz-answer--text">{item?.userAnswer?.answer_text}</p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {/* <li className="quiz-result">
                                    <div className="quiz-result--item">
                                        <p className="quiz-question counter-quiz">
                                            Where does the patient specifically
                                            experience the chronic lower leg pain?
                                        </p>
                                    </div>
                                    <div className="quiz-result--item">
                                        <div className="quiz-answer --quiz-success">
                                            <p className="quiz-answer--text">
                                                Between the knee and ankle
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li className="quiz-result">
                                    <div className="quiz-result--item">
                                        <p className="quiz-question counter-quiz">
                                            What exacerbates the patient's chronic
                                            lower leg pain, according to the description?
                                        </p>
                                    </div>
                                    <div className="quiz-result--item">
                                        <div className="quiz-answer --quiz-danger">
                                            <p className="quiz-answer--text">
                                                Medication
                                            </p>
                                        </div>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="quiz__footer">
                        <div className="container">
                            {!quiz?.quizSetting?.one_attempt && <button type='button' onClick={restartQuize} className="btn btn--shadow gap-[10px] btn--md radius-5 ml-auto">
                                <span className="ico">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.864 4.25244V7.49377H14.6235H17.864" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.4413 7.49359C16.1973 5.21384 13.7792 3.6665 10.9998 3.6665C6.95 3.6665 3.6665 6.95 3.6665 10.9998C3.6665 15.0497 6.95 18.3332 10.9998 18.3332C15.0497 18.3332 18.3332 15.0497 18.3332 10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span className="info text-[13px] font-[500] flex">Restart Quiz</span>
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default QuizPageResult