import { useEffect, useState } from "react";
import SelectGroup from "../UI/formElements/SelectGroup";
import ModalTemplate from "./ModalTemplate";
import { useSelector } from "react-redux";
import { getFilteres } from "../../store/filters/filtersSlice";
import UploadImage from "../UI/UploadImage";
import TextEditorCourse from "../parts/TextEditorCourse";
import { getImageSrc } from "../../helpers/utils";
import { json, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createThreadFeth, updateThreadByThreadIdFeth } from "../../store/forums/forumsThunk";
import { toast } from "react-toastify";
import ButtonLoader from "../UI/ButtonLoader";
import { getFiltersByName } from "../../store/filters/filtersThukns";
import SelectGroupRow from "../UI/formElements/SelectGroupRow";

export const AddThreadModal = ({ open, handleClose, title, isEdit, forumName, removeHandler }) => {
    const { id, threadId } = useParams()
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('text')
    const { isTreadLoading, threadOne, forumOne } = useSelector(state => state.forums)
    const systems = useSelector(getFilteres);
    const categotyes = systems ? systems?.map(item => ({ value: item?.id, label: item?.name })) : null;
    const [threadData, setThreadData] = useState({
        cover: null,
        type: 'text',
        title: '',
        filterId: null,
        content: '',
        link: '',
        media: []
    })
   
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
    const videoExtensions = /\.(mp4|webm|ogg|mov|avi|wmv|flv|mkv)$/i;
    useEffect(() => {
        if (threadOne) {
            setThreadData({
                cover: threadOne.cover,
                type: threadOne.type,
                title: threadOne.title,
                filterId: threadOne.filter.name,
                content: threadOne.content ? threadOne.content : '',
                link: threadOne.link ? threadOne.link : '',
                media: threadOne.media ? threadOne.media.map(item => ({
                    id: item.id,
                    preview: getImageSrc(item.fileName),
                    type: `${imageExtensions.test(item.fileName) ? 'image' : 'video'}/${item.fileName.split('.').pop()}`
                })) : []
            })
            setActiveTab(threadOne.type)
        } else {
            clearState()
        }
    }, [threadOne])
    useEffect(() => {
        if (forumOne && !threadOne?.type) {
            const curentSetting = forumOne?.settings?.threadsSetup;
            const getTypeFromSetting = curentSetting === 'links' ? 'link' : 'text'
            setActiveTab(getTypeFromSetting)
            setThreadData(prev => ({
                ...prev,
                type: getTypeFromSetting
            }))

        }
    }, [forumOne, threadOne])
    const changeActiveTab = (e) => {
        const type = e.currentTarget.id
        setActiveTab(type)
        setThreadData(prev => ({
            ...prev,
            type: type
        }))
    }
    const inputHandler = (name, value) => {
        setThreadData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    // const removeHandler = async () => {

    //     // const res = await dispatch(deleteRuleByRuleIdFeth(id)).unwrap();
    //     // if (res) {

    //     // }
    // }
    const clearState = () => {
        setThreadData({
            cover: null,
            type: 'text',
            title: '',
            filterId: null,
            content: '',
            link: '',
            media: []
        })
    }

    const submitHandler = async (e, createAnother) => {
        e.preventDefault()
        if (threadData.title.trim().length === 0 && !threadData.filterId) {
            toast.error('Title & Category is required')
            return
        }
        const mediaFilses = [...threadData.media]
        const currentCategory = categotyes.find(cat => cat.label === threadData.filterId)
        let res = null
       
        const newMedia = mediaFilses.filter(media => !media.id)
        const odlMedia = mediaFilses.filter(media => media.id).map(media => media.id)
        const formData = new FormData()
        formData.append('cover', threadData.cover)
        formData.append('type', threadData.type)
        formData.append('title', threadData.title)
        formData.append('filterId', currentCategory.value)
        console.log(threadData.type)
        if (threadData.type === 'text') {
            formData.append('content', threadData.content)
        }
        if (threadData.type === 'link') {
            formData.append('link', threadData.link)
        }
        if (threadData.type === 'media') {
            if (newMedia.length > 0) {
                newMedia.forEach(media => {
                    formData.append('media', media)
                })
            }
            if (odlMedia.length > 0) {
                formData.append('media_data', JSON.stringify(odlMedia))
            }
        }

        if (threadId) {
            res = await dispatch(updateThreadByThreadIdFeth({ id: threadId, body: formData })).unwrap()
        } else {
            res = await dispatch(createThreadFeth({ id: id, body: formData })).unwrap()
        }
        if (res) {
            if (createAnother) {
                clearState()
            } else {
                handleClose()
            }
        }
    }

    useEffect(() => {
        dispatch(getFiltersByName('thread'))
    }, [dispatch])
    return (
        <ModalTemplate open={open} handelClose={handleClose} modalTheme="thread-modal" modalSize={'modal__dialog--870'} lightGreyBg={false} >
            <>
                <div className="modal__body pt-0">
                    <div className="modal__body-heading gap--xxs">
                        <h2 className="text--xxl">{title}</h2>
                        <p className="color-secondary text--md ff-2">for <span className="font-semibold color-default">{forumName}</span> forum
                        </p>
                    </div>

                    <div className="tabs gap-[32px]">
                        <div className="tabs-header">
                            <ul className="tabs-nav flex-wrap">
                                {forumOne?.settings?.threadsSetup !== 'links' && <li className="tabs-nav--item max-w-max">
                                    <button onClick={changeActiveTab} id={'text'} data-href="#text-thread" className={`btn btn-tablink btn-tablink-row 
                                        btn--md rounded-full bg-transparent px-[10px] ${activeTab === 'text' ? 'active-tab' : ''}`}>
                                        <span className="ico">
                                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M9.77719 6.91476H6.8732C6.61996 6.91476 6.41467 7.12005 6.41467 7.37329V9.66591C6.41467 9.91915 6.61996 10.1244 6.8732 10.1244H9.77719C10.0304 10.1244 10.2357 9.91915 10.2357 9.66591V7.37329C10.2357 7.12005 10.0304 6.91476 9.77719 6.91476Z"
                                                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                                <path d="M12.834 6.91476H15.5851" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.834 9.97159H15.5851" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M6.41467 13.0284H15.5852" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M6.41467 16.0852H10.2357" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <rect x="2.74646" y="3.24658" width="16.5069" height="16.5069" rx="4"
                                                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                    strokeLinejoin="round"></rect>
                                            </svg>
                                        </span>
                                        <span className="text--sm font-medium">Text Thread</span>
                                    </button>
                                </li>}
                                {forumOne?.settings?.threadsSetup === "any" &&
                                    <>
                                        <li className="flex items-center">OR</li>
                                        <li className="tabs-nav--item max-w-max">
                                            <button onClick={changeActiveTab} id={'media'} data-href="#image-videos" className={`btn btn-tablink btn-tablink-row
                                        btn--md rounded-full bg-transparent px-[10px] ${activeTab === 'media' ? 'active-tab' : ''}`}>
                                                <span className="ico">
                                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M11.4315 11.3207L11.4318 11.3203C11.4993 11.2507 11.4976 11.1395 11.428 11.072C11.3584 11.0044 11.2472 11.0061 11.1796 11.0756C11.112 11.1452 11.1136 11.2564 11.1831 11.324C11.2526 11.3917 11.3638 11.3902 11.4315 11.3207"
                                                            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M2.75 12.6917C3.04494 12.6463 3.3428 12.6224 3.6412 12.6204V12.6204C5.29574 12.6204 6.8825 13.2776 8.05243 14.4476C9.22237 15.6175 9.87962 17.2043 9.87962 18.8588C9.87688 19.1572 9.85306 19.455 9.80833 19.75"
                                                            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <rect x="2.75" y="6.91669" width="12.8333" height="12.8333" rx="3"
                                                            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                            strokeLinejoin="round"></rect>
                                                        <path
                                                            d="M5.5 6.91667V6C5.5 4.48122 6.73122 3.25 8.25 3.25H16.5C18.0188 3.25 19.25 4.48122 19.25 6V14.25C19.25 15.7688 18.0188 17 16.5 17H15.5833"
                                                            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                                <span className="text--sm font-medium">Image &amp; Videos</span>
                                            </button>
                                        </li>
                                    </>
                                }
                                {forumOne?.settings?.threadsSetup !== 'texts' &&
                                    <>
                                        {forumOne?.settings?.threadsSetup !== 'links' &&
                                            <li className="flex items-center">OR</li>
                                        }
                                        <li className="tabs-nav--item max-w-max">
                                            <button onClick={changeActiveTab} id={'link'} data-href="#link" className={`btn btn-tablink btn-tablink-row
                                            btn--md rounded-full bg-transparent px-[10px] ${activeTab === 'link' ? 'active-tab' : ''}`}>
                                                <span className="ico">
                                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M11.9302 17.0135L10.5282 18.4155C8.7488 20.1948 5.86389 20.1948 4.08452 18.4155V18.4155C2.30516 16.6361 2.30516 13.7512 4.08452 11.9718L5.91227 10.1441C6.77181 9.28455 7.9376 8.80167 9.15318 8.80167C10.3688 8.80167 11.5345 9.28455 12.3941 10.1441"
                                                            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                        <path
                                                            d="M10.0699 5.98651L11.4719 4.58452C13.2512 2.80516 16.1361 2.80516 17.9155 4.58452V4.58452C19.6949 6.36389 19.6949 9.2488 17.9155 11.0282L16.0878 12.8559C14.2979 14.6458 11.3959 14.6458 9.60596 12.8559"
                                                            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                                <span className="text--sm font-medium">Link</span>
                                            </button>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>
                        <div className="tabs-body">
                            <div className={`tabs-item gap-[16px] active-tab`} id="text-thread">
                                <div>
                                    <div className="row-group gap-[24px] items-stretch mb-[24px]">
                                        <label className="upload-form w-full max-w-[145px]">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={({ target }) => inputHandler('cover', target.files[0])} className="hidden"
                                            />
                                            {threadData.cover ?
                                                <img src={getImageSrc(threadData.cover)} alt="" />
                                                :
                                                <div className="upload-form-content justify-center">
                                                    <div className="btn btn--primary btn--xs gap--xs radius-5">
                                                        <span className="ico">
                                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M6.66729 14.6667H6.33329C4.03211 14.6667 2.16663 12.8012 2.16663 10.5C2.17034 8.42154 3.70968 6.66574 5.76978 6.39015C6.45829 4.36409 8.36011 3.00104 10.5 3C13.2614 3 15.5 5.23858 15.5 8C17.3409 8 18.8333 9.49238 18.8333 11.3333C18.8333 13.1743 17.3409 14.6667 15.5 14.6667H14.3326"
                                                                    stroke="currentColor" strokeWidth="1.5"
                                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                                <path d="M12.1667 15.5L10.5 13.8333L8.83337 15.5"
                                                                    stroke="currentColor" strokeWidth="1.5"
                                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                                <path d="M10.5 18V13.8333" stroke="currentColor"
                                                                    strokeWidth="1.5" strokeLinecap="round"
                                                                    strokeLinejoin="round"></path>
                                                            </svg>
                                                        </span>
                                                        <span className="text--xs font-medium">Cover</span>
                                                    </div>
                                                </div>}
                                        </label>
                                        <div className="col-group gap--xs flex-auto">
                                            <div className="input-wrapper input--md mb-[12px]">
                                                <input
                                                    className="input input--outline"
                                                    type="text"
                                                    value={threadData.title}
                                                    placeholder="Enter thread title..."
                                                    onChange={({ target }) => inputHandler('title', target.value)}
                                                />
                                            </div>
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                value={categotyes?.find(cat => cat.label === threadData.filterId)}
                                                options={categotyes}
                                                placeholder={"Select Category"}
                                                onChange={(option) => inputHandler('filterId', option.label)}
                                            />
                                        </div>
                                    </div>
                                    {activeTab === 'text' && <div className="editor-theme-gray"><TextEditorCourse editorData={threadData.content} setData={(value) => inputHandler('content', value)} /></div>}
                                    {activeTab === 'media' && <UploadImage
                                        onChange={(image) => {
                                            inputHandler('media', image)
                                        }}
                                        defaulImages={threadData.media}
                                        isRow={true}
                                        type="media"
                                        multiple={true}
                                    />}
                                    {activeTab === 'link' && <div className="input-wrapper">
                                        <input
                                            className="input input--outline p-[16px]" placeholder="Enter your link..."
                                            onChange={({ target }) => {
                                                inputHandler('link', target.value)
                                            }}
                                        />
                                    </div>}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal__footer">
                    {threadId ?
                        <button
                            type='button'
                            disabled={isTreadLoading}
                            onClick={ () => {
                                 removeHandler()
                                 handleClose()
                            }}
                            className="btn btn--danger btn--md radius-5">
                            {
                                isTreadLoading
                                    ? <ButtonLoader /> :
                                    'Delete'
                            }
                        </button>
                        :
                        <button
                            type='button'
                            onClick={handleClose}
                            className="btn btn--shadow btn--md radius-5"
                            disabled={isTreadLoading}
                        >
                            {
                                isTreadLoading
                                    ? <ButtonLoader /> :
                                    'Cancel'
                            }
                        </button>
                    }
                    <button
                        className="btn btn--primary btn--md radius-5 ml-auto"
                        onClick={submitHandler}
                        disabled={isTreadLoading}
                    >
                        {
                            isTreadLoading
                                ? <ButtonLoader /> :
                                'Save'
                        }
                    </button>
                    {!isEdit && <button
                        className="btn btn--primary btn--md radius-5"
                        onClick={(e) => submitHandler(e, true)}
                        disabled={isTreadLoading}
                    >
                        {
                            isTreadLoading
                                ? <ButtonLoader /> :
                                'Save and Add Another'
                        }

                    </button>}
                </div>
            </>
        </ModalTemplate>
    )
}