import React from 'react'
import { Link } from 'react-router-dom'

const SidebarHelpLinks = ({ links, title, footerText }) => {
    
    
    return (
        <div>
            <div className="card card-custom py-[14px] px-[18px] rounded-[6px]">
                <div className="card-header mb-[12px]">
                    <h5 className="text-[12px] uppercase color-secondary-2">
                        {title}
                    </h5>
                </div>
                <div className="card-body">
                    <ul className="flex flex-col gap-[10px]">
                        {links && links.map((item, index) => (
                            <li key={index}>
                                <Link to={item.url} className="btn before-point text-[13px]  before-point--secondary btn-keyword text-left justify-start">
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                {footerText && <div className="card-footer mt-[12px]">
                    <p className="desk-txt font-2">
                        {footerText}
                    </p>
                </div>}
            </div>
        </div>
    )
}

export default SidebarHelpLinks
