import { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import Loader from '../Loader';


const SelectGroup = ({
    isSearchable = true,
    defaultValue,
    loadOptions,
    options,
    wrapperClasses = '',
    onChange,
    value,
    label,
    placeholder,
    error,
    errorMessage,
    onMenuScrollToBottom,
    hasMoreOptions,
    isLoading,
    ...props
}) => {
    const lastScrollPos = useRef(0);
    
    const findValue = (value) => {
        
        if (typeof value === "object") return value;

        if (Array.isArray(options)) {
            const foundOption = options.find(option => option.value === value);
            
            if (foundOption) return foundOption;
        }
        return null;
    }

    const [selectedValue, setSelectedValue] = useState(findValue(defaultValue))

    let selectComponents = {
        IndicatorSeparator: () => null,
        DropdownIndicator: (props) => {
            return (
                <components.DropdownIndicator {...props}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0967 4.35656C10.0683 4.28804 10.0203 4.22946 9.95862 4.18824C9.89696 4.14702 9.82446 4.12502 9.7503 4.125H2.2503C2.17608 4.12494 2.10352 4.1469 2.0418 4.1881C1.98008 4.22931 1.93197 4.28789 1.90356 4.35645C1.87515 4.42501 1.86773 4.50046 1.88222 4.57324C1.89672 4.64602 1.93248 4.71287 1.98498 4.76531L5.73498 8.51531C5.76981 8.55018 5.81117 8.57784 5.85669 8.59671C5.90222 8.61558 5.95101 8.62529 6.0003 8.62529C6.04958 8.62529 6.09837 8.61558 6.1439 8.59671C6.18942 8.57784 6.23078 8.55018 6.26561 8.51531L10.0156 4.76531C10.068 4.71284 10.1037 4.646 10.1182 4.57324C10.1326 4.50048 10.1251 4.42508 10.0967 4.35656Z" fill="currentColor" />
                    </svg>
                </components.DropdownIndicator>
            )
        },
        MenuList: ({ innerRef, ...props }) => {
            const menuRef = useRef(null)

            const onScroll = (e) => {
                const pos = e.target.scrollTop
                if (pos > 0)
                    lastScrollPos.current = pos
            }

            useEffect(() => {
                const el = menuRef.current
                if (!el) return;
                el.addEventListener("scroll", onScroll)
                return () => el.removeEventListener("scroll", onScroll)
            }, [])

            useEffect(() => {
                const el = menuRef.current
                if (!el) return
                el.scrollTop = lastScrollPos.current
            }, [options])

            return (
                <components.MenuList {...props} innerRef={node => {
                    innerRef(node)
                    menuRef.current = node
                }} className="menulist">
                    {props.children}
                    {
                        isLoading && (
                            <div className='flex justify-center items-center h-9 w-full'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g><circle cx="12" cy="2.5" r="1.5" fill="currentColor" opacity="0.14" /><circle cx="16.75" cy="3.77" r="1.5" fill="currentColor" opacity="0.29" /><circle cx="20.23" cy="7.25" r="1.5" fill="currentColor" opacity="0.43" /><circle cx="21.5" cy="12" r="1.5" fill="currentColor" opacity="0.57" /><circle cx="20.23" cy="16.75" r="1.5" fill="currentColor" opacity="0.71" /><circle cx="16.75" cy="20.23" r="1.5" fill="currentColor" opacity="0.86" /><circle cx="12" cy="21.5" r="1.5" fill="currentColor" /><animateTransform attributeName="transform" calcMode="discrete" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12" /></g></svg>
                            </div>
                        )
                    }
                </components.MenuList>
            )
        }

    }
    let classNames = {
        control: (e) => {
            return `select-selection ${e.menuIsOpen ? 'select-selection-focused' : ''} ${error ? "input--error" : ""}`
        },
        singleValue: (e) => {
            return e.getValue()?.value ? 'aaa has-value' : 'aaa';
        },
        valueContainer: () => 'bbb',
        menu: () => 'ccc select2-dropdown select2-dropdown--below',
        container: () => 'ddd',
        menuList: () => 'menulist',
        option: (e) => {
            return `select-list-item ${e.isSelected ? 'select-list-item__selected' : ''} ${e.isFocused ? 'select-list-item__focus' : ''}`

        },
        dropdownIndicator: () => 'select-indicator',
        placeholder: () => 'select-placeholder'
    }



    const handleChange = (value) => {
        setSelectedValue(value)
        onChange && onChange(value)
    }

    return (
        <div className={`input-wrapper ${wrapperClasses}`}>
            {label && <label className="label">{label}</label>}
            <Select
                menuPlacement="auto"
                options={options}
                loadOptions={loadOptions}
                value={selectedValue}
                classNames={classNames}
                components={{ ...selectComponents }}
                isSearchable={isSearchable}
                defaultValue={findValue(defaultValue)}
                onChange={handleChange}
                placeholder={placeholder}
                noOptionsMessage={() => isLoading ? "Loading..." : "No Options"}
                menuPosition="fixed"
                onMenuScrollToBottom={onMenuScrollToBottom}
                {...props}
            >
            </Select>
            {
                errorMessage && error ?
                    <p className='color-danger text--xs mt-1'>{errorMessage}</p>
                    :
                    null
            }
        </div>
    )
}

export default SelectGroup
