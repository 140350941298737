import { Link, useNavigate, useParams } from "react-router-dom";
import CheckBoxGroup from "../../components/UI/formElements/CheckBoxGroup";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "../../components/UI/Loader";
import { useEffect, useState } from "react";
import { createSettingsByCourseIdFeth, editSettingsByCourseIdFeth } from "../../store/courses/coursesThunk";
import { setClearCourse } from "../../store/courses/coursesSlice";
import { setHasEditChanges } from "../../store/auth/authSlice";


export const CourseCreateSettings = () => {
    const { id } = useParams()
    const conf = {
        title: 'Settings',
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { courseOne, courseId, isLoading } = useSelector(state => state.courses)
    const [settingsData, setSettingsData] = useState({
        enableComments: false,
        showRelatedLinks: false,
        displayAuthorName: false,
    })

    const formDataHandler = (name, value) => {
        dispatch(setHasEditChanges(true)) 
        setSettingsData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    useEffect(() => {
        if (courseOne?.settings) {
            setSettingsData({
                enableComments: courseOne?.settings?.enableComments,
                showRelatedLinks: courseOne?.settings?.showRelatedLinks,
                displayAuthorName: courseOne?.settings?.displayAuthorName,
            })
        }
    }, [courseOne])
    const submitHandler = async (e, createAnother = false) => {
        let res = null
        if (courseOne.settings) {
            res = await dispatch(editSettingsByCourseIdFeth({ id: courseId, body: settingsData })).unwrap()
        } else {
            res = await dispatch(createSettingsByCourseIdFeth({ id: courseId, body: settingsData })).unwrap()
        }
        if (res) {

            if (createAnother) {
                dispatch(setClearCourse())
                navigate(`/course-create/general`)
            }
            dispatch(setHasEditChanges(false)) 
        }
    }
    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--full">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="container container--lg">
                                                <div className="group gap--lg md:flex-nowrap">
                                                    <p className="text--md font-medium min-w-[140px] md:text-right">Options</p>
                                                    <div className="checkboxes-list">
                                                        <CheckBoxGroup
                                                            checked={settingsData.enableComments}
                                                            onChange={(e) => {
                                                                formDataHandler('enableComments', e.target.checked)
                                                            }}
                                                        >
                                                            <p className="check-text">
                                                                Enable comments for the course
                                                            </p>
                                                        </CheckBoxGroup>
                                                        <CheckBoxGroup
                                                            checked={settingsData.showRelatedLinks}
                                                            onChange={(e) => {
                                                                formDataHandler('showRelatedLinks', e.target.checked)
                                                            }}
                                                        >
                                                            <p className="check-text">
                                                                Show links to related materials at the end of the course
                                                            </p>
                                                        </CheckBoxGroup>
                                                        <CheckBoxGroup
                                                            checked={settingsData.displayAuthorName}
                                                            onChange={(e) => {
                                                                formDataHandler('displayAuthorName', e.target.checked)
                                                            }}
                                                        >
                                                            <p className="check-text">
                                                                Display the name of the author(s)
                                                            </p>
                                                        </CheckBoxGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container flex-wrap gap-[10px]">
                                    <Link to={'/courses'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <div className="group items-center md:flex-nowrap gap--xs">
                                        <button type="button" onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save</span>
                                        </button>
                                        <button type="button" onClick={(e) => submitHandler(e, true)} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save and Create Another</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default CourseCreateSettings