const pagesBase = 'api/materials';

export const materialLibrary = (instance) => ({
    async getAllMaterialLibrary(qwery) {
        try {
            const { data } = await instance.get(`${pagesBase}/?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getMaterialLibraryById(id) {
        try {
            const { data } = await instance.get(`${pagesBase}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createMaterialLibrary(payload) {
        try {
            const { data } = await instance.post(`${pagesBase}`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async likeMaterialLibraryById (id) {
        try {
            const { data } = await instance.post(`${pagesBase}/like/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async favoriteMaterialLibraryByid (id) {
        try {
            const { data } = await instance.post(`${pagesBase}/favorite/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async viewMaterialLibraryById (id) {
        try {
            const { data } = await instance.post(`${pagesBase}/view/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editMaterialLibraryById ({id, body}) {
        try {
            const { data } = await instance.patch(`${pagesBase}/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async delMaterialLibraryById (id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});