export const data = [
    {
        id: 2,
        img: require("./assets/img/blue-ray-img-2.png"),
        title: "Chronic Watershed Cerebral Infarction",
        category: "Diagnosis Certain",
        author: {
            avatar: require("./assets/img/user-1.png"),
            name: "Devon Lane"
        },
        likes: 158,
        count: 158,
        progress: 64,
        createdAt: "17 Oct, 2024",
        description: `
    A flipped meniscus occurs when a
    component of a meniscus avulses and
    the ruptured fragment displaces
    either anteriorly or posteriorly,
    as in this case, resulting in a
    disproportionately enlarged horn
    of a meniscus, in this case, the
    posterior horn of the lateral meniscus.`
    },
    {
        id: 3,
        img: require("./assets/img/blue-ray-img-3.png"),
        title: "Distal Radial Fracture - Salter-Harris 2",
        category: "Diagnosis Almost Certain",
        author: {
            avatar: require("./assets/img/user-1.png"),
            name: "Devon Lane"
        },
        likes: 158,
        count: 158,
        progress: 64,
        createdAt: "17 Oct, 2024",
        description: `
    A flipped meniscus occurs when a
    component of a meniscus avulses and
    the ruptured fragment displaces
    either anteriorly or posteriorly,
    as in this case, resulting in a
    disproportionately enlarged horn
    of a meniscus, in this case, the
    posterior horn of the lateral meniscus.`
    },
    {
        id: 4,
        img: require("./assets/img/blue-ray-img-1.png"),
        title: "Neurosarcoidosis of the Spinal Cord",
        category: "Diagnosis Probable",
        author: {
            avatar: require("./assets/img/user-1.png"),
            name: "Devon Lane"
        },
        likes: 158,
        count: 158,
        progress: 64,
        createdAt: "17 Oct, 2024",
        description: `
    A flipped meniscus occurs when a
    component of a meniscus avulses and
    the ruptured fragment displaces
    either anteriorly or posteriorly,
    as in this case, resulting in a
    disproportionately enlarged horn
    of a meniscus, in this case, the
    posterior horn of the lateral meniscus.`
    },
    {
        id: 5,
        img: require("./assets/img/blue-ray-img-1.png"),
        title: "Neurosarcoidosis of the Spinal Cord",
        category: "Diagnosis Probable",
        author: {
            avatar: require("./assets/img/user-1.png"),
            name: "Devon Lane"
        },
        likes: 158,
        count: 158,
        progress: 64,
        createdAt: "17 Oct, 2024",
        description: `
    A flipped meniscus occurs when a
    component of a meniscus avulses and
    the ruptured fragment displaces
    either anteriorly or posteriorly,
    as in this case, resulting in a
    disproportionately enlarged horn
    of a meniscus, in this case, the
    posterior horn of the lateral meniscus.`
    },
    {
        id: 6,
        img: require("./assets/img/blue-ray-img-2.png"),
        title: "Neurosarcoidosis of the Spinal Cord",
        category: "Diagnosis Probable",
        author: {
            avatar: require("./assets/img/user-2.png"),
            name: "Devon Lane"
        },
        likes: 158,
        count: 158,
        progress: 64,
        createdAt: "17 Oct, 2024",
        description: `
    A flipped meniscus occurs when a
    component of a meniscus avulses and
    the ruptured fragment displaces
    either anteriorly or posteriorly,
    as in this case, resulting in a
    disproportionately enlarged horn
    of a meniscus, in this case, the
    posterior horn of the lateral meniscus.`
    },
    {
        id: 7,
        img: require("./assets/img/blue-ray-img-3.png"),
        title: "Neurosarcoidosis of the Spinal Cord",
        category: "Diagnosis Probable",
        author: {
            avatar: require("./assets/img/user-2.png"),
            name: "Devon Lane"
        },
        likes: 158,
        count: 158,
        progress: 64,
        createdAt: "17 Oct, 2024",
        description: `
    A flipped meniscus occurs when a
    component of a meniscus avulses and
    the ruptured fragment displaces
    either anteriorly or posteriorly,
    as in this case, resulting in a
    disproportionately enlarged horn
    of a meniscus, in this case, the
    posterior horn of the lateral meniscus.`
    },
    {
        id: 8,
        img: require("./assets/img/blue-ray-img-1.png"),
        title: "Neurosarcoidosis of the Spinal Cord",
        category: "Diagnosis Probable",
        author: {
            avatar: require("./assets/img/user-2.png"),
            name: "Devon Lane"
        },
        likes: 158,
        count: 158,
        progress: 64,
        createdAt: "17 Oct, 2024",
        description: `
    A flipped meniscus occurs when a
    component of a meniscus avulses and
    the ruptured fragment displaces
    either anteriorly or posteriorly,
    as in this case, resulting in a
    disproportionately enlarged horn
    of a meniscus, in this case, the
    posterior horn of the lateral meniscus.`
    },
    {
        id: 9,
        img: require("./assets/img/blue-ray-img-2.png"),
        title: "Neurosarcoidosis of the Spinal Cord",
        category: "Diagnosis Probable",
        author: {
            avatar: require("./assets/img/user-2.png"),
            name: "Devon Lane"
        },
        likes: 158,
        count: 158,
        progress: 64,
        createdAt: "17 Oct, 2024",
        description: `
    A flipped meniscus occurs when a
    component of a meniscus avulses and
    the ruptured fragment displaces
    either anteriorly or posteriorly,
    as in this case, resulting in a
    disproportionately enlarged horn
    of a meniscus, in this case, the
    posterior horn of the lateral meniscus.`
    },
];