import React, { useDebugValue, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Accordeon from '../../components/UI/Accordeon'
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks'
import UpgradeToPremium from '../../components/parts/UpgradeToPremium'
import { getAllPagesFetch, getPagesByPathFetch } from '../../store/pages/pagesThunks'
import { useSelector } from 'react-redux'
import { findValue, getImageSrc } from '../../helpers/utils'
import PageLoader from '../../components/parts/PageLoader'
import { Navigate, useNavigate } from 'react-router-dom'

const FAQ = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pageData, pages , isLoading } = useSelector(state => state.pages);
    const pageKey = pages.filter(item => item.id === 1)[0]?.url.slice(1)
    useEffect(() => {
        if(pageKey) {
            dispatch(getPagesByPathFetch(pageKey))
        }
    }, [dispatch,pageKey])
    
    const getValue = (key) => findValue(pageData?.blocks, key)
    
    if (isLoading) return <PageLoader />

    return (
        <main className="content">
            <section className="page-section">
                <div className="container">
                    <div className="column-group column-group--md items-center text-center max-w-[500px] mx-auto">
                        <div className="column-group column-group--md">
                            <div className="heading-row justify-center text-center">
                                <div className="heading-item--row">
                                    <span className="ico ico-50">
                                        <img src={getImageSrc(getValue("header_icon"))} />
                                    </span>
                                    <h1 className="heading">{getValue("header_title")}</h1>
                                </div>
                            </div>
                            <p className="desk-txt-2">{getValue("header_text_description")}</p>
                        </div>
                    </div>
                    <div className="page-section--row">
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar banner-list sidebar-xs">
                                <UpgradeToPremium />
                            </aside>
                        </div>
                        <div className="page-content">
                            <div className="flex flex-col">
                                {getValue("content_items") ? getValue("content_items")?.map((item, index) => (
                                    <Accordeon key={index} title={item.title} className={'accordeon-2'}>
                                        <p dangerouslySetInnerHTML={{__html: item.content}}></p>
                                    </Accordeon>
                                ))
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    <SidebarHelpLinks
                                        title={getValue("title_right_links")}
                                        links={getValue("content_right_links")}
                                    />
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default FAQ