import React, { useEffect, useState } from 'react'
import UpgradeToPremium from '../../components/parts/UpgradeToPremium'
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks'
import InputGroup from '../../components/UI/formElements/InputGroup'
import TextareaGroup from '../../components/UI/formElements/TextareaGroup'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import { findValue, getFormData, getImageSrc } from '../../helpers/utils'
import PageLoader from '../../components/parts/PageLoader'
import { getPagesByPathFetch } from '../../store/pages/pagesThunks'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllProfessionsFetch } from '../../store/professions/professionsThunks'
import { toast } from 'react-toastify'
import { useValidation } from '../../hooks/useValidation'
import { createMessageFeth } from '../../store/contactUs/contactThunk'
import ButtonLoader from '../../components/UI/ButtonLoader'
import { InputPhoneCodeMask } from '../../components/UI/formElements/InputPhoneCodeMask'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { getMe } from '../../store/auth/authSlice'

const ContactUs = () => {
    const dispatch = useDispatch()
    const me = useSelector(getMe)
    const { pageData, isLoading, pages } = useSelector(state => state.pages)
    const  {isContactSend} =  useSelector(state => state.contactUs)
    const [formData, setFormData] = useState({
		phone: "",
		email: "",
		firstName: "",
        professionId: null,
		lastName: "",
		fullName: "",
        message: '',
	})
    const [professions, setProfessions] = useState([])
	const [isProfessionsLoading, setIsProfessionsLoading] = useState(false)
    const pageKey = pages.filter(item => item.id === 2)[0]?.url.slice(1)
    const validators = {
		phone: value => isValidPhoneNumber('+' + String(value)) ? true : 'Invalid phone number format',
		email: value => /\S+@\S+\.\S+/.test(value) ? true : 'Invalid email format',
		firstName: value => /^[^0-9]*$/.test(value) ? true : 'First name must not contain numeric characters',
		lastName: value => /^[^0-9]*$/.test(value) ? true : 'Last name must not contain numeric characters',
		fullName: value => {
			if (/\d/.test(value)) {
				return 'Full name must not contain numeric characters';
			}
			const nameParts = value.trim().split(' ');
			if (nameParts.length < 2) {
				return 'Full name must contain at least a first and last name';
			}
			return true;
		},
        message: value => {
            if(value.trim().length < 5) {
                return 'Message must be more than 5 characters';
            } 
            return true
        }
	};
    const messages = {
		// phone: 'Phone number is required',
		email: 'Email is required',
		firstName: 'First name is required',
		lastName: 'Last name is required',
		professionId: 'Profession is required',
		fullName: 'Full name is required',
		message: 'Message is required',
	};
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(formData, {
		validators,
		messages
	});
    const getProfessions = async () => {
		try {
			setIsProfessionsLoading(true)
			const data = await dispatch(getAllProfessionsFetch()).unwrap()
			setProfessions(data?.map(option => ({ label: option.name, value: option.id })) || [])
			setIsProfessionsLoading(false)
		} catch (error) {
			setIsProfessionsLoading(false)
			toast.error(error.message)
		}
	}

    const handleSubmit = async (e) => {
        
		e.preventDefault()
		if (errors) {
			setIsErrorShown(true)
			return
		}
		
		const res = dispatch(createMessageFeth(formData)).unwrap()
        
		
	}

	const handleChange = (name, value) => {
		setFormData(prev => ({
			...prev, [name]: value
		}))
		if (name === "fullName") {
			const [firstName, lastName] = value.split(" ")
			setFormData(prev => ({
				...prev, firstName: firstName?.trim(), lastName: lastName?.trim()
			}))
		}
	}

	useEffect(() => {
		getProfessions()
	}, [])
    useEffect(() => {
        if(pageKey) {
            dispatch(getPagesByPathFetch(pageKey))
        }
    }, [dispatch,pageKey])
    
    const getValue = (key) => findValue(pageData?.blocks, key)
    console.log(me)
    if (isLoading) return <PageLoader />
    return (
        <main className="content">
            <section className="page-section">
                <div className="container">
                    <div className="column-group column-group--md items-center text-center max-w-[500px] mx-auto">
                        <div className="column-group column-group--md">
                            <div className="heading-row justify-center text-center">
                                <div className="heading-item--row">
                                    <span className="ico ico-50">
                                        <img src={getImageSrc(getValue("header_icon"))} />
                                    </span>
                                    <h1 className="heading">{getValue("header_title")}</h1>
                                </div>
                            </div>
                            <p className="desk-txt-2">{getValue("header_text_description")}</p>
                        </div>
                    </div>
                    <div className="page-section--row">
                       <div className="page-sidebar --none-border">
                            <aside className="sidebar banner-list sidebar-xs">
                                <UpgradeToPremium />
                            </aside>
                        </div>
                        <div className="page-content">
                            <form className="form" onSubmit={handleSubmit}>
                                <div className="form-body">
                                    <div className="row gutters-24">
                                        <div className="col-md-6 col-12">
                                            <InputGroup
                                                label={'Full Name'}
                                                wrapperClasses=' input--lg'
                                                inputClasses='input--outline'
                                                placeholder={'Enter your full name...'}
                                                value={formData?.fullName}
												error={isErrorShown && errors?.fullName}
												errorMessage={errorMessages?.fullName}
												onChange={(e) => handleChange("fullName", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <InputGroup
                                                label={'Email'}
                                                wrapperClasses=' input--lg'
                                                inputClasses='input--outline'
                                                placeholder={'Enter your full email...'}
                                                value={formData?.email}
												error={isErrorShown && errors?.email}
												errorMessage={errorMessages?.email}
												onChange={(e) => handleChange("email", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <InputPhoneCodeMask
                                                label={'Phone Number'}
                                                wrapperClasses=' input--lg'
                                                inputClasses='input--outline'
                                                placeholder={'Enter your phone...'}
                                                value={formData?.phone}
												error={isErrorShown && errors?.phone}
                                                errorMessage={errorMessages?.phone}
												onChange={(value) => handleChange("phone",value)}
                                            />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <SelectGroup
												wrapperClasses='select--outline input--lg'
												label={'My Profession'}
												placeholder={'Select profession'}
												value={formData?.professionId}
												error={isErrorShown && errors?.professionId}
												errorMessage={errorMessages?.professionId}
												options={professions}
												isLoading={isProfessionsLoading}
												onChange={( value ) => handleChange("professionId", value)}
											/>
                                        </div>
                                        <div className="col-12">
                                            <TextareaGroup
                                                label={'Message'}
                                                wrapperClasses='input--lg'
                                                textareaClasses=' input--outline p-[16px]'
                                                placeholder={'Enter your comment'}
                                                rows='7'
                                                value={formData?.message}
												error={isErrorShown && errors?.message}
                                                errorMessage={errorMessages?.message}
												onChange={(e) => handleChange("message", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <button  type="submit" disabled={isContactSend} className="btn btn--primary btn--lg radius-5">
                                                {isContactSend ? 
                                                <ButtonLoader/>
                                                :
                                                <span>Send Message</span>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    <SidebarHelpLinks
                                        title={getValue("title_right_links")}
                                        links={getValue("content_right_links")}
                                    />
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default ContactUs