import React from 'react'
import SwithGroup from '../UI/formElements/SwithGroup'
import { useSelector } from 'react-redux'
import { setSiteTheme } from '../../store/auth/authSlice'
import { useDispatch } from 'react-redux'

const ThemSwith = ({iconsOnly = false}) => {
    const dispatch = useDispatch()
    const {siteTheme} = useSelector(state => state.auth)
    const themeHandler = ({target}) => {
        if(!target.checked) {
            dispatch(setSiteTheme('light'))
            window.localStorage.setItem('siteTheme', 'light')
        } else {
            dispatch(setSiteTheme('dark'))
            window.localStorage.setItem('siteTheme', 'dark')
        }
    }
    return (
        <SwithGroup
            onChange={themeHandler}
            defaultChecked = {siteTheme === 'dark'}
            leftNode={<>
                <span className="ico ico-18">
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.1517 6.34835C13.6161 7.81281 13.6161 10.1872 12.1517 11.6517C10.6872 13.1161 8.31281 13.1161 6.84835 11.6517C5.38388 10.1872 5.38388 7.81281 6.84835 6.34835C8.31281 4.88388 10.6872 4.88388 12.1517 6.34835"
                            stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"
                            strokeLinejoin="round"></path>
                        <path d="M9.5 3V1.5" stroke="currentColor" strokeWidth="1.2"
                            strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M9.5 16.5V15" stroke="currentColor" strokeWidth="1.2"
                            strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M14.2699 4.22999L14.8024 3.6975" stroke="currentColor"
                            strokeWidth="1.2" strokeLinecap="round"
                            strokeLinejoin="round"></path>
                        <path d="M4.19762 14.3025L4.73012 13.77" stroke="currentColor"
                            strokeWidth="1.2" strokeLinecap="round"
                            strokeLinejoin="round"></path>
                        <path d="M15.5 9H17" stroke="currentColor" strokeWidth="1.2"
                            strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M2 9H3.5" stroke="currentColor" strokeWidth="1.2"
                            strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M14.2699 13.77L14.8024 14.3025" stroke="currentColor"
                            strokeWidth="1.2" strokeLinecap="round"
                            strokeLinejoin="round"></path>
                        <path d="M4.19762 3.6975L4.73012 4.22999" stroke="currentColor"
                            strokeWidth="1.2" strokeLinecap="round"
                            strokeLinejoin="round"></path>
                    </svg>
                </span>
                {!iconsOnly && <span className="switch-control--text">Light</span>}
            </>}
            rightNode={<>
                <span className="ico ico-18">
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M9.49978 9C10.9214 7.54033 11.2728 5.3449 10.3776 3.51447C10.3216 3.38121 10.3472 3.22763 10.4433 3.11967C10.5394 3.0117 10.689 2.96856 10.8278 3.00876C11.897 3.28434 12.8729 3.84108 13.6543 4.62118C16.0726 6.97714 16.1237 10.8473 13.7683 13.2663C11.3496 15.6218 7.47938 15.5711 5.12321 13.153C4.34266 12.3722 3.78562 11.3964 3.51004 10.3273C3.46963 10.1883 3.51283 10.0384 3.62105 9.94221C3.72926 9.84603 3.88316 9.82069 4.0165 9.87712C5.84612 10.7718 8.04055 10.4208 9.49978 9Z"
                            stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"
                            strokeLinejoin="round"></path>
                    </svg>
                </span>
                {!iconsOnly && <span className="switch-control--text">Dark</span>}
            </>}
        />
    )
}

export default ThemSwith
