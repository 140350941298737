import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, Outlet, useParams, useSearchParams } from 'react-router-dom'
import { ReactComponent as ArticleIcon } from '../assets/img/sidebar-article-content.svg'
import { ReactComponent as PreviewIcon } from '../assets/img/sidebar-preview.svg'
import {ReactComponent as HintsIcon} from '../assets/img/sidebar-hints.svg'
import { ReactComponent as SettingIcon } from '../assets/img/sidebar-settings.svg'
import CreateSidebar from '../components/parts/createPagesParts/CreateSidebar'
import { getArticleByIdFeth } from '../store/articles/articlesThunks'
import { getMe } from '../store/auth/authSlice'



const ArticleEditLayout = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const user = useSelector(getMe)
    const { article, isLoading } = useSelector((state) => state.articles)
    const [searchParams] = useSearchParams()

    const links = [
        {
            icon: <ArticleIcon />,
            to: `/article-edit/${id}${searchParams.get('versionId') ? '?'+searchParams.toString() : ''}`,
            label: 'Article Content',
        },
        {
            icon: <SettingIcon />,
            to: `settings${searchParams.get('versionId') ? '?'+searchParams.toString() : ''}`,
            label: 'Settings',
        },
        {
            icon: <PreviewIcon />,
            to: `preview${searchParams.get('versionId') ? '?'+searchParams.toString() : ''}`,
            label: 'Preview Article',
        },
        {
            icon: <HintsIcon />,
            to: `for-appoval${searchParams.get('versionId') ? '?'+searchParams.toString() : ''}`,
            label: 'Suggets Changes',
        },
    ]

    useEffect(() => {
        if (id) {
            dispatch(getArticleByIdFeth({id, qwery:`${searchParams.toString()}`}))
        }
    }, [id, dispatch])

    const canEdit = useMemo(() => {
        return (article?.editors?.map((editor) => editor?.id) || []).includes(user?.id)
    }, [article, user])

    const isCreator = useMemo(() => article?.user?.id === user?.id, [article, user?.id])
    const isAutor = useMemo(() => {
        return (article?.authors?.map((author) => author?.id) || []).includes(user?.id)
    }, [article, user])

    const sidebarLinks = useMemo(() => {
        
        if(isCreator) {
            return links
        }
        if (isAutor) {
            return links.filter((_, index) => index !== 1)
        }
        return links.filter((_, index) => index !== 1 && index !== 3)
    }, [isCreator, isAutor])
    
    // if (!(canEdit || isCreator) && (!isLoading && user && article)) {
    //     return <Navigate to={`/articles/${id}`} />
    // }
   
    return (
        <div className={'quiz-page'}>
            <CreateSidebar links={sidebarLinks} status={article?.status} title={'Edit Article'} backRoute={'/articles'} />
            <div className="custom-overlay toggler-menu" style={{ zIndex: 51 }}></div>
            <Outlet />
        </div>
    )
}

export default ArticleEditLayout
