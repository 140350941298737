import React from 'react'
import ModalTemplate from './ModalTemplate'
import { Link, useParams } from 'react-router-dom'

const DeleteModal = ({ open, handelClose, blockCloseBg, title, deleteHandler }) => {
    const { id } = useParams()
    return (
        <ModalTemplate open={open} handelClose={handelClose} showHeader={false} blockCloseBg={blockCloseBg} >
            <div className="modal__body items-center pt-0">
                <div className="successfull-paymant">
                    <div className="successfull-paymant__content flex-auto">
                        {/* <img src={require("../../assets/img/successfull-image.png")} alt="" /> */}
                        <h4 className="mb-[16px] text-center">Are you sure you want to delete the {title}</h4>
                        <div className="flex flex-wrap gap-5">
                            <button type='button' className='btn btn--transparent btn--md radius-5 flex-1' onClick={handelClose}>Cancel</button>
                            <button type='button' className='btn btn--danger btn--md radius-5 flex-1' onClick={deleteHandler}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalTemplate>
    )
}

export default DeleteModal