import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getAllEventsFetch = createAsyncThunk(
    '/getAllEventsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.events.getAllEvents(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getEventCountByDateFetch = createAsyncThunk(
    '/getEventCountByDateFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.events.getEventCountByDate(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getEventByIdFetch = createAsyncThunk(
    '/getEventByIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.events.getEventById(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const viewEventByIdFetch = createAsyncThunk(
    '/viewEventByIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.events.viewEventById(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const bookEventByIdFetch = createAsyncThunk(
    '/bookEventByIdFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.events.bookEventById(payload);
            if (res.success) {
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);