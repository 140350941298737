import React from 'react'

const TextareaGroupRow = ({placeholder,rows = '8',defaultValue, value, textareaClasses='',label,error,errorMessage, ...props}) => {
    return (
        <div className="form-group-row">
            <span className="form-group-row__label justify-end">{label}</span>
            <div className="list-group list-group--xs flex-auto">
                <div className="input-wrapper input--md flex">
                    <textarea rows={rows} className={`textarea ${textareaClasses}`} style={{resize: "none"}} placeholder={placeholder} {...props} defaultValue={defaultValue} value ={value}></textarea>
                </div>
                {
                    errorMessage && error ?
                        <p className='color-danger text--xs mt-1'>{errorMessage}</p>
                        :
                        null
                }
            </div>
        </div>
    )
}

export default TextareaGroupRow
