import { createSlice, current } from '@reduxjs/toolkit';
import {
    addFlashcardCardStatsByCardIdFetch,
    createFlashCardBySetIdFetch,
    createFlashCardSetFetch,
    deleteFlashCardByIdFetch,
    deleteFlashCardSetFetch,
    editFlashCardByIdFetch,
    editFlashCardSetByIdFetch,
    editFlashCardSetSettingsByIdFetch,
    getAllFlashCardSetsFetch,
    getFavoriteFlashCardsFetch,
    getFlashCardSetByIdFetch,
    toggleFavoriteFlashcardCardByIdFetch,
    toggleFavoriteFlashcardFetch,
    toggleLikeFlashcardFetch,
    сlearFCSetStatisticsBySetIdFetch
} from './flashcardsThunks';

const initialState = {
    flashCardSets: [],
    flashCardSet: null,
    flashCards: [],
    flashCardId: null,
    activeFlashCardId: null,
    isLoading: false,
    isFlashCardsLoading: false,
    error: null,
    quizData: {
        round: 1,
        startTime: null,
        endTime: null,
        total: null,
        flashcards: [],
        selectedCards: [],
        currentCardIndex: 0,
        cardsCompleted: 0,
        cardsTotal: 0,
        studyTime: 0,
        againCount: 0,
        hardCount: 0,
        goodCount: 0,
        easyCount: 0,
        skipCount: 0
    },
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    }
};
const flashcardsSlice = createSlice({
    name: 'flashcards',
    initialState,
    reducers: {
        submitFlashcardAnswer(state, { payload }) {
            state.quizData.flashcards.push(payload)
        },
        flashcardsQuizStart(state, { payload }) {
            state.quizData.startTime = new Date().toISOString()
        },
        flashcardsQuizEnd(state, { payload }) {
            state.quizData.endTime = new Date().toISOString()
            state.quizData.currentCardIndex = 0
        },
        setFlashcardsRound(state, { payload }) {
            state.quizData.round = payload
        },

        endTraining(state, { payload }) {
            state.quizData = {
                round: 1,
                startTime: null,
                endTime: null,
                flashcards: [],
                selectedCards: [],
                currentCardIndex: 0,
                cardsCompleted: 0,
                cardsTotal: 0,
                studyTime: 0,
                againCount: 0,
                hardCount: 0,
                goodCount: 0,
                easyCount: 0,
                skipCount: 0
            }
        },
        addSelectedCard(state, { payload }) {
            const existingCardIndex = state.quizData.selectedCards.findIndex(card => card.id === payload.id);
            if (existingCardIndex !== -1) {
                state.quizData.selectedCards[existingCardIndex] = payload;
            } else {
                state.quizData.selectedCards.push(payload);
            }
        },
        removeSelectedCard(state, { payload }) {
            const cardIndex = state.quizData.selectedCards.findIndex(card => card.id === payload.id);

            if (cardIndex !== -1) {
                state.quizData.selectedCards.splice(cardIndex, 1);
            }

        },
        setCurrentCardIndex: (state, action) => {
            state.quizData.currentCardIndex = action.payload
        },

        setFlashCardId: (state, action) => {
            state.flashCardId = action.payload
        },
        setFlashCardSet: (state, action) => {
            state.flashCardSet = action.payload
        },
        setDragDropCards: (state, action) => {
            state.flashCardSet.flashcards = action.payload

        },
        setActiveFlashCardId: (state, action) => {
            state.activeFlashCardId = action.payload
        },
        updateCardStats: (state, action) => {

            const currentCardIndex = state.flashCardSet.flashcards.findIndex(item => item.id === action.payload.id);
            if (currentCardIndex >= 0) {

                // state.flashCardSet?.flashcards[currentCardIndex]?.flashCardStats[0] = action.payload?.data
                state.flashCardSet.flashcards[currentCardIndex].flashCardStats[0] = action.payload?.body


            }
        }
    },

    extraReducers(builder) {
        builder
            .addCase(getAllFlashCardSetsFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllFlashCardSetsFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.flashCardSets = action.payload?.data;
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllFlashCardSetsFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getFlashCardSetByIdFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getFlashCardSetByIdFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.flashCardSet = {
                    ...action.payload?.data,
                    flashcards: action.payload?.data?.flashcards.sort((a, b) => a.order - b.order)
                };
                // state.quizData.total = action.payload?.pagination?.total;
                state.pagination = action.payload?.pagination;
                const round = (action.payload?.data.flashCardSetStats[0]?.cardsCompleted || 0) / action.payload?.pagination?.total;
                console.log(round)
                state.quizData = {
                    ...state.quizData,
                    round: Math.ceil(round + 1),
                    currentCardIndex: (action.payload?.data.flashCardSetStats[0]?.cardsCompleted) || 0,
                    cardsCompleted: 0,
                    cardsTotal: 0,
                    studyTime: 0,
                    againCount: 0,
                    hardCount: 0,
                    goodCount: 0,
                    easyCount: 0,
                    skipCount: 0,
                    ...action.payload?.data.flashCardSetStats[0]
                }
            })
            .addCase(getFlashCardSetByIdFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(createFlashCardSetFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createFlashCardSetFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.flashCardSet = action.payload;
                state.flashCardId = action.payload?.id
            })
            .addCase(createFlashCardSetFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(editFlashCardSetByIdFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editFlashCardSetByIdFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.flashCardSet = {
                    ...state.flashCardSet,
                    ...action.payload
                };
            })
            .addCase(editFlashCardSetByIdFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(editFlashCardSetSettingsByIdFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editFlashCardSetSettingsByIdFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.flashCardSet = action.payload;
            })
            .addCase(editFlashCardSetSettingsByIdFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteFlashCardSetFetch.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteFlashCardSetFetch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.flashCardSets = state.flashCardSets.filter(
                    (set) => set.id !== action.payload.id
                );
            })
            .addCase(deleteFlashCardSetFetch.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(createFlashCardBySetIdFetch.pending, (state) => {
                state.isFlashCardsLoading = true;
                state.error = null;
            })
            .addCase(createFlashCardBySetIdFetch.fulfilled, (state, action) => {
                state.isFlashCardsLoading = false;
                state.activeFlashCardId = action.payload.id
                state.flashCardSet = {
                    ...state.flashCardSet,
                    flashcards: [
                        ...state.flashCardSet.flashcards,
                        action.payload
                    ]
                }
            })
            .addCase(createFlashCardBySetIdFetch.rejected, (state, action) => {
                state.isFlashCardsLoading = false;
                state.error = action.payload;
            })
            .addCase(editFlashCardByIdFetch.pending, (state) => {
                state.isFlashCardsLoading = true;
                state.error = null;
            })
            .addCase(editFlashCardByIdFetch.fulfilled, (state, action) => {
                state.isFlashCardsLoading = false;
                let curretCardIdx = state.flashCardSet.flashcards.findIndex(item => item.id === action.payload.id)
                state.flashCardSet.flashcards[curretCardIdx] = action.payload
            })
            .addCase(editFlashCardByIdFetch.rejected, (state, action) => {
                state.isFlashCardsLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteFlashCardByIdFetch.pending, (state) => {
                state.isFlashCardsLoading = true;
                state.error = null;
            })
            .addCase(deleteFlashCardByIdFetch.fulfilled, (state, action) => {
                state.isFlashCardsLoading = false;
                state.flashCardSet.flashcards = state.flashCardSet.flashcards.filter(
                    (card) => card.id !== action.payload.id
                );
            })
            .addCase(deleteFlashCardByIdFetch.rejected, (state, action) => {
                state.isFlashCardsLoading = false;
                state.error = action.payload;
            })
            .addCase(toggleLikeFlashcardFetch.fulfilled, (state, action) => {
                let cardSet = state.flashCardSets.find(item => item.id === action.payload.id);
                console.log(action)
                if (cardSet) {
                    cardSet.isLiked = action.payload.isLiked;
                    cardSet.likes = action.payload.likes;
                }
                if (state.flashCardSet) {

                    state.flashCardSet.isLiked = action.payload.isLiked
                }

            })
            .addCase(toggleFavoriteFlashcardFetch.fulfilled, (state, action) => {
                let cardSet = state.flashCardSets.find(item => item.id === action.payload.id);

                if (cardSet) {
                    cardSet.isFavorite = action.payload.isFavorite;
                }
                if (state.flashCardSet) {
                    state.flashCardSet.isFavorite = action.payload.isFavorite
                }
            })
            .addCase(toggleFavoriteFlashcardCardByIdFetch.fulfilled, (state, action) => {
                let currentCard = state.flashCardSet.flashcards.find(item => item.id === action.payload.id)
                currentCard.isFavorite = action.payload.isFavorite
                state.quizData.selectedCards = state.quizData.selectedCards
                    .filter(card => {
                        if(card.id === action.payload?.id) {
                            return action.payload?.isFavorite
                        } else {
                            return true
                        }
                    })
                    
            })
            .addCase(getFavoriteFlashCardsFetch.fulfilled, (state, action) => {
                state.quizData.selectedCards = action.payload?.flashcards
            })
            .addCase(addFlashcardCardStatsByCardIdFetch.fulfilled, (state, action) => {
                state.quizData = {
                    ...state.quizData,
                    ...action.payload
                }
            })
            .addCase(сlearFCSetStatisticsBySetIdFetch.fulfilled, (state, action) => {
                if (state.flashCardSet) {
                    state.flashCardSet.flashCardSetStats = []
                }
                state.quizData = {
                    ...state.quizData,
                    round: 1,
                    currentCardIndex: 0,
                    cardsCompleted: 0,
                    cardsTotal: 0,
                    studyTime: 0,
                    againCount: 0,
                    hardCount: 0,
                    goodCount: 0,
                    easyCount: 0,
                    skipCount: 0,
                }
            })
    }
})

export const {
    submitFlashcardAnswer,
    flashcardsQuizStart,
    flashcardsQuizEnd,
    setFlashcardsRound,
    endTraining,
    addSelectedCard,
    removeSelectedCard,
    setFlashCardId,
    setFlashCardSet,
    setCurrentCardIndex,
    setDragDropCards,
    setActiveFlashCardId,
    updateCardStats
} = flashcardsSlice.actions;

export const getFlashCardSet = (state) => state.flashcards.flashCardSet;
export const getFlashCardId = (state) => state.flashcards.flashCardId;
export const getIsLoading = (state) => state.flashcards.isLoading;
export const getActiveFlashCardId = (state) => state.flashcards.activeFlashCardId;

export default flashcardsSlice.reducer