import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { createNewConversationByUserIdFeth, getAllConversationsFeth } from '../../../store/chat/chatThunk'
import { formatDateToTime, getImageSrc } from '../../../helpers/utils'
import PageLoader from '../../../components/parts/PageLoader'
import UserAvatar from './UserAvatar'
import useDebounce from '../../../hooks/useDebounce'

const Dialogs = ({ chatHandler, setFirstLoad }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [limits, setLimits] = useState(20)
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1);
    const { id } = useParams()
    const { allChats, chatUsersAll } = useSelector(state => state.chat)
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const loadMoreRef = useRef(null);
    const cotainerRef = useRef(null)
    const observer = useRef(null);
    const searchRef = useRef(null)
    useEffect(() => {
        const fetchData = async () => {
            if (!hasMore || loading) return;
            setLoading(true)
            const res = await dispatch(getAllConversationsFeth(`search=${search}&page=${page}`)).unwrap()
            if (res) {
                setLoading(false)
                if (res.pagination.maxPages === page) {
                    setHasMore(false)
                }
            }
        }
        fetchData()
        setFirstLoad(false)
    }, [page, search])
    const createNewChat = async (user) => {
        const res = await dispatch(createNewConversationByUserIdFeth(user.id)).unwrap()
        if (res) {
            setHasMore(true)
            setSearch('')
            setPage(1)
            searchRef.current.value = ''
            navigate(`/chat/${res.conversation.id}`)
            chatHandler('user-chart')
        }
        
    }
    
    const debouncedSearchChange = useDebounce((value) => {
        setSearch(value)
        setHasMore(true)
        setPage(1)
    }, 500);
    const handleSearchChange = (e) => {
        const { value } = e.target;
        debouncedSearchChange(value);
    };
    useEffect(() => {
        if (loadMoreRef.current) {
            const observerCallback = (entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPage(prev => prev + 1);
                }
            };

            observer.current = new IntersectionObserver(observerCallback, {
                root: null,
                rootMargin: '50px',
                threshold: 1.0,
            });

            observer.current.observe(loadMoreRef.current);

            return () => {
                if (observer.current && loadMoreRef.current) {
                    observer.current.unobserve(loadMoreRef.current);
                }
            };
        }
    }, [hasMore, loadMoreRef]);
    
    return (
        <aside className="chat-sidebar chat-sidebar--left">
            <div className="post-widget--header">
                <div className="heading-post-row">
                    <span className="ico ico--xs">
                        <img src={require("../../../assets/img/Mail-icon.png")} />

                    </span>
                    <p className="heading-title-post text-def-2-c font-[600]">
                        Messages
                    </p>
                </div>
            </div>
            <div className="input-wrapper input--md">
                <span className="ico">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10.137" cy="10.137" r="6.47345" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                        <path d="M18.3365 18.3365L14.7141 14.7141" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </span>
                <input
                    ref={searchRef}
                    className="input input--search input--solid rounded-full"
                    onChange={handleSearchChange}
                    type="text"
                    placeholder="Search..."
                />
            </div>
            <div className="sidebar-body" ref={cotainerRef}>
                <div className="chat-list">
                    {(allChats && allChats.length > 0) || chatUsersAll && chatUsersAll.length > 0 ?
                        <>
                            {allChats.map((chat, index) => (
                                <Link to={`/chat/${chat.id}`} key={chat.id} className={`chat-list-item ${chat.id === +id ? 'active-chat' : ''}`} onClick={() => chatHandler('user-chart')}>
                                    <UserAvatar user={chat.user} />
                                    <div className="user-chat-body">
                                        <div className="chat-list-item__heading user-chat-heading">
                                            <span className="chat-list-item__user-name user-chat-name">{`${chat.user.firstName} ${chat.user.lastName}`}</span>
                                            <span className="chat-list-item__time user-chat-time">{formatDateToTime(chat.user.lastSeen)}</span>
                                        </div>
                                        <div className="chat-list-item__message">
                                            {chat.lastMessage}
                                        </div>
                                    </div>
                                    {chat.unreadMessagesCount > 0 ? <span className='unread-messages'>{chat.unreadMessagesCount}</span> : null}
                                </Link>
                            ))}
                            {chatUsersAll.map((user, index) => (
                                <button type='button' key={user.id} className={`bg-transparent chat-list-item w-full`} onClick={()=> createNewChat(user)}>
                                    <UserAvatar user={user} />
                                    <div className="user-chat-body">
                                        <div className="chat-list-item__heading user-chat-heading">
                                            <span className="chat-list-item__user-name text-start user-chat-name">{`${user.firstName} ${user.lastName}`}</span>
                                        </div>
                                    </div>
                                </button>
                            ))}
                        </> :
                        loading ? null
                            : <h4> You have no active conversations </h4>}
                    {hasMore && <span ref={loadMoreRef} className="btn btn--xl radius-5 w-full">
                        <span className="ico animate-spin">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.7053 3.40707C14.1506 3.31066 13.5829 3.24999 13.0001 3.24999C12.4173 3.24999 11.8496 3.31066 11.2949 3.40707" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M22.2279 9.7207C22.6103 10.7705 22.8291 11.8982 22.8291 13.079" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M20.5271 6.76315C19.7926 5.8889 18.9054 5.14898 17.9109 4.57373" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.08939 4.57373C7.09489 5.14898 6.20873 5.8889 5.47314 6.76315" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M3.17097 13.079C3.17097 11.8971 3.38981 10.7694 3.77222 9.7207" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.08936 21.5842C9.06436 22.1486 10.1444 22.5484 11.2949 22.7499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M3.77222 16.4375C4.16872 17.5252 4.74288 18.5251 5.47305 19.395" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M14.7053 22.7499C15.8558 22.5484 16.9348 22.1486 17.9109 21.5842" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M20.5271 19.395C21.2573 18.5251 21.8314 17.5252 22.2279 16.4375" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                    </span>}
                </div>
            </div>
        </aside>
    )
}

export default Dialogs
