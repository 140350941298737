import { createSlice, current } from '@reduxjs/toolkit';
import { finalizeLoginFetch, forgotPasswordFetch, getMeFetch, loginFetch, logoutFetch, registerFetch, updateProfileFetch } from './authThunks';

const initialState = {
    authorized: null,
    me: null,
    token: window.localStorage.getItem("authToken") || window.sessionStorage.getItem("authToken"),
    tempToken: null,
    isLoading: false,
    isLocalload: false,
    showLoginModal: false,
    hasEditChanges: false,
    showCreateModal: false,
    siteTheme: window.localStorage.getItem("siteTheme") || 'light'
}

const clearToken = () => {
    window.localStorage.removeItem('authToken');
    window.sessionStorage.removeItem('authToken');
    // document.cookie = "rememberMe=false; path=/";
}
const clearRemember = () => {
    window.localStorage.removeItem('remember');
}
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthorized: (state, action) => {
            state.authorized = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setMe: (state, action) => {
            state.me = action.payload
        },
        setShowLoginModal: (state, action) => {
            state.showLoginModal = action.payload
        },
        deleteCotanctEmail: (state, action) => {
            state.me.contactEmails = state.me.contactEmails.filter(email => email.id !== action.payload)
        },
        addCotanctEmail: (state, action) => {
            state.me.contactEmails.push(action.payload)
        },
        updateCotanctEmail: (state, action) => {
            const contactEmail = state.me.contactEmails.find(email => email.id === action.payload.id);
            contactEmail.email = action.payload.email
        },
        updateMe: (state, action) => {
            state.me = {
                ...state.me, 
                ...action.payload
            }
        },
        setHasEditChanges: (state, action) => {
            state.hasEditChanges = action.payload
        },
        setShowCreateModal: (state, action) => {
            state.showCreateModal = action.payload
        },
        setSiteTheme: (state, action) => {
            state.siteTheme = action.payload
        },
        set2FA: (state, action) => {
            state.me.auth2fa = action.payload
        },
        setTermToken: (state, action) => {
            state.tempToken = action.payload
        }
    },

    extraReducers(builder) {
        builder.addCase(loginFetch.fulfilled, (state,  payload ) => {
            // state.authorized = true;
            // state.token = payload?.token
            // window.localStorage.setItem("authToken", payload?.token)
            state.isLocalload = false

        });
        builder.addCase(loginFetch.pending, (state, { payload }) => {
            state.authorized = null;
            state.token = null;
            state.isLocalload = true
        });
        builder.addCase(loginFetch.rejected, (state, { payload }) => {
            clearToken()
            clearRemember()
            state.token = null;
            state.authorized = false;
            state.isLocalload = false
        });
        builder.addCase(finalizeLoginFetch.fulfilled, (state, { payload }) => {
            // state.authorized = true;
            // state.token = payload?.token
            // window.localStorage.setItem("authToken", payload?.token)
            state.isLoading = false

        });
        builder.addCase(finalizeLoginFetch.pending, (state, { payload }) => {
            state.authorized = null;
            state.token = null;
            state.isLoading = true
        });
        builder.addCase(finalizeLoginFetch.rejected, (state, { payload }) => {
            clearToken()
            clearRemember()
            state.token = null;
            state.authorized = false;
            state.isLoading = false
        });

        builder.addCase(getMeFetch.fulfilled, (state, { payload }) => {
            state.me = payload;
            state.authorized = true;
            state.isLoading = false
        });
        builder.addCase(getMeFetch.pending, (state, { payload }) => {
            state.authorized = null;
            state.isLoading = true
        });
        builder.addCase(getMeFetch.rejected, (state, { payload }) => {
            state.token = null;
            state.authorized = false;
            state.isLoading = false
            clearToken()
        });

        builder.addCase(registerFetch.fulfilled, (state, { payload }) => {
            window.sessionStorage.setItem("authToken", payload?.token)
            state.token = payload?.token
        });
        builder.addCase(updateProfileFetch.fulfilled, (state, { payload }) => {
            state.me = {
                ...state.me,
                ...payload
            };
        });

        builder.addCase(logoutFetch.fulfilled, (state, { payload }) => {
            state.authorized = false;
            state.me = null;
            state.token = null;
            clearToken()
            clearRemember()
        });
        builder.addCase(forgotPasswordFetch.fulfilled, (state, action) => {
            state.isLocalload = false
        })
        builder.addCase(forgotPasswordFetch.pending, (state, action) => {
            state.isLocalload = true
        })
        builder.addCase(forgotPasswordFetch.rejected, (state, action) => {
            state.isLocalload = false
        })
    }
})

export const {
    setAuthorized, setToken, setMe, deleteCotanctEmail, updateCotanctEmail, addCotanctEmail,setShowLoginModal,  updateMe,setHasEditChanges,setShowCreateModal,setSiteTheme, set2FA, setTermToken
} = authSlice.actions;

export const getMe = (state) => state.auth.me;
export const getAuthorized = (state) => state.auth.authorized;
export const getToken = (state) => state.auth.token;
export const getShowLoginModal = (state) => state.auth.showLoginModal;

export default authSlice.reducer