import React from 'react'
import pdf from "../../../assets/img/Pdf-File.svg"
import word from "../../../assets/img/Word.svg"
import Actions from './Actions'
import { getDateToString, getImageSrc } from '../../../helpers/utils'
import { Link } from 'react-router-dom'
import { setShowLoginModal } from '../../../store/auth/authSlice'
import { useDispatch } from 'react-redux'
import { favoriteMaterialLibraryByidFeth, likeMaterialLibraryByIdFeth } from '../../../store/materials/materialLibraryThunks'

const CardMaterials = ({ data, isList, isDocument, authorized, isVideo }) => {
    const dispatch = useDispatch();
    const toggleLike = () => {
        if (authorized) {
            dispatch(likeMaterialLibraryByIdFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true))
        }

    }
    const toggleFavorite = () => {
        if (authorized) {
            dispatch(favoriteMaterialLibraryByidFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true))
        }

    }
    return (
        <>
            {
                !isList ?
                    <div className="card card-post">
                        <div className={`card-header post-image wrapp-image-sm ${isDocument ? "post-image--center" : ""}`}>

                            {isVideo ?
                                <Link
                                    to={`${authorized ? `/materials/video/${data?.id}` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }
                                    }
                                    className={'video-wrapper'}
                                >
                                    {data?.fileName && <video src={getImageSrc(data?.fileName)} style={{
                                        width: ' 100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}></video>}
                                    <button className="btn video-action-btn">
                                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 7.13397C12.6667 7.51888 12.6667 8.48112 12 8.86602L1.5 14.9282C0.833331 15.3131 -6.92038e-07 14.832 -6.58389e-07 14.0622L-1.28416e-07 1.93782C-9.47672e-08 1.16802 0.833333 0.686896 1.5 1.0718L12 7.13397Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </Link>
                                :
                                isDocument ?
                                    <Link
                                        to={`${authorized ? `/materials/document/${data?.id}` : '#'}`}
                                        onClick={(e) => {
                                            if (!authorized) {
                                                e.preventDefault()
                                                dispatch(setShowLoginModal(true))
                                            }
                                        }
                                        }
                                        className='flex w-full self-stretch items-center justify-center'
                                    >
                                        {data?.fileName && <img src={data?.fileName?.split('.')?.pop() === 'docx' ? word : pdf} alt={data?.title} />}
                                    </Link>
                                    :
                                    <Link
                                        to={`${authorized ? `/materials/image/${data?.id}` : '#'}`}
                                        onClick={(e) => {
                                            if (!authorized) {
                                                e.preventDefault()
                                                dispatch(setShowLoginModal(true))
                                            }
                                        }
                                        }
                                        className={'flex w-full'}
                                    >
                                        {data?.fileName && <img className='w-full' src={getImageSrc(data?.fileName)} alt={data?.title} />}
                                    </Link>
                            }
                            <Actions
                                isFavoriteProps={data.isFavorite}
                                isLikeProps={data.isLiked}
                                handleLikeProps={toggleLike}
                                handleAddToFavoritesProps={toggleFavorite}
                            />

                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <div className="group-between">
                                    <div className="pill-custom ">
                                        <span className="pill-text">{data?.filter?.name}</span>
                                    </div>
                                </div>
                                <Link
                                    to={`${authorized ? `/materials/${isVideo ? 'video' : isDocument ? 'document' : 'image'}/${data?.id}` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }
                                    }
                                >
                                    <h5 className="heading post-title">{data.title}</h5>
                                </Link>
                                <div className="post-others">
                                    {data?.user && <p className="text-[13px] leading-[16px]">
                                        by {" "}
                                        <Link to={`/profile/${data?.user?.id}`}>
                                            <span className="color-danger">{data?.user?.firstName + '' + data?.user?.lastName}</span>
                                        </Link>
                                    </p>}
                                    <p className="mini-post--desk">{getDateToString(data?.createdAt)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="card card-post --card-row card-post--md">
                        <div className={`card-header post-image wrapp-image-sm ${isDocument ? "post-image--center" : ""}`}>
                            {isVideo ?
                                <Link
                                    to={`${authorized ? `/materials/video/${data?.id}` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }
                                    }
                                    className={'video-wrapper'}
                                >
                                    <video src={getImageSrc(data?.fileName)} style={{
                                        width: ' 100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}></video>
                                    <button className="btn video-action-btn">
                                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 7.13397C12.6667 7.51888 12.6667 8.48112 12 8.86602L1.5 14.9282C0.833331 15.3131 -6.92038e-07 14.832 -6.58389e-07 14.0622L-1.28416e-07 1.93782C-9.47672e-08 1.16802 0.833333 0.686896 1.5 1.0718L12 7.13397Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </Link>
                                :
                                isDocument ?
                                    <Link
                                        to={`${authorized ? `/materials/document/${data?.id}` : '#'}`}
                                        onClick={(e) => {
                                            if (!authorized) {
                                                e.preventDefault()
                                                dispatch(setShowLoginModal(true))
                                            }
                                        }
                                        }
                                    >
                                       {data?.fileName && <img src={data?.fileName?.split('.')?.pop() === 'docx' ? word : pdf} alt={data?.title} />}
                                    </Link>
                                    :
                                    <Link
                                        to={`${authorized ? `/materials/image/${data?.id}` : '#'}`}
                                        onClick={(e) => {
                                            if (!authorized) {
                                                e.preventDefault()
                                                dispatch(setShowLoginModal(true))
                                            }
                                        }
                                        }
                                        className={'flex w-full'}
                                    >
                                        <img className='w-full' src={getImageSrc(data?.fileName)} alt={data?.title} />
                                    </Link>
                            }
                            <Actions
                                isFavoriteProps={data.isFavorite}
                                isLikeProps={data.isLiked}
                                handleLikeProps={toggleLike}
                                handleAddToFavoritesProps={toggleFavorite}
                            />

                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <Link
                                    to={`${authorized ? `/materials/${isVideo ? 'video' : isDocument ? 'document' : 'image'}/${data?.id}` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }
                                    }
                                >
                                    <h4 className="heading post-title">{data?.title}</h4>
                                </Link>
                                <div className="post-others">
                                    {data?.user && <p className="text-[13px] leading-[16px]">
                                        by {" "}
                                        <Link to={`/profile/${data?.user?.id}`}>
                                            <span className="color-danger">{data?.user?.firstName + '' + data?.user?.lastName}</span>
                                        </Link>
                                    </p>}
                                </div>
                            </div>
                            <div className="card-body--item">
                                <p className="post--desk">{data?.description}</p>
                            </div>
                            <div className="card-body--item">
                                <div className="group-between">
                                    <div className="group-between--item">
                                        <div className="pill-custom">
                                            <span className="pill-text">{data?.filter?.name}</span>
                                        </div>
                                        <p className="mini-post--desk">{getDateToString(data?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default CardMaterials