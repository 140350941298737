import React, { useEffect, useMemo, useState } from 'react'
import CardArticle from '../../components/parts/Cards/CardArticle';
import Pagination from '../../components/parts/Pagination';
import SelectGroup from '../../components/UI/formElements/SelectGroup';
import SwitchViewType from '../../components/parts/SwitchViewType';
import { filterOptions } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import PageLoader from '../../components/parts/PageLoader';
import { getProfileArticlesFeth } from '../../store/profile/profileThunk';

const ProfileArticles = () => {
    const { authorized,  showLoginModal } = useSelector((state) => state.auth)
    const [isList, setIsList] = useState(false)
    const dispatch = useDispatch()
    const [userId] = useOutletContext()
    const { profileData, isLoading, pagination } = useSelector(state => state.profile);
    const [searchParams, setSearchParams] = useSearchParams()
    // const [params, setParams] = useState({
    //     sort: 'latest',
    // })
    const formatOptionsQuery = (value) => {
		return value?.length
			? value.split(',').filter(Boolean) || []
			: []
	}
	const params = useMemo(() => ({
		sort: searchParams.get('sort') || 'latest',
	}), [searchParams])
    const handleSortChange = (option) => {
        // setParams(prev => ({
        //     ...prev,
        //     sort: option.value
        // }))
        setSearchParams({
            ...params,
            sort: option.value,
        });
    }
    useEffect(() => {
        if (userId) {
            dispatch(getProfileArticlesFeth({ id: userId, qwery: searchParams.toString() }))
        }
    }, [userId, searchParams])
    return (
        <div className="tabs-item active-tab" id="quizzes">
            <div className="w-full">
                <div className="page-heading mb-[30px]">
                    <div className="page-heading__item">
                        <h3 className="mr-[24px]">Articles</h3>
                        <SwitchViewType onChange={(checked) => setIsList(checked)} />
                    </div>
                    <div className="page-heading__item">
                        <SelectGroup
                            wrapperClasses={'input--sm'}
                            options={filterOptions}
                            defaultValue={filterOptions[0]}
                            onChange={handleSortChange}
                        />
                    </div>
                </div>   
                <div className="row gutters-cards flex-auto">
                    {
                        profileData && profileData.length && !isLoading ?
                            profileData.map(card => (
                                <div key={card.id} className={!isList ? "col-sm-6 col-lg-4" : "col-12"} >
                                    <CardArticle data={card} isList={isList} authorized={authorized} showStatus={true} />
                                </div>
                            ))
                            :isLoading ?
                            <PageLoader /> :
                            <h2>Page is empty</h2>
                    }
                </div>
                <Pagination {...pagination} params={params} />                                
            </div>
        </div>
    )
}

export default ProfileArticles
