import { getAxiosConfig } from "../config";

const pagesBase = 'api/courses';

export const courses = (instance) => ({
    async getAllCourses(qwery) {
        try {
            const { data } = await instance.get(`${pagesBase}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getCourseById({ id, qwery }) {
        try {
            const { data } = await instance.get(`${pagesBase}/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createCourse(payload) {
        try {
            const { data } = await instance.post(`${pagesBase}`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createLessonByCourseId({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/lessons/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async completeLessonByLessonId(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/lessons/complete/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async lessonsOrderingByCourseId({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/lessons/ordering/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async createSettingsByCourseId({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/settings/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async likeCourseByCourseId(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/like/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async viewCourseByCourseId(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/view/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async favoriteCourseByCourseId(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/favorite/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async purchaseCourseById({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/purchase/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async purchaseConfirmation({ id, body }) {
        try {
            const { data } = await instance.post(`${pagesBase}/purchase/${id}/confirm`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editCourseById({ id, body }) {
        try {
            const { data } = await instance.patch(`${pagesBase}/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editSettingsByCourseId({ id, body }) {
        try {
            const { data } = await instance.patch(`${pagesBase}/settings/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editLessonByLessonId({ id, body }) {
        try {
            const { data } = await instance.put(`${pagesBase}/lessons/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async cloneLessonById(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/lessons/clone/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteLessonByLessonId(id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/lessons/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteCourseById(id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});