import { createSlice } from '@reduxjs/toolkit';
import { getSubscriptionPlansFeth, subscribeByPlanIdFeth } from './subscriptionPlansThunk';

const initialState = {
    subscriptionPlans: [],
    currentPlan: null,
    isLoading: false
}

const subscriptionPlanSlice = createSlice({
    name: 'subscriptionPlan',
    initialState,
    reducers: {
        setCurrentPlan: (state, action) => {
            state.currentPlan = action.payload
        }
    },

    extraReducers(builder) {
        builder
            .addCase(getSubscriptionPlansFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.subscriptionPlans = action.payload
            })
            .addCase(getSubscriptionPlansFeth.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getSubscriptionPlansFeth.rejected, (state, action) => {
                state.isLoading = false
            })
    }
})

export const {
    setCurrentPlan
} = subscriptionPlanSlice.actions;


export default subscriptionPlanSlice.reducer