import React, { useState } from 'react'

const Accordeon = ({ children, title, isActive = false, iconStyle = 'pluse', className='' }) => {
    const [accordeonActive, setAccordeonActive] = useState(isActive)
    const clickHandler = () => {
        setAccordeonActive(prev => !prev);
    }
    return (
        <div className={`accordeon ${className}  ${accordeonActive ? 'active-accordeon' : ''}`} >
            <div className="accordeon-handler" onClick={clickHandler} >
                {iconStyle === 'icon-first' && <span className="accordeon__icon">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.33398 13H21.6673" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M13 4.33325V21.6666" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </span>}
                <div className="accordeon__title">{title}</div>
                {iconStyle === 'pluse' && <div className="accordeon__icos--motor">
                    <span className="accordeon__icon --plus">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M3.75 12H20.25" stroke="#6F7D8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M12 3.75V20.25" stroke="#6F7D8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_521_51828">
                                    <rect width="24" height="24" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <span className="accordeon__icon --minus">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path d="M3.75 12H20.25" stroke="#6F7D8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_521_51916">
                                    <rect width="24" height="24" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </div>}
                {iconStyle === 'pluseCircle' &&
                    <span className="accordeon__icon ml-auto">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0016 24C8.80156 24 5.80156 22.8 3.50156 20.5C-1.19844 15.8 -1.19844 8.2 3.50156 3.5C5.80156 1.2 8.80156 0 12.0016 0C15.2016 0 18.2016 1.2 20.5016 3.5C25.2016 8.2 25.2016 15.8 20.5016 20.5C18.2016 22.8 15.2016 24 12.0016 24ZM12.0016 2C9.30156 2 6.80156 3 4.90156 4.9C1.00156 8.8 1.00156 15.2 4.90156 19.1C6.80156 21 9.30156 22 12.0016 22C14.7016 22 17.2016 21 19.1016 19.1C23.0016 15.2 23.0016 8.9 19.1016 5C17.2016 3 14.7016 2 12.0016 2Z" fill="#6F7D8C" />
                            <path d="M12 18C11.4 18 11 17.6 11 17V7C11 6.4 11.4 6 12 6C12.6 6 13 6.4 13 7V17C13 17.6 12.6 18 12 18Z" fill="#6F7D8C" />
                            <path d="M17 13H7C6.4 13 6 12.6 6 12C6 11.4 6.4 11 7 11H17C17.6 11 18 11.4 18 12C18 12.6 17.6 13 17 13Z" fill="#6F7D8C" />
                        </svg>
                    </span>
                }
                {iconStyle === 'arrowLeft' &&
                    <span className="accordeon__icon ml-auto">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 16L14 12L10 8" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                    </span>
                }
            </div>
            {accordeonActive &&
                <div className="accordeon-body !block pb-3">
                    <div>
                        {children}
                    </div>
                </div>
            }
        </div>
    )
}

export default Accordeon
