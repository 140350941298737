import {ForumSideBar} from "../pages/Forums/ForumSideBar";
import Header from "../components/parts/Header";
import {Outlet, useParams} from "react-router-dom";
import Footer from "../components/parts/Footer";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getForumFeth } from "../store/forums/forumsThunk";
import BreadCrambs from "../components/parts/BreadCrambs";
import { useSelector } from "react-redux";


export const ForumLayout = () => {
    const {id, threadId} = useParams()
    const dispatch = useDispatch()
    const { forumOne , threadOne} = useSelector(state => state.forums)
    const breadcramdsForum = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/forums',
            label: 'Forums'
        },
        {
            link: `/forums/${id}`,
            label: forumOne?.name
        },
    ]
    const breadcramdsThread = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/forums',
            label: 'Forums'
        },
        {
            link: `/forums/${id}`,
            label: forumOne?.name
        },
        {
            link: `/forums/${id}/thread/${threadId}`,
            label: threadOne?.title
        },
    ]
    useEffect(() => {
        if(id) {
            dispatch(getForumFeth(id))
        }
    },[id])
    return (
        <div className='wrapper header-fixed'>
            <Header/>
            <main className="content">
                <BreadCrambs breabcrambs={threadId ? breadcramdsThread : breadcramdsForum} />
                <section className="page-section">
                    <div className="container">
                        <div className="page-section--row">
                            <div className="page-content self-stretch">
                                <Outlet />
                            </div>
                            <ForumSideBar/>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </div>


    )
}