import React, { useEffect, useState } from 'react';
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";

import { ReactComponent as PlusIcon } from '../../assets/img/ico-plus-2.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/img/ico-arrow-right.svg';

import SortableList from '../../components/parts/SortableList';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import SortCourses from '../../components/parts/SortCourses';
import { setLesson, setLessonId, setLessons } from '../../store/courses/coursesSlice';
import { cloneLessonByIdFeth, deleteLessonByLessonIdFeth, lessonsOrderingByCourseIdFeth } from '../../store/courses/coursesThunk';
import { setHasEditChanges } from '../../store/auth/authSlice';

const CourseContent = () => {
    const {id} = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { courseOne, courseId, isLoading } = useSelector(state => state.courses)
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(courseOne?.lessons)
    }, [courseOne])

    const questionEditHandler = (id) => {
        dispatch(setLessonId(id));
        dispatch(setLesson(...courseOne.lessons.filter(item => item.id === id)))
        navigate('add')
    }
    const clearLesson = () => {
        dispatch(setLesson(null))
        dispatch(setLessonId(null))
    }
    const submitHandler = async () => {
        const orderingData = data.map((item, index) => ({
            lessonId: item.id,
            order: index
        }))
        const res = await dispatch(lessonsOrderingByCourseIdFeth({
            id: courseId, body: {
                "ordering": orderingData
            }
        })).unwrap()
        if(res) {
            dispatch(setLessons(data))
            navigate(`settings`)
            if(id) {
                navigate(`/course-edit/${id}/settings`)
            } else {
                navigate(`/course-create/settings`)
            }
            dispatch(setHasEditChanges(false)) 
        }
    }
    const deletehandler = async (id) => {
        const filteredLessons = courseOne.lessons.filter(item => item.id !== id)
        let res = await dispatch(deleteLessonByLessonIdFeth(id)).unwrap()
        if (res) {
            const orderingData = filteredLessons.map((item, index) => ({
                lessonId: item.id,
                order: index
            }))
            const res2 = await dispatch(lessonsOrderingByCourseIdFeth({
                id: courseId, body: {
                    "ordering": orderingData
                }
            })).unwrap()
        }
    }
    const copyHandler = async (id) => {
        let res = await dispatch(cloneLessonByIdFeth(id)).unwrap()
        console.log(res)
    }
    const conf = {
        title: 'Course Content',
        btnTitle: 'Add Lesson',
        btnAction: () => {
            clearLesson()
            navigate("add")
        }
    };

    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Lessons ({data?.length})</p>
                                                <div className="w-full">
                                                    {data?.length > 0 && 
                                                        <SortCourses 
                                                            items={data} 
                                                            setItems={(e)=>{
                                                                dispatch(setHasEditChanges(true)) 
                                                                setData(e)}} 
                                                                editHandler={questionEditHandler} 
                                                                removeHandler={deletehandler}
                                                                copyHandler={copyHandler}
                                                            />}
                                                    <Link to={"add"}
                                                        onClick={() => clearLesson()}
                                                        className="btn btn--primary btn--xs radius-5 gap-[10px]">
                                                        <span className="ico">
                                                            <PlusIcon />
                                                        </span>
                                                        <span className="info text--sm">Add Lesson</span>
                                                    </Link>
                                                    <p className="text--md color-secondary mt-[16px]">Add more lessons to your course </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/courses'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <ArrowRightIcon />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default CourseContent;
