import React, { useEffect, useRef, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
    ClassicEditor,
    AccessibilityHelp,
    Alignment,
    AutoImage,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    Essentials,
    ImageBlock,
    ImageCaption,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    List,
    Paragraph,
    SelectAll,
    SimpleUploadAdapter,
    Underline,
    Undo
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import EmojiPlugin from '../../hooks/ckEditorEmoijPlagin';
import ThisCustomUploadAdapterPlugin from '../../hooks/uploadAdapter';

const CommentForm = ({ addComment, formType }) => {
    const [editor, setEditor] = useState(null);
    const [text, setText] = useState('');
    const toolbarRef = useRef();
    const handleSubmit = (e) => {
        e.preventDefault();
        addComment(text);
        setText('')
    };
    const handleImageUpload = () => {
        console.log('Before upload')
    }
    const editorConfig = {
        toolbar: {
            items: [
                // 'undo',
                // 'redo',
                // '|',
                // 'selectAll',
                'emojiPicker',
                // '|',
                'bold',
                'italic',
                'underline',
                // '|',
                'link',
                // 'insertImage',
                // 'blockQuote',
                // '|',
                // 'alignment',
                // '|',
                // 'bulletedList',
                // 'numberedList',
                '|',
                // 'accessibilityHelp',

            ],
            shouldNotGroupWhenFull: false
        },
        plugins: [
            AccessibilityHelp,
            Alignment,
            AutoImage,
            AutoLink,
            Autosave,
            BlockQuote,
            Bold,
            Essentials,
            ImageBlock,
            ImageCaption,
            ImageInsert,
            ImageInsertViaUrl,
            ImageResize,
            ImageToolbar,
            ImageUpload,
            Italic,
            Link,
            List,
            Paragraph,
            SelectAll,
            // SimpleUploadAdapter,
            Underline,
            Undo,
            EmojiPlugin
        ],
        extraPlugins: [ThisCustomUploadAdapterPlugin],
        image: {
            toolbar: ['toggleImageCaption', 'imageTextAlternative', '|', 'resizeImage']
        },
        height: 120,
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            // decorators: {
            //     toggleDownloadable: {
            //         mode: 'manual',
            //         // label: 'Downloadable',
            //         // attributes: {
            //         //     download: 'file'
            //         // }
            //     }
            // }
        },
        placeholder: 'Enter your text thread...',
        ckfinder: {
            options: {
                beforeSend: handleImageUpload
            }
        },
    };
    
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setText(data);
    };
    useEffect(() => {
        if (editor && toolbarRef.current) {
            const toolbar = editor.ui.view.toolbar.element;
            toolbarRef.current.appendChild(toolbar);
        }
    }, [editor]);

    return (
        <div>
            {formType === 'input' && <form className="comments-send-form" onSubmit={handleSubmit}>
                <div className="input-wrapper input--lg flex">
                    <input
                        className="input input--outline"
                        placeholder="Enter your comment here..."
                        required
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn--primary btn--lg radius-5">
                    <span className="text-[14px] font-[500]">Comment</span>
                </button>
            </form>}
            {formType === 'editor' && <form method="post" className="discus-form" onSubmit={handleSubmit}>
                <div className='input input--outline overflow-auto max-h-[130px] text-editor-form'>
                    <CKEditor

                        editor={ClassicEditor}
                        config={editorConfig}
                        data={text}
                        onChange={handleEditorChange}
                        onReady={(editor) => {
                            setEditor(editor);
                        }}
                    />
                </div>
                <div className="discus-controls">
                    <div className="editor-tools" ref={toolbarRef}></div>
                    <div>
                        <button type="submit" className="btn btn--primary btn--lg radius-5 text-[14px]">
                            <p>Comment</p>
                        </button>
                    </div>
                </div>
            </form>}
        </div>
    );
};
export default CommentForm