import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getSearchResultsFetch = createAsyncThunk(
    '/getSearchResultsFetch/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.search.getSearchResults(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);