import { toast } from "react-toastify";

export const apiConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'X-Requested-With': 'XMLHttpRequest',
    }, 
    onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(percentCompleted)
        // // Обновляем прогресс в состоянии
        // store.dispatch(setUploadProgress(percentCompleted));

        // Обновляем toast с прогрессом
        toast.update('uploadProgress', {
          render: `Uploading: ${percentCompleted}%`,
          progress: percentCompleted / 100,
        });

        // Закрываем toast, когда достигли 100%
        if (percentCompleted === 100) {
          setTimeout(() => {
            toast.dismiss('uploadProgress');
          }, 500); // Небольшая задержка для завершения анимации
        }
    }, 
}

export const getAxiosConfig = () => {
    return {
      
    };
  };