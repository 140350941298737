import { useEffect, useState } from "react";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import SortableList from "../../components/parts/SortableList";
import AddQuestionModal from "../../components/Modals/AddQuestionModal";

import { ReactComponent as PlusIcon } from "../../assets/img/ico-plus-2.svg"
import SortVerticalPart from "../../components/parts/SortVerticalPart";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { addQuizQuestionsOrderingByQuizIdFeth, deleteQuizQuestionsByQuestionIdFeth, getQuizQuestionsByQuizIdFeth } from "../../store/quizzes/quizzesThunks";
import { setActiveQuestionId } from "../../store/quizzes/quizzesSlice";
import Loader from "../../components/UI/Loader";
import { setHasEditChanges } from "../../store/auth/authSlice";

export const QuizCreateQuiz = () => {
    const {id} = useParams()
    const dispach = useDispatch()
    const navigate = useNavigate();
    const {questions, isLoading} = useSelector((state) => state.quizzes)
    const [quizId, quiz] = useOutletContext();
    const [addQuestionModal, setAddQuestionModal] = useState(false);
    
    const [data, setData] = useState([]);

    const questionEditHandler = (id) => {
        dispach(setActiveQuestionId(id))
        setAddQuestionModal(true)
    }
    const continueHandler = async () => {
        const orderingData = data.map(item => ({
            questionId: item.id,
            order: item.order
        }))
        
        const res = await  dispach(addQuizQuestionsOrderingByQuizIdFeth({id: quizId, body: {
            "ordering" : orderingData
        }})).unwrap()
        if(res) {
            if(id) {
                navigate(`/quiz-edit/${id}/settings`)
            } else {
                navigate('/quiz-create/settings')
            }
        }
        dispach(setHasEditChanges(false))
    }
    const handleAddQuestionModal = () => {
        setAddQuestionModal(true)
    }
    const conf = {
        title: 'Quiz',
        btnTitle: 'Add Question',
        btnAction: () => {
            dispach(setActiveQuestionId(null))
            handleAddQuestionModal()
        }
    }
    useEffect(() => {
        setData(questions)
    },[questions])
    useEffect(() => {
        if(!quizId) return
        dispach(getQuizQuestionsByQuizIdFeth(quizId)).unwrap()
    },[quizId])
    if (isLoading) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader/>
        </div>
    )
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Questions ({data?.length})</p>
                                                <div className="w-full">
                                                    {data?.length > 0 && <SortVerticalPart items={data} setItems={(e) => {
                                                        dispach(setHasEditChanges(true))
                                                        setData(e)
                                                    }} editHandler={questionEditHandler} />}
                                                    <button
                                                        onClick={() => {
                                                            dispach(setActiveQuestionId(null))
                                                            handleAddQuestionModal()
                                                        }}
                                                        className="btn btn--primary btn--xs radius-5 gap-[10px]">
                                                        <span className="ico">
                                                            <PlusIcon />
                                                        </span>
                                                        <span className="info text--sm">Add Question</span>
                                                    </button>
                                                    <p className="text--md color-secondary mt-[16px]">Add more questions
                                                        with correct answers to create complex quiz</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/quiz'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button onClick={continueHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4163 10.9998H4.58301" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 15.5833L17.4163 11" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 6.4165L17.4163 10.9998" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>

                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {addQuestionModal && <AddQuestionModal open={addQuestionModal} handelClose={() => setAddQuestionModal(false)} />}
        </>
    )
}