const usersBase = 'api/users';

export const users = (instance) => ({
    async getMe(payload) {
        try {
            const { data } = await instance.get(`${usersBase}/me`);
            return data
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async getAllUsers(payload) {
        try {
            const { data } = await instance.get(`${usersBase}/me`);
            return data
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async getUser({ userId }) {
        try {
            const { data } = await instance.get(`${usersBase}/${userId}`);
            return data
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async updateProfile(payload) {
        try {
            const { data } = await instance.put(`${usersBase}/me`, payload);
            return data
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },


});