const base = 'api/events';

export const events = (instance) => ({
    async getAllEvents(query = "") {
        try {
            const { data } = await instance.get(`${base}?${query}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async getEventCountByDate(query = "") {
        try {
            const { data } = await instance.get(`${base}/by-days?${query}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async getEventById({ id }) {
        try {
            const { data } = await instance.get(`${base}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async viewEventById({ id }) {
        try {
            const { data } = await instance.post(`${base}/view/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async bookEventById({ id, body }) {
        try {
            const { data } = await instance.post(`${base}/book/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },



});