import React, { useState, useEffect, useRef } from 'react';

const AutoResizeTextarea = ({ value, onChange, ...props }) => {
//   const [height, setHeight] = useState('auto');
  const textareaRef = useRef(null);
  const [minHeight, setMinHeight] = useState(0);

  useEffect(() => {
    if (textareaRef.current) {
        const textarea = textareaRef.current;
  
        // Сначала сбрасываем высоту на 'auto', чтобы получить корректный scrollHeight
        textarea.style.height = 'auto';
  
        // Устанавливаем высоту на основе scrollHeight
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
  }, [value]);

  return (
    <textarea
      {...props}
      ref={textareaRef}
      placeholder="Leave your comment here..."
      className='textarea'
      style={{
        overflow: 'hidden',
        resize: 'none', // Отключение возможности изменения размера пользователем
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default AutoResizeTextarea;