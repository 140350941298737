import React from 'react'
import Actions from './Actions'
import { Link } from 'react-router-dom'
import { setShowLoginModal } from '../../../store/auth/authSlice'
import { useDispatch } from 'react-redux'
import { getDateToString, getImageSrc } from '../../../helpers/utils'
import { favoriteCaseByIdToggleFeth, likeCaseByIdToggleFeth } from '../../../store/cases/casesThunk'

const CardCase = ({ data, isList, authorized, hideActions = false }) => {
    const dispatch = useDispatch();
    
    const getCategory = (array) => {
      
        if (array) {
            
            const category = array.filter(item => item.type === 'diagnosis')
            console.log(array, category)
            const generateClass = category[0].name === 'Diagnosis Certain' ? '--success' :
                                  category[0].name === 'Diagnosis almost certain' ? '--warn-2' :
                                  category[0].name === 'Diagnosis probable' ? '--warn' : ''
            return { name: category[0].name, class: generateClass }
        }
    }

    const toggleLike = () => {
        if (authorized) {
            dispatch(likeCaseByIdToggleFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true))
        }
    }
    const toggleFavorite = () => {
        if (authorized) {
            dispatch(favoriteCaseByIdToggleFeth(data.id))
        } else {
            dispatch(setShowLoginModal(true))
        }
    }

    return (
        <>
            {
                !isList ?
                    <div className="card card-post">
                        <div className="card-header post-image wrapp-image-sm">
                            <Link
                                className="flex w-full"
                                to={`${authorized ? `/cases/${data?.id}/` : '#'}`}
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }}
                            >
                                {data?.cover && <img style={{ width: '100%' }} src={getImageSrc(data?.cover)} />}
                                {!data?.isCompleted && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}
                            </Link>
                            {!hideActions && <Actions
                                handleLikeProps={toggleLike}
                                handleAddToFavoritesProps={toggleFavorite}
                                isFavoriteProps={data?.isFavorite}
                                isLikeProps={data?.isLiked}
                            />}
                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <div className="group-between">
                                    <div className={`pill-custom pill-2 pill-status ${getCategory(data?.filters)?.class}`}>
                                        <span className="pill-text">{getCategory(data?.filters)?.name}</span>
                                    </div>
                                </div>
                                <Link
                                    to={`${authorized ? `/cases/${data?.id}/` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}
                                >
                                    <h5 className="heading post-title">{data?.name}</h5>
                                </Link>
                                <div className="post-others">
                                    <p className="text-[13px] leading-[16px]">
                                        by {" "}
                                        <Link to={`/profile/${data?.user?.id}`}>
                                            <span className="color-danger">{(data?.user?.firstName || '') + ' ' + (data?.user?.lastName || '')}</span>
                                        </Link>
                                    </p>
                                    <p className="mini-post--desk">{getDateToString(data?.createdAt)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="card card-post --card-row card-post--md">
                        <div className="card-header post-image">
                            <Link
                                to={`${authorized ? `/cases/${data?.id}/` : '#'}`}
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }}
                                className="flex w-full"
                            >
                                {data?.cover && <img style={{ width: '100%' }} src={getImageSrc(data?.cover)} />}
                                {!data?.isCompleted && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}

                            </Link>
                            <Actions
                                handleLikeProps={toggleLike}
                                handleAddToFavoritesProps={toggleFavorite}
                                isFavoriteProps={data?.isFavorite}
                                isLikeProps={data?.isLiked}
                            />
                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <Link
                                    to={`${authorized ? `/cases/${data?.id}/` : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}
                                >
                                    <h4 className="heading post-title">{data?.name}</h4>
                                </Link>
                                <div className="post-others">
                                    <p className="text-[14px]">
                                        by {" "}
                                        <Link to={`/profile/${data?.user?.id}`}>
                                            <span className="color-danger">{(data?.user?.firstName || '') + ' ' + (data?.user?.lastName || '')}</span>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="card-body--item max-md:hidden">
                                <p className="post--desk">{data?.description}</p>
                            </div>
                            <div className="card-body--item">
                                <div className="group-between">
                                    <div className="group-between--item">
                                        <div className={`pill-custom pill-2 pill-status ${getCategory(data?.filters)?.class}`}>
                                            <span className="pill-text">{getCategory(data?.filters)?.name}</span>
                                        </div>
                                        <p className="mini-post--desk">{getDateToString(data?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default CardCase