import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { viewFCSetBySetIdFetch } from '../../../store/flashcards/flashcardsThunks';

const FlashcardPageDescription = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const { flashCardSet, isLoading } = useSelector(state => state.flashcards)
    useEffect(() => {
        if(id) {
            dispatch(viewFCSetBySetIdFetch(id))
        }
    } , [id])
    return (
        <main className="content">
            <div className="quiz">
                <div className="quiz__content">
                    <div className="quiz__body pt-[60px] pb-[60px]">
                        <div className="container">
                            <div className="mb-[47px]">
                                <p className="quiz__label mb-[13px]">Description</p>
                                <div className="quiz__text" style={{ whiteSpace: 'pre-wrap'}}>{flashCardSet?.description}</div>
                            </div>
                            <Link to={"flashcards/start"} className="btn btn--primary btn--md radius-5 gap-[12px]">
                                <span className="info text--md">Continue</span>
                                <span className="ico">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8332 10H4.1665" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M11.6665 14.1667L15.8332 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M11.6665 5.83334L15.8332 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default FlashcardPageDescription