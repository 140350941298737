import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useDebounce from "../../hooks/useDebounce";
import { createModeratorFeth, getUsersForAssignAsModeratorByForumIdFeth } from "../../store/forums/forumsThunk";
import SelectGroup from "../UI/formElements/SelectGroup";
import ModalTemplate from "./ModalTemplate";
import ButtonLoader from "../UI/ButtonLoader";
import SelectGroupAsynk from "../UI/formElements/SelectGroupAsynk";

const LIMIT = 10

export const AddModeratorModal = ({ open, handelClose }) => { 
    const dispatch = useDispatch()
    const [inviteBy, setInviteBy] = useState('email')
    const { id } = useParams()
    const { forumId, forumUsersByMeders, isModeratorsLoading, paginationModerators } = useSelector(state => state.forums)
    const [message, setMessage] = useState('')
    const [names, setNames] = useState([])
    const [emails, setEmails] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    // const moderators = forumUsersByMeders?.map(moder => ({ label: `${moder.firstName} ${moder.lastName}`, value: moder.id }))

    const loadOptionsModerators = async (search, loadedOptions, { page }) => {
        const res = await dispatch(getUsersForAssignAsModeratorByForumIdFeth({ id: forumId, qwery: `page=${page}&limit=${LIMIT}&search=${search}` })).unwrap();

        return {
            options: res.data?.map(moder => ({ label: `${moder.firstName} ${moder.lastName}`, value: moder.id })),
            hasMore: page != res?.pagination?.maxPages, // флаг, показывающий, есть ли еще данные
            additional: {
                page: page + 1, // увеличиваем номер страницы для следующего запроса
            },
        };
    };
   

    function validateEmails(emails) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emails.every(email => emailRegex.test(email));
    }
    const submitHandler = async () => {
        const formData = {
            message: message,
            userIds: names.map(name => name.value),
            emails: emails.split(',')
        }
        if (inviteBy === 'email' && !validateEmails(formData.emails)) {
            toast.error('Emails is not valid')
            return
        }
        setIsLoading(true)
        if (inviteBy === 'email') {
            delete formData.userIds

        }
        if (message.length === 0) {
            delete formData.message
        }
        if (inviteBy === 'name') {
            delete formData.emails
        }
        console.log(formData)
        const res = await dispatch(createModeratorFeth({ id: forumId, body: formData })).unwrap()
        if (res) {
            console.log(res)
            handelClose()
        }
        setIsLoading(false)
    }
   

    return (
        <ModalTemplate open={open} handelClose={handelClose} modalSize={'modal__dialog--870'} >
            <>
                <div className="modal__body items-center pt-0">
                    <div className="modal__body-heading text-center">
                        <h2>Invite Moderator</h2>
                    </div>
                    <div className="list-group list-group--lg w-full">

                        <div className="form-group-row ">
                            <span className="form-group-row__label h-auto justify-end"></span>
                            <div className="list-group list-group--xs flex-auto">
                                <div className="row-group gap--xxs">
                                    <label className="radio">
                                        <input type="radio" className="hidden" name="invite_by"
                                            onClick={() => setInviteBy('email')}
                                            checked={inviteBy === 'email'} readOnly />
                                        <span className="btn btn--xs btn--radio rounded-full">
                                            <span className="text--sm font-medium">Invite by email</span>
                                        </span>
                                    </label>
                                    <label className="radio">
                                        <input type="radio" className="hidden" name="invite_by"
                                            onClick={() => setInviteBy('name')}
                                            checked={inviteBy === 'name'} readOnly />
                                        <span className="btn btn--xs btn--radio rounded-full">
                                            <span className="text--sm font-medium">Invite by name</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {inviteBy === 'email' && <div className="form-group-row ">
                            <span className="form-group-row__label justify-end">Email</span>
                            <div className="list-group list-group--xs flex-auto">
                                <div className="actions-group">
                                    <div className="input-wrapper input--md flex-auto">
                                        <input
                                            className="input input--outline"
                                            type="text"
                                            value={emails}
                                            onChange={({ target }) => setEmails(target.value)}
                                            placeholder="Enter user email address(es)..." />
                                    </div>
                                </div>
                                <p className="ff-2 text--xs color-placeholders">For multiple addresses please separate
                                    with “,”</p>
                            </div>
                        </div>}
                        {inviteBy === 'name' && <div className="form-group-row ">
                            <span className="form-group-row__label justify-end">Names</span>
                            <div className="list-group list-group--xs flex-auto">
                                <div className="actions-group">
                                    <SelectGroupAsynk
                                        placeholder={'Select users'}
                                        wrapperClasses="input--md flex-auto select--outline"
                                        defaultValue={names}
                                        onChange={setNames}
                                        isMulti
                                        fetchFunk={loadOptionsModerators}
                                    />
                                </div>
                            </div>
                        </div>}
                        <div className="list-group list-group--md">
                            <div className="form-group-row ">
                                <span className="form-group-row__label justify-end">Additional Message</span>
                                <div className="list-group list-group--xs flex-auto">
                                    <div className="input-wrapper input--md flex">
                                        <textarea
                                            rows="8"
                                            value={message}
                                            onChange={({ target }) => setMessage(target.value)}
                                            className="textarea input--outline py-[16px]"
                                            style={{ resize: 'none' }}
                                            placeholder="Enter your description..."></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal__footer">
                    <button onClick={handelClose} disabled={isLoading} className="btn btn--shadow btn--md radius-5">
                       {isLoading ? <ButtonLoader/> : 'Cancel'}
                    </button>
                    <button
                        onClick={submitHandler}
                        disabled={isLoading}
                        className={`btn btn--primary btn--md radius-5 ml-auto 
                            ${((names.length === 0 && inviteBy === 'name') || (emails.length === 0 && inviteBy === 'email')) ? 'disabled' : ''}`}
                    >                        
                        {isLoading ? <ButtonLoader/> : 'Send Invites'}
                    </button>
                </div>
            </>
        </ModalTemplate>
    )
}