import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import { AddRuleModal } from "../../components/Modals/AddRuleModal";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../components/UI/Loader";
import SortRules from "../../components/parts/SortRules";
import { setRule, setRuleId, setRules } from "../../store/forums/forumsSlice";
import { ReactComponent as ArrowRightIcon } from '../../assets/img/ico-arrow-right.svg'
import { rulesOrderingByForumIdFeth } from "../../store/forums/forumsThunk";

export const ForumRules = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openAddRule, setOpenAddRule] = useState(false)
    const { forumOne, forumId, isLoading } = useSelector(state => state.forums)
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(forumOne?.rules)
    }, [forumOne])

    const openModal = () => {
        setOpenAddRule(true)
    }
    const ruleEditHandler = (id) => {
        dispatch(setRuleId(id));
        dispatch(setRule(...forumOne?.rules.filter(item => item.id === id)))
        setOpenAddRule(true)
    }
    const clearRule = () => {
        dispatch(setRule(null))
        dispatch(setRuleId(null))
    }
    const closeModal = () => {
        setOpenAddRule(false)
    }
    const submitHandler = async () => {
        const orderingData = data.map((item, index) => ({
            id: item.id,
            order: index
        }))
        const res = await dispatch(rulesOrderingByForumIdFeth({
            id: forumId, body: {
                "ordering": orderingData
            }
        })).unwrap()
        if (res) {
            dispatch(setRules(data))
            if (id) {
                navigate(`/forum/${id}/moderators`)
            } else {
                navigate(`/forum/moderators`)
            }
        }
    }
    const conf = {
        title: 'Rules',
        btnTitle: 'Add rule',
        btnAction: () => {
            openModal()
            clearRule()
        }
    }
    if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
        <Loader />
    </div>
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content pt-[20px]">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Rules
                                                    ({data?.length})</p>
                                                <div className="w-full overflow-hidden">
                                                    {data?.length > 0 ?
                                                        <SortRules items={data} setItems={setData} editHandler={ruleEditHandler} />
                                                        : null}
                                                    <button onClick={openModal}
                                                        className="btn btn--primary btn--xs radius-5 gap-[10px]">
                                                        <span className="ico">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_264_6175)">
                                                                    <path d="M3.33301 10H16.6663" stroke="#191919"
                                                                        strokeWidth="1.5" strokeLinecap="round"
                                                                        strokeLinejoin="round"></path>
                                                                    <path d="M10 3.3335V16.6668" stroke="#191919" strokeWidth="1.5"
                                                                        strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_264_6175">
                                                                        <rect width="20" height="20" fill="white"></rect>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span className="info text--sm">Add Rule</span>
                                                    </button>
                                                    <p className="text--md color-secondary mt-[16px]">Add rules to your
                                                        forum to regulate users subscribed to your forum</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/forums'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <ArrowRightIcon />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <AddRuleModal open={openAddRule} handleClose={closeModal} />

            </div>
        </>
    )
}
