import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import usersReducer from './users/usersSlice';
import pagesReducer from './pages/pagesSlice';
import contactUsReducer from './contactUs/contactSlice';
import flashcardsSlice from './flashcards/flashcardsSlice';
import filtersReduser from './filters/filtersSlice';
import quizzesReduser from './quizzes/quizzesSlice';
import articlesReduser from './articles/articlesSlice';
import materialLibraryReduser from './materials/materialLibrarySlice';
import commentsReduser from './comments/commentsSlise';
import casesReduser from './cases/casesSlice';
import coursesReduser from './courses/coursesSlice';
import profileReduser from './profile/profileSlice';
import paymantReduser from './paymant/paymantSlice';
import subscriptionPlanReduser from './subscriptionPlan/subscriptionPlansSlice';
import notificationsReduser from './notifications/notificationsSlice';
import chatReduser from './chat/chatSlice';
import forumsReduser from './forums/forumsSlice';
import eventsSlice from './events/eventsSlice';
import searchSlice from './search/searchSlice';
import chartsSlice from './charts/chartsSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        pages: pagesReducer,
        contactUs: contactUsReducer,
        flashcards: flashcardsSlice,
        filters: filtersReduser,
        quizzes: quizzesReduser,
        articles: articlesReduser,
        materialLibrary: materialLibraryReduser,
        comments: commentsReduser,
        cases: casesReduser,
        courses: coursesReduser,
        profile: profileReduser,
        paymant: paymantReduser,
        subscriptionPlan: subscriptionPlanReduser,
        notifications: notificationsReduser,
        chat: chatReduser,
        forums: forumsReduser,
        events: eventsSlice,
        search: searchSlice,
        charts: chartsSlice,
    },
});

export default store;