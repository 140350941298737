import { createSlice } from '@reduxjs/toolkit';
import { addSettingsToArticleByArticleIdFeth, authorPublishOwnChangesFeth, createNewArticleFeth, editArticleByIdFeth, editArticleSettingsByArticleIdFeth, getAllArticlesFeth, getArticleByIdFeth, getArticlesHistoryFeth, getArticlesVersiunsForApprovalFeth, getRelatedQuizzesListFeth, getUsersListFeth, likeArticleByArticleIdFeth, sendArticleToAuthorModerationFeth, toggleFavoriteArticleByArticleIdFeth } from './articlesThunks';

const initialState = {
    articlesAll: [],
    articleId: null,
    article: null,
    relatedArticles: null,
    isLoading: false,
    isModeratorsLoading: false,
    isQuizLoading: false,
    articleHistory: [],
    articleUsersList: [],
    suggetsArtikles: [],
    errors: null,
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    },
    paginationQuiz: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    }
}

const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {
        setClearArticle: (state, action) => {
            state.article = null
            state.articleId = null
        },
        setArticleId: (state, action) => {
            state.articleId = action.payload
        }
    },

    extraReducers(builder) {
        builder
            .addCase(getAllArticlesFeth.fulfilled, (state, action) => {
                state.isLoading = false
                
                state.articlesAll = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllArticlesFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getAllArticlesFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getArticlesVersiunsForApprovalFeth.fulfilled, (state, action) => {
                state.isLoading = false
                
                state.suggetsArtikles = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getArticlesVersiunsForApprovalFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getArticlesVersiunsForApprovalFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getArticlesHistoryFeth.fulfilled, (state, action) => {
                state.isLoading = false
                
                state.articleHistory = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getArticlesHistoryFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getArticlesHistoryFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(authorPublishOwnChangesFeth.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(authorPublishOwnChangesFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(authorPublishOwnChangesFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getUsersListFeth.fulfilled, (state, action) => {
                state.isModeratorsLoading = false
                state.articleUsersList = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getUsersListFeth.pending, (state, action) => {
                state.isModeratorsLoading = true
                state.errors = null;
            })
            .addCase(getUsersListFeth.rejected, (state, action) => {
                state.isModeratorsLoading = false
                state.errors = action.payload;
            })
            .addCase(getArticleByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload) {
                    state.article = action.payload
                    state.articleId = action.payload.id
                }

            })
            .addCase(getArticleByIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(getArticleByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(createNewArticleFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.article = action.payload
                state.articleId = action.payload.id
            })
            .addCase(createNewArticleFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(createNewArticleFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editArticleByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.article = {...state.article,...action.payload}
            })
            .addCase(editArticleByIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editArticleByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(getRelatedQuizzesListFeth.fulfilled, (state, action) => {
                state.isQuizLoading = false
                state.relatedArticles = action.payload.data
                state.paginationQuiz= action.payload.pagination
            })
            .addCase(getRelatedQuizzesListFeth.pending, (state, action) => {
                state.isQuizLoading = true
                state.errors = null
            })
            .addCase(getRelatedQuizzesListFeth.rejected, (state, action) => {
                state.isQuizLoading = false
                state.errors = action.payload
            })
            .addCase(addSettingsToArticleByArticleIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.article = {
                    ...state.article,
                    ...action.payload
                }
            })
            .addCase(addSettingsToArticleByArticleIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(addSettingsToArticleByArticleIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(editArticleSettingsByArticleIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.article = {
                    ...state.article,
                    ...action.payload
                }
            })
            .addCase(editArticleSettingsByArticleIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.errors = null
            })
            .addCase(editArticleSettingsByArticleIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            .addCase(likeArticleByArticleIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.articlesAll.find(item => item.id === action.payload.id);
                if(currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes
                }
                if( state.article) {
                    state.article.isLiked = action.payload.isLiked
                    state.article.likes = action.payload.likes
                }
            })
            .addCase(toggleFavoriteArticleByArticleIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.articlesAll.find(item => item.id === action.payload.id);
                if(currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }
                if(state.article) {
                    state.article.isFavorite = action.payload.isFavorite
                }
                
            })
            .addCase(sendArticleToAuthorModerationFeth.fulfilled, (state, action) => {
                state.article = action.payload
            })
    }
})

export const {
    setClearArticle,
    setArticleId
} = articlesSlice.actions;


export default articlesSlice.reducer