import React, { useEffect, useState } from 'react'
import { CreateFormHeader } from '../../components/parts/CreateFormHeader'
import InputGroupRow from '../../components/UI/formElements/InputGroupRow'
import TextareaGroupRow from '../../components/UI/formElements/TextareaGroupRow'
import UploadImage from '../../components/UI/UploadImage'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { getFilterDiagnosis, getFilteres, getIsLoadingFilters } from '../../store/filters/filtersSlice'
import { useSelector } from 'react-redux'
import Loader from '../../components/UI/Loader'
import { getFiltersByName, getFiltersDiagnosisFeth } from '../../store/filters/filtersThukns'
import { getFormData, getImageSrc } from '../../helpers/utils'
import { useValidation } from '../../hooks/useValidation'
import SelectGroupRow from '../../components/UI/formElements/SelectGroupRow'
import { createCaseFeth, editCaseByIdFeth } from '../../store/cases/casesThunk'
import { setHasEditChanges } from '../../store/auth/authSlice'

const CaseCreateGeneral = () => {
    const conf = {
        title: 'General Information',
    }
    const {id} = useParams()

    const navigate = useNavigate();   
    const dispach = useDispatch()
    const [caseOne, caseId] = useOutletContext();
    const {isLoading} = useSelector(state => state.cases)
    const systems = useSelector(getFilteres);
    const diagnosisFilters = useSelector(getFilterDiagnosis);
    const isLoadingFilters = useSelector(getIsLoadingFilters)
    const [formData, setFormData] = useState({
        cover: null,
        name: '',
        description: '',
        system: null,
        diagnosis: null,
    })
        
    const categotyes = systems ? systems.map(item => ({ value: item?.name, label: item?.name })) : null;
    const categotyesDiagnosis = diagnosisFilters ? diagnosisFilters.map(item => ({ value: item?.name, label: item?.name })) : null;
    
    
    useEffect(() => {
        if(caseOne) {
            setFormData({
                cover: {
                    preview: getImageSrc(caseOne?.cover),
                    type:  `image/${ caseOne?.cover.split('.').pop()}`
                },
                name: caseOne?.name,
                description: caseOne?.description,
                system: caseOne?.filters?.filter(item => item.type === 'system')[0]?.name,
                diagnosis: caseOne?.filters?.filter(item => item.type === 'diagnosis')[0]?.name,
            })
        }

    }, [caseOne])
    
    const validators = {
        name: value => value?.length > 1 ? true : 'Name must be more than 1 characters',
        description: value => value?.length > 5 ? true : 'Description must be more than 5 characters',
        cover: file => {
            if (!file) {
                return 'Cover is required';
            }
            if (!file?.type?.startsWith('image/')) {
                return 'Photo must be an image file (jpg, jpeg, png, gif)';
            }

            return true;
        },
        system: value => value ? true : 'System is required',
        diagnosis: value => value ? true : 'Diagnosis is required',
    };
    const messages = {
        name: 'First name is required',
        description: 'Description is required',
        cover: 'Cover is required',
        system: 'System is required',
        diagnosis: 'Diagnosis is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(
        formData,
        {
            validators,
            messages
        }
    );
    const formDataHandler = (name, value) => {
        dispach(setHasEditChanges(true)) 
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    useEffect(() => {
        dispach(getFiltersByName('system'))
        dispach(getFiltersDiagnosisFeth())
    }, [dispach])
    
    const submitHandler = async (e) => {
        e.preventDefault();
        if (errors) {
            
            setIsErrorShown(true)
            return
        }
        const body = getFormData(formData);
        
        let res = null
        if (caseId) {
            res = await dispach(editCaseByIdFeth({ id: caseId, body })).unwrap()
        } else {
            res = await dispach(createCaseFeth(body)).unwrap()

        }
        if (res) {
            if(id) {
                navigate(`/case-edit/${id}/images-videos`)
            } else {
                navigate(`/case-create/images-videos`)
            } 
            setIsErrorShown(false);
            dispach(setHasEditChanges(false)) 
        }
    }
    const getFilterDefault = (item) => {
        
        return item ? {value: item[0].name, label: item[0].name} : null
    }
    if (isLoading || isLoadingFilters) return  <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
    <Loader/>
</div>
    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group items-center gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Cases Cover</p>
                                                <UploadImage 
                                                    onChange={(image) => {
                                                        formDataHandler('cover', ...image)
                                                    }}
                                                    defaulImages={formData?.cover ? [formData?.cover] : []}
                                                    error={isErrorShown && errors?.cover}
                                                    errorMessage={errorMessages?.cover}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <InputGroupRow
                                                label={"Case Name"}
                                                inputClasses={'input--outline'}
                                                placeholder={'Enter case name...'}
                                                defaultValue={caseOne?.name}
                                                onChange={({ target }) => formDataHandler('name', target.value)}
                                                error={isErrorShown && errors?.name}
                                                errorMessage={errorMessages?.name}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Category'}
                                                placeholder={'Select Category'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                options={categotyes}
                                                defaultValue={getFilterDefault(caseOne?.filters?.filter(item => item.type === 'system'))}
                                                onChange = {(option) => {formDataHandler('system', option.value)}}
                                                error={isErrorShown && errors?.system}
                                                errorMessage={errorMessages?.system}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Diagnosis'}
                                                placeholder={'Select Diagnosis category'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                options={categotyesDiagnosis}
                                                defaultValue={getFilterDefault(caseOne?.filters?.filter(item => item.type === 'diagnosis'))}
                                                onChange = {(option) => {formDataHandler('diagnosis', option.value)}}
                                                error={isErrorShown && errors?.diagnosis}
                                                errorMessage={errorMessages?.diagnosis}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextareaGroupRow
                                                label={'Case Presentation'}
                                                placeholder={"Enter Case Presentation..."}
                                                textareaClasses="input--outline"
                                                onChange={({ target }) => formDataHandler('description', target.value)}
                                                defaultValue={caseOne?.description}
                                                rows="7"
                                                error={isErrorShown && errors?.description}
                                                errorMessage={errorMessages?.description}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/cases'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4163 10.9998H4.58301" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 15.5833L17.4163 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 6.4165L17.4163 10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default CaseCreateGeneral
