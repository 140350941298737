import { useDispatch } from "react-redux";
import UploadImage from "../../components/UI/UploadImage";
import SelectGroupRow from "../../components/UI/formElements/SelectGroupRow";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getFilteres, getIsLoadingFilters } from "../../store/filters/filtersSlice";
import { useSelector } from "react-redux";
import { getIsLoading } from "../../store/quizzes/quizzesSlice";
import { useEffect, useState } from "react";
import { getFormData, getImageSrc } from "../../helpers/utils";
import { getFiltersByName } from "../../store/filters/filtersThukns";
import { useValidation } from "../../hooks/useValidation";
import Loader from "../../components/UI/Loader";
import InputGroupRow from "../../components/UI/formElements/InputGroupRow";
import TextareaGroupRow from "../../components/UI/formElements/TextareaGroupRow";
import { createQuizFeth, editQuizByIdFeth } from "../../store/quizzes/quizzesThunks";
import { setHasEditChanges } from "../../store/auth/authSlice";


export const QuizGeneralInformation = () => {
    const { id } = useParams()
    const dispach = useDispatch()
    const [quizId, quiz] = useOutletContext();
    const systems = useSelector(getFilteres);
    const isLoadingFilters = useSelector(getIsLoadingFilters)
    const navigate = useNavigate();
    const isLoading = useSelector(getIsLoading);
    const conf = {
        title: 'General Information',
    }
    const categotyes = systems ? systems.map(item => ({ value: item?.name, label: item?.name })) : null
    const [formData, setFormData] = useState({
        cover: null,
        name: quiz?.name,
        description: quiz?.description,
        system: quiz?.filter?.name,
        age: quiz?.age,
        gender: quiz?.gender
    })
    console.log(formData)
    useEffect(() => {
        if (quiz) {
            setFormData({
                cover: {
                    preview: getImageSrc(quiz?.cover),
                    type: `image/${quiz?.cover.split('.').pop()}`
                },
                name: quiz?.name,
                description: quiz?.description,
                system: quiz?.filter?.name,
                age: quiz?.age,
                gender: quiz?.gender
            })
        }

    }, [quiz])

    useEffect(() => {
        dispach(getFiltersByName('system'))
    }, [dispach])

    const validators = {
        name: value => value?.length > 1 ? true : 'Name must be more than 1 characters',
        description: value => value?.length > 5 ? true : 'Description must be more than 5 characters',
        cover: file => {
            if (!file) {
                return 'Cover is required';
            }
            if (!file?.type?.startsWith('image/')) {
                return 'Photo must be an image file (jpg, jpeg, png, gif)';
            }

            return true;
        },
        system: value => value ? true : 'System is required',
        age: value => {
            return value > 10 ? true : 'Age must be more than 10'
        },
        gender: value => value ? true : 'Gender is required',
    };
    const messages = {
        name: 'First name is required',
        description: 'Description is required',
        cover: 'Cover is required',
        system: 'System is required',
        age: 'Age is required',
        gender: 'Gender is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(
        formData,
        {
            validators,
            messages
        }
    );
    const formDataHandler = (name, value) => {
        
        dispach(setHasEditChanges(true))
        let newValue = value;
        if (name === 'description') {
            newValue = newValue.replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]/g, '')
        }
        if (name === 'age') {

            const numberTest = /^\d{0,3}(\.\d{0,2})?$/;

            // if (value.includes(',')) {
            //     value = value.replace(',', '.');
            //     newValue = value;
            // }
            if( !numberTest.test(value)) {
                newValue = newValue.slice(0, -1);
            }
        }
        setFormData(prev => ({
            ...prev,
            [name]: newValue
        }))
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if (errors) {
            console.log(errors)
            setIsErrorShown(true)
            return
        }
        const body = getFormData(formData);

        let res = null
        if (quizId) {
            res = await dispach(editQuizByIdFeth({ id: quizId, body })).unwrap()

        } else {
            res = await dispach(createQuizFeth(body)).unwrap()

        }
        if (res) {
            if (id) {
                navigate(`/quiz-edit/${id}/image`)
            } else {
                navigate('/quiz-create/image')
            }
            setIsErrorShown(false);
            dispach(setHasEditChanges(false))
        }
    }
    if (isLoading || isLoadingFilters) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader />
        </div>
    )
    return (
        <div>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group items-center gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Quiz
                                                    Cover</p>
                                                <UploadImage
                                                    onChange={(image) => {
                                                        formDataHandler('cover', ...image)
                                                    }}
                                                    defaulImages={formData?.cover ? [formData?.cover] : []}
                                                    error={isErrorShown && errors?.cover}
                                                    errorMessage={errorMessages?.cover}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <InputGroupRow
                                                label={"Quiz Name"}
                                                inputClasses={'input--outline'}
                                                placeholder={'Enter quize name...'}
                                                defaultValue={quiz?.name}
                                                onChange={({ target }) => formDataHandler('name', target.value)}
                                                error={isErrorShown && errors?.name}
                                                errorMessage={errorMessages?.name}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Category'}
                                                placeholder={'Select Category'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                options={categotyes}
                                                defaultValue={
                                                    quiz?.filter?.name
                                                        ? { value: quiz?.filter?.name, label: quiz?.filter?.name }
                                                        : null
                                                }
                                                onChange={(option) => { formDataHandler('system', option.value) }}
                                                error={isErrorShown && errors?.system}
                                                errorMessage={errorMessages?.system}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextareaGroupRow
                                                label={'Case Presentation'}
                                                placeholder={"Enter Case Presentation..."}
                                                textareaClasses="input--outline"
                                                onChange={({ target }) => formDataHandler('description', target.value)}
                                                value={formData?.description}
                                                rows="7"
                                                error={isErrorShown && errors?.description}
                                                errorMessage={errorMessages?.description}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <InputGroupRow
                                                label={"Patient Age"}
                                                type="text"
                                                // max={999.99}
                                                inputClasses={'input--outline'}
                                                placeholder={'Enter Patient Age name...'}
                                                value={formData?.age}
                                                // step={"0.1"}
                                                inputmode="decimal"
                                                onChange={({ target }) => formDataHandler('age', target.value)}
                                                error={isErrorShown && errors?.age}
                                                errorMessage={errorMessages?.age}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Patient Gender'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                options={[
                                                    { value: "female", label: "Female" },
                                                    { value: "male", label: "Male" },
                                                ]}
                                                placeholder={"Select gender"}
                                                defaultValue={
                                                    quiz?.gender
                                                        ? { value: quiz?.gender, label: quiz?.gender }
                                                        : null
                                                }
                                                onChange={(option) => { formDataHandler('gender', option.value) }}
                                                error={isErrorShown && errors?.gender}
                                                errorMessage={errorMessages?.gender}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/quiz'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type="button" onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4163 10.9998H4.58301" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 15.5833L17.4163 11" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 6.4165L17.4163 10.9998" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>

                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </div>
    )
}
