import React from 'react'

const InputGroupRow = ({ defaultValue, value, placeholder, inputClasses = '', wrapperClasse = '', label, tips, type = "text", error, errorMessage , ...props }) => {
    return (
        <div className={`form-group-row ${wrapperClasse}`}>
            <span className="form-group-row__label justify-end">{label}</span>
            <div className="list-group list-group--xs flex-auto">
                <div className="actions-group">
                    <div className="input-wrapper input--md flex-auto">
                        <input
                            className={`input ${inputClasses} ${error ? "input--error" : ""}`}
                            type={type}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            value={value}
                            {...props}
                        />
                    </div>
                </div>
                {tips && <p className="ff-2 text--xs color-placeholders">{tips}</p>}
                {
                    errorMessage && error ?
                        <p className='color-danger text--xs mt-1'>{errorMessage}</p>
                        :
                        null
                }
            </div>
        </div>
    )
}

export default InputGroupRow
