import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { formatDateToTime, getDateToString, getHeaderByDate, getImageSrc } from '../../../helpers/utils';
import { getMe } from '../../../store/auth/authSlice';
import Linkify from 'react-linkify';
import { Link, useParams } from 'react-router-dom';
import { chat } from '../../../api/parts/chat';
import { useDispatch } from 'react-redux';
import { messagesMarkAsReadFeth } from '../../../store/chat/chatThunk';
import { setSearchMessageId } from '../../../store/chat/chatSlice';

function isEmojiOnly(text) {
    // Регулярное выражение для проверки только эмодзи
    const emojiRegex = /^[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2B50}\u{2B55}\u{23E9}-\u{23F3}\u{231A}-\u{231B}\u{20E3}\u{2194}-\u{21AA}\u{2934}-\u{2935}\u{25AA}\u{25AB}\u{25B6}\u{25C0}\u{25FB}-\u{25FE}]+$/u;

    return emojiRegex.test(text);
}
const MessagesBody = ({ setLimets }) => {
    const { id } = useParams()
    const me = useSelector(getMe)
    const messagesEndRef = useRef(null);
   
    const containerRef = useRef(null);
    const messagesRefs = useRef({});
    const lastScrollTop = useRef(0);
    const dispatch = useDispatch()
    const { curretnChatMessages, allChats, searchMessageId } = useSelector(state => state.chat);
    const previousVisibleUnreadIds = useRef([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [isSearchMessage, setIsSearchMessage] = useState(null);
     const scrollToBottom = useCallback(() => {
        if (lastScrollTop.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight - lastScrollTop.current;
            lastScrollTop.current = 0
            return
        } else  {
            messagesEndRef.current?.scrollIntoView();
            return
        }        
    }, [curretnChatMessages]); 
    useEffect(() => {
        if(!searchMessageId) return
        setIsSearchMessage(searchMessageId)
    }, [searchMessageId])   
    const scrollToSearchMesage = () => {
        const message = document.querySelector('.search-message-ref')
        if(message) {
            message.scrollIntoView();
            setTimeout(() => {
                dispatch(setSearchMessageId(null))
            }, 200)
           
        }
    }
    const handleScroll = () => {
        const container = containerRef.current;
        if (container.scrollTop === 0 ) {
            lastScrollTop.current = container.scrollHeight - container.scrollTop;
            setLimets(prev => prev + 1)
        }
    };
    // useEffect(() => {
    //     messagesEndRef.current = null
    //     if (id) {
    //         const currentChat = allChats.filter(chat => chat.id === +id)
    //         if (currentChat[0].unreadMessagesCount > 0) {
    //             const element = document.querySelector('.unread-message');
    //             messagesEndRef.current = element
    //         } else {
    //             const bottomScroller = document.querySelector('.bottom-scroller')
    //             messagesEndRef.current = bottomScroller
    //         }
    //     }
    // }, [id])
    
    useEffect(() => {
        if(!searchMessageId) {
            scrollToBottom();
        } else {
            scrollToSearchMesage()
        }
       
    }, [curretnChatMessages, scrollToBottom]);

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);


    // calculate
    const setMineToTrue = curretnChatMessages.map(message => {
        if (message.user.id === me?.id) {
            return {
                ...message,
                isRead: true
            }
        } else {
            return message
        }
    })
    const unreadMessages = setMineToTrue.filter(message => !message.isRead);
    // const readMessages = setMineToTrue.filter(message => message.isRead);
    const groupedMessages = setMineToTrue.reduce((acc, message) => {
        const header = getHeaderByDate(message.createdAt);
        if (!acc[header]) {
            acc[header] = [];
        }
        acc[header].push(message);
        return acc;
    }, {});


    const normaliseMessages = Object.keys(groupedMessages).map(header => ({
        header,
        messages: groupedMessages[header],
    }));
    // const finaliseMessages = unreadMessages.length > 0 ? [
    //     ...normaliseMessages, {
    //         header: 'Undead Messages',
    //         messages: unreadMessages
    //     }
    // ] : normaliseMessages
    const componentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" >
            {text}
        </a>
    );

    // const handleDownload = async (url) => {
    //     try {
    //         // Запрос на получение файла
    //         const response = await fetch(url);
    //         if (!response.ok) throw new Error('Network response was not ok');

    //         // Получение Blob из ответа
    //         const blob = await response.blob();

    //         // Создание URL для Blob
    //         const blobUrl = URL.createObjectURL(blob);

    //         // Создание временной ссылки для скачивания
    //         const link = document.createElement('a');
    //         link.href = blobUrl;
    //         link.download = url.split('/').pop(); // Извлечение имени файла из URL
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //         URL.revokeObjectURL(blobUrl); // Освобождение ресурсов
    //     } catch (error) {
    //         console.error('Ошибка при загрузке файла:', error);
    //     }
    // };

    const sendAsReat = async (idsArray) => {
        if (idsArray.length > 0) {
            const res = await dispatch(messagesMarkAsReadFeth({ id: id, body: { "messageIds": idsArray } })).unwrap()

        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const visible = entries
                .filter(entry => entry.isIntersecting)
                .map(entry => +entry.target.dataset.id);
            const newUnreadIds = visible
            if (JSON.stringify(previousVisibleUnreadIds.current) !== JSON.stringify(newUnreadIds)) {
                sendAsReat(newUnreadIds)
                previousVisibleUnreadIds.current = newUnreadIds;
            }
        }, {
            root: containerRef.current,
            threshold: 1 // Измените по необходимости
        });

        Object.keys(messagesRefs.current).forEach(id => {
            if (messagesRefs.current[id]) {
                observer.observe(messagesRefs.current[id]);
            }
        });

        return () => {
            Object.keys(messagesRefs.current).forEach(id => {
                if (messagesRefs.current[id]) {
                    observer.unobserve(messagesRefs.current[id]);
                }
            });
        };
    }, [normaliseMessages]);

    return (
        <div className="chart-block__body" ref={containerRef}>
            {normaliseMessages && normaliseMessages.length > 0 ? normaliseMessages.map((messageGroup, index) => (
                <div className="chart-block__body-item " key={index} >
                    <div className="chart-block__body-title">
                        <span>{messageGroup.header}</span>
                    </div>
                    {messageGroup.messages.map((mesage, idx) => (
                        <React.Fragment key={idx}>
                            {messageGroup.messages.findIndex(item => !item.isRead) === idx ? <div className="chart-block__body-title"
                            ref={(allChats.find(chat => chat.id === +id)?.unreadMessagesCount) ? messagesEndRef : null}
                            >
                                <span>Unread messages</span>
                            </div> : null}
                            <div
                                data-id={mesage.id}
                                className={`chart-block-user-message 
                                ${mesage.isSystemMessage ? 'system-message' : mesage.user.id === me?.id ? 'user-message-send' : 'user-message-recived'} 
                                ${mesage.isRead ? '' : 'unread-message'}
                                ${searchMessageId === mesage.id ? 'search-message-ref' : ''}
                                ${isSearchMessage === mesage.id ? 'search-message-style' : ''}
                            `}
                                key={idx}
                                ref={mesage.isRead ? null : (el => messagesRefs.current[mesage.id] = el)}
                            >
                                {mesage.isSystemMessage ?
                                    <div className='system-message-content'>{mesage.content}</div>
                                    :
                                    <div className="chart-block-user-wrapp">
                                        <div className="user-chat-name text--xs">{`${mesage.user.firstName} ${mesage.user.lastName}`}</div>
                                        <div className="user-messages">
                                            {mesage.content !== null ?
                                                <div
                                                    className={`user-messages__text-mesage 
                                                ${isEmojiOnly(mesage.content) ? 'only-emoji-message' : ''}`}
                                                >
                                                    <Linkify componentDecorator={componentDecorator}>
                                                        {mesage.content}
                                                    </Linkify>
                                                </div> : null}
                                            {mesage.files.length > 0 ? mesage.files.map((file, indexFiles) => (
                                                <a
                                                    href={getImageSrc(file.fileName)}
                                                    // onClick={(e) => {
                                                    //     e.preventDefault()
                                                    //     handleDownload(getImageSrc(file.fileName))
                                                    // }}
                                                    target='_blank'
                                                    key={indexFiles}
                                                    className="user-messages__file"
                                                >
                                                    <div className="user-messages__file-icon">
                                                        <img src={require("../../../assets/img/messages-file-icon.svg").default} alt="" />
                                                    </div>
                                                    <div className="user-messages__file-content">
                                                        <div className="user-messages__file-name">{file.originalName}</div>
                                                        <div className="user-messages__file-date">{getDateToString(mesage.createdAt)}</div>
                                                    </div>
                                                </a>
                                            )) : null}
                                        </div>
                                        <div className="user-chat-time text--xs">{formatDateToTime(mesage.updatedAt || mesage.createdAt)}</div>
                                    </div>}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )) : <h5 className='text-center m-auto'>There are no messages in this conversation yet</h5>}
            <div className='bottom-scroller' 
            ref={(searchMessageId || allChats.find(chat => chat.id === +id)?.unreadMessagesCount) ? null : messagesEndRef}/> {/* Элемент для прокрутки */}
        </div>
    )
}

export default MessagesBody
