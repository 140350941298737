const usersBase = 'api/subscriptions';

export const subscriptionPlan = (instance) => ({
    async getSubscriptionPlans(payload) {
        try {
            const { data } = await instance.get(`${usersBase}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async subscribeByPlanId({ id, body }) {
        try {
            const { data } = await instance.post(`${usersBase}/subscribe/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async confirmSubscribe(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/subscribe-confirm`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async updateSubscribeByPlanId(id) {
        try {
            const { data } = await instance.patch(`${usersBase}/subscribe/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});