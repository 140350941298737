import { Plugin } from "ckeditor5";

export default class MyCustomPlugin extends Plugin {
    init() {
        const editor = this.editor;

        // Расширяем схему для элемента 'imageBlock'
        editor.model.schema.extend('imageBlock', {
            allowAttributes: ['data-chart', 'class']
        });

        // Конвертер для 'data-chart'
        editor.conversion.for('downcast').add(dispatcher => {
            dispatcher.on('attribute:dataChart:imageBlock', (evt, data, conversionApi) => {
                
                const viewWriter = conversionApi.writer;
                const viewImage = conversionApi.mapper.toViewElement(data.item);
                
                if (data.attributeNewValue !== null) {
                    viewWriter.setAttribute('data-chart', data.attributeNewValue, viewImage);
                } else {
                    viewWriter.removeAttribute('data-chart', viewImage);
                }
            });
        });

        // Конвертер для 'class'
        editor.conversion.for('downcast').add(dispatcher => {
            dispatcher.on('attribute:class:imageBlock', (evt, data, conversionApi) => {
                const viewWriter = conversionApi.writer;
                const viewImage = conversionApi.mapper.toViewElement(data.item);

                if (data.attributeNewValue) {
                    viewWriter.addClass(data.attributeNewValue, viewImage);
                } else {
                    viewWriter.removeClass(data.attributeNewValue, viewImage);
                }
            });
        });

        // Конвертер для upcast (модель -> представление)
        editor.conversion.for('upcast').elementToElement({
            view: 'img',
            model: (viewElement, modelWriter) => {
                const src = viewElement.getAttribute('src');
                const alt = viewElement.getAttribute('alt');
                const dataChart = viewElement.getAttribute('data-chart');
                const className = viewElement.getAttribute('class');

                return modelWriter.createElement('imageBlock', {
                    src,
                    alt,
                    'data-chart': dataChart,
                    class: className
                });
            }
        });
    }
}