import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { useClickOutside } from "../../hooks/useClickOutside";

/**
 * Dropdown component
 * 
 * @param {Object} props - Props for the Dropdown component.
 * @param {ReactNode} props.button - The button element that triggers the dropdown.
 * @param {ReactNode} props.body - The content of the dropdown body.
 * @param {string} [props.wrapperClass=""] - Additional classes for the dropdown wrapper.
 * @param {string} [props.bodyClass=""] - Additional classes for the dropdown body.
 * @param {Object} [props.style={}] - Inline styles for the dropdown component.
 * @param {Object} [props.options={}] - Configuration options for the component.
 * @param {string} [props.options.position='top-start'] - Positioning of the component.
 * @param {boolean} [props.options.autoPositions=true] - Automatically adjust the position if needed.
 * @param {boolean} [props.autoClose=false] - Automatically adjust the position if needed.
 * @param {boolean} [props.closeSelf=false] - Automatically adjust the position if needed.
 * @param {boolean} [props.options.insideoverflow=true] - Handle overflow inside the container.
 * @param {boolean} [props.options.corectionx=true] - Apply X-axis correction if necessary.
 * @param {number} [props.options.mobileGutters=15] - Gutter size for mobile devices in pixels.
 * 
 * @returns {JSX.Element} - The rendered Dropdown component.
 */
export const Dropdown = ({autoClose, closeSelf = false, button, body, wrapperClass = "", bodyClass = "", style, options = {} }) => {
    const {pathname} = useLocation()
    const [isOpen, setIsOpen] = useState(false)

    const wrapperRef = useRef(null)
    const menuRef = useRef(null)

    // const handleClose = (e) => {
    //     if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
    //         setIsOpen(false);
    //     }
    // }

    const calculatePosition = () => {
        if (!wrapperRef.current || !menuRef.current) return;

        const dropdownElem = wrapperRef.current;
        const menuElem = menuRef.current;
        const {
            position = 'top-start',
            autoPositions = true,
            insideoverflow = true,
            corectionx = true,
            mobileGutters = 15,
        } = options;

        const elementRect = dropdownElem.getBoundingClientRect();
        const menuRect = menuElem.getBoundingClientRect();

        let topPos = null;
        let leftPos = null;
        let transformY = 0;
        const transformX = 0;

        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        const guttersY = parseFloat(getComputedStyle(menuElem).marginTop) || 0;
        const widthDrop =
            menuRect.width > windowWidth - mobileGutters * 2
                ? windowWidth - mobileGutters * 2
                : menuRect.width;

        if (insideoverflow !== false) {
            topPos = elementRect.top + dropdownElem.clientHeight;
            leftPos = elementRect.left;
            if (position === 'top-end') {
                leftPos = elementRect.right - widthDrop;
            }
            if (position === 'bottom-start') {
                transformY = -(
                    menuRect.height +
                    dropdownElem.clientHeight +
                    guttersY * 2
                );
            }
            if (position === 'bottom-end') {
                leftPos = elementRect.right - widthDrop;
                transformY = -(
                    menuRect.height +
                    dropdownElem.clientHeight +
                    guttersY * 2
                );
            }
        }

        if (autoPositions) {
            if (
                windowHeight - elementRect.bottom - guttersY <
                menuRect.height &&
                elementRect.top > menuRect.height
            ) {
                transformY = -(
                    menuRect.height +
                    dropdownElem.clientHeight +
                    guttersY * 2
                );
            } else if (
                windowHeight - elementRect.bottom - guttersY <
                menuRect.height &&
                elementRect.top < menuRect.height
            ) {
                transformY = 0;
                topPos =
                    menuRect.height > windowHeight
                        ? guttersY
                        : windowHeight - menuRect.height - guttersY * 2;
            } else {
                transformY = 0;
            }
        }

        if (corectionx !== false) {
            if (
                elementRect.left + elementRect.width - mobileGutters <
                widthDrop &&
                (position === 'bottom-end' || position === 'top-end')
            ) {
                leftPos = mobileGutters;
            }
            if (
                windowWidth - (elementRect.left + mobileGutters) < widthDrop &&
                (position === 'top-start' || position === 'bottom-start')
            ) {
                leftPos = windowWidth - widthDrop - mobileGutters;
            }
        }

        Object.assign(menuElem.style, {
            top: `${topPos}px`,
            left: `${leftPos}px`,
            transform: `translate(${transformX}, ${transformY}px)`,
            width: `${widthDrop}px`,
            maxWidth: `${windowWidth - mobileGutters * 2}px`,
            maxHeight: `${windowHeight - mobileGutters * 2}px`,
        });
    };
    useEffect(() => {
        function scrollHandler(e) {
            setIsOpen(false)
        }
        window.addEventListener('scroll', scrollHandler)
        return () => {window.removeEventListener('scroll', scrollHandler)}
    }, [])
    useEffect(() => {
        if (isOpen) {
            calculatePosition();
        }
    }, [isOpen]);


    // useEffect(() => {
    //     if (isOpen) {
    //         document.addEventListener('click', handleClose)
    //     } else {
    //         document.removeEventListener('click', handleClose)
    //     }
    //     return () => document.removeEventListener('click', handleClose)
    // }, [isOpen])

    useClickOutside(menuRef, () => setIsOpen(false))


    useEffect(() => {
        setIsOpen(false)
    }, [pathname])
    useEffect(() => {
        if(autoClose) {
            setIsOpen(false)
        }
    }, [autoClose])


    return (
        <>
            <div
                ref={wrapperRef}
                onClick={() => setIsOpen(!isOpen)}
                className={`dropdown relative ${wrapperClass} ${isOpen ? 'dropdown-open' : ''}`}
                data-dropdown="dropdown"
                style={style}
            >
                {button}
            </div>
            {isOpen &&
                createPortal(
                    <div
                        className={`dropdown__body right-0 top-[100%] ${bodyClass} ${isOpen ? 'is-open' : ''}`}
                        onClick={() => {
                            if(closeSelf) {
                                setIsOpen(!isOpen)
                            }
                        }}
                        ref={menuRef}
                    >
                        {body}
                    </div>,
                    document.body,
                )}
        </>
    )
}