import React, { useEffect, useState } from 'react'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'

import SwitchViewType from '../../components/parts/SwitchViewType'

import CardMaterials from '../../components/parts/Cards/CardMaterials'

import { ReactComponent as PlusIcon } from "../../assets/img/ico-plus-2.svg"
import { filterOptions } from '../../helpers/constants'
import { AddMaterialModal } from '../../components/Modals/AddMaterialModal'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { getAllMaterialLibraryFeth } from '../../store/materials/materialLibraryThunks'
import PageLoader from '../../components/parts/PageLoader'
import { type } from '@testing-library/user-event/dist/type'
import { setShowLoginModal } from '../../store/auth/authSlice'
import { clearMaterial, setAddModalShow } from '../../store/materials/materialLibrarySlice'



const MaterialsLibrary = ({ tab }) => {
  const { authorized,  showLoginModal } = useSelector((state) => state.auth)
  const { materialsAll, isLoading, pagination, addModalShow } = useSelector(state => state.materialLibrary)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const systems = useSelector(getFilteres);
 
  // const [data, setData] = useState([])
  const [params, setParams] = useState({
    sort: 'latest',
    system: [],
  })
 
  const [isList, setIsList] = useState(false)
  const [addMaterialModal, setAddMaterialModal] = useState(false)
  const filters = [
    {
        title: "Filter by Categories",
        checkboxOptions: systems
    },
]
  const links = [
    {
      label: "Documents",
      to: "/materials",
      ico: (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.33325 11.9168H14.6666" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.33325 15.5833H14.6666" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M5.49996 2.75H13.9076C14.3935 2.75 14.86 2.94342 15.2038 3.28717L17.7961 5.8795C18.1399 6.22325 18.3333 6.68983 18.3333 7.17567V17.4167C18.3333 18.4296 17.5129 19.25 16.5 19.25H5.49996C4.48704 19.25 3.66663 18.4296 3.66663 17.4167V4.58333C3.66663 3.57042 4.48704 2.75 5.49996 2.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18.3333 7.33333H14.6667C14.1607 7.33333 13.75 6.92267 13.75 6.41667V2.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.33325 8.24984H10.0833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )
    },
    {
      label: "Videos",
      to: "/materials/videos",
      ico: (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M19.25 11V11C19.25 15.5568 15.5568 19.25 11 19.25V19.25C6.44325 19.25 2.75 15.5568 2.75 11V11C2.75 6.44325 6.44325 2.75 11 2.75V2.75C15.5568 2.75 19.25 6.44325 19.25 11Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path fillRule="evenodd" clipRule="evenodd" d="M10.0292 8.30293L13.5877 10.4076C14.0378 10.6734 14.0378 11.3252 13.5877 11.591L10.0292 13.6957C9.57091 13.967 8.99158 13.6361 8.99158 13.1035V8.89509C8.99158 8.36251 9.57091 8.03159 10.0292 8.30293V8.30293Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )
    },
    {
      label: "Images",
      to: "/materials/images",
      ico: (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.4315 10.8207L11.4318 10.8203C11.4993 10.7507 11.4976 10.6395 11.428 10.572C11.3584 10.5044 11.2472 10.5061 11.1796 10.5756C11.112 10.6452 11.1136 10.7564 11.1831 10.824C11.2526 10.8917 11.3638 10.8902 11.4315 10.8207" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M2.75 12.1919C3.04494 12.1465 3.3428 12.1227 3.6412 12.1206V12.1206C5.29574 12.1206 6.8825 12.7779 8.05243 13.9478C9.22237 15.1177 9.87962 16.7045 9.87962 18.359C9.87688 18.6574 9.85306 18.9552 9.80833 19.2502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <rect x="2.75" y="6.4165" width="12.8333" height="12.8333" rx="3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.5 6.41667V5.5C5.5 3.98122 6.73122 2.75 8.25 2.75H16.5C18.0188 2.75 19.25 3.98122 19.25 5.5V13.75C19.25 15.2688 18.0188 16.5 16.5 16.5H15.5833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )
    },

  ]
  const {pathname} = useLocation()
  useEffect(() => {
      setAddMaterialModal(false)
  }, [pathname])
  const addModalHandler = () => {
    
    setAddMaterialModal(prev => !prev)
    dispatch(setAddModalShow(false))
  }
  const handleFilterChange = (options) => {
    setParams((prevFilters) => {
      const system = prevFilters.system.includes(options.name)
        ? prevFilters.system.filter((cat) => cat !== options.name)
        : [...prevFilters.system, options.name];

      return {
        ...prevFilters,
        system
      };
    })
    setSearchParams({
      ...params,
      system: params.system.includes(options.name)
      ? params.system.filter((cat) => cat !== options.name).join(',')
      : [...params.system, options.name].join(','),
    });
  }
  const handleSortChange = (option) => {
    setParams(prev => ({
      ...prev,
      sort: option.value
    }))
    setSearchParams({
      ...params,
      sort: option.value,
    });
  }
  useEffect(() => {
    const systemParam = searchParams.get('system');

    const system = systemParam ? searchParams.get('system')?.split(',').filter(Boolean) : [];
    const sort = searchParams.get('sort') || 'latest';
    setParams({ system, sort});
  }, [searchParams])
  
  useEffect( () => {
    const type = tab === 'Images' ? 'image': tab === 'Video' ? 'video' : 'doc'
    const fetchData = async () => {
        
        let res = null
        res = await dispatch(getAllMaterialLibraryFeth(`type=${type}&${searchParams.toString()}`)).unwrap()
        if(res) {          
            window.scrollTo(0, 0)
        }
    }
    fetchData()
  }, [tab, params])

  useEffect(() => {
    dispatch(getFiltersByName('system'))
  }, [dispatch])
  
  return (
    <>
      <main className="content">
        <div className="container">
          <section className="section-filters">
            <SidebarFilters filters={filters} defaultCheked = {params?.system} links={links} onChange={handleFilterChange} />
            <div className="section-filters__body">
              <div className="page-heading mb-[30px]">
                <div className="page-heading__item">
                  <h3 className="mr-[24px]">Materials Library </h3>
                  <SwitchViewType onChange={(checked) => setIsList(checked)} />
                </div>
                <div className="page-heading__item">
                  <SelectGroup
                    wrapperClasses={'input--sm'}
                    options={filterOptions}
                    defaultValue={filterOptions[0]}
                    onChange={handleSortChange}
                  />
                  {tab && tab !== "Document" ?
                    <button type='button' 
                    onClick={
                      () => {
                        if (authorized) {
                          dispatch(clearMaterial())
                          addModalHandler()
                        } else {
                          dispatch(setShowLoginModal(true))
                        }
                      }} className="btn btn--primary btn--md radius-5"
                    >
                      <span className="ico ico-22 mr-1">
                        <PlusIcon />
                      </span>
                      <span>Add {tab}</span>
                    </button>
                    :
                    null
                  }
                </div>
              </div>
              <div className="row gutters-cards flex-auto">
                {
                  !isLoading && materialsAll && materialsAll.length ?
                    materialsAll.map(card => (
                      <div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"} >

                        <CardMaterials data={card} isList={isList} isDocument={tab === "Document"} isVideo={tab === "Video"} authorized={authorized} />
                      </div>
                    ))
                    : isLoading  ?
                      <PageLoader /> :
                      <h2>Materials is not Found</h2>
                }
              </div>
              <Pagination {...pagination} setParams={setParams} params = {params} />
            </div>
          </section>
        </div >
      </main >
      {(addMaterialModal || addModalShow) && <AddMaterialModal title={`Add ${tab}`} uploadType={tab === 'Images' ? 'image' : 'video'} open={addMaterialModal || addModalShow} handleClose={addModalHandler}/>}
    </>
  )
}

export default MaterialsLibrary