const usersBase = '/api/payments';

export const paymant = (instance) => ({
    async getCustomerExistedPaymentMethods(payload) {
        try {
            const { data } = await instance.get(`${usersBase}/payment-methods`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async setupIntent(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/setup-intent`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async savePaymentMethod(payload) {
        try {
            const { data } = await instance.post(`${usersBase}/payment-methods`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async assignDefaultPaymentMethodByMethodId(payload) {
        try {
            const { data } = await instance.patch(`${usersBase}/payment-methods/${payload}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deletePaymentMethodByMethodId(payload) {
        try {
            const { data } = await instance.delete(`${usersBase}/payment-methods/${payload}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});