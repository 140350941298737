import React, { useState } from 'react'
import ModalTemplate from './ModalTemplate'
import { useDispatch } from 'react-redux'
import { forgotPasswordFetch } from '../../store/auth/authThunks'
import { useValidation } from '../../hooks/useValidation'
import InputGroup from '../UI/formElements/InputGroup'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ForgotPassword = ({open, handelClose, linkToRegister = true}) => {
    const dispatch = useDispatch()
    const {isLocalload} = useSelector(state => state.auth)
    const [formData, setFormData] = useState({
        email: "",
    })
    const validators = {
        email: value => /\S+@\S+\.\S+/.test(value) ? true : 'Invalid email format',
    };

    const messages = {
        email: 'Email is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(formData, {
        validators,
        messages
    });
    const handleForgotPassword = async () => {
        if (errors?.email) {
            setIsErrorShown(true)
            return;
        }
        const res = await dispatch(forgotPasswordFetch({ email: formData.email })).unwrap()
    }
    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev, [name]: value
        }))
    }
  return (
    <ModalTemplate open={open} handelClose={handelClose}>
            <div className="modal__body items-center">
                <div className="btn">
                    <img src={require("../../assets/img/logo-modal-darck.png")} alt="" />
                </div>
                <div className="modal__body-heading text-center">
                    <h2>Forgot password</h2>
                    <p>Please enter your email</p>
                </div>
                <div className="w-full">
                    <div className="row g-16">
                        <div className="col-12">
                            <InputGroup
                                wrapperClasses=' input--lg'
                                label={'Email address'}
                                type='email'
                                inputClasses='input--outline'
                                placeholder={'Enter your email...'}
                                value={formData.email}
                                error={isErrorShown && errors.email}
                                errorMessage={errorMessages.email}
                                onChange={(e) => handleChange("email", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full gap-[24px]">
                    <button type='button' disabled={isLocalload} className={`btn btn--primary btn--lg radius-5 w-full ${isLocalload ? 'disabled' : ''}`} onClick={handleForgotPassword} >Send</button>
                    <div className="flex color-secondary items-center justify-center w-full text-[13px] gap-3">
                        <span>Don’t have an account?</span>
                        {linkToRegister
                            ?
                            <Link to={'/register'} className="color-danger">Create Account</Link>
                            :
                            <button type='button' onClick={handelClose} className="color-danger">Create Account</button>
                        }
                    </div>
                </div>
            </div>
        </ModalTemplate>
  )
}

export default ForgotPassword
