const pagesBase = 'api/comments';

export const comments = (instance) => ({
    async getCommentsByEntityTypeEntityId({id, type, qwery}) {
        try {
            const { data } = await instance.get(`${pagesBase}/${type}/${id}?${qwery}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addCommentId({id, type, body}) {
        try {
            const { data } = await instance.post(`${pagesBase}/${type}/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addCommentQuizId({id, body}) {
        try {
            const { data } = await instance.post(`${pagesBase}/quiz/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addCommentArticleId({id, body}) {
        try {
            const { data } = await instance.post(`${pagesBase}/article/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addCommentCaseId({id, body}) {
        try {
            const { data } = await instance.post(`${pagesBase}/case/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async addCommentCourseId({id, body}) {
        try {
            const { data } = await instance.post(`${pagesBase}/course/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async editCommentById({id, body}) {
        try {
            const { data } = await instance.patch(`${pagesBase}/${id}`, body);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteCommentById(id) {
        try {
            const { data } = await instance.delete(`${pagesBase}/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async likeCommentById(id) {
        try {
            const { data } = await instance.post(`${pagesBase}/like/${id}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    
});