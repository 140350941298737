const usersBase = '/api/chat/';

export const chat = (instance) => ({
    async getAllConversations(qwery) {
        try {
            const { data } = await instance.get(`${usersBase}/conversations?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getConversationById({id, qwery}) {
        try {
            const { data } = await instance.get(`${usersBase}/conversations/${id}?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getUnreadCount(payload) {
        try {
            const { data } = await instance.get(`${usersBase}/unread-count`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async searchMessages({id, qwery}) {
        try {
            const { data } = await instance.get(`${usersBase}/conversations/${id}/search-messages?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async searchLinks({id, qwery}) {
        try {
            const { data } = await instance.get(`${usersBase}/conversations/${id}/search-links?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async searchFiles({id, qwery}) {
        try {
            const { data } = await instance.get(`${usersBase}/conversations/${id}/search-files?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async searchInfo({id, qwery}) {
        try {
            const { data } = await instance.get(`${usersBase}/conversations/${id}/search-info?${qwery}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
   
    async createNewConversationByUserId(userId) {
        try {
            const { data } = await instance.post(`${usersBase}/conversations/${userId}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async sendMessageByConversationId({id, body}) {
        try {
            const { data } = await instance.post(`${usersBase}/conversations/messages/${id}`, body);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async messagesMarkAsRead({id, body}) {
        try {
            const { data } = await instance.post(`${usersBase}/conversations/mark-as-read/${id}`, body);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async deleteConversation(id) {
        try {
            const { data } = await instance.delete(`${usersBase}/conversations/${id}`);
            return data;
        }
        catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});