import React, { useEffect } from 'react'
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getPagesByPathFetch } from '../../store/pages/pagesThunks'
import { findValue, getImageSrc } from '../../helpers/utils'
import { Link } from 'react-scroll'
import PageLoader from '../../components/parts/PageLoader'
import { useNavigate } from 'react-router-dom'

const AboutUs = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pageData, pages, isLoading } = useSelector(state => state.pages)
    const pageKey = pages.filter(item => item.id === 3)[0]?.url.slice(1)
    useEffect(() => {
        if(pageKey) {
           dispatch(getPagesByPathFetch(pageKey))
        }
    }, [dispatch,pageKey])
    const getValue = (key) => findValue(pageData?.blocks, key)
    
    if (isLoading) return <PageLoader />
    return (
        <main className="content">
            <section className="page-section">
                <div className="container">
                    <div className="column-group column-group--md items-center text-center max-w-[500px] mx-auto">
                        <div className="column-group column-group--md">
                            <div className="heading-row justify-center text-center">
                                <div className="heading-item--row">
                                    <span className="ico ico-50">
                                        <img src={getImageSrc(getValue("header_icon"))} />
                                    </span>
                                    <h1 className="heading">{getValue("header_title")}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-section--row">
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <p className="sidebar-label mb-[20px]">Contents</p>
                                <ul className="nav-article">
                                    {getValue('content_items') ? getValue('content_items')?.map((item, index) => (
                                        <li key={item.title}>
                                            <Link
                                                activeClass="active"
                                                to={`${(item.title).replaceAll(" ", "_").toLowerCase()}`}
                                                spy={true}
                                                smooth={true}
                                                hashSpy={true}
                                                offset={-100}
                                                duration={500}
                                            >
                                                {item.title}
                                            </Link>
                                        </li>
                                    )) : null}
                                </ul>
                                {/* <a href="" className="btn btn--primary btn--square btn--md sidebar-action-btn" data-aside="acticon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"></path>
                                    </svg>
                                </a> */}
                            </aside>
                        </div>
                        <div className="page-content">
                            <article className="single-post">
                                {getValue('content_items') ? getValue('content_items')?.map((item, index) => (
                                    <div id={(item.title).replaceAll(" ", "_").toLowerCase()} key={index}>
                                        <h2>
                                            {item.title}
                                        </h2>
                                        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                    </div>
                                )) : null}

                            </article>
                        </div>
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    <SidebarHelpLinks
                                        title={getValue('title_right_links')}
                                        links={getValue('content_right_links')}
                                    />
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AboutUs