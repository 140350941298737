import React, { useEffect } from 'react'
import { ReactComponent as ArrowRightIcon } from "../../../assets/img/ico-arrow-right.svg"
import { useSelector } from 'react-redux'
import { getQuiz } from '../../../store/quizzes/quizzesSlice'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { viewQuizByIdFeth } from '../../../store/quizzes/quizzesThunks'


const QuizPagePresentation = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const quizData = useSelector(getQuiz);
    useEffect(() => {
        if(id) {
            dispatch(viewQuizByIdFeth(id))
        }
    } , [id])
    return (
        <main className="content">
            <div className="quiz">
                <div className="quiz__content">
                    <div className="quiz__body pt-[60px] pb-[60px]">
                        <div className="container">
                            <div className="mb-[47px]">
                                <p className="quiz__label mb-[13px]">Case Presentation</p>
                                <div className="quiz__text" style={{ whiteSpace: 'pre-wrap'}}>
                                    {quizData?.description}
                                </div>
                            </div>
                            <div className="mb-[47px]">
                                <p className="quiz__label mb-[13px]">Patient Data</p>
                                <p className="quiz__text">{quizData?.age} years, {quizData?.gender}</p>
                            </div>
                            <Link to={`/quiz-page/${quizData?.id}/materials`} className="btn btn--primary btn--md radius-5 gap-[12px]">
                                <span className="info text--md">Continue</span>
                                <span className="ico">
                                    <ArrowRightIcon />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default QuizPagePresentation