import { getImageSrc } from "../helpers/utils";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    const formData = new FormData();
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          formData.append("file", file, file.name);

          return fetch(`${process.env.REACT_APP_API_URL}/api/articles/upload`, {
            method: "POST",
            headers: {
              Authorization: `token ${localStorage.getItem('authToken')}`,
            },
            body: formData,
          })
            .then((res) => res.json())
            .then((d) => {
              if (d.url) {
                this.loader.uploaded = true;
                resolve({
                  default: getImageSrc(d.url),
                });
              } else {
                reject(`Couldn't upload file: ${file.name}.`);
              }
            });
        })
    );
  }
}

export default function ThisCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}