import React, { useEffect } from "react";
import { createPortal } from "react-dom";

const ModalTemplate = ({ open, handelClose,blockCloseBg = false,modalTheme='', lightGreyBg = true, showHeader = true, modalSize = 'modal__dialog--500', children }) => {
    useEffect(() => {
        document.body.classList.add('lock')
        return () => {
            document.body.classList.remove('lock')
        }
    }, [])
    return createPortal(
        <div className={`modal modal-1 ${modalTheme} ${lightGreyBg ? 'modal-light-grey-bg' : ''} ${open ? 'modal--show' : ''}`} 
        onClick={()=>{
            if(blockCloseBg) {
                return
            }
            handelClose()
        }}
        >
            <div className={`modal__dialog ${modalSize}`} >
                <div className="modal__content" onClick={(e) => e.stopPropagation()}>
                    {showHeader && <div className="modal__header">
                        <button type="button" className="btn btn-close" onClick={handelClose}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.22">
                                    <path d="M17.1875 4.8125L4.8125 17.1875" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M17.1875 17.1875L4.8125 4.8125" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </g>
                            </svg>
                        </button>
                    </div>}
                    {children}
                </div>
            </div>
        </div>
        , document.body)
}

export default ModalTemplate