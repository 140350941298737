import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getMe, set2FA } from '../../store/auth/authSlice'
import { Link } from 'react-router-dom'
import InputGroup from '../../components/UI/formElements/InputGroup'
import { useDispatch } from 'react-redux'
import { disable2FAFeth } from '../../store/profile/profileThunk'

const Settinsg2FA = () => {
    const dispatch = useDispatch()
    const me = useSelector(getMe)
    const [code2FA, setCode2FA] = useState('')
    const [showForm, setShowForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const submitHandler = async () => {
        setIsLoading(true)
        const res = await dispatch(disable2FAFeth({'code': +code2FA})).unwrap()
        if(res) {
           dispatch(set2FA(res.auth2fa || false))
           setShowForm(false)
           setCode2FA('')
        }
        setIsLoading(false)
    }
    return (
        <div>
            <div className="flex justify-between items-center">
                <p>Settup 2FA</p>

                {me?.auth2fa ?
                    <button
                        type='button'
                        className="btn btn--danger btn--lg radius-5"
                        onClick={() => setShowForm(true)}
                    >
                        <span className="text--md font-medium">Unsetup</span>
                    </button>
                    :
                    <Link to={'/setup/2FA'} className="btn btn--primary btn--lg radius-5">
                        <span className="text--md font-medium">Setup</span>
                    </Link>
                }
            </div>
            {true && <div className='row g-16 mt-5'>
                <div className="col-12">
                    <p>
                        Look at the Google authenticator code on your phone and enter it
                    </p>
                </div>
                <div className="col-6">
                    <InputGroup
                        type='number'
                        wrapperClasses=' input--lg'
                        placeholder={'Enter code'}
                        inputClasses='input--outline'
                        value={code2FA}
                        onChange = {({target}) => setCode2FA(target.value)}
                    />
                </div>
                <div className="col-12">
                    <button type='button' onClick={submitHandler} disabled={isLoading} className="btn btn--primary btn--lg radius-5">
                        <span className="text--md font-medium">Submit</span>
                    </button>
                </div>
            </div>}
        </div>
    )
}

export default Settinsg2FA
