import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';

const MediaSwiper = ({ media }) => {
    const [playingIndex, setPlayingIndex] = useState(null);

    return (
        <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            className='swiper-media-thread'
            pagination={{
                el: '.swiper-pagination',
                clickable: true
            }}
            navigation
            //   loop={true}
            onSlideChange={(swiper) => {
                setPlayingIndex(swiper.activeIndex);
            }}
        >
            {media.map((item, index) => (
                <SwiperSlide key={index}>
                    {item.type === 'image' ? (
                        <img src={item.src} alt={`Slide ${index}`} style={{ width: '100%', height: '100%', objectFit:'contain' }} />
                    ) : item.type === 'video' ? (
                        <ReactPlayer
                            url={item.src}
                            controls
                            width="100%"
                            height="100%"
                            playing={playingIndex === index}
                        />
                    ) : null}
                </SwiperSlide>
            ))}
             <div class="swiper-pagination swiper-pagination--static mt-4"></div>
        </Swiper>
    );
};

export default MediaSwiper;