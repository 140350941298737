import { createSlice } from '@reduxjs/toolkit';
import { getAllEventsFetch, getEventByIdFetch, getEventCountByDateFetch } from './eventsThunks';

const initialState = {
    events: [],
    event: null,
    isEventsLoading: false,
    isEventLoading: false,
    isEventCountByDateLoading: false,
    eventCountByDate: [],
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    }
}

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {},

    extraReducers(builder) {
        builder
            .addCase(getAllEventsFetch.fulfilled, (state, action) => {
                state.isEventsLoading = false
                state.events = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllEventsFetch.pending, (state, action) => {
                state.isEventsLoading = true
                state.errors = null;
            })
            .addCase(getAllEventsFetch.rejected, (state, action) => {
                state.isEventsLoading = false
                state.errors = action.payload;
            })
            .addCase(getEventByIdFetch.fulfilled, (state, action) => {
                state.isEventLoading = false
                state.event = action.payload?.data
            })
            .addCase(getEventByIdFetch.pending, (state, action) => {
                state.isEventLoading = true
                state.errors = null;
            })
            .addCase(getEventByIdFetch.rejected, (state, action) => {
                state.isEventLoading = false
                state.errors = action.payload;
            })
            .addCase(getEventCountByDateFetch.fulfilled, (state, action) => {
                state.isEventCountByDateLoading = false
                state.eventCountByDate = action.payload?.data
            })
            .addCase(getEventCountByDateFetch.pending, (state, action) => {
                state.isEventCountByDateLoading = true
                state.errors = null;
            })
            .addCase(getEventCountByDateFetch.rejected, (state, action) => {
                state.isEventCountByDateLoading = false
                state.errors = action.payload;
            })
    }
})

export const {
} = eventsSlice.actions;


export default eventsSlice.reducer