import React from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import CardThread from '../../components/parts/Cards/CardThread'
import { Dropdown } from '../../components/parts/Dropdown'
import Filters from '../../components/parts/Filters'
import PageLoader from '../../components/parts/PageLoader'
import Pagination from '../../components/parts/Pagination'
import InputSearch from '../../components/UI/formElements/InputSearch'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import SearchButton from '../../components/UI/SearchButton'
import { filterOptions } from '../../helpers/constants'
import useDebounce from '../../hooks/useDebounce'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { getProfileThreadsFetch } from '../../store/profile/profileThunk'

const ProfileThreads = () => {
    const { authorized, me } = useSelector((state) => state.auth)
    const { profileData, isLoading, pagination } = useSelector(state => state.profile)
    const [userId] = useOutletContext()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const systems = useSelector(getFilteres);

    const formatOptionsQuery = (value) => {
        return value?.length
            ? value.split(',').filter(Boolean) || []
            : []
    }

    const params = useMemo(() => ({
        sort: searchParams.get('sort') || 'latest',
        category: formatOptionsQuery(searchParams.get('category')) || [],
        search: searchParams.get("search") || ""
    }), [searchParams])

    const filters = [
        {
            title: "Filter by Industries",
            checkboxOptions: systems
        },
    ]



    const handleQueryChange = (name, value) => {
        setSearchParams(prev => {
            if (value) {
                prev.set(name, value)
            } else if (prev.get(name)) {
                prev.delete(name)
            }
            return prev
        })
    }

    const handleFilterChange = (options) => {
        const value = params.category.includes(options.name)
            ? params.category.filter((cat) => cat !== options.name).join(',')
            : [...params.category, options.name].join(',')
        handleQueryChange("category", value)
    }

    const debouncedSearchChange = useDebounce((value) => {
        handleQueryChange("search", value)
    }, 500);

    const handleSearchChange = (e) => {
        const { value } = e.target;
        debouncedSearchChange(value);
    };

    useEffect(() => {
        if (!userId) return
        const fetchData = async () => {
            const res =
                await dispatch(getProfileThreadsFetch({ id: userId, qwery: searchParams.toString() })).unwrap()
            if (res) {
                window.scrollTo(0, 0)
            }
        }
        fetchData()
    }, [searchParams, me])

    const handleSortChange = (option) => {
        handleQueryChange("sort", option.value)
    }

    useEffect(() => {
        dispatch(getFiltersByName('thread'))
    }, [dispatch])
    return (
        <div className="tabs-item active-tab" id="threads">
            <div className="w-full">
                <div className="page-heading mb-[30px]">
                    <div className="page-heading__item">
                        <h3 className="mr-[24px]">Threads</h3>
                    </div>
                    <div className="page-heading__item">
                        <div className="row-group gap--xs">
                            <SearchButton value={params.search} onChange={handleSearchChange} />
                            <Dropdown
                                button={<button className="btn btn--md btn--transparent radius-5">
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M3.35338 8.90736C2.96532 8.48458 2.75 7.93159 2.75 7.35772V5.5C2.75 3.98122 6.44365 2.75 11 2.75C15.5563 2.75 19.25 3.98122 19.25 5.5V7.35772C19.25 7.93159 19.0347 8.48458 18.6466 8.90736L13.75 14.2421V17.213C13.75 18.338 12.838 19.25 11.713 19.25H10.287C9.16202 19.25 8.25001 18.338 8.25 17.213V14.242L3.35338 8.90736Z" stroke="#292929" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </button>}
                                bodyClass="p-4"
                                body={
                                    <Filters filters={filters} defaultCheked={params?.category} onChange={handleFilterChange} />
                                }
                            />
                            <SelectGroup
                                wrapperClasses={'input--sm'}
                                options={filterOptions}
                                defaultValue={filterOptions[0]}
                                onChange={handleSortChange}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="row-group gap--md justify-between mb-[30px]">
                    <div className="row-group gap--xs">
                        <SearchButton value={params.search} onChange={handleSearchChange} />
                        <Dropdown
                            button={<button className="btn btn--md btn--transparent radius-5">
                                <span className="ico">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.35338 8.90736C2.96532 8.48458 2.75 7.93159 2.75 7.35772V5.5C2.75 3.98122 6.44365 2.75 11 2.75C15.5563 2.75 19.25 3.98122 19.25 5.5V7.35772C19.25 7.93159 19.0347 8.48458 18.6466 8.90736L13.75 14.2421V17.213C13.75 18.338 12.838 19.25 11.713 19.25H10.287C9.16202 19.25 8.25001 18.338 8.25 17.213V14.242L3.35338 8.90736Z" stroke="#292929" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </button>}
                            bodyClass="p-4"
                            body={
                                <Filters filters={filters} defaultCheked={params?.category} onChange={handleFilterChange} />
                            }
                        />

                    </div>
                </div> */}

                <div className="threads-list flex-auto">
                    {
                        profileData && profileData.length ?
                            profileData.map(card => (
                                <CardThread key={card.id} data={card} authorized={authorized} />
                            ))
                            : isLoading ?
                                <PageLoader /> :
                                <h2>Page is empty</h2>

                    }
                </div>
                <Pagination {...pagination} params={params} />
            </div>
        </div>
    )
}

export default ProfileThreads
