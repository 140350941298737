import React, { useEffect, useState } from 'react'
import { CreateFormHeader } from '../../components/parts/CreateFormHeader'
import SelectGroupRow from '../../components/UI/formElements/SelectGroupRow'
import UploadImage from '../../components/UI/UploadImage'
import { ReactComponent as ArrowRightIcon } from '../../assets/img/ico-arrow-right.svg'
import { categoryOptions } from '../../helpers/constants'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Loader from '../../components/UI/Loader'
import { useSelector } from 'react-redux'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { useValidation } from '../../hooks/useValidation'
import InputGroupRow from '../../components/UI/formElements/InputGroupRow'
import TextareaGroupRow from '../../components/UI/formElements/TextareaGroupRow'
import { getFormData, getImageSrc } from '../../helpers/utils'
import { createCourseFeth, editCourseByIdFeth } from '../../store/courses/coursesThunk'
import { setHasEditChanges } from '../../store/auth/authSlice'

const CourseCreateGeneral = () => {
    const {id} = useParams()
    const conf = {
        title: 'General Information',
    }
    const navigate = useNavigate();   
    const dispatch = useDispatch();
    const { courseOne, courseId, isLoading } = useSelector(state => state.courses)
    const systems = useSelector(getFilteres);
    const [formData, setFormData] = useState({
        cover: null,
        name: '',
        description: '',
        price: null,
        category: null,
    })
    const categotyes = systems ? systems.map(item => ({ value: item?.name, label: item?.name })) : null;

    const validators = {
        name: value => value?.length > 1 ? true : 'Name must be more than 1 characters',
        description: value => value?.length > 5 ? true : 'Description must be more than 5 characters',
        cover: file => {
            if (!file) {
                return 'Cover is required';
            }
            if (!file?.type?.startsWith('image/')) {
                return 'Photo must be an image file (jpg, jpeg, png, gif)';
            }

            return true;
        },
        price: value => value ? true : 'Price is required',
        category: value => value ? true : 'Category is required',
    };
    const messages = {
        name: 'First name is required',
        description: 'Description is required',
        cover: 'Cover is required',
        price: 'Price is required',
        category: 'Category is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(
        formData,
        {
            validators,
            messages
        }
    );
    const formDataHandler = (name, value) => {
        dispatch(setHasEditChanges(true)) 
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        if (errors) {
            
            setIsErrorShown(true)
            return
        }
        const newData = {...formData}
        delete newData.cover
        if(formData.cover instanceof File) {
            newData['cover'] = formData.cover
        }
        const body = getFormData(newData);
        
        let res = null
        if (courseId) {
            res = await dispatch(editCourseByIdFeth({ id: courseId, body })).unwrap()
        } else {
            res = await dispatch(createCourseFeth(body)).unwrap()

        }
        if (res) {
            if(id) {
                navigate(`/course-edit/${id}/content`)
            } else {
                navigate(`/course-create/content`)
            }
            setIsErrorShown(false);
            dispatch(setHasEditChanges(false)) 
        }
    }
    useEffect(() => {
        if(courseOne) {
            setFormData({
                cover: {
                    preview: getImageSrc(courseOne?.cover),
                    type:  `image/${ courseOne?.cover.split('.').pop()}`
                },
                name: courseOne?.name,
                description: courseOne?.description,
                price: courseOne?.price,
                category: courseOne?.filter?.name,
            })
        }

    }, [courseOne])
    useEffect(() => {
        dispatch(getFiltersByName('course'))
    }, [dispatch])
    
    if(isLoading) return  <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
    <Loader/>
</div>
    return (
        <div>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group items-center gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Course Cover</p>
                                                <UploadImage 
                                                    onChange={(image) => {
                                                        formDataHandler('cover', ...image)
                                                    }}
                                                    defaulImages={formData?.cover ? [formData?.cover] : []}
                                                    error={isErrorShown && errors?.cover}
                                                    errorMessage={errorMessages?.cover}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Category'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                options={categotyes}
                                                placeholder={"Select category"}
                                                defaultValue={courseOne?.filter ? {value: courseOne?.filter?.name, label: courseOne?.filter?.name} : null}
                                                onChange = {(option) => {formDataHandler('category', option.value)}}
                                                error={isErrorShown && errors?.category}
                                                errorMessage={errorMessages?.category}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <InputGroupRow
                                                label={"Course Name"}
                                                inputClasses={'input--outline'}
                                                placeholder={'Enter Course Name...'}
                                                defaultValue={courseOne?.name}
                                                onChange={({ target }) => formDataHandler('name', target.value)}
                                                error={isErrorShown && errors?.name}
                                                errorMessage={errorMessages?.name}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextareaGroupRow
                                                label={'Description'}
                                                placeholder={"Enter Description..."}
                                                textareaClasses="input--outline"
                                                onChange={({ target }) => formDataHandler('description', target.value)}
                                                defaultValue={courseOne?.description}
                                                rows="7"
                                                error={isErrorShown && errors?.description}
                                                errorMessage={errorMessages?.description}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <InputGroupRow
                                                label={"Price $"}
                                                inputClasses={'input--outline'}
                                                type='number'
                                                placeholder={'$0.00'}
                                                defaultValue={courseOne?.price}
                                                onChange={({ target }) => formDataHandler('price', target.value)}
                                                error={isErrorShown && errors?.price}
                                                errorMessage={errorMessages?.price}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/courses'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type='button' onClick={submitHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <ArrowRightIcon />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </div>
    )
}

export default CourseCreateGeneral