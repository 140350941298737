import React, { useEffect, useState } from 'react'
import ModalTemplate from './ModalTemplate'
import { useOutletContext } from 'react-router-dom';
import { getFormData, getImageSrc } from '../../helpers/utils';
import InputGroup from '../UI/formElements/InputGroup';
import { useDispatch } from 'react-redux';
import { createFlashCardBySetIdFetch, deleteFlashCardByIdFetch, editFlashCardByIdFetch, orderingFlashCardsFetch } from '../../store/flashcards/flashcardsThunks';
import { useSelector } from 'react-redux';
import { getActiveFlashCardId, setActiveFlashCardId } from '../../store/flashcards/flashcardsSlice';
import ButtonLoader from '../UI/ButtonLoader';
import { useValidation } from '../../hooks/useValidation';

const AddFlashcardModal = ({ handelClose, open }) => {
    const [flashCardId, flashCardSet] = useOutletContext();
    const activeFlashcardId = useSelector(getActiveFlashCardId)
    const [showAddLink, setShowAddLink] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const dispach = useDispatch()
    const [formData, setFormData] = useState(
        {
            question_text: '',
            answer_text: '',
            explanation: '',
            question_image: null,
            answer_image: null,
            links: [],
            order: 0
        }
    )
    const [newLink, setNewLink] = useState('')

    const messages = {
        question_text: 'Question is required',
        answer_text: 'Answer is required',
        explanation: 'Explanation is required',
    };
    const validators = {
        question_text: value => value?.length > 1 ? true : 'Question must be more than 1 characters',
        answer_text: value => value?.length > 1 ? true : 'Answer must be more than 1 characters',
        explanation: value => value?.length > 1 ? true : 'Explanation must be more than 1 characters',
    };
    const requiredFields = ['question_text', 'answer_text', 'explanation']
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(
        formData,
        {
            validators,
            requiredFields,
            messages
        }
    );
    useEffect(() => {
        if (activeFlashcardId) {

            setFormData(flashCardSet?.flashcards.find(item => item.id === activeFlashcardId))
        } else {
            setFormData({
                question_text: '',
                answer_text: '',
                explanation: '',
                question_image: null,
                answer_image: null,
                links: [],
                order: flashCardSet?.flashcards?.length || 0
            })
        }
    }, [activeFlashcardId])

    const newLinkHandler = (e) => {
        setNewLink(e.target.value)
    }
    const addLink = () => {
        if (!newLink.length) return
        setFormData(prev => ({
            ...prev,
            links: [
                ...prev.links,
                newLink
            ]
        }))
        setNewLink('')
    }
    const clearState = () => {
        dispach(setActiveFlashCardId(null))
        setFormData({
            question_text: '',
            answer_text: '',
            explanation: '',
            question_image: null,
            answer_image: null,
            links: []
        })
    }
    const deleteLink = (idx) => {
        setFormData(prev => ({
            ...prev,
            links: prev.links.filter((item, index) => index != idx)
        }))
    }
    const formDataHandler = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    console.log(flashCardSet?.flashcards)
    const deleteHandler = async (e) => {
        e.preventDefault()
        const filteredQuestions = flashCardSet.flashcards.filter(item => item.id !== activeFlashcardId)
        console.log(filteredQuestions)
        const res = await dispach(deleteFlashCardByIdFetch(activeFlashcardId)).unwrap()
        
        if(res) {
            const orderingData = filteredQuestions.map((item, index) => ({
                id: item.id,
                order: index
            }))
            const res2 = await  dispach(orderingFlashCardsFetch({id: flashCardId, body: {
                "ordering" : orderingData
            }})).unwrap()
            handelClose()
        }
        
    }
    const submitHandler = async (e, createAnother) => {
        e.preventDefault();
        if (errors) {
            setIsErrorShown(true)
            return
        }

        const body = getFormData(formData);

        let res = null
        setIsLoading(true)
        if (activeFlashcardId) {
            res = await dispach(editFlashCardByIdFetch({ id: activeFlashcardId, body })).unwrap()
        } else {
            res = await dispach(createFlashCardBySetIdFetch({ id: flashCardId, body })).unwrap()
        }
        if (res) {
            if(createAnother) {
                clearState()
            }
            setIsLoading(false)
        }

    }
    return (
        <ModalTemplate open={open} lightGreyBg={false} handelClose={handelClose} modalSize='modal__dialog--870'>
            <div className="modal__body items-center pt-0">
                <div className="modal__body-heading text-center">
                    {activeFlashcardId ? <h2>Edit flashcard</h2> : <h2>Add new flashcard</h2>}
                </div>
                <div className="list-group list-group--lg w-full">
                    <div className="form-group-row ">
                        <span className="form-group-row__label justify-end">Question</span>
                        <div className="list-group list-group--xs flex-auto">
                            <div className="actions-group">
                                <div className="input-wrapper input--md flex-auto">
                                    <input
                                        className="input input--outline"
                                        type="text"
                                        name={'question_text'}
                                        placeholder="Enter option here..."
                                        value={formData?.question_text}
                                        onChange={({ target }) => formDataHandler('question_text', target.value)}
                                    />
                                </div>
                                {formData?.question_image && formData?.question_image !== 'null' && <div className="image-preview-group">
                                    <img src={getImageSrc(formData?.question_image)} alt="" />
                                    <button
                                        type='button'
                                        className="btn color-danger"
                                        onClick={(e) => formDataHandler('question_image', null)}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8266 4.88617 15.9558L4.13867 5.83331H15.8337L15.1112 15.9516C15.0487 16.8241 14.3228 17.5 13.4487 17.5V17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9.99967 9.16669V14.1667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M3.33301 5.83335H16.6663" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14.1663 5.83333L13.3222 3.58167C13.078 2.93083 12.4563 2.5 11.7613 2.5H8.23801C7.54301 2.5 6.92134 2.93083 6.67717 3.58167L5.83301 5.83333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12.8585 9.16669L12.5002 14.1667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.1418 9.16669L7.50013 14.1667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    </button>
                                </div>}
                                <label className="btn btn--square btn--secondary-2 btn--md">
                                    <input
                                        type="file"
                                        accept='image/*'
                                        onChange={({ target }) => formDataHandler('question_image', target.files[0])}
                                        hidden />
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 5H3" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.5 6.5V3.5" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.7499 6.5L10.4355 11.8143" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M2.31445 12.5643L5.68945 9.18934C6.27524 8.60355 7.22498 8.60355 7.81077 9.18934L14.1698 15.5484" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.25 2.75H12C14.0711 2.75 15.75 4.42893 15.75 6.5V12.5C15.75 14.5711 14.0711 16.25 12 16.25H6C3.92893 16.25 2.25 14.5711 2.25 12.5V8.75" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.5 6.5H10.5037H10.5H10.4963H10.5" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </label>
                            </div>
                            {
                                isErrorShown && errorMessages?.question_text && errors?.question_text ?
                                    <p className='color-danger text--xs'>{errorMessages?.question_text}</p>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="list-group list-group--md">
                        <div className="form-group-row ">
                            <span className="form-group-row__label justify-end">Answer</span>
                            <div className="list-group list-group--xs flex-auto">
                                <div className="actions-group">
                                    <div className="input-wrapper input--md flex-auto">
                                        <input
                                            className="input input--outline"
                                            type="text"
                                            placeholder="Enter option here..."
                                            name='answer_text'
                                            value={formData?.answer_text}
                                            onChange={({ target }) => formDataHandler('answer_text', target.value)}
                                        />
                                    </div>
                                    {formData?.answer_image && formData?.answer_image !== 'null' && <div className="image-preview-group">
                                        <img src={getImageSrc(formData?.answer_image)} alt="" />
                                        <button
                                            type='button'
                                            className="btn color-danger"
                                            onClick={(e) => formDataHandler('answer_image', null)}
                                        >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4487 17.5H6.54867C5.67617 17.5 4.95117 16.8266 4.88617 15.9558L4.13867 5.83331H15.8337L15.1112 15.9516C15.0487 16.8241 14.3228 17.5 13.4487 17.5V17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9.99967 9.16669V14.1667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M3.33301 5.83335H16.6663" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14.1663 5.83333L13.3222 3.58167C13.078 2.93083 12.4563 2.5 11.7613 2.5H8.23801C7.54301 2.5 6.92134 2.93083 6.67717 3.58167L5.83301 5.83333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M12.8585 9.16669L12.5002 14.1667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M7.1418 9.16669L7.50013 14.1667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </button>
                                    </div>}
                                    <label className="btn btn--square btn--secondary-2 btn--md">
                                        <input
                                            type="file"
                                            accept='image/*'
                                            hidden
                                            onChange={({ target }) => formDataHandler('answer_image', target.files[0])}
                                        />
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 5H3" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.5 6.5V3.5" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15.7499 6.5L10.4355 11.8143" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M2.31445 12.5643L5.68945 9.18934C6.27524 8.60355 7.22498 8.60355 7.81077 9.18934L14.1698 15.5484" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8.25 2.75H12C14.0711 2.75 15.75 4.42893 15.75 6.5V12.5C15.75 14.5711 14.0711 16.25 12 16.25H6C3.92893 16.25 2.25 14.5711 2.25 12.5V8.75" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.5 6.5H10.5037H10.5H10.4963H10.5" stroke="#999999" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </label>
                                </div>
                                {
                                    isErrorShown && errorMessages?.answer_text && errors?.answer_text ?
                                        <p className='color-danger text--xs'>{errorMessages?.answer_text}</p>
                                        :
                                        null
                                }
                            </div>
                        </div>
                        <div className="form-group-row ">
                            <span className="form-group-row__label justify-end">Explanation</span>
                            <div className="list-group list-group--md flex-auto">
                                <div className="input-wrapper input--md flex">
                                    <textarea
                                        rows="8"
                                        className="textarea input--outline py-[16px]"
                                        style={{ resize: "none" }}
                                        placeholder="Enter explanation to your answer..."
                                        name='explanation'
                                        value={formData?.explanation}
                                        onChange={({ target }) => formDataHandler('explanation', target.value)}
                                    ></textarea>

                                </div>
                                {
                                    isErrorShown && errorMessages?.explanation && errors?.explanation ?
                                        <p className='color-danger text--xs'>{errorMessages?.explanation}</p>
                                        :
                                        null
                                }
                                <div className="links-group">
                                    {formData?.links?.length > 0 && formData?.links.map((item, index) => (
                                        <div
                                            key={index}
                                            className="links-group__item color-danger">
                                            <p>{item}</p>
                                            <button type="button"
                                                onClick={() => deleteLink(index)}
                                                className="btn">
                                                <svg width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 8.5L16 16.5"
                                                        stroke="#999999"
                                                        strokeWidth="1.2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"></path>
                                                    <path d="M16 8.5L8 16.5"
                                                        stroke="#999999"
                                                        strokeWidth="1.2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                {showAddLink && <div className="add-link-group flex flex-wrap gap-[20px]">
                                    <InputGroup
                                        wrapperClasses={'input--lg flex-auto'}
                                        inputClasses={'input--outline'}
                                        placeholder={'Link'}
                                        name={'link'}
                                        value={newLink}
                                        onChange={newLinkHandler}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn--primary btn--xs radius-5"
                                        onClick={addLink}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.33398 10H16.6673" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10 3.33337V16.6667" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </button>
                                </div>}
                                <div className="">
                                    <button type="bytton"
                                        onClick={() => setShowAddLink(!showAddLink)}
                                        className="btn btn--primary btn--xs radius-5">
                                        <span className="ico mr-[10px]">
                                            <svg width="20" height="21" viewBox="0 0 20 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.8456 15.5123L9.57106 16.7868C7.95346 18.4044 5.3308 18.4044 3.7132 16.7868V16.7868C2.0956 15.1692 2.0956 12.5466 3.7132 10.929L5.37479 9.26739C6.15619 8.48598 7.216 8.047 8.32107 8.047C9.42614 8.047 10.4859 8.48598 11.2674 9.26739"
                                                    stroke="#323232" strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                                <path
                                                    d="M9.15417 5.48773L10.4287 4.2132C12.0463 2.5956 14.669 2.5956 16.2866 4.2132V4.2132C17.9042 5.8308 17.9042 8.45346 16.2866 10.0711L14.625 11.7326C12.9978 13.3598 10.3596 13.3598 8.73242 11.7326"
                                                    stroke="#323232" strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                        <span className="text--sm font-medium">{showAddLink ? 'Hide Add Link' : 'Add link'}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal__footer">
                {activeFlashcardId ?
                    <button type='button' onClick={deleteHandler} disabled={isLoading} className="btn btn--danger btn--md radius-5">
                        {
                            isLoading
                                ? <ButtonLoader /> :
                                'Delete'
                        }
                    </button>
                    : <button type='button' onClick={handelClose} disabled={isLoading} className="btn btn--shadow btn--md radius-5">
                        {
                            isLoading
                                ? <ButtonLoader /> :
                                'Cancel'
                        }
                    </button>}
                <button type='button' onClick={submitHandler} disabled={isLoading} className="btn btn--primary btn--md radius-5 md:ml-auto">
                    {isLoading
                        ? <ButtonLoader /> :
                        'Save'
                    }
                </button>
                <button type='button' disabled={isLoading} onClick={(e) => {
                    submitHandler(e, true)
                    
                }} className="btn btn--primary btn--md radius-5">
                    {isLoading
                        ? <ButtonLoader /> :
                        'Save and Add Another'
                    }
                </button>
            </div>
        </ModalTemplate>
    )
}

export default AddFlashcardModal