import React, { useEffect, useState } from 'react'
import InputGroup from '../../../components/UI/formElements/InputGroup'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { savePaymentMethodFeth, setupIntentFeth } from '../../../store/paymant/paymantThunk';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getMe } from '../../../store/auth/authSlice';
import ButtonLoader from '../../../components/UI/ButtonLoader';
import CheckBoxGroup from '../../../components/UI/formElements/CheckBoxGroup';

const options = {
    classes: {
        base: 'input input--outline'
    },
    style: {
        base: {
            iconColor: '#c4f0ff',
            // lineHeight: '42px',
            fontWeight: 400,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '14px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#999999'
            }
        }
    },
    // showIcon: true,
}

const PaymantMethodForm = () => {
    const me = useSelector(getMe)
    const dispatch = useDispatch()
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [mainCard, setMainCard] = useState(true)
    useEffect(() => {
        const createSetupIntent = async () => {
            const response = await dispatch(setupIntentFeth()).unwrap()
            if (response) {
                setClientSecret(response.client_secret);
            }
        };
        createSetupIntent();
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements || !clientSecret) {
            return;
        }
       
        setIsProcessing(true);
        setError(null);

        const cardElement = elements.getElement(CardNumberElement);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                name: name,
                email: me.email,
            },
        });

        if (error) {
            toast.error(error.message);
            setIsProcessing(false);
            return false;
        }
        const { error: confirmError } = await stripe.confirmCardSetup(
            clientSecret,
            {
                payment_method: paymentMethod.id,
            }
        );

        if (confirmError) {
            toast.error(confirmError.message);
            setIsProcessing(false);
        } else {
            //   Отправка setupIntent и кастомных полей на сервер
            const data = {
                id: paymentMethod.id,
                brand: paymentMethod.card.brand,
                exp_month: paymentMethod.card.exp_month,
                exp_year: paymentMethod.card.exp_year,
                last4: paymentMethod.card.last4,
                defaultCard: mainCard
            }
        
            
            const response = await dispatch(savePaymentMethodFeth(data)).unwrap();
            
            if (response) {
                setSuccess(true);
                setName('');
                elements.getElement(CardNumberElement).clear();
                elements.getElement(CardExpiryElement).clear();
                elements.getElement(CardCvcElement).clear();
            } else {
                toast.error('Failed to save payment method');
            }
            setIsProcessing(false);
        }
    };
    return (
        <form onSubmit={handleSubmit} className="col-group gap-[24px]">
            <div className="row-group justify-between gap--md">
                <div className="col-group gap--md-2">
                    <h4 className="text--lg">Add new card</h4>
                    <p className="color-secondary">Add new payment methods</p>
                </div>
                <button type='submit' disabled={isProcessing} className="btn btn--primary gap--md btn--lg radius-5 mt-auto">
                    {!isProcessing ? <>
                        <span className="ico">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 8V16" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 12H8" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="info text--md font-medium">Add card</span>
                    </> :
                        <ButtonLoader />
                    }
                </button>
            </div>
            <div className="flex w-full">
                <div className="row flex-auto w-full g-24">
                    <div className="col-md-6">
                        <InputGroup
                            wrapperClasses='input--lg'
                            label={'Name on your card '}
                            inputClasses='input--outline'
                            placeholder={'Nur Alam'}
                            value={name}
                            onChange={(target) => {setName(target.value)}}
                        />
                    </div>
                    <div className="col-md-6">
                        <div className={`input-wrapper input--lg`}>
                            <label className="label">Expiry</label>
                            <CardExpiryElement className='input input--outline py-[13px] cursor-text' options={options} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={`input-wrapper input--lg`}>
                            <label className="label">Card Number </label>
                            {/* 4000 0027 6000 3184 */}
                            <CardNumberElement className='input input--outline py-[13px] cursor-text' options={options} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={`input-wrapper input--lg`}>
                            <label className="label">CVV</label>
                            <CardCvcElement className='input input--outline py-[13px] cursor-text' options={options} />
                        </div>
                    </div>
                    <div className="col-12">
                        <CheckBoxGroup
                            checked={mainCard}
                            onChange={() => setMainCard(prev => !prev)}
                        >
                            <p className="check-text">
                                Set as main
                            </p>
                        </CheckBoxGroup>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default PaymantMethodForm
