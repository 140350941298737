import { Link, useSearchParams } from 'react-router-dom'
import { ReactComponent as CardsIcon } from "../../../assets/img/ico-cards.svg"
import { ReactComponent as HeartIcon } from "../../../assets/img/ico-heart.svg"
import { getImageSrc } from '../../../helpers/utils'
import Actions from './Actions'
import { ReactComponent as PlaceholderIcon } from "../../../assets/img/user-placeholder.svg"
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import { getAllFlashCardSetsFetch, toggleFavoriteFlashcardFetch, toggleLikeFlashcardFetch } from '../../../store/flashcards/flashcardsThunks'
import { setShowLoginModal } from '../../../store/auth/authSlice'

const CardFlashcard = ({ data, isList, authorized }) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams()
    const progress = data?.flashCardSetStats ? (data?.flashCardSetStats[0]?.cardsCompleted / data?.flashCardSetStats[0]?.cardsTotal) * 100 : 0;
    const toggleLike = () => {
        if (authorized) {
            dispatch(toggleLikeFlashcardFetch({ id: data.id }))
        }
        else {
            dispatch(setShowLoginModal(true))
        }
    }
    const toggleFavorite = () => {
        if (authorized) {
            dispatch(toggleFavoriteFlashcardFetch({ id: data.id }))
        } else {
            dispatch(setShowLoginModal(true))
        }

    }
    return (
        <>
            {
                !isList ?
                    <div className="card card-post">
                        <div className="card-header post-image wrapp-image-sm">
                            <Link
                                to={`${authorized ? data?.id?.toString() : '#'}`}
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }}
                                className='flex w-full'>
                                {data?.cover && <img className='w-full' src={getImageSrc(data?.cover)} alt={data?.name} />}
                                {!data?.isCompleted && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}
                            </Link>
                            <Actions
                                isFavoriteProps={data?.isFavorite}
                                handleLikeProps={toggleLike}
                                handleAddToFavoritesProps={toggleFavorite}
                                isLikeProps={data?.isLiked}
                                isDisabletFavorite={data?.disable_favorites}
                            />
                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <div className="group-between">
                                    {data?.user ?
                                        <Link to={`/profile/${data?.user?.id}`} className="user-info user-info--xs">
                                            <div className="ico user-ico  image-wrapper rounded-full">
                                                {
                                                    data?.user?.photo ?
                                                        <img src={getImageSrc(data?.user?.photo)} alt={data?.user?.firstName} />
                                                        :
                                                        <PlaceholderIcon />
                                                }
                                            </div>
                                            <p className="user-name">{data?.user.firstName + " " + data?.user?.lastName}</p>
                                        </Link>
                                        : null
                                    }
                                    <div className="like-checker">
                                        <span className="ico ico-like-check">
                                            <HeartIcon />
                                        </span>
                                        <span className="check-total">{data?.likes}</span>
                                    </div>
                                </div>
                                <Link
                                    to={`${authorized ? data?.id?.toString() : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}>
                                    <h5 className="heading post-title">{data?.name}</h5>
                                </Link>
                                {
                                    (data?.flashCardSetStats?.length > 0) ?
                                        <div className="progress">
                                            <p className="progress-text">{Number(progress).toFixed()} %</p>
                                            <div className="progress-bar">
                                                <span className="progress-line" style={{ width: progress + "%" }}></span>
                                            </div>
                                        </div>
                                        : null
                                }
                                <div className="group-between">
                                    <div className="group-between--item">
                                        <div className="pill-custom">
                                            <span className="pill-text">{data?.filter?.name}</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <CardsIcon />
                                            <span className="pill-text">{data?.flashcardsCount}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    :
                    <div className="card card-post --card-row card-post--md">
                        <div className="card-header post-image wrapp-image-sm">
                            <Link
                                to={`${authorized ? data?.id?.toString() : '#'}`}
                                onClick={(e) => {
                                    if (!authorized) {
                                        e.preventDefault()
                                        dispatch(setShowLoginModal(true))
                                    }
                                }}
                                className='flex w-full'>
                                {data?.cover && <img className='w-full' src={getImageSrc(data?.cover)} alt={data?.name} />}
                                {!data?.isCompleted  && data.hasOwnProperty('isCompleted') && <span className='draft-label color-danger text-xl'>Draft</span>}
                            </Link>
                            {/* <img src={getImageSrc(data?.cover)} alt={data?.name} /> */}
                            <Actions
                                isFavoriteProps={data?.isFavorite}
                                handleLikeProps={toggleLike}
                                handleAddToFavoritesProps={toggleFavorite}
                                isLikeProps={data?.isLiked}
                                isDisabletFavorite={data?.disable_favorites}
                            />
                        </div>
                        <div className="card-body">
                            <div className="card-body--item">
                                <Link to={`${authorized ? data?.id?.toString() : '#'}`}
                                    onClick={(e) => {
                                        if (!authorized) {
                                            e.preventDefault()
                                            dispatch(setShowLoginModal(true))
                                        }
                                    }}>
                                    <h4 className="heading post-title">{data?.name}</h4>
                                </Link>
                                <div className="group-between flex-wrap">
                                    <div className="group-between--item">
                                        <div className="pill-custom">
                                            <span className="pill-text">{data?.filter?.name}</span>
                                        </div>
                                        <p className="mini-post--desk">{format(data.createdAt, "dd MMM, yyyy")}</p>
                                        {data?.user ?
                                            <Link to={`/profile/${data?.user?.id}`} className="user-info user-info--xs">
                                                <div className="ico user-ico  image-wrapper rounded-full">
                                                    {
                                                        data?.user?.photo ?
                                                            <img src={getImageSrc(data?.user?.photo)} alt={data?.user?.firstName} />
                                                            :
                                                            <PlaceholderIcon />
                                                    }
                                                </div>
                                                <p className="user-name">{data?.user.firstName + " " + data?.user?.lastName}</p>
                                            </Link>
                                            : null
                                        }
                                        <div className="like-checker">
                                            <span className="ico ico-like-check">
                                                <HeartIcon />
                                            </span>
                                            <span className="check-total">{data.likes}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body--item max-md:hidden">
                                <p className="post--desk">{data?.description}</p>
                            </div>
                            {
                                (data?.flashCardSetStats?.length > 0) ?
                                    <div className="progress">
                                        <p className="progress-text">{Number(progress).toFixed()} %</p>
                                        <div className="progress-bar">
                                            <span className="progress-line" style={{ width: progress + "%" }}></span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export default CardFlashcard