import React, { useEffect } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import ProfileSidebar from '../../components/parts/profile/ProfileSidebar'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getMe } from '../../store/auth/authSlice'
import { getProfileByIdFeth } from '../../store/profile/profileThunk'

const Settings = () => {
    const dispatch = useDispatch()
    const userData = useSelector(getMe);
    const {profile} = useSelector(state => state.profile);
    const navLlinks = [
        {
            name: 'My Details',
            route: '/settings'
        },
        {
            name: 'Password',
            route: '/settings/password'
        },
        {
            name: 'Payment Methods',
            route: '/settings/paymant-methods'
        },
        {
            name: 'Billing Plan',
            route: '/settings/billing-plan'
        },
        {
            name: '2FA',
            route: '/settings/2FA'
        },
    ]
    useEffect(() => {
        if(userData?.id) {
            dispatch(getProfileByIdFeth(userData.id))
        }
    },[userData])
    return (
        <main className="content">
            <div className="container">
                <section className="py-[32px]">
                    <div className="page-section--row page-section--row--reverse">
                        <div className="page-content">
                            <div className="page-heading mb-[32px]">
                                <h2 className="mr-[24px]">Settings</h2>
                            </div>
                            <div className="tabs settings-tabs w-full">
                                <div className="tabs-header">
                                    <ul className="tabs-nav">
                                        {navLlinks.map((item, index) => (
                                            <li className="tabs-nav--item" key={index}>
                                                <NavLink 
                                                    to={item.route}
                                                    end
                                                    className={({ isActive, isPending }) => `btn btn-tablink btn--md rounded-full ${isActive ? "active-tab" : ""}`
                                                    }>
                                                    <span>{item.name}</span>
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="tabs-body py-[32px]">
                                    <Outlet />
                                </div>
                            </div>
                            
                        </div>
                        <ProfileSidebar />
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Settings