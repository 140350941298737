import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import CheckBoxGroup from "../../components/UI/formElements/CheckBoxGroup";
import SelectGroup from "../../components/UI/formElements/SelectGroup";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editFlashCardSetSettingsByIdFetch } from "../../store/flashcards/flashcardsThunks";
import { getIsLoading, setFlashCardId, setFlashCardSet } from "../../store/flashcards/flashcardsSlice";
import { useSelector } from "react-redux";
import Loader from "../../components/UI/Loader";
import { setHasEditChanges } from "../../store/auth/authSlice";
import { toast } from "react-toastify";


export const FlashcardsCreateSettings = () => {
    const [flashCardId, flashCardSet] = useOutletContext();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLoading = useSelector(getIsLoading)
    const [settingsData, setSettingsData] = useState({})
    const [customRound, setCustomRound] = useState(false)
    const [cardPerRoundOptions , setCardPerPoundOpions]= useState([
        {value: 'custom', label: 'Custom value'},
        { value: 10, label: "10 cards" },
        { value: 15, label: "15 cards" },
        { value: 25, label: "25 cards" },
        { value: 30, label: "30 cards" },
        { value: 35, label: "35 cards" },
        { value: 40, label: "40 cards" },
        { value: 45, label: "45 cards" },
    ])
    useEffect(() => {
        if(flashCardSet?.cards_per_round) {
            const hasValue = cardPerRoundOptions.findIndex(time => time.value === flashCardSet.cards_per_round)
            console.log(hasValue)
            if(hasValue === -1) {
                setCardPerPoundOpions(prev => ([
                    ...prev,
                    {value: flashCardSet?.cards_per_round, label:  `${flashCardSet?.cards_per_round} cards` }
                ]))
            }
        }
    },[flashCardSet])
    const conf = {
        title: 'Settings',
    }

    const handelChange = (name, value, customRound) => {
        dispatch(setHasEditChanges(true))
        if(name === 'cards_per_round' && value === 'custom') {
            setCustomRound(true)
            return
        } else {
            !customRound && setCustomRound(false)
        }
        setSettingsData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const saveHandler = async (createAnother = false) => {
        if(settingsData?.cards_per_round < 5 && settingsData?.cards_per_round !== null) {
            toast.error('Cards per round cannot be less than 5 select "No borders"')
            return
        }
        const body = settingsData
        const res = await dispatch(editFlashCardSetSettingsByIdFetch({flashCardId, body})).unwrap();

        if(createAnother && res) {
            dispatch(setFlashCardId(null))
            dispatch(setFlashCardSet(null))
            navigate('/flashcards-create/general')
           
        }
        dispatch(setHasEditChanges(false))
    }
    if(isLoading) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader/>
        </div>
    )


    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--full">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="container container--lg">
                                                <div className="group gap--lg md:flex-nowrap">
                                                    <p className="text--md font-medium min-w-[140px] md:text-right">Cards
                                                        per round</p>
                                                    <div className="w-full">
                                                        <div className="mb-[26px]">
                                                            <label className="quiz-answer-item">
                                                                <input type="radio" 
                                                                    defaultChecked={flashCardSet?.cards_per_round != -1} name="cards_per_round"
                                                                    onChange={
                                                                        (e) => {
                                                                            if(!flashCardSet.cards_per_round) {
                                                                                handelChange('cards_per_round', 10)
                                                                            }
                                                                        }
                                                                    }
                                                                    hidden 
                                                                />
                                                                <span
                                                                    className="quiz-answer-item__icon mr-[16px]"></span>
                                                                <div className="w-full">
                                                                    <p className="text--sm mb-[6px]">Flashcards
                                                                        amount</p>
                                                                    <p className="color-secondary text--xs mb-[16px]">Set
                                                                        flashcards amount per round</p>
                                                                    <SelectGroup
                                                                        groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                                        wrapperClasses={'select--outline input--md w-full max-w-[200px]'}
                                                                        options={cardPerRoundOptions}
                                                                        defaultValue={
                                                                            flashCardSet?.cards_per_round === -1 
                                                                            ? false 
                                                                            : {
                                                                                value: flashCardSet?.cards_per_round, 
                                                                                label: `${flashCardSet?.cards_per_round} cards`
                                                                            }
                                                                        }
                                                                        onChange={(option) => handelChange('cards_per_round', option.value)}
                                                                        placeholder={"Select amount"}
                                                                    />
                                                                    {customRound && <div className="input-wrapper input--md w-full max-w-[200px] mt-2">
                                                                        <label className="label">Enter a value greater than 5</label>
                                                                        <input 
                                                                            type="number"
                                                                            min={5} 
                                                                            defaultValue={5}
                                                                            onKeyDown={(e) => {
                                                                              if (e.key === 'ArrowDown' || (e.key === 'ArrowUp' && e.target.value <= 5)) {
                                                                                e.preventDefault();
                                                                              }
                                                                            }}
                                                                            placeholder="Enter Round Count " 
                                                                            onChange={({target}) => {
                                                                                handelChange('cards_per_round', target.value, true)
                                                                            }} className="input input--outline" />
                                                                    </div>}
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <label className="quiz-answer-item">
                                                            <input type="radio" 
                                                                defaultChecked={flashCardSet?.cards_per_round === -1} name="cards_per_round"  
                                                                onChange={(e) => handelChange('cards_per_round', -1)}
                                                                hidden 
                                                            />
                                                            <span className="quiz-answer-item__icon mr-[16px]"></span>
                                                            <div>
                                                                <p className="text--sm mb-[6px]">No borders</p>
                                                                <p className="color-secondary text--xs">Train all cards
                                                                    at once</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr className="hr" />
                                        </div>
                                        <div className="col-12">
                                            <div className="container container--lg">
                                                <div className="group gap--lg md:flex-nowrap">
                                                    <p className="text--md font-medium min-w-[140px] md:text-right">Options</p>
                                                    <div className="checkboxes-list">
                                                        {/* <CheckBoxGroup
                                                            wrapperClasses={'input--lg'}
                                                            name={'only_flashcards'}
                                                            defaultChecked = {flashCardSet?.only_flashcards}
                                                            onChange = {(e) => handelChange('only_flashcards', e.target.checked)}
                                                        >
                                                            <p className="check-text">
                                                                Only training with flashcards
                                                            </p>
                                                        </CheckBoxGroup> */}
                                                        <CheckBoxGroup
                                                            wrapperClasses={'input--lg'}
                                                            name={'disable_favorites'}
                                                            defaultChecked = {flashCardSet?.disable_favorites}
                                                            onChange = {(e) => handelChange('disable_favorites', e.target.checked)}
                                                        >
                                                            <p className="check-text">
                                                                Don’t allow favorite flashcards
                                                            </p>
                                                        </CheckBoxGroup>
                                                        {/* <CheckBoxGroup
                                                            wrapperClasses={'input--lg'}
                                                            name={'disable_statistics'}
                                                            defaultChecked = {flashCardSet?.disable_statistics}
                                                            onChange = {(e) => handelChange('disable_statistics', e.target.checked)}
                                                        >
                                                            <p className="check-text">
                                                                Without statistics
                                                            </p>
                                                        </CheckBoxGroup> */}
                                                        {/* <CheckBoxGroup
                                                            wrapperClasses={'input--lg'}
                                                            name={'disable_copying'}
                                                            defaultChecked = {flashCardSet?.disable_copying}
                                                            onChange = {(e) => handelChange('disable_copying', e.target.checked)}
                                                        >
                                                            <p className="check-text">
                                                                Attendants can copy flashcards
                                                            </p>
                                                        </CheckBoxGroup> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/quiz/flashcards'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <div className="group items-center md:flex-nowrap gap--xs">
                                        <button type="button" onClick={() => saveHandler(false)} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save</span>
                                        </button>
                                        <button onClick={() => saveHandler(true)} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save and Create Another</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}