import React from 'react';
import { STATUSES } from '../../helpers/constants';

const QuizSteps = ({ data, total, activeIndex }) => {
    console.log(data)
    return (
        <ul className="quiz-nav-menu">
            {/* (total ? data.concat(Array.from({ length: total - data.length })) : data) */}
            {data && data?.map((step, index) => (
                <li key={index} className="quiz-nav-menu__item">
                    <span className={`quiz-nav-menu__link`} style={{
                        backgroundColor:  index <= activeIndex ? "var(--white)" : null,
                        color: index === activeIndex ? STATUSES.active : STATUSES[String(step?.flashCardStats[0]?.difficulty).toLocaleUpperCase()]?.color,
                        boxShadow: index <= activeIndex ? "0 2px 4px 0 rgba(133, 133, 133, 0.16), 0 0 1px 0 rgba(26, 32, 36, 0.32)" : null,
                    }}></span>
                </li>
            ))}
        </ul>
    );
};

export default QuizSteps;
