import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InputGroup from '../../components/UI/formElements/InputGroup'
import { checkErrors, getFormData } from '../../helpers/utils'
import { updateProfileFetch } from '../../store/auth/authThunks'
import { useValidation } from '../../hooks/useValidation'

const Password = () => {
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    })

    const validators = {
        confirmNewPassword: (value, data) => value === data.password ? true : 'Passwords do not match',
        newPassword: (value) => {
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            return passwordRegex.test(value) ? true : 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character';
        },
    };

    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(formData, {
        validators,
    });


    const handleChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (errors) {
            
            setIsErrorShown(true)
            return;
        };
        const body = getFormData(formData)
        dispatch(updateProfileFetch(body))
    }

    return (
        <div className="tabs-item active-tab" id="password">
            <h4 className="mb-[24px] text--lg">Change Password</h4>
            <form autoComplete="off" className="col-group col-group--border gap--xl">
                <div className="flex">
                    <div className="row flex-auto w-full g-24">
                        <div className="col-md-6">
                            <InputGroup
                                wrapperClasses='input--lg'
                                label={'Current Password'}
                                inputClasses='input--outline'
                                placeholder={'Enter your current password...'}
                                value={formData.currentPassword}
                                error={isErrorShown && errors?.currentPassword}
                                errorMessage={errorMessages?.currentPassword}
                                type='password'
                                autoComplete={"new-password"}
                                onChange={(e) => handleChange("currentPassword", e.target.value)}
                            />
                        </div>
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <InputGroup
                                wrapperClasses='input--lg'
                                label={'New Password'}
                                inputClasses='input--outline'
                                placeholder={'Enter your new password...'}
                                value={formData.newPassword}
                                error={isErrorShown && errors?.newPassword}
                                errorMessage={errorMessages?.newPassword}
                                type='password'
                                onChange={(e) => handleChange("newPassword", e.target.value)}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputGroup
                                wrapperClasses='input--lg'
                                label={'Confirm Password'}
                                inputClasses='input--outline'
                                placeholder={'Confirm Password...'}
                                value={formData.confirmNewPassword}
                                error={isErrorShown && errors?.confirmNewPassword}
                                errorMessage={errorMessages?.confirmNewPassword}
                                type='password'
                                onChange={(e) => handleChange("confirmNewPassword", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row-group flex-wrap justify-between gap--sm">
                    <button onClick={handleSubmit} className="btn md:w-auto md:min-w-[250px] btn--primary btn--lg radius-5">
                        <span className="text--md font-medium">Change Password</span>
                    </button>
                    <button className="btn md:w-auto md:min-w-[250px] btn--secondary-2 btn--lg radius-5">
                        <span className="text--md font-medium">Cancel</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Password
