import React, { useState } from 'react'

import { ReactComponent as LikeIcon } from "../../assets/img/ico-like.svg"
import { ReactComponent as PlaceholderIcon } from "../../assets/img/user-placeholder.svg"
import { ReactComponent as ShareIcon } from "../../assets/img/ico-share.svg"
import { ReactComponent as ReplyIcon } from "../../assets/img/ico-reply.svg"
import { getImageSrc, timeDifference } from '../../helpers/utils'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'

const Comment = ({ comment, addReply, likeComment, showOnly, pinComment , isPin}) => {
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [replyText, setReplyText] = useState('');
    const [copied, setCopied] = useState(false);
    const handleReplySubmit = (e) => {
        e.preventDefault();
        if (comment.parentId) {
            addReply(comment.parentId, replyText);
        } else {
            addReply(comment.id, replyText);
        }

        setReplyText('');
        setShowReplyForm(false);
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    const getCommentTitle = (comment) => {
        if (!comment) return
        if (comment.hasOwnProperty('quiz') && comment.quiz) {
            return comment.quiz.name
        }
        if (comment.hasOwnProperty('article') && comment.article) {
            return comment.article.title
        }
        if (comment.hasOwnProperty('case') && comment.case) {
            return comment.case.name
        }
        if (comment.hasOwnProperty('course') && comment.course) {
            return comment.course.name
        }
    }
    const getLinkType = (comment) => {
        if (!comment) return
        if (comment.hasOwnProperty('quiz') && comment.quiz) {
            return `/quiz-page/${comment.quiz.id}/presentation`
        }
        if (comment.hasOwnProperty('article') && comment.article) {
            return `/articles/${comment.article.id}`
        }
        if (comment.hasOwnProperty('case') && comment.case) {
            return `/cases/${comment.case.id}`
        }
        if (comment.hasOwnProperty('course') && comment.course) {
            return `/courses/${comment.course.id}`
        }
    }

    return (
        <li className="comment-row">
            <div className="comment-content comment-avatar">
                <div className="user-info user-info--sm items-start max-w-max">
                    <div className="ico user-ico image-wrapper rounded-full">
                        {
                            comment?.user?.photo ?
                                <img src={getImageSrc(comment?.user?.photo)} />
                                : <PlaceholderIcon />
                        }
                    </div>
                </div>
            </div>
            <div className="comment-content flex-auto">
                <div className="comment-title">
                    <p className="comment-title-name">{(comment?.user?.firstName || '') + ' ' + (comment?.user?.lastName || '')}</p>
                    <p className="commnet-title-date">{timeDifference(comment?.updatedAt)}</p>
                </div>
                {comment?.refType && <div className="comment-desk text--sm">
                    <span>comment for: </span>
                    <Link to={getLinkType(comment)} className='color-danger'>
                        {getCommentTitle(comment)}
                    </Link>
                </div>}
                <div className="comment-desk" dangerouslySetInnerHTML={{ __html: comment?.content }}/>
                <div className="discus-controls">
                    <div>
                        <button type='button' onClick={() => likeComment(comment.id)} className={`btn btn--sm radius-5 btn--transparent btn--like gap-[7px] like-button ${comment.isLiked ? '--selected' : ''}`}>
                            <span className="ico">
                                <LikeIcon />
                            </span>
                            <p className="text-[13px] font-[500]">{comment?.likes}</p>
                        </button>

                    </div>
                    {!showOnly && <div>
                        <button type='button' onClick={() => setShowReplyForm(prev => !prev)} className="btn btn--sm radius-5 btn--transparent btn--save gap-[7px]">
                            <span className="ico">
                                <ReplyIcon />
                            </span>
                            <p className="text-[13px] font-[500]">Reply</p>
                        </button>
                    </div>}
                    {isPin && <div>
                        <button onClick={() => pinComment(comment.id)} className={`btn btn--sm radius-5 btn--transparent btn--save gap-[7px] like-button ${comment.isPinned ? '--selected' : ''}`}>
                            <span className="ico">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.8435 3.84997L16.233 7.23945C16.5486 7.55508 16.7061 7.9956 16.6621 8.43979C16.6181 8.88399 16.3773 9.28507 16.0059 9.53267L13.5276 11.1849C13.2192 11.3905 12.9985 11.7036 12.9086 12.0632L11.8775 16.1876C11.8124 16.448 11.6123 16.6532 11.3537 16.7249C11.095 16.7965 10.8178 16.7235 10.628 16.5337L3.54926 9.45493C3.35948 9.26515 3.28646 8.98796 3.3581 8.72931C3.42975 8.47066 3.63498 8.27054 3.89535 8.20545L8.01976 7.17434C8.37933 7.08444 8.69248 6.86375 8.89808 6.55536L10.5503 4.07705C10.7979 3.70564 11.199 3.46482 11.6432 3.42083C12.0874 3.37685 12.5279 3.53434 12.8435 3.84997Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M3.33057 16.7522L7.08866 12.9941" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                            <p className="text-[13px] font-[500]">Pin comment</p>
                        </button>
                    </div>}
                    <div>
                        <CopyToClipboard text={comment?.content} onCopy={handleCopy}>
                            <span className="btn btn--sm radius-5 btn--transparent btn--share gap-[7px]">
                                <span className="ico">
                                    <ShareIcon />
                                </span>
                                <p className="text-[13px] font-[500]">{copied ? 'Content Copied' : 'Copy'}</p>
                            </span>
                        </CopyToClipboard>
                    </div>
                </div>
                {showReplyForm && (
                    <form className="comments-send-form" onSubmit={handleReplySubmit}>
                        <div className="input-wrapper input--lg">
                            <input
                                className="input input--outline"
                                placeholder="Enter your comment here..."
                                value={replyText}
                                onChange={(e) => setReplyText(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn--primary btn--lg radius-5">
                            <span className="text-[14px] font-[500]">Comment</span>
                        </button>
                    </form>
                )}
                {
                    comment.replies && comment.replies.length > 0 && (<ul className="comments-list">
                        {comment.replies.map((reply, index) => (
                            <Comment
                                key={index}
                                comment={reply}
                                addReply={addReply}
                                likeComment={likeComment}
                            />
                        ))}
                    </ul>)
                }
            </div>
        </li>
    );
};
export default Comment