import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import Flashcard from '../../../components/parts/Flashcard'
import { useDispatch } from 'react-redux'
import { getFavoriteFlashCardsFetch } from '../../../store/flashcards/flashcardsThunks'
import Loader from '../../../components/UI/Loader'

const FlashcardPageSelectedFlashcards = () => {
    const dispatch = useDispatch()
    const { quizData: { selectedCards }, isLoading, flashCardSet } = useSelector(state => state.flashcards)
    const [currentIndex, setCurrentIndex] = useState(0)

    const handleNext = () => {
        if (currentIndex < selectedCards.length - 1) {
            setCurrentIndex(prev => prev + 1)
        }
    }
    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prev => prev - 1)
        }
    }

    useEffect(() => {
        if (currentIndex > selectedCards.length - 1) {
            setCurrentIndex(selectedCards.length - 1)
        } else {
            setCurrentIndex(0)
        }
    }, [selectedCards])

    useEffect(() => {
        dispatch(getFavoriteFlashCardsFetch({ id: flashCardSet?.id }))
    }, [dispatch, flashCardSet?.id])

    if (isLoading) {
        return <Loader />
    }

    if (!selectedCards?.length) {
        return (
            <div className='flex flex-auto items-center justify-center'>
                <h2>There is no flashcards</h2>
            </div>
        )
    }

    return (
        <main className="content">
            <div className="quiz">
                <div className="quiz__content card-fix-mobail">
                    <div className="quiz__body">
                        <div className="container full-center">
                            <Flashcard data={selectedCards[currentIndex]} />
                        </div>
                    </div>
                    <div className="quiz__footer bg-white">
                        <div className="container">
                            <p className="flex items-center gap-[6px]">
                                <span className="ico">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.62933 14.6667H3.40733C2.63 14.6667 2 14.0367 2 13.2594V6.22203C2 5.4447 2.63 4.8147 3.40733 4.8147H7.62933C8.40667 4.8147 9.03667 5.4447 9.03667 6.22203V13.2594C9.03733 14.0367 8.40667 14.6667 7.62933 14.6667Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.50146 4.81468L7.15413 2.37668C7.35546 1.62601 8.12746 1.18001 8.87813 1.38135L12.9561 2.47401C13.7068 2.67535 14.1528 3.44668 13.9515 4.19801L12.1301 10.9953C11.9288 11.746 11.1575 12.192 10.4068 11.9907L9.0368 11.624" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <span>{currentIndex + 1} / {selectedCards.length}</span>
                            </p>
                            <div className="flex items-center gap--xs">
                                <button onClick={handlePrev} className={`btn btn--shadow gap-[10px] btn--md radius-5 ${currentIndex <= 0 ? "disabled" : ""}`}>
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.58301 10.9998H17.4163" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.16634 6.4165L4.58301 10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M9.16634 15.5833L4.58301 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <span className="info text-[13px] font-[500] sm:flex hidden">Previous</span>
                                </button>
                                <button onClick={handleNext} className={`btn btn--primary gap-[10px] btn--md radius-5 ${currentIndex >= selectedCards.length - 1 ? "disabled" : ""}`} >
                                    <span className="info text-[13px] font-[500] sm:flex hidden">Next Card</span>
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.4163 10.9998H4.58301" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.833 15.5833L17.4163 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.833 6.4165L17.4163 10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default FlashcardPageSelectedFlashcards