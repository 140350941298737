import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { getQuizQuestionsByQuizIdFeth } from '../../../store/quizzes/quizzesThunks';
import { useSelector } from 'react-redux';

const QuizPageStart = () => {
	// const {id} = useParams();
	const {quiz, questions,quizeResults} = useSelector((state) => state.quizzes)
	// const navigate = useNavigate()
	// useEffect(()=> {
    //     if(quizeResults?.completedQuestions === quizeResults?.totalQuestions) {
    //         navigate(`/quiz-page/${id}/quiz/end`)
    //     }
    // },[quizeResults])
	
	return (
		
			
				<main className="content">
					<div className="quiz">
						<div className="quiz__content">
							<div className="quiz__body pt-[60px] pb-[60px]">
								<div className="container full-center">
								{
									(questions  && questions.length > 0) ?  
									<>
										<div className="ico ico--xxl image-wrapper mb-[32px]">
											<img src={require("../../../assets/img/quiz-img.png")} alt="" />
										</div>
										<p className="quiz__text font-[600] mb-[6px]">Ready for Quiz</p>
										<p className="quiz__label mb-[28px]">Test yourself on the skills in this quiz!</p>
										<p className="pill-custom mb-[32px]">
											<span className="pill-text">
												{questions?.length } questions • 
												{quiz?.quizSetting?.timer ?  ` ${quiz?.quizSetting?.timer} mins` : ' No time limit'}</span>
										</p>
										<Link to={'questions'} className="btn btn--primary btn--md radius-5">
											<span className="info text--md">Let's Start</span>
										</Link>
									</>
									: <h2>Question not Found</h2>
								}
								</div>
							</div>
						</div>
					</div>
			</main> 
		
	)
}

export default QuizPageStart