import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getCommentsByEntityTypeEntityIdFeth = createAsyncThunk(
    '/getCommentsByEntityTypeEntityIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.comments.getCommentsByEntityTypeEntityId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addCommentQuizIdFeth = createAsyncThunk(
    '/addCommentQuizIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.comments.addCommentQuizId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addCommentArticleIdFeth = createAsyncThunk(
    '/addCommentArticleIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.comments.addCommentArticleId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addCommentCaseIdFeth = createAsyncThunk(
    '/addCommentCaseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.comments.addCommentCaseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addCommentCourseIdFeth = createAsyncThunk(
    '/addCommentCourseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.comments.addCommentCourseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editCommentByIdFeth = createAsyncThunk(
    '/editCommentByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.comments.editCommentById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteCommentByIdFeth = createAsyncThunk(
    '/deleteCommentByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.comments.deleteCommentById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const likeCommentByIdFeth = createAsyncThunk(
    '/likeCommentByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.comments.likeCommentById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);