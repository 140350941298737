import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { STATUSES } from '../../../helpers/constants'

const FlashcardPageStart = () => {
    const { flashCardSet: { flashcards }, quizData, isLoading, pagination } = useSelector(state => state.flashcards)
    const {id} = useParams()
    const progress = quizData?.cardsTotal > 0 ? quizData.cardsCompleted * 100 / quizData?.cardsTotal : 0
    const navigate = useNavigate()
    useEffect(() => {
        if ( progress === 100) {
            navigate(`/quiz/flashcards/${id}/flashcards/end`)
        } 
    }, [])
    const statusCounts = quizData.flashcards.reduce((acc, item) => {
        acc[item.status] = (acc[item.status] || 0) + 1;
        return acc;
    }, {});

    if (!flashcards?.length) {
        return (
            <div className='flex flex-auto items-center justify-center'>
                <h2>There is no flashcards</h2>
            </div>
        )
    }


    return (
        <main className="content">
            <div className="quiz">
                <div className="quiz__content">
                    <div className="quiz__body pt-[60px] pb-[60px]">
                        <div className="container container--md full-center">
                            <div className="ico ico--xxl image-wrapper mb-[32px]">
                                <img src={require("../../../assets/img/flashcards-icon.png")} alt="" />
                            </div>
                            <p className="quiz__text mb-[6px] font-[600]">Train Flashcards</p>
                            <p className="quiz__label mb-[32px]">Review the cards and train yourself</p>
                            <div className="mb-[32px] w-full">
                                <div className="progress mb-[24px]">
                                    <p className="progress-text">{progress.toFixed()} %</p>
                                    <div className="progress-bar">
                                        <span className="progress-line" style={{
                                            width: `${progress}%`
                                        }}></span>
                                    </div>
                                </div>
                                <div className="group-between justify-center">
                                    <div className="group-between--item flex-wrap">
                                        <div className="pill-custom">
                                            <span className="pill-text">{pagination?.total} cards</span>
                                        </div>
                                        <div className="pill pill--outline">
                                            <span className="text--sm color-danger">Again: {quizData?.againCount || 0}</span>
                                        </div>
                                        <div className="pill pill--outline">
                                            <span className="text--sm color-warning">Hard: {quizData?.hardCount || 0}</span>
                                        </div>
                                        <div className="pill pill--outline">
                                            <span className="text--sm color-success">Good: {quizData?.goodCount || 0}</span>
                                        </div>
                                        <div className="pill pill--outline">
                                            <span className="text--sm color-pending">Easy: {quizData?.easyCount || 0}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to={"../flashcards"} className="btn btn--primary btn--md radius-5">
                                <span className="info text--md">Let's Start</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default FlashcardPageStart