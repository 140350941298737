import React, { useEffect, useRef, useState } from 'react'
import ThemSwith from '../ThemSwith'
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import logo from "../../../assets/img/logo.png"
import logoDark from "../../../assets/img/logo-footer.png" 
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { saveProtectFunction } from '../../../helpers/utils'


const statusClasses  = {
    "approved": 'color-success',
    "rejected": 'color-danger',
    "pending": 'color-primary',
    "draft": '',
}

const CreateSidebar = ({ links = [],status, title, backRoute = '/' }) => {
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef(null);
    const [searchParams] = useSearchParams()
    const {hasEditChanges, siteTheme} = useSelector(state => state.auth)
    const handleOpen = () => {
        if (window.innerWidth < 992) {
            isOpen ? setIsOpen(false) : setIsOpen(true)
            ref?.current && isOpen ? ref.current?.classList.remove("lock") : ref.current?.classList.add("lock")
        }
    }

    

    useEffect(() => {
        const a = document.getElementsByClassName('quiz-page')[0]
        ref.current = a
    }, []);
    

    return (
        <aside className={`sidebar-left sidebar--quize ${isOpen ? 'active' : ''}`} style={{ zIndex: 60 }} data-sidebar-menu="">

            <div className="sidebar-left__content">
                <div className="sidebar-header header-size">

                    <div className="flex items-center gap-[12px]">
                        <Link to={backRoute} onClick={(e) => saveProtectFunction(e, hasEditChanges)} className="btn btn--transparent btn--square btn--xs rounded-full">
                            <span className="ico ico-20">
                                <img src={require('../../../assets/img/chevron-prev.svg').default} />
                            </span>
                        </Link>
                        <Link to={"/"} onClick={(e) => saveProtectFunction(e,hasEditChanges)} className="logo logo-size">
                            {/* <img src={require('../../../assets/img/logo.png')} /> */}
                            <img src={siteTheme === 'dark' ? logoDark : logo} alt="mediwise" />
                        </Link>
                    </div>
                    <div className="sidebar-left-hide-container-btn">
                        <button onClick={handleOpen} type="button" className="btn btn-burger btn--shadow">
                            <div className={`header__burger btn--xs btn--square ${isOpen ? 'active' : ''}`}>
                                <span></span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="sidebar-body scrollstyle">
                    <div className="heading-block gap-[12px]">
                        <h3 className="heading title-sidebar">
                            {title}
                        </h3>
                        {/* {status && <p>Status: <span className={statusClasses[status]}>{status}</span> </p>} */}
                    </div>
                    <div className="sidebar-menu">
                        <ul className="menu-list">
                            {links.map((link, index) => (
                                <li key={index}>
                                    <NavLink
                                        to={`${link.to}`}
                                        end
                                        className={({ isActive }) => ` btn btn--md btn-menu-link ${isActive ? '--active-link' : ''
                                            }`}
                                        onClick={(e) => {
                                            saveProtectFunction(e,hasEditChanges)
                                            if (link?.disabled) {
                                                e.preventDefault()
                                            }
                                            handleOpen()
                                        }}
                                    >
                                        <span className="ico ico-22">
                                            {link.icon}
                                        </span>
                                        <span>{link.label}</span>
                                    </NavLink>
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
                <div className="sidebar-footer">
                    <ThemSwith />
                </div>
            </div>
        </aside>
    )
}

export default CreateSidebar
