import React, { useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";
import CreateSidebar from "../components/parts/createPagesParts/CreateSidebar";
import {ReactComponent as GeneralIcon} from '../assets/img/sidebar-general.svg'
import {ReactComponent as SettingIcon} from '../assets/img/sidebar-settings.svg'
import {ReactComponent as CourseIcon} from '../assets/img/sidebar-course.svg'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCourseByIdFeth } from "../store/courses/coursesThunk";
const CourseEditLayout = () => {
    const dispatch = useDispatch()
    const {id} = useParams()
    // const { courseOne, courseId } = useSelector(state => state.courses)
    const sidebalLiks = [
        {
            icon: <GeneralIcon/>,
            to: 'general',
            label: 'General Information'
        },
        {
            icon: <CourseIcon/>,
            to: 'content',
            label: 'Course Content',
        },
        {
            icon: <SettingIcon/>,
            to: 'settings',
            label: 'Settings',
        },
    ]
    useEffect(() => {
        if(id) {
          dispatch(getCourseByIdFeth({id: id, qwery: ''}));
        }
    }, [id])
    return (
        <>
            <div className={'quiz-page'}>
                <CreateSidebar links={sidebalLiks} title={'Edit Course'} backRoute={`/courses/${id}`} />
                <Outlet />
                <div className="custom-overlay toggler-menu" style={{ zIndex: 51 }}></div>
            </div>
        </>
    )
}


export default CourseEditLayout