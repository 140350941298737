import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import MainLayout from '../layouts/MainLayout';
import Anatomy from './Anatomy/Anatomy';
import Articles from './Articles/Articles';
import Cases from './Cases/Cases';
import Courses from './Courses/Courses';
import Forums from './Forums/Forums';
import HomePage from './HomePage/HomePage';
import MaterialsLibrary from './MaterialsLibrary/MaterialsLibrary';
import Profile from './Profile/Profile';
import ProfileArticles from './Profile/ProfileArticles';
import ProfileCases from './Profile/ProfileCases';
import ProfileComments from './Profile/ProfileComments';
import ProfileCourses from './Profile/ProfileCourses';
import ProfileForums from './Profile/ProfileForums';
import ProfileMaterials from './Profile/ProfileMaterials';
import ProfileQuizzes from './Profile/ProfileQuizzes';
import ProfileThreads from './Profile/ProfileThreads';
import Quiz from './Quiz/Quiz';
import MyDetails from './Settings/MyDetails';
import Password from './Settings/Password';
import PaymantMethods from './Settings/PaymantMethods';
import BillingPlan from './Settings/BillingPlan';
import MyProfile from './Profile/MyProfile';
import { ForumPage } from "./Forums/ForumPage";
import { ForumThread } from "./Forums/ForumThread";
import { ForumLayout } from "../layouts/ForumLayout";
import { ForumCreateLayout } from "../layouts/ForumCreateLayout";
import { ForumModerators } from "./Forums/ForumModerators";
import { ForumGeneralInformation } from "./Forums/ForumGeneralInformation";
import { ForumRules } from "./Forums/ForumRules";
import { ForumSettings } from "./Forums/ForumSettings";
import Settings from './Settings/Settings';
import SignUp from './SignUp/SignUp';
import Ui from './Ui';
import FAQ from './FAQ/FAQ';
import ContactUs from './ContactUs/ContactUs';
import AboutUs from './AboutUs/AboutUs';
import ImageSingle from './MaterialsLibrary/ImageSingle';
import VideoSingle from './MaterialsLibrary/VideoSingle';
import SingleDocument from './MaterialsLibrary/SingleDocument';
import Favorites from './Favorites/Favorites';
import Pricing from './Pricing/Pricing';
import PricingCheckout from './Pricing/PricingCheckout';
import ArticleSingle from './Articles/ArticleSingle';
import CaseSingle from './Cases/CaseSingle';
import CourseSingle from './Courses/CourseSingle';
import EventSingle from './EventCalendar/EventSingle';
import EventCalendar from './EventCalendar/EventCalendar';
import Notifications from './Notifications/Notifications';
import Chat from './Chat/Chat';
import { QuizCreateLayout } from "../layouts/QuizCreateLayout";
import { QuizGeneralInformation } from "./Quiz/QuizGeneralInformation";
import { QuizCreateImage } from "./Quiz/QuizCreateImage";
import { QuizCreateHints } from "./Quiz/QuizCreateHints";
import { QuizCreateQuiz } from "./Quiz/QuizCreateQuiz";
import { QuizCreateSettings } from "./Quiz/QuizCreateSettings";
import { FlashcardsCreateGeneral } from "./Quiz/FlashcardsCreateGeneral";
import { FlashcardCreateLayout } from "../layouts/FlashcardCreateLayout";
import { FlashcardsCreateFlashcards } from "./Quiz/FlashcardsCreateFlashcards";
import { FlashcardsCreateSettings } from "./Quiz/FlashcardsCreateSettings";
import CourseCreateLayout from '../layouts/CourseCreateLayout';
import CourseCreateGeneral from './Courses/CourseCreateGeneral';
import CourseCreateContent from './Courses/CourseCreateContent';
import CourseCreateContentAddLesson from './Courses/CourseCreateContentAddLesson';
import CourseCreateSettings from './Courses/CourseCreateSettings';
import CaseCreateGeneral from './Cases/CaseCreateGeneral';
import CaseCreateImageVideo from './Cases/CaseCreateImageVideo';
import CaseCreateSettings from './Cases/CaseCreateSettings';
import CaseCreateComments from './Cases/CaseCreateComments';
import CaseCreateLayout from '../layouts/CaseCreateLayout';
import ArticlesHistory from './Articles/ArticlesHistory';
import ArticleCreateLayout from '../layouts/ArticleCreateLayout';
import ArticleCreateContent from './Articles/ArticleCreateContent';
import ArticleCreateSettings from './Articles/ArticleCreateSettings';
import ArticleCreatePreview from './Articles/ArticleCreatePreview';
import FlashcardPageLayout from '../layouts/FlashcardPageLayout';
import FlashcardPageDescription from './Quiz/FlashcardPage/FlashcardPageDescription';
import FlashcardPageStart from './Quiz/FlashcardPage/FlashcardPageStart';
import FlashcardPageSelectedFlashcards from './Quiz/FlashcardPage/FlashcardPageSelectedFlashcards';
import FlashcardPageEnd from './Quiz/FlashcardPage/FlashcardPageEnd';
import FlashcardPageFlashcards from './Quiz/FlashcardPage/FlashcardPageFlashcards';
import QuizPageLayout from '../layouts/QuizPageLayout';
import QuizPageDiscussion from './Quiz/QuizPage/QuizPageDiscussion';
import QuizPageStart from './Quiz/QuizPage/QuizPageStart';
import QuizPageQuestions from './Quiz/QuizPage/QuizPageQuestions';
import QuizPageEnd from './Quiz/QuizPage/QuizPageEnd';
import QuizPageResult from './Quiz/QuizPage/QuizPageResult';
import QuizPagePresentation from './Quiz/QuizPage/QuizPagePresentation';
import QuizPageMaterials from './Quiz/QuizPage/QuizPageMaterials';
import QuizPageHints from './Quiz/QuizPage/QuizPageHints';
import ProtectedRoute from '../layouts/ProtectedRoute';
import TermsOfUse from './Legal/TermsOfUse';
import PrivacyPolicy from './Legal/PrivacyPolicy';
import RecoveryPassword from './SignUp/RecoveryPassword';
import { NotFound } from './NotFound/NotFound';
import { useSelector } from 'react-redux';
import { getAllPages } from '../store/pages/pagesSlice';
import FinalizeLogin from './SignUp/FinalizeLogin';
import SingIn from './SignUp/SingIn';
import ArticleEditLayout from '../layouts/ArticleEditLayout';
import CaseEditLayout from '../layouts/CaseEditLayout';
import CourseEditLayout from '../layouts/CourseEditLayout';
import FavoritesQuizes from './Favorites/FavoritesQuizes';
import FavoritesCourses from './Favorites/FavoritesCourses';
import FavoritesArticle from './Favorites/FavoritesArticle';
import FavoritesCases from './Favorites/FavoritesCases';
import QuizEditLayout from '../layouts/QuizEditLayout';
import FlashcardEditLayout from '../layouts/FlashcardEditLayout';
import { ForumEditLayout } from '../layouts/ForumEditLayout';
import FavoritesThreads from './Favorites/FavoritesThreads';
import FA from './SignUp/FA';
import Settinsg2FA from './Settings/Settinsg2FA';
import SuggetsChanges from './Articles/SuggetsChanges';
// import PrivateRouts from '../layouts/PrivateRouts';

export const AppRouters = () => {
    const routes = useSelector(getAllPages);

    //   const routeElements = routes.map((route, index) => (
    //     <Route key={index} path={route.path} element={<route.component />} />
    //   ));

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path={'/ui'} element={<Ui />} />
                <Route element={<ProtectedRoute />}>
                    <Route path={'/finalize-login/:token'} element={<FinalizeLogin />} />
                    <Route path={'/setup/2FA'} element={<FA />} />
                </Route>
                <Route element={<MainLayout />}>
                    <Route index element={<HomePage />} />
                    {routes && routes.map(route => (
                        <Route key={route.id} path={route.url} element={
                            route.id === 1 ? <FAQ /> :
                                route.id === 2 ? <ContactUs /> :
                                    route.id === 3 ? <AboutUs /> : ""
                        } />
                    ))}
                    <Route path='/pricing' element={<Pricing />} />
                    <Route path='/notifications' element={<Notifications />} />
                </Route>
                <Route element={<ProtectedRoute />}>
                    <Route element={<MainLayout hideFooter={true} />}>
                        <Route path='/chat' element={<Chat />} />
                        <Route path='/chat/:id' element={<Chat />} />
                    </Route>
                    <Route path='/checkout' element={<PricingCheckout type={'pricing'} />} />
                    <Route path='/checkout/cource/:id' element={<PricingCheckout type={'course'} />} />
                </Route>
                <Route path='/quiz'>
                    <Route element={<MainLayout />}>
                        <Route index element={<Quiz tab="Quizzes" />} />
                    </Route>
                    <Route path='flashcards'>
                        <Route element={<MainLayout />}>
                            <Route index element={<Quiz tab="Flashcards" />} />
                        </Route>

                        <Route path=':id' element={<ProtectedRoute />} >
                            <Route element={<FlashcardPageLayout />}>
                                <Route index element={<FlashcardPageDescription />} />
                                <Route path='flashcards/start' element={<FlashcardPageStart />} />
                                <Route path='flashcards' element={<FlashcardPageFlashcards />} />
                                <Route path='flashcards/end' element={<FlashcardPageEnd />} />
                                <Route path='selected' element={<FlashcardPageSelectedFlashcards />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path='/courses'>
                    <Route element={<MainLayout />}>
                        <Route index element={<Courses />} />
                        <Route element={<ProtectedRoute />}>
                            <Route path='/courses/:id' element={<CourseSingle />} />
                        </Route>
                    </Route>
                </Route>

                <Route path='/cases'>
                    <Route element={<MainLayout />}>
                        <Route index element={<Cases />} />
                        <Route element={<ProtectedRoute />}>
                            <Route path='/cases/:id' element={<CaseSingle />} />
                        </Route>
                    </Route>
                </Route>

                <Route path='/articles'>
                    <Route element={<MainLayout />}>
                        <Route index element={<Articles />} />
                        <Route element={<ProtectedRoute />}>
                            <Route path='/articles/:id' element={<ArticleSingle />} />
                            <Route path='/articles/:id/history' element={<ArticlesHistory />} />
                        </Route>
                    </Route>
                </Route>

                <Route path='/anatomy'>
                    <Route element={<MainLayout />}>
                        <Route index element={<Anatomy />} />
                    </Route>
                </Route>

                <Route path='/forums'>
                    <Route element={<MainLayout />}>
                        <Route index element={<Forums />} />
                    </Route>
                </Route>

                <Route path='/materials' element={<MainLayout />}>
                    <Route index element={<MaterialsLibrary tab="Document" />} />
                    <Route path="videos" element={<MaterialsLibrary tab="Video" />} />
                    <Route path="images" element={<MaterialsLibrary tab="Images" />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path="image/:id" element={<ImageSingle />} />
                        <Route path="video/:id" element={<VideoSingle />} />
                        <Route path="document/:id" element={<SingleDocument />} />
                    </Route>
                </Route>

                <Route path='/events' element={<MainLayout />}>
                    <Route index element={<EventCalendar />} />
                    <Route element={<ProtectedRoute />}>
                        <Route path='/events/:id' element={<EventSingle />} />
                    </Route>
                </Route>
                <Route element={<ProtectedRoute />}>
                    <Route path='/favorites' element={<MainLayout />}>
                        <Route path='documents' element={<Favorites tab="Document" />} />
                        <Route path="videos" element={<Favorites tab="Video" />} />
                        <Route path="images" element={<Favorites tab="Images" />} />
                        <Route index element={<FavoritesQuizes tab="Quizez" />} />
                        <Route path="flashcards" element={<FavoritesQuizes tab="Flashcards" />} />
                        <Route path="courses" element={<FavoritesCourses />} />
                        <Route path="articles" element={<FavoritesArticle />} />
                        <Route path="cases" element={<FavoritesCases />} />
                        <Route path="threads" element={<FavoritesThreads />} />
                    </Route>
                </Route>
                <Route element={<ProtectedRoute />}>
                    <Route element={<MainLayout />}>
                        <Route path='/settings' element={<Settings />} >
                            <Route index element={<MyDetails />} />
                            <Route path='/settings/password' element={<Password />} />
                            <Route path='/settings/paymant-methods' element={<PaymantMethods />} />
                            <Route path='/settings/billing-plan' element={<BillingPlan />} />
                            <Route path='/settings/2FA' element={<Settinsg2FA />} />
                        </Route>
                    </Route>

                    <Route element={<MainLayout />}>
                        <Route path='/profile' element={<Profile />} >
                            <Route index element={<MyProfile />} />
                            <Route path='quizzes' element={<ProfileQuizzes />} />
                            <Route path='courses' element={<ProfileCourses />} />
                            <Route path='cases' element={<ProfileCases />} />
                            <Route path='articles' element={<ProfileArticles />} />
                            <Route path='comments' element={<ProfileComments />} />
                            <Route path='forums' element={<ProfileForums />} />
                            <Route path='threads' element={<ProfileThreads />} />
                            <Route path='my-materials' element={<ProfileMaterials />} />
                        </Route>
                    </Route>
                </Route>
                <Route element={<MainLayout />}>
                    <Route path='/profile/:id' element={<Profile />} >
                        <Route index element={<MyProfile />} />
                        <Route path='quizzes' element={<ProfileQuizzes />} />
                        <Route path='courses' element={<ProfileCourses />} />
                        <Route path='cases' element={<ProfileCases />} />
                        <Route path='articles' element={<ProfileArticles />} />
                        <Route path='comments' element={<ProfileComments />} />
                        <Route path='forums' element={<ProfileForums />} />
                        <Route path='threads' element={<ProfileThreads />} />
                        <Route path='my-materials' element={<ProfileMaterials />} />
                    </Route>
                </Route>
                <Route path='/forums' element={<ProtectedRoute />}>
                    <Route element={<ForumLayout />}>
                        <Route path='/forums/:id' element={<ForumPage />} />
                        <Route path='/forums/:id/thread/:threadId' element={<ForumThread />} />
                    </Route>
                </Route>

                <Route path='/forum' element={<ProtectedRoute />}>
                    <Route element={<ForumCreateLayout />}>
                        <Route path='/forum/moderators' element={<ForumModerators />} />
                        <Route path='/forum/general' element={<ForumGeneralInformation />} />
                        <Route path='/forum/rules' element={<ForumRules />} />
                        <Route path='/forum/settings' element={<ForumSettings />} />
                    </Route>
                </Route>
                <Route path='/forum/:id' element={<ProtectedRoute />}>
                    <Route element={<ForumEditLayout />}>
                        <Route path='moderators' element={<ForumModerators />} />
                        <Route path='general' element={<ForumGeneralInformation />} />
                        <Route path='rules' element={<ForumRules />} />
                        <Route path='settings' element={<ForumSettings />} />
                    </Route>
                </Route>

                <Route path='/quiz-create' element={<ProtectedRoute />}>
                    <Route element={<QuizCreateLayout />}>
                        <Route path='/quiz-create/general' element={<QuizGeneralInformation />} />
                        <Route path='/quiz-create/image' element={<QuizCreateImage />} />
                        <Route path='/quiz-create/hints' element={<QuizCreateHints />} />
                        <Route path='/quiz-create/quiz' element={<QuizCreateQuiz />} />
                        <Route path='/quiz-create/settings' element={<QuizCreateSettings />} />
                    </Route>
                </Route>
                <Route path='/quiz-edit/:id' element={<ProtectedRoute />}>
                    <Route element={<QuizEditLayout />}>
                        <Route path='/quiz-edit/:id/general' element={<QuizGeneralInformation />} />
                        <Route path='/quiz-edit/:id/image' element={<QuizCreateImage />} />
                        <Route path='/quiz-edit/:id/hints' element={<QuizCreateHints />} />
                        <Route path='/quiz-edit/:id/quiz' element={<QuizCreateQuiz />} />
                        <Route path='/quiz-edit/:id/settings' element={<QuizCreateSettings />} />
                    </Route>
                </Route>

                <Route path='/flashcards-create' element={<ProtectedRoute />}>
                    <Route element={<FlashcardCreateLayout />}>
                        <Route path='/flashcards-create/general' element={<FlashcardsCreateGeneral />} />
                        <Route path='/flashcards-create/flashcards' element={<FlashcardsCreateFlashcards />} />
                        <Route path='/flashcards-create/settings' element={<FlashcardsCreateSettings />} />
                    </Route>
                </Route>
                <Route path='/flashcards-edit/:id' element={<ProtectedRoute />}>
                    <Route element={<FlashcardEditLayout />}>
                        <Route path='general' element={<FlashcardsCreateGeneral />} />
                        <Route path='flashcards' element={<FlashcardsCreateFlashcards />} />
                        <Route path='settings' element={<FlashcardsCreateSettings />} />
                    </Route>
                </Route>

                <Route path='/quiz-page/:id' element={<ProtectedRoute />}>
                    <Route element={<QuizPageLayout />}>
                        <Route path='presentation' element={<QuizPagePresentation />} />
                        <Route path='materials' element={<QuizPageMaterials tab={"image"} />} />
                        <Route path='materials/video' element={<QuizPageMaterials tab={"video"} />} />
                        <Route path='materials/notes' element={<QuizPageMaterials tab={"notes"} />} />
                        <Route path='hints' element={<QuizPageHints />} />
                        <Route path='quiz'>
                            <Route index element={<QuizPageStart />} />
                            <Route path='questions' element={<QuizPageQuestions />} />
                            <Route path='end' element={<QuizPageEnd />} />
                            <Route path='result' element={<QuizPageResult />} />
                        </Route>
                        <Route path='discussion' element={<QuizPageDiscussion />} />

                    </Route>
                </Route>



                <Route path='/course-create' element={<ProtectedRoute />}>
                    <Route element={<CourseCreateLayout />}>
                        <Route path='general' element={<CourseCreateGeneral />} />
                        <Route path='content' element={<CourseCreateContent />} />
                        <Route path='content/add' element={<CourseCreateContentAddLesson />} />
                        <Route path='settings' element={<CourseCreateSettings />} />
                    </Route>
                </Route>
                <Route path='/course-edit/:id' element={<ProtectedRoute />}>
                    <Route element={<CourseEditLayout />}>
                        <Route path='general' element={<CourseCreateGeneral />} />
                        <Route path='content' element={<CourseCreateContent />} />
                        <Route path='content/add' element={<CourseCreateContentAddLesson />} />
                        <Route path='settings' element={<CourseCreateSettings />} />
                    </Route>
                </Route>

                <Route path='/case-create' element={<ProtectedRoute />}>
                    <Route element={<CaseCreateLayout />}>
                        <Route index element={<CaseCreateGeneral />} />
                        <Route path='images-videos' element={<CaseCreateImageVideo />} />
                        <Route path='comments' element={<CaseCreateComments />} />
                        <Route path='settings' element={<CaseCreateSettings />} />
                    </Route>
                </Route>
                <Route path='/case-edit/:id' element={<ProtectedRoute />}>
                    <Route element={<CaseEditLayout />}>
                        <Route index element={<CaseCreateGeneral />} />
                        <Route path='images-videos' element={<CaseCreateImageVideo />} />
                        <Route path='comments' element={<CaseCreateComments />} />
                        <Route path='settings' element={<CaseCreateSettings />} />
                    </Route>
                </Route>

                <Route path='/article-create' element={<ProtectedRoute />}>
                    <Route element={<ArticleCreateLayout />}>
                        <Route index element={<ArticleCreateContent />} />
                        <Route path='settings' element={<ArticleCreateSettings />} />
                        <Route path='preview' element={<ArticleCreatePreview />} />
                    </Route>
                </Route>
                <Route path='/article-edit/:id' element={<ProtectedRoute />}>
                    <Route element={<ArticleEditLayout />}>
                        <Route index element={<ArticleCreateContent />} />
                        <Route path='settings' element={<ArticleCreateSettings />} />
                        <Route path='preview' element={<ArticleCreatePreview />} />
                        <Route path='for-appoval' element={<SuggetsChanges />} />
                    </Route>
                </Route>

                <Route path='/register' >
                    <Route index element={<SignUp />} />
                </Route>
                <Route path='/recovery-password/:token' >
                    <Route index element={<RecoveryPassword />} />
                </Route>

                <Route element={<MainLayout />}>
                    <Route path='/terms' element={<TermsOfUse />} />
                    <Route path='/privacy' element={<PrivacyPolicy />} />
                </Route>
                <Route element={<MainLayout />}>
                    <Route path='*' element={<NotFound />} />
                </Route>

                <Route path='/sign-in' element={<SingIn />} />

            </>
        )
    );

    return <RouterProvider router={router} />;
};

