import React, { useEffect, useRef, useState } from 'react'
import { format, getYear, isToday, isYesterday } from 'date-fns';
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks'
import { useSelector } from 'react-redux'
import PageLoader from '../../components/parts/PageLoader'
import { useDispatch } from 'react-redux'
import { getNotificationsPageContentFeth, getUserNotificationsFeth, notificationsMarkAsReadAllFeth, notificationsMarkAsReadOneFeth } from '../../store/notifications/notificationsThunk'
import { getHeaderByDate, timeAgo } from '../../helpers/utils';
import SidebarArticlesPosts from '../../components/parts/sidebarPosts/SidebarArticlesPosts';
import SirebarLatestCases from '../../components/parts/sidebarPosts/SirebarLatestCases';
import SidebarPopularQuizes from '../../components/parts/sidebarPosts/SidebarPopularQuizes';
import { Link, useNavigate } from 'react-router-dom';
import { managingModeratorStatusFeth } from '../../store/forums/forumsThunk';
const helpLinks = [
    {
        title: 'FAQ',
        url: '/faq'
    },
    {
        title: 'Event Calendar',
        url: '/event-calendar'
    },
    {
        title: 'Pricing Plan',
        url: '/pricing-plan'
    },
    {
        title: 'About Us',
        url: '/about-us'
    },
    {
        title: 'Contact Us',
        url: '/contact-us'
    },
]

const Notifications = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { notificationsAll, isLoading, pagination, pageContent, newNotifications } = useSelector(state => state.notifications)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const loadMoreRef = useRef(null);
    const maxPagesRef = useRef(0);
    useEffect(() => {
        dispatch(getNotificationsPageContentFeth())
    }, [dispatch])
    useEffect(() => {
        const loadData = async () => {
            if (!hasMore || loading) return
            setLoading(true)
            const res = await dispatch(getUserNotificationsFeth(`page=${page}&limit=${limit}`)).unwrap()
            if (res) {
                setLoading(false)
                maxPagesRef.current = res.pagination.maxPages
                if (res.pagination.maxPages === page) {
                    setHasMore(false)
                }
            }

        }
        loadData()
    }, [dispatch, page])

    useEffect(() => {
        const handleScroll = () => {
            if (loadMoreRef.current) {
                const rect = loadMoreRef.current.getBoundingClientRect();
                if (rect.top <= window.innerHeight) {
                    loadNotifications();
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const loadNotifications = async () => {
        if (maxPagesRef.current > 1) {
            setPage(prev => prev + 1)
        }
    };
    const generateLink = (id, type) => {
        let link = null
        switch (type) {
            case 'Quiz':
                link = `/quiz-page/${id}/presentation`
                break
            case 'Case':
                link = `/cases/${id}`
                break
            case 'FlashCardSet':
                link = `/quiz/flashcards/${id}`
                break
            case 'Course':
                link = `/courses/${id}`
                break
            case 'Forum':
                link = `/forums/${id}`
                break
            case 'Article':
                link = `/articles/${id}`
                break
            case "MaterialImage":
                link = `/materials/image/${id}`
                break
            case "MaterialVideo":
                link = `/materials/video/${id}`
                break
            case  "MaterialDocument":
                link = `/materials/document/${id}`
                break
            case 'Thread':
                link = `/forums/thread/${id}`
                break
            case 'ArticleComment':
                link = `/articles/${id}#comments`
                break
            case 'QuizComment':
                link = `/quiz-page/${id}/discussion`
                break
            case 'CaseComment':
                link = `/cases/${id}#comments`
                break
            case 'ThreadComment':
                link = `/forums/thread/${id}#comments`
                break
        }
        console.log(link, type)
        navigate(link)
    }
    const marsAsReadAll = async () => {
        const res = await dispatch(notificationsMarkAsReadAllFeth()).unwrap()
    }
    const groupedNotifications = notificationsAll.reduce((acc, notification) => {
        const header = getHeaderByDate(notification.createdAt);
        if (!acc[header]) {
            acc[header] = [];
        }
        acc[header].push(notification);
        return acc;
    }, {});
    const markAsReadOne = (id) => {
        const res = dispatch(notificationsMarkAsReadOneFeth(id)).unwrap()
    }
    const moderHandler = async (e, status, id) => {
        e.preventDefault()
        const res = await dispatch(managingModeratorStatusFeth({id, body: {
            "status": status
        }})).unwrap()
    }
    const normaliseNotifications = Object.keys(groupedNotifications).map(header => ({
        header,
        notifications: groupedNotifications[header],
    }));
    console.log(notificationsAll)
    if (isLoading) return <PageLoader />
    return (
        <main className="content">
            <section className="page-section">
                <div className="container">

                    <div className="page-section--row">
                        <div className="page-sidebar --none-border !static">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    {pageContent?.latestArticles && pageContent?.latestArticles.length > 0 ?
                                        <SidebarArticlesPosts title={'Latest Articles'} posts={pageContent.latestArticles} />
                                        : null}
                                    {pageContent?.latestCases && pageContent?.latestCases?.length > 0 ?
                                        <SirebarLatestCases title={'Latest Cases'} cases={pageContent.latestCases} />
                                        : null}
                                    {pageContent?.popularQuizzes && pageContent?.popularQuizzes?.length > 0 ? <SidebarPopularQuizes
                                        title={'Popular Quizzes'}
                                        quizes={pageContent?.popularQuizzes}
                                    />
                                        : null
                                    }
                                </div>
                            </aside>
                        </div>
                        <div className="page-content overflow-visible self-stretch">
                            <article className="single-post flex-auto notification-page">
                                <div>
                                    <h2>
                                        Notifications
                                    </h2>

                                    <div className="discus-profile">
                                        <div className="discus-profile--head">
                                            <p className="text--lg">You have {newNotifications} notifications to go through.</p>
                                            <div className="discus-controls">
                                                <div>
                                                    <button type='button' onClick={marsAsReadAll} className="btn btn--md radius-5 btn--shadow ">
                                                        Mark all as Read
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {normaliseNotifications && normaliseNotifications.length ? normaliseNotifications.map((groupItem, index) => (
                                    <div key={index}>
                                        <div className="list-group list-group--md">
                                            <p className="text--sm color-secondary">{groupItem.header}</p>
                                            {groupItem.notifications.map((notification, idx) => (
                                                <div
                                                    className={`card card-notification  box-rounded--md 
                                                            ${notification.isRead ? 'bg-[#F5F5F5]' : 'box-shadow'}`
                                                    }
                                                    // onMouseEnter={() => {!notification.isRead && markAsReadOne(notification.id)}}
                                                    onClick={() => { !notification.isRead && markAsReadOne(notification.id) }}
                                                    key={idx}
                                                >
                                                    <div className=" notification-card">
                                                        <div className="notification-card__icon">
                                                            <img src={require("../../assets/img/support-icon.svg").default} alt="" />
                                                        </div>
                                                        <div className="notification-card__body">
                                                            <div className="notification-card__title">
                                                                <span className="text--lg font-semibold text-elipsis">{notification.title}</span>
                                                                <span className="notification-card__date text--md color-secondary verenada-font">{timeAgo(notification.createdAt)}</span>
                                                            </div>
                                                            <div className="text--md verenada-font">
                                                                {notification.text}
                                                            </div>
                                                        </div>
                                                        {(
                                                            notification.refType !== 'Password' &&
                                                            notification.refType !== 'System' &&
                                                            notification.refType !== 'Plan' &&
                                                            notification.refType !== 'Payment' && notification.refType !== 'ForumModerator'
                                                        ) &&
                                                            <div className="notification-card__actions">
                                                                <button onClick={() => generateLink(notification.refId, notification.refType)}
                                                                    type='button'
                                                                    className="btn btn--transparent btn--md radius-5">
                                                                    <span className="color-danger">
                                                                        View
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        }
                                                        {notification.refType === 'ForumModerator' && !notification?.isProcessed && <div className="notification-card__actions">
                                                            <button 
                                                                onClick={(e) => moderHandler(e, 'active', notification.refId)}
                                                                type='button'
                                                                className="btn btn--transparent btn--md radius-5">
                                                                <span>
                                                                    Accept
                                                                </span>
                                                            </button>
                                                            <button 
                                                                onClick={(e) => moderHandler(e, 'rejected',notification.refId)}
                                                                type='button'
                                                                className="btn btn--transparent btn--md radius-5">
                                                                <span className="color-danger">
                                                                    Reject
                                                                </span>
                                                            </button>
                                                        </div>}
                                                        {notification.refType === 'ForumModerator' && notification?.isProcessed && <div className="notification-card__actions">
                                                            <span>Answered</span>
                                                        </div>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )) : <h4 className='text-center'>You don't have notifications yet</h4>}
                                {loading && <span className="btn btn--shadow btn--xl radius-5 w-full">
                                    <span className="ico animate-spin">
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.7053 3.40707C14.1506 3.31066 13.5829 3.24999 13.0001 3.24999C12.4173 3.24999 11.8496 3.31066 11.2949 3.40707" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M22.2279 9.7207C22.6103 10.7705 22.8291 11.8982 22.8291 13.079" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M20.5271 6.76315C19.7926 5.8889 18.9054 5.14898 17.9109 4.57373" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M8.08939 4.57373C7.09489 5.14898 6.20873 5.8889 5.47314 6.76315" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M3.17097 13.079C3.17097 11.8971 3.38981 10.7694 3.77222 9.7207" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M8.08936 21.5842C9.06436 22.1486 10.1444 22.5484 11.2949 22.7499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M3.77222 16.4375C4.16872 17.5252 4.74288 18.5251 5.47305 19.395" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M14.7053 22.7499C15.8558 22.5484 16.9348 22.1486 17.9109 21.5842" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M20.5271 19.395C21.2573 18.5251 21.8314 17.5252 22.2279 16.4375" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </span>}
                                {hasMore && !loading && (
                                    <button ref={loadMoreRef} className='btn btn--shadow btn--xl radius-5 w-full' onClick={loadNotifications}>
                                        Load More
                                    </button>
                                )}
                            </article>

                        </div>
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    <SidebarHelpLinks
                                        title={'Useful links'}
                                        links={helpLinks}
                                    />
                                </div>
                            </aside>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    )
}

export default Notifications