import React, { useEffect } from 'react';
import { getImageSrc } from '../../helpers/utils';
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks';
import { Link } from 'react-scroll'


const TermsOfUse = () => {
    const links = [
        {
            "title": "Support",
            "url": "#"
        },
        {
            "title": "Event Calendar",
            "url": "#"
        },
        {
            "title": "Pricing Plan",
            "url": "#"
        },
        {
            "title": "Materials Library",
            "url": "#"
        },
        {
            "title": "Contact Us",
            "url": "#"
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="content">
            <section className="page-section">
                <div className="container">
                    <div className="column-group column-group--md items-center text-center max-w-[500px] mx-auto">
                        <div className="column-group column-group--md">
                            <div className="heading-row justify-center text-center">
                                <div className="heading-item--row">
                                    <span className="ico ico-50">
                                        <img src={getImageSrc("/uploads/f490bef1-86db-4170-a92c-749329fb70c0_1717139464436_.png")} />
                                    </span>
                                    <h1 className="heading">{`Terms of Use`}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-section--row">
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <p className="sidebar-label mb-[20px]">Contents</p>
                                <ul className="nav-article">
                                    <li key={`Lorem ipsum dolor`}>
                                        <Link
                                            activeClass="active"
                                            to={`${(`Lorem ipsum dolor`).replaceAll(" ", "_").toLowerCase()}`}
                                            spy={true}
                                            smooth={true}
                                            hashSpy={true}
                                            offset={-100}
                                            duration={500}
                                        >
                                            {`Lorem ipsum dolor`}
                                        </Link>
                                    </li>
                                    <li key={`Lorem ipsum dolor sit`}>
                                        <Link
                                            activeClass="active"
                                            to={`${(`Lorem ipsum dolor sit`).replaceAll(" ", "_").toLowerCase()}`}
                                            spy={true}
                                            smooth={true}
                                            hashSpy={true}
                                            offset={-100}
                                            duration={500}
                                        >
                                            {`Lorem ipsum dolor sit`}
                                        </Link>
                                    </li>
                                    <li key={`Lorem ipsum dolor sit amet`}>
                                        <Link
                                            activeClass="active"
                                            to={`${(`Lorem ipsum dolor sit amet`).replaceAll(" ", "_").toLowerCase()}`}
                                            spy={true}
                                            smooth={true}
                                            hashSpy={true}
                                            offset={-100}
                                            duration={500}
                                        >
                                            {`Lorem ipsum dolor sit amet`}
                                        </Link>
                                    </li>
                                </ul>
                            </aside>
                        </div>
                        <div className="page-content">
                            <article className="single-post">
                                <h2 id={(`Lorem ipsum dolor`).replaceAll(" ", "_").toLowerCase()}>Lorem ipsum dolor sit amet</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut turpis id magna pulvinar sodales. Nulla facilisi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla sit amet urna luctus, tincidunt leo ac, condimentum purus. Integer tempus eget ex id vehicula. Ut tincidunt ut libero sed feugiat.</p>
                                <h2 id={(`Lorem ipsum dolor sit`).replaceAll(" ", "_").toLowerCase()}>Lorem ipsum dolor sit amet</h2>
                                <p>Proin id suscipit magna. Vivamus at eros ac dolor lacinia vulputate nec nec neque. Fusce at orci ac tortor vestibulum auctor sed a nulla. Vestibulum eget eleifend enim. Sed eu lacus in neque egestas dignissim. Curabitur bibendum enim ac felis rutrum, eget consectetur sapien eleifend. Mauris quis mi id nunc vestibulum posuere.</p>
                                <h2 id={(`Lorem ipsum dolor sit amet`).replaceAll(" ", "_").toLowerCase()}>Lorem ipsum dolor sit amet</h2>
                                <p>Donec vel turpis sit amet elit commodo rhoncus et a purus. Morbi finibus nisl ligula, et dapibus tortor malesuada et. Phasellus eget nulla at nulla lobortis volutpat. Vestibulum accumsan non nisi a fermentum. Mauris dictum neque vitae urna euismod rhoncus. Curabitur efficitur lectus a metus sollicitudin, eu tincidunt magna feugiat. Vestibulum quis turpis et lorem accumsan interdum.</p>

                            </article>
                        </div>
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    <SidebarHelpLinks
                                        title={`USEFUL LINKS`}
                                        links={links}
                                    />
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TermsOfUse;
