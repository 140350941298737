const usersBase = '/api/contact-emails';

export const contactEmails = (instance) => ({
    async addCotactEmail(payload) {
        try {
            const { data } = await instance.post(`${usersBase}`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async updateCotactEmail(payload) {
        try {
            const { data } = await instance.put(`${usersBase}/${payload.id}`, payload);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

    async deleteCotactEmail(emailId) {
        try {
            const { data } = await instance.delete(`${usersBase}/${emailId}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },

});