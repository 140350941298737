import React from 'react'
import InputGroup from '../components/UI/formElements/InputGroup'
import CheckBoxGroup from '../components/UI/formElements/CheckBoxGroup'
import RadioButtonQuiz from '../components/UI/formElements/RadioButtonQuiz'
import InputGroupRow from '../components/UI/formElements/InputGroupRow'
import TextareaGroupRow from '../components/UI/formElements/TextareaGroupRow'
import TextareaGroup from '../components/UI/formElements/TextareaGroup'
import RadioDefault from '../components/UI/formElements/RadioDefault'
import SelectGroup from '../components/UI/formElements/SelectGroup'
import SelectGroupRow from '../components/UI/formElements/SelectGroupRow'
import SwithGroup from '../components/UI/formElements/SwithGroup'

const Ui = () => {
    return (
        <>
            <div className="container">
                <div className="flex flex-col flex-wrap gap-4 p-8">
                    <div className="flex gap-5">
                        <InputGroup
                            wrapperClasses={'input--lg'}
                            inputClasses={'input--outline'}
                            placeholder={'Nur'}
                            label={'First Name'}
                        />
                        <CheckBoxGroup
                            wrapperClasses={'input--lg'}

                        >
                            <p className="check-text">
                                Remember me
                            </p>
                        </CheckBoxGroup>

                    </div>

                    <div className="flex flex-col gap-2">
                        <RadioButtonQuiz
                            name={'question'}
                            value={'question1'}
                        >
                            <span className="quiz-answer-item__text">
                                <span className="quiz-answer-item__id">B)</span>
                                <span>2 months</span>
                            </span>
                        </RadioButtonQuiz>
                    </div>
                    <InputGroupRow
                        label={'Email'}
                        tips={'For multiple addresses please separate with “,”'}
                        inputClasses={'input--outline'}
                        placeholder={'Enter user email address(es)...'}
                    />
                    <TextareaGroupRow
                        label={'Additional Message'}
                        placeholder={'Enter your description...'}
                        textareaClasses={'input--outline py-[16px]'}
                    />
                    <TextareaGroup
                        textareaClasses={'input--outline p-[16px]'}
                        placeholder={'Enter your notes...'}
                    />
                    <RadioDefault>
                        <div className="w-full">
                            <p className="text--sm mb-[6px]">Send to the existing email</p>
                            <p className="color-secondary text--xs">Nur.Alam@mail.com</p>
                        </div>
                    </RadioDefault>
                    <SelectGroup
                        label={'Billing address'}
                        wrapperClasses={'select--outline input--lg'}
                        options={[
                            {value:"value1", label:"value1"},
                            {value:"value2", label:"value2"},
                        ]}
                    />
                    <SelectGroup
                        // label={'Billing address'}
                        wrapperClasses={'input--lg'}
                        options={[
                            {value:"value1", label:"value1"},
                            {value:"value2", label:"value2"},
                        ]}
                    />
                    <SelectGroupRow
                        groupClasses={'items-center gap--lg md:flex-nowrap'}
                        label={'Billing address'}
                        wrapperClasses={'select--outline input--md w-full'}
                        options={[
                            {value:"value1", label:"value1"},
                            {value:"value2", label:"value2"},
                        ]}
                    />
                    <SwithGroup
                        wrapperClasses='w-auto min-w-[163px]'
                        leftNode={
                            <>
                                <span className="ico ico-18">
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.5625 7.3125H4.5C3.67125 7.3125 3 6.64125 3 5.8125V3.75C3 2.92125 3.67125 2.25 4.5 2.25H6.5625C7.39125 2.25 8.0625 2.92125 8.0625 3.75V5.8125C8.0625 6.64125 7.39125 7.3125 6.5625 7.3125Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15 7.3125H12.9375C12.1087 7.3125 11.4375 6.64125 11.4375 5.8125V3.75C11.4375 2.92125 12.1087 2.25 12.9375 2.25H15C15.8288 2.25 16.5 2.92125 16.5 3.75V5.8125C16.5 6.64125 15.8288 7.3125 15 7.3125Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.5625 15.75H4.5C3.67125 15.75 3 15.0788 3 14.25V12.1875C3 11.3587 3.67125 10.6875 4.5 10.6875H6.5625C7.39125 10.6875 8.0625 11.3587 8.0625 12.1875V14.25C8.0625 15.0788 7.39125 15.75 6.5625 15.75Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15 15.75H12.9375C12.1087 15.75 11.4375 15.0788 11.4375 14.25V12.1875C11.4375 11.3587 12.1087 10.6875 12.9375 10.6875H15C15.8288 10.6875 16.5 11.3587 16.5 12.1875V14.25C16.5 15.0788 15.8288 15.75 15 15.75Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <span className="switch-control--text">Grid</span>
                            </>
                        }
                        rightNode={
                            <>
                                <span className="ico ico-18">
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.49927 4.82752H15.7527" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M15.7527 9H7.49927" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M7.49927 13.1725H15.7527" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M4.12271 4.45236C3.91552 4.45236 3.74756 4.62033 3.74756 4.82752C3.74756 5.03471 3.91552 5.20267 4.12271 5.20267C4.32991 5.20267 4.49787 5.03471 4.49787 4.82752C4.49787 4.62033 4.32991 4.45236 4.12271 4.45236" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M4.12271 8.62485C3.91552 8.62485 3.74756 8.79281 3.74756 9C3.74756 9.2072 3.91552 9.37516 4.12271 9.37516C4.32991 9.37516 4.49787 9.2072 4.49787 9C4.49787 8.79281 4.32991 8.62485 4.12271 8.62485" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M4.12271 12.7973C3.91552 12.7973 3.74756 12.9653 3.74756 13.1725C3.74756 13.3797 3.91552 13.5476 4.12271 13.5476C4.32991 13.5476 4.49787 13.3797 4.49787 13.1725C4.49787 12.9653 4.32991 12.7973 4.12271 12.7973" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <span className="switch-control--text">List</span>
                            </>
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default Ui
