import React from 'react'
import ModalTemplate from './ModalTemplate'
import { Link } from 'react-router-dom'


const OptionToCreateModal = ({ open, handelClose}) => {
    return (
        <ModalTemplate open={open} handelClose={handelClose}>
            <div className="modal__body items-center">
                <div className="modal__body-heading text-center">
                    <h2>Select one option to create </h2>
                </div>
                <div className="flex flex-wrap gap-[12px] w-full">
                    <Link to={"/quiz-create/general"} className="btn btn--shadow create-quize-card radius-5 flex-1">
                        <img src={require("../../assets/img/Quiz-1.png")} className="mb-[14px]" alt="Quiz" />
                        <div className="font-semibold text-[16px] mb-[12px]">Create Quiz</div>
                        <div className="text-[14px] color-secondary">Create test with pictures, videos and hints.</div>
                    </Link>
                    <Link to={"/flashcards-create/general"} className="btn btn--shadow create-quize-card radius-5 flex-1">
                        <img src={require("../../assets/img/Flashcards-1.png")} className="mb-[14px]" alt="Flashcards" />
                        <div className="font-semibold text-[16px] mb-[12px]">Create Flashcards</div>
                        <div className="text-[14px] color-secondary">Create cards with questions and answers to train.</div>
                    </Link>
                </div>
            </div>
        </ModalTemplate>
    )
}

export default OptionToCreateModal
