import { createSlice } from '@reduxjs/toolkit';
import { getFiltersByName, getFiltersDiagnosisFeth, getFiltersForFooterFeth } from './filtersThukns';



const initialState = {
    filters: null,
    filterDiagnosis: null,
    filterFooter: [],
    isLoadingFilters: false,
}

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
            
    },
    
    extraReducers(builder) {
        builder.addCase(getFiltersByName.fulfilled, (state, { payload }) => {
            state.filters = payload?.data
            state.isLoading = false
        })
        builder.addCase(getFiltersByName.pending, (state, { payload }) => {
            state.isLoading = true
        })
        builder.addCase(getFiltersByName.rejected, (state, { payload }) => {
            state.filters = null
            state.isLoading = false
        })
        builder.addCase(getFiltersDiagnosisFeth.fulfilled, (state, { payload }) => {
            state.filterDiagnosis = payload?.data
            state.isLoading = false
        })
        builder.addCase(getFiltersDiagnosisFeth.pending, (state, { payload }) => {
            state.isLoading = true
        })
        builder.addCase(getFiltersDiagnosisFeth.rejected, (state, { payload }) => {
            state.filterDiagnosis = null
            state.isLoading = false
        })
        builder.addCase(getFiltersForFooterFeth.fulfilled, (state, { payload }) => {
            state.filterFooter = payload
            state.isLoading = false
        })
        builder.addCase(getFiltersForFooterFeth.pending, (state, { payload }) => {
            state.isLoading = true
        })
        builder.addCase(getFiltersForFooterFeth.rejected, (state, { payload }) => {
            state.isLoading = false
        })
    }
})

export const {
   
} = filtersSlice.actions;
export const getFilteres = (state) => state.filters.filters;
export const getFilterDiagnosis = (state) => state.filters.filterDiagnosis;
export const getIsLoadingFilters = (state) => state.filters.isLoadingFilters;
export const getFilterFooter = (state) => state.filters.filterFooter;

export default filtersSlice.reducer