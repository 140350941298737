import React from 'react'
import 'react-phone-number-input/style.css'
// import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const InputPhoneCodeMask = ({ label, wrapperClasses = '', inputClasses = '', type = 'text', placeholder, defaultValue, value='', error, errorMessage,onChange, ...props }) => {
   
    return (
        <div className={`input-wrapper ${wrapperClasses}`}>
          {label && <label className="label">{label}</label>}
          <PhoneInput
            country={'us'}
            type={type}
            enableSearch={true}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            {...props}
          />
          {
            errorMessage && error ?
              <p className='color-danger text--xs mt-1'>{errorMessage}</p>
              :
              null
          }
        </div>
      )
}
