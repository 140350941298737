import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import HeaderQuiz from '../components/parts/HeaderQuiz'
import SidebarQuizPage from '../components/parts/SidebarQuizPage'
import { useDispatch } from 'react-redux'
import { getQuizGeneralByQuizIdFeth, getQuizHintsByQuizIdFeth, getQuizMediaByQuizIdFeth, getQuizQuestionsByQuizIdFeth, getQuizResultsByQuizIdFeth } from '../store/quizzes/quizzesThunks'
import { useSelector } from 'react-redux'
import {setActiveQuestionIndex } from '../store/quizzes/quizzesSlice'
import PageLoader from '../components/parts/PageLoader'

const QuizPageLayout = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const {quiz, isLoading} = useSelector(state => state.quizzes)
    const [path, setPath] = useState('')
    useEffect(() => {
        if(id) {
            dispatch(getQuizGeneralByQuizIdFeth(id))
            dispatch(getQuizQuestionsByQuizIdFeth(id))
            dispatch(getQuizResultsByQuizIdFeth(id))
            dispatch(getQuizMediaByQuizIdFeth(id))
            dispatch(getQuizHintsByQuizIdFeth(id))
        }
    }, [id]);
    
    const navLinkHndler = (value) => {
        setPath(value)
    }
    
    if(isLoading) return <PageLoader />
    return (
        <div className='quiz-page'>
            <HeaderQuiz desctiption={path} name={quiz?.name} prev={quiz?.prevQuizId ? `/quiz-page/${quiz?.prevQuizId}/presentation` : null} next={quiz?.nextQuizId ? `/quiz-page/${quiz?.nextQuizId}/presentation`: null} />
            <SidebarQuizPage quizData={quiz} navHandler={navLinkHndler}/>
            <div className="custom-overlay toggler-menu">
            </div>
            <div className="wrapper header-fixed sidebar-left-fixed">
                <Outlet />
            </div>
        </div>
    )
}

export default QuizPageLayout