import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getAllMaterialLibraryFeth = createAsyncThunk(
    '/getAllMaterialLibraryFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.materialLibrary.getAllMaterialLibrary(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getMaterialLibraryByIdFeth = createAsyncThunk(
    '/getMaterialLibraryByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.materialLibrary.getMaterialLibraryById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createMaterialLibraryFeth = createAsyncThunk(
    '/createMaterialLibraryFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.materialLibrary.createMaterialLibrary(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const likeMaterialLibraryByIdFeth = createAsyncThunk(
    '/likeMaterialLibraryByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.materialLibrary.likeMaterialLibraryById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const favoriteMaterialLibraryByidFeth = createAsyncThunk(
    '/favoriteMaterialLibraryByidFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.materialLibrary.favoriteMaterialLibraryByid(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editMaterialLibraryByIdFeth = createAsyncThunk(
    '/editMaterialLibraryByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.materialLibrary.editMaterialLibraryById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const viewMaterialLibraryByIdFeth = createAsyncThunk(
    '/viewMaterialLibraryByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.materialLibrary.viewMaterialLibraryById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const delMaterialLibraryByIdFeth = createAsyncThunk(
    '/delMaterialLibraryByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.materialLibrary.delMaterialLibraryById(payload);
            if (res.success) {
                toast.success(res.message)
                return { id: payload ,res};
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
