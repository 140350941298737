import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as StarIcon } from "../../assets/img/ico-star.svg"
import { getImageSrc } from '../../helpers/utils'
import { toggleFavoriteFlashcardCardByIdFetch } from '../../store/flashcards/flashcardsThunks'

const Flashcard = ({ data, selected, onSelect, onOpen }) => {
    const dispatch = useDispatch()
    const [isFront, setIsFront] = useState(false)


    const handleSelect = async (e) => {
        e.stopPropagation()
        await dispatch(toggleFavoriteFlashcardCardByIdFetch({ id: data.id })).unwrap()
    }
    const handleClick = (e) => {
        setIsFront(!isFront)
        onOpen && onOpen(!isFront)
    }

    useEffect(() => {
        setIsFront(false)
    }, [data?.id])

    if (!data) return

    return (
        <div className={`card card-flipp flashcard-custom ${isFront ? "--flip" : ""}`}>
            <div className="card-flipp-content" onClick={handleClick}>
                <div className="card-front card flashcard-custom flash-1">
                    <div className="card-header">
                        <div className="card-actions">
                            <label className="check-btn" data-flip-fix="">
                                <input type="checkbox" checked={data?.isFavorite} onChange={handleSelect} className="hidden" />
                                <div className="ico ico--sm btn--shadow-2 rounded-full btn-like">
                                    <span className="ico ico-22">
                                        <StarIcon />
                                    </span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="card-body">
                        {
                            (data.question_image && data.question_image !== 'null') ?
                                <div className="wrapp-image custom-card-img">
                                    <img src={getImageSrc(data.question_image)} alt={data?.question_text} />
                                </div>
                                : null
                        }
                        <div className="heading-block">
                            <h3 className="heading">{data?.question_text}</h3>
                        </div>
                    </div>
                </div>
                <div className="card-back card flashcard-custom flash-2">
                    <div className="card-header">
                        <div className="card-actions">
                            <label className="check-btn" data-flip-fix="">
                                <input type="checkbox" checked={data?.isFavorite} onChange={handleSelect} className="hidden" />
                                <div className="ico ico--sm btn--shadow-2 rounded-full btn-like">
                                    <span className="ico ico-22">
                                        <StarIcon />
                                    </span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="card-body">
                        {
                            (data?.answer_image && data?.answer_image !== 'null') ?
                                <div className="wrapp-image custom-card-img">
                                    <img src={getImageSrc(data.answer_image)} alt={data?.answer_text} />
                                </div>
                                : null
                        }
                        <div className="heading-block">
                            <h4 className="heading">
                                {data?.answer_text}
                            </h4>
                            <p className="heading-desk-2">{data?.explanation}</p>
                            {data?.links?.length ?
                                data.links.map((link, index) => (
                                    <a key={index} href={link} className='color-danger ff-2 break-words'>{link}</a>
                                ))
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Flashcard