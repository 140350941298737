import React, { useEffect } from 'react'
import logo from "../../assets/img/logo-footer.png"
import { Link } from 'react-router-dom'
import { getAllPages } from '../../store/pages/pagesSlice'
import { useSelector } from 'react-redux'
import { getFiltersForFooterFeth } from '../../store/filters/filtersThukns'
import { useDispatch } from 'react-redux'
import { getFilterFooter } from '../../store/filters/filtersSlice'


const Footer = () => {
    const maxCount = 5
    const pages = useSelector(getAllPages)
    const filters = useSelector(getFilterFooter)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFiltersForFooterFeth())
    }, [])
    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="footer-top-body">
                        <div className="footer-top-body__info">
                            <a href="" className="footer-logo"><img src={logo} alt="logo" /></a>
                            <p className="footer-desc">Some text here about medical educational platform Medvise.</p>
                            <div className="footer-social-list">
                                <a href="" className="btn btn--default-light btn--square-md  rounded-full">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.22 2H14.78C17.66 2 20 4.34 20 7.22V14.78C20 16.1644 19.45 17.4922 18.4711 18.4711C17.4922 19.45 16.1644 20 14.78 20H7.22C4.34 20 2 17.66 2 14.78V7.22C2 5.83557 2.54996 4.50784 3.5289 3.5289C4.50784 2.54996 5.83557 2 7.22 2ZM7.04 3.8C6.1807 3.8 5.35659 4.14136 4.74897 4.74897C4.14136 5.35659 3.8 6.1807 3.8 7.04V14.96C3.8 16.751 5.249 18.2 7.04 18.2H14.96C15.8193 18.2 16.6434 17.8586 17.251 17.251C17.8586 16.6434 18.2 15.8193 18.2 14.96V7.04C18.2 5.249 16.751 3.8 14.96 3.8H7.04ZM15.725 5.15C16.0234 5.15 16.3095 5.26853 16.5205 5.4795C16.7315 5.69048 16.85 5.97663 16.85 6.275C16.85 6.57337 16.7315 6.85952 16.5205 7.0705C16.3095 7.28147 16.0234 7.4 15.725 7.4C15.4266 7.4 15.1405 7.28147 14.9295 7.0705C14.7185 6.85952 14.6 6.57337 14.6 6.275C14.6 5.97663 14.7185 5.69048 14.9295 5.4795C15.1405 5.26853 15.4266 5.15 15.725 5.15ZM11 6.5C12.1935 6.5 13.3381 6.97411 14.182 7.81802C15.0259 8.66193 15.5 9.80653 15.5 11C15.5 12.1935 15.0259 13.3381 14.182 14.182C13.3381 15.0259 12.1935 15.5 11 15.5C9.80653 15.5 8.66193 15.0259 7.81802 14.182C6.97411 13.3381 6.5 12.1935 6.5 11C6.5 9.80653 6.97411 8.66193 7.81802 7.81802C8.66193 6.97411 9.80653 6.5 11 6.5ZM11 8.3C10.2839 8.3 9.59716 8.58446 9.09081 9.09081C8.58446 9.59716 8.3 10.2839 8.3 11C8.3 11.7161 8.58446 12.4028 9.09081 12.9092C9.59716 13.4155 10.2839 13.7 11 13.7C11.7161 13.7 12.4028 13.4155 12.9092 12.9092C13.4155 12.4028 13.7 11.7161 13.7 11C13.7 10.2839 13.4155 9.59716 12.9092 9.09081C12.4028 8.58446 11.7161 8.3 11 8.3Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <a href="" className="btn btn--default-light btn--square-md rounded-full">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 11C20 6.032 15.968 2 11 2C6.032 2 2 6.032 2 11C2 15.356 5.096 18.983 9.2 19.82V13.7H7.4V11H9.2V8.75C9.2 7.013 10.613 5.6 12.35 5.6H14.6V8.3H12.8C12.305 8.3 11.9 8.705 11.9 9.2V11H14.6V13.7H11.9V19.955C16.445 19.505 20 15.671 20 11Z" fill="currentColor" />
                                    </svg>
                                </a>
                                <a href="" className="btn btn--default-light btn--square-md rounded-full">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.1757 3H18.9357L12.9058 9.8925L20 19.2698H14.4455L10.0955 13.5817L5.117 19.2698H2.3555L8.8055 11.8973L2 3.00075H7.6955L11.6278 8.19975L16.1757 3ZM15.2075 17.6182H16.7367L6.8645 4.56525H5.2235L15.2075 17.6182Z" fill="currentColor" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="footer-top-body__menu-list ">
                            <div className="row g-80">
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Article Topics</span>
                                    {filters?.articleTopics?.length > 0 ? <ul className="footer-menu">
                                        {filters?.articleTopics?.slice(0, maxCount)?.map(topic => (
                                            <li key={topic.id}>
                                                <Link
                                                    to={`/articles?${topic.type}=${String(topic.name).replace(' ', '+')}`}>
                                                    {topic.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul> : null}
                                    {filters?.articleTopics?.length > maxCount - 1 &&
                                        <Link to={`/articles`} className="btn footer-show-more-btn">Show More</Link>
                                    }
                                </div>
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Courses</span>
                                    {filters?.courses?.length > 0 ? <ul className="footer-menu">
                                        {filters?.courses?.slice(0, maxCount)?.map(topic => (
                                            <li key={topic.id}>
                                                <Link
                                                    to={`/courses?${topic.type}=${String(topic.name).replace(' ', '+')}`}>
                                                    {topic.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul> : null}
                                </div>
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Cases</span>
                                    {filters?.cases?.length > 0 ? <ul className="footer-menu">
                                        {filters?.cases?.slice(0, maxCount)?.map(topic => (
                                            <li key={topic.id}>
                                                <Link
                                                    to={`/cases?${topic.type}=${String(topic.name).replace(' ', '+')}`}>
                                                    {topic.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul> : null}
                                    {filters?.cases?.length > maxCount - 1 &&
                                        <Link to={`/cases`} className="btn footer-show-more-btn">Show More</Link>
                                    }
                                </div>
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Platform</span>
                                    <ul className="footer-menu">
                                        {pages && pages.map(item => (
                                            item.id !== 1 ? <li key={item.id}>
                                                <Link to={item.url} >
                                                    {item.name}
                                                </Link>
                                            </li> : null
                                        ))}
                                        <li>
                                            <Link to={'/anatomy'}>Anatomy</Link>
                                        </li>
                                        <li>
                                            <Link to={'/forums'}>Forums</Link>
                                        </li>
                                        <li>
                                            <Link to={'/quiz'}>Quizzes</Link>
                                        </li>

                                    </ul>
                                </div>
                                <div className="footer-top-body__menu-list--item">
                                    <span className="pill rounded-full pill-default">Resources</span>
                                    <ul className="footer-menu">
                                        <li>
                                            <Link to={'/materials'}>Materials Library </Link>
                                        </li>
                                        {pages && pages.map(item => (
                                            item.id === 1 ? <li key={item.id}>
                                                <Link to={item.url} >
                                                    {item.name}
                                                </Link>
                                            </li> : null
                                        ))}
                                        <li>
                                            <Link to={'/events'}>Event Calendar</Link>
                                        </li>
                                        <li>
                                            <Link to={'/pricing'}>Pricing Plan</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-bottom-body">
                        <p>© 2024 Medwise</p>
                        <ul className="footer-bottom-list">
                            <li><Link to={'/terms'}>Terms of Use</Link></li>
                            <li><Link to={'/privacy'}>Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer