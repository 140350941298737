import React, { useEffect, useMemo } from 'react'
import { CreateFormHeader } from '../../components/parts/CreateFormHeader'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Pagination from '../../components/parts/Pagination'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { getArticlesVersiunsForApprovalFeth, moderateArticleAuthorFeth } from '../../store/articles/articlesThunks'
import Loader from '../../components/UI/Loader'
import CardArticle from '../../components/parts/Cards/CardArticle'
import PageLoader from '../../components/parts/PageLoader'

import { ReactComponent as PlaceholderIcon } from "../../assets/img/user-placeholder.svg"
import { getImageSrc } from '../../helpers/utils'

const SuggetsChanges = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { suggetsArtikles, pagination, isLoading } = useSelector(state => state.articles)
    const [searchParams, setSearchParams] = useSearchParams()
    const params = useMemo(() => ({

    }), [searchParams])
    const fetchData = async () => {
        const res = await dispatch(getArticlesVersiunsForApprovalFeth({ id: id, qwery: `status=pending&${searchParams.toString()}` })).unwrap()
    }
    useEffect(() => {
        fetchData()
    }, [searchParams])
    const conf = {
        title: 'Suggets Changes',
    }
    const moderateHandler = async (status) => {
        const body = {
            "status": status,   // required. "approved", "rejected"
            // "comment": "Good job"  // optional 
        }
        const res = await dispatch(moderateArticleAuthorFeth({ id, body })).unwrap()
    }
    // if (isLoading) return <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
    //     <Loader />
    // </div>

    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz create-article pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row gutters-16 flex-auto">
                                        {
                                            suggetsArtikles && suggetsArtikles.length && !isLoading ?
                                                suggetsArtikles.map((card, index) => (
                                                    <div key={index} className="col-12" >
                                                        <div className='flex flex-wrap w-full box-shadow p-3 rounded-lg'>
                                                            {card?.user ? <Link to={`/profile/${card?.user?.id}`} className="user-info user-info--xs">
                                                                <div className="ico user-ico  image-wrapper rounded-full">
                                                                    {
                                                                        card?.user?.photo ?
                                                                            <img src={getImageSrc(card?.user?.photo)} />
                                                                            : <PlaceholderIcon />
                                                                    }
                                                                </div>
                                                                <p className="user-name">{(card?.user?.firstName || '') + ' ' + (card?.user?.lastName || '')}</p>
                                                            </Link> : null}
                                                            <div className="flex flex-wrap ml-auto gap-2 ">
                                                                <Link target='_blank' to={`/articles/${id}/?versionId=${card.versionId}`} className='btn btn--md btn--shadow rounded-sm'>View</Link>
                                                                {card?.status === 'pending' && <>
                                                                    <button onClick={() => moderateHandler('approved')} className='btn btn--md btn--primary rounded-sm'>Accept</button>
                                                                    <button onClick={() => moderateHandler('rejected')}  className='btn btn--md btn--danger rounded-sm'>Reject</button>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                : isLoading ?
                                                    <PageLoader /> :
                                                    <h2 className='text-center'>No articles to confirm</h2>

                                        }
                                    </div>
                                    <Pagination {...pagination} params={params} />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default SuggetsChanges
