import React, { useEffect, useState } from 'react'
import SidebarHelpLinks from '../../components/parts/SidebarHelpLinks'
import pdf from "../../assets/img/Pdf-File.svg"
import word from "../../assets/img/Word.svg"
import SingleActions from '../../components/parts/SingleActions'
import BreadCrambs from '../../components/parts/BreadCrambs'
import { favoriteMaterialLibraryByidFeth, getMaterialLibraryByIdFeth, likeMaterialLibraryByIdFeth, viewMaterialLibraryByIdFeth } from '../../store/materials/materialLibraryThunks'
import PageLoader from '../../components/parts/PageLoader'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ReactDocViewer from '../../components/parts/ReactDocViewer'
import { getDateToString, getImageSrc } from '../../helpers/utils'
import SidebarRelatedDocuments from '../../components/parts/sidebarPosts/SidebarRelatedDocuments'

const SingleDocument = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { material, isLoading } = useSelector(state => state.materialLibrary)
    
    const helpLinks = [
        {
            title: 'FAQ',
            url: '/faq'
        },
        {
            title: 'Event Calendar',
            url: '/event-calendar'
        },
        {
            title: 'Pricing Plan',
            url: '/pricing-plan'
        },
        {
            title: 'About Us',
            url: '/about-us'
        },
        {
            title: 'Contact Us',
            url: '/contact-us'
        },
    ]
    const breadcramds = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/materials',
            label: 'Material Library'
        },
        {
            link: '/materials/image/:id',
            label: material?.title
        },
    ]
    const toggleLike = () => {
        dispatch(likeMaterialLibraryByIdFeth(id))

    }
    const toggleFavorite = () => {
        dispatch(favoriteMaterialLibraryByidFeth(id))
    }
    useEffect(() => {
        if (id) {
            dispatch(getMaterialLibraryByIdFeth(id))
            dispatch(viewMaterialLibraryByIdFeth(id))
        }
    }, [id, dispatch])
    if (isLoading) return <PageLoader />
    return (
        <main className="content">
            <BreadCrambs breabcrambs={breadcramds} />
            <section className="page-section">
                <div className="container">

                    <div className="page-section--row">
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    {material?.relatedMaterials && material?.relatedMaterials.length > 0 ? <SidebarRelatedDocuments 
                                        documents={material?.relatedMaterials}
                                        title={'Documents'}
                                    /> : null}
                                </div>
                            </aside>
                        </div>
                        <div className="page-content">
                            <article className="single-post">
                                <div>
                                    <div className="pill-custom pill-custom-2">
                                        <span className="pill-text">Digital Health</span>
                                    </div>
                                    <h2>
                                        Interventional Radiology
                                    </h2>
                                    <SingleActions
                                        likes={material?.likes}
                                        user={material?.user}
                                        displayUser={true}
                                        createAt={material?.updatedAt}
                                        toggleLike={toggleLike}
                                        toggleFavorite={toggleFavorite}
                                        isLikeProps={material?.isLiked}
                                        isFavoriteProps={material?.isFavorite}
                                        hideDrop = {true}
                                    />
                                </div>
                                <div>
                                    <ReactDocViewer fileUrl={getImageSrc(material?.fileName)} fileType={material?.fileName.split('.').pop()}/>
                                </div>
                            </article>
                        </div>
                        <div className="page-sidebar --none-border">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    <SidebarHelpLinks
                                        title={'Useful links'}
                                        links={helpLinks}
                                    />
                                </div>
                            </aside>
                        </div>
                    </div>

                </div>
            </section>
        </main>
    )
}

export default SingleDocument
