import React, { useEffect, useState } from 'react'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'
import SwitchViewType from '../../components/parts/SwitchViewType'
import CardMaterials from '../../components/parts/Cards/CardMaterials'
import { filterOptions } from '../../helpers/constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { useSelector } from 'react-redux'
import { getAllMaterialLibraryFeth } from '../../store/materials/materialLibraryThunks'
import PageLoader from '../../components/parts/PageLoader'
import { useMemo } from 'react'

export const Favoritelinks = [
    {
        label: "Quizzes",
        to: "/favorites",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.2534 14.3647V17.3176" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.2533 6.6691V9.62199H19.2542C18.8264 9.53565 18.3834 9.56445 17.9704 9.70544C17.2028 9.96788 16.6204 10.6013 16.4233 11.3882C16.2379 12.1134 16.3979 12.8835 16.8566 13.4749C17.3154 14.0664 18.0216 14.4128 18.77 14.4136C18.9326 14.4133 19.0948 14.397 19.2542 14.365V17.3179C19.2542 17.8313 19.0503 18.3237 18.6873 18.6868C18.3242 19.0498 17.8318 19.2538 17.3184 19.2538H14.3655V19.2547C14.4518 18.8269 14.423 18.3838 14.282 17.9708C14.0196 17.2032 13.3861 16.6209 12.5992 16.4237C11.874 16.2384 11.1039 16.3983 10.5125 16.8571C9.92108 17.3158 9.57467 18.022 9.57388 18.7705C9.57415 18.9331 9.59043 19.0953 9.62248 19.2547L6.6705 19.2538C5.60162 19.2539 4.73493 18.3877 4.73461 17.3188V14.3659C4.5752 14.3979 4.41301 14.4142 4.25041 14.4145C3.50192 14.4137 2.79575 14.0673 2.337 13.4759C1.87824 12.8844 1.71832 12.1143 1.90368 11.3891C2.10079 10.6022 2.68315 9.96879 3.45074 9.70636C3.86376 9.56537 4.30681 9.53657 4.73461 9.62291H4.73369V6.67001C4.73366 6.15657 4.9376 5.66415 5.30066 5.30109C5.66372 4.93803 6.15614 4.73408 6.66958 4.73412H9.62248C9.59043 4.57471 9.57415 4.41253 9.57388 4.24992C9.57467 3.50143 9.92108 2.79526 10.5125 2.33651C11.1039 1.87775 11.874 1.71783 12.5992 1.90319C13.3858 2.10086 14.019 2.68301 14.282 3.45025C14.423 3.86327 14.4518 4.30633 14.3655 4.73412H17.3184C17.8318 4.73408 18.3242 4.93803 18.6873 5.30109C19.0503 5.66415 19.2542 6.15657 19.2542 6.67001" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
    },
    {
        label: "Flashcards",
        to: "/favorites/flashcards",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.4903 20.1666H4.68508C3.61625 20.1666 2.75 19.3004 2.75 18.2315V8.5552C2.75 7.48637 3.61625 6.62012 4.68508 6.62012H10.4903C11.5592 6.62012 12.4254 7.48637 12.4254 8.5552V18.2315C12.4263 19.3004 11.5592 20.1666 10.4903 20.1666Z" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.93945 6.62031L9.83687 3.26806C10.1137 2.23589 11.1752 1.62264 12.2074 1.89947L17.8146 3.40189C18.8468 3.67872 19.46 4.73931 19.1832 5.77239L16.6789 15.1187C16.402 16.1509 15.3415 16.7641 14.3093 16.4873L12.4255 15.9831" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
    },
    {
        label: "Courses",
        to: "/favorites/courses",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.4526 2H5.88114C5.12481 2.00023 4.39897 2.29355 3.86064 2.81652C3.32232 3.33949 3.01478 4.05005 3.00457 4.7945L3 4.78888V17.1639C3.00015 17.916 3.30375 18.6373 3.84403 19.1692C4.38432 19.701 5.11706 19.9999 5.88114 20H15.5954C16.2263 20 16.7143 19.496 16.7143 18.875V7.0625C16.7143 6.752 16.4823 6.5 16.1669 6.5H5.88114C4.92229 6.5 4.14286 5.77887 4.14286 4.83612C4.14331 4.38244 4.3266 3.94747 4.65249 3.62667C4.97838 3.30587 5.42026 3.12545 5.88114 3.125H17.8571V16.0625C17.8571 16.373 18.1371 16.625 18.4526 16.625C18.768 16.625 19 16.373 19 16.0625V2.5625C19 2.252 18.768 2 18.4526 2ZM5.88114 7.625H15.5726L15.5954 18.875H5.88114C5.42026 18.8746 4.97838 18.6941 4.65249 18.3733C4.3266 18.0525 4.14331 17.6176 4.14286 17.1639V7.07488C4.62286 7.42138 5.22286 7.625 5.88114 7.625Z" fill="#6F7D8C" stroke="#6F7D8C" strokeWidth="0.2" />
            </svg>
        )
    },
    {
        label: "Cases",
        to: "/favorites/cases",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.4375 6.33333H14.6562V4.58333C14.6562 4.42862 14.597 4.28025 14.4915 4.17085C14.386 4.06146 14.2429 4 14.0938 4H7.90625C7.75707 4 7.61399 4.06146 7.5085 4.17085C7.40301 4.28025 7.34375 4.42862 7.34375 4.58333V6.33333H2.5625C2.41332 6.33333 2.27024 6.39479 2.16475 6.50419C2.05926 6.61358 2 6.76196 2 6.91667V17.4167C2 17.5714 2.05926 17.7197 2.16475 17.8291C2.27024 17.9385 2.41332 18 2.5625 18H19.4375C19.5867 18 19.7298 17.9385 19.8352 17.8291C19.9407 17.7197 20 17.5714 20 17.4167V6.91667C20 6.76196 19.9407 6.61358 19.8352 6.50419C19.7298 6.39479 19.5867 6.33333 19.4375 6.33333ZM8.46875 5.16667H13.5312V6.33333H8.46875V5.16667ZM18.875 16.8333H3.125V7.5H18.875V16.8333Z" fill="#6F7D8C" stroke="#6F7D8C" strokeWidth="0.2" />
            </svg>
        )
    },
    {
        label: "Articles",
        to: "/favorites/articles",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.1875 7.59V5.15625C17.1875 4.51807 16.934 3.90603 16.4827 3.45477C16.0315 3.00352 15.4194 2.75 14.7812 2.75H5.15625C4.51807 2.75 3.90603 3.00352 3.45477 3.45477C3.00352 3.90603 2.75 4.51807 2.75 5.15625V16.8438C2.75 17.4819 3.00352 18.094 3.45477 18.5452C3.90603 18.9965 4.51807 19.25 5.15625 19.25H16.8438C17.4819 19.25 18.094 18.9965 18.5452 18.5452C18.9965 18.094 19.25 17.4819 19.25 16.8438V9.96875C19.2493 9.39062 19.0405 8.83205 18.6618 8.39524C18.283 7.95844 17.7597 7.67259 17.1875 7.59ZM12.3372 17.875H5.15625C4.88275 17.875 4.62044 17.7663 4.42705 17.573C4.23365 17.3796 4.125 17.1173 4.125 16.8438V5.15625C4.125 4.88275 4.23365 4.62044 4.42705 4.42705C4.62044 4.23365 4.88275 4.125 5.15625 4.125H14.7812C15.0548 4.125 15.3171 4.23365 15.5105 4.42705C15.7038 4.62044 15.8125 4.88275 15.8125 5.15625V17.875H12.3372ZM17.875 16.8438C17.8744 17.0565 17.808 17.2639 17.6849 17.4374C17.5618 17.611 17.3881 17.7422 17.1875 17.8131V8.99938C17.3881 9.0703 17.5618 9.20152 17.6849 9.37507C17.808 9.54862 17.8744 9.75599 17.875 9.96875V16.8438Z" fill="#6F7D8C" />
                <path d="M14.0938 10.433H5.84375C5.66141 10.433 5.48655 10.5055 5.35761 10.6344C5.22868 10.7633 5.15625 10.9382 5.15625 11.1205C5.15625 11.3029 5.22868 11.4778 5.35761 11.6067C5.48655 11.7356 5.66141 11.808 5.84375 11.808H14.0938C14.2761 11.808 14.451 11.7356 14.5799 11.6067C14.7088 11.4778 14.7812 11.3029 14.7812 11.1205C14.7812 10.9382 14.7088 10.7633 14.5799 10.6344C14.451 10.5055 14.2761 10.433 14.0938 10.433ZM14.0938 13.0318H5.84375C5.66141 13.0318 5.48655 13.1042 5.35761 13.2332C5.22868 13.3621 5.15625 13.537 5.15625 13.7193C5.15625 13.9016 5.22868 14.0765 5.35761 14.2054C5.48655 14.3344 5.66141 14.4068 5.84375 14.4068H14.0938C14.2761 14.4068 14.451 14.3344 14.5799 14.2054C14.7088 14.0765 14.7812 13.9016 14.7812 13.7193C14.7812 13.537 14.7088 13.3621 14.5799 13.2332C14.451 13.1042 14.2761 13.0318 14.0938 13.0318ZM14.0938 7.7793H5.84375C5.66141 7.7793 5.48655 7.85173 5.35761 7.98066C5.22868 8.10959 5.15625 8.28446 5.15625 8.4668C5.15625 8.64913 5.22868 8.824 5.35761 8.95293C5.48655 9.08186 5.66141 9.1543 5.84375 9.1543H14.0938C14.2761 9.1543 14.451 9.08186 14.5799 8.95293C14.7088 8.824 14.7812 8.64913 14.7812 8.4668C14.7812 8.28446 14.7088 8.10959 14.5799 7.98066C14.451 7.85173 14.2761 7.7793 14.0938 7.7793Z" fill="#6F7D8C" />
            </svg>
        )
    },
    
    {
        label: "Forums",
        to: "/favorites/threads",
        ico: (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.4503 11.3427C16.3702 11.3055 16.2888 11.2697 16.2064 11.2355C16.0629 8.67546 14.6176 7.20984 12.1908 7.19484C12.1798 7.19478 12.1689 7.19478 12.1579 7.19478C10.7064 7.19478 9.49914 7.79446 8.7561 8.88569L10.0908 9.77183C10.6458 8.95672 11.517 8.78295 12.1585 8.78295C12.1659 8.78295 12.1734 8.78295 12.1807 8.78302C12.9798 8.78795 13.5827 9.01281 13.973 9.45131C14.257 9.77056 14.4469 10.2117 14.541 10.7685C13.8325 10.6519 13.0663 10.6161 12.2473 10.6616C9.94001 10.7902 8.45671 12.0926 8.55633 13.9024C8.60689 14.8204 9.0794 15.6101 9.88677 16.126C10.5694 16.5622 11.4486 16.7754 12.3623 16.7272C13.569 16.6631 14.5156 16.2175 15.176 15.4028C15.6775 14.784 15.9948 13.9822 16.1348 12.9718C16.7099 13.3077 17.1361 13.7498 17.3715 14.2811C17.7717 15.1844 17.795 16.6687 16.5437 17.8789C15.4473 18.939 14.1294 19.3976 12.1377 19.4118C9.92834 19.3959 8.25744 18.7102 7.17107 17.3735C6.15377 16.1218 5.62802 14.3139 5.60841 12C5.62802 9.68604 6.15377 7.87815 7.17107 6.6265C8.25744 5.28983 9.92831 4.60406 12.1377 4.58817C14.363 4.60418 16.0631 5.29326 17.191 6.63637C17.7441 7.29502 18.1611 8.12333 18.436 9.08909L20 8.68521C19.6668 7.49646 19.1425 6.4721 18.429 5.62256C16.983 3.90063 14.8681 3.0183 12.1431 3H12.1322C9.41279 3.01823 7.32159 3.90392 5.91673 5.63243C4.66659 7.1706 4.02174 9.31085 4.00007 11.9937L4 12L4.00007 12.0063C4.02174 14.6891 4.66659 16.8294 5.91673 18.3676C7.32159 20.0961 9.41279 20.9818 12.1322 21H12.1431C14.5608 20.9838 16.265 20.3711 17.6689 19.0135C19.5057 17.2374 19.4504 15.0112 18.845 13.6445C18.4107 12.6644 17.5826 11.8684 16.4503 11.3427ZM12.2759 15.1413C11.2647 15.1964 10.2141 14.7571 10.1623 13.8161C10.1239 13.1184 10.6753 12.3399 12.338 12.2472C12.5284 12.2366 12.7152 12.2314 12.8988 12.2314C13.5027 12.2314 14.0677 12.2882 14.5814 12.3968C14.3898 14.7127 13.266 15.0887 12.2759 15.1413Z" fill="#6F7D8C" />
            </svg>

        )
    },

    {
        label: "Documents",
        to: "/favorites/documents",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.33325 11.9168H14.6666" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.33325 15.5833H14.6666" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.49996 2.75H13.9076C14.3935 2.75 14.86 2.94342 15.2038 3.28717L17.7961 5.8795C18.1399 6.22325 18.3333 6.68983 18.3333 7.17567V17.4167C18.3333 18.4296 17.5129 19.25 16.5 19.25H5.49996C4.48704 19.25 3.66663 18.4296 3.66663 17.4167V4.58333C3.66663 3.57042 4.48704 2.75 5.49996 2.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.3333 7.33333H14.6667C14.1607 7.33333 13.75 6.92267 13.75 6.41667V2.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.33325 8.24984H10.0833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
    },
    {
        label: "Videos",
        to: "/favorites/videos",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M19.25 11V11C19.25 15.5568 15.5568 19.25 11 19.25V19.25C6.44325 19.25 2.75 15.5568 2.75 11V11C2.75 6.44325 6.44325 2.75 11 2.75V2.75C15.5568 2.75 19.25 6.44325 19.25 11Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0292 8.30293L13.5877 10.4076C14.0378 10.6734 14.0378 11.3252 13.5877 11.591L10.0292 13.6957C9.57091 13.967 8.99158 13.6361 8.99158 13.1035V8.89509C8.99158 8.36251 9.57091 8.03159 10.0292 8.30293V8.30293Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
    },
    {
        label: "Images",
        to: "/favorites/images",
        ico: (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4315 10.8207L11.4318 10.8203C11.4993 10.7507 11.4976 10.6395 11.428 10.572C11.3584 10.5044 11.2472 10.5061 11.1796 10.5756C11.112 10.6452 11.1136 10.7564 11.1831 10.824C11.2526 10.8917 11.3638 10.8902 11.4315 10.8207" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.75 12.1919C3.04494 12.1465 3.3428 12.1227 3.6412 12.1206V12.1206C5.29574 12.1206 6.8825 12.7779 8.05243 13.9478C9.22237 15.1177 9.87962 16.7045 9.87962 18.359C9.87688 18.6574 9.85306 18.9552 9.80833 19.2502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <rect x="2.75" y="6.4165" width="12.8333" height="12.8333" rx="3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.5 6.41667V5.5C5.5 3.98122 6.73122 2.75 8.25 2.75H16.5C18.0188 2.75 19.25 3.98122 19.25 5.5V13.75C19.25 15.2688 18.0188 16.5 16.5 16.5H15.5833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
    },
    
    
   
    

]

const Favorites = ({ tab }) => {
    const { authorized, showLoginModal } = useSelector((state) => state.auth)
    const [isList, setIsList] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const { materialsAll, isLoading, pagination } = useSelector(state => state.materialLibrary)
    const systems = useSelector(getFilteres);
    // const [params, setParams] = useState({
    //     sort: 'latest',
    //     system: [],
    // })

    const formatOptionsQuery = (value) => {
        return value?.length
            ? value.split(',').filter(Boolean) || []
            : []
    }

    const params = useMemo(() => ({
        sort: searchParams.get("sort") || 'latest',
        system: formatOptionsQuery(searchParams.get('system')) || [],
        type: searchParams.get('favorite') || 'doc'
    }), [searchParams])

    const filters = [
        {
            title: "Filter by Systems",
            checkboxOptions: systems,
        }
    ]

    useEffect(() => {
        if (tab) {
            const type = tab === 'Images' ? 'image' : tab === 'Video' ? 'video' : 'doc'
            // setParams(prev => ({
            //     ...prev,
            //     type: type
            // }))
            setSearchParams({
                ...params,
                type: type
            });
        }
    }, [tab])

    const handleQueryChange = (name, value) => {
        setSearchParams(prev => {
            if (value) {
                prev.set(name, value)
            } else if (prev.get(name)) {
                prev.delete(name)
            }
            return prev
        })
    }


    const handleFilterChange = (options) => {
        // setParams((prevFilters) => {
        //     const newType = prevFilters[type].includes(options.name)
        //         ? prevFilters[type].filter((cat) => cat !== options.name)
        //         : [...prevFilters[type], options.name];

        //     return {
        //         ...prevFilters,
        //         [type]: newType
        //     };
        // })

        const value = params.system.includes(options.name)
            ? params.system.filter((cat) => cat !== options.name).join(',')
            : [...params.system, options.name].join(',')
        
         handleQueryChange("system", value)
    }

    const handleSortChange = (option) => {
        // setParams(prev => ({
        //     ...prev,
        //     sort: option.value
        // }))
        handleQueryChange("sort", option.value)
        // setSearchParams({
        //     ...params,
        //     sort: option.value,
        // });
    }
    useEffect(() => {
        const fetchData = async () => {
            const res = await dispatch(getAllMaterialLibraryFeth(`favorite=true&${searchParams.toString()}`)).unwrap()
            if (res) {
                window.scrollTo(0, 0)
            }
        }
        fetchData()
    }, [searchParams])

    // useEffect(() => {
    //     const systemParam = searchParams.get('system');
    //     const system = systemParam ? systemParam.split(',').filter(Boolean) : [];
    //     const sort = searchParams.get('sort') || 'latest';
    //     // const favorite = searchParams.get('favorite') || true;
    //     const type = searchParams.get('favorite') || 'doc';
    //     setParams({ system, sort, type });
    // }, [searchParams])

    useEffect(() => {
        dispatch(getFiltersByName('system'))
    }, [dispatch])
    return (
        <main className="content">
            <div className="container">
                <section className="section-filters">
                    <SidebarFilters filters={filters} links={Favoritelinks} defaultCheked={params?.system} onChange={handleFilterChange} />
                    <div className="section-filters__body">
                        <div className="page-heading mb-[30px]">
                            <div className="page-heading__item">
                                <h3 className="mr-[24px]">Favorites</h3>
                                <SwitchViewType onChange={(checked) => setIsList(checked)} />
                            </div>
                            <div className="page-heading__item">
                                <SelectGroup
                                    wrapperClasses={'input--sm'}
                                    options={filterOptions}
                                    defaultValue={filterOptions[0]}
                                    onChange={handleSortChange}
                                />
                            </div>
                        </div>
                        <div className="row gutters-cards flex-auto">
                            {
                                !isLoading && materialsAll && materialsAll.length ?
                                    materialsAll.map(card => (
                                        <div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"} >
                                            {
                                                <>
                                                    {/* {tab === "Quizez" ? <CardQuiz data={{ ...card, isFavorite: true }} isList={isList} /> :
                                                        tab === "Flashcards" ? <CardFlashcard data={{ ...card, isFavorite: true }} isList={isList} /> :
                                                            tab === "Courses" ? <CardCourse data={{ ...card, isFavorite: true }} isList={isList} /> :
                                                                tab === "Articles" ? <CardArticle data={{ ...card, isFavorite: true }} isList={isList} /> :
                                                                    tab === "Cases" ? <CardCase data={{ ...card, isFavorite: true }} isList={isList} /> : */}
                                                    <CardMaterials
                                                        data={{ ...card }}
                                                        isList={isList}
                                                        isDocument={tab === "Document"}
                                                        authorized={authorized}
                                                        isVideo={tab === "Video"}
                                                    />
                                                </>
                                            }
                                        </div>
                                    ))
                                    : isLoading ?
                                        <PageLoader /> :
                                        <h2>{tab} is not Found</h2>
                            }
                        </div>
                        <Pagination {...pagination} params={params} />
                    </div>
                </section>
            </div >
        </main >
    )
}

export default Favorites