import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as DeleteIcon } from '../../assets/img/ico-trash.svg';
import { ReactComponent as UploadIcon } from '../../assets/img/ico-upload.svg';

import { DraggableItem, SortableWrapper } from '../parts/SortableWrapper';

const UploadImageSet = ({ onChange = () => { }, defaulImages, multiple, setDelete, isNarrow, onSelect, }) => {
    const [images, setImages] = useState([]);

    const [selectedImageSetIndex, setSelectedImageSetIndex] = useState(null)
    const onDrop = useCallback((acceptedFiles) => {
        const getImageFiles = (startIndex = 0) => acceptedFiles.map((file, index) => {
            file.preview = URL.createObjectURL(file)
            file.order = startIndex + index
            return file
        })
        const newSet = {
            name: '',
            activeIndex: 0,
            images: getImageFiles()
        };
        const updatedImages = [...images];

        if (typeof selectedImageSetIndex === "number" && selectedImageSetIndex >= 0) {
            updatedImages[selectedImageSetIndex]?.images.push(...getImageFiles(updatedImages[selectedImageSetIndex]?.images?.length))
        } else {
            updatedImages.push(newSet)
        }
        setImages(updatedImages);
        onChange(updatedImages);
        if (typeof selectedImageSetIndex !== "number") {
            setSelectedImageSetIndex(updatedImages.length - 1)
        }
    }, [images, onChange, selectedImageSetIndex]);

    useEffect(() => {
        setImages(defaulImages);
    }, [defaulImages]);

    const handleDeleteImage = (e, index) => {
        e.stopPropagation()
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);
        onChange(newImages)
        if (selectedImageSetIndex === index) {
            setSelectedImageSetIndex(null)
            onSelect && onSelect(null)
        }

    };

    const handleDeleteSetImage = (e, imageOrder, setIndex) => {
        e.stopPropagation();

        const newImages = [...images];

        if (newImages[setIndex]?.images?.length <= 1) {
            newImages.splice(setIndex, 1);

            if (selectedImageSetIndex === setIndex) {
                setSelectedImageSetIndex(null);
                onSelect && onSelect(null);
            }

        } else {
            newImages[setIndex].images = newImages[setIndex].images.filter(
                (image) => image.order !== imageOrder
            );
        }

        setImages(newImages);
        onChange(newImages);
    };


    const handleChangeSetImageOrder = (data) => {
        const newImages = [...images];
        newImages[selectedImageSetIndex].images = data
        setImages(newImages);
        onChange(newImages)

    }

    const handleChangeFileName = (value, index) => {
        const newNames = [...images]
        newNames[index].name = value
        setImages(newNames)
        onChange(newNames)
    };
    const handelChooseActiveImage = (index) => {
        const newActivities = [...images]
        newActivities[selectedImageSetIndex].activeIndex = index
        setImages(newActivities)
        onChange(newActivities)
    }

    const handleSelectImageSet = (index) => {
        setSelectedImageSetIndex(index)
    }

    const acceptedFiles = {
        image: { "image/jpeg": [], "image/png": [], "image/gif": [], "image/bmp": [], "image/webp": [] },
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFiles['image'],
        multiple: true
    });


    return (
        <>
            <form method="post" className='relative max-w-full' action="#">
                <div {...getRootProps({ className: `upload-form` })}>
                    <input {...getInputProps()} className="hidden" />
                    <div className="upload-form-content">
                        <div className="upload-description">
                            <span className="ico ico--xs">
                                <UploadIcon />
                            </span>
                            <p>{isDragActive ? `Drop the Images here...` : `Drag & Drop images set here or `}</p>
                        </div>
                        <div className="btn btn--primary btn--xs radius-5">
                            <span className="text-[13px] font-[500]">Upload</span>
                        </div>
                    </div>
                </div>
            </form>
            <ul className={`blueray-list ${isNarrow ? "blueray-list--50" : "blueray-list-2"}`}>
                {selectedImageSetIndex === null && !!images?.length && images.map((imageSet, index) => (
                    <li key={index} className="blueray-item">
                        <div className="blueray-control">
                            <div className={`btn blueray-btn`} onClick={() => handleSelectImageSet(index)}>
                                <div className="card-actions">
                                    <button type="button" onClick={(e) => handleDeleteImage(e, index)} className="ico ico--xs btn--shadow-2 rounded-full btn--trash">
                                        <span className="ico ico-20">
                                            <DeleteIcon />
                                        </span>
                                    </button>
                                </div>
                                <div className="card-pills">
                                    <div className="photos-count-pill">
                                        <span className="count">{imageSet?.images?.length}</span> photos
                                    </div>
                                </div>
                                <img className='h-full' src={imageSet.images[imageSet.activeIndex]?.preview} alt={`preview`} />
                            </div>
                            <div className="input-wrapper input--xs">
                                <input
                                    className="input input--outline" type="text"
                                    value={imageSet.name}
                                    onChange={(e) => handleChangeFileName(e.target.value, index)}
                                    placeholder={`Set name...`} />
                            </div>
                        </div>
                    </li>
                ))}
                {selectedImageSetIndex !== null && !!images[selectedImageSetIndex]?.images?.length &&
                    <SortableWrapper
                        items={images[selectedImageSetIndex]?.images}
                        setItems={handleChangeSetImageOrder}
                        renderItem={(image) => (
                            <DraggableItem
                                id={image.order.toString()}
                                key={image.order}
                                className="blueray-item"
                            >
                                <div className="blueray-control">
                                    <div className={`btn blueray-btn ${images[selectedImageSetIndex].activeIndex === image.order ? "selected" : ""}`} onClick={() => handelChooseActiveImage(image.order)}>
                                        <div className="card-actions">
                                            <button type="button" onClick={(e) => handleDeleteSetImage(e, image.order, selectedImageSetIndex)} className="ico ico--xs btn--shadow-2 rounded-full btn--trash">
                                                <span className="ico ico-20">
                                                    <DeleteIcon />
                                                </span>
                                            </button>
                                        </div>
                                        <img className='h-full' src={image.preview} alt={`preview`} />
                                    </div>
                                </div>
                            </DraggableItem>
                        )}
                    />
                }
            </ul>
            {!!images[selectedImageSetIndex]?.images?.length && selectedImageSetIndex !== null &&
                <div className="flex flex-col gap-[15px] mt-[32px] items-center">
                    <button type='button' onClick={() => {
                        setSelectedImageSetIndex(null)
                    }} className="btn btn--shadow btn--md radius-5 w-full">
                        <span className="text-[14px] font-[500]">
                            Back To Sets
                        </span>
                    </button>
                </div>
            }
        </>
    );
};

export default UploadImageSet;
