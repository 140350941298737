import React, { Children } from 'react'

const SwithGroup = ({wrapperClasses='',leftNode, onChange ,rightNode ,defaultChecked, ...props}) => {
    return (
        <label className={`switch btn btn--sm ${wrapperClasses}`}>
            <input 
                type="checkbox" 
                className="hidden" 
                defaultChecked={defaultChecked} 
                
                onChange={onChange}
                {...props}
            />
            <span className="switch-active">
                <span className="switch-active--bg"></span>
            </span>
            <div className="switch-controls">
                <div className="switch-control">
                    {leftNode}
                </div>
                <div className="switch-control">
                    {rightNode}
                </div>
            </div>
        </label>
    )
}

export default SwithGroup
