import { createSlice } from '@reduxjs/toolkit';
import { getCommentsByEntityTypeEntityIdFeth } from './commentsThunk';
import { getThreadCommentFeth } from '../forums/forumsThunk';


const initialState = {
    comments: [],
    pagination: null,
    commentsIsLoading: false
}

const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        
    },

    extraReducers(builder) {
       builder
        .addCase(getCommentsByEntityTypeEntityIdFeth.fulfilled, (state, action) => {
            state.commentsIsLoading = false
            if(action.payload?.pagination.page === 1) {
                state.comments = action.payload.comments
            } else {
                state.comments = [...state.comments, ...action.payload.comments]
            }
            
            state.pagination = action.payload?.pagination
        })
        .addCase(getCommentsByEntityTypeEntityIdFeth.pending, (state, action) => {
            state.commentsIsLoading = true
        })
        .addCase(getCommentsByEntityTypeEntityIdFeth.rejected, (state, action) => {
            state.commentsIsLoading = false
        })
        .addCase(getThreadCommentFeth.fulfilled, (state, action) => {
            state.commentsIsLoading = false
            
            if(action.payload.pagination.page === 1) {
                state.comments = action.payload.comments
            } else {
                state.comments = [...state.comments, ...action.payload.comments]
            }
            
            state.pagination = action.payload.pagination
        })
        .addCase(getThreadCommentFeth.pending, (state, action) => {
            state.commentsIsLoading = true
        })
        .addCase(getThreadCommentFeth.rejected, (state, action) => {
            state.commentsIsLoading = false
        })
    }
})

export const {
   
} = commentsSlice.actions;


export default commentsSlice.reducer