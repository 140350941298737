import React from 'react'
import { useSelector } from 'react-redux'
import { getMe } from '../../store/auth/authSlice'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentPlan } from '../../store/subscriptionPlan/subscriptionPlansSlice'

const SubscriptionPlans = ({ plans, intervalPrice }) => {
    const me = useSelector(getMe)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const selectPlan = async (e, plan) => {
        e.preventDefault()
        dispatch(setCurrentPlan(plan))
        navigate('/checkout')
    }
    
    return (
        <div className="row gutters-32">
            {plans?.map((item, index) => (
                <div className="col-md-4 col-sm-12 flex flex-col" key={index}>
                    <div className={`card card-price h-full ${item.isRecommended ? 'card-price--popular' : ''}`}>
                        <div className="card-header">
                            <p className="title-price">{item.name}</p>
                        </div>
                        <div className="card-body">
                            <p className="price-amount">
                                <span>${item.price}</span>
                                <sup className="price-amount--days"> / {intervalPrice}</sup>
                            </p>
                            <ul className="features-list">
                                {item.features.map((item) => (
                                    <li key={item}>
                                        <p className="custom-txt-2">{item}</p>
                                    </li>
                                ))}
                            </ul>

                        </div>
                        <div className="card-footer">
                            {me?.subscription?.id != item.id ?
                                <button type='button' onClick={(e) => selectPlan(e, item)} className="btn btn--primary btn--lg radius-5">
                                    <span className="text-[14px] font-[500]">
                                        Select Now
                                    </span>
                                </button> :
                                <span className="btn btn--default btn--lg radius-5 pointer-events-none"><span className="text-[14px] font-[500]">Current Plan</span></span>
                            }
                            <div className="pill-description">
                                <p>
                                    {item.bestFor}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default SubscriptionPlans
