import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import store from "./store/index"

import "./assets/css/bootstrap-grid.css";
import "./assets/css/tiptap-css.css";
import "./assets/fonts/fonts.css";
import "./assets/css/reset.css";
import "./assets/css/global.css";
import "./assets/css/components/components.css";
import "./assets/css/main-alpha.css";
import "./assets/css/main-bravo.css";
import "./assets/css/main-charlie.css";
import "./assets/css/main-delta.css";
import "./assets/css/media-alpha.css";
import "./assets/css/media-bravo.css";
import "./assets/css/media-charlie.css";
import "./assets/css/media-delta.css";
import "./assets/css/react-styles.css"
import "./index.css"
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

