import React from 'react'
import { Link } from 'react-router-dom'

const BreadCrambs = ({breabcrambs}) => {
    return (
        <div className="breadcrumbs">
            <div className="container">
                <ul className="breadcrumb-list">
                    {breabcrambs.map((item, index) => (
                        <li key={index}>
                            <Link to={item.link}>{item.label}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default BreadCrambs
