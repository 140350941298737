import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getDateToString, getImageSrc } from '../../helpers/utils'
import { ReactComponent as PlaceholderIcon } from "../../assets/img/user-placeholder.svg"
import { ReactComponent as BookmarkIcon } from "../../assets/img/ico-favor.svg";
import { ReactComponent as HeartIcon } from "../../assets/img/ico-like.svg";
import { ReactComponent as Dots } from "../../assets/img/ico-dots.svg";
import { ReactComponent as Arrow } from "../../assets/img/ico-share.svg";
import CopyToClipboard from 'react-copy-to-clipboard';
import { Dropdown } from './Dropdown';
import { useSelector } from 'react-redux';
import { getMe } from '../../store/auth/authSlice';
const SingleActions = ({ historyBtn = false, wrapperClasses, disabledButtons = false, user, createAt, likes, displayUser, toggleLike, toggleFavorite, isFavoriteProps, isLikeProps, editButtonHandler,handleDeleteButton, hideDrop = false , canEdit, hideDeletebutton = false}) => {
    const [copied, setCopied] = useState(false);
    const me = useSelector(getMe)
    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
   
    return (
        <div className={`discus-profile ${wrapperClasses}`}>
            <div className="discus-profile--head">
                <div className="discus-acc-card">

                    {displayUser && <span className="ico ico--md image-wrapper rounded-full">
                        {
                            user?.photo ?
                                <img src={getImageSrc(user?.photo)} />
                                : <PlaceholderIcon />
                        }
                    </span>}
                    <div className="discus-acc--text">
                        {displayUser && <p className="discus-acc-title font-[500]">{(user?.firstName || '') + ' ' + (user?.lastName || '')}</p>}
                        <p className="discus-acc-desk">{getDateToString(createAt)}</p>
                    </div>
                </div>
                <div className={`discus-controls `}>
                    {historyBtn && <div>
                        <Link to={historyBtn} className={`btn btn--transparent btn--md radius-5 rounded-full ${disabledButtons ? 'disabled' : ''}`}>
                            <span className="ico">
                                <img src={require("../../assets/img/ico-history.svg").default} />
                            </span>
                            <span className="text-[14px] color-default">History</span>
                        </Link>
                    </div>}
                    <div className='flex flex-col'>
                        <button type='button' className={`btn btn--md radius-5 btn--shadow btn--like btn--square btn--square--fix rounded-full favorite-btn ${isLikeProps ? "--selected" : ""}`} disabled={disabledButtons} onClick={toggleLike}>
                            <span className="ico">
                                <HeartIcon />
                            </span>
                            {likes && likes > 0 ? <p className="text-[13px] font-[500]">{likes}</p> : null}
                        </button>
                    </div>
                    <div>
                        <button type='button' className={`btn btn--md radius-5 btn--shadow btn--save btn--square btn--square--fix rounded-full favorite-btn ${isFavoriteProps ? "--selected" : ""}`} disabled={disabledButtons} onClick={toggleFavorite}>
                            <span className="ico">
                                <BookmarkIcon />
                            </span>
                        </button>
                    </div>
                    <div>
                        <CopyToClipboard text={window.location.href} onCopy={handleCopy}>
                            <button type='button' className="btn btn--md radius-5 btn--shadow relative btn--share btn--square btn--square--fix rounded-full" disabled={disabledButtons}>
                                <span className="ico">
                                    <Arrow/>
                                </span>
                                {copied ? <span className='copy-message'>Url copied</span> : null}
                            </button>
                        </CopyToClipboard>
                    </div>
                    {(canEdit || (user?.id === me?.id) && !hideDrop) &&  <div>
                        <Dropdown
                            closeSelf={true}
                            button={
                                <button type='button' className="btn btn--md radius-5 btn--transparent btn--share btn--square btn--square--fix rounded-full">
                                    <span className="ico">
                                        <Dots/>
                                    </span>
                                </button>
                            }
                            body={
                                <ul className='min-w-[150px]'>
                                    <li>
                                        <button type='button' onClick={editButtonHandler} className='btn btn--transparent btn--lg radius-5 gap--xs ' disabled={disabledButtons}><span className='color-default'>Edit</span></button>
                                    </li>
                                    <li className="px-[14px]"><hr className="hr"/></li>
                                    {!hideDeletebutton && <li>
                                        <button type='button' onClick={handleDeleteButton} className='btn btn--transparent btn--lg radius-5 gap--xs'  disabled={disabledButtons}>Delete</button>
                                    </li>}
                                </ul>
                            }
                        />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default SingleActions
