import React from 'react'

const CheckBoxGroup = ({children,wrapperClasses='', ...props}) => {
    return (
        <label className={`check-form ${wrapperClasses}`}>
                <input type="checkbox"  className="hidden" {...props}/>
                <span className="check"></span>
                {children}
        </label>
    )
}

export default CheckBoxGroup
