import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getCustomerExistedPaymentMethodsFeth = createAsyncThunk(
    '/getCustomerExistedPaymentMethodsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.paymant.getCustomerExistedPaymentMethods(payload);
            if (res) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const setupIntentFeth = createAsyncThunk(
    '/setupIntentFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.paymant.setupIntent(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const savePaymentMethodFeth = createAsyncThunk(
    '/savePaymentMethodFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.paymant.savePaymentMethod(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const assignDefaultPaymentMethodByMethodIdFeth = createAsyncThunk(
    '/assignDefaultPaymentMethodByMethodIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.paymant.assignDefaultPaymentMethodByMethodId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deletePaymentMethodByMethodIdFeth = createAsyncThunk(
    '/deletePaymentMethodByMethodIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.paymant.deletePaymentMethodByMethodId(payload);
            if (res.success) {
                // toast.success(res.message)
                return {id: payload, res};
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);