import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { ReactComponent as SertificateIcon } from '../../../assets/img/SertificateIcon.svg'
import InputGroup from '../../../components/UI/formElements/InputGroup';
import DatePickerGroup from '../../../components/UI/formElements/DatePickerGroup';
import { useDispatch } from 'react-redux';
import { addCertificateFeth, deleteCertificateByCertIdFeth, editCertificateByCertIdFeth } from '../../../store/profile/profileThunk';
import { getDateToString } from '../../../helpers/utils';
import { ReactComponent as DeleteIcon } from '../../../assets/img/delete-Icon.svg';
import { useValidation } from '../../../hooks/useValidation';



const Certifications = ({showOnly = false, titleClass = 'text--lg'}) => {
    const { profile } = useSelector(state => state.profile);
    const [showAddForm, setShowAddForm] = useState(false)
    const [isEditId, setIsEditId] = useState(null)
    const dispatch = useDispatch()
    const [addCertificationData, setAddCertificationData] = useState({
        title: "",
        location: '',
        date: ''
    })
    const messages = {
        title: 'Title is required',
        location: 'Location name is required',
        date: 'Date name is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(addCertificationData, {
        messages
    });
    const clearData = () => {
        setAddCertificationData(
            {
                title: "",
                location: '',
                date: ''
            }
        )
    }
    const addInputsHandler = (name, value) => {
        setAddCertificationData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const editHandler = (element) => {
        setIsEditId(element.id)
        setAddCertificationData({
            title: element.title,
            location: element.location,
            date: element.date
        })
        setShowAddForm(false)
    }
    const createSumbitHanler = async () => {
        if (errors) {
            
            setIsErrorShown(true)
            return
        }
        const body = {
            certificate: addCertificationData
        }
        let res  = null
        if(isEditId) {
            res = await dispatch(editCertificateByCertIdFeth({id:isEditId, body})).unwrap()
        } else {
            res = await dispatch(addCertificateFeth(body)).unwrap()
        }
        if (res) {
            clearData()
            setIsErrorShown(false)
            if(isEditId) {
                setIsEditId(null)
            }
        }
    }
    const deleteHandler = async (id) => {
        const res = await dispatch(deleteCertificateByCertIdFeth(id)).unwrap()
        if(res) {
            clearData()
        }
    }
    const addFormHandler = () => {
        clearData()
        setShowAddForm(prev => !prev)
        setIsEditId(null)
    }
    
    return (
        <div className="col-group">
            <h4 className={`mb-[24px] ${titleClass}`}>Certifications</h4>
            <div className="row g-16">
                {profile?.certificates && profile?.certificates.map((certificate, index) => (
                    <div className={`${!showOnly ?"col-lg-6" : "col-lg-4"}`} key={index}>
                        <div className="card card-border card-certificate relative">
                            {isEditId !== certificate.id ? <>
                                <div className="ico ico-24">
                                    <SertificateIcon />
                                </div>
                                <div className="col-group gap--xs">
                                    <p className="font-semibold">{certificate.title}</p>
                                    <div className="col-group gap--xs color-secondary text--md">
                                        <p>{certificate.location}</p>
                                        <p>{getDateToString(certificate.date, "MMM dd, yyyy")}</p>
                                    </div>
                                </div>
                                {!showOnly && <button type='button' onClick={() => editHandler(certificate)} className='btn card-edit-button'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_3147_61464)">
                                            <path d="M8.68969 20.2501H4.5C4.30109 20.2501 4.11032 20.1711 3.96967 20.0305C3.82902 19.8898 3.75 19.699 3.75 19.5001V15.3104C3.75009 15.1118 3.82899 14.9213 3.96938 14.7807L15.5306 3.2195C15.6713 3.07895 15.862 3 16.0608 3C16.2596 3 16.4503 3.07895 16.5909 3.2195L20.7806 7.40637C20.9212 7.54701 21.0001 7.7377 21.0001 7.93653C21.0001 8.13535 20.9212 8.32605 20.7806 8.46668L9.21937 20.0307C9.07883 20.1711 8.88834 20.25 8.68969 20.2501Z" stroke="#FF4000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.75 6L18 11.25" stroke="#FF4000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3147_61464">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>}
                            </> :
                                <div className="flex-auto">
                                    <div className="row g-16">
                                        <div className="col-12">
                                            <InputGroup
                                                wrapperClasses='input--md'
                                                name={'title'}
                                                label={'Title'}
                                                inputClasses='input--outline'
                                                placeholder={'Enter Title'}
                                                value={addCertificationData.title}
                                                onChange={({ target }) => addInputsHandler('title', target.value)}
                                                error={isErrorShown && errors?.title}
                                                errorMessage={errorMessages?.title}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <InputGroup
                                                wrapperClasses='input--md'
                                                name={'location'}
                                                label={'Location'}
                                                inputClasses='input--outline'
                                                placeholder={'Enter Location'}
                                                value={addCertificationData.location}
                                                onChange={({ target }) => addInputsHandler('location', target.value)}
                                                error={isErrorShown && errors?.location}
                                                errorMessage={errorMessages?.location}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <DatePickerGroup
                                                wrapperClasses='input--md'
                                                name={'date'}
                                                label={'Date'}
                                                inputClasses='input--outline input-date'
                                                placeholder={'Enter Date'}
                                                selectedDate={addCertificationData.date}
                                                onChange={(date) => addInputsHandler('date', date)}
                                                error={isErrorShown && errors?.date}
                                                errorMessage={errorMessages?.date}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="row-group gap--md-2">
                                                <button type='button' onClick={createSumbitHanler} className="btn btn--primary btn--lg radius-5">
                                                    <span className="info text--md">Save Changes</span>
                                                </button>
                                                <button type='button' onClick={() => {deleteHandler(certificate.id)}} className="btn btn--transparent btn--md radius-5">
                                                    <span className="ico">
                                                        <DeleteIcon/>
                                                    </span>
                                                    <span className="info text--md color-danger">Delete</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                ))}
                {showAddForm && <div className="col-lg-6">
                    <div className="card card-border card-certificate">
                        <div className="flex-auto">
                            <div className="row g-16">
                                <div className="col-12">
                                    <InputGroup
                                        wrapperClasses='input--md'
                                        name={'title'}
                                        label={'Title'}
                                        inputClasses='input--outline'
                                        placeholder={'Enter Title'}
                                        value={addCertificationData.title}
                                        onChange={({ target }) => addInputsHandler('title', target.value)}
                                        error={isErrorShown && errors?.title}
                                        errorMessage={errorMessages?.title}
                                    />
                                </div>
                                <div className="col-6">
                                    <InputGroup
                                        wrapperClasses='input--md'
                                        name={'location'}
                                        label={'Location'}
                                        inputClasses='input--outline'
                                        placeholder={'Enter Location'}
                                        value={addCertificationData.location}
                                        onChange={({ target }) => addInputsHandler('location', target.value)}
                                        error={isErrorShown && errors?.location}
                                        errorMessage={errorMessages?.location}
                                    />
                                </div>
                                <div className="col-6">
                                    <DatePickerGroup
                                        wrapperClasses='input--md'
                                        name={'date'}
                                        label={'Date'}
                                        inputClasses='input--outline input-date'
                                        placeholder={'Enter Date'}
                                        selectedDate={addCertificationData.date}
                                        onChange={(date) => addInputsHandler('date', date)}
                                        error={isErrorShown && errors?.date}
                                        errorMessage={errorMessages?.date}
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="row-group gap--md-2">
                                        <button type='button' onClick={createSumbitHanler} className="btn btn--primary btn--lg radius-5">
                                            <span className="info text--md">Add Certification</span>
                                        </button>
                                        <button type='button' onClick={addFormHandler} className="btn btn--shadow btn--lg radius-5">
                                            <span className="info text--md">Cansel</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {!showOnly && <div className="col-12 flex">
                    <button type='button' onClick={addFormHandler} className="btn btn--shadow gap--md btn--lg radius-5 ml-auto">
                        <span className="ico">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 8V16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16 12H8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="info text--md font-medium">Add Certification</span>
                    </button>
                </div>}
            </div>
        </div>
    )
}

export default Certifications
