import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getAllCoursesFeth = createAsyncThunk(
    '/getAllCoursesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.getAllCourses(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getCourseByIdFeth = createAsyncThunk(
    '/getCourseByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.getCourseById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createCourseFeth = createAsyncThunk(
    '/createCourseFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.createCourse(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createLessonByCourseIdFeth = createAsyncThunk(
    '/createLessonByCourseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.createLessonByCourseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const purchaseCourseByIdFeth = createAsyncThunk(
    '/purchaseCourseByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.purchaseCourseById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const purchaseConfirmationFeth = createAsyncThunk(
    '/purchaseConfirmationFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.purchaseConfirmation(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const completeLessonByLessonIdFeth = createAsyncThunk(
    '/completeLessonByLessonIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.completeLessonByLessonId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const lessonsOrderingByCourseIdFeth = createAsyncThunk(
    '/lessonsOrderingByCourseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.lessonsOrderingByCourseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createSettingsByCourseIdFeth = createAsyncThunk(
    '/createSettingsByCourseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.createSettingsByCourseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const likeCourseByCourseIdFeth = createAsyncThunk(
    '/likeCourseByCourseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.likeCourseByCourseId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const viewCourseByCourseIdFeth = createAsyncThunk(
    '/viewCourseByCourseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.viewCourseByCourseId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const favoriteCourseByCourseIdFeth = createAsyncThunk(
    '/favoriteCourseByCourseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.favoriteCourseByCourseId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editCourseByIdFeth = createAsyncThunk(
    '/editCourseByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.editCourseById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editSettingsByCourseIdFeth = createAsyncThunk(
    '/editSettingsByCourseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.editSettingsByCourseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editLessonByLessonIdFeth = createAsyncThunk(
    '/editLessonByLessonIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.editLessonByLessonId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const cloneLessonByIdFeth = createAsyncThunk(
    '/cloneLessonByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.cloneLessonById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteLessonByLessonIdFeth = createAsyncThunk(
    '/deleteLessonByLessonIdFeth/',
    async (id, { rejectWithValue }) => {
        
        try {
            const res = await api.courses.deleteLessonByLessonId(id);
            if (res.success) {
                toast.success(res.message)
                return {id, res};
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteCourseByIdFeth = createAsyncThunk(
    '/deleteCourseByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.courses.deleteCourseById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);