import React, { useEffect, useMemo, useState } from 'react'
import SelectGroup from '../../components/UI/formElements/SelectGroup'
import Pagination from '../../components/parts/Pagination'
import SidebarFilters from '../../components/parts/SidebarFilters'
import SwitchViewType from '../../components/parts/SwitchViewType'
import CardMaterials from '../../components/parts/Cards/CardMaterials'
import { filterOptions } from '../../helpers/constants'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getFilteres } from '../../store/filters/filtersSlice'
import { getFiltersByName } from '../../store/filters/filtersThukns'
import { useSelector } from 'react-redux'
import { getAllMaterialLibraryFeth } from '../../store/materials/materialLibraryThunks'
import PageLoader from '../../components/parts/PageLoader'
import { quizzesAll, quizzesPagination } from '../../store/quizzes/quizzesSlice'
import { getIsLoading } from '../../store/flashcards/flashcardsSlice'
import { getAllFlashCardSetsFetch } from '../../store/flashcards/flashcardsThunks'
import { getAllQuizzesFeth } from '../../store/quizzes/quizzesThunks'
import { Favoritelinks } from './Favorites'
import CardQuiz from '../../components/parts/Cards/CardQuiz'
import CardFlashcard from '../../components/parts/Cards/CardFlashcard'

const FavoritesQuizes = ({ tab }) => {
    const { authorized, showLoginModal } = useSelector((state) => state.auth)
    const [isList, setIsList] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const { flashCardSets, isLoading, pagination } = useSelector(state => state.flashcards)
    const quizzes = useSelector(quizzesAll)
    const isQuizLoading = useSelector(getIsLoading);
    const quizPagination = useSelector(quizzesPagination)
    const [data, setData] = useState([])
    const systems = useSelector(getFilteres);
   
    const formatOptionsQuery = (value) => {
        return value?.length
            ? value.split(',').filter(Boolean) || []
            : []
    }

    const params = useMemo(() => ({
        sort: searchParams.get('sort') || 'latest',
        system: formatOptionsQuery(searchParams.get('system')) || [],
    }), [searchParams])

    const filters = [
        {
            title: "Filter by Systems",
            checkboxOptions: systems,
        }
    ]

    const handleFilterChange = (options) => {
        setSearchParams({
            ...params,
            system: params.system.includes(options.name)
                ? params.system.filter((cat) => cat !== options.name).join(',')
                : [...params.system, options.name].join(','),
        });
    }

    const handleSortChange = (option) => {
      
        setSearchParams({
            ...params,
            sort: option.value,
        });
    }
  
    useEffect(() => {
        const fetchData = async () => {
            let res = null
            if (tab === "Flashcards") {
                res = await dispatch(getAllFlashCardSetsFetch({ query: `favorite=true&${searchParams.toString()}` })).unwrap()
            } else {
                res = await dispatch(getAllQuizzesFeth({ query: `favorite=true&${searchParams.toString()}` })).unwrap()
            }
            if (res) {
                window.scrollTo(0, 0)
            }
        }
        fetchData()
    }, [searchParams])
    useEffect(() => {
        setSearchParams(params);
    }, [tab])
    useEffect(() => {
        if (tab === "Flashcards") {
            setData(flashCardSets)
        } else {
            setData(quizzes)
        }
    }, [flashCardSets, quizzes])

    useEffect(() => {
        dispatch(getFiltersByName('system'))
    }, [dispatch])

    return (
        <main className="content">
            <div className="container">
                <section className="section-filters">
                    <SidebarFilters filters={filters} links={Favoritelinks} defaultCheked={params?.system} onChange={handleFilterChange} />
                    <div className="section-filters__body">
                        <div className="page-heading mb-[30px]">
                            <div className="page-heading__item">
                                <h3 className="mr-[24px]">Favorites</h3>
                                <SwitchViewType onChange={(checked) => setIsList(checked)} />
                            </div>
                            <div className="page-heading__item">
                                <SelectGroup
                                    wrapperClasses={'input--sm'}
                                    options={filterOptions}
                                    defaultValue={filterOptions[0]}
                                    onChange={handleSortChange}
                                />
                            </div>
                        </div>
                        <div className="row gutters-cards flex-auto">
                            {
                                (!isLoading || !isQuizLoading) && data?.length ?
                                    data.map(card => (
                                        <div key={card.id} className={!isList ? "col-sm-6 col-lg-4 col-xl-3" : "col-12"} >
                                            {
                                                tab === "Quizzes" ?
                                                    <CardQuiz authorized={authorized} data={card} isList={isList} />
                                                    :
                                                    <CardFlashcard authorized={authorized} data={card} isList={isList} />
                                            }
                                        </div>
                                    ))
                                    : isLoading || isQuizLoading ?
                                        <PageLoader /> :
                                        <h2>Page is empty</h2>

                            }
                        </div>
                        {tab === "Flashcards" && <Pagination {...pagination}  params={params} />}
                        {tab === "Quizzes" && <Pagination {...quizPagination} params={params} />}
                    </div>
                </section>
            </div >
        </main >
    )
}

export default FavoritesQuizes
