import { useSelector } from 'react-redux'
import { getDateToString, getImageSrc, timeDifference } from '../../helpers/utils'
import { ReactComponent as CalendarIcon } from '../../assets/img/calendar-icon-gray.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Accordeon from '../../components/UI/Accordeon'
import { useDispatch } from 'react-redux'
import { deleteForumFeth, subscribeForumFeth } from '../../store/forums/forumsThunk'
import { ReactComponent as DeleteIcon } from '../../assets/img/delete-Icon.svg';
import { getMe } from '../../store/auth/authSlice'
import DeleteModal from '../../components/Modals/DeleteModal'
import { useState } from 'react'
export const ForumSideBar = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const me = useSelector(getMe)
    const { id } = useParams()
    const { forumOne } = useSelector(state => state.forums)
    const [deleteModal, setDeleteModal] = useState(false)
    const activeModerators = forumOne?.moderators?.filter(
        (moder) => moder.status === "active"
    );
    const subscribeForum = () => {
        dispatch(subscribeForumFeth(id))
    }
    const deleteHandler = async () => {
        const res = await dispatch(deleteForumFeth(id)).unwrap()
        if (res) {
            navigate('/forums')
        }
    }
    return (
        <div className="page-sidebar --none-border mobile-fixed mobile-fixed--right" data-aside="body">
            <aside className="sidebar post-sidebar">
                <div className="sidebar-body">

                    <div className="post-widget">
                        <div className="post-widget--header">
                            <div className="heading-post-row">
                                <span className="ico ico--xxl imageWrapper image-shadow">
                                    <img className='h-full' src={getImageSrc(forumOne?.cover)} />
                                </span>
                                <div className="col-group gap--xs">
                                    <p className="heading-title-post text-def-2-c font-semibold">{forumOne?.name}</p>
                                    <div className="row-group gap--xs color-secondary">
                                        <span className="ico ico-24">
                                            <CalendarIcon />
                                        </span>
                                        <p className="text--sm font-medium">Created {getDateToString(forumOne?.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="post-widget--body col-group">
                            <p className="text--sm">
                                {forumOne?.description}
                            </p>
                        </div>
                        {me?.id === forumOne?.user?.id ?
                            <div className="row-group gap--md-2">
                                <Link to={`/forum/${forumOne?.id}/general`} className="btn btn--primary btn--sm radius-5 flex-1">
                                    <span className="info text--md">Edit</span>
                                </Link>
                                <button type='button' onClick={() => setDeleteModal(true)} className="btn btn--transparent btn--sm radius-5  flex-1">
                                    <span className="ico">
                                        <DeleteIcon />
                                    </span>
                                    <span className="info text--md color-danger">Delete</span>
                                </button>
                            </div>
                            : <button
                                type='button'
                                className="btn btn--primary btn--lg radius-5"
                                onClick={subscribeForum}
                            >
                                <span className="text--md font-medium">
                                    {forumOne?.isSubscribed ? 'Unsubscribe' : 'Join Forum'}
                                </span>
                            </button>}
                        <div className="row-group gap--sm justify-between">
                            <div className="col-group text-center item-center gap--xs flex-1">
                                <p className="text--lg font-semibold">{forumOne?.threadsCount}</p>
                                <p className="text--xs color-secondary">Threads</p>
                            </div>
                            <div className="col-group text-center item-center gap--xs flex-1">
                                <p className="text--lg font-semibold">{forumOne?.subscribers}</p>
                                <p className="text--xs color-secondary">Subscribers</p>
                            </div>
                            <div className="col-group text-center item-center gap--xs flex-1">
                                <p className="text--lg font-semibold">{forumOne?.likes}</p>
                                <p className="text--xs color-secondary">Likes</p>
                            </div>
                        </div>
                    </div>
                    <div className="post-widget">
                        <div className="post-widget--header">
                            <div className="heading-post-row">
                                <span className="ico ico--xs">
                                    <img src={require('../../assets/img/folder-ico.png')} />
                                </span>
                                <p className="heading-title-post text-def-2-c font-[600]">
                                    Rules
                                </p>
                            </div>
                        </div>
                        <div className="post-widget--body flex flex-col">
                            <div className="rules-list">

                                {forumOne?.rules?.map(rule => (
                                    <Accordeon
                                        iconStyle='arrowLeft'
                                        key={rule.id}
                                        title={rule.rule}
                                    >
                                        {rule.description}
                                    </Accordeon>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="post-widget">
                        <div className="post-widget--header">
                            <div className="heading-post-row">
                                <span className="ico ico--xs">
                                    <img src={require('../../assets/img/moderators-ico.png')} />
                                </span>
                                <p className="heading-title-post text-def-2-c font-[600]">
                                    Moderators
                                </p>
                            </div>
                        </div>
                        <div className="post-widget--body flex flex-col">
                            {activeModerators?.length > 0 ? <ul className="moderators-list">
                                {activeModerators.map(moder => (
                                    <li key={moder.user.id}>
                                        <Link className="custom-link">
                                            @{`${moder.user.firstName}_${moder.user.lastName}`} </Link>
                                    </li>
                                ))}
                            </ul> : <p>Dot'n have moderators</p>}
                        </div>
                    </div>

                    {forumOne?.popularThreads?.length > 0 ? <div className="post-widget">
                        <div className="post-widget--header">
                            <div className="heading-post-row">
                                <span className="ico ico--xs">
                                    <img src={require('../../assets/img/popular-threads-ico.png')} />
                                </span>
                                <p className="heading-title-post text-def-2-c font-[600]">
                                    Popular Threads
                                </p>
                            </div>
                        </div>
                        <div className="post-widget--body col-group">
                            <ul className="col-group border-list">
                                {forumOne?.popularThreads?.map(thread => (
                                    <li key={thread.id}>
                                        <Link to={`/forums/${id}/thread/${thread.id}`}
                                            className="col-group gap--xxs pt-[20px] pb-[14px] not_scrolljs">
                                            <div className="group-between">
                                                <div className="user-info user-info--xs">
                                                    <p className="text--sm">
                                                        <span>by </span>
                                                        <object type="owo/uwu">
                                                            <Link to={`/profile/${thread.user.id}`} className="not_scrolljs">
                                                                <span className="color-danger">
                                                                    {`${thread?.user?.firstName} ${thread?.user?.lastName}`}
                                                                </span>
                                                            </Link>
                                                        </object>
                                                    </p>
                                                </div>
                                                <p className="mini-post--desk">{timeDifference(thread.createdAt)}</p>
                                            </div>
                                            <h6 className="heading">{thread?.title}</h6>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div> : null}

                </div>
                <a href="" className="btn btn--primary btn--square btn--md sidebar-action-btn" data-aside="acticon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
                        <path fill="currentColor"
                            d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"></path>
                    </svg>
                </a>
            </aside>
            {deleteModal && <DeleteModal open={deleteModal} handelClose={() => setDeleteModal(false)} title={'forum'} deleteHandler={deleteHandler} />}
        </div>
    )
}