import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getAllConversationsFeth = createAsyncThunk(
    '/getAllConversationsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.chat.getAllConversations(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getConversationByIdFeth = createAsyncThunk(
    '/getConversationByIdFeth/',
    async (payload, { rejectWithValue }) => {
        
        try {
            const res = await api.chat.getConversationById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getUnreadCountFeth = createAsyncThunk(
    '/getUnreadCountFeth/',
    async (payload, { rejectWithValue }) => {
        
        try {
            const res = await api.chat.getUnreadCount(payload);
            if (res) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const searchMessagesFeth = createAsyncThunk(
    '/searchMessagesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.chat.searchMessages(payload);
            if (res.success) {
                // toast.success(res.message)
                return {...res, page: payload.page};
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const searchLinksFeth = createAsyncThunk(
    '/searchLinksFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.chat.searchLinks(payload);
            if (res.success) {
                // toast.success(res.message)
                return {...res, page: payload.page};
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const searchFilesFeth = createAsyncThunk(
    '/searchFilesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.chat.searchFiles(payload);
            if (res.success) {
                // toast.success(res.message)
                return {...res, page: payload.page};
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const searchInfoFeth = createAsyncThunk(
    '/searchInfoFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.chat.searchInfo(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);

export const createNewConversationByUserIdFeth = createAsyncThunk(
    '/createNewConversationByUserIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.chat.createNewConversationByUserId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const sendMessageByConversationIdFeth = createAsyncThunk(
    '/sendMessageByConversationIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.chat.sendMessageByConversationId(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const messagesMarkAsReadFeth = createAsyncThunk(
    '/messagesMarkAsReadFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.chat.messagesMarkAsRead(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteConversationFeth = createAsyncThunk(
    '/deleteConversationFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.chat.deleteConversation(payload);
            if (res.success) {
                // toast.success(res.message)
                return {res , id: payload };
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);