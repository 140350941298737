import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getAllChartsFeth = createAsyncThunk(
    '/getAllChartsFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.charts.getAllCharts(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createChartFeth = createAsyncThunk(
    '/createChartFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.charts.createChart(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const updateChartFeth = createAsyncThunk(
    '/updateChartFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.charts.updateChart(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getChartByIdFeth = createAsyncThunk(
    '/getChartByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.charts.getChartById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteChartFeth = createAsyncThunk(
    '/deleteChartFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.charts.deleteChart(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
