import UploadImage from "../../components/UI/UploadImage";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import { ReactComponent as ArrowRightIcon } from "../../assets/img/ico-arrow-right.svg"
import { useEffect, useState } from "react";
import { useValidation } from "../../hooks/useValidation";
import InputGroupRow from "../../components/UI/formElements/InputGroupRow";
import TextareaGroupRow from "../../components/UI/formElements/TextareaGroupRow";
import { useDispatch } from "react-redux";
import { createFlashCardSetFetch, editFlashCardSetByIdFetch } from "../../store/flashcards/flashcardsThunks";
import { getFormData, getImageSrc } from "../../helpers/utils";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Loader from "../../components/UI/Loader";
import { getIsLoading } from "../../store/flashcards/flashcardsSlice";
import { useSelector } from "react-redux";
import SelectGroupRow from "../../components/UI/formElements/SelectGroupRow";
import { getFilteres, getIsLoadingFilters } from "../../store/filters/filtersSlice";
import { getFiltersByName } from "../../store/filters/filtersThukns";
import { setHasEditChanges } from "../../store/auth/authSlice";



export const FlashcardsCreateGeneral = () => {
    const {id} = useParams()
    const dispach = useDispatch()
    const [flashCardId, flashCardSet] = useOutletContext();
    const systems = useSelector(getFilteres);
    const isLoadingFilters = useSelector(getIsLoadingFilters)
    const navigate = useNavigate();
    const isLoading = useSelector(getIsLoading);
    const conf = {
        title: 'General Information',
    }
    const categotyes = systems ?  systems.map(item => ({value: item?.name, label: item?.name})) : null
    const [formData, setFormData] = useState({
        cover: null,
        name: flashCardSet?.name,
        description: flashCardSet?.description,
        system: flashCardSet?.filter?.name,
    })
    
    useEffect(() => {
        if(flashCardSet) {
            setFormData({
                cover:{
                    preview: getImageSrc(flashCardSet?.cover),
                    type:  `image/${ flashCardSet?.cover.split('.').pop()}`
                },
                name: flashCardSet?.name,
                description: flashCardSet?.description,
                system: flashCardSet?.filter?.name,
            })
        }
        
        
    }, [flashCardSet])
    const validators = {
        name: value => value?.length > 1 ? true : 'Name must be more than 1 characters',
        description: value => value?.length > 5 ? true : 'Description must be more than 5 characters',
        cover: file => {            
            if (!file) {
                return 'Cover is required';
            }
            if (!file?.type?.startsWith('image/')) {
                return 'Photo must be an image file (jpg, jpeg, png, gif)';
            }

            return true;
        },
        system: value => value ? true : 'System is required',
    };

    useEffect(() => {
        dispach(getFiltersByName('system'))
    }, [dispach])

    const messages = {
        name: 'First name is required',
        description: 'Description is required',
        cover: 'Cover is required',
        system: 'System is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(
        formData,
        {
            validators,
            messages
        }
    );
    const formDataHandler = (name, value) => {
       dispach(setHasEditChanges(true))
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        if (errors) {
            
            setIsErrorShown(true)
            return
        }
        const body = getFormData(formData);
        let res = null
        if (flashCardId) {
            res = await dispach(editFlashCardSetByIdFetch({ flashCardId, body })).unwrap()

        } else {
            res = await dispach(createFlashCardSetFetch(body)).unwrap()

        }
        if (res) {
            if(id) {
                navigate(`/flashcards-edit/${id}/flashcards`)
            } else {
                navigate('/flashcards-create/flashcards')
            }
            setIsErrorShown(false);
            dispach(setHasEditChanges(false))
        }
    }
    if (isLoading || isLoadingFilters) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader/>
        </div>
    )
    return (
        <div>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group items-center gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Flashcards
                                                    Cover</p>
                                                <UploadImage
                                                    onChange={(image) => {
                                                        // formDataHandler('cover', ...image)
                                                        setFormData(prev => ({
                                                            ...prev,
                                                            'cover': image[0]
                                                        }))
                                                    }}
                                                    defaulImages={formData.cover ? [formData.cover] : []}
                                                    error={isErrorShown && errors?.cover}
                                                    errorMessage={errorMessages?.cover}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <InputGroupRow
                                                label={"Flashcards Name"}
                                                inputClasses={'input--outline'}
                                                placeholder={'Enter flashcard name ...'}
                                                defaultValue={flashCardSet?.name}
                                                onChange={({ target }) => formDataHandler('name', target.value)}
                                                error={isErrorShown && errors?.name}
                                                errorMessage={errorMessages?.name}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <SelectGroupRow
                                                groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                label={'Category'}
                                                placeholder={'Select Category'}
                                                wrapperClasses={'select--outline input--md w-full'}
                                                options={categotyes}
                                                defaultValue={
                                                    flashCardSet?.filter?.name
                                                    ? {value: flashCardSet?.filter?.name, label: flashCardSet?.filter?.name} 
                                                    : null
                                                }
                                                onChange = {(option) => {formDataHandler('system', option.value)}}
                                                error={isErrorShown && errors?.system}
                                                errorMessage={errorMessages?.system}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextareaGroupRow
                                                label={'Description'}
                                                placeholder={"Enter flashcards description..."}
                                                textareaClasses="input--outline"
                                                onChange={({ target }) => formDataHandler('description', target.value)}
                                                defaultValue={flashCardSet?.description}
                                                rows="7"
                                                error={isErrorShown && errors?.description}
                                                errorMessage={errorMessages?.description}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/quiz/flashcards'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type="button"
                                        onClick={submitHandler}
                                        className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <ArrowRightIcon />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}