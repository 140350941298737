import {NavLink, Outlet} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import CreateSidebar from "../components/parts/createPagesParts/CreateSidebar";


import {ReactComponent as GeneralIcon} from '../assets/img/sidebar-general.svg'
import {ReactComponent as SettingIcon} from '../assets/img/sidebar-settings.svg'
import {ReactComponent as ImageVideoIcon} from '../assets/img/sidebar-image-videos.svg'
import {ReactComponent as HintsIcon} from '../assets/img/sidebar-hints.svg'
import {ReactComponent as QuizeIcon} from '../assets/img/sidebar-quiz.svg'
import { getQuiz, getQuizId } from "../store/quizzes/quizzesSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getQuizGeneralByQuizIdFeth } from "../store/quizzes/quizzesThunks";
export const QuizCreateLayout = () => {
    const dispach = useDispatch();
    const quizId = useSelector(getQuizId);
    const quiz = useSelector(getQuiz)
   
    useEffect(() => {
        if(quizId) {
          dispach(getQuizGeneralByQuizIdFeth(quizId)).unwrap();
        }
    }, [quizId])
    const sidebalLiks = [
        {
            icon: <GeneralIcon/>,
            to: 'general',
            label: 'General Information'
        },
        {
            icon: <ImageVideoIcon/>,
            to: 'image',
            label: 'Images & Videos',
            disabled: quizId ? false : true
        },
        {
            icon: <HintsIcon/>,
            to: 'hints',
            label: 'Hints',
            disabled: quizId ? false : true
        },
        {
            icon: <QuizeIcon/>,
            to: 'quiz',
            label: 'Quiz',
            disabled: quizId ? false : true
        },
        {
            icon: <SettingIcon/>
            ,
            to: 'settings',
            label: 'Settings',
            disabled: quizId ? false : true
        },
    ]

    return (
        <>
            <div className={'quiz-page'}>
                <CreateSidebar 
                    links={sidebalLiks}
                    title={'Create Quiz'} 
                    backRoute={'/quiz'}
                />
                <Outlet context={[quizId, quiz]}/>
                <div className="custom-overlay toggler-menu" style={{zIndex: 51}}></div>
            </div>
        </>
    )
}


