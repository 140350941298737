import {
    DndContext, PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const SortableWrapper = ({ items, setItems, renderItem }) => {
    const handleDragEnd = (event) => {
        let itemsCopy = [...items]
        const { active, over } = event;

        if (over && active.id !== over.id) {
            const oldIndex = itemsCopy.findIndex(item => item.order === parseInt(active.id));
            const newIndex = itemsCopy.findIndex(item => item.order === parseInt(over.id));
            itemsCopy = arrayMove(itemsCopy, oldIndex, newIndex).map((item, index) => {
                item.order = index
                return item
            });
            setItems([...itemsCopy]);
        }
    };

    const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });
    const sensors = useSensors(pointerSensor);
    return (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={items.map(item => item.order.toString())}>
                {items.map((item) => renderItem(item))}
            </SortableContext>
        </DndContext>
    );
};

export const DraggableItem = ({ id, children, ...rest }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: isDragging ? 1000 : 'auto',
        boxShadow: isDragging ? '0 4px 10px rgba(0, 0, 0, 0.3)' : 'none',
        touchAction: 'none'
    };

    return (
        <div
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            style={{ ...style, cursor: 'grab' }}
            {...rest}
        >
            {children}
        </div>
    );
};