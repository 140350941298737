import React from 'react'
import { Dropdown } from '../../../components/parts/Dropdown';
import UserAvatar from './UserAvatar';
import { useSelector } from 'react-redux';
import { formatDateToTime, timeAgo } from '../../../helpers/utils';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteConversationFeth } from '../../../store/chat/chatThunk';
import { toggleUserBlockFeth } from '../../../store/profile/profileThunk';

const ChatMessageHead = ({ chatHandler }) => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const { currentChatData } = useSelector(state => state.chat)
    const handleDeleteButton = async () => {
        const res = await dispatch(deleteConversationFeth(id)).unwrap()
        if (res) {
            navigate('/chat/')
        }
    }
    const blockUser = async () => {
        const res = dispatch(toggleUserBlockFeth(currentChatData?.conversation?.user?.id)).unwrap()
        if(res) {
            console.log(res)
        }
    }
    return (
        <div className="chart-block__heading">
            <button type='button' onClick={() => { chatHandler('users-list') }}  className="btn back-to-chat-list btn--transparent">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.16663 10.0002H15.8333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M8.33329 5.8335L4.16663 10.0002" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M8.33329 14.1667L4.16663 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
            </button>
            <Link to={`/profile/${currentChatData?.conversation?.user?.id}`} className="user-chat-heading open-chat-info-btn">
                <UserAvatar user={currentChatData?.conversation?.user} />
                <span className="chart-block__heading-name user-chat-name">
                    {`${currentChatData?.conversation?.user?.firstName} ${currentChatData?.conversation?.user?.lastName}`}
                </span>
            </Link>
            <div className="list-group-row list-group--xs items-center whitespace-nowrap">
                <span className="chart-block__heading-time user-chat-time text--sm">
                    {timeAgo(currentChatData?.conversation?.user?.lastSeen)}
                </span>
                <button type='button' onClick={() => { chatHandler('user-chat-info') }} className='btn mobil-search-info-button btn--transparent'>
                    <span className="ico ico-24">
                        <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10.137" cy="10.137" r="6.47345" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                            </circle>
                            <path d="M18.3365 18.3365L14.7141 14.7141" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>
                </button>
                <Dropdown
                    wrapperClass={'flex'}
                    button={
                        <button type='button' className="btn btn--transparent" data-role="button">
                            <span className="ico ico-24">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9983 5.49714C12.2745 5.49714 12.4985 5.72109 12.4985 5.99735C12.4985 6.27361 12.2745 6.49756 11.9983 6.49756C11.722 6.49756 11.498 6.27361 11.498 5.99735C11.498 5.72109 11.722 5.49714 11.9983 5.49714" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M11.9983 11.4996C12.2745 11.4996 12.4985 11.7235 12.4985 11.9998C12.4985 12.276 12.2745 12.5 11.9983 12.5C11.722 12.5 11.498 12.276 11.498 11.9998C11.498 11.7235 11.722 11.4996 11.9983 11.4996" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M11.9983 17.502C12.2745 17.502 12.4985 17.726 12.4985 18.0022C12.4985 18.2785 12.2745 18.5024 11.9983 18.5024C11.722 18.5024 11.498 18.2785 11.498 18.0022C11.498 17.726 11.722 17.502 11.9983 17.502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                        </button>
                    }
                    body={
                        <ul className='min-w-[150px]'>
                            <li>
                                <button 
                                    type='button' 
                                    onClick={blockUser} 
                                    className='btn btn--transparent btn--lg radius-5 gap--xs '>
                                        <span>{currentChatData.conversation.isBlocked ? 'UnBlock' : 'Block'}</span> 
                                </button>
                            </li>
                            <li>
                                <button type='button' onClick={handleDeleteButton} className='btn btn--transparent btn--lg radius-5 gap--xs '><span className='color-danger'>Delete</span> </button>
                            </li>
                        </ul>
                    }
                />
            </div>
        </div>
    )
}

export default ChatMessageHead
