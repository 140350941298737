import { NavLink, Outlet } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import CreateSidebar from "../components/parts/createPagesParts/CreateSidebar";
import {ReactComponent as GeneralIcon} from '../assets/img/sidebar-general.svg'
import {ReactComponent as SettingIcon} from '../assets/img/sidebar-settings.svg'
import {ReactComponent as ModeratorIcon} from '../assets/img/sidebar-moderator.svg'
import {ReactComponent as RulesIcon} from '../assets/img/sidebar-rules.svg'
import { getForumFeth } from "../store/forums/forumsThunk";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
export const ForumCreateLayout = () => {
    const dispatch = useDispatch()
    const {forumId} = useSelector(state => state.forums)
    const sidebalLiks = [
        {
            icon: <GeneralIcon/>,
            to: 'general',
            label: 'General Information'
        },
        {
            icon: <RulesIcon/>,
            to: 'rules',
            label: 'Rules',
            disabled: forumId ? false : true
        },
        {
            icon: <ModeratorIcon/>,
            to: 'moderators',
            label: 'Moderators',
            disabled: forumId ? false : true
        },
        {
            icon: <SettingIcon/>
            ,
            to: 'settings',
            label: 'Settings',
            disabled: forumId ? false : true
        },
    ] 
    useEffect(() => {
        if(forumId) {
          dispatch(getForumFeth(forumId));
        }
    }, [forumId])
    return (
        <div className={'quiz-page'}>
            <CreateSidebar
                links={sidebalLiks}
                title={'Create Forum'}
                backRoute={'/forums'}
            />
            <Outlet />
            <div className="custom-overlay toggler-menu" style={{ zIndex: 51 }}></div>
        </div>
    )
}

