import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { setToken } from '../../store/auth/authSlice'
import PageLoader from '../../components/parts/PageLoader'

const SingIn = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch();
    const navigation = useNavigate()
    useEffect(()=> {
        if(searchParams.get('authToken')) {
            // const storage = localStorage.getItem("remember") ? localStorage : sessionStorage;
            // storage.setItem("authToken", searchParams.get('authToken'))
            localStorage.setItem("authToken", searchParams.get('authToken'))
            dispatch(setToken(searchParams.get('authToken')))
            navigation('/')
        }
    },[])
  return (
    <PageLoader/>
  )
}

export default SingIn
