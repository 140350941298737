import React, { useEffect, useMemo, useState } from 'react'
import SwithGroup from '../../components/UI/formElements/SwithGroup'
import InputSearch from '../../components/UI/formElements/InputSearch'
import CommentSection from '../../components/comments/CommentSection'
import { useSelector } from 'react-redux'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getProfileCommentsFeth } from '../../store/profile/profileThunk'
import useDebounce from '../../hooks/useDebounce'

const ProfileComments = () => {
    const [commentsLimit, setCommentsLimit] = useState(10)
    const [commentsPage, setCommentsPage] = useState(1)
    const dispatch = useDispatch()
    const [userId] = useOutletContext()
    const { profileData, isLoading, pagination } = useSelector(state => state.profile);
    const [searchParams, setSearchParams] = useSearchParams()
    const [params, setParams] = useState({
        sort: 'latest',
        search: ''
    })

    
    const handleSortChange = ({ target }) => {
        setParams(prev => ({
            ...prev,
            sort: target.checked ? 'latest' : 'popular'
        }))
        setCommentsPage(1)
    }
    const debouncedSearchChange = useDebounce((value) => {
        setParams(prev => ({
            ...prev,
            search: value
        }))
    }, 500);
    const handleSearchChange = (e) => {
        const { value } = e.target;
        debouncedSearchChange(value);
    };
    useEffect(() => {
        if (userId) {
            dispatch(getProfileCommentsFeth(
                {
                    id: userId,
                    qwery: `page=${commentsPage}&limit=${commentsLimit}&sort=${params.sort}&search=${params.search}`
                }
            ))
        }
    }, [userId, params, commentsPage])
    return (
        <div className="tabs-item active-tab" id="comments">
            <div className="w-full">
                <h3 className="mb-[32px]">Comments</h3>
                <div className="page-heading mb-[30px]">
                    <div className="page-heading__item">
                        <SwithGroup
                            wrapperClasses='btn btn--sm w-auto min-w-[163px]'
                            leftNode={
                                <span className="switch-control--text">Latest</span>
                            }
                            rightNode={<span className="switch-control--text">Popular</span>}
                            onChange={handleSortChange}
                        />
                    </div>
                    <div className="page-heading__item">
                        <form method="get">
                            <InputSearch
                                defaultValue={params.search} onChange={handleSearchChange}
                            />
                        </form>
                    </div>
                </div>
            </div>
            <div>
                {profileData && profileData?.length > 0 ? <CommentSection
                    // addCommentFeth={addCommentArticleIdFeth} 
                    commentArrray={profileData}
                    pagination={pagination}
                    setLimit={setCommentsPage}
                    isLoading={isLoading}
                    showOnly={true}
                /> : isLoading ?
                    <span className="btn btn--shadow btn--xl radius-5 w-full">
                        <span className="ico animate-spin">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.7053 3.40707C14.1506 3.31066 13.5829 3.24999 13.0001 3.24999C12.4173 3.24999 11.8496 3.31066 11.2949 3.40707" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M22.2279 9.7207C22.6103 10.7705 22.8291 11.8982 22.8291 13.079" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M20.5271 6.76315C19.7926 5.8889 18.9054 5.14898 17.9109 4.57373" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.08939 4.57373C7.09489 5.14898 6.20873 5.8889 5.47314 6.76315" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M3.17097 13.079C3.17097 11.8971 3.38981 10.7694 3.77222 9.7207" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.08936 21.5842C9.06436 22.1486 10.1444 22.5484 11.2949 22.7499" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M3.77222 16.4375C4.16872 17.5252 4.74288 18.5251 5.47305 19.395" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M14.7053 22.7499C15.8558 22.5484 16.9348 22.1486 17.9109 21.5842" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M20.5271 19.395C21.2573 18.5251 21.8314 17.5252 22.2279 16.4375" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                    </span>
                    :
                    <h3>Comments not found</h3>
                }
            </div>
        </div>
    )
}

export default ProfileComments
