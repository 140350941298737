const base = '/api/filters';


export const filters = (instance) => ({
    async getFiltersByName(payload) {
        try {
            const { data } = await instance.get(`${base}/${payload}`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getFiltersDiagnosis(payload) {
        try {
            const { data } = await instance.get(`${base}/diagnosis`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
    async getFiltersForFooter(payload) {
        try {
            const { data } = await instance.get(`${base}/footer`);
            return data;
        } catch (error) {
            return {
                success: false,
                message: error.message
            }
        }
    },
});