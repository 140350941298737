import React, { useEffect, useState } from 'react'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { getFormData, getImageSrc } from "../../helpers/utils"
import logo from "../../assets/img/logo.png"
import logoDark from "../../assets/img/logo-footer.png"
import { useDispatch } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import ButtonLoader from '../../components/UI/ButtonLoader'
import { useSelector } from 'react-redux'
import { generateFaFetch, verifyFaFetch } from '../../store/auth/authThunks'
import InputGroup from '../../components/UI/formElements/InputGroup'
import { toast } from 'react-toastify'
import { set2FA } from '../../store/auth/authSlice'


const FA = () => {

	const { siteTheme, token } = useSelector(state => state.auth)
	const navigate = useNavigate()
	const dispacth = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [generateData, setGenerateData] = useState({
		qr: '',
		secret: ''
	})
	const [code2FA, setCode2FA] = useState('')
	useEffect(() => {
		if (!token) return
		async function generateFA() {
			const res = await dispacth(generateFaFetch()).unwrap()

			setGenerateData(res)
		}

		generateFA()
	}, [token])

	const handleSubmit = async (e) => {
		if(code2FA.length < 6) {
			toast.error('The code must contain 6 digits')
			return
		}
		e.preventDefault()
		setIsLoading(true)
		const res = await dispacth(verifyFaFetch({ 'code': +code2FA })).unwrap()
		if(res) {
			dispacth(set2FA(res.auth2fa))
			navigate('/')
		}
		setIsLoading(false)
	}
	return (
		<>
			<div className="wrapper">
				<main className="content">
					<section className="sing-up-section">
						<div className="sing-up-section__item sing-up-section__body">
							<form className="sing-up-section-form" autoComplete='off' onSubmit={handleSubmit}>
								<Link to={"/"} className="logo max-w-[130px]">
									{/* <img src={require("../../assets/img/logo.png")} alt="" /> */}
									<img src={siteTheme === 'dark' ? logoDark : logo} alt="mediwise" />
								</Link>
								<div className="sing-up-section-form__body my-auto">
									<div className="sing-up-section-form__heading">
										<h2>2FA Medwise account </h2>
										<p className="text-[13px]">
											2FA is optional, you can turn it on or off in your account settings <span>?</span>
										</p>
										<p className="text-[13px]">To install 2FA, you need to install the <a className='color-pending' href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Google Authenticator</a>  application on your mobile phone. Then scan the QR code or enter the code manually into the mobile authenticator. Then enter the received code from the mobile authenticator in the Enter 2FA code field</p>
									</div>
									<div className="row g-16">
										<div className="col-12">
											<div className="text-center">
												<img src={generateData.qr} alt="" />
											</div>
										</div>
										<div className='col-12 text-center'>
											or
										</div>
										<div className='col-12 text-center'>
											{generateData.secret}
										</div>
										<div className="col-12">
											<InputGroup
												max={999999}
												type='number'
												wrapperClasses=' input--lg'
												placeholder={'Enter code'}
												inputClasses='input--outline'
												value={code2FA}
												onChange={({ target }) => setCode2FA(target.value)}
											/>
										</div>
									</div>
								</div>
								<div className="sing-up-section-form__bottom">
									<div className="row">
										<div className="col-6">
											<Link
												to={'/'}
												className={`btn btn--shadow btn--lg radius-5 w-full font-semibold  ${isLoading ? 'disabled' : ''}`}>
												Skip
											</Link>
										</div>
										<div className="col-6">
											<button
												type='submit'
												disabled={isLoading}
												className={`btn btn--primary btn--lg radius-5 w-full font-semibold ${isLoading ? 'disabled' : ''}`}>
												{isLoading ? <ButtonLoader /> : 'Confirm'}
											</button>
										</div>
									</div>

								</div>
							</form>
						</div>
						<div className="sing-up-section__item sing-up-section__image" style={{
							backgroundImage: `url(${require('../../assets/img/sing-up-bg-image.png')})`
						}}>
							<Swiper className='sing-up-slider' pagination={{
								el: '.swiper-pagination'
							}} modules={[Pagination]}>
								<SwiperSlide className='sing-up-slider--item'>
									<div className="sing-up-slider--item__text">"Medwise is a game-changer in my medical studies. Its user-friendly interface and interactive tools make learning enjoyable and accessible. The organized content and insightful feedback have boosted my confidence and deepened my understanding."</div>
									<div className="sing-up-slider--item__name">Leslie Alexander / student of university</div>
								</SwiperSlide>
								<div className="swiper-pagination swiper-pagination--white swiper-pagination--static text-start mt-[24px]"></div>
							</Swiper>
						</div>
					</section>
				</main>
			</div>
		</>
	)
}

export default FA
