import React from 'react'
import { useDispatch } from 'react-redux'
import { getAuthorized, setShowLoginModal } from '../../../store/auth/authSlice'
import { useSelector } from 'react-redux'
import pdf from "../../../assets/img/Pdf-File.svg"
import word from "../../../assets/img/Word.svg"
import { Link } from 'react-router-dom'
import { getDateToString, getImageSrc } from '../../../helpers/utils'

const SidebarRelatedDocuments = ({ documents, title, postImageBig = false }) => {
    const dispatch = useDispatch()
    const authorized = useSelector(getAuthorized)
    const protectLink = (e) => {
        if (!authorized) {
            e.preventDefault()
            dispatch(setShowLoginModal(true))
        }
    }
    return (
        <div className="post-widget">
            <div className="post-widget--header">
                <div className="heading-post-row">
                    <span className="ico ico--xs">
                        <img src={require("../../../assets/img/folder-ico.png")} />
                    </span>
                    <p className="heading-title-post text-def-2-c font-[600]">
                        {title}
                    </p>
                </div>
            </div>
            <div className="post-widget--body flex flex-col">
                <ul className="mini-posts-list flex flex-col">
                    {documents.map((item, index) => (
                        <li key={index}>
                            <Link 
                                to={`/materials/document/${item.id}`} 
                                className={`mini-post ${postImageBig ? '' : 'mini-post--image-sm'}`}
                            >
                                <div className="wrapp-image mini-post-img mini-post-img--center">
                                    <img style={{
                                        width: postImageBig ? '32px' : '20px'
                                    }} src={item.fileName.split('.').pop() === 'pdf' ? pdf : word} />
                                </div>
                                <div className="mini-post-content">
                                    <p className="mini-post--title">
                                        {item?.title}
                                    </p>
                                    <p className="mini-post--desk text-[13px]">
                                        {getDateToString(item?.createdAt)}
                                    </p>
                                </div>
                            </Link>
                        </li>))}
                </ul>
                <Link to={'/materials'} className="btn max-w-max">
                    <span className="color-danger text-[13px] font-[500]">
                        See more documents
                    </span>
                </Link>
            </div>
        </div>
    )
}

export default SidebarRelatedDocuments
