import React from 'react'



const Loader = ({className = "", ...rest}) => {
  return (
    <div className={`loader ${className}`} {...rest}></div>
  )
}

export default Loader