import { format, formatDistanceStrict, formatDistanceToNow, getYear, isToday, isYesterday } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { toast } from "react-toastify";

export const getImageSrc = (image, placeholder) => {
    if (!image) {
        return placeholder
    }
    return image instanceof File ? URL.createObjectURL(image) : process.env.REACT_APP_API_URL + image;
}


export const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => {

        if (Array.isArray(object[key])) {
            formData.append(key, JSON.stringify(object[key]))
        } else {
            formData.append(key, object[key])
        }
    });
    return formData;
}

export const findValue = (blocks, key) => {
    if (!blocks) return null
    return blocks.reduce((result, block) => {
        const preset = (block.blockPresets || []).find(blockPreset => {
            return blockPreset.key === key;
        });
        if (!preset?.type) return;
        if (preset.type === "list_items") return preset.value?.data
        if (preset) return preset.value;
        return result;
    }, false);
}

export const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
};

export const checkErrors = (data, { requiredFields = [], exceptions = [], validators = {}, messages = {} } = {}) => {
    const errors = {};
    const errorMessages = {};

    Object.keys(data).forEach(name => {
        if (exceptions.includes(name)) {
            errors[name] = false;
            errorMessages[name] = '';
        } else if (validators[name]) {
            const validationResult = validators[name](data[name]);
            if (validationResult !== true) {
                errors[name] = true;
                errorMessages[name] = validationResult || messages[name] || 'Invalid value';
            } else {
                errors[name] = false;
                errorMessages[name] = '';
            }
        } else {
            const isRequired = requiredFields.includes(name) || requiredFields.length === 0;
            if (isRequired) {
                const hasError = Array.isArray(data[name]) ? !data[name]?.length : !data[name];
                errors[name] = hasError;
                errorMessages[name] = hasError ? (messages[name] || `This field is required`) : '';
            } else {
                errors[name] = false;
                errorMessages[name] = '';
            }
        }
    });

    const isErrors = Object.values(errors).some(value => value);
    return isErrors ? { errors, errorMessages } : false;
};

export const getDateToString = (date) => {
    if (!date) return
    return format(date, "MMM dd, yyyy")
}
export function formatDateRange(startDate, endDate) {
    if (!startDate) return
    const formattedStartDate = format(new Date(startDate), 'MMM yyyy'); // Форматирование начальной даты
    const formattedEndDate = endDate ? format(new Date(endDate), 'MMM yyyy') : 'Present'; // Форматирование конечной даты или использование 'Present'
    const distance = formatDistanceStrict(new Date(startDate), endDate ? new Date(endDate) : new Date(), { addSuffix: false }); // Вычисление расстояния

    return `${formattedStartDate} - ${formattedEndDate} - ${distance}`; // Создание строки формата
}

export function timeDifference(dateString) {
    const createdDate = new Date(dateString);
    const currentDate = new Date();

    const diffInMilliseconds = currentDate - createdDate;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInSeconds < 60) {
        return `${Math.abs(diffInSeconds)} sec ago`;
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hr ago`;
    } else {
        return `${diffInDays} days ago`;
    }
}

export const timeAgo = (date) => {
    if (!date) return
    return formatDistanceToNow(date, { addSuffix: true });
};

export function formatDateToTime(date) {
    if (!date) return
    return format(date, 'h:mm a');
}


export function formatDateTimeZone(date) {
    if (!date) return
    // Получите тайм-зону из браузера
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formattedDate = formatInTimeZone(date, timeZone, 'hh:mm a \'GMT\'XXX');

    return formattedDate;
}

export const getHeaderByDate = (date) => {
    const currentYear = new Date().getFullYear();
    if (isToday(date)) {
        return 'Today';
    } else if (isYesterday(date)) {
        return 'Yesterday';
    } else if (getYear(date) === currentYear) {
        return format(date, 'MMM d');
    } else {
        return format(date, 'MMM d, yyyy');
    }
};


export const saveProtectFunction = (e, hasEditChanges) => {
    if (hasEditChanges) {
        toast.warning('You have no conscious changes, click “Continue for awareness.”')
        e.preventDefault()
    }
}

/**
 * Utility function to handle file upload with progress tracking and toast notifications
 * @param {Function} uploadFunction - The function to handle the file upload (e.g., Axios POST request).
 * @param {Object} config - Configuration object containing URL, body, and additional Axios config.
 * @returns {Promise<Object>} - The response data or error message.
 */

export const handleFileUploadWithProgress = async (uploadFunction, config) => {
    let toastId = null;

    try {
        const { data } = await uploadFunction({
            ...config,
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                if (toastId === null) {
                    toastId = toast(`Upload in Progress: ${percentCompleted}%`, {
                        progress: percentCompleted / 100,
                        isLoading: true,
                        autoClose: false, // Keep toast open during upload
                    });
                } else {
                    toast.update(toastId, {
                        render: `Upload in Progress: ${percentCompleted}%`,
                        progress: percentCompleted / 100,
                    });
                }
            },
        });

        toast.update(toastId, {
            render: 'Upload Successful',
            type: 'success',
            isLoading: false,
            autoClose: 5000, // Close after 5 seconds upon success
        });

        return data;
    } catch (error) {
        if (toastId !== null) {
            toast.update(toastId, {
                render: `Error: ${error.message}`,
                type: 'error',
                isLoading: false,
                autoClose: false, // Keep toast open on error
            });
        } else {
            toast.error(`Error: ${error.message}`);
        }

        return {
            success: false,
            message: error.message,
        };
    }
};
export const finalizeUploadToast = () => {
    toast.update('uploadProgress', {
        render: `Uploading: 100%`,
        progress: 100,
    });
    setTimeout(() => {
        toast.dismiss('uploadProgress');
    }, 500);
}
export const getFullName = (firstName, lastName) => {
    return [firstName, lastName].filter(Boolean).join(" ")
}

export const formatDateRange2 = (startedAt, endedAt) => {
    const startDate = new Date(startedAt);
    const endDate = new Date(endedAt);

    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    if (startYear !== endYear) {
        return `${format(startDate, 'MMM d, yyyy')} - ${format(endDate, 'MMM d, yyyy')}`;
    } else if (startDate.getMonth() !== endDate.getMonth()) {
        return `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d, yyyy')}`;
    } else {
        return `${format(startDate, 'MMM')} ${format(startDate, 'd')}-${format(endDate, 'd')}, ${startYear}`;
    }
}

export const capitalize = (str) => {
    if (!str || typeof str !== "string") return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
