import React, { useState } from 'react'

const AccordeonLockedType = ({ iconLeft, title, isLock = false, children, id , isCompleted}) => {
    const [accordeonActive, setAccordeonActive] = useState(false)
    
    const clickHandler = () => {
        if (isLock) {
            setAccordeonActive(false)
        } else {
            setAccordeonActive(prev => !prev);
        }

    }
    return (
        <div className={`accordeon ${accordeonActive ? 'active-accordeon' : ''}`} id={id}>
            <div className="accordeon-handler" onClick={clickHandler} >
                <span className='ico ico-24'>
                    {iconLeft}
                </span>
                <div className="accordeon__title flex-auto truncate">{title}</div>
                <span className="accordeon__icon-secondary">
                    {isLock ?
                        <span className="ico ico-24">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.417 22.75H7.58366C6.38658 22.75 5.41699 21.7804 5.41699 20.5833V11.9167C5.41699 10.7196 6.38658 9.75 7.58366 9.75H18.417C19.6141 9.75 20.5837 10.7196 20.5837 11.9167V20.5833C20.5837 21.7804 19.6141 22.75 18.417 22.75Z" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M13.0007 18.5143V15.7085" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M13.5745 14.3215C13.8918 14.6388 13.8918 15.1532 13.5745 15.4705C13.2572 15.7878 12.7428 15.7878 12.4255 15.4705C12.1082 15.1532 12.1082 14.6388 12.4255 14.3215C12.7428 14.0042 13.2572 14.0042 13.5745 14.3215" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.66699 9.75V7.58333V7.58333C8.66699 5.19025 10.6072 3.25 13.0003 3.25V3.25C15.3934 3.25 17.3337 5.19025 17.3337 7.58333V7.58333V9.75" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                        :
                        <>
                            <span className="ico ico-24">
                                {isCompleted ?  <img className='w-full' src={require('../../assets/img/icons8-double-tick-64.png')} alt="" /> :<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.33398 13H21.6673" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path className="path-toggle" d="M13 4.33325V21.6666" stroke="#6F7D8C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>}
                            </span>                            
                        </>
                    }
                </span>
            </div>
            {accordeonActive &&
                <div className="accordeon-body !block">
                    <div>
                        {children}
                    </div>
                </div>
            }
        </div>
    )
}

export default AccordeonLockedType
