import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Circle, Image as KonvaImage, Layer, Line, Stage, Group } from 'react-konva';
import { useImage } from "react-konva-utils";

import { ReactComponent as DrawIcon } from "../../assets/img/ico-draw.svg";
import { ReactComponent as EraseIcon } from "../../assets/img/ico-erase.svg";
import { ReactComponent as HintsIcon } from "../../assets/img/ico-hint-cloud.svg";
import SelectCommentModal from '../Modals/SelectCommentModal';
import AutoResizeTextarea from './AutoResizeTextarea';


const fileToDataImage = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};
const HINT_OPTION = {
    radius: 8,
    strokeWidth: 13,
    strokeDefaultColor: "#19191932",
    strokeActiveHintColor: '#FFE47F',
    fillDragColor: '#FFE47F',
    filDefaultColor: '#ffffff',
    filActiveColor: '#191919'
}
const DrawingComponentTypeModam = React.forwardRef(({ image = null, hintsSelected = null, selectedIndexes = [], setItems, showOnly = false, modalType = false }, stageRef) => {
    // const [lines, setLines] = useState([]);
    const isDrawing = useRef(false);
    const containerRef = useRef();
    const [tool, setTool] = useState("");
    const [color, setColor] = useState('#000000');
    const [lineWidth, setLineWidth] = useState(5);
    const [eraserSize, setEraserSize] = useState(20);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [commentModal, setCommentModal] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [loadedImage] = useImage(imageSrc);
    const [mobileCommentsChange, setMobileCommentsChange] = useState(false)
    const [isDragging, setIsDragging] = useState(false);
    const [isActiveHitn, setIsActiveHiht] = useState(false)
    const [scales, setScales] = useState({ scaleX: 1, scaleY: 1 })
    const [pointMode, setPointMode] = useState(false)
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0
    });

    const updateCanvasDimensions = () => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            });
        }
    };
    const handelCommentModal = () => {
        setCommentModal(prev => !prev)
    }
    useEffect(() => {
        updateCanvasDimensions();
        window.addEventListener('resize', updateCanvasDimensions);
        return () => window.removeEventListener('resize', updateCanvasDimensions);
    }, []);
    useEffect(() => {
        if (containerRef.current) {
            setDimensions({
                width: containerRef.current.offsetWidth,
                height: containerRef.current.offsetHeight,
            })
        }
    }, [containerRef, loadedImage])
    const [activeHint, setActiveHiht] = useState({
        content: ''
    })
    useEffect(() => {
        setIsActiveHiht(false)
        setTool('')
    }, [imageSrc])
    useEffect(() => {
        if (isActiveHitn && hintsSelected) {
            setActiveHiht(...hintsSelected?.hintTexts?.filter(item => item.id === isActiveHitn))
        }
    }, [isActiveHitn, hintsSelected])

    const renderCommentStyles = (id) => {
        return {
            position: 'absolute',
            left: (activeHint?.position?.x * calculateScale().x + calculateOffset().x) - 10 || 0,
            top: (activeHint?.position?.y * calculateScale().y + calculateOffset().y) + 10 || 0,
            maxHeight: '200px'
        }
    }

    useEffect(() => {
        const loadImage = async () => {
            if (image instanceof File) {
                try {
                    const dataUrl = await fileToDataImage(image);
                    setImageSrc(dataUrl);
                } catch (error) {
                    console.error("Error converting file to Data URL:", error);
                }
            } else {

                setImageSrc(image?.preview);

            }
        };
        loadImage();
    }, [image]);

    const calculateScale = () => {
        if (!loadedImage) return { x: 1, y: 1 };

        const scaleX = dimensions.width / loadedImage.width;
        const scaleY = dimensions.height / loadedImage.height;
        const scale = Math.min(scaleX, scaleY);
        return { x: scale, y: scale };
    };

    const calculateOffset = () => {
        if (!loadedImage) return { x: 0, y: 0 };

        const scaledWidth = loadedImage.width * calculateScale().x;
        const scaledHeight = loadedImage.height * calculateScale().y;
        const offsetX = (dimensions.width - scaledWidth) / 2;
        const offsetY = (dimensions.height - scaledHeight) / 2;
        return { x: offsetX, y: offsetY };
    };
    const scaledLines = hintsSelected?.hintMarkers?.map(line => ({
        ...line,
        points: line.points.map((p, i) =>
            i % 2 === 0
                ? p * calculateScale().x + calculateOffset().x
                : p * calculateScale().y + calculateOffset().y
        ),
    }));
    const scaledHints = hintsSelected?.hintTexts?.map(hint => ({
        ...hint,
        position: hint.position ? { x: hint.position.x * calculateScale().x + calculateOffset().x, y: hint.position.y * calculateScale().y + calculateOffset().y } : null
    }))

    const addHint = (pos, justComment = false) => {
        setItems(prev => prev.map((item, index) => {
            if (selectedIndexes.includes(index)) {
                return {
                    ...prev[index],
                    hintTexts: [
                        ...item.hintTexts,
                        {
                            id: 'my' + prev[index].hintTexts.length + 1,
                            content: "",
                            position: justComment
                                ? null
                                : { x: pos.x, y: pos.y }
                        }]
                }
            } else {
                return item
            }
        }))
    };
    const removeHintHandler = (id) => {
        setItems(prev => prev.map((item, index) => {
            if (selectedIndexes.includes(index)) {
                return {
                    ...prev[index],
                    hintTexts: prev[index].hintTexts.filter(item => item.id !== id)
                }
            } else {
                return item
            }
        }))
        setIsActiveHiht(false)
    }
    const handleMouseDown = (e) => {
        if (!tool) return;

        const pos = e.target.getStage().getPointerPosition();
        const adjustedPos = {
            x: (pos.x - calculateOffset().x) / calculateScale().x,
            y: (pos.y - calculateOffset().y) / calculateScale().y,
        };
        if (adjustedPos.x < 0 || adjustedPos.y < 0 || adjustedPos.y > loadedImage.height || adjustedPos.x > loadedImage.width) return
        if (tool === "hint" && !modalType && e.target?.parent?.parent?.index !== 2 && e.target?.parent) {
            addHint(adjustedPos)
            return;
        }
        if (tool === "hint" && modalType && pointMode && e.target?.parent?.parent?.index !== 2 && e.target?.parent) {
            addHint(adjustedPos)
            return;
        }
        if (tool !== "hint") {
            isDrawing.current = true;
            setItems(prev => prev.map((item, index) => {
                if (selectedIndexes.includes(index)) {
                    return {
                        ...item,
                        hintMarkers: [...item.hintMarkers, { tool, points: [adjustedPos.x, adjustedPos.y], strokeWidth: tool === 'erase' ? eraserSize : lineWidth, color }]
                    }
                } else {
                    return item
                }
            }))
        }
    };
    const handleMouseMove = (e) => {
        if (showOnly) return
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        const adjustedPos = {
            x: (point.x - calculateOffset().x) / calculateScale().x,
            y: (point.y - calculateOffset().y) / calculateScale().y,
        };
        setCursorPosition(point);
        if (adjustedPos.x < 0 || adjustedPos.y < 0 || adjustedPos.y > loadedImage.height || adjustedPos.x > loadedImage.width) return
        if (!isDrawing.current) return;

        let lastLine = hintsSelected.hintMarkers[hintsSelected.hintMarkers.length - 1];
        lastLine.points = lastLine.points.concat([adjustedPos.x, adjustedPos.y]);
        hintsSelected.hintMarkers.splice(hintsSelected.hintMarkers.length - 1, 1, lastLine);
        setItems(prev => prev.map((item, index) => {
            if (selectedIndexes.includes(index)) {
                return {
                    ...item,
                    hintMarkers: hintsSelected.hintMarkers.concat()
                }
            } else {
                return item
            }
        }))
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };
    const dragEndHints = (e, id) => {

        setIsDragging(false)
        const adjustedPos = {
            x: (e.target.x() - calculateOffset().x) / calculateScale().x,
            y: (e.target.y() - calculateOffset().y) / calculateScale().y,
        };
        if (adjustedPos.x < 0) {
            adjustedPos.x = 10
        }
        if (adjustedPos.y < 0) {
            adjustedPos.y = 10
        }
        if (adjustedPos.y > loadedImage.height) {
            adjustedPos.y = loadedImage.height - 10
        }
        console.log(adjustedPos.y)
        if (adjustedPos.x > loadedImage.width) {
            adjustedPos.x = loadedImage.width - 10
        }
        setItems(prev => prev.map((item, index) => {
            if (selectedIndexes.includes(index)) {
                return {
                    ...item,
                    hintTexts: item.hintTexts.map(hint => {
                        if (hint.id === id) {

                            const newHint = {
                                ...hint,
                                position: {
                                    x: adjustedPos.x,
                                    y: adjustedPos.y,
                                }
                            }
                            return newHint
                        } else {
                            return hint
                        }
                    })
                }
            } else {
                return item
            }
        }))

    }
    const commentHandler = ({ target }, hintId) => {
        setItems(prev => prev.map((item, index) => {
            if (selectedIndexes.includes(index)) {
                return {
                    ...item,
                    hintTexts: item.hintTexts.map(hint => {
                        if (hint.id === hintId) {
                            const newHint = {
                                ...hint,
                                content: target.value
                            }
                            return newHint
                        } else {
                            return hint
                        }
                    })
                }
            } else {
                return item
            }
        }))
        setActiveHiht(prev => ({
            ...prev,
            content: target.value
        }))
    }
    const handleColorChange = (e) => {
        setColor(e.target.value);
    };

    const handleLineWidthChange = (value) => {
        setLineWidth(parseInt(value[0]));
    };

    const handleEraserSizeChange = (value) => {
        setEraserSize(parseInt(value[0]));
    };
    const handlerClickHint = (id) => {
        setIsActiveHiht(id === isActiveHitn ? false : id)
    }
    if (!hintsSelected) return null
    return (
        <>
            <div className="editor-col editor-col--content">
                <div className="canvas-workspace" style={{ height: '100%', overflow: "visible", padding: '20px 0' }}>
                    <div className="canvas-container flex-auto">
                        <div className="draw-canvas-content" ref={containerRef} style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            margin: "auto"
                        }}>
                            <Stage
                                width={dimensions.width}
                                height={dimensions.height}
                                onMouseDown={handleMouseDown}
                                onMousemove={handleMouseMove}
                                onMouseup={handleMouseUp}
                                onTouchStart={handleMouseDown}
                                onTouchMove={handleMouseMove}
                                onTouchEnd={handleMouseUp}
                                ref={stageRef}
                            >

                                <Layer>
                                    {loadedImage && (
                                        <KonvaImage
                                            image={loadedImage}
                                            width={loadedImage.width * calculateScale().x}
                                            height={loadedImage.height * calculateScale().y}
                                            x={calculateOffset().x}
                                            y={calculateOffset().y}
                                        />
                                    )}
                                </Layer>
                                <Layer>
                                    {scaledLines?.map((line, i) => (
                                        <Line
                                            key={i}
                                            points={line.points}
                                            stroke={line.tool === 'erase' ? 'white' : line.color}
                                            strokeWidth={line.strokeWidth}
                                            tension={0.5}
                                            lineCap="round"
                                            globalCompositeOperation={
                                                line.tool === 'erase' ? 'destination-out' : 'source-over'
                                            }
                                        />
                                    ))}
                                    <Circle
                                        x={cursorPosition.x}
                                        y={cursorPosition.y}
                                        radius={tool === 'erase' ? eraserSize / 2 : lineWidth / 2}
                                        stroke={tool === 'erase' ? 'gray' : color}
                                        strokeWidth={1}
                                        dash={[4, 4]}
                                    />
                                </Layer>
                                {(tool === 'hint' || showOnly) &&
                                    <Layer>
                                        {scaledHints?.map((hint, index) => {
                                            if (hint.position) {
                                                return <Group
                                                    key={index}
                                                    draggable={!showOnly}
                                                    x={hint.position?.x}
                                                    y={hint.position?.y}
                                                    onDragStart={() => {
                                                        if (showOnly) return
                                                        setIsDragging(hint.id)
                                                    }}
                                                    onDragEnd={(e) => {
                                                        if (showOnly) return
                                                        dragEndHints(e, hint.id)
                                                    }
                                                    }
                                                    onClick={() => {
                                                        setMobileCommentsChange(true)
                                                        handlerClickHint(hint.id)
                                                    }}
                                                    onTap={() => {
                                                        setMobileCommentsChange(true)
                                                        handlerClickHint(hint.id)
                                                        
                                                    }}
                                                >
                                                    <Circle
                                                        radius={HINT_OPTION.radius}
                                                        stroke={
                                                            isDragging === hint.id ?
                                                                HINT_OPTION.strokeDefaultColor :
                                                                isActiveHitn === hint.id ?
                                                                    HINT_OPTION.strokeActiveHintColor :
                                                                    HINT_OPTION.strokeDefaultColor
                                                        }
                                                        strokeWidth={HINT_OPTION.strokeWidth}
                                                        fill={`
                                                            ${isDragging === hint.id ?
                                                                HINT_OPTION.fillDragColor :
                                                                isActiveHitn === hint.id ?
                                                                    HINT_OPTION.filActiveColor :
                                                                    HINT_OPTION.filDefaultColor}`
                                                        }
                                                        fillAfterStrokeEnabled
                                                    />

                                                </Group>
                                            } else {
                                                return null
                                            }
                                        })}
                                    </Layer>
                                }
                            </Stage>
                            {/* {isActiveHitn && !isDragging && !modalType &&
                                <div className="dropdown__body dropdown-hint__body dropdown-hint__body--creation is-open" style={renderCommentStyles(isActiveHitn)}>
                                    {!showOnly ? <>
                                        <textarea className="textarea" value={activeHint?.content} onChange={commentHandler} placeholder="Leave your comment here..." style={{ resize: 'none', minHeight: '200px', }}></textarea>
                                        <button
                                            type='button'
                                            className="btn"
                                            onClick={removeHintHandler}
                                        >
                                            <span className="ico ico-22">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.7928 19.2498H7.20276C6.24301 19.2498 5.44551 18.5092 5.37401 17.5513L4.55176 6.4165H17.4163L16.6215 17.5467C16.5528 18.5064 15.7543 19.2498 14.7928 19.2498V19.2498Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M11.0003 10.0835V15.5835" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M3.66699 6.41683H18.3337" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M15.5837 6.41667L14.6551 3.93983C14.3865 3.22392 13.7027 2.75 12.9382 2.75H9.06249C8.29799 2.75 7.61416 3.22392 7.34558 3.93983L6.41699 6.41667" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M14.1437 10.0835L13.7495 15.5835" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M7.85598 10.0835L8.25014 15.5835" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </>
                                        :
                                        <p>{activeHint?.content}</p>
                                    }
                                </div>} */}
                        </div>
                    </div>
                    {!showOnly && <ul className="vertical-controls">
                        <li className="control-item">
                            <div className={`control-tool ${tool === "hint" ? "tool_active" : ""}`}>
                                <button
                                    onClick={() => {
                                        setTool(tool !== "hint" ? "hint" : "")
                                        if (tool !== "hint" && modalType) {
                                            handelCommentModal()
                                        }
                                    }}
                                    className="btn btn--shadow-2 btn--square btn--lg rounded-full control-tool-btn"
                                    disabled={!imageSrc ? true : false}
                                >
                                    <span className="ico">
                                        <HintsIcon />
                                    </span>
                                </button>
                            </div>
                        </li>
                        {
                            selectedIndexes.length <= 1 &&
                            <>
                                <li className="control-item">
                                    <div className={`control-tool ${tool === "draw" ? "tool_active" : ""}`}>
                                        <button
                                            className="btn btn--shadow-2 btn--square btn--lg rounded-full control-tool-btn"
                                            onClick={() => setTool(tool !== "draw" ? "draw" : "")}
                                            disabled={!imageSrc ? true : false}
                                        >
                                            <span className="ico">
                                                <DrawIcon />
                                            </span>
                                        </button>
                                        <div className="control-tool-menu" style={{ display: tool === "draw" ? "flex" : "none" }}>
                                            <label className="btn btn--shadow-2 btn--square btn--md rounded-full">
                                                <input type="color" className="custom-colorpicker" value={color} onChange={handleColorChange} />
                                            </label>
                                            <div className="btn btn--shadow-2 rounded-full btn--md w-[40px] py-[14px]">
                                                <Nouislider
                                                    range={{ min: 1, max: 50 }}
                                                    start={lineWidth}
                                                    onChange={handleLineWidthChange}
                                                    className={"slider-1 custom-slider none-tooltips"}
                                                    orientation={"vertical"}
                                                    connect={[true, false]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="control-item">
                                    <div className={`control-tool ${tool === "erase" ? "tool_active" : ""}`}>
                                        <button
                                            onClick={() => setTool(tool !== "erase" ? "erase" : "")}
                                            className="btn btn--shadow-2 btn--square btn--lg rounded-full control-tool-btn"
                                            disabled={!imageSrc ? true : false}
                                        >
                                            <span className="ico">
                                                <EraseIcon />
                                            </span>
                                        </button>
                                        <div className="control-tool-menu" style={{ display: tool === "erase" ? "flex" : "none" }}>
                                            <div className="btn btn--shadow-2 rounded-full btn--md w-[40px] py-[14px]">
                                                <Nouislider
                                                    range={{ min: 1, max: 50 }}
                                                    start={eraserSize}
                                                    onChange={handleEraserSizeChange}
                                                    className="slider-1 custom-slider none-tooltips"
                                                    orientation="vertical"
                                                    connect={[true, false]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </>
                        }
                    </ul>}
                </div>
            </div>
            <div className={`editor-col editor-col--sidebar-comments editor-col--sidebar-drowpart ${mobileCommentsChange ? '--active' : ''}`}>

                <ul className="comments-list-2">
                    <li className='comment-button-mobile-close'>
                        <button
                            type='button'
                            className="btn w-full"
                            onClick={() => {
                                handlerClickHint(null)
                                setMobileCommentsChange(false)}}
                        >
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.1875 4.8125L4.8125 17.1875" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.1875 17.1875L4.8125 4.8125" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </li>
                    {hintsSelected?.hintTexts?.map((hint, index) => (
                        <li className="comment-item" key={hint.id}>
                            <div className={`form-comment ${hint.id === isActiveHitn ? '--selected' : ''}`}>
                                <AutoResizeTextarea
                                    value={hint.content}
                                    onFocus={() => {
                                        setTool('hint')
                                        setIsActiveHiht(hint.id)
                                    }
                                    }
                                    onChange={(e) => {
                                        commentHandler(e, hint.id)
                                    }} />
                                <div className="comment-footer">
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={() => { removeHintHandler(hint.id) }} >
                                        <span className="ico ico-22">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M14.7932 19.25H7.20325C6.2435 19.25 5.446 18.5094 5.3745 17.5514L4.55225 6.41669H17.4167L16.622 17.5469C16.5532 18.5066 15.7548 19.25 14.7932 19.25V19.25Z" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M10.9998 10.0833V15.5833" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M3.6665 6.41665H18.3332" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M15.5832 6.41667L14.6546 3.93983C14.386 3.22392 13.7022 2.75 12.9377 2.75H9.062C8.2975 2.75 7.61367 3.22392 7.34509 3.93983L6.4165 6.41667" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M14.1442 10.0833L13.75 15.5833" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M7.85598 10.0833L8.25014 15.5833" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {commentModal &&
                <SelectCommentModal
                    canPoint={selectedIndexes.length === 1}
                    open={commentModal}
                    handelClose={() => {
                        setTool('')
                        handelCommentModal()
                    }}
                    poinHandler={() => {
                        setPointMode(true)
                        handelCommentModal()
                    }}
                    justCommentHandler={() => {
                        setMobileCommentsChange(true)
                        setTool('')
                        setPointMode(false)
                        addHint({ x: 0, j: 0 }, true)
                        handelCommentModal()

                    }}
                />
            }
        </>
    );
});

export default DrawingComponentTypeModam;