import { useEffect, useState } from "react";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import SelectGroup from "../../components/UI/formElements/SelectGroup"
import InputGroup from "../../components/UI/formElements/InputGroup";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../components/UI/Loader";
import { getIsLoading, setClearState, setQuiz, setQuizId } from "../../store/quizzes/quizzesSlice";
import { data } from "../../data";
import CheckBoxGroup from "../../components/UI/formElements/CheckBoxGroup";
import { addQuizSettingsByQuizIdFeth, editQuizSettingsByIdFeth } from "../../store/quizzes/quizzesThunks";
import { setHasEditChanges } from "../../store/auth/authSlice";

export const QuizCreateSettings = () => {
   
    const [quizId, quiz] = useOutletContext();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLoading = useSelector(getIsLoading)
    const [customTime, setCustomTime] = useState(false)
    const [settingsData, setSettingsData] = useState({
        timer: null,
        // private: false,
        enableComments: false,
        one_attempt: false,
        can_copy: false,
        discussion: null
    })
    const [timeOptions, setTimerOptions] = useState([
        { value: 'custom', label: "Custom Time" },
        { value: 30, label: "30 min" },
        { value: 40, label: "40 min" },
        { value: 50, label: "50 min" },
        { value: 60, label: "1 hour" },
        { value: 70, label: "1 hour 10 min" },
        { value: 80, label: "1 hour 20 min" },
        { value: 90, label: "1 hour 30 min" },
        { value: 100, label: "1 hour 40 min" },
        { value: 110, label: "1 hour 50 min" },
        { value: 120, label: "2 hours" },
    ])
    const [links, setLinks] = useState([])
    const [newLink, setNewLink] = useState('')
    
    const [showAddLink, setShowAddLink] = useState(false)
    const addLink = () => {
        if (!newLink === '') return
        dispatch(setHasEditChanges(true))
        setLinks(prev => ([
            ...prev,
            newLink
        ]))
        setNewLink('')
    }
    useEffect(() => {
        if(quiz?.quizSetting) { 
                setSettingsData(prev => ({
                ...prev,
                ...quiz.quizSetting,
                discussion: quiz?.quizSetting?.discussion ? {
                    ...quiz?.quizSetting?.discussion
                } : null
            }))
        }
        if(quiz?.quizSetting?.discussion) {
            setLinks([...quiz?.quizSetting?.discussion?.links])
        }
        if(quiz?.quizSetting?.timer) {
            const hasValue = timeOptions.findIndex(time => time.value === quiz.timer)
            console.log(hasValue)
            if(hasValue === -1) {
                setTimerOptions(prev => ([
                    ...prev,
                    {value: quiz.quizSetting.timer, label: formatMinutes(quiz.quizSetting.timer) }
                ]))
            }
        }
    },[quiz])   
    function formatMinutes(totalMinutes) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
      
        const hoursPart = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
        const minutesPart = minutes > 0 ? `${minutes} min` : '';
      
        return [hoursPart, minutesPart].filter(Boolean).join(' ');
    }
    const newLinkHandler = (e) => {
        dispatch(setHasEditChanges(true))
        setNewLink(e.target.value)
    }
    const deleteLink = (idx) => {
        dispatch(setHasEditChanges(true))
        setLinks(links.filter((item, index) => index !== idx))
    }
    const conf = {
        title: 'Settings',
    }
    
    
    const handelChange = (name, value, customTimer = false) => {
        dispatch(setHasEditChanges(true))
        if(name === 'timer' && value === 'custom') {
            setCustomTime(true)
            return
        } else {
            !customTimer && setCustomTime(false)
        }
        console.log(value)
        setSettingsData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const discussionHandler = (name, value) => {
        dispatch(setHasEditChanges(true))
        setSettingsData(prev => ({
            ...prev,
            discussion: {
                ...prev?.discussion,
                [name]: value
            }
        }))
    }
    
    const saveHandler = async (createAnother = false) => {
        let body = {...settingsData}
        
        if(body.discussion) {
            body.discussion = {
                ...body.discussion,
                links: links
            }
        }
        let res = null;

        if (quiz?.quizSetting) {
            res = await dispatch(editQuizSettingsByIdFeth({ id: quizId, body })).unwrap();
        } else {
            res = await dispatch(addQuizSettingsByQuizIdFeth({ id: quizId, body })).unwrap()
        }

       
        if (createAnother && res) {
            dispatch(setQuiz(null))
            dispatch(setQuizId(null))
            dispatch(setClearState())
            navigate('/quiz-create/general')
        }
        dispatch(setHasEditChanges(false))
        setCustomTime(false)
    }
    if (isLoading) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader />
        </div>
    )

    return (
        <>
            <CreateFormHeader config={conf} />
            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--full">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="container container--lg">
                                                <div className="group gap--lg md:flex-nowrap">
                                                    <p className="text--md font-medium min-w-[140px] md:text-right">Timer</p>
                                                    <div className="w-full">
                                                        <div className="mb-[26px]">
                                                            <label className="quiz-answer-item">
                                                                <input type="radio"
                                                                    defaultChecked={quiz?.quizSetting?.timer}
                                                                    name="timer"
                                                                    onChange={
                                                                        (e) => {
                                                                            if (!quiz?.quizSetting?.timer) {
                                                                                handelChange('timer', 30)
                                                                            }
                                                                        }
                                                                    }
                                                                    hidden />
                                                                <span
                                                                    className="quiz-answer-item__icon mr-[16px]"></span>
                                                                <div className="w-full">
                                                                    <p className="text--sm mb-[6px]">Set deadline</p>
                                                                    <p className="color-secondary text--xs mb-[16px]">Make
                                                                        timer with quiz deadline</p>
                                                                    <SelectGroup
                                                                        groupClasses={'items-center gap--lg md:flex-nowrap'}
                                                                        wrapperClasses={'select--outline input--md w-full max-w-[200px]'}
                                                                        options={timeOptions}
                                                                        defaultValue={
                                                                            !quiz?.quizSetting?.timer
                                                                                ? false
                                                                                : {
                                                                                    value: quiz?.quizSetting?.timer,
                                                                                    label: `${quiz?.quizSetting?.timer} min`
                                                                                }
                                                                        }
                                                                        onChange={(option) => handelChange('timer', option.value)}
                                                                        placeholder={"Select deadline"}
                                                                    />
                                                                    {customTime && <div className="input-wrapper input--md w-full max-w-[200px] mt-2">
                                                                        <input type="time" onChange={({target}) => {
                                                                            const [hours, minutes] = target.value.split(":").map(Number);
                                                                            const totalMinutes = hours * 60 + minutes;
                                                                            handelChange('timer', totalMinutes, true)
                                                                            }} className="input input--outline" />
                                                                    </div>}
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <label className="quiz-answer-item">
                                                            <input
                                                                type="radio"
                                                                name="timer"
                                                                defaultChecked={!quiz?.quizSetting?.timer}
                                                                onChange={(e) => handelChange('timer', null)}
                                                                hidden />
                                                            <span className="quiz-answer-item__icon mr-[16px]"></span>
                                                            <div>
                                                                <p className="text--sm mb-[6px]">No timer</p>
                                                                <p className="color-secondary text--xs">Take as much time as
                                                                    you need for a quiz</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr className="hr" />
                                        </div>
                                        <div className="col-12">
                                            <div className="container container--lg">
                                                <div className="group gap--lg md:flex-nowrap">
                                                    <p className="text--md font-medium min-w-[140px] md:text-right">Discussion</p>
                                                    <div className="w-full ">
                                                        <div className="mb-[26px]">
                                                            <label className="quiz-answer-item">
                                                                <input
                                                                    type="radio"
                                                                    defaultChecked={quiz?.quizSetting?.discussion}
                                                                    name="discussion"
                                                                    hidden
                                                                    onChange={(e) => handelChange('discussion', {})}
                                                                />
                                                                <span
                                                                    className="quiz-answer-item__icon mr-[16px]"></span>
                                                                <div className="w-full">
                                                                    <p className="text--sm mb-[6px]">Add discussion</p>
                                                                    <p className="color-secondary text--xs mb-[16px]">Attach
                                                                        some statement and let people comment the
                                                                        quiz</p>
                                                                    <div
                                                                        className="input-wrapper input--md w-full mb-[8px]">
                                                                        <input className="input input--outline"
                                                                            type="text"
                                                                            defaultValue={quiz?.quizSetting?.discussion?.statement}
                                                                            disabled={!settingsData?.discussion && !quiz?.quizSetting?.discussion}
                                                                            onChange={({ target }) => discussionHandler('statement', target.value)}
                                                                            placeholder="Enter quiz statement..." />
                                                                    </div>
                                                                    <div
                                                                        className="list-group list-group--xs flex-auto">
                                                                        <div className="input-wrapper input--md flex">
                                                                            <textarea
                                                                                className="textarea input--outline"
                                                                                type="text"
                                                                                placeholder="Add description to your statement..."
                                                                                defaultValue={quiz?.quizSetting?.discussion?.description}
                                                                                disabled={!settingsData?.discussion && !quiz?.quizSetting?.discussion}
                                                                                onChange={({ target }) => discussionHandler('description', target.value)}
                                                                                style={{ minHeight: '150px', resize: 'none' }}></textarea>
                                                                        </div>
                                                                        <div className="links-group">
                                                                            {links && links.map((item, index) => (
                                                                                <div key={index}
                                                                                    className="links-group__item color-danger">
                                                                                    <p>{item}</p>
                                                                                    <button type="button"
                                                                                        onClick={() => deleteLink(index)}
                                                                                        className="btn">
                                                                                        <svg width="24" height="24"
                                                                                            viewBox="0 0 24 24" fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M8 8.5L16 16.5"
                                                                                                stroke="#999999"
                                                                                                strokeWidth="1.2"
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"></path>
                                                                                            <path d="M16 8.5L8 16.5"
                                                                                                stroke="#999999"
                                                                                                strokeWidth="1.2"
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"></path>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        {showAddLink && <div className="add-link-group flex flex-wrap gap-[20px]">
                                                                            <InputGroup
                                                                                wrapperClasses={'input--lg flex-auto'}
                                                                                inputClasses={'input--outline'}
                                                                                placeholder={'Link'}
                                                                                name={'link'}
                                                                                value={newLink}
                                                                                onChange={newLinkHandler}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn--primary btn--xs radius-5"
                                                                                onClick={addLink}
                                                                            >
                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M3.33398 10H16.6673" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    <path d="M10 3.33337V16.6667" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                </svg>
                                                                            </button>
                                                                        </div>}
                                                                        <div className="">
                                                                            <button type="bytton"
                                                                                onClick={() => setShowAddLink(!showAddLink)}
                                                                                disabled={!settingsData?.discussion && !quiz?.quizSetting?.discussion}
                                                                                className="btn btn--primary btn--xs radius-5">
                                                                                <span className="ico mr-[10px]">
                                                                                    <svg width="20" height="21" viewBox="0 0 20 21"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                                        <path
                                                                                            d="M10.8456 15.5123L9.57106 16.7868C7.95346 18.4044 5.3308 18.4044 3.7132 16.7868V16.7868C2.0956 15.1692 2.0956 12.5466 3.7132 10.929L5.37479 9.26739C6.15619 8.48598 7.216 8.047 8.32107 8.047C9.42614 8.047 10.4859 8.48598 11.2674 9.26739"
                                                                                            stroke="#323232" strokeWidth="1.5"
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"></path>
                                                                                        <path
                                                                                            d="M9.15417 5.48773L10.4287 4.2132C12.0463 2.5956 14.669 2.5956 16.2866 4.2132V4.2132C17.9042 5.8308 17.9042 8.45346 16.2866 10.0711L14.625 11.7326C12.9978 13.3598 10.3596 13.3598 8.73242 11.7326"
                                                                                            stroke="#323232" strokeWidth="1.5"
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"></path>
                                                                                    </svg>
                                                                                </span>
                                                                                <span className="text--sm font-medium">{showAddLink ? 'Hide Add Link' : 'Add link'}</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <label className="quiz-answer-item">
                                                            <input
                                                                type="radio"
                                                                name="discussion"
                                                                defaultChecked={!quiz?.quizSetting?.discussion}
                                                                hidden
                                                                onChange={(e) => handelChange('discussion', null)}
                                                            />
                                                            <span className="quiz-answer-item__icon mr-[16px]"></span>
                                                            <div>
                                                                <p className="text--sm mb-[6px]">No discussion</p>
                                                                <p className="color-secondary text--xs">Just a classic
                                                                    quiz test without comments</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr className="hr" />
                                        </div>
                                        <div className="col-12">
                                            <div className="container container--lg">
                                                <div className="group gap--lg md:flex-nowrap">
                                                    <p className="text--md font-medium min-w-[140px] md:text-right">Options</p>
                                                    <div className="checkboxes-list">
                                                        {/* <CheckBoxGroup
                                                            wrapperClasses={'input--lg'}
                                                            defaultChecked={quiz?.quizSetting?.private}
                                                            onChange={(e) => handelChange('private', e.target.checked)}
                                                        >
                                                            <p className="check-text">
                                                                Private quiz
                                                            </p>
                                                        </CheckBoxGroup> */}
                                                        {/* <CheckBoxGroup
                                                            wrapperClasses={'input--lg'}
                                                            defaultChecked={quiz?.quizSetting?.anonymous_responses}
                                                            onChange={(e) => handelChange('anonymous_responses', e.target.checked)}
                                                        >
                                                            <p className="check-text">
                                                                Anonymous responses
                                                            </p>
                                                        </CheckBoxGroup> */}
                                                        <CheckBoxGroup
                                                            wrapperClasses={'input--lg'}
                                                            defaultChecked={quiz?.quizSetting?.one_attempt}
                                                            onChange={(e) => handelChange('one_attempt', e.target.checked)}
                                                        >
                                                            <p className="check-text">
                                                                Attendants can take quiz only 1 time
                                                            </p>
                                                        </CheckBoxGroup>
                                                        <CheckBoxGroup
                                                            wrapperClasses={'input--lg'}
                                                            defaultChecked={quiz?.quizSetting?.enableComments}
                                                            onChange={(e) => handelChange('enableComments', e.target.checked)}
                                                        >
                                                            <p className="check-text">
                                                                Enable Comments
                                                            </p>
                                                        </CheckBoxGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/quiz'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <div className="group items-center md:flex-nowrap gap--xs">
                                        <button type="button" onClick={() => saveHandler(false)} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save</span>
                                        </button>
                                        <button onClick={() => saveHandler(true)} className="btn btn--primary gap-[10px] btn--md radius-5">
                                            <span className="info text-[13px] font-[500]">Save and Create Another</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}