import { useEffect, useState } from "react";
import { CreateFormHeader } from "../../components/parts/CreateFormHeader";
import SortableList from "../../components/parts/SortableList";
import AddFlashcardModal from "../../components/Modals/AddFlashcardModal";

import { ReactComponent as PlusIcon } from "../../assets/img/ico-plus-2.svg"
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIsLoading, setActiveFlashCardId, setDragDropCards } from "../../store/flashcards/flashcardsSlice";
import { useDispatch } from "react-redux";
import Loader from "../../components/UI/Loader";
import SortVerticalPart from "../../components/parts/SortVerticalPart";
import PageLoader from "../../components/parts/PageLoader";
import { setHasEditChanges } from "../../store/auth/authSlice";
import { orderingFlashCardsFetch } from "../../store/flashcards/flashcardsThunks";

export const FlashcardsCreateFlashcards = () => {
    const { id } = useParams()
    const [flashCardId, flashCardSet] = useOutletContext();
    const dispach = useDispatch()
    const navigate = useNavigate()
    const isLoading = useSelector(getIsLoading)
    const [addFlashcardModal, setAddFlashcardModal] = useState(false);
    const [data, setData] = useState([]);

    const handleOnDragEnd = (result) => {
        setData(result)
    }
    const flashCardEditModalHandler = (id) => {
        dispach(setActiveFlashCardId(id))

        setAddFlashcardModal(true);
    }
    const handleAddFlashcardModal = () => {
        setAddFlashcardModal(true)
    }
    const conf = {
        title: 'Flashcards',
        btnTitle: 'Add Flashcard',
        btnAction: () => {
            handleAddFlashcardModal()
            dispach(setActiveFlashCardId(null))
        }
    }
    const continueHandler = async () => {
        const orderingData = data.map(item => ({
            id: item.id,
            order: item.order
        }))

        const res = await dispach(orderingFlashCardsFetch({
            id: flashCardId || id, body: {
                "ordering": orderingData
            }
        })).unwrap()
        if (res) {
            if (id) {
                navigate(`/flashcards-edit/${id}/settings`)
            } else {
                navigate('/flashcards-create/settings')
            }
        }
        dispach(setHasEditChanges(false))
    }
    useEffect(() => {
        if (flashCardSet?.flashcards?.length > 0) {
            setData(flashCardSet?.flashcards)
        }

    }, [flashCardSet])
    if (isLoading) return (
        <div className="wrapper header-fixed sidebar-left-fixed flex items-center justify-center flex-auto">
            <Loader />
        </div>
    )
    return (
        <>
            <CreateFormHeader config={conf} />

            <div className="wrapper header-fixed sidebar-left-fixed">
                <main className="content">
                    <div className="quiz pt-0">
                        <div className="quiz__content overflow-hidden">
                            <div className="quiz__body quiz__body--start">
                                <div className="container container--lg ">
                                    <div className="row information-gutters">
                                        <div className="col-12">
                                            <div className="group gap--lg md:flex-nowrap">
                                                <p className="text--md font-medium min-w-[140px] md:text-right">Flashcards({flashCardSet?.flashcards?.length || 0})</p>
                                                <div className="w-full">
                                                    {data.length > 0 &&
                                                        // <SortableList 
                                                        //     onDragEnd={handleOnDragEnd} 
                                                        //     items={flashCardSet?.flashcards} 
                                                        //     editHandler={flashCardEditModalHandler} 
                                                        //     setDragDrop={setDragDropCards} 
                                                        // />
                                                        <SortVerticalPart
                                                            items={data}
                                                            setItems={(e) => {
                                                                dispach(setHasEditChanges(true))
                                                                setData(e)
                                                            }}
                                                            editHandler={flashCardEditModalHandler}
                                                        />
                                                    }
                                                    <button
                                                        onClick={() => {
                                                            handleAddFlashcardModal()
                                                            dispach(setActiveFlashCardId(null))
                                                        }}
                                                        className="btn btn--primary btn--xs radius-5 gap-[10px]">
                                                        <span className="ico">
                                                            <PlusIcon />
                                                        </span>
                                                        <span className="info text--sm">Add Flashcard</span>
                                                    </button>
                                                    <p className="text--md color-secondary mt-[16px]">Add as more
                                                        flashcards as you can</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="quiz__footer bg-white">
                                <div className="container">
                                    <Link to={'/quiz/flashcards'} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500]">Cancel</span>
                                    </Link>
                                    <button type="button" onClick={continueHandler} className="btn btn--primary gap-[10px] btn--md radius-5">
                                        <span className="info text-[13px] font-[500] sm:flex hidden">Continue</span>
                                        <span className="ico">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.4163 10.9998H4.58301" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 15.5833L17.4163 11" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M12.833 6.4165L17.4163 10.9998" stroke="currentColor" strokeWidth="1.5"
                                                    strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <AddFlashcardModal open={addFlashcardModal} handelClose={() => setAddFlashcardModal(false)} />
        </>
    )
}