import {NavLink, Outlet, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import CreateSidebar from "../components/parts/createPagesParts/CreateSidebar";


import {ReactComponent as GeneralIcon} from '../assets/img/sidebar-general.svg'
import {ReactComponent as SettingIcon} from '../assets/img/sidebar-settings.svg'
import {ReactComponent as ImageVideoIcon} from '../assets/img/sidebar-image-videos.svg'
import {ReactComponent as HintsIcon} from '../assets/img/sidebar-hints.svg'
import {ReactComponent as QuizeIcon} from '../assets/img/sidebar-quiz.svg'
import { getQuiz, getQuizId, setQuizId } from "../store/quizzes/quizzesSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getQuizGeneralByQuizIdFeth } from "../store/quizzes/quizzesThunks";

const QuizEditLayout = () => {
    const {id} = useParams()
    const dispach = useDispatch();
    const quizId = useSelector(getQuizId);
    const quiz = useSelector(getQuiz);
   

    useEffect(() => {
        if (id) {
            dispach(getQuizGeneralByQuizIdFeth(id)).unwrap();
            dispach(setQuizId(id)) 
        }
    }, [id])
    const sidebalLiks = [
        {
            icon: <GeneralIcon />,
            to: 'general',
            label: 'General Information'
        },
        {
            icon: <ImageVideoIcon />,
            to: 'image',
            label: 'Images & Videos',
        },
        {
            icon: <HintsIcon />,
            to: 'hints',
            label: 'Hints',
        },
        {
            icon: <QuizeIcon />,
            to: 'quiz',
            label: 'Quiz',
        },
        {
            icon: <SettingIcon />
            ,
            to: 'settings',
            label: 'Settings',
        },
    ]

    return (
        <>
            <div className={'quiz-page'}>
                <CreateSidebar
                    links={sidebalLiks}
                    title={'Edit Quiz'}
                    backRoute={`/quiz-page/${id}/presentation`}
                />
                <Outlet context={[quizId, quiz]} />
                <div className="custom-overlay toggler-menu" style={{ zIndex: 51 }}></div>
            </div>
        </>
    )
}

export default QuizEditLayout
