import React from 'react'
import { Link } from 'react-router-dom'

export const NotFound = () => {
    return (
        <main className='coontent'>
            <section className="not-found">
                <div className="container">
                    <div className="not-found-body">
                        <img width={600} src={require('../../assets/img/404-page-not-found.svg').default} alt="404" />
                        <h3>Oops! Page not found</h3>
                        <p>The page you are looking for does not exist. Go back to the main page or search.</p>
                        <Link to={'/'} className='btn btn--lg btn--primary' >Go to Home Page</Link>
                    </div>
                </div>
            </section>
        </main>
    )
}
