import React from 'react'
import { ReactComponent as PlaceholderIcon } from "../../../assets/img/user-placeholder.svg"
import { getImageSrc } from '../../../helpers/utils'
const UserAvatar = ({user}) => {
    return (
        <div className="user-chat-avatar">
            {
                user?.photo ?
                    <img src={getImageSrc(user?.photo)} />
                    : <PlaceholderIcon />
            }
            <span className={`status-indicator ${user?.online ? '' : '--offline'}`}></span>
        </div>
    )
}

export default UserAvatar
