import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InputGroup from '../../components/UI/formElements/InputGroup'
import PageLoader from '../../components/parts/PageLoader'
import { getFormData, getImageSrc } from '../../helpers/utils'
import { getMe, updateMe } from '../../store/auth/authSlice'
import { addCotactEmailFetch, deleteCotactEmailFetch, updateCotactEmailFetch, updateProfileFetch } from '../../store/auth/authThunks'
import { useValidation } from '../../hooks/useValidation'
import TextareaGroup from '../../components/UI/formElements/TextareaGroup'
import {  deleteUserPhotoFeth, editProfileFeth } from '../../store/profile/profileThunk'
import Expertise from './parts/Expertise'
import PracticeExperience from './parts/PracticeExperience'
import Certifications from './parts/Certifications'

const MyDetails = () => {
    const userData = useSelector(getMe);
    const isProfileLoading = useSelector(state => state.auth.isLoading);
    const { profile, isLoading} = useSelector(state => state.profile);
    const dispatch = useDispatch()
    const [updateUserData, setUpdateUserData] = useState({});
    // const [contactEmails, setContactEmails] = useState([]);

    const [profileImage, setProfileImage] = useState(null);
    // const [errosEmails, setEmailsErors] = useState({})
    const [socials, setSocilas] = useState({})
    const [profilePart, setProfilePart] = useState({})
    
    const validators = {
        // phoneNumber: value => isValidPhoneNumber(String(value)) ? true : 'Invalid phone number format',
        email: value => /\S+@\S+\.\S+/.test(value) ? true : 'Invalid email format',
        firstName: value => /^[^0-9]*$/.test(value) ? true : 'First name must not contain numeric characters',
        lastName: value => /^[^0-9]*$/.test(value) ? true : 'Last name must not contain numeric characters',
        photo: file => {
            if (!file) {
                return true;
            }
            if (!file?.type?.startsWith('image/') && file instanceof File) {
                return 'Photo must be an image file (jpg, jpeg, png, gif)';
            }
            return true;
        },
    };

    const messages = {
        // phoneNumber: 'Phone number is required',
        email: 'Email is required',
        firstName: 'First name is required',
        lastName: 'Last name is required',
    };
    const { errors, errorMessages, isErrorShown, setIsErrorShown } = useValidation(updateUserData, {
        validators,
        messages
    });
    useEffect(() => {
        if (profile) {
            // setContactEmails(userData?.contactEmails)
            setProfileImage(profile?.user?.photo)
            // setUpdateUserData(profile.user)
        }
    }, [profile])
    
    const socilastHandler = (name, value) => {
        setSocilas(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const profilePartHandler = (name, value) => {
        setProfilePart(prev => ({
            ...prev,
            [name]: value
        }))
    }
   
    // const saveEmailHandler = async (email, index) => {

    //     if (validators.email(email.email) !== true) {
    //         setEmailsErors(prev => ({
    //             ...prev,
    //             [`email${index}`]: validators.email(email.email)
    //         }))

    //         return
    //     }

    //     setIsloading(true)
    //     if (email.id) {
    //         const oldEmail = userData?.contactEmails[index]
    //         if (oldEmail?.email != email.email) {
    //             const res = await dispatch(updateCotactEmailFetch(email)).unwrap()
    //         }
    //     } else {

    //         const res = await dispatch(addCotactEmailFetch(email)).unwrap()

    //     }
    //     setIsloading(false)
    // }

    // const deleteEmailHandler = async (email, index) => {
    //     if (email.id) {
    //         setIsloading(true)
    //         const res = await dispatch(deleteCotactEmailFetch(email.id)).unwrap()
    //         setIsloading(false)
    //     } else {
    //         setContactEmails(contactEmails.filter(item => item.email !== email.email))
    //     }
    // }

    // const contactEmailHandler = (e, index) => {
    //     setEmailsErors(prev => ({
    //         ...prev,
    //         [`email${index}`]: false
    //     }))
    //     const copyContacts = [...contactEmails]
    //     copyContacts[index] = {
    //         ...copyContacts[index],
    //         email: e.target.value
    //     }
    //     setContactEmails(copyContacts)
    // }
    
    const removeImageHandler = async () => {
        const res = await dispatch(deleteUserPhotoFeth()).unwrap()
        if(res) {
            setProfileImage(null)
            dispatch(updateMe({photo: null}))
        }
      
        
    }    
    const updateHandler = ({ target }) => {
        setUpdateUserData(prev => ({
            ...prev,
            [target.name]: target.name === 'photo' ? target.files[0] : target.value
        }))
        if (target.name === 'photo') {
            setProfileImage(target.files[0])
        }
    }
    
    const saveChanges = async () => {
        if (errors) {
           
            setIsErrorShown(true)
            return
        }

        
        let newUserData = { ...updateUserData }
        delete newUserData['photo']
        let data = {}
        if (Object.keys(newUserData).length > 0) {
            data['user'] = newUserData
        }
        if (Object.keys(profilePart).length > 0) {
            data['profile'] = profilePart
        }

        if (Object.keys(socials).length > 0) {
            data['socials'] = socials
        }
        
        const formData = new FormData();
        if (updateUserData.hasOwnProperty('photo')) {
            formData.append('photo', updateUserData.photo)
        }
        if (Object.keys(data).length > 0) {
            formData.append('data', JSON.stringify(data))
        }
        const response = await dispatch(editProfileFeth(formData)).unwrap();
        if (response) {
            dispatch(updateMe(response.user))
        }
        
        
    }
   
    if (isProfileLoading || isLoading) return <PageLoader />

    return (
        <div className="tabs-item active-tab" id="my_details">
            <div className="col-group col-group--border gap--xl">
                <div className="flex">
                    <div className="row no-border items-center flex-auto">
                        <div className="col-md-6">
                            <div className="upload-avatar">
                                <div className="upload-avatar__preview">
                                    <span className="ico ico--xxxl image-wrapper rounded-full">
                                        {
                                            profileImage ?
                                                <img src={getImageSrc(profileImage)} alt="" />
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 448 512">
                                                    <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" fill="currentColor" />
                                                </svg>
                                        }

                                    </span>
                                </div>
                                <div className="col-group">
                                    <p className="font-semibold text--lg">Profile picture</p>
                                    <p className="text--md">PNG, JPEG under 15 MB </p>
                                    {isErrorShown && errors?.photo ?
                                        <p className='color-danger text--xs mt-1'>{errorMessages?.photo}</p>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row-group w-full justify-end gap--xl">
                                <label className="upload-avatar__upload">
                                    <input type="file" name={'photo'} hidden={true} onChange={updateHandler} />
                                    <span className="btn btn--shadow btn--lg radius-5">
                                        <span className="text--md">Upload new picture</span>
                                    </span>
                                </label>
                                <button type='button' onClick={removeImageHandler} className="btn btn--transparent btn--lg radius-5">
                                    <span className="info text--md">Delete</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-group">
                    <h4 className="mb-[24px] text--lg">Full name</h4>
                    <div className="flex w-full">
                        <div className="row flex-auto w-full g-24">
                            <div className="col-md-6">
                                <InputGroup
                                    wrapperClasses='input--lg'
                                    name={'firstName'}
                                    label={'Last Name'}
                                    inputClasses='input--outline'
                                    defaultValue={userData?.firstName}
                                    error={isErrorShown && errors?.firstName}
                                    errorMessage={errorMessages?.firstName}
                                    onChange={updateHandler}
                                />
                            </div>
                            <div className="col-md-6">
                                <InputGroup
                                    wrapperClasses='input--lg'
                                    name={'lastName'}
                                    label={'Last Name'}
                                    inputClasses='input--outline'
                                    defaultValue={userData?.lastName}
                                    error={isErrorShown && errors?.lastName}
                                    errorMessage={errorMessages?.lastName}
                                    onChange={updateHandler}
                                />
                            </div>
                            <div className="col-12">
                                <TextareaGroup
                                    label={'About me'}
                                    wrapperClasses='input--lg'
                                    textareaClasses={'input--outline py-[14px]'}
                                    defaultValue={profile?.profile?.about}
                                    placeholder={'Enter About...'}
                                    rows='5'
                                    onChange={({ target }) => { profilePartHandler('about', target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-group">
                    <h4 className="mb-[24px] text--lg">Contact Email</h4>
                    <div className="flex w-full">
                        <div className="row flex-auto w-full g-24 items-end">
                            <div className="col-md-6">
                                <div className="row g-24">
                                    <InputGroup
                                        wrapperClasses='input--lg'
                                        label={'Email'}
                                        name={'email'}
                                        inputClasses='input--outline'
                                        defaultValue={userData?.email}
                                        onChange={updateHandler}
                                        error={isErrorShown && errors?.email}
                                        errorMessage={errorMessages?.email}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-4 offset-md-2 ">
                                <div className="w-full flex justify-end">
                                    <button type='button' disabled={isLoading} onClick={() => {
                                        setContactEmails(prev => ([
                                            ...prev,
                                            {
                                                email: ''
                                            }
                                        ]))
                                    }} className="btn btn--shadow gap--md btn--lg radius-5 mt-auto">
                                        <span className="ico">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 8V16" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16 12H8" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                        <span className="info text--md font-medium">Add another email </span>
                                    </button>
                                </div>
                            </div> */}
                        </div>

                    </div>
                    {/* {(contactEmails?.length) ?
                        <div className="row mt-[16px]">
                            <div className="col-md-6">
                                <div className="row g-16">
                                    {contactEmails.map((item, index) => (
                                        <div className="col-12" key={index}>
                                            <div className={`actions-group actions-group--mobile`}>
                                                <InputGroup
                                                    wrapperClasses='input--md flex-auto'
                                                    inputClasses='input--outline'
                                                    name={`contact${index}`}
                                                    defaultValue={item.email}
                                                    onChange={(e) => { contactEmailHandler(e, index) }}
                                                    onBlur={() => { saveEmailHandler(item, index) }}
                                                />

                                                <button type='button' disabled={isLoading} onClick={() => { deleteEmailHandler(item, index) }} className="btn btn--square btn--md">
                                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.1036 16.25H5.89361C5.10836 16.25 4.45586 15.644 4.39736 14.8603L3.72461 5.75H14.2501L13.5999 14.8565C13.5436 15.6418 12.8904 16.25 12.1036 16.25V16.25Z" stroke="#F02B10" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M9 8.75V13.25" stroke="#F02B10" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M3 5.75H15" stroke="#F02B10" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M12.75 5.75L11.9903 3.7235C11.7705 3.13775 11.211 2.75 10.5855 2.75H7.4145C6.789 2.75 6.2295 3.13775 6.00975 3.7235L5.25 5.75" stroke="#F02B10" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M11.5724 8.75L11.2499 13.25" stroke="#F02B10" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M6.42762 8.75L6.75012 13.25" stroke="#F02B10" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            {errosEmails?.[`email${index}`] != false ?
                                                <p className='color-danger text--xs mt-1'>{errosEmails?.[`email${index}`]}</p>
                                                :
                                                null
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        : null
                    } */}
                </div>
                <Expertise/>
                <PracticeExperience/>
                <Certifications/>
                <div className="col-group">
                    <h4 className="mb-[24px] text--lg">Social media</h4>
                    <div className="row g-24">
                        <div className="col-lg-6">
                            <InputGroup
                                wrapperClasses='input--lg'
                                label={'Twitter'}
                                name={'twitter'}
                                defaultValue={profile?.socials?.twitter}
                                inputClasses='input--outline'
                                placeholder={'Twitter link...'}
                                onChange={({ target }) => { socilastHandler('twitter', target.value) }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <InputGroup
                                wrapperClasses='input--lg'
                                label={'LinkedIn'}
                                name={'linkedIn'}
                                defaultValue={profile?.socials?.linkedIn}
                                inputClasses='input--outline'
                                placeholder={'Twitter linkedIn...'}
                                onChange={({ target }) => { socilastHandler('linkedIn', target.value) }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <InputGroup
                                wrapperClasses='input--lg'
                                label={'Facebook'}
                                name={'facebook'}
                                defaultValue={profile?.socials?.facebook}
                                inputClasses='input--outline'
                                placeholder={'Twitter facebook...'}
                                onChange={({ target }) => { socilastHandler('facebook', target.value) }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <InputGroup
                                wrapperClasses='input--lg'
                                label={'Instagram'}
                                name={'instagram'}
                                defaultValue={profile?.socials?.instagram}
                                inputClasses='input--outline'
                                placeholder={'Twitter instagram...'}
                                onChange={({ target }) => { socilastHandler('instagram', target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row-group flex-wrap justify-between gap--sm">
                    <button type='button' onClick={saveChanges} disabled={isLoading} className="btn w-full md:w-auto md:min-w-[250px] btn--primary btn--lg radius-5">
                        <span className="text--md font-medium">Save Changes</span>
                    </button>
                    <a href="#" className="btn w-full md:w-auto md:min-w-[250px] btn--secondary-2 btn--lg radius-5">
                        <span className="text--md font-medium">Cancel</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MyDetails
