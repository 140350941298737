import React, { useEffect } from 'react';

import { ReactComponent as ChevronNext } from "../../assets/img/chevron-next.svg";
import CardArticle from '../../components/parts/Cards/CardArticle';
import { data } from "../../data";
import Accordeon from '../../components/UI/Accordeon';
import { useSelector } from 'react-redux';
import { getMe } from '../../store/auth/authSlice';
import SubscriptionBigPart from '../../components/parts/SubscriptionBigPart';
import { useDispatch } from 'react-redux';
import { getAnatomyPageFetch } from '../../store/pages/pagesThunks';
import PageLoader from '../../components/parts/PageLoader';
import { Html } from 'react-konva-utils';
import CardCase from '../../components/parts/Cards/CardCase';
import { Link } from 'react-router-dom';
import SidebarRelatedImages from '../../components/parts/sidebarPosts/SidebarRelatedImages';
import SidebarRelatedVideos from '../../components/parts/sidebarPosts/SidebarRelatedVideos';
import SidebarRelatedDocuments from '../../components/parts/sidebarPosts/SidebarRelatedDocuments';


const Anatomy = () => {
    const dispacth = useDispatch()
    const { pageData, isLoading } = useSelector(state => state.pages)
    const { authorized, showLoginModal } = useSelector((state) => state.auth)
    const explorationTips = [
        "How to use Medwise platform?", "How properly set profile?",
        "What badges means?", "Popular forums for new users"
    ];
    const recommendedTopics = ["Digital Health", "Genomics",
        "Myth Busting", "Mental Health", "Researches",
        "Surgery", "Women's Health", "Pediatrics", "Cardiovascular"];
    const faqData = [
        {
            title: 'How to use Medwise platform?',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic adipisci vitae porro quisquam vero fuga unde ipsa, debitis dolorum nam!'
        },
        {
            title: 'How properly set profile?',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic adipisci vitae porro quisquam vero fuga unde ipsa, debitis dolorum nam!'
        },
        {
            title: 'What badges means?',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic adipisci vitae porro quisquam vero fuga unde ipsa, debitis dolorum nam!'
        },
        {
            title: 'Is there a free trial available?',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic adipisci vitae porro quisquam vero fuga unde ipsa, debitis dolorum nam!'
        },
        {
            title: 'Can I change my plan later?',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic adipisci vitae porro quisquam vero fuga unde ipsa, debitis dolorum nam!'
        },
        {
            title: 'What is your cancellation policy?',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic adipisci vitae porro quisquam vero fuga unde ipsa, debitis dolorum nam!'
        },
        {
            title: 'What is your cancellation policy?',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic adipisci vitae porro quisquam vero fuga unde ipsa, debitis dolorum nam!'
        },
        {
            title: 'How do I change my account email?',
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic adipisci vitae porro quisquam vero fuga unde ipsa, debitis dolorum nam!'
        },
    ]

    useEffect(() => {
        dispacth(getAnatomyPageFetch())
    }, [dispacth])
    console.log(pageData)
    if (isLoading) return <PageLoader />

    return (
        <main className="content">
            <section className="page-section">
                <div className="container">

                    <div className="page-section--row">
                        <div className="page-content">
                            <div className="section-cards">
                                <div className="row gutters-cards">
                                    <div className="col-12">
                                        <div className="banner after-005" style={{ background: "var(--bunner-bg-2)" }}>
                                            <div className="banner-row">
                                                <div className="banner-content">
                                                    <div className="heading-block">
                                                        <h3 className="heading text-[#fff]">
                                                            Embark on a Learning Journey
                                                        </h3>
                                                        <p className="heading-desk text-[#fff]">
                                                            Discover a world of knowledge with our extensive range of courses, articles and quizzes
                                                        </p>
                                                    </div>
                                                    <div className="group group--sm">
                                                        <a href="" className="btn btn--gradient-primary 
                                                btn--md rounded-full">
                                                            <span className="text-[14px] font-[500]">
                                                                Let’s go
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="banner-img banner-img--shadow">
                                                    <img className="w-full" src={require("../../assets/img/unsplash_nazL_0vWZ0Y.png")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text--lg editor-text editor-container" dangerouslySetInnerHTML={{ __html: pageData?.content }}/>
                                {/* <a href="#" className="btn max-w-max color-danger gap-[8px]">
                                    <span className="text-[13px] font-[500]">View more</span>
                                    <span className="ico">
                                        <ChevronNext />
                                    </span>
                                </a> */}
                            </div>
                            <div className="section-cards">
                                <div className="posts-list">
                                    <div className="heading-row">
                                        <div className="heading-block">
                                            <h2 className="heading capitalize">anatomy articles</h2>
                                        </div>
                                    </div>
                                    <div className="row gutters-cards">
                                        {
                                            pageData?.articles && pageData?.articles?.length ?
                                                pageData?.articles.map(card => (
                                                    <div key={card.id} className="col-lg-4 col-md-6 col-12">
                                                        <CardArticle data={card} authorized={authorized} />
                                                    </div>
                                                ))
                                                :
                                                null
                                        }
                                    </div>
                                    <Link to={'/articles'} className="btn max-w-max color-danger gap-[8px]">
                                        <span className="text-[13px] font-[500]">View more articles</span>
                                        <span className="ico">
                                            <ChevronNext />
                                        </span>
                                    </Link>
                                </div>
                                {/* <div className="posts-list">
                                    <div className="heading-row">
                                        <div className="heading-block">
                                            <h2 className="heading capitalize">anatomy cases</h2>
                                        </div>
                                    </div>
                                    <div className="row gutters-cards">

                                        {
                                            pageData?.cases && pageData?.cases?.length ?
                                                pageData?.cases.map(card => (
                                                    <div key={card.id} className="col-lg-4 col-md-6 col-12">
                                                        <CardCase data={card} authorized={authorized} />
                                                    </div>
                                                ))
                                                :
                                                null
                                        }
                                    </div>
                                    <Link to={'/cases'} className="btn max-w-max color-danger gap-[8px]">
                                        <span className="text-[13px] font-[500]">View more courses</span>
                                        <span className="ico">
                                            <ChevronNext />
                                        </span>
                                    </Link>
                                </div> */}
                                {/* <div className="posts-list">
                                    <div className="heading-row">
                                        <div className="heading-block">
                                            <h2 className="heading capitalize">anatomy Quizzes</h2>
                                        </div>
                                    </div>
                                    <div className="row gutters-cards">
                                        {
                                            data && data.length ?
                                                data.map(card => (
                                                    <div key={card.id} className="col-lg-4 col-md-6 col-12">
                                                        <CardArticle data={card} />
                                                    </div>
                                                ))
                                                :
                                                null
                                        }
                                    </div>
                                    <a href="#" className="btn max-w-max color-danger gap-[8px]">
                                        <span className="text-[13px] font-[500]">View more courses</span>
                                        <span className="ico">
                                            <ChevronNext />
                                        </span>
                                    </a>
                                </div> */}
                                <SubscriptionBigPart />
                                {/* <div className="posts-list">
                                    <div className="heading-row">
                                        <div className="heading-block">
                                            <h2 className="heading uppercase">faq</h2>
                                        </div>
                                    </div>
                                    <div className="accordeon-list">
                                        
                                        {faqData.map((item, index) => (<Accordeon
                                            iconStyle = 'pluseCircle'
                                            className={'border-top-none'} 
                                            title={item.title}
                                            key={index}
                                        >
                                            <div className="pb-[20px]">
                                                <p>{item.description}</p>
                                            </div>
                                        </Accordeon>)) }
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="page-sidebar" style={{ position: 'static' }}>
                            <aside className="sidebar post-sidebar">
                                <div className="sidebar-body">
                                    {/* <div className="post-widget">
                                        <div className="post-widget--header">
                                            <div className="heading-post-row">
                                                <span className="ico ico--xs">
                                                    <img src={require("../../assets/img/ico-recent-article.svg").default} alt='articles' />
                                                </span>
                                                <p className="heading-title-post text-def-2-c">
                                                    Anatomy Articles
                                                </p>
                                            </div>
                                        </div>
                                        <div className="post-widget--body flex flex-col">
                                            <ul className="mini-posts-list flex flex-col">
                                                <li>
                                                    <a href="#" className="mini-post">
                                                        <div className="wrapp-image mini-post-img">
                                                            <img src={require("../../assets/img/post-bg-1.png")} alt='post' />
                                                        </div>
                                                        <div className="mini-post-content">
                                                            <p className="mini-post--title">
                                                                Using deep learning with CT
                                                                helps predict hip fractures
                                                            </p>
                                                            <p className="mini-post--desk">17 Oct, 2024</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            <a href="#" className="btn max-w-max">
                                                <span className="color-danger text-[13px] font-[500]">
                                                    See more articles
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="post-widget">
                                        <div className="post-widget--header">
                                            <div className="heading-post-row">
                                                <span className="ico ico--xs">
                                                    <img src={require("../../assets/img/ico-recomend-topic.svg").default} alt='topics' />
                                                </span>
                                                <p className="heading-title-post text-def-2-c">
                                                    Topic related to anatomy
                                                </p>
                                            </div>
                                        </div>
                                        <div className="post-widget--body flex flex-col">
                                            <ul className="keywords-list">
                                                {
                                                    recommendedTopics && recommendedTopics.length ?
                                                        recommendedTopics.map((topic, index) => (
                                                            <li key={index}>
                                                                <a href="#" className="btn btn--light btn--sm-2 btn-keyword">
                                                                    <span>{topic}</span>
                                                                </a>
                                                            </li>
                                                        ))
                                                        :
                                                        null
                                                }
                                            </ul>
                                            <a href="#" className="btn max-w-max">
                                                <span className="color-danger text-[13px] font-[500]">
                                                    See more topics
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="post-widget">
                                        <div className="post-widget--header">
                                            <div className="heading-post-row">
                                                <span className="ico ico--xs">
                                                    <img src={require("../../assets/img/ico-recent-cases.svg").default} alt='cases' />
                                                </span>
                                                <p className="heading-title-post text-def-2-c">
                                                    Anatomy Cases
                                                </p>
                                            </div>
                                        </div>
                                        <div className="post-widget--body flex flex-col">
                                            <ul className="mini-posts-list flex flex-col">
                                                <li>
                                                    <a href="#" className="mini-post">
                                                        <div className="wrapp-image mini-post-img">
                                                            <img src={require("../../assets/img/blueray-img-1.png")} alt='post' />
                                                        </div>
                                                        <div className="mini-post-content">
                                                            <p className="mini-post--title">
                                                                Bilateral osteochondrosis of
                                                                medial cuneiform and navicular bone
                                                            </p>
                                                            <p className="mini-post--desk">17 Oct, 2024</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            <a href="#" className="btn max-w-max">
                                                <span className="color-danger text-[13px] font-[500]">
                                                    See more cases
                                                </span>
                                            </a>
                                        </div>
                                    </div> */}
                                    {pageData?.images && pageData?.images?.length > 0 ?
                                        <SidebarRelatedImages
                                            images={pageData?.images}
                                            title={'Anatomy Images'}
                                        />
                                        : null}

                                    {pageData?.videos && pageData?.videos?.length > 0 ? <SidebarRelatedVideos
                                        videos={pageData?.videos}
                                        title={'Related Videos'}
                                    />
                                        : null}

                                    {pageData?.documents
                                        && pageData?.documents?.length > 0 ? <SidebarRelatedDocuments
                                        documents={pageData?.documents
                                        }
                                        title={'Documents'}
                                    /> : null}
                                    <div className="post-widget">
                                        <div className="post-widget--body flex flex-col">
                                            <div className="card card-custom card-md">
                                                <div className="card-header">
                                                    <h5 className="heading">Exploration Tips</h5>
                                                </div>
                                                <div className="card-body">
                                                    <ul className="flex flex-col gap-[10px]">
                                                        {
                                                            explorationTips && explorationTips?.length ?
                                                                explorationTips.map((tip, index) => (

                                                                    <li key={index}>
                                                                        <Link to={'/faq'} className="btn btn--sm-2 btn-keyword before-point text-left justify-start btn--def">
                                                                            <span>{tip}</span>
                                                                        </Link>
                                                                    </li>
                                                                ))
                                                                :
                                                                null
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="post-widget">
                                        <div className="post-widget--header">
                                            <div className="heading-post-row">
                                                <span className="ico ico--xs">
                                                    <img src={require("../../assets/img/ico-popular-forums.svg").default} alt='forums' />
                                                </span>
                                                <p className="heading-title-post text-def-2-c">
                                                    Popular Forums
                                                </p>
                                            </div>
                                        </div>
                                        <div className="post-widget--body flex flex-col">
                                            <ul className="mini-posts-list flex flex-col">
                                                <li>
                                                    <a href="#" className="mini-post">
                                                        <div className="wrapp-image mini-post-img">
                                                            <img src={require("../../assets/img/post-img-1.png")} alt='post' />
                                                        </div>
                                                        <div className="mini-post-content">
                                                            <p className="mini-post--title">
                                                                Forum related to anatomy
                                                            </p>
                                                            <div className="flex items-center flex-wrap gap-[6px]">
                                                                <ul className="users-list">
                                                                    <li>
                                                                        <div className="ico user-img user-img-border">
                                                                            <img src={require("../../assets/img/user-ico-1.png")} alt='user' />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <p className="mini-post--desk">123 members</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            <a href="#" className="btn max-w-max">
                                                <span className="color-danger text-[13px] font-[500]">
                                                    See more forums
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="post-widget">
                                        <div className="post-widget--header">
                                            <div className="heading-post-row">
                                                <span className="ico ico--xs">
                                                    <img src={require("../../assets/img/ico-cources.svg").default} alt='post' />
                                                </span>
                                                <p className="heading-title-post text-def-2-c">
                                                    Anatomy Courses
                                                </p>
                                            </div>
                                        </div>
                                        <div className="post-widget--body flex flex-col">
                                            <ul className="mini-posts-list flex flex-col">
                                                <li>
                                                    <a href="#" className="mini-post">
                                                        <div className="wrapp-image mini-post-img">
                                                            <img src={require("../../assets/img/post-img-2.png")} alt='post' />
                                                        </div>
                                                        <div className="mini-post-content">
                                                            <p className="mini-post--desk">Video Course</p>
                                                            <p className="mini-post--title">
                                                                Abdominal Emergency Radiology Course
                                                                Radiology Course
                                                            </p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            <a href="#" className="btn max-w-max">
                                                <span className="color-danger text-[13px] font-[500]">
                                                    See more courses
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="post-widget">
                                        <div className="post-widget--header">
                                            <div className="heading-post-row">
                                                <span className="ico ico--xs">
                                                    <img src={require("../../assets/img/ico-quiz.svg").default} alt='post' />
                                                </span>
                                                <p className="heading-title-post text-def-2-c">
                                                    Anatomy Quizzes
                                                </p>
                                            </div>
                                        </div>
                                        <div className="post-widget--body flex flex-col">
                                            <ul className="mini-posts-list flex flex-col">

                                                <li>
                                                    <a href="#" className="mini-post">
                                                        <div className="wrapp-image mini-post-img">
                                                            <img src={require("../../assets/img/blueray-img-1.png")} alt='post' />
                                                        </div>
                                                        <div className="mini-post-content">
                                                            <p className="mini-post--title">
                                                                Bilateral osteochondrosis of
                                                                medial cuneiform and navicular bone
                                                            </p>
                                                            <p className="mini-post--desk">17 Oct, 2024</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            <a href="#" className="btn max-w-max">
                                                <span className="color-danger text-[13px] font-[500]">
                                                    See more quizzes
                                                </span>
                                            </a>
                                        </div>
                                    </div> */}

                                </div>
                            </aside>
                        </div>
                    </div>

                </div>
            </section>
        </main >
    )
}

export default Anatomy