import React, { useEffect, useRef, useState } from 'react'
import ThemSwith from '../components/parts/ThemSwith'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import CreateSidebar from '../components/parts/createPagesParts/CreateSidebar'

import {ReactComponent as CommentsIcon} from '../assets/img/sedebar-comments.svg'
import {ReactComponent as ImageVideoIcon} from '../assets/img/sidebar-image-videos.svg'
import {ReactComponent as GeneralIcon} from '../assets/img/sidebar-general.svg'
import {ReactComponent as SettingIcon} from '../assets/img/sidebar-settings.svg'
import { useSelector } from 'react-redux'
import { getCaseByIdFeth } from '../store/cases/casesThunk'
import { useDispatch } from 'react-redux'

const CaseEditLayout = () => {
    const dispach = useDispatch();
    const {id} = useParams()
    const {caseOne} = useSelector(state => state.cases);
    
    useEffect(() => {
        if(id) {
          dispach(getCaseByIdFeth(id)).unwrap();
        }
    }, [id])
    const sidebalLiks = [
        {
            icon: <GeneralIcon/>,
            to: ``, 
            label: 'General Information'
        },
        {
            icon: <ImageVideoIcon/>,
            to: 'images-videos',
            label: 'Images & Videos',
        },
        {
            icon:  <CommentsIcon/>,
            to: 'comments',
            label: 'Comments',
        },
        {
            icon: <SettingIcon/>,
            to: 'settings',
            label: 'Settings',
        },
    ]
    return (
        <>
            <div className={'quiz-page'}>
                <CreateSidebar links={sidebalLiks} title={'Create Case'}  backRoute={`/cases/${id}`}/>
                <div className="custom-overlay toggler-menu" style={{ zIndex: 51 }}></div>

                <Outlet context={[caseOne, id]} />

            </div>
        </>
    )
}

export default CaseEditLayout
