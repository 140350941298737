import React from 'react'
import Header from '../components/parts/Header'
import Footer from '../components/parts/Footer'
import { Outlet } from 'react-router-dom'

const MainLayout = ({hideFooter = false}) => {
    return (
        <div className='wrapper header-fixed'>
            <Header />
            <Outlet />
            {!hideFooter && <Footer />}
        </div>
    )
}

export default MainLayout