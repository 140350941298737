import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getSubscriptionPlansFeth, subscribeByPlanIdFeth } from '../../store/subscriptionPlan/subscriptionPlansThunk';
import { useSelector } from 'react-redux';
import { setCurrentPlan } from '../../store/subscriptionPlan/subscriptionPlansSlice';
import PageLoader from '../../components/parts/PageLoader';
import { getMe } from '../../store/auth/authSlice';
import SubscriptionPlans from '../../components/parts/SubscriptionPlans';

const Pricing = () => {
    const dispatch = useDispatch()
    const { subscriptionPlans, isLoading } = useSelector(state => state.subscriptionPlan)
    const [pricings, setPricings] = useState([])
    const [pricePeriod, setPricePeriod] = useState(false);
    const [intervalPrice, setIntervalPrice] = useState('month');
    const swicthPrice = ({ target }) => {
        setPricePeriod(prev => !prev)
        setIntervalPrice(target.checked ? 'year' : 'month')

    }
    useEffect(() => {
        setPricings(subscriptionPlans.filter(item => item.interval === intervalPrice))
    }, [subscriptionPlans, intervalPrice])
    useEffect(() => {
        dispatch(getSubscriptionPlansFeth())
    }, [])
    if (isLoading) return <PageLoader />
    return (
        <main className="content">
            <section className="page-section--md">
                <div className="container container-sm">
                    <div className="column-group column-group--lg">
                        <div className="column-group column-group--md items-center text-center">
                            <div className="column-group column-group--sm">
                                <p className="desk-txt">Choose your plan with us!</p>
                                <h1 className="heading">Pricing Plan</h1>
                            </div>
                            <label className="switch-col">
                                <p className="custom-txt-3 font-[500]">Monthly</p>
                                <label className="switch btn max-w-max switch-custom">
                                    <input type="checkbox" className="hidden" checked={pricePeriod} onChange={swicthPrice} />
                                    <span className="switch-active">
                                        <span className="switch-active--bg"></span>
                                    </span>
                                    <span className="switch-bg"></span>
                                </label>
                                <p className="custom-txt-3 font-[500]">Annually</p>
                            </label>
                        </div>
                        <SubscriptionPlans plans={pricings} intervalPrice={intervalPrice}/>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Pricing