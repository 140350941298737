import React, { useEffect } from 'react'
import Loader from '../UI/Loader'

const PageLoader = ({ isOverlay }) => {
    useEffect(() => {
        document.querySelector("body").classList.add("lock")
            return () => document.querySelector("body").classList.remove("lock")
    }, [])
    return (
        <div className={`flex items-center justify-center flex-auto min-h-52 ${isOverlay ? " bg-white fixed inset-0 z-[999]" : ""}`}>
            <Loader />
        </div>
    )
}

export default PageLoader