import React from 'react'

const RadioDefault = ({defaultChecked, name,value,children,wrapperClasses, ...props}) => {
    return (
        <label className={`quiz-answer-item ${wrapperClasses}`}>
            <input type="radio" 
                defaultChecked={defaultChecked} 
                name={name} 
                value={value} 
                hidden={true} 
                {...props}
            />
                <span className="quiz-answer-item__icon mr-[16px]"></span>
                {children}
        </label>
    )
}

export default RadioDefault
