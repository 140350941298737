import React, { useEffect, useState } from 'react'
import { getSubscriptionPlansFeth } from '../../store/subscriptionPlan/subscriptionPlansThunk'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentPlan } from '../../store/subscriptionPlan/subscriptionPlansSlice'
import PageLoader from '../../components/parts/PageLoader'
import { getMe } from '../../store/auth/authSlice'
import SubscriptionPlans from '../../components/parts/SubscriptionPlans'

const BillingPlan = () => {
    const dispatch = useDispatch()
    const { subscriptionPlans, isLoading } = useSelector(state => state.subscriptionPlan)
    const [pricings, setPricings] = useState([])
    const [intervalPrice, setIntervalPrice] = useState('month');
    useEffect(() => {
        if (subscriptionPlans) {
            setPricings(subscriptionPlans.filter(item => item.interval === intervalPrice))
        }
    }, [subscriptionPlans, intervalPrice])
    useEffect(() => {
        dispatch(getSubscriptionPlansFeth())
    }, [])
    if (isLoading) return <PageLoader />
    return (
        <div className="tabs-item active-tab" id="plan">
            <h4 className="mb-[24px] text--lg">Change Subscription</h4>
            <SubscriptionPlans plans={pricings} intervalPrice={intervalPrice} />
        </div>
    )
}

export default BillingPlan
