import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import QuizSteps from '../../../components/parts/QuizSteps'
import { STATUSES } from '../../../helpers/constants'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { endTraining, setFlashcardsRound } from '../../../store/flashcards/flashcardsSlice'
import { сlearFCSetStatisticsBySetIdFetch } from '../../../store/flashcards/flashcardsThunks'

const FlashcardPageEnd = () => {
    const { flashCardSet: { flashcards, cards_per_round }, isLoading, pagination, quizData } = useSelector(state => state.flashcards)
    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const totalCount = quizData.cardsTotal;
    let totalPercent = 0

    const statusCounts = {
        AGAIN: quizData?.againCount,
        HARD: quizData?.hardCount,
        GOOD: quizData?.goodCount,
        EASY: quizData?.easyCount,
        SKIP: quizData?.skipCount,
    }
    const statusPercentages = Object.keys(statusCounts).map(status => {
        const percentage = (statusCounts[status] / totalCount * 100)
        totalPercent += percentage
        return {
            status: status,
            percentage: percentage.toFixed() + "%"
        };
    });

    useEffect(() => {
        if (!quizData?.cardsTotal) {
            navigate("..")
        }
    }, [quizData])

    function getDuration(durationMs) {
        const hours = Math.floor(durationMs / 3600);
        const minutes = Math.floor((durationMs % 3600) / 60);
        const remainingSeconds = durationMs % 60;
        
        return minutes > 1 ? `${minutes}m ${remainingSeconds}s` : `${remainingSeconds}s`;
    }
    const quizTime = getDuration(quizData?.studyTime)
    const restartQuize = async () => {
        const res = await dispatch(сlearFCSetStatisticsBySetIdFetch(id)).unwrap()
        if(res) {
            navigate(`/quiz/flashcards/${id}/flashcards/start`)
        }
    }
    const getNextRound = () => {
        const { page, maxPages, total } = pagination;
       
        return page < maxPages ? page + 1 : null
    }
    const nextRound = getNextRound()

    const handleNextRound = () => {
        dispatch(setFlashcardsRound(nextRound))
        navigate(`../flashcards/start`)
    }
    const handleEndTraining = () => {
        dispatch(endTraining())
        navigate(`/quiz/flashcards`)
    }
    const remaining = pagination?.total - quizData?.cardsCompleted
    
    return (
        <main className="content">
            <div className="quiz">
                <QuizSteps data={quizData.flashcards} total={quizData.total} />
                <div className="quiz__content">
                    <div className="quiz__body pt-[60px] pb-[60px]">
                        <div className="container container--sm full-center max-w-max">
                            <p className="font-bold text-[64px] leading-[46px] mb-[36px]">{totalPercent.toFixed()}%</p>
                            <p className="quiz__label mb-[32px]">Review the cards and train yourself</p>
                            <div className="mb-[36px] w-full">
                                <div className="progress-bar-multiple mb-[20px]">
                                    {
                                        statusPercentages.map(({ status, percentage }, index) => (
                                            <span key={index} style={{
                                                width: percentage,
                                                backgroundColor: STATUSES[status].color,
                                                "--i": statusPercentages.length - index
                                            }}></span>
                                        ))
                                    }
                                </div>
                                <div className="group-between overflow-visible">
                                    <div className="group-between--item gap--xs overflow-visible">
                                        <div className="pill pill--outline whitespace-nowrap">
                                            <span className="text--sm color-danger">Again: {quizData?.againCount || 0}</span>
                                        </div>
                                        <div className="pill pill--outline whitespace-nowrap">
                                            <span className="text--sm color-warning">Hard: {quizData?.hardCount || 0}</span>
                                        </div>
                                        <div className="pill pill--outline whitespace-nowrap">
                                            <span className="text--sm color-success">Good: {quizData?.goodCount || 0}</span>
                                        </div>
                                        <div className="pill pill--outline whitespace-nowrap">
                                            <span className="text--sm color-pending">Easy: {quizData?.easyCount || 0}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-[36px] w-full">
                                <div className="flex items-center justify-between w-full mb-[12px]">
                                    <p className="text--md color-secondary">Time studied:</p>
                                    <p className="text--xl">{quizTime}</p>
                                </div>
                                <div className="flex items-center justify-between w-full">
                                    <p className="text--md color-secondary">Cards left:</p>
                                    <p className="text--xl color-secondary"><span className="color-default">{quizData?.cardsCompleted}</span> / {quizData?.cardsTotal} </p>
                                </div>
                            </div>
                            <div className="flex items-center gap--xs">
                                <button onClick={handleEndTraining} className="btn btn--shadow btn--md radius-5">
                                    <span className="info text--md">End Training</span>
                                </button>
                                {
                                    nextRound ?
                                        <button onClick={handleNextRound} className="btn btn--primary btn--md radius-5">
                                            <span className="info text--md">{ remaining < cards_per_round ? remaining : cards_per_round} More Cards</span>
                                        </button>
                                        : null

                                }
                                
                                  {+totalPercent.toFixed() === 100 && <button type='button' onClick={restartQuize} className="btn btn--shadow  btn--md radius-5">
									<span className="ico">
										<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M17.864 4.75244V7.99377H14.6235H17.864" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M17.4413 7.99359C16.1973 5.71384 13.7792 4.1665 10.9998 4.1665C6.95 4.1665 3.6665 7.45 3.6665 11.4998C3.6665 15.5497 6.95 18.8332 10.9998 18.8332C15.0497 18.8332 18.3332 15.5497 18.3332 11.4998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</span>
									<span>Restart Quiz</span>
								</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default FlashcardPageEnd