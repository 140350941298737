import React from 'react'
import ModalTemplate from './ModalTemplate'

const SelectCommentModal = ({ open, handelClose, poinHandler, justCommentHandler, canPoint = true }) => {
    return (
        <ModalTemplate open={open} handelClose={handelClose}>
            <div className="modal__body items-center">
                <div className="modal__body-heading text-center">
                    <h2>Select one option to comment</h2>
                </div>
                <div className="flex flex-wrap gap-[12px] w-full">

                    <button type='button' onClick={canPoint ? poinHandler : () => { }}
                        className={`btn btn--shadow create-quize-card radius-5 flex-1 ${!canPoint ? "disabled" : ""}`}  >
                        <span className="ico ico-36 mb-[14px]">
                            <img src={require("../../assets/img/ico-points.svg").default} alt="" />
                        </span>
                        <div className="font-semibold text-[16px] mb-[12px]">Point + Comment</div>
                        <div className="text-[14px] color-secondary">Create point on image and text comment for it.</div>
                    </button>

                    <button type='button' onClick={justCommentHandler} className="btn btn--shadow create-quize-card radius-5 flex-1" >
                        <span className="ico ico-36 mb-[14px]">
                            <img src={require("../../assets/img/ico-chat-comments.svg").default} alt="" />
                        </span>
                        <div className="font-semibold text-[16px] mb-[12px]">Just Comment</div>
                        <div className="text-[14px] color-secondary">Create just a text for comment the picture.</div>
                    </button>
                </div>
            </div>
        </ModalTemplate>
    )
}

export default SelectCommentModal
