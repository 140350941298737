import React, { useEffect, useRef, useState } from 'react'
import ModalTemplate from './ModalTemplate'
import { ReactComponent as PlayIcon } from "../../assets/img/play.svg"
import { useSelector } from 'react-redux';
import { getImageSrc } from '../../helpers/utils';
import CaseGalleryPart from '../parts/CaseGalleryPart';
import ReactPlayer from 'react-player';
const CaseModal = ({ open, handelClose }) => {
    const { caseOne, isLoading } = useSelector(state => state.cases)
    const [selectedImageSet, setSelectedImagesSet] = useState(null)
    const [selectedHintsIndex, setSelectedHintsIndex] = useState(0)

    const videoRef = useRef(null);
    const playButtonRef = useRef(null);
    const modalTabs = [
        {
            label: 'Images',
            icon: <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9314 11.3207L11.9317 11.3203C11.9992 11.2507 11.9975 11.1395 11.9279 11.072C11.8582 11.0044 11.7471 11.0061 11.6795 11.0756C11.6119 11.1452 11.6135 11.2564 11.683 11.324C11.7525 11.3917 11.8637 11.3902 11.9314 11.3207" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M3.25 12.6917C3.54494 12.6463 3.8428 12.6224 4.1412 12.6204V12.6204C5.79574 12.6204 7.3825 13.2776 8.55243 14.4476C9.72237 15.6175 10.3796 17.2043 10.3796 18.8588C10.3769 19.1572 10.3531 19.455 10.3083 19.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <rect x="3.25" y="6.91669" width="12.8333" height="12.8333" rx="3" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></rect>
                <path d="M6 6.91667V6C6 4.48122 7.23122 3.25 8.75 3.25H17C18.5188 3.25 19.75 4.48122 19.75 6V14.25C19.75 15.7688 18.5188 17 17 17H16.0833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
        },
        {
            label: 'Videos',
            icon: <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0294 8.80317L13.5879 10.9078C14.0379 11.1737 14.0379 11.8254 13.5879 12.0913L10.0294 14.1959C9.57103 14.4673 8.9917 14.1363 8.9917 13.6038V9.39534C8.9917 8.86275 9.57103 8.53184 10.0294 8.80317V8.80317Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path fillRule="evenodd" clipRule="evenodd" d="M16.5 19.75H5.5C3.98108 19.75 2.75 18.5189 2.75 17V6C2.75 4.48108 3.98108 3.25 5.5 3.25H16.5C18.0189 3.25 19.25 4.48108 19.25 6V17C19.25 18.5189 18.0189 19.75 16.5 19.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
        },
        {
            label: 'Notes',
            icon: <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.8119 11.9898L12.3525 17.4492C12.0019 17.7998 11.7744 18.2547 11.7043 18.7456L11.5046 20.1434C11.4842 20.2862 11.5322 20.4303 11.6342 20.5323C11.7363 20.6343 11.8803 20.6823 12.0232 20.6619L13.421 20.4622C13.9119 20.3921 14.3668 20.1647 14.7174 19.8141L20.1768 14.3546C20.8298 13.7016 20.8298 12.6428 20.1768 11.9898V11.9898C19.8632 11.6762 19.4379 11.5 18.9944 11.5C18.5509 11.5 18.1255 11.6762 17.8119 11.9898Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M8.75 9.66668H14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M8.75 13.3333H10.5833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M8.74984 19.75H5.99984C5.51374 19.7501 5.0475 19.5572 4.70368 19.2135C4.35986 18.8699 4.16664 18.4038 4.1665 17.9177V5.08333C4.16637 4.59724 4.35934 4.13099 4.70297 3.78718C5.0466 3.44336 5.51273 3.25013 5.99883 3.25H16.9998C17.4859 3.24986 17.9522 3.44284 18.296 3.78646C18.6398 4.13009 18.833 4.59623 18.8332 5.08233V8.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M3.25 7.83333H5.38583" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M3.25 11.5H5.38583" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M3.14014 15.1667H5.38597" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
        }
    ]
    const [mobileSidebarActive, setMobileSidebarActive] = useState(false)
    const [activeTab, setActiveTab] = useState(modalTabs[0].label);
    const [activeVideoIndex, setActiveVideoIndex] = useState(0)
    const [currentVideoFile, setCurrentVideoFile] = useState({})

    const thumbsClickHandler = (set, index) => {
        setSelectedImagesSet(set)
        setSelectedHintsIndex(index)
        setMobileSidebarActive(true)
    }
    useEffect(() => {
        if (caseOne?.caseContent?.images) {
            setSelectedImagesSet(caseOne?.caseContent?.images[0])
            setSelectedHintsIndex(0)
        }

    }, [caseOne])
    useEffect(() => {
        if (caseOne?.caseContent?.videos) {
            setCurrentVideoFile(caseOne?.caseContent?.videos[activeVideoIndex])
        }
    }, [caseOne, activeVideoIndex])
    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            playButtonRef.current.style.display = 'none';
        }
    };

    const handlePause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            playButtonRef.current.style.display = 'block';
        }
    };

    return (
        <ModalTemplate
            open={open}
            handelClose={handelClose}
            showHeader={false}
            modalSize='modal__dialog--1200'
            modalTheme={`black-modal modal-fullscreen ${mobileSidebarActive && '--sidebar-active'}`}
        >
            <div className="modal__row">

                <div className="modal_sidebar">
                    <div className="sidebar-left__content tabs">
                        <div className="sidebar-left__content-abs">
                            <button type='button' onClick={handelClose} className="btn btn-tablink btn--xl radius-5 flex-768 h-max">
                                <span className="ico ico-22">
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11 3.25V3.25C15.5568 3.25 19.25 6.94325 19.25 11.5V11.5C19.25 16.0568 15.5568 19.75 11 19.75V19.75C6.44325 19.75 2.75 16.0568 2.75 11.5V11.5C2.75 6.94325 6.44325 3.25 11 3.25Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M7.3335 11.5H14.6668" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M10.0835 14.25L7.3335 11.5L10.0835 8.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                                <span>Close</span>
                            </button>
                            <button type='button' onClick={() => { setMobileSidebarActive(prev => !prev) }} className="btn btn-tablink btn--xl radius-5 h-max toggle-sidebar-modal-btn">
                                <span className="ico ico-22">
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11 3.25V3.25C15.5568 3.25 19.25 6.94325 19.25 11.5V11.5C19.25 16.0568 15.5568 19.75 11 19.75V19.75C6.44325 19.75 2.75 16.0568 2.75 11.5V11.5C2.75 6.94325 6.44325 3.25 11 3.25Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M7.3335 11.5H14.6668" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M10.0835 14.25L7.3335 11.5L10.0835 8.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="sidebar-body scrollstyle">
                            <div className="tabs-header">
                                <button type='button' onClick={handelClose} className="btn btn-tablink btn--xl radius-5 hidden-768">
                                    <span className="ico ico-22">
                                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11 3.25V3.25C15.5568 3.25 19.25 6.94325 19.25 11.5V11.5C19.25 16.0568 15.5568 19.75 11 19.75V19.75C6.44325 19.75 2.75 16.0568 2.75 11.5V11.5C2.75 6.94325 6.44325 3.25 11 3.25Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M7.3335 11.5H14.6668" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M10.0835 14.25L7.3335 11.5L10.0835 8.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                    <span>Back</span>
                                </button>
                                <ul className="tabs-nav">
                                    {modalTabs.map((tab, index) => (
                                        <li className="tabs-nav--item" key={index}>
                                            <button type='button' onClick={() => { setActiveTab(tab.label) }} className={`btn btn-tablink btn--xl radius-5 ${activeTab === tab.label && 'active-tab'}`}>
                                                <span className="ico ico-22">
                                                    {tab.icon}
                                                </span>
                                                <span>{tab.label}</span>
                                            </button>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                            <div className="tabs-body">
                                {activeTab === 'Images' && <div className="tabs-item active-tab" >
                                    <ul className="blueray-list">
                                        {caseOne?.caseContent?.images?.map((imageSet, index) => (
                                            <li className="blueray-item" key={index}>
                                                <label className="blueray-control">
                                                    <input type="radio"
                                                        id="blueray-0"
                                                        checked={selectedHintsIndex === index}
                                                        className="hidden"
                                                        name="blueray"
                                                        onChange={() => thumbsClickHandler(imageSet, index)}
                                                    />
                                                    <div className="btn blueray-btn">
                                                        <img src={getImageSrc(imageSet.images.find(image => image.isCover).fileName)} />
                                                    </div>
                                                    <p className="blueray-title">
                                                        {imageSet.setName}
                                                    </p>
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>}
                                {activeTab === 'Videos' && <div className="tabs-item active-tab" >
                                    <ul className="blueray-list">
                                        {caseOne?.caseContent?.videos?.map((videoItem, index) => (
                                            <li className="blueray-item" key={index}>
                                                <label className="blueray-control">
                                                    <input
                                                        type="radio"
                                                        id="video-0"
                                                        className="hidden"
                                                        name="video"
                                                        checked={activeVideoIndex === index}
                                                        onChange={() => {
                                                            setMobileSidebarActive(true)
                                                            setActiveVideoIndex(index)
                                                        }}
                                                    />
                                                    <div className="btn blueray-btn">
                                                        <video
                                                            className="w-full"
                                                            src={getImageSrc(videoItem.fileName)}
                                                        ></video>
                                                    </div>
                                                    <p className="blueray-title">
                                                        {videoItem.name}
                                                    </p>
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>}
                                {activeTab === 'Notes' && <div className="tabs-item active-tab">
                                    <ul className="list-notes">
                                        <li className="note-item">
                                            <div className="note-block">
                                                <p className="note-block--title">Author Notes</p>
                                                <p className="note-block--desk">
                                                    {caseOne?.caseContent?.notes}
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-canva">
                    <div className="article-cases --check-delegate">
                        {activeTab === 'Images' && <CaseGalleryPart imagesSet={selectedImageSet} showNotes={true} hideMobileActions={true} />}
                        {activeTab === 'Notes' &&
                            <div className='article-cases-single'>
                                <div className="article-cases-image px-4 text-white text-center">
                                    {caseOne?.caseContent?.notes}
                                </div>
                            </div>
                        }
                        {activeTab === 'Videos' &&
                            <div className='article-cases-single'>
                                <div className="article-cases-image px-4 text-white text-center">
                                    <div className="container container--video flex-auto">
                                        {currentVideoFile?.fileName ? <div className="quiz-video">
                                            {/* <div className="btn-play" ref={playButtonRef} onClick={handlePlay}>
                                                <button className="quiz-video__button btn btn--square btn--xl rounded-full btn--shadow">
                                                    <PlayIcon />
                                                </button>
                                            </div>
                                            <video
                                                ref={videoRef}
                                                src={getImageSrc(currentVideoFile?.fileName)}
                                                controls
                                                className="video video-md"
                                                onPlay={handlePlay}
                                                onPause={handlePause}
                                                controlsList="nodownload" oncontextmenu="return false;"
                                            /> */}
                                            <ReactPlayer
                                                playing={true}
                                                url={getImageSrc(currentVideoFile?.fileName)}
                                                playIcon={<span className="quiz-video__button btn btn--square btn--xl rounded-full btn--shadow">
                                                    <PlayIcon />
                                                </span>}
                                                controls
                                                light={
                                                    <video
                                                        src={getImageSrc(currentVideoFile?.fileName)}
                                                    />
                                                }
                                                className="!w-full"
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            controlsList: 'nodownload'
                                                        }
                                                    }
                                                }}
                                            />
                                        </div> : <h3 className='w-full self-center'>No video files</h3>}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ModalTemplate>
    )
}

export default CaseModal
