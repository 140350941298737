import { createSlice } from '@reduxjs/toolkit';

import { createMessageFeth } from './contactThunk';

const initialState = {
    isContactSend: false
}

const contactSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {
        
    },

    extraReducers(builder) {
        builder.addCase(createMessageFeth.fulfilled, (state, { payload }) => {
            state.isLoading = false
        })
        builder.addCase(createMessageFeth.pending, (state, { payload }) => {
            state.isLoading = true
        })
        builder.addCase(createMessageFeth.rejected, (state, { payload }) => {
            state.isLoading = false
        })
    }
})

export const {
   
} = contactSlice.actions;


export default contactSlice.reducer