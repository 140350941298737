import React, { useEffect, useRef, useState } from 'react'
import BreadCrambs from '../../components/parts/BreadCrambs'
import SingleActions from '../../components/parts/SingleActions'
import CommentsPart from '../../components/parts/CommentsPart'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import AccordeonLockedType from '../../components/UI/AccordeonLockedType'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { completeLessonByLessonIdFeth, deleteCourseByIdFeth, favoriteCourseByCourseIdFeth, getCourseByIdFeth, likeCourseByCourseIdFeth, viewCourseByCourseIdFeth } from '../../store/courses/coursesThunk'
import CommentSection from '../../components/comments/CommentSection'
import { addCommentCourseIdFeth, getCommentsByEntityTypeEntityIdFeth } from '../../store/comments/commentsThunk'
import { getDateToString, getImageSrc } from '../../helpers/utils'
import { Link as ScrollLink } from 'react-scroll'
import { ReactComponent as VideoIcon } from '../../assets/img/cource-video-icon.svg'
import { ReactComponent as ImageIcon } from '../../assets/img/course-image-icon.svg'
import { ReactComponent as DocIcon } from '../../assets/img/course-doc-icon.svg'
import { getMe } from '../../store/auth/authSlice'
import { ReactComponent as PlayIcon } from "../../assets/img/play.svg"
import pdf from "../../assets/img/Pdf-File.svg"
import word from "../../assets/img/Word.svg"
import PageLoader from '../../components/parts/PageLoader'
import SidebarRelatedVideos from '../../components/parts/sidebarPosts/SidebarRelatedVideos'
import SidebarRelatedImages from '../../components/parts/sidebarPosts/SidebarRelatedImages'
import SidebarRecomendedCourses from '../../components/parts/sidebarPosts/SidebarRecomendedCourses'
import MediaRenderer from '../../components/parts/MediaRenderer'
import ReactPlayer from 'react-player'

const CourseSingle = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const me = useSelector(getMe)
    const { courseOne, courseId, isLoading } = useSelector(state => state.courses)
    const { comments, pagination, commentsIsLoading } = useSelector(state => state.comments)
    const [commentsLimit, setCommentsLimit] = useState(10)
    const [commentsPage, setCommentsPage] = useState(1)
    const [searchParams] = useSearchParams();
    const videoRef = useRef(null);
    const playButtonRef = useRef(null);
    useEffect(() => {
        if (id) {
            dispatch(getCommentsByEntityTypeEntityIdFeth({ id, type: 'course', qwery: `limit=${commentsLimit}&page=${commentsPage}` }));
        }
    }, [id, commentsPage])
    const breadcramds = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/courses',
            label: 'Courses'
        },
        {
            link: '/courses/:id',
            label: courseOne?.name
        },
    ]
    const toggleLike = () => {
        dispatch(likeCourseByCourseIdFeth(id))

    }
    const toggleFavorite = () => {
        dispatch(favoriteCourseByCourseIdFeth(id))

    }
    const deleteHandler = async () => {
        const res = await dispatch(deleteCourseByIdFeth(id)).unwrap()
        if (res) {
            navigate('/courses')
        }
    }
    const editHandler = () => {
        navigate(`/course-edit/${id}/general`)
    }
    const documentThumb = {
        pdf,
        docx: word
    }
    const completeLesson = async (id) => {
        const res = await dispatch(completeLessonByLessonIdFeth(id)).unwrap()

    }
    const getFileExt = (file) => {

        const parts = file.fileName?.split('.')
        const ext = parts?.length ? parts[parts.length - 1] : "";
        return ext;
    };
    useEffect(() => {
        if (id) {
            dispatch(getCourseByIdFeth({ id: id, qwery: '' }));
            dispatch(viewCourseByCourseIdFeth(id))
        }
    }, [id])
    const completedPersent = courseOne?.statistics ? Math.round(courseOne.statistics.completed / courseOne.statistics.total * 100) : 0
    if (isLoading) return <PageLoader />
    return (
        <main className="content">
            <BreadCrambs breabcrambs={breadcramds} />
            <section className="page-section">
                <div className="container">
                    <div className="page-section--row">
                        <div className="page-sidebar --none-border nav-article-aside mobile-fixed mobile-fixed--left lg:mt-[210px]" data-aside="body">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <p className="sidebar-label mb-[20px]">Contents</p>
                                <ul className="nav-article">
                                    <li>
                                        <ScrollLink
                                            activeClass="active"
                                            to={'description'}
                                            spy={true}
                                            smooth={true}
                                            hashSpy={true}
                                            offset={-100}
                                            duration={500}
                                        >
                                            Course Description
                                        </ScrollLink>
                                    </li>
                                    {
                                        courseOne?.lessons?.map(lesson => (
                                            <li key={lesson.id}>
                                                <ScrollLink
                                                    activeClass="active"
                                                    to={lesson.title.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '')}
                                                    spy={true}
                                                    smooth={true}
                                                    hashSpy={true}
                                                    offset={-100}
                                                    duration={500}
                                                >
                                                    {lesson.title}
                                                </ScrollLink>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <a href="" className="btn btn--primary btn--square btn--md sidebar-action-btn" data-aside="acticon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"></path>
                                    </svg>
                                </a>
                            </aside>
                        </div>
                        <div className="page-content">
                            <article className="single-post">
                                <div >
                                    <div className="pill-custom">
                                        <span className="pill-text">{courseOne?.filter?.name}</span>
                                    </div>
                                    <h2>
                                        {courseOne?.name}
                                    </h2>

                                    <SingleActions
                                        likes={courseOne?.likes}
                                        displayUser={courseOne?.settings?.displayAuthorName}
                                        user={courseOne?.user}
                                        createAt={courseOne?.updatedAt}
                                        toggleLike={toggleLike}
                                        toggleFavorite={toggleFavorite}
                                        isLikeProps={courseOne?.isLiked}
                                        isFavoriteProps={courseOne?.isFavorite}
                                        handleDeleteButton={deleteHandler}
                                        editButtonHandler={editHandler}
                                    />

                                    {(courseOne?.isPurchased || me?.id === courseOne?.user?.id) && courseOne?.statistics && <div className="progress -mb-[24px]">
                                        <p className="text--lg font-semibold">{completedPersent} %</p>
                                        <div className="progress-bar">
                                            <span className="progress-line" style={{ width: `${completedPersent}%` }}></span>
                                        </div>
                                    </div>}
                                    {!courseOne?.isPurchased && me?.id !== courseOne?.user?.id &&
                                        <>
                                            <hr />
                                            <div className="row-group gap--md justify-between w-full -mb-[24px] -mt-[8px]">
                                                <div className="row-group gap--sm">
                                                    <h2 className="color-danger">${courseOne?.price}</h2>
                                                    {courseOne?.oldPrice && <span className="line-through color-secondary">${courseOne?.oldPrice}</span>}
                                                </div>
                                                <Link to={`/checkout/cource/${id}`} className="btn btn--primary btn--lg radius-5 gap--xs">
                                                    <span className="ico ">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 17.5L5 3.5H3" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M8.25 17.5H18.25" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <circle cx="18.25" cy="19.25" r="1.75" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                                            <circle cx="8.25" cy="19.25" r="1.75" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                                                            <path d="M5.64258 6.5H19.9992C20.3098 6.5 20.6028 6.64438 20.7921 6.89073C20.9814 7.13708 21.0454 7.45741 20.9654 7.75759L19.3659 13.7576C19.2492 14.1954 18.8528 14.5 18.3997 14.5H7.35688" stroke="#191919" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </span>
                                                    <span className="info color-default">Buy Course</span>
                                                </Link>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="article-media">
                                    <img src={getImageSrc(courseOne?.cover)} alt="" />
                                </div>
                                <div id="description">
                                    <h3>Course Description</h3>
                                    <p>
                                        {courseOne?.description}
                                    </p>
                                </div>
                                <div>
                                    <h3>Lessons</h3>
                                    <div className="w-full">
                                        {courseOne?.lessons?.map(lesson => (
                                            <AccordeonLockedType
                                                iconLeft={
                                                    lesson.type === 'image+text' ? <ImageIcon /> :
                                                        lesson.type === 'video+text' ? <VideoIcon /> :
                                                            <DocIcon />
                                                }
                                                title={lesson.title}
                                                isLock={me?.id === courseOne?.user?.id ? false : !courseOne?.isPurchased}
                                                id={lesson.title.trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '')}
                                                key={lesson.id}
                                                isCompleted={courseOne?.statistics?.completedIds?.includes(lesson.id)}
                                            >
                                                <div className="pb-[20px]">
                                                    <div className="single-post mb-[30px]">
                                                        {lesson?.link && <MediaRenderer url={lesson?.link} />
                                                        }
                                                        {lesson?.media && lesson?.media.length > 0 &&
                                                            <>
                                                                {lesson?.type === 'image+text' && lesson?.media.map((media, index) =>
                                                                (<div className="article-media" key={index}>
                                                                    <img onContextMenu={(e) => e.preventDefault()} src={getImageSrc(media.fileName)} alt="" />
                                                                </div>)
                                                                )}
                                                                {lesson?.type === 'video+text' && lesson?.media.map((media, index) =>
                                                                (<div className="video-wrapper relative" key={index}>
                                                                    <ReactPlayer
                                                                        url={getImageSrc(media?.fileName)}
                                                                        controls
                                                                        className={`mb-0 flex`}
                                                                        // light={<video className='w-full' src={getImageSrc(media?.fileName)}></video>}
                                                                        width="100%"
                                                                        controlsList="nodownload"
                                                                        height="auto"
                                                                        config={{
                                                                            file: {
                                                                              attributes: {
                                                                                controlsList: 'nodownload'  // Отключаем кнопку скачивания
                                                                              }
                                                                            }
                                                                          }}
                                                                        // playIcon={<button className="quiz-video__button btn btn--square btn--xl rounded-full btn--shadow">
                                                                        //     <PlayIcon />
                                                                        // </button>}
                                                                    />
                                                                    {/* <a className='btn download-video' href={getImageSrc(`/api/courses/download?filename=${media?.fileName}`)}>
                                                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_172_20278)">
                                                                                <path d="M12.1914 13.9448V3.44482" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                <path d="M20.4414 13.9448V19.9448H3.94141V13.9448" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                                <path d="M15.9414 10.1948L12.1914 13.9448L8.44141 10.1948" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_172_20278">
                                                                                    <rect width="24" height="24" fill="white" transform="translate(0.191406 0.444824)" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </a> */}
                                                                </div>
                                                                )
                                                                )}
                                                                {lesson?.type === 'document+text' && lesson?.media.map((media, index) =>
                                                                (<div key={index}>
                                                                    <a href={getImageSrc(`/api/courses/download?filename=${media?.fileName}`)} download={true}>
                                                                        <img src={documentThumb[getFileExt(media)]} />
                                                                    </a>
                                                                </div>
                                                                )
                                                                )}
                                                            </>
                                                        }
                                                        <div>
                                                            <h3>Course Description</h3>
                                                            <div dangerouslySetInnerHTML={{ __html: lesson?.content }} />
                                                        </div>
                                                    </div>
                                                    {me?.id !== courseOne?.user?.id && !courseOne?.statistics?.completedIds?.includes(lesson.id) &&
                                                        <button
                                                            type='button'
                                                            className="btn btn--primary radius-5 gap--xs btn--lg"
                                                            onClick={() => { completeLesson(lesson.id) }}
                                                        >
                                                            <span className="ico">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M20 6.5L9 17.5L4 12.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                </svg>
                                                            </span>
                                                            <span className="info">Complete Lesson</span>
                                                        </button>
                                                    }
                                                </div>
                                            </AccordeonLockedType>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <SingleActions
                                        likes={courseOne?.likes}
                                        displayUser={courseOne?.displayAuthorName}
                                        user={courseOne?.user}
                                        createAt={courseOne?.updatedAt}
                                        toggleLike={toggleLike}
                                        toggleFavorite={toggleFavorite}
                                        isLikeProps={courseOne?.isLiked}
                                        isFavoriteProps={courseOne?.isFavorite}
                                        handleDeleteButton={deleteHandler}
                                        editButtonHandler={editHandler}
                                    />
                                </div>
                                {courseOne?.settings?.enableComments && <div>
                                    <CommentSection
                                        addCommentFeth={addCommentCourseIdFeth}
                                        commentArrray={comments}
                                        pagination={pagination}
                                        setLimit={setCommentsPage}
                                        isLoading={commentsIsLoading}
                                    />
                                </div>}
                            </article>
                        </div>
                        <div className="page-sidebar --none-border lg:mt-[210px]">
                            <aside className="sidebar post-sidebar sidebar-xs">
                                <div className="sidebar-body">
                                    {courseOne?.relatedImages && courseOne?.settings?.showRelatedLinks &&
                                        <SidebarRelatedImages
                                            images={courseOne?.relatedImages}
                                            title={'Related Images'}
                                        />
                                    }
                                    {courseOne?.relatedVideos && courseOne?.settings?.showRelatedLinks &&
                                        <SidebarRelatedVideos
                                            videos={courseOne?.relatedVideos}
                                            title={'Related Videos'}
                                        />
                                    }
                                    {courseOne?.relatedCourses && courseOne?.relatedCourses?.length > 0 &&
                                        <SidebarRecomendedCourses
                                            title={`${courseOne?.filter?.name} Courses`}
                                            courses={courseOne?.relatedCourses}
                                            hideIcon={true}
                                        />
                                    }
                                    <a href="" className="btn btn--primary btn--square btn--md sidebar-action-btn" data-aside="acticon">
                                        <span className="ico">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                <path d="M256 0H576c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64zM476 106.7C471.5 100 464 96 456 96s-15.5 4-20 10.7l-56 84L362.7 169c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H552c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144zM336 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM64 128h96V384v32c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V384H512v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64zm8 64c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V312c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H72zm336 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H424c-8.8 0-16 7.2-16 16z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default CourseSingle
