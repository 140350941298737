import React, { useEffect, useRef, useState } from 'react'
import { Fancybox as NativeFancybox } from '@fancyapps/ui';

import { ReactComponent as ArrowLeftIcon } from "../../../assets/img/ico-arrow-left.svg"
import { ReactComponent as ArrowRightIcon } from "../../../assets/img/ico-arrow-right.svg"
import { ReactComponent as PlayIcon } from "../../../assets/img/play.svg"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getQuizMediaByQuizIdFeth } from '../../../store/quizzes/quizzesThunks'
import Loader from '../../../components/UI/Loader'
import { useSelector } from 'react-redux'
import { getImageSrc } from '../../../helpers/utils'
import { setActiveImageWathIndex, setActiveVideoWathIndex } from '../../../store/quizzes/quizzesSlice'
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import ReactPlayer from 'react-player';
const openFancybox = (media, startIndex = 0, dispatch, setIndex) => {
    const items = media.map((item) => ({
        src: getImageSrc(item.fileName)
    }));

    NativeFancybox.show(items, {
        startIndex: startIndex,
        on: {
            close: (fancybox) => {

                dispatch(setIndex(fancybox.getSlide().index));
            },

        },
    });
};

const QuizPageMaterials = ({ tab }) => {
    const { id } = useParams();

    const { quizMedia, isLoading, activeImageWathIndex, activeVideoWathIndex } = useSelector(state => state.quizzes)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentImageFile, setCurrentImageFile] = useState({})
    const [currentVideoFile, setCurrentVideoFile] = useState({})
    useEffect(() => {

        setCurrentImageFile(quizMedia?.images?.find((item, index) => index === activeImageWathIndex))

    }, [activeImageWathIndex, quizMedia])
    useEffect(() => {

        setCurrentVideoFile(quizMedia?.videos?.find((item, index) => index === activeVideoWathIndex))

    }, [activeVideoWathIndex, quizMedia])
    const videoRef = useRef(null);
    const playButtonRef = useRef(null);
    const decrimentHandler = () => {
        if (tab === 'image') {
            dispatch(setActiveImageWathIndex(activeImageWathIndex - 1))
        } else {
            dispatch(setActiveVideoWathIndex(activeVideoWathIndex - 1))
        }
    }
    const incrimentHandler = () => {
        if (tab === 'image') {
            dispatch(setActiveImageWathIndex(activeImageWathIndex + 1))
        } else {
            dispatch(setActiveVideoWathIndex(activeVideoWathIndex + 1))
        }
    }

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            playButtonRef.current.style.display = 'none';
        }
    };

    const handlePause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            playButtonRef.current.style.display = 'block';
        }
    };
    console.log(tab, currentVideoFile)
    if (!quizMedia) return null
    if (isLoading) return <Loader />
    return (
        <div className="content">
            <div className="quiz">
                <div className="quiz__content">
                    <div className="quiz__body quiz__body-media">
                        {tab === "video" && quizMedia?.videos?.length > 0 ?
                            <div className="container container--video">
                                <div className="quiz-video video-wrapper">
                                    {/* <div className="btn-play" ref={playButtonRef} onClick={handlePlay}>
                                        <button className="quiz-video__button btn btn--square btn--xl rounded-full btn--shadow">
                                            <PlayIcon />
                                        </button>
                                    </div>
                                    <video
                                        ref={videoRef}
                                        src={getImageSrc(currentVideoFile?.fileName)}
                                        controls
                                        className="video"
                                        controlsList="nodownload"
                                        onPlay={handlePlay}
                                        onPause={handlePause}
                                        
                                    /> */}
                                    <ReactPlayer
                                        playIcon={
                                            <span className="quiz-video__button btn btn--square btn--xl rounded-full btn--shadow">
                                                <PlayIcon />
                                            </span>
                                        }
                                        playing={true}
                                        width={'100%'}
                                        height={'100%'}
                                        controls
                                        config={{
                                            file: {
                                                attributes: {
                                                    controlsList: 'nodownload'
                                                }
                                            }
                                        }}
                                        light ={
                                            <video src={getImageSrc(currentVideoFile?.fileName)}/>
                                        }
                                        url={getImageSrc(currentVideoFile?.fileName)}
                                    />
                                </div>
                            </div>
                            : tab === "image" && quizMedia?.images?.length > 0 ?
                                <div className="container container--image">
                                    <div className="quiz-img">
                                        <img src={getImageSrc(currentImageFile?.fileName)} alt="" />
                                    </div>
                                </div> :
                                <div className="container container--image">
                                    <div>
                                        {quizMedia?.notes}
                                    </div>
                                </div>
                        }

                    </div>
                    <div className="quiz__footer bg-white">
                        <div className="container">
                            <Link to={`/quiz-page/${id}/presentation`} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                <span className="ico">
                                    <ArrowLeftIcon />
                                </span>
                                <span className="info text-[13px] font-[500] sm:flex hidden">Back</span>
                            </Link>
                            {tab !== "notes" && <div className="flex items-center gap--xs">
                                <button
                                    className={`btn btn--transparent btn--square btn--md radius-5 
                                    ${(!activeImageWathIndex && tab === 'image') ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (!activeVideoWathIndex && tab === 'video') {
                                            navigate(`/quiz-page/${id}/materials`)
                                            return
                                        }
                                        decrimentHandler()
                                    }}
                                >
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.8332 7.33337L9.1665 11L12.8332 14.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </button>
                                <button
                                    type='button'
                                    onClick={() => {
                                        if (tab === 'image') {
                                            openFancybox(quizMedia?.images, activeImageWathIndex, dispatch, setActiveImageWathIndex)
                                        }
                                        if (tab === 'video') {
                                            openFancybox(quizMedia?.videos, activeVideoWathIndex, dispatch, setActiveVideoWathIndex)
                                        }
                                    }}
                                    className={`btn btn--transparent btn--square btn--md radius-5 ${(!quizMedia.videos || !quizMedia.images) ? 'disabled' : ''}`}>
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="2.74658" y="2.74646" width="16.5069" height="16.5069" rx="5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11.4585 7.79028H14.2096V10.5414" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.5417 14.2096H7.79053V11.4585" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </button>
                                <button
                                    type='button'
                                    className={`btn btn--transparent btn--square btn--md radius-5 
                                        
                                        `}

                                    onClick={() => {
                                        if (activeImageWathIndex === quizMedia?.images?.length - 1 && tab === 'image') {
                                            navigate('video')
                                            return
                                        }
                                        if (activeVideoWathIndex === quizMedia?.videos?.length - 1 && tab === 'video') {
                                            navigate(`/quiz-page/${id}/materials/notes`)
                                            return
                                        }
                                        incrimentHandler()
                                    }}
                                >
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.1665 14.6667L12.8332 11L9.1665 7.33337" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </button>
                            </div>}
                            <Link to={`/quiz-page/${id}/hints`} className="btn btn--primary gap-[10px] btn--md radius-5">
                                <span className="info text-[13px] font-[500] sm:flex hidden">Next Step</span>
                                <span className="ico">
                                    <ArrowRightIcon />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuizPageMaterials