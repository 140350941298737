import React, { useEffect, useRef, useState } from 'react'
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import { ReactComponent as ArrowRightIcon } from "../../../assets/img/ico-arrow-right.svg"
import { ReactComponent as ArrowLeftIcon } from "../../../assets/img/ico-arrow-left.svg"
import { Dropdown } from '../../../components/parts/Dropdown'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getQuizHintsByQuizIdFeth } from '../../../store/quizzes/quizzesThunks'
import DrawingComponent from '../../../components/parts/DrawingComponent'
import { getImageSrc } from '../../../helpers/utils'
import { setActiveHintWathIndex } from '../../../store/quizzes/quizzesSlice'
import "@fancyapps/ui/dist/fancybox/fancybox.css";
const openFancybox = (media, startIndex = 0, dispatch, setIndex) => {
    const items = media.map((item) => ({
        src: getImageSrc(item.fileName)
    }));
    
    NativeFancybox.show(items, {
      startIndex: startIndex,
      on: {
        close: (fancybox) => {
          
          dispatch(setIndex(fancybox.getSlide().index));
        },
        
      },
    });
  };

const QuizPageHints = () => {

    const { id } = useParams();
    const { quizeHints,activeHintWathIndex, isLoading } = useSelector((state) => state.quizzes);
    const dispatch = useDispatch();
    const drawingRef = useRef();
    const [selectedHint, setSelectedHint] = useState(null)
    
    useEffect(() => {
        if(quizeHints) {
            setSelectedHint(quizeHints?.find((item, index) => index ===activeHintWathIndex))
        }
    },[activeHintWathIndex,quizeHints])
    const decrimentHandler = () => {
        dispatch(setActiveHintWathIndex(activeHintWathIndex - 1))
    }
    const incrimentHandler = () => {
        dispatch(setActiveHintWathIndex(activeHintWathIndex + 1))
    }
    return (
        <main className="content">
            <div className="quiz">
                <div className="quiz__content">
                    <div className="quiz__body">
                        <div className="container container--image flex-auto "  >
                            <DrawingComponent
                                ref={drawingRef}
                                image={{preview: getImageSrc(selectedHint?.fileName)}}
                                hintsSelected={selectedHint}
                                // selectedIndex={selectedHintsIndex}
                                setItems={() => { }}
                                showOnly={true}
                            />
                        </div>
                    </div>
                    <div className="quiz__footer bg-white">
                        <div className="container">
                            <Link to={`/quiz-page/${id}/materials`} className="btn btn--shadow gap-[10px] btn--md radius-5">
                                <span className="ico">
                                    <ArrowLeftIcon />
                                </span>
                                <span className="info text-[13px] font-[500] sm:flex hidden">Back</span>
                            </Link>
                            <div className="flex items-center gap--xs">
                                <button type='button' onClick={decrimentHandler} className={`btn btn--transparent btn--square btn--md radius-5 ${!activeHintWathIndex ? 'disabled' :  '' }`}>
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.8332 7.33337L9.1665 11L12.8332 14.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </button>
                                <button 
                                    type='button'
                                    onClick={() => openFancybox(quizeHints, activeHintWathIndex, dispatch, setActiveHintWathIndex)}  
                                    className="btn btn--transparent btn--square btn--md radius-5">
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="2.74658" y="2.74646" width="16.5069" height="16.5069" rx="5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M11.4585 7.79028H14.2096V10.5414" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.5417 14.2096H7.79053V11.4585" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </button>
                                <button type='button' 
                                    onClick={incrimentHandler} 
                                    className={`btn btn--transparent btn--square btn--md radius-5 ${(activeHintWathIndex === quizeHints?.length - 1)  ? 'disabled': '' }`}>
                                    <span className="ico">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.1665 14.6667L12.8332 11L9.1665 7.33337" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <Link to={`/quiz-page/${id}/quiz`} className="btn btn--primary gap-[10px] btn--md radius-5">
                                <span className="info text-[13px] font-[500] sm:flex hidden">Next Step</span>
                                <span className="ico">
                                    <ArrowRightIcon />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )
}

export default QuizPageHints