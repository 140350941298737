import React from 'react'
const STATUSES = {
    'true': '#2CC932',
    'false': '#F02B10',
    skip: "#08A0F6",
    default:  "#999999",
    active: "#999999"
    
}

export const QuizeStepsQuestions = ({ data, activeIndex}) => {
  return (
        <ul className="quiz-nav-menu">
            {data && data.map((step, index) => (
                <li key={index} className="quiz-nav-menu__item">
                    <span className={`quiz-nav-menu__link`} style={{
                        backgroundColor:  index <= activeIndex ? "var(--white)" : null,
                        color: index === activeIndex ? STATUSES.active 
                            : index < activeIndex && typeof step.userAnswer === 'string'
                            ? STATUSES.skip : index < activeIndex && step?.userAnswer?.answer_text ? STATUSES[step.userAnswer.correct_answer] : STATUSES.default ,
                        boxShadow: index <= activeIndex ? "0 2px 4px 0 rgba(133, 133, 133, 0.16), 0 0 1px 0 rgba(26, 32, 36, 0.32)" : null,
                    }}></span>
                </li>
            ))}
        </ul>
  )
}
