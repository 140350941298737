import { createSlice, current } from '@reduxjs/toolkit';
import { createMaterialLibraryFeth, delMaterialLibraryByIdFeth, editMaterialLibraryByIdFeth, favoriteMaterialLibraryByidFeth, getAllMaterialLibraryFeth, getMaterialLibraryByIdFeth, likeMaterialLibraryByIdFeth } from './materialLibraryThunks';
import { stages } from 'konva/lib/Stage';

const initialState = {
    materialsAll: [],
    material: null,
    materialId: null,
    addModalShow: false,
    isLoading: false,
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    },
    errors: null 
}

const materialLibrarySlice = createSlice({
    name: 'materialLibrary',
    initialState,
    reducers: {
        clearMaterial: (state, action) => {
            state.materialId = null
            state.material = null
        },
        setMaterial: (state, action) => {
            state.materialId = action.payload.id
            state.material = action.payload
        },
        setMaterialId: (state, action) => {
            state.materialId = action.payload
        },
        setAddModalShow: (state, action) => {
            state.addModalShow = action.payload
        }
    },

    extraReducers(builder) {
       builder
            .addCase(getAllMaterialLibraryFeth.fulfilled, (state, action ) => {
                state.isLoading = false
                state.materialsAll = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllMaterialLibraryFeth.pending, (state, action ) => {
                state.materialsAll = []
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getAllMaterialLibraryFeth.rejected, (state, action ) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(getMaterialLibraryByIdFeth.fulfilled, (state, action ) => {
                state.isLoading = false
                state.material = action.payload
            })
            .addCase(getMaterialLibraryByIdFeth.pending, (state, action ) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(getMaterialLibraryByIdFeth.rejected, (state, action ) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(createMaterialLibraryFeth.fulfilled, (state, action ) => {
                state.isLoading = false
                state.materialsAll.push(action.payload)
            })
            .addCase(createMaterialLibraryFeth.pending, (state, action ) => {
                state.isLoading = true
                state.errors = null;
            })
            .addCase(createMaterialLibraryFeth.rejected, (state, action ) => {
                state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(editMaterialLibraryByIdFeth.fulfilled, (state, action ) => {
                console.log('edit')
                const currentMaterial = state.materialsAll.findIndex(item => item.id === action.payload.id)
                // state.isLoading = false
                if(currentMaterial) {
                    state.materialsAll[currentMaterial] = action.payload
                }
                state.material = {...state.material ,...action.payload}
                
                console.log(action.payload)
               
            })
            .addCase(editMaterialLibraryByIdFeth.pending, (state, action ) => {
                // state.isLoading = true
                state.errors = null;
            })
            .addCase(editMaterialLibraryByIdFeth.rejected, (state, action ) => {
                // state.isLoading = false
                state.errors = action.payload;
            })
            .addCase(likeMaterialLibraryByIdFeth.fulfilled, (state, action) => {
                let currentArticle = state.materialsAll.find(item => item.id === action.payload.id);
                if(currentArticle) {
                    currentArticle.isLiked = action.payload.isLiked
                    currentArticle.likes = action.payload.likes
                }
                if( state.material) {
                    state.material.isLiked = action.payload.isLiked
                    state.material.likes = action.payload.likes
                }
            })
            .addCase(favoriteMaterialLibraryByidFeth.fulfilled, (state, action) => {
                let currentArticle = state.materialsAll.find(item => item.id === action.payload.id);
                if(currentArticle) {
                    currentArticle.isFavorite = action.payload.isFavorite
                }
                if(state.material) {
                    state.material.isFavorite = action.payload.isFavorite
                }
                
            })
            .addCase(delMaterialLibraryByIdFeth.fulfilled, (state, action) => {
                if(state.materialsAll) {
                    state.materialsAll = state.materialsAll.filter(item => item.id !== action.payload.id)
                }
                state.material = null
                state.materialId = null
            })
    }
})

export const {
    clearMaterial,
    setMaterial,
    setMaterialId,
    setAddModalShow
} = materialLibrarySlice.actions;


export default materialLibrarySlice.reducer