import { createSlice, current } from '@reduxjs/toolkit';
import { addQuizHintFilesByQuizIdFeth, addQuizMediaFilesByQuizIdFeth, addQuizQuestionsByQuizIdFeth, addQuizQuestionsOrderingByQuizIdFeth, addQuizResultByQuestionIdFeth, addQuizSettingsByQuizIdFeth, createQuizFeth, deleteQuizQuestionsByQuestionIdFeth, editQuizByIdFeth, editQuizHintFilesByQuizIdFeth, editQuizMediaFilesByQuizIdFeth, editQuizQuestionsByQuestionIdFeth, editQuizSettingsByIdFeth, getAllQuizzesFeth, getQuizDiscussionByQuizIdFeth, getQuizGeneralByQuizIdFeth, getQuizHintsByQuizIdFeth, getQuizMediaByQuizIdFeth, getQuizQuestionsByQuizIdFeth, getQuizResultsByQuizIdFeth, toggleFavoriteQuizByIdFeth, toggleLikeQuizByIdFeth, сlearQuizResultsbyQuizIdFeth } from './quizzesThunks';
import { stages } from 'konva/lib/Stage';


const initialState = {
    quizzesAll: [],
    quiz: null,
    quizId: null,
    quizMedia: null,
    isLoading: false,
    isLoadingQuestin: false,
    isLoadingDiscussion: false,
    questions: null,
    activeQuestionId: null,
    activeImageWathIndex: 0,
    activeVideoWathIndex: 0,
    activeHintWathIndex: 0,
    activeQuestionIndex: 0,
    quizeHints: null,
    quizeResults: {
        completedQuestions: 0,
        correctAnswers: 0,
        incorrectAnswers: 0,
        skippedQuestions: 0,
        totalQuestions: null
    },
    error: null,
    pagination: {
        page: null,
        limit: null,
        offset: null,
        maxPages: null,
        total: null
    },
    quizeDiscussion: null,
}

const quizzesSlice = createSlice({
    name: 'quizzes',
    initialState,
    reducers: {
        setQuizId: (state, action) => {
            state.quizId = action.payload
        },
        setQuiz: (state, action) => {
            state.quiz = action.payload
        },
        setActiveQuestionId: (state, action) => {
            state.activeQuestionId = action.payload
        },
        setActiveQuestionIndex: (state, action) => {
            state.activeQuestionIndex = action.payload
        },
        setAnswers: (state, action) => {
            state.answers = action.payload
        },
        setActiveImageWathIndex: (state, action) => {
            state.activeImageWathIndex = action.payload
        },
        setActiveVideoWathIndex: (state, action) => {
            state.activeVideoWathIndex = action.payload
        },
        setActiveHintWathIndex: (state, action) => {
            state.activeHintWathIndex = action.payload
        },
        setClearState: (state, action) => {
            state.quizMedia = null
            state.quizeHints = null
        },
    },

    extraReducers(builder) {
        builder
            .addCase(getAllQuizzesFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.quizzesAll = action.payload?.data
                state.pagination = action.payload?.pagination;
            })
            .addCase(getAllQuizzesFeth.pending, (state, action) => {
                state.isLoading = true
                state.error = null;
            })
            .addCase(getAllQuizzesFeth.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload;
            })
            .addCase(getQuizGeneralByQuizIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.quiz = action.payload?.data
                state.quizId = action.payload?.data?.id
            })
            .addCase(getQuizGeneralByQuizIdFeth.pending, (state, action) => {
                state.isLoading = true
                state.error = null;
            })
            .addCase(getQuizGeneralByQuizIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload;
            })
            .addCase(getQuizDiscussionByQuizIdFeth.fulfilled, (state, action) => {
                state.isLoadingDiscussion = false
                state.quizeDiscussion = action.payload;
            })
            .addCase(getQuizDiscussionByQuizIdFeth.pending, (state, action) => {
                state.isLoadingDiscussion = true
                state.error = null;
            })
            .addCase(getQuizDiscussionByQuizIdFeth.rejected, (state, action) => {
                state.isLoadingDiscussion = false
                state.error = action.payload;
            })
            .addCase(createQuizFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.quiz = action.payload
                state.quizId = action.payload?.id
            })
            .addCase(createQuizFeth.pending, (state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createQuizFeth.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload;
            })
            .addCase(editQuizByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.quiz = {
                    ...state.quiz,
                    ...action.payload
                }
            })
            .addCase(editQuizByIdFeth.pending, (state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editQuizByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload;
            })
            .addCase(addQuizSettingsByQuizIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.quiz = {
                    ...state.quiz,
                    quizSetting: action.payload
                }
            })
            .addCase(addQuizSettingsByQuizIdFeth.pending, (state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addQuizSettingsByQuizIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload;
            })
            .addCase(editQuizSettingsByIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.quiz.quizSetting = action.payload
            })
            .addCase(editQuizSettingsByIdFeth.pending, (state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editQuizSettingsByIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload;
            })
            .addCase(addQuizMediaFilesByQuizIdFeth.fulfilled, (state, action) => {
                
                state.isLoading = false
                state.quizMedia = {
                    ...state.quizMedia,
                    ...action.payload
                }
            })
            .addCase(addQuizMediaFilesByQuizIdFeth.pending, (state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addQuizMediaFilesByQuizIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload;
            })
            .addCase(editQuizMediaFilesByQuizIdFeth.fulfilled, (state, action) => {
                state.isLoading = false
                state.quizMedia = {
                    ...state.quizMedia,
                    ...action.payload
                }
                
            })
            .addCase(editQuizMediaFilesByQuizIdFeth.pending, (state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editQuizMediaFilesByQuizIdFeth.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload;
            })
            .addCase(toggleFavoriteQuizByIdFeth.fulfilled, (state, action) => {
                const quizeCurrent = state.quizzesAll.find(item => item.id === action.payload.id)
                if(quizeCurrent) {
                    quizeCurrent.isFavorite = action.payload.isFavorite
                } 
                if(state.quiz) {
                    state.quiz.isFavorite = action.payload.isFavorite
                }
                
            })
            .addCase(toggleLikeQuizByIdFeth.fulfilled, (state, action) => {
                const quizeCurrent = state.quizzesAll.find(item => item.id === action.payload.id)
                if(quizeCurrent) {
                    quizeCurrent.isLiked = action.payload.isLiked
                    quizeCurrent.likes = action.payload.likes
                } 
                if(state.quiz) {
                    state.quiz.isLiked = action.payload.isLiked
                    state.quiz.likes = action.payload.likes
                }
               

            })
            .addCase(getQuizMediaByQuizIdFeth.fulfilled,(state, action) => {
                state.isLoading = false;
                
                // if(action.payload?.data?.images?.length > 0 || action.payload?.data?.notes || action.payload?.data?.videos?.length > 0) {

                    state.quizMedia = action.payload?.data 
                // } else {
                //     state.quizMedia = []
                // }
                
            })
            .addCase(getQuizMediaByQuizIdFeth.pending,(state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getQuizMediaByQuizIdFeth.rejected,(state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getQuizQuestionsByQuizIdFeth.fulfilled, (state, action) => {
                
                state.questions = action.payload?.data.map(item=> {
                    return {
                        ...item, 
                        answers: item.answers.sort((a, b) => a.order - b.order)
                    }
                })
                state.isLoading = false;
            })
            .addCase(getQuizQuestionsByQuizIdFeth.pending, (state, action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getQuizQuestionsByQuizIdFeth.rejected,(state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addQuizQuestionsByQuizIdFeth.fulfilled, (state, action) => {
                state.isLoadingQuestin = false;
                state.questions.push(action.payload)
                state.activeQuestionId = action.payload.id
            })
            .addCase(addQuizQuestionsByQuizIdFeth.pending, (state, action) => {
                state.isLoadingQuestin= true;
            })
            .addCase(addQuizQuestionsByQuizIdFeth.rejected, (state, action) => {
                state.isLoadingQuestin = false;
            })
            .addCase(editQuizQuestionsByQuestionIdFeth.fulfilled, (state, action) => {
                state.isLoadingQuestin = false;
                
                state.questions = state.questions.map(item => {
                    if(item.id === action.payload.id) {
                        return action.payload
                    } else {
                        return item
                    }
                })
            })
            .addCase(editQuizQuestionsByQuestionIdFeth.pending, (state, action) => {
                state.isLoadingQuestin = true;
            })
            .addCase(editQuizQuestionsByQuestionIdFeth.rejected, (state, action) => {
                state.isLoadingQuestin = false;
            })
            .addCase(deleteQuizQuestionsByQuestionIdFeth.fulfilled, (state,  action) => {
                const filteredQuestions = state.questions.filter(item => item.id !== action.payload.id);
                state.questions = filteredQuestions.map((item, index) => ({
                        ...item, 
                        order: index
                }))
            })
            .addCase(addQuizQuestionsOrderingByQuizIdFeth.fulfilled, (state,  action) => {
               
                state.questions = action.payload
               
                
            })
            .addCase(addQuizResultByQuestionIdFeth.fulfilled, (state,  action) => {
                const questionIndex = state.questions.find(item => item.id === action.payload.questionId)
                questionIndex.userAnswer = action.payload?.data.userAnswer
                
                state.quizeResults = action.payload?.data
            })
            .addCase(сlearQuizResultsbyQuizIdFeth.fulfilled, (state,  action) => {
                 state.quizeResults = {
                    completedQuestions: 0,
                    correctAnswers: 0,
                    incorrectAnswers: 0,
                    skippedQuestions: 0,
                    totalQuestions: null
                 }
            })
            .addCase(getQuizResultsByQuizIdFeth.fulfilled, (state,  action) => {
                if(action.payload.totalQuestions) {
                    state.quizeResults = action.payload
                }
            })
            .addCase(getQuizHintsByQuizIdFeth.fulfilled, (state,  action) => {
                state.isLoading = false
                state.quizeHints = action.payload
            })
            .addCase(getQuizHintsByQuizIdFeth.pending, (state,  action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getQuizHintsByQuizIdFeth.rejected, (state,  action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(addQuizHintFilesByQuizIdFeth.fulfilled, (state,  action) => {
                state.isLoading = false
                state.quizeHints = action.payload
            })
            .addCase(addQuizHintFilesByQuizIdFeth.pending, (state,  action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addQuizHintFilesByQuizIdFeth.rejected, (state,  action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(editQuizHintFilesByQuizIdFeth.fulfilled, (state,  action) => {
                state.isLoading = false
                state.quizeHints = action.payload
            })
            .addCase(editQuizHintFilesByQuizIdFeth.pending, (state,  action) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editQuizHintFilesByQuizIdFeth.rejected, (state,  action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export const {
    setQuizId,
    setQuiz,
    setActiveQuestionId,
    setActiveQuestionIndex,
    setActiveVideoWathIndex,
    setActiveImageWathIndex,
    setActiveHintWathIndex,
    setClearState
} = quizzesSlice.actions;

export const getQuizId = (state) => state.quizzes.quizId;
export const getQuiz = (state) => state.quizzes.quiz;
export const quizzesAll = (state) => state.quizzes.quizzesAll;
export const quizzesPagination = (state) => state.quizzes.pagination;
export const getIsLoading = (state) => state.quizzes.isLoading;
export const getQuizMedia = (state) => state.quizzes.quizMedia;

export default quizzesSlice.reducer