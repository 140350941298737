import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';


export const getAllCassesFeth = createAsyncThunk(
    '/getAllCassesFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.getAllCasses(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getCaseByIdFeth = createAsyncThunk(
    '/getCaseByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.getCaseById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getRelatedCaseQuizzesListFeth = createAsyncThunk(
    '/getRelatedCaseQuizzesList/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.getRelatedQuizzesList(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const getRelatedCaseArticlesListFeth = createAsyncThunk(
    '/getRelatedCaseArticlesList/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.getRelatedArticlesList(payload);
            if (res.success) {
                // toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const createCaseFeth = createAsyncThunk(
    '/createCaseFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.createCase(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addCaseMediaFilesByCaseIdFeth = createAsyncThunk(
    '/addCaseMediaFilesByCaseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.addCaseMediaFilesByCaseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addCaseCommentsfilesByCaseIdFeth = createAsyncThunk(
    '/addCaseCommentsfilesByCaseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.addCaseCommentsfilesByCaseId(payload);
           
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const addSettingsToCaseByCaseIdFeth = createAsyncThunk(
    '/addSettingsToCaseByCaseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.addSettingsToCaseByCaseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const favoriteCaseByIdToggleFeth = createAsyncThunk(
    '/favoriteCaseByIdToggleFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.favoriteCaseByIdToggle(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const likeCaseByIdToggleFeth = createAsyncThunk(
    '/likeCaseByIdToggleFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.likeCaseByIdToggle(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const viewCaseByIdFeth = createAsyncThunk(
    '/viewCaseByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.viewCaseById(payload);
            if (res.success) {
                // toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editCaseByIdFeth = createAsyncThunk(
    '/editCaseByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.editCaseById(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editCaseMediaFilesByCaseIdFeth = createAsyncThunk(
    '/editCaseMediaFilesByCaseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.editCaseMediaFilesByCaseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editCaseCommentFilesByCaseIdFeth = createAsyncThunk(
    '/editCaseCommentFilesByCaseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.editCaseCommentFilesByCaseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const editCaseSettingsByCaseIdFeth = createAsyncThunk(
    '/editCaseSettingsByCaseIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.editCaseSettingsByCaseId(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);
export const deleteCaseByIdFeth = createAsyncThunk(
    '/deleteCaseByIdFeth/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.cases.deleteCaseById(payload);
            if (res.success) {
                toast.success(res.message)
                return res;
            }
            toast.error(res.message)
        } catch (err) {
            toast.error("Something went wrong")
            return rejectWithValue(err?.message);
        }
    },
);