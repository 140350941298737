import React, { useLayoutEffect, useState } from 'react'
import SuccesPaymant from '../../components/Modals/SuccesPaymant'
import { Link, Navigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import logo from "../../assets/img/logo.png"
import logoDark from "../../assets/img/logo-footer.png"
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import PaymantForm from './PaymantForm'
import { confirmSubscribeFeth, subscribeByPlanIdFeth } from '../../store/subscriptionPlan/subscriptionPlansThunk';
import { purchaseConfirmationFeth, purchaseCourseByIdFeth } from '../../store/courses/coursesThunk';

const stripePromise = loadStripe('pk_test_51PhmnU2NXaTKKpTl0SqJWAi0pzKUupBA7aAC9AC7XYU08RWyauzA3MsDEDUlwcaTF9iqGNOE1gX3ksA15VyiU23j00lBpEwYIC');

const PricingCheckout = ({ type }) => {
    const {id} = useParams()
    const { currentPlan } = useSelector(state => state.subscriptionPlan)
    const {siteTheme} = useSelector(state => state.auth)
    const { courseOne, courseId, isLoading } = useSelector(state => state.courses)
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    if ((!currentPlan && type === 'pricing') || (!courseOne && type === 'course')) return <Navigate to={type === 'pricing' ? '/pricing' : `/courses/${id}`} />
    return (
        <>
            <main className="content">
                <section className="checkout-section">
                    <div className="checkout-section__total-info">
                        <div className="checkout-section__form">
                            <Link to={'/'} className="logo">
                                <img src={siteTheme === 'dark' ? logoDark : logo} alt="mediwise" />
                            </Link>
                            <div className="checkout-section__form-body">
                                {type === 'pricing' && <div className="list-group list-group--llg">
                                    <div className="checkout-heading">
                                        <p className="checkout-heading__text">Subscribe to {currentPlan?.name}</p>
                                        <div className="checkout-price">
                                            <div className="checkout-price__value">${currentPlan?.price}</div>
                                            <span className="checkout-price__text">per {currentPlan?.interval}</span>
                                        </div>
                                    </div>
                                    <div className="list-item-group">
                                        <div>
                                            <span className="list-item-group__text">{currentPlan?.name}</span>
                                            <p className="text--sm font-normal color-secondary">
                                                Billed  {currentPlan?.interval}ly
                                            </p>
                                        </div>
                                        <span>{currentPlan?.price}</span>
                                    </div>
                                    <div className="list-item-group border-y">
                                        <span className="list-item-group__text">Subtotal</span>
                                        <span>{currentPlan?.price}</span>
                                    </div>
                                    {/* <div className="input-wrapper input--lg">
                                        <label className="label">Have a promotion code?</label>
                                        <div className="comments-send-form">
                                            <input className="input input--outline" type="text" placeholder="Add a promotion code" />
                                            <button type="submit" className="btn btn--primary btn--lg radius-5">
                                                <span className="text-[14px] font-[500]">Apply</span>
                                            </button>
                                        </div>
                                    </div> */}

                                    <div className="list-item-group">
                                        <span className="list-item-group__text">Total due today</span>
                                        <span>{currentPlan?.price}</span>
                                    </div>
                                </div>}
                                {type === 'course' && <div className="list-group list-group--llg">
                                    <div className="checkout-heading">
                                        <p className="checkout-heading__text">{courseOne?.name}</p>
                                        <div className="checkout-price">
                                            <div className="checkout-price__value">${courseOne?.price}</div>
                                            {courseOne?.oldPrice && <span className="line-through color-secondary">${courseOne?.oldPrice}</span>}
                                        </div>
                                    </div>
                                    {/* <div className="list-item-group">
                                        <div>
                                            <span className="list-item-group__text">{courseOne?.name}</span>
                                            <p className="text--sm font-normal color-secondary">
                                                Billed  {currentPlan?.interval}ly
                                            </p>
                                        </div>
                                        <div>
                                            <span>{courseOne?.price}</span>
                                            <span className="line-through color-secondary">${courseOne?.oldPrice}</span>
                                        </div>
                                    </div> */}
                                    <div className="list-item-group border-y">
                                        <span className="list-item-group__text">Subtotal</span>
                                        <span>{courseOne?.price}</span>
                                    </div>
                                    {/* <div className="input-wrapper input--lg">
                                        <label className="label">Have a promotion code?</label>
                                        <div className="comments-send-form">
                                            <input className="input input--outline" type="text" placeholder="Add a promotion code" />
                                            <button type="submit" className="btn btn--primary btn--lg radius-5">
                                                <span className="text-[14px] font-[500]">Apply</span>
                                            </button>
                                        </div>
                                    </div> */}

                                    <div className="list-item-group">
                                        <span className="list-item-group__text">Total due today</span>
                                        <span>${courseOne?.price}</span>

                                    </div>
                                </div>}
                            </div>
                            <div className="checkout-section__form-footer max-md:hidden">
                                <ul className="checkout-section__footer-list">
                                    <li>
                                        Powered by <span className="font-bold">Medwise</span>
                                    </li>
                                    <li>
                                        <Link to={'/terms'}>Terms</Link>
                                    </li>
                                    <li>
                                        <Link to={'/privacy'}>Privacy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Elements stripe={stripePromise}>
                        {type === 'pricing' && 
                            <PaymantForm 
                                currentPlan={currentPlan} 
                                secretKeyFeth = {subscribeByPlanIdFeth} 
                                succsessFeth={confirmSubscribeFeth} 
                                type={type}  
                        />}
                        {type === 'course' && 
                            <PaymantForm 
                                currentPlan={courseOne} 
                                type={type} 
                                secretKeyFeth = {purchaseCourseByIdFeth} 
                                succsessFeth={purchaseConfirmationFeth} 
                            />}
                    </Elements>
                </section>
            </main>
        </>
    )
}

export default PricingCheckout
