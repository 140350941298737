import React from 'react'

const InputGroup = ({ label, wrapperClasses = '', inputClasses = '', type = 'text', placeholder, defaultValue, value, error, errorMessage, ...props }) => {
  return (
    <div className={`input-wrapper ${wrapperClasses}`}>
      {label && <label className="label">{label}</label>}
      <input
        className={`input ${inputClasses} ${error ? "input--error" : ""}`}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        {...props}
      />
      {
        errorMessage && error ?
          <p className='color-danger text--xs mt-1'>{errorMessage}</p>
          :
          null
      }
    </div>
  )
}

export default InputGroup
